import { createSlice } from "@reduxjs/toolkit";

const buzAiSlice = createSlice({
	name: "buzAi",
	initialState: {
		data: [],
	},
	reducers: {
		setNewMessage: (state, action) => {
			state.data = action.payload;
		},
	},
});

// Extract the action creators object and the reducer
export const { actions, reducer } = buzAiSlice;

export const setNewMessage = (values) => (dispatch, getState) => {
	let data = [...getState().bazAi.data, ...values];
	dispatch(actions.setNewMessage(data));
};
export const setUpdateMessage = (values) => (dispatch, getState) => {
	if (Array.isArray(values)) {
		let removeElement =
			getState().bazAi.data[getState().bazAi.data.length - 1].id;
		let remainingItems = getState().bazAi.data.filter(
			(el) => el.id !== removeElement
		);
		let updatedData = [...remainingItems, ...values];
		dispatch(actions.setNewMessage(updatedData));
	} else {
		let data = getState().bazAi.data.map((el) => {
			if (values?.updateImage) {
				return el.id ===
					getState().bazAi.data[getState().bazAi.data.length - 2].id
					? {
							...el,
							...values,
					  }
					: el;
			} else {
				console.log(el);
				console.log(
					getState().bazAi.data[getState().bazAi.data.length - 1]
				);
				return el.id ===
					getState().bazAi.data[getState().bazAi.data.length - 1].id
					? {
							...el,
							...values,
					  }
					: el;
			}
		});
		dispatch(actions.setNewMessage(data));
	}
};
// Export the reducer, either as a default or named export
export default reducer;
