import SuperFetch from "config/SuperFetch";
import { baseUrl } from "config/apiUrl";

const BazAi = {
	searchProduct: (query) => {
		return SuperFetch(`${baseUrl.audio}/bazai/order/`, {
			method: "POST",
			data: query,
		});
	},
};

export default BazAi;
