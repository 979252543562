import React from "react";
import { useSelector } from "react-redux";

export default function Banner({ ekkLanguage }) {
	const user = useSelector((state) => state.auth.data.data.user);

	return (
		<div className='flex flex-col justify-center items-center '>
			<h5 className='text-20 font-bold pb-2'>
				{ekkLanguage.voiceOrder.bannerHello} {user.full_name}
			</h5>
			<p className='font-semibold text-16 pb-2'>
				{ekkLanguage.voiceOrder.bannerAI}
			</p>
			<p className='font-medium text-14 text-center'>
				{ekkLanguage.voiceOrder.bannerUse}
			</p>
		</div>
	);
}
