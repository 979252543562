import React from "react";

export default function Comment() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='30'
			height='30'
			viewBox='0 0 30 30'
			fill='none'
		>
			<path
				d='M27.9252 13.3029L27.9158 13.2988L2.8916 2.91954C2.68112 2.83145 2.45209 2.7969 2.22498 2.81898C1.99788 2.84107 1.7798 2.9191 1.59023 3.04611C1.38996 3.17734 1.22545 3.3563 1.11151 3.5669C0.997569 3.77749 0.937773 4.01311 0.9375 4.25255V10.8906C0.937612 11.218 1.05191 11.535 1.26069 11.7871C1.46948 12.0392 1.75967 12.2106 2.08125 12.2717L15.7295 14.7953C15.7831 14.8055 15.8315 14.8341 15.8663 14.8761C15.9012 14.9181 15.9202 14.971 15.9202 15.0256C15.9202 15.0802 15.9012 15.1331 15.8663 15.1751C15.8315 15.2171 15.7831 15.2457 15.7295 15.2559L2.08184 17.7795C1.76035 17.8405 1.47017 18.0116 1.26129 18.2635C1.05242 18.5153 0.93791 18.8322 0.9375 19.1594V25.7986C0.937345 26.0273 0.993958 26.2524 1.10226 26.4538C1.21056 26.6551 1.36716 26.8264 1.55801 26.9524C1.78758 27.1049 2.057 27.1864 2.33262 27.1867C2.52423 27.1866 2.71388 27.1481 2.89043 27.0736L27.9141 16.7535L27.9252 16.7483C28.262 16.6035 28.549 16.3632 28.7507 16.057C28.9524 15.7508 29.0599 15.3922 29.0599 15.0256C29.0599 14.659 28.9524 14.3004 28.7507 13.9942C28.549 13.688 28.262 13.4477 27.9252 13.3029Z'
				fill='#FF564C'
			/>
		</svg>
	);
}
