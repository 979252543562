import { IonInput } from "@ionic/react";
import React, { memo } from "react";

function OTPInput({ otp, ekkLanguage, setOtp }) {
	return (
		<IonInput
			type='number'
			value={otp}
			placeholder={ekkLanguage.otpsubmit.otpPlaceholder}
			onIonChange={(e) => setOtp(e.detail.value)}
			clearInput></IonInput>
	);
}
export default memo(OTPInput);
