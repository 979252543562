import {
	IonCol,
	IonGrid,
	IonRow,
	IonSegment,
	IonSegmentButton,
} from "@ionic/react";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedTab } from "redux/container/learningSlice";

function LearningStatusBtn({
	setOrderStatus,
	setReachedEnd,
	setHasMoreData,
	getLearningList,
	ekkLanguage,
}) {
	const dispatch = useDispatch();
	const selectedTab = useSelector((state) => state.learning.selectedTab);
	return (
		<>
			<IonGrid style={{ backgroundColor: "#fff" }}>
				<IonRow>
					<IonCol size='12'>
						<IonSegment
							value={selectedTab}
							scrollable
							className='orderBtnWrapper no-ripple'
							onIonChange={(e) => {
								setReachedEnd(false);
								setHasMoreData(true);
								// setOrderStatus(e.detail.value);
								// getLearningList(e.detail.value);
								dispatch(setSelectedTab(e.detail.value));
							}}>
							<IonSegmentButton
								value='My Company'
								className='paid relative'>
								<button className='text-paid font-sans p-0 font-normal shadow-none text-14'>
									My Company
								</button>
							</IonSegmentButton>

							<IonSegmentButton value='News' className='cancel relative'>
								<button className='text-F94B41-1000 font-sans p-0 font-normal shadow-none text-14'>
									News
								</button>
							</IonSegmentButton>

							<IonSegmentButton value='Help' className='cancel relative'>
								<button className='text-F94B41-1000 font-sans p-0 font-normal shadow-none text-14'>
									Help
								</button>
							</IonSegmentButton>
						</IonSegment>
					</IonCol>
				</IonRow>
			</IonGrid>
		</>
	);
}
export default memo(LearningStatusBtn);
