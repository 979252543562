import { IonCol, IonGrid, IonInput, IonRow, IonSearchbar } from "@ionic/react";
import Cross from "assets/images/icon/svg/Cross";
import Search from "assets/images/icon/svg/Search";
import React from "react";

export default function ProductSearch({
	searchQ,
	setSearchQ,
	itemsSearch,
	placeholder,
}) {
	const deleteQueryHandler = async (e) => {
		setSearchQ("");
	};
	return (
		<IonGrid>
			<IonRow>
				<IonCol size='12'>
					<div className='relative'>
						<span className='absolute left-[10px] top-[24%] h-full z-10 cursor-pointer'>
							<Search color='rgba(0,0,0,.6)' />
						</span>
						<input
							className='bg-white w-full h-11 rounded-[7px] pl-12 search-input border'
							placeholder={placeholder}
							style={{ boxShadow: "1px 2px 8px rgba(0, 0, 0, 0.2) " }}
							value={searchQ}
							onKeyPress={(e) => {
								if (e.key === "Enter") {
									itemsSearch(searchQ);
								}
							}}
							onChange={(e) => setSearchQ(e.target.value)}></input>
						{searchQ && (
							<span
								className='absolute  right-[10px] top-[34%] h-full z-10 cursor-pointer'
								onClick={deleteQueryHandler}>
								<Cross />
							</span>
						)}
					</div>
				</IonCol>
			</IonRow>
		</IonGrid>
	);
}
