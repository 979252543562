import React, { useState } from "react";
import Sheet from "react-modal-sheet";
import Print from "assets/images/icon/svg/Print";
import { IonItem } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { setBusinessBottomSheet } from "redux/container/userSlice";
import BpEdit from "assets/images/icon/svg/BpEdit";
import BpPayment from "assets/images/icon/svg/BpPayment";
import BpBranch from "assets/images/icon/svg/BpBranch";
import BpVarified from "assets/images/icon/svg/BpVarified";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import CancelPopUp from "assets/images/icon/svg/CancelPopUp";
import Business from "services/Business";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import { getUserBusiness } from "config/user";

export default function BusinessBottomSheet({ businessData }) {
	const ref = React.useRef();
	let navigate = useNavigate();
	const isOpenBottomSheet = useSelector(
		(state) => state.user.businessBottomSheet
	);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const dispatch = useDispatch();
	const [isOpen, setIsOpen] = useState(false);
	const onOpenModal = () => setIsOpen(true);
	const onCloseModal = () => setIsOpen(false);
	const [loader, setLoader] = useState(false);
	const businessInfo = getUserBusiness();
	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "300px",
			transition: ".4s all ease",
			borderRadius: 12,
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};
	const optSend = async function () {
		setLoader(true);
		let response = await Business.businessDeleteOtp();
		if (response.status === 200) {
			setLoader(false);
			navigate("/business-delete-otp");
			toast.success(ekkLanguage.password.otpSendLabel);
		} else {
			setLoader(false);
		}
	};
	return (
		<div>
			<Sheet
				className='bottom-sheet'
				initialSnap={1}
				ref={ref}
				isOpen={isOpenBottomSheet}
				snapPoints={[260, 260, 100, 0]}
				onClose={() => dispatch(setBusinessBottomSheet(false))}>
				<Sheet.Container>
					<Sheet.Header />
					<Sheet.Content>
						<IonItem
							button
							onClick={() => {
								dispatch(setBusinessBottomSheet(false));
								navigate("/business-edit");
							}}
							lines='none'>
							<span className='py-[5px]'>
								<BpEdit />
							</span>
							<p className='text-14 font-normal text-black-1000 pl-2'>
								{ekkLanguage.businessProfile.editBusinessMenu}
							</p>
						</IonItem>

						{/* <IonItem button onClick={() => {}} lines='none'>
							<span className='py-[5px]'>
								<BpPayment />
							</span>
							<p className='text-14 font-normal text-black-1000 pl-2'>
								{ekkLanguage.businessProfile.addPayMenu}
							</p>
						</IonItem>
						<IonItem button onClick={() => {}} lines='none'>
							<span className='py-[5px]'>
								<BpBranch />
							</span>
							<p className='text-14 font-normal text-black-1000 pl-2'>
								{ekkLanguage.businessProfile.addMenu}
							</p>
						</IonItem>
*/}
						{businessData?.business_status !== "Verified" &&
							businessData?.business_status !== "Pending" && (
								<IonItem
									button
									onClick={() => {
										dispatch(setBusinessBottomSheet(false));
										navigate("/nid-verification");
									}}
									lines='none'>
									<span className='py-[5px]'>
										<BpVarified />
									</span>
									<p className='text-14 font-normal text-black-1000 pl-2'>
										{ekkLanguage.businessProfile.verifyBusinessMenu}
									</p>
								</IonItem>
							)}

						<IonItem
							button
							onClick={() => {
								dispatch(setBusinessBottomSheet(false));
								navigate(`/pick-location/${businessData.id}`);
							}}
							lines='none'>
							<span className='py-[5px]'>
								<BpVarified />
							</span>
							<p className='text-14 font-normal text-black-1000 pl-2'>
								{ekkLanguage.businessProfile.setLocation}
							</p>
						</IonItem>
						{businessInfo.role === "Admin" && (
							<IonItem
								button
								onClick={() => {
									dispatch(setBusinessBottomSheet(false));
									onOpenModal();
								}}
								lines='none'>
								<img
									src='assets/images/delete.png'
									alt='delete'
									className='py-[5px]'
								/>
								<p className='text-14 font-normal text-black-1000 pl-2'>
									{ekkLanguage.businessProfile.deleteBusiness}
								</p>
							</IonItem>
						)}
					</Sheet.Content>
				</Sheet.Container>

				<Sheet.Backdrop
					onClick={() => dispatch(setBusinessBottomSheet(false))}
				/>
			</Sheet>
			<Modal
				style={customStyles}
				closeTimeoutMS={200}
				isOpen={isOpen}
				onRequestClose={onCloseModal}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2'>
						<CancelPopUp />
					</span>
					<h6 className='text-16 font-bold pb-[30px]'>
						{ekkLanguage.businessProfile.deleteWarning}
					</h6>

					<div className='flex'>
						<button
							color='primary'
							className={`${
								loader && "select-none cursor-no-drop"
							} font-bold h-10 rounded-[7px] w-1/2 mr-2 border-2 bg-primary text-white flex justify-center items-center`}
							onClick={!loader ? optSend : undefined}>
							{loader ? (
								<ClipLoader
									sizeUnit={"px"}
									size={20}
									color={"white"}
									loading={true}
								/>
							) : (
								ekkLanguage.orderDetails.yesBtn
							)}
						</button>
						<button
							className='border-2 font-bold h-10 rounded-[7px] w-1/2 ml-2 bg-success text-white'
							onClick={onCloseModal}>
							{ekkLanguage.orderDetails.noBtn}
						</button>
					</div>
				</div>
			</Modal>
		</div>
	);
}
