import { IonLoading } from "@ionic/react";
import React from "react";
import { useSelector } from "react-redux";
import NotificationCard from "./NotificationCard";
import NotificationTypeBtn from "./NotificationTypeBtn";

export default function NotificationList({ loading, setLoading,localLanguage }) {
  const ekkLanguage = useSelector((state) => state.lan.lan);
  return (
    <div className="px-2 pt-14">
      {/* <NotificationTypeBtn setOrderStatus={setOrderStatus} /> */}
      {loading ? (
        <IonLoading
          isOpen={true}
          message={ekkLanguage.orderList?.loader}
        ></IonLoading>
      ) : (
        <NotificationCard localLanguage={localLanguage} />
      )}
    </div>
  );
}
