import React, { useState } from "react";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { IonCheckbox } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { digitChanger, numberWithCommas, translator } from "helpers/helper";
import { setBrandId } from "redux/container/productSlice";

export default function SingleProduct({
	id,
	img,
	name,
	supplierName,
	title,
	qty,
	tag,
	offer,
	offerAmount,
	nearby,
	price,
	oldPrice,
	newPrice,
	sellPrice,
	profit,
	nameShow,
	skuShow,
	priceShow,
	oldPriceShow,
	distShow,
	profitShow,
	sellPriceShow,
	supplierNameShow,
	newPriceShow,
	titleShow,
	select,
	link,
	type,
	defaultImage,
	currency,
	offerPriceShow,
	offerPrice,
	brandId,
}) {
	let navigate = useNavigate();
	const [checked, setChecked] = useState(false);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const dispatch = useDispatch();

	let defaultImageMokeUp = () => {
		if (defaultImage) {
			return defaultImage;
		} else {
			if (type === "variant") {
				return "/assets/images/varient_default.png";
			} else if (type === "pack") {
				return "/assets/images/pack_default.png";
			} else if (type === "offer") {
				return "/assets/images/offer_default.png";
			}
		}
	};
	let isImageLength = () => {
		if (img?.length) {
			if (img[0] != "") {
				return `${img[0]}`;
			} else {
				if (type === "variant") {
					return "/assets/images/varient_default.png";
				} else if (type === "pack") {
					return "/assets/images/pack_default.png";
				} else if (type === "offer") {
					return "/assets/images/offer_default.png";
				}
			}
		}
	};

	return (
		<div className='rounded-md'>
			<div className='rounded-xl overflow-hidden bg-white  mb-[5px] relative'>
				{select && (
					<span className='absolute left-0 top-0'>
						<IonCheckbox
							color='success'
							className='rounede'
							checked={checked}
							onIonChange={(e) => setChecked(e.detail.checked)}
						/>
					</span>
				)}

				{img?.length ? (
					<img
						onClick={() => {
							dispatch(setBrandId(brandId));
							navigate(`/${link}/${id}`);
						}}
						src={isImageLength()}
						alt='productImage'
						className='w-full image-sizer'
					/>
				) : (
					<img
						onClick={() => {
							dispatch(setBrandId(brandId));
							navigate(`/${link}/${id}`);
						}}
						src={defaultImageMokeUp()}
						alt='productImage'
						className='w-full image-sizer'
					/>
				)}
			</div>

			<div>
				{nameShow && (
					<h5 className='font-semibold text-16'>{digitChanger(name)}</h5>
				)}
				{supplierNameShow && (
					<h5 className='font-semibold text-12'>{supplierName}</h5>
				)}
				{titleShow && (
					<h5 className='font-semibold text-16'>{digitChanger(title)}</h5>
				)}

				{/* {priceShow && (
					<span className='font-bold text-12 text-primary'>৳ {price}</span>
				)} */}
				{skuShow && (
					<span className='font-normal text-10'>
						{digitChanger(qty)} {ekkLanguage.home.skusLabel}
					</span>
				)}
				<div className='flex justify-between'>
					{offerPriceShow && (
						<span className='font-bold text-12 text-primary colors-222222 self-start'>
							{currency && translator(currency)}{" "}
							{digitChanger(numberWithCommas(offerPrice))}
						</span>
					)}
					{priceShow && (
						<span className='font-bold text-12 text-primary colors-222222 self-start'>
							{currency && translator(currency)}{" "}
							{digitChanger(numberWithCommas(price))}
						</span>
					)}
					{oldPriceShow && (
						<span className='font-medium text-12 colors-808080 line-through'>
							{currency && translator(currency)} {digitChanger(oldPrice)}
						</span>
					)}
					{profitShow && (
						<span className='font-medium text-12 text-success'>
							{currency && translator(currency)}{" "}
							{digitChanger(numberWithCommas(profit))}
						</span>
					)}
				</div>
				{/* <div className='flex justify-between'>
					{priceShow && (
						<span className='flex items-center'>
							<span className='font-bold text-12 text-primary '>
								{currency} {digitChanger(numberWithCommas(price))}
							</span>
						</span>
					)}
					{profitShow && (
						<span className=' flex items-center'>
							<span className='font-bold text-12 text-success'>
								{currency} {digitChanger(numberWithCommas(profit))}
							</span>
						</span>
					)}
				</div> */}
			</div>
		</div>
	);
}
