import { Button } from "components/global-components/common/button";
import { Input } from "components/global-components/common/Input";
import React, { useRef, useState } from "react";
import Box from "./Box";
import Auth from "services/Auth";
import { authBox_Block } from "Constants";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
	fowordToDashboard,
	login,
	loginFromSignUp,
} from "redux/container/authSlice";
import { useNavigate } from "react-router-dom";
import { updateToken } from "config/SuperFetch";
import { setLocalUser } from "config/user";
import { setLanguage } from "config/language";
import { selectLan } from "redux/container/languageSlice";
import { selectors } from "redux/container/authSlice";
import { parseNumber } from "libphonenumber-js";

export default function Password({
	password,
	setPassword,
	loading,
	startLoading,
	setBlockIndex,
	setIsForgotPassword,
	stopLoading,
	phoneNo,
	country,
	countryCode,
}) {
	const dispatch = useDispatch();
	let navigate = useNavigate();
	const [loginClick, setLoginClick] = useState(false);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const { loginStatus } = useSelector(selectors.getAuth);

	const forgotPass = () => {
		return (
			<span
				onClick={() => onForgotPassword(phoneNo, country, countryCode)}
				className='text-xs underline text-red'
			>
				{ekkLanguage?.password.forgetPass}
			</span>
		);
	};
	const isValidate = ({ password }) => {
		if (password.length > 0) {
			return false;
		} else {
			return true;
		}
	};

	let clickBtnRef = useRef(null);
	const _handleKeyPress = (e) => {
		if (e.key === "Enter") {
			clickBtnRef.click();
		}
	};

	//forgotpassword handler
	const onForgotPassword = async (phoneNumber, country, countryCode) => {
		// let pn = parseNumber(countryCode + phoneNo).phone;
		let req_body = {
			phone_number: (countryCode + phoneNo).trim(),
			country,
		};
		try {
			startLoading();

			let response = await Auth.resendOptToken(req_body);
			if (response.status === 200 || response.status === 201) {
				await setIsForgotPassword(true);
				setBlockIndex(authBox_Block.TOKEN);
				toast.success(ekkLanguage?.password.otpSendLabel);
			}
			stopLoading();
		} catch (e) {
			stopLoading();
			console.log(e);
		}
	};

	//this is login handler for calling redux login dispatch.
	const loginHandler = async (countryCode, country, phoneNo, password) => {
		// let pn = parseNumber(countryCode + phoneNo).phone;
		setLoginClick(true);
		let req_body = {
			phone_number: (countryCode + phoneNo).trim(),
			country,
			password,
		};
		try {
			const response = await Auth.login(req_body);
			if (response?.status === 200) {
				dispatch(loginFromSignUp(response));
				updateToken(response);
				setLocalUser(response?.data);
				if (response?.data?.user.Country === "Bangladesh") {
					navigate("/home");
					setLanguage("bn");
					dispatch(selectLan());
					setLoginClick(false);
				} else {
					setLanguage("en");
					dispatch(selectLan());
					setLoginClick(false);
				}
			}
		} catch (e) {
			stopLoading();
			console.log(e);
			toast.error(e.errorData.message);
		}
	};

	//cuntry varification
	async function findRoll(id) {
		try {
			const response = await Auth.userCheck(id);
			console.log(response.data);
			if (response.success) {
				if (response.data) {
					if (response.data.role === "GA") {
						await dispatch(fowordToDashboard());
						// window.location.href = window.location.origin + "/";
					} else {
						toast.error(
							"You are not authorized to access this global admin page"
						);
					}
					console.log("User Already Exist");
					// window.location.href = window.location.origin + "/";
				} else {
					toast.error("You are not authorized to access this page");
				}
				// setRoll("");
			}
		} catch (error) {
			console.log(error);
		}
	}

	return (
		<Box>
			<div>
				<Input
					onChange={(event) => setPassword(event.target.value)}
					type='password'
					label={ekkLanguage?.password.passwordLabel}
					placeholder={ekkLanguage?.password.passwordPlaceHolder}
					onKeyPress={_handleKeyPress}
				/>
				<div>{forgotPass()}</div>
			</div>

			<div className='flex justify-center py-4'>
				{loginClick ? (
					<Button
						title='Loading...'
						disabled={true}
						loading={loading}
						onClick={() =>
							loginHandler(countryCode, country, phoneNo, password)
						}
						propsRef={(input) => {
							clickBtnRef = input;
						}}
					/>
				) : (
					<Button
						title={ekkLanguage?.password.btnLabel}
						// disabled={isValidate(this.props)}
						loading={loading}
						onClick={() =>
							loginHandler(countryCode, country, phoneNo, password)
						}
						propsRef={(input) => {
							clickBtnRef = input;
						}}
					/>
				)}
			</div>
		</Box>
	);
}
