import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./style.scss";

let slideData = [
	{ image: "assets/images/banner.png" },
	{ image: "assets/images/banner1.png" },
];

export default function Banner() {
	return (
		<div className='banner-wrapper mt-5'>
			<Swiper
				modules={[Pagination, Navigation]}
				pagination={{ clickable: true }}
				spaceBetween={0}
				slidesPerView={1}
				// onSlideChange={() => console.log("slide change")}
				// onSwiper={(swiper) => console.log(swiper)}
			>
				{slideData.map((el, i) => {
					return (
						<SwiperSlide key={i}>
							<div className='rounded-[8px]'>
								<img
									src={el.image}
									className='w-100'
									alt='slideImage'
								/>
							</div>
						</SwiperSlide>
					);
				})}
			</Swiper>
		</div>
	);
}
