import React, { useEffect, useState } from "react";
import { IonApp, IonContent, IonPage } from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Business from "services/Business";
import EditEmployees from "components/mobile-components/CreateEmployees/EditEmployee";
import { setEditEmployeeData } from "redux/container/userSlice";

export default function UpdateEmployeePage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const employeeData = useSelector((state) => state.user.editEmployeeData);
	const dispatch = useDispatch();

	let { id } = useParams();
	// const [employees, setEmployees] = useState({});
	const [empLoading, setEmpLoading] = useState(false);

	const getEmployees = async () => {
		if (!employeeData?.id) {
			setEmpLoading(true);
			let response = await Business.getEmployees(id);
			if (response.status === 200) {
				dispatch(setEditEmployeeData(response.results));
				setEmpLoading(false);
			} else {
				setEmpLoading(false);
			}
		}
	};

	useEffect(() => {
		getEmployees();
	}, []);

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle
						title={ekkLanguage.createEmployees.updateEmployee}
					/>
					<EditEmployees />
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
