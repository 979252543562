import React, { useState } from "react";
import Card from "../Common/Card";
import "moment-timezone";
import { useSelector } from "react-redux";
import { digitChanger } from "helpers/helper";
import moment from "moment-timezone";
import Modal from "react-modal";
import Cross from "assets/images/icon/svg/Cross";
import { COUNTRYINFO } from "data/country";
export default function TranDetailsCard({ collection }) {
	const [confirmModal, setConfirmModal] = useState(false);
	const closeModal = () => setConfirmModal(false);
	const openModal = () => setConfirmModal(true);
	const [selectedImage, setSelectedImage] = useState(null);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const { createUserData } = useSelector((state) => state.user);
	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "350px",
			transition: ".4s all ease",
			borderRadius: 12,
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};
	return (
		<div className='px-2 pb-5'>
			<Card
				title={`${
					collection?.acc_group?.title === "Expense"
						? "Expense"
						: "Revenue"
				} Details`}>
				<div>
					<table className='table-fixed w-full paymentSettlementLabel'>
						<tbody className="before:content-[' '] before:block before:h-2 border-b-2">
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{collection?.acc_group?.title === "Expense"
										? "Expense"
										: "Revenue"}{" "}
									Date
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{moment(collection?.date).format("DD-MM-YYYY")}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									Category
									{/* {ekkLanguage.accounting.category} */}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{collection?.acc_category?.title}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									Description
									{/* {ekkLanguage.accounting.description} */}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{collection?.description}
								</td>
							</tr>
							{collection?.acc_group?.title === "Revenue" && (
								<>
									<tr>
										<td className='text-000080 text-12 font-medium py-[6px]'>
											Revenue Amount
										</td>
										<td className='text-000080 text-12 font-medium py-[6px] text-right'>
											{
												COUNTRYINFO?.[createUserData?.country]
													?.currency
											}
											{collection?.amount}
										</td>
									</tr>
									<tr>
										<td className='text-000080 text-12 font-medium py-[6px]'>
											Service Amount
										</td>
										<td className='text-000080 text-12 font-medium py-[6px] text-right'>
											{
												COUNTRYINFO?.[createUserData?.country]
													?.currency
											}
											{collection?.service_amount}
										</td>
									</tr>
									<tr>
										<td className='text-000080 text-12 font-medium py-[6px]'>
											Net Revenue
										</td>
										<td className='text-000080 text-12 font-medium py-[6px] text-right'>
											{
												COUNTRYINFO?.[createUserData?.country]
													?.currency
											}
											{collection?.net_revenue}
										</td>
									</tr>
								</>
							)}
							{collection?.acc_group?.title === "Expense" && (
								<tr>
									<td className='text-000080 text-12 font-medium py-[6px]'>
										Expense Amount
									</td>
									<td className='text-000080 text-12 font-medium py-[6px] text-right'>
										{COUNTRYINFO?.[createUserData?.country]?.currency}
										{collection?.amount}
									</td>
								</tr>
							)}

							{collection?.acc_group?.title === "Expense" && (
								<>
									<tr>
										<td className='text-000080 text-12 font-medium py-[6px]'>
											Supplier
										</td>
										<td className='text-000080 text-12 font-medium py-[6px] text-right'>
											{collection?.seller?.business_name}
										</td>
									</tr>
									<tr>
										<td className='text-000080 text-12 font-medium py-[6px]'>
											{/* {ekkLanguage.accounting.alreadyPaid} */}
											Expense Already paid?
										</td>
										<td className='text-000080 text-12 font-medium py-[6px] text-right'>
											{collection?.is_paid ? "Paid" : "Not Paid"}
										</td>
									</tr>
									{!collection?.is_paid && (
										<tr>
											<td className='text-000080 text-12 font-medium py-[6px]'>
												{ekkLanguage.accounting.onto}
											</td>
											<td className='text-000080 text-12 font-medium py-[6px] text-right'>
												{moment(collection?.paid_date).format(
													"DD-MM-YYYY"
												)}
											</td>
										</tr>
									)}
								</>
							)}
							{collection?.images?.length && (
								<tr>
									<div>
										<td className='text-000080 text-12 font-medium py-[6px]'>
											Images
										</td>
									</div>
								</tr>
							)}
						</tbody>
					</table>
					<div className='pt-4 flex'>
						{collection?.images?.map((el, i) => {
							return (
								// <div className='product-image-sizer'>
								// 	<img
								// 		key={i}
								// 		src={el}
								// 		alt='images'
								// 		className='rounded-[5px] bg-white block min-h-[100px] m-auto'
								// 		style={{
								// 			border: "0.5px solid rgba(0, 0, 0, 0.3)",
								// 		}}
								// 	/>
								// </div>
								<div className='product-image-sizer relative rounded-[10px] flex pb-[5px]'>
									<img
										onClick={() => {
											setSelectedImage(el);
											openModal();
										}}
										className='w-full h-full object-contain flex m-auto mb-[5px] cursor-pointer'
										src={el}
										alt='icon'
									/>
								</div>
							);
						})}
					</div>
				</div>
			</Card>
			<Modal
				style={customStyles}
				closeTimeoutMS={200}
				isOpen={confirmModal}
				onRequestClose={closeModal}
				classNames='bottom-sheet-modal'>
				<div
					className='absolute right-2 top-2 cursor-pointer'
					onClick={() => {
						closeModal();
					}}>
					<Cross />
				</div>
				<div className='p-5 pt-8'>
					<img src={selectedImage} alt='images' />
				</div>
			</Modal>
		</div>
	);
}
