import { IonItem } from "@ionic/react";
import React from "react";
import Sheet from "react-modal-sheet";
import { useDispatch, useSelector } from "react-redux";
import "react-responsive-modal/styles.css";
import { useNavigate } from "react-router-dom";
import { setMyEmployees } from "redux/container/userSlice";

export default function BottomSheet() {
  const ref = React.useRef();
  const isOpenBottomSheet = useSelector((state) => state.user.myEmployees);
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div>
      <Sheet
        className="bottom-sheet"
        initialSnap={1}
        ref={ref}
        isOpen={isOpenBottomSheet}
        snapPoints={[120, 120, 100, 0]}
        onClose={() => dispatch(setMyEmployees(false))}
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <IonItem
              button
              onClick={() => navigate("/create-employee")}
              lines="none"
            >
              <span className="py-[5px]">
                <img
                  className="h-10 w-10"
                  src="assets/images/add-user.png"
                  alt="user"
                />
              </span>
              <p className="text-16 font-bold text-black-1000 pl-2">
                {ekkLanguage.employees.createNewEmployees}
              </p>
            </IonItem>
          </Sheet.Content>
        </Sheet.Container>

        <Sheet.Backdrop onClick={() => dispatch(setMyEmployees(false))} />
      </Sheet>
    </div>
  );
}
