import {
	IonButton,
	IonCol,
	IonItem,
	IonLabel,
	IonRadio,
	IonRadioGroup,
	IonRow,
} from "@ionic/react";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import Check from "assets/images/icon/svg/Check";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ImageUploader from "./ImageUploader";
import User from "services/User";
import Delete from "assets/images/icon/svg/Delete";
import { setKYC } from "redux/container/businessSettingsSlice";
import { getUserBusiness, setUserBusiness } from "config/user";
import Business from "services/Business";

export default function PropertyType() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const bs = useSelector((state) => state.businessSettings);
	const navigate = useNavigate();
	const [files, setFiles] = useState(bs.data?.contract_paper_img ?? "");
	const [rentalProperty, setRentalProperty] = useState(true);
	const [property, setProperty] = useState(bs.data?.property_type ?? "Rental");
	let dispatch = useDispatch();
	let business = getUserBusiness();

	useEffect(() => {
		dispatch(
			setKYC({
				property_type: bs.data?.property_type ?? property,
			})
		);
	}, []);

	const setProperties = (e) => {
		setProperty(e.detail.value);
		dispatch(
			setKYC({
				property_type: e.detail.value,
			})
		);
		if (e?.detail?.value === "Rental") {
			setRentalProperty(true);
		} else {
			setRentalProperty(false);
		}
	};

	const nextHandler = async () => {
		if (!files[0].preview) {
			return navigate("/rental-info");
		}
		let formData = new FormData();
		formData.append("key", files[0]);

		if (files.length !== 0) {
			let response = await User.toBucketImage(formData);
			if (response.status === 201) {
				if (rentalProperty) {
					dispatch(
						setKYC({
							contract_paper_img: response.results[0],
						})
					);
					let query = {
						contract_paper_img: response.results[0],
						business: business?.id,
					};

					const res = bs.data?.kycUpdate
						? await Business.updateKYC(bs.data?.kycId, {
								contract_paper_img: response.results[0],
						  })
						: await Business.createKYC(query);
					if (res.status === 200) {
						dispatch(
							setKYC({
								kycUpdate: true,
								business_status: "Pending",
							})
						);
						setUserBusiness({
							...business,
							business_status: "Pending",
						});
						navigate("/rental-info");
					} else if (res.status === 201) {
						dispatch(
							setKYC({
								kycId: res.results?.id,
								kycUpdate: true,
								business_status: "Pending",
							})
						);
						setUserBusiness({
							...business,
							business_status: "Pending",
						});
						navigate("/rental-info");
					}
				} else {
					dispatch(
						setKYC({
							contract_paper_img: response.results[0],
						})
					);
					let query = {
						contract_paper_img: response.results[0],
						business: business?.id,
					};
					const res = bs.data?.kycUpdate
						? await Business.updateKYC(bs.data?.kycId, {
								contract_paper_img: response.results[0],
						  })
						: await Business.createKYC(query);
					if (res.status === 200) {
						dispatch(
							setKYC({
								kycUpdate: true,
								business_status: "Pending",
							})
						);
						setUserBusiness({
							...business,
							business_status: "Pending",
						});
						navigate("/rental-info");
					} else if (res.status === 201) {
						dispatch(
							setKYC({
								kycId: res.results?.id,
								kycUpdate: true,
								business_status: "Pending",
							})
						);
						setUserBusiness({
							...business,
							business_status: "Pending",
						});
						navigate("/rental-info");
					}
				}
			}
		}
	};

	async function kycDataSubmit() {
		let formData = new FormData();
		formData.append("key", files[0]);

		if (files.length !== 0) {
			let response = await User.toBucketImage(formData);
			if (response.status === 201) {
				let query = {
					...bs.data,
					contract_paper_img: response.results[0],
					business: business?.id,
				};
				try {
					const response = bs.data?.kycUpdate
						? await Business.updateKYC(bs.data?.kycId, query)
						: await Business.createKYC(query);
					if (response.status === 200) {
						localStorage.setItem("auth", true);

						dispatch(
							setKYC({
								business_status: "Pending",
							})
						);
						setUserBusiness({
							...business,
							business_status: "Pending",
						});
						navigate("/business-success");
					} else if (response.status === 201) {
						localStorage.setItem("auth", true);
						dispatch(
							setKYC({
								business_status: "Pending",
							})
						);
						setUserBusiness({
							...business,
							business_status: "Pending",
						});
						navigate("/business-success");
					}
				} catch (error) {
					console.log(error);
				}
			}
		}
	}
	return (
		<div className='pt-14 verifyBusiness-content'>
			<div className='px-4 py-5 pt-6 pb-0'>
				<p className='text-16 font-semibold text-black-1000 pb-3'>
					Property Type
				</p>

				<IonRadioGroup value={property} onIonChange={(e) => setProperties(e)}>
					<IonRow>
						<IonCol size='6'>
							<IonItem
								lines='none'
								className='bg-white border rounded-[10px] mb-4'
							>
								<IonLabel className='border-0 font-sans text-14 text-black-500 font-medium'>
									Rented
								</IonLabel>
								<IonRadio slot='start' value='Rental' color='success' />
							</IonItem>
						</IonCol>
						<IonCol size='6'>
							<IonItem
								lines='none'
								className='bg-white border rounded-[10px] mb-4'
							>
								<IonLabel className='border-0 font-sans text-14 text-black-500 font-medium'>
									Owned
								</IonLabel>
								<IonRadio slot='start' value='Own' color='success' />
							</IonItem>
						</IonCol>
					</IonRow>
				</IonRadioGroup>
			</div>

			<div className='pb-5 px-4'>
				<div className='pt-4 pb-[10px] flex items-center justify-between'>
					{rentalProperty ? (
						<h4 className='font-bold text-black text-16'>
							Rental Agreement / Deed with Owner
						</h4>
					) : (
						<h4 className='font-bold text-black text-16'>
							Ownership Agreement / Deed
						</h4>
					)}
					<span
						className={`h-10 w-10 flex items-center justify-center bg-white
						rounded-[5px]`}
						onClick={() => setFiles("")}
					>
						<Delete />
					</span>
				</div>
				<ImageUploader
					files={files}
					setFiles={setFiles}
					bHeight={438}
					placeHolder='Upload Image'
				/>
			</div>

			<div className='pt-5 px-4'>
				{files.length ? (
					<div
						className='rounded-[5px] text-center py-4'
						style={{
							background: "#CCFFD6",
							boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.15)",
						}}
					>
						<p
							className='font-normal text-14 flex justify-center mb-[10px]'
							style={{ color: "#808080" }}
						>
							<Check></Check> Upload Complete.
						</p>
						{rentalProperty ? (
							<p className='font-normal text-14' style={{ color: "#808080" }}>
								Please click "<span style={{ color: "#00B224" }}>NEXT</span>"
								button
							</p>
						) : (
							<p className='font-normal text-14' style={{ color: "#808080" }}>
								Please click "<span style={{ color: "#00B224" }}>SUBMIT</span>"
								button
							</p>
						)}
					</div>
				) : (
					<div
						className='rounded-[5px]'
						style={{
							background: "#FFFFCC",
							boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.15)",
						}}
					>
						{rentalProperty ? (
							<p
								className='font-normal p-[14px] text-14'
								style={{ color: "#808080" }}
							>
								Please, upload your{" "}
								<b className='text-primary font-semibold'>
									"Rental Agreement Document"
								</b>{" "}
								copy.
							</p>
						) : (
							<p
								className='font-normal p-[14px] text-14'
								style={{ color: "#808080" }}
							>
								Please, upload your{" "}
								<b className='text-primary font-semibold'>
									"Own Property Paper"
								</b>{" "}
								copy.
							</p>
						)}
					</div>
				)}

				<div className='pt-12 text-center pb-10'>
					<IonButton
						disabled={files ? "false" : "true"}
						color={files ? "success" : "dark"}
						className='font-bold h-[50px] w-full'
						onClick={property === "Own" ? kycDataSubmit : nextHandler}
					>
						{ekkLanguage.businessCreateName.btnLabel}
						<span className='pl-2'>
							<ArrayRight />
						</span>
					</IonButton>
					<IonButton
						color='white'
						className='font-bold h-[50px] w-full mt-[30px] border-primary border-2 text-primary rounded-[5px]'
						onClick={() =>
							navigate(
								property === "Own"
									? bs.data?.kycUpdate
										? "/business-success"
										: "/business-profile-settings"
									: "/rental-info"
							)
						}
					>
						I will give this information later
						<span className='pl-2'>
							<ArrayRight color='red' />
						</span>
					</IonButton>
				</div>
			</div>
		</div>
	);
}
