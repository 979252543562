import { IonApp, IonContent, IonPage } from "@ionic/react";
import BusinessLocation from "components/mobile-components/Business/BusinessLocation";
import BusinessLocation2 from "components/mobile-components/Business/BusinessLocation2";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import React from "react";
import { useSelector } from "react-redux";

export default function BusinessLocationPage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<IonApp>
			<IonPage className='business-location'>
				<IonContent>
					<BackButtonTitle
						title={ekkLanguage.businessLocation.pageTitle}
					/>
					<BusinessLocation2 />
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
