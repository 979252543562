import React from "react";
import CardTitle from "./CardTitle";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./style.scss";
import { useSelector } from "react-redux";
import { IonCol, IonRow, IonSpinner } from "@ionic/react";
import { useNavigate } from "react-router-dom";
import Pin from "assets/images/icon/svg/Pin";
import { digitChanger, numberWithCommas, stringCount } from "helpers/helper";
import StartMini from "assets/images/icon/svg/StartMini";

export default function OfferCard({ slideData }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const navigate = useNavigate();

	return (
		<div className='home-card pt-[20px]'>
			<CardTitle
				title={ekkLanguage.home.offerLabel}
				link='/products/offer'
				icon='assets/images/giftbox.png'
			/>
			<div>
				{slideData ? (
					<>
						{slideData.length > 0 ? (
							<Swiper
								spaceBetween={16}
								breakpoints={{
									260: {
										slidesPerView: 1,
									},
									320: {
										slidesPerView: 1.5,
									},
									360: {
										slidesPerView: 1.5,
									},
									575: {
										slidesPerView: 2.5,
									},
									768: {
										slidesPerView: 2.5,
									},
								}}>
								{slideData.map((el, i) => {
									// let businessType = () =>
									// 	BUSINESS_TYPE_INFO.find(
									// 		(cur) => cur.type === el?.business_type?.title
									// 	);
									return (
										<SwiperSlide key={i}>
											<div className='relative overflow-hidden'>
												<div
													className='relative cursor-pointer'
													onClick={() =>
														navigate(
															`/product-details/${el.id}?&type=offers`
														)
													}>
													<div className='flex absolute w-[50px] h-[26px] border-2 border-white bg-success items-center justify-center rounded-br-[10px] rounded-tl-[10px] top-[-2px] left-0 border-l-0'>
														<div>
															<img
																src='assets/images/box.png'
																alt='icon'
															/>
														</div>
														<span className='ml-[5px] font-bold text-12 text-white'>
															{digitChanger(
																Number(el?.inventory_quantity)
															)}
														</span>
													</div>
													<div className='flex absolute w-auto h-[26px] border-2 border-white bg-success items-center justify-center rounded-bl-[10px] rounded-tr-[10px] top-[-2px] right-0 border-r-0'>
														<span className='ml-[5px] font-bold text-12 text-white px-1'>
															{el?.country &&
																el?.country
																	?.currency_symbol_native}
															{digitChanger(
																numberWithCommas(el?.profit)
															)}
														</span>
													</div>

													<img
														src={
															el?.images?.length
																? el.images[0]
																: "assets/images/offer_default.png"
														}
														className='w-100 rounded-[10px] mb-[5px]'
														alt='icon'
													/>
												</div>

												<h5 className='font-bold text-14 text-primary mb-[5px]'>
													{el?.country &&
														el?.country?.currency_symbol_native}
													{digitChanger(
														numberWithCommas(el?.base_price)
													)}
												</h5>
												<div className='flex items-center'>
													<span
														className='font-bold text-16 text-262626-1000 cursor-pointer'
														onClick={() =>
															navigate(
																`/product-details/${el.id}?&type=offers`
															)
														}>
														{stringCount(el?.title, 25)}
													</span>
												</div>
											</div>
										</SwiperSlide>
									);
								})}
							</Swiper>
						) : (
							<IonRow>
								<IonCol>
									<div className='text-center'>
										<img
											className='m-auto mb-[10px] mt-5'
											src='/assets/images/not-found-file.png'
											alt='emptyCard'
										/>
										<p className='text-14 font-semibold text-black-500 mb-[35px]'>
											{ekkLanguage.home.offerProductNotFound}
										</p>
									</div>
								</IonCol>
							</IonRow>
						)}
					</>
				) : (
					<div className='flex items-center justify-center h-[156px] m-auto'>
						{" "}
						<IonSpinner name='crescent'></IonSpinner>
					</div>
				)}
			</div>
		</div>
	);
}
