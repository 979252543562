const malaysia = {
	login: {
		contryLabel: "NEGARA",
		contryPlaceHolder: "",
		phoneLable: "NOMBOR TELEFON",
		phonePlaceHolder: "NOMBOR TELEFON",
		validationLabel:
			"Nombor telefon tidak sah, Sila tulis nombor telefon dalam bahasa Inggeris",
		btnLabel: "Teruskan",
		tokenSuccessLabel: "Token anda telah dihantar ke telefon anda",
		tokenSuccessFailed: "Token anda belum dicipta lagi, sila cuba lagi",
		langChange: "Tukar bahasa ke",
		termsAllow: "Sila benarkan terma dan syarat semasa log masuk!",
	},

	password: {
		passwordLabel: "KATA LALUAN",
		passwordPlaceHolder: "Masukkan kata laluan anda",
		forgetPass: "Lupa Kata Laluan",
		backWord: "Kembali",
		btnLabel: "Log Masuk",
		btnLoadingLabel: "Sedang log masuk",
		otpSendLabel: "Kod telah dihantar ke telefon bimbit anda",
		passError: "Kata laluan tidak sepadan dengan nombor telefon",
	},
	changePass: {
		passwordLabel: "KATA LALUAN BARU",
		passwordPlaceHolder: "*******************",
		confirmPassLabel: "SAHKAN KATA LALUAN",
		confirmPassPlaceHolder: "*******************",
		passNotMatchLabel: "Kata laluan tidak sepadan",
		btnLabel: "Teruskan",
		successLabel: "Kata laluan anda berjaya ditukar",
		errorLabel: "Penukaran kata laluan anda gagal",
		lengthErrorLabel: "Kata laluan mesti sekurang-kurangnya 4 aksara",
		notMatchLabel: "Kata laluan dan sahkan kata laluan anda tidak sepadan.",
	},
	token: {
		tokenLabel: "TOKEN",
		tokenPlaceHolder: "Masukkan token anda",
		tokenResend: "Hantar semula token",
		tokenSuccessLabel:
			"Kod telah dihantar ke telefon bimbit anda, taip untuk teruskan",
		tokenErrorLabel: "Penghantaran token gagal",
		timerLabel: "Sila tunggu",
		second: "saat",
		btnLabel: "Teruskan",
		tokenNotMatch: "Token tidak sepadan",
		tokenVarified: "Token anda telah disahkan",
		tokenNotWrite: "Sila masukkan token anda",
	},
	registration: {
		nameLabel: "NAMA PENUH",
		namePlaceholder: "Masukkan nama penuh anda",
		emailLabel: "EMEL",
		emailPlaceholder: "Masukkan emel anda",
		passwordLabel: "KATA LALUAN",
		passwordPlaceholder: "Masukkan kata laluan anda",
		confirmPassLabel: "SAHKAN KATA LALUAN",
		confirmPassPlaceholder: "Masukkan sahkan kata laluan anda",
		validationLabel: "Medan input mesti sekurang-kurangnya 4 aksara",
		passNotMatch: "Kata laluan dan sahkan kata laluan anda tidak sepadan.",
		btnLabel: "Teruskan",
		successMessage: "Profil Berjaya Dicipta",
		errorMessage: "Sila semak input yang sah",
		passLengthValidLabel: "Kata laluan mesti sekurang-kurangnya 4 aksara",
		passwordLength: "Kata laluan anda adalah",
		strong: "Kuat",
		medium: "Sederhana",
		weak: "Lemah",
		gender: "JANTINA",
		male: "Lelaki",
		female: "Perempuan",
		others: "Lain-lain",
		dobLabel: "TARIKH LAHIR",
		day: "Hari",
		month: "Bulan",
		year: "Tahun",
	},
	letNav: {
		homeMenuLabel: "Laman Utama",
		orderMenuLabel: "Sejarah Pesanan",
		mySupplierMenuLabel: "Pembekal Saya",
		businessProfileMenuLabel: "Tetapan Profil Perniagaan",
		phoneCall: "Panggilan Untuk Memesan",
	},
	menu: {
		accounting: "Perakaunan",
		procurement: "Perolehan",
		products: "Produk",
		loan: "Pinjaman",
	},
	home: {
		showMoreLabel: "Tunjukkan Lagi",
		brandLabel: "Jenama",
		offerLabel: "Tawaran Baru",
		mySuppliersLabel: "Pembekal Saya",
		nearSuppliersLabel: "Pembekal Berhampiran",
		recommendentLabel: "Hanya Untuk Anda",
		newProductLabel: "Produk Baru",
		recommendentOrderLabel: "Pesanan Disyorkan",
		latestNewsLabel: "Berita Terkini",
		searchLabel: "Cari produk",
		supplierLabel: "Cari pembekal",
		todayPurchaseLabel: "Pembelian Hari Ini",
		totalOrderLabel: "Produk dari",
		totalOrder: "Pesanan",
		skusLabel: "SKU",
		tillNow: "Sehingga Kini",
		orderDue: "Pesanan Tertunggak",
		brandProductPageTitle: "Semua Jenama",
		offerProductPageTitle: "Produk Tawaran",
		offerProductNotFound: "Maaf, Tiada tawaran dijumpai untuk anda.",
		mySupplierNotFound:
			"Anda tidak mempunyai pembekal. Sila beli produk untuk menambah pembekal anda di sini.",
		supplierNotFound:
			"Maaf, tiada pembekal dijumpai di kawasan anda, Kami akan menugaskan beberapa pembekal di kawasan anda tidak lama lagi.",
		brandSeller: "Pembekal",
		allBrandSeller: "Semua Pembekal",
		km: "km",
		account: "Akaun",
		warning:
			"Sila ambil perhatian, transaksi akan terjejas pada 1 Mac 2024 kerana sistem sedang dinaik taraf.",
		warningHeading: "Amaran",
		product: "Produk",
		offerSearchNotFound: "Tiada produk dijumpai dengan nama itu",
	},
	profile: {
		pageTitle: "Profil & Tetapan",
		contactMenuLable: "Hubungi & Maklumat Asas",
		passwordMenuLable: "Tukar Kata Laluan",
		logoutMenuLable: "Log Keluar",
		changeLanguageMenuLable: "Tukar Bahasa",
	},
	changeLanguages: {
		pageTitle: "Pilih Bahasa",
		btnLabel: "Simpan",
	},
	contact: {
		pageTitle: "Hubungi & Maklumat Asas",
		emailLabel: "Emel",
		emailPlaceholder: "Masukkan emel anda",
		nidLabel: "NID",
		nidPlaceholder: "Masukkan nombor NID",
		btnLabel: "Simpan",
		successLabel: "Profil anda berjaya dikemas kini.",
		currectEmail: "sila masukkan alamat emel semasa",
	},
	otpsend: {
		pageTitle: "Sahkan Nombor",
		btnLabel: "Hantar",
		message:
			"Ketuk untuk menghantar kod pengesahan ke telefon bimbit anda melalui SMS.",
		successLabel: "Token anda telah dihantar ke telefon anda",
		errorLabel: "Token anda belum dicipta lagi, sila cuba lagi",
	},
	otpsubmit: {
		pageTitle: "Sahkan Nombor",
		btnLabel: "Hantar",
		verifiLabel: "Sahkan & Teruskan",
		title: "Kod Pengesahan",
		paragraph:
			"Kami telah menghantar kod pengesahan ke Nombor Telefon Bimbit Anda",
		otpLabel: "Masukkan kod OTP anda",
		otpPlaceholder: "Masukkan kod OTP anda",
		receiveYetLabel: "Tidak menerima kod?",
		againRequestLabel: "Mohon semula",
		timerLabel: "Sila tunggu",
		second: "saat",
		successLabel: "Token anda telah dihantar ke telefon anda",
		errorLabel: "Token anda belum dicipta lagi, sila cuba lagi",
		tokenVarifiedMessage: "Token anda telah disahkan",
		validationLabel: "Sila masukkan OTP anda",
		optNotMatch: "OTP anda tidak sah",
	},
	changePassword: {
		pageTitle: "Tukar Kata Laluan",
		btnLabel: "Hantar",
		passwordLabel: "KATA LALUAN",
		passwordPlaceholder: "Masukkan kata laluan anda",
		confirmPassLabel: "SAHKAN KATA LALUAN",
		confirmPassPlaceholder: "Masukkan sahkan kata laluan anda",
		successLabel: "Kata laluan anda telah dikemas kini.",
		errorLabel: "Kemas kini kata laluan anda gagal",
		passNotMatchLabel:
			"Kata laluan dan sahkan kata laluan anda tidak sepadan.",
		changePassSuccessPara: "Kata laluan anda berjaya ditukar.",
		changePassContinueBtn: "Teruskan",
	},
	brandDetails: {
		skusLabel: "SKU",
		descriptionLabel: "Penerangan",
		productTitleLabel: "Semua produk jenama ini",
	},
	mysuppliers: {
		skusLabel: "SKU",
		productTitleLabel: "Produk Pembekal Ini",
	},
	productList: {
		pageTitle: "Senarai Produk Tawaran",
	},
	productDetails: {
		offerStart: "Tarikh Mula Tawaran",
		offerEnd: "Tarikh Tamat Tawaran",
		moqLabel: "MOQ",
		consumerLabel: "Harga Pengguna (MRP)",
		profitLabel: "Keuntungan",
		productDetailTitle: "Butiran Produk",
		skuId: "ID SKU",
		expireData: "Tarikh Luput",
		manufactureData: "Tarikh Pengilangan",
		productWeight: "Berat Produk",
		packingType: "Jenis Pembungkusan",
		importedBy: "Diimport Oleh",
		manufacturedBy: "Dihasilkan Oleh",
		country: "Negara",
		descriptionLabel: "Penerangan",
		btnLabel: "Pesan Sekarang",
		inStock: "Dalam stok",
		outStock: "Habis stok",
		preOrderBtn: "Mohon Pra-Pesanan",
		preOrderQuantity: "Tulis Kuantiti Pra-Pesanan",
		searchTram: "Cari Produk",
	},
	createOrder: {
		pageTitle: "Buat Pesanan",
		buyerDetailsTitle: "Butiran Pembeli",
		sellerDetailsTitle: "Butiran Penjual",
		deliveryDetailsTitle: "Penghantaran",
		productItemTitle: "Item Produk",
		costSummaryTitle: "Ringkasan Kos",
		paymentDetailsTitle: "Butiran Pembayaran",
		paymentTermsTitle: "Syarat Pembayaran",
		paymentMethodeTitle: "Kaedah Pembayaran",
		moqLabel: "MOQ",
		pcsLabel: "Unit",
		subtotal: "Jumlah Kecil",
		tax: "Cukai / VAT",
		discount: "Diskaun",
		deliveryFee: "Yuran Penghantaran",
		creditFee: "Yuran Perkhidmatan",
		total: "Jumlah",
		immediatePayment: "Pembayaran Segera",
		pickAndPay: "Ambil dan Bayar",
		today: "Pada Malam Hari Penghantaran",
		threeDays: "Bayar dalam 3 hari",
		sevenDays: "Bayar dalam 7 hari",
		immediatePaymentText:
			"Tiada Yuran Perkhidmatan dikenakan untuk pesanan ini.",
		todayPaymentText1:
			"Untuk pembayaran hari ini, Yuran Perkhidmatan tambahan sebanyak ",
		todayPaymentText2: "akan ditambah ke pesanan ini.",
		threePaymentText1:
			"Untuk pembayaran dalam 3 hari, Yuran Perkhidmatan tambahan sebanyak ",
		threePaymentText2: "akan ditambah ke pesanan ini.",
		sevenPaymentText1:
			"Untuk pembayaran dalam 7 hari, Yuran Perkhidmatan tambahan sebanyak ",
		sevenPaymentText2: " akan ditambah ke pesanan ini.",
		cashOnDelivery: "Bayar Semasa Penghantaran",
		cashOnDeliveryText1: "Bayar Tunai kepada",
		cashOnDeliveryText2: "semasa barang dihantar.",
		cash: "Tunai",
		bkash: "Bkash",
		nagad: "Nagad",
		cashText: "Bayar Tunai kepada",
		bkashText: "Bayar melalui Bkash",
		nagadText: "Bayar melalui Nagad",
		bankTransfer: "Pindahan Bank",
		bankTransferText1: "Pindahan Bank - Bayar kepada",
		bankTransferText2: "akaun bank dengan 'Nombor Pesanan' sebagai rujukan",
		bankTransferText3: "Nama Bank",
		bankTransferText4: "Nama Akaun",
		bankTransferText5: "Nombor Akaun",
		bankTransferText6: "Nombor Laluan",
		bankTransferText7: "Cawangan",
		btnLabel: "Sahkan",
		requestDeliveryDate: "Minta Tarikh Penghantaran",
		addMoreProductLabel: "Tambah Lagi Produk",
		selectProductLabel: "Hantar",
		cosgressTitle: "Tahniah!",
		cosgressPara: "Pesanan anda telah diselesaikan.",
		preOrderCongress:
			"Pra-pesanan anda telah berjaya diselesaikan. Wakil kami akan menghubungi anda tidak lama lagi",
		congressCancelText:
			"Jika terdapat sebarang masalah dengan pesanan, batalkan sebelum pukul 12 tengah hari. Anda tidak boleh membatalkan pesanan selepas pukul 12 tengah hari.",
		congressProductLabel: "Teruskan",
		orderNow: "Pesan Sekarang",
		alert: "Amaran",
		importentMessage: "Mesej penting",
		deliveryDateMessage: "Sila Pilih Tarikh Penghantaran",
		paymentTermsMessage: "Sila Pilih Syarat Pembayaran",
		paymentMethodMessage: "Sila Pilih Kaedah Pembayaran",
		backButtonMessage:
			"Troli anda mempunyai beberapa item, jika anda meninggalkan halaman ini troli anda akan kosong.",
		okBtnLabel: "OK",
		loader: "Sila tunggu...",
		placeConformationMessage: "Adakah anda pasti mahu membuat pesanan ini?",
		lessThenMoq:
			"Kuantiti item yang dipilih kurang daripada kuantiti pesanan minimum.",
		all: "Semua",
		number: "Nombor",
		numberWarning:
			"Jika wang dihantar ke nombor selain nombor ini, ia akan dianggap sebagai dibatalkan.",
		islamiBank: "Bank Islam",
		bankAsia: "Bank Asia",
		payTo: "Bayar Kepada",
		merchantNumber: "Nombor Peniaga",
		outOffStock: "Produk tidak ada dalam stok",
	},
	selectProduct: {
		pageTitle: "Pilih Produk",
		totalLabel: "Jumlah",
		btnLabel: "Hantar",
	},
	orderList: {
		pageTitle: "Buat Pesanan",
		tabMenuRecent: "Semua",
		tabMenuPlaced: "Ditempatkan",
		tabMenuAccepted: "Diterima",
		tabMenuScDelivered: "Dijadualkan Untuk Penghantaran",
		tabMenuPartialDelivered: "Sebahagian Dihantar",
		tabMenuDelivered: "Dihantar",
		tabMenuReturn: "Kembalikan Produk",
		tabMenuPartialPaid: "Sebahagian Dibayar",
		tabMenuPaid: "Dibayar",
		tabMenuCancel: "Batal",
		tabMenuDeliveryFailed: "Penghantaran Gagal",
		orderItem: "Item Pesanan",
		seller: "Penjual",
		buyer: "Pembeli",
		loader: "Sila tunggu...",
		createdBy: "Dibuat Oleh",
		to: "Kepada",
		for: "Untuk",
		notFound: "Tidak Dijumpai",
	},
	orderDetails: {
		createdBy: "Dibuat oleh",
		placedLabel: "Ditempatkan",
		deliveredLabel: "Dihantar",
		paidLabel: "Dibayar",
		orderItemLabel: "Item Pesanan",
		castItemLabel: "Butiran Kos",
		timelineLabel: "Garis Masa",
		subtotal: "Jumlah Kecil",
		tax: "Cukai / VAT",
		discount: "Diskaun",
		deliveryFee: "Yuran Penghantaran",
		creditFee: "Yuran Perkhidmatan",
		total: "Jumlah",
		paymentSettlementLabel: "Penyelesaian Pembayaran",
		date: "Tarikh",
		amount: "Jumlah",
		dueDate: "Tarikh Akhir",
		remark: "Catatan",
		collectedBy: "Dikutip oleh ",
		paymentDetailsLabel: "Butiran Pembayaran",
		paymentMethod: "Kaedah Pembayaran",
		paymentTerms: "Syarat Pembayaran",
		paymentDueDate: "Tarikh Akhir Pembayaran",
		totalDue: "Jumlah Tertunggak",
		totalPaid: "Jumlah Dibayar",
		remaining: "Baki",
		deliveryDetailsLabel: "Butiran Penghantaran",
		BuyerName: "Nama Pembeli",
		address: "Alamat",
		phoneNumber: "Nombor Telefon",
		email: "Emel",
		requestDeliveryDate: "Minta Tarikh Penghantaran",
		scheduleDeliveryDate: "Jadualkan Tarikh Penghantaran",
		scheduleDeliveryTime: "Jadualkan Masa Penghantaran",
		deliveryDate: "Tarikh Penghantaran",
		downloadInvoices: "Muat Turun Invois",
		receiptDownloads: "Cetak Resit",
		cancelOrder: "Batalkan Pesanan",
		cancelConformationAsk: "Adakah anda pasti mahu Membatalkan Pesanan ini?",
		cancelConformationParagraph:
			"Anda tidak boleh membatalkan pesanan ini, anda hanya boleh membatalkan pesanan pada hari yang sama.",
		yesBtn: "Ya",
		noBtn: "Tidak",
		withoutSettelment:
			"Maklumat pembayaran yang telah diselesaikan akan ditunjukkan di sini.",
		bankInfoLabel: "Maklumat Akaun Bank untuk Pemindahan Pembayaran",
		deliveryFailMessage: "Mesej penghantaran gagal",
		close: "Tutup",
	},
	businessCreateName: {
		pageTitle: "Nama Perniagaan",
		businessName: "Nama Perniagaan",
		businessNamePlaceholder: "Masukkan Nama Perniagaan",
		paragraph:
			"Kami hanya memerlukan 3 langkah lagi untuk mencipta perniagaan anda. Mari kita mulakan dengan nama perniagaan anda.",
		btnLabel: "Seterusnya",
		submit: "Hantar",
		numberOne: 1,
		numberTwo: 2,
		numberThree: 3,
		errorLabel: "Masukkan nama perniagaan!",
		congratulation: "Tahniah",
		congratulationMessage: "Perniagaan anda telah berjaya dicipta",
		kycSubmit: "Hantar KYC Anda",
		pending:
			"Terima kasih kerana menghantar KYC. Pengesahan KYC anda sedang dalam proses, sila tunggu.",
		goBack: "Kembali",
	},

	businessType: {
		pageTitle: "Jenis Perniagaan",
		paragraph: "Mari pilih jenis perniagaan anda.",
		fmcg: "FMCG",
		miniMart: "Mini Mart",
		superMart: "Super Mart",
		pharmacy: "Farmasi",
		cart: "Troli",
		bazaarStore: "Kedai Bazaar",
		hawkerCenter: "Pusat Penjaja",
		restaurant: "Restoran",
		hotel: "Hotel",
		cosmeticsStore: "Kedai Kosmetik",
		nonFmcg: "Bukan FMCG",
		office: "Pejabat",
		NGO: "NGO",
		entertainmentPlace: "Tempat Hiburan",
		religiousOrganization: "Organisasi Keagamaan",
		spa: "Spa",
		salon: "Salun",
		gym: "Gym",
		electronicsStore: "Kedai Elektronik",
		shoeStore: "Kedai Kasut",
		fashionStore: "Kedai Fesyen",
		laundryStore: "Kedai Dobi",
		numberOne: 1,
		numberTwo: 2,
		numberThree: 3,
		btnLabel: "Seterusnya",
		validationLabel: "Sila Pilih jenis perniagaan",
	},
	businessLocation: {
		pageTitle: "Lokasi Perniagaan",
		paragraph: "Masukkan butiran alamat perniagaan anda",
		address: "Alamat",
		addressPlaceholder: "Masukkan nombor jalan/nama kawasan",
		city: "Bandar",
		cityPlaceholder: "Masukkan Nama Bandar",
		postalCode: "Poskod",
		postalCodePlaceholder: "Masukkan poskod",
		email: "Emel",
		emailPlaceholder: "Masukkan emel anda",
		userName: "Nama Pengguna",
		userNamePlaceholder: "Masukkan nama pengguna anda",
		numberOne: 1,
		numberTwo: 2,
		numberThree: 3,
		btnLabel: "SIAP",
		successLabel: "Tahniah perniagaan dicipta",
		errorLabel: "Penciptaan perniagaan gagal",
		validationLabel: "Sila isi alamat untuk teruskan.",
		loader: "Sila tunggu...",
		validationCityLabel: "Sila isi bandar.",
		validationPostCodeLabel: "Sila isi poskod.",
	},

	locationPicker: {
		pageTitle: "Lokasi Perniagaan",
		btnLabel: "SAHKAN",
		paragraph: "Pindahkan peta ke lokasi perniagaan anda untuk memilihnya",
	},
	location: {
		title: "Aktifkan Lokasi Anda",
		paragraph:
			"Aplikasi ini memerlukan perkhidmatan lokasi dihidupkan pada peranti anda dan untuk aplikasi ini. Anda mesti mengaktifkannya dalam Tetapan sebelum menggunakan aplikasi ini.",
		btnLabel: "Benarkan hanya semasa menggunakan aplikasi",
	},
	businessSelect: {
		pageTitle: "Pilih Perniagaan",
	},

	pwaDownload: {
		header: "Muat Turun Apl EkkBaz",
		paragraph:
			"Muat turun aplikasi dan pesan dalam jumlah besar dari pembekal anda pada bila-bila masa.",
		cancelBtn: "Batal",
		downloadBtn: "Muat Turun",
	},
	businessProfile: {
		pageTitle: "Tetapan Profil Perniagaan",
		businessDetailsLabel: "Butiran",
		registrationNo: "Nombor Pendaftaran",
		taxNo: "Nombor Cukai/Imbalan Nilai Tambah",
		mobileNo: "Nombor Telefon Bimbit",
		officeNo: "Nombor Telefon Pejabat",
		email: "Emel",
		website: "Laman Web",
		branchLabel: "Senarai Cawangan",
		paymentDetailsLabel: "Butiran Pembayaran",
		bankInfoLabel: "Maklumat Bank",
		bankName: "Nama Bank",
		branchName: "Cawangan Bank",
		branchAddress: "Alamat Bank",
		swiftCode: "Kod SWIFT Bank",
		acName: "Nama Akaun Bank",
		acNumber: "Nombor Akaun Bank",
		bkashInfoLabel: "Maklumat Bkash",
		bkashAcName: "Nama Akaun Bkash",
		bkashAcNo: "Nombor Akaun Bkash",
		nagadInfoLabel: "Maklumat Nagad",
		nagadAcName: "Nama Akaun Nagad",
		nagadAcNo: "Nombor Akaun Nagad",
		upayInfoLabel: "Maklumat Upay",
		upayAcName: "Nama Akaun Upay",
		upayAcNo: "Nombor Akaun Upay",
		verified: "Disahkan",
		unVerified: "Belum Disahkan",
		editBusinessMenu: "Edit Perniagaan",
		addPayMenu: "Tambah Pilihan Pembayaran ",
		setLocation: "Kemaskini Lokasi Perniagaan",
		addMenu: "Tambah Cawangan",
		verifyBusinessMenu: "Beri Maklumat KYC",
		bankInfoNotFound: "Maklumat bank tidak dijumpai.",
		deleteBusiness: "Padam Perniagaan",
		deleteWarning: "Semua kandungan perniagaan ini akan dipadam.",
		otpSubmit: "Hantar OTP",
		confirmDeleteWarning: "Adakah anda pasti mahu memadam perniagaan ini",
		switchBusiness: "Tukar Perniagaan",
		myEmployeesMenuLabel: "Pekerja",
	},

	businessEdit: {
		pageTitle: "Edit Perniagaan",
		uploadPhoto: "Muat Naik Gambar",
		businessName: "Nama Perniagaan",
		businessNamePlaceholder: "Masukkan nama perniagaan anda",
		email: "Emel",
		emailPlaceholder: "Masukkan emel anda",
		address: "Alamat",
		addressPlaceholder: "Masukkan alamat anda",
		city: "Bandar",
		cityPlaceholder: "Masukkan nama bandar",
		postalCode: "Poskod",
		postalCodePlaceholder: "Masukkan poskod",
		resigter: "Nombor Pendaftaran",
		resigterPlaceholder: "Masukkan nombor pendaftaran anda",
		tex: "Nombor Cukai/Imbalan Nilai Tambah",
		texPlaceholder: "Masukkan nombor cukai/imbalan nilai tambah anda",
		mobile: "Nombor Telefon Bimbit",
		mobilePlaceholder: "Masukkan nombor telefon bimbit anda",
		office: "Nombor Telefon Pejabat",
		officePlaceholder: "Masukkan nombor telefon pejabat anda",
		website: "Laman Web",
		websitePlaceholder: "Masukkan laman web anda",
		btnLabel: "Simpan",
		successLabel: "Profil anda telah dikemas kini. Berjaya.",
		currectEmail: "Sila masukkan alamat emel semasa",
	},
	bankDetails: {
		referenceNumber: "Nombor Rujukan",
		bankName: "Nama Bank",
		accountName: "Nama Akaun",
		accountNumber: "Nombor Akaun",
		routeNumber: "Nombor Laluan",
		branch: "Cawangan",
	},

	invoice: {
		title: "INBOIS",
		receipt: "Resit",
		buyerDetails: "Butiran Pembeli",
		sellerDetails: "Butiran Penjual",
		description: "PENERANGAN",
		qty: "KUANTITI",
		price: "HARGA",
		subtotal: "JUMLAH KESELURUHAN",
	},
	verifyBusiness: {
		numberOne: "১",
		numberTwo: "২",
		numberThree: "৩",
		numberFour: "৪",
		numberFive: "৫",
		numberSix: "৬",
	},
	receipt: {
		placed: "Ditempatkan",
		name: "Nama",
		qty: "Kuantiti",
		price: "Harga",
		subtotal: "Jumlah Keseluruhan",
		tax: "Cukai",
		discount: "Diskaun",
		creditFee: "Yuran Perkhidmatan",
		deliveryFee: "Yuran Penghantaran",
		total: "Jumlah",
		paid: "Dibayar",
		totalDue: "Jumlah Terutang",
		paymentTerms: "Syarat Pembayaran",
		days: "Hari",
		paymentMethod: "Kaedah Pembayaran",
		referenceNumber: "Nombor Rujukan Bank",
		deliveryDate: "Tarikh Penghantaran",
		dueDate: "Tarikh Jatuh Tempo",
		printTime: "Masa Cetakan",
		conditionsCreditFee: "Syarat Pembayaran & Yuran Perkhidmatan",
		immediatePayment: "Bayaran Segera",
		cashOnDelivery: "Bayar Semasa Penghantaran",
		atNight: "Pada Malam Hari Penghantaran",
		payInthreeDay: "Bayar dalam 3 Hari",
		payInSevenDay: "Bayar dalam 7 Hari",
		afterSeven: "Selepas 7 Hari (tambahan untuk setiap hari tambahan)",
	},
	landing: {
		header1: "Pasaran borong di hujung jari anda.",
		header2: "Beli Sekarang",
		header3: "Bayar Kemudian!",
		login: "Log masuk",
		bangla: "বাংলা",
		english: "English",
		vietnamese: "Tiếng Việt",
		malaysia: "Malay",
		retail: "Runcit",
		manufacture: "Pembekal",
		hotline: "Talian panas",
		phoneNumber: "Nombor Telefon",
		phoneNumberPlaceHolder: "Masukkan Nombor Telefon",
		fAbout:
			"Pasaran borong di hujung jari anda. Beli Sekarang, Bayar Kemudian!",
		location: "Lokasi",
		singapore: "Pejabat Singapura",
		bangladesh: "Pejabat Bangladesh",
		bOfficeAddress: "Tingkat 13, Plaza Razzak, Mog Bazar Moor, Dhaka-1205",
		otherInfo: "Maklumat lain",
		partners: "Rakan kami",
		news: "Berita",
		support: "Sokongan",
		corporate: "Korporat",
		refundPolicy: "Dasar Pembayaran Balik",
		termsCondition: "Syarat-syarat",
		privacyPolicy: "Dasar Privasi",
		contactUs: "Hubungi Kami",
		messageUs: "Hantar Mesej",
		facebook: "Facebook",
		sales: "Jualan",
		media: "Media",
		downloadBtn: "Muat turun aplikasi",
		backers: "Pegun",
		benefits: "Manfaat Anda",
		discover: "Berita Terkini EkkBaz",
		benefitsOne: "Penghantaran tepat pada masanya 24 jam",
		benefitsDisOne: "",
		benefitsTwo: "Harga pasaran terbaik",
		benefitsDisOneTwo: "",
		benefitsThree: "Asortmen SKUs terbesar",
		benefitsDisOneThree: "",
		benefitsFore: "Sokongan kewangan untuk pembelian",
		benefitsDisOneFore: "",
		seeMore: "Lihat Lebih",
		weAvailable: "Kami Ada",
		downloadApp: "Muat turun aplikasi sekarang!",
	},
	seeMoreProduct: {
		brandsSearchPlaceholder: "Cari Jenama",
		supplierSearchPlaceholder: "Carian Pembekal",
		offerSearchPlaceholder: "Cari produk tawaran",
	},
	sellerList: {
		pageTitle: "Senarai Penjual",
		nearBySeller: "Penjual Berdekatan",
		allSeller: "Semua Penjual",
		supplierNotFound: "Anda tidak mempunyai pembekal yang tersedia",
		nearBySupplierNotFound:
			"Maaf, tiada pembekal dijumpai di kawasan anda, kami akan memberikan beberapa pembekal di kawasan anda tidak lama lagi.",
	},
	learning: {
		pageTitle: "Senarai Pembelajaran",
		learningDetailsTitle: "Butiran Pembelajaran",
		comments: "Ulasan",
		commentAs: "Tulis komen anda di sini",
		likedWarning: "Anda tidak boleh menyukai lebih dari sekali",
	},
	navFooter: {
		home: "Laman Utama",
		order: "Pesanan",
		supplier: "Pembekal",
		learning: "Pembelajaran",
		account: "Akaun",
	},
	notification: {
		pageTitle: "Pemberitahuan",
		order: "Pesanan",
		product: "Produk",
		update: "Kemas kini",
	},
	autoLogOut: {
		pageTitle: "Log Keluar Automatik",
		warningMsg:
			"Log keluar automatik kerana nombor ini digunakan dalam Log Masuk yang lain",
		instrMsg: "Klik butang hijau di bawah untuk log masuk semula",
		btnLabel: "Log masuk",
	},
	dashboard: {
		statusLable: "Status Pesanan",
		today: "Hari Ini",
		yesterday: "Semalam",
		thisWeek: "Minggu Ini",
		thisMonth: "Bulan Ini",
		thisYear: "Tahun Ini",
		lastWeek: "Minggu Lalu",
		lastMonth: "Bulan Lalu",
		lastYear: "Tahun Lalu",
		custom: "Hari Kustom",
		selectDay: "Pilih Hari",
		Order: "Pesanan",
		totalOrder: "Jumlah Pesanan",
		placed: "Ditempatkan",
		accepted: "Diterima",
		scDelivered: "Dijadualkan Untuk Dihantar",
		partialDelivered: "Dihantar Sebahagian",
		delivered: "Dihantar",
		return: "Pulangkan Produk",
		partialPaid: "Bayaran Sebahagian",
		paid: "Dibayar",
		totalPaid: "Jumlah Dibayar",
		cancel: "Batal",
		deliveryFailed: "Penghantaran Gagal",
		orderAmount: "Jumlah Pesanan",
		totalAmount: "Jumlah Keseluruhan",
		collectedAmount: "Jumlah Yang Dikutip",
		dueAmount: "Jumlah Tertunggak",
		totalDue: "Jumlah Tertunggak Keseluruhan",
		directOrder: "Pesanan Langsung",
		directOrderAmount: "Jumlah Pesanan Langsung",
		ekkHeroOrder: "Pesanan EkkHero",
		ekkHeroOrderAmount: "Jumlah Pesanan EkkHero",
		cancelOrderAmount: "Jumlah Pesanan Dibatalkan",
		seeDetails: "Lihat Butiran",
	},
	voiceOrder: {
		pageTitle: "Pesanan Suara",
		bannerHello: "Hello",
		bannerAI: "Selamat datang ke Pesanan Suara AI",
		bannerUse:
			"Anda boleh mencari produk yang anda inginkan menggunakan saya",
		speechTitle: "Ucapan Anda",
		speechHeader: "Saya ingin membuat pesanan - ",
		speechInfo:
			"Sila beritahu saya nama produk dengan mengklik butang putih di atas",
		keywordTitle: "Kata Kunci Sepadan",
		keywordInfo:
			"Sila pilih salah satu kata kunci di atas untuk mendapatkan produk berkaitan",
		productsTitle: "Senarai Produk SePadan Terbaik",
		notFound:
			"Maaf, saya tidak dapat mencari sebarang produk, sila cuba lagi..!",
	},
	addBankSettings: {
		bottomSheetTitle: "Tambah Akaun Bank Anda",
		pageTitle: "Tambah Akaun Bank Anda",
		accountName: "Nama Akaun",
		accountNamePlaceholder: "Masukkan nama akaun anda",
		accountNumber: "Nombor Akaun",
		accountNumberPlaceholder: "Masukkan nombor akaun anda",
		selectBank: "Pilih Bank",
		selectBankPlaceHolder: "Pilih akaun bank anda",
		branch: "Cawangan",
		branchPlaceHolder: "Masukkan cawangan bank anda",
		address: "Alamat",
		addressPlaceholder: "Masukkan alamat bank anda",
		selectCountry: "Pilih Negara",
		selectCountryPlaceHolder: "Pilih negara bank anda",
		selectCity: "Pilih Bandar",
		selectCityPlaceHolder: "Pilih bandar",
		postalCode: "Poskod",
		postalCodePlaceHolder: "Masukkan poskod bank anda",
		buttonTitle: "Simpan",
		bankNameWarning: "Sila masukkan nama akaun bank anda",
		bankNumberWarning: "Sila masukkan nombor akaun bank anda",
		selectBankWarning: "Sila pilih satu bank",
		branchWarning: "Sila masukkan cawangan bank anda",
		addressWarning: "Sila masukkan alamat anda",
		cityWarning: "Sila masukkan bandar anda",
	},
	addedBankList: {
		pageTitle: "Senarai Bank",
		acName: "Nama Akaun",
		acNumber: "Nombor Akaun",
		branch: "Cawangan",
		address: "Alamat",
		city: "Bandar",
		country: "Negara",
		addBank: "Tambah Akaun Bank Anda",
	},
	blackListed: {
		pageTitle: "Dibenarkan Hitam",
		warningMsg: "Perniagaan Anda Dibenarkan Hitam",
		instrMsg:
			"Sila minta pemilik perniagaan anda untuk menghubungi EkkBaz untuk tindakan yang diperlukan untuk mengaktifkan semula perniagaan anda.",
		btnLabel: "Log keluar",
	},
	industryType: {
		pageTitle: "Industri Bekerja",
		title: "Sila pilih industri kerja anda",
	},
	accounting: {
		todayRevenue: "Jumlah Hasil Hari Ini",
		todayExpense: "Jumlah Perbelanjaan Hari Ini",
		revenues: "Pendapatan",
		expenses: "Perbelanjaan",
		accPageTitle: "Akaun",
		revenue: "Hasil",
		expense: "Perbelanjaan",
		addRevenue: "Tambah Hasil",
		addExpense: "Tambah Perbelanjaan",
		date: "Tarikh",
		amount: "Jumlah",
		amountPlaceholder: "Tulis jumlah",
		category: "Kategori",
		categoryPlaceholder: "Pilih kategori",
		description: "Penerangan",
		descriptionPlaceholder: "Tulis penerangan",
		images: "Muat Naik Gambar",
		supplier: "Pembekal",
		supplierPlaceholder: "Pilih pembekal",
		submitRevenue: "Hantar Hasil",
		submitExpense: "Hantar Perbelanjaan",
		uploadImageInfo: "Muat naik gambar anda di sini, atau semak imbas",
		uploadImageInfoPlaceholder:
			"Saiz fail maksimum sokongan 10 MB, JPG, JPEG atau PNG",
		revenueCreateWarning:
			"Adakah anda pasti mahu menghantar laporan hasil ini?",
		expenseCreateWarning:
			"Adakah anda pasti mahu menghantar laporan perbelanjaan ini?",
		successRevenue: "Hasil berjaya dicipta",
		successExpense: "Perbelanjaan berjaya dicipta",
		supplierPageTitle: "Senarai Pembekal",
		alreadyPaid: "Perbelanjaan Sudah Dibayar?",
		onto: "Akan Dibayar Pada",
		paid: "Dibayar",
		notPaid: "Belum Dibayar",
		takePhoto: "Ambil Foto",
		addImage: "Tambah Gambar",
		successFullyCreated: "Berjaya dicipta",
		amountCategoryDescription:
			"Sila tambah jumlah yang sah, pilih kategori dan tulis deskripsi",
		amountDescription: "Sila tambah jumlah yang sah dan tulis deskripsi",
		amountCategory: "Sila tambah jumlah yang sah dan pilih kategori.",
		categoryDescription: "Sila pilih kategori dan tulis deskripsi",
		amountWarning: "Sila masukkan jumlah yang sah.",
		descriptionWarning: "Sila masukkan deskripsi yang sah.",
		categoryWarning: "Sila pilih kategori.",
	},
	employees: {
		pageTitle: "Pekerja",
		createNewEmployees: "Cipta Pekerja Baru",
		searchPlaceHolder: "Cari pekerja anda",
		collectorList: "Senarai Pemungut",
		collectorSearch: "Cari pemungut",
	},
	employeesDetails: {
		pageTitle: "Butiran Pekerja",
	},
	createEmployees: {
		pageTitle: "Cipta Pekerja Baru",
		customerNoHolder: "Masukkan Nombor Telefon",
		employeeNumber: "Nombor Pekerja",
		name: "Nama",
		namePlaceholder: "Masukkan nama pekerja",
		confirmBtn: "Sahkan",
		selectEmployees: "Pilih jawatan pekerja",
		admin: "Administrator",
		stuff: "Staf",
		orderManager: "Pengurus Pesanan",
		bookManager: "Pengurus Buku",
		productManager: "Pengurus Produk",
		employeeManager: "Pengurus Pekerja",
		nameError: "Sila masukkan nama pekerja",
		roleError: "Sila pilih peranan pekerja",
		edit: "Sunting",
		delete: "Padam",
		editEmployee: "Sunting Pekerja",
		deleteConformationAsk: "Adakah anda pasti mahu memadam pekerja ini?",
		updateEmployee: "Kemas Kini Pekerja",
	},
	loan: {
		pageTitle: "Khoản vay",
		myLoanRequest: "Yêu cầu vay của tôi",
		loanProviders: "Nhà cung cấp khoản vay",
		loanAmount: "Số tiền vay cần thiết",
		loanPurpose: "Mục đích khoản vay",
		country: "Quốc gia",
		loanDuration: "Thời hạn vay",
		creditScore: "Điểm tín dụng",
		createLoanRequest: "Tạo yêu cầu vay",
	},
};
export default malaysia;
