import moment from "moment";
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setNewMessage, setUpdateMessage } from "redux/container/bazAiSlice";
import Procurement from "services/Procurement";
import User from "services/User";
import { v4 as uuidv4 } from "uuid";
import BazAiApi from "services/BazAi";
import { getAuth } from "config/authFetch";
import Modal from "react-modal";
import CancelPopUp from "assets/images/icon/svg/CancelPopUp";
import { ClipLoader } from "react-spinners";
import Accounts from "services/Accounts";
import { toast } from "react-toastify";
import { getUserBusiness } from "config/user";
import { numberWithCommas } from "helpers/helper";
import MultiImageUploader from "../Accounts/MultiImageUploader";
import { clearExpense, clearRevenue } from "redux/container/accountSlice";
import { dispose } from "@react-three/fiber";
import { IonSpinner } from "@ionic/react";

export default function BazAiBottomChatArea({
	textareaRef,
	messageData,
	handleKeyDown,
	height,
	handleInputChange,
	setComment,
	comment,
	handleAssistant,
	timer,
	getRootProps,
	getInputProps,
	formatTime,
	handleTouchStart,
	handleTouchEnd,
	ripple,
	newSenderItemHandler,
	updateBeforeLastDataHandler,
	updateLastDataHandler,
	materials,
	user,
	scrollToBottom,
	
}) {
	const [showDropUp, setShowDropUp] = useState(false);
	const revenueFileInputRef = useRef(null);
	const expenseFileInputRef = useRef(null);
	const thread = useSelector((state) => state?.bazAi?.threadId);
	let revenue = useSelector((state) => state?.accounts?.revenue);
	let expense = useSelector((state) => state?.accounts?.expense);
	const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

	// const fileInputRef = useRef(null);

	const [fileUrl, setFileUrl] = useState(null);
	const [files, setFiles] = useState(null);
	const [jsonUrl, setJsonUrl] = useState(null);
	const [fileContent, setFileContent] = useState(null);
	let dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const { token } = getAuth();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const business = getUserBusiness();

	const updateProductDataHandler = (procurement, bom, email, cad) => {
		let data = [
			{
				id: messageData?.length + 1,
				uid: uuidv4(),
				user_name: "Bot",
				receiver: true,
				created_at: moment().format("LLLL"),
				avatar: "assets/images/bot-s.png",
				loading: false,
				...bom,
			},
			{
				id: messageData?.length + 3,
				uid: uuidv4(),
				user_name: "Bot",
				receiver: true,
				created_at: moment().format("LLLL"),
				avatar: "assets/images/bot-s.png",
				loading: false,
				...procurement,
			},

			{
				id: messageData?.length + 2,
				uid: uuidv4(),
				user_name: "Bot",
				receiver: true,
				created_at: moment().format("LLLL"),
				avatar: "assets/images/bot-s.png",
				loading: false,
				...email,
			},
		];

		dispatch(setUpdateMessage(data));
	};

	const toggleDropUp = () => {
		setShowDropUp(!showDropUp);
	};

	const handleRevenueFileClick = () => {
		revenueFileInputRef.current.click();
	};

	const handleExpenseFileClick = () => {
		expenseFileInputRef.current.click();
	};

	const handleFileUpload = async (event, type) => {
		if (!event?.target?.files?.length) return;
		const file = event.target.files[0];

		if (file) {
			// Validate file is an image
			if (!file.type.startsWith("image/")) {
				console.error("Please upload an image file");
				return;
			}

			const reader = new FileReader();
			reader.onload = async (e) => {
				const imageData = e.target.result;

				// Create preview URL for display
				const previewUrl = URL.createObjectURL(file);

				// Show loading state and preview to user
				setLoading(true);
				newSenderItemHandler({
					imageUrl: previewUrl,
					type: "image",
					loading: true,
				});

				// Prepare form data for upload
				const formData = new FormData();
				formData.append("key", file);

				try {
					// Upload image to server
					const response = await User.toBucketImage(formData);

					if (response.status === 201) {
						updateBeforeLastDataHandler({
							imageUrl: response.results[0],
							loading: false,
							updateImage: true,
						});
						getSearchHandler(response.results[0], type);
					} else {
						throw new Error("Upload failed");
					}
				} catch (error) {
					console.error("Error uploading image:", error);
					updateBeforeLastDataHandler({
						error: "Failed to upload image",
						loading: false,
					});
				} finally {
					setLoading(false);
					URL.revokeObjectURL(previewUrl);
				}
			};

			reader.onerror = () => {
				console.error("Error reading file");
				setLoading(false);
			};

			reader.readAsDataURL(file);
		}

		setShowDropUp(false);
	};

	const getSearchHandler = async (imageUrl, type) => {
		let response = await Accounts.getAccountType(
			type === "revenue" ? "Revenue" : "Expense"
		);
		let chatPayload = {
			thread: thread,
			chat_history: [
				{
					images: imageUrl,
					card_type: "image",
					is_user: true,
				},
			],
		};
		await BazAiApi.newChatMessage(chatPayload);
		if (response?.success) {
			let payload = {
				acc_group: response?.results?.id,
				images: imageUrl,
				need_review: true,
				thread: thread,
				origin: window.location.origin,
			};

			try {
				let res = await Accounts.createTransaction(payload);
				if (res.success) {
					setLoader(false);
					updateLastDataHandler({
						message: `${res?.results?.acc_group?.title} request successfully created.\n \n <a class='text-blue-500 underline' href='${window.location.origin}/transaction-details/${res?.results?.id}'>see details</a>`,
						type: "initial",
						loading: false,
					});

					if (depositAmount && type === "revenue") {
						depositSubmit();
					} else {
						toggleDropUp();
						scrollToBottom();
						onCloseCreateRevenueModal();
						onCloseCreateExpenseModal();
						dispatch(clearRevenue());
						dispatch(clearExpense());
					}
				} else {
					setLoader(false);
					updateLastDataHandler({
						message: `Sorry, i couldn't complete your request. 😭\nI am baby and still learning😋`,
						type: "notFound",
						loading: false,
					});
				}
			} catch (error) {
				console.log(error);
				updateLastDataHandler({
					message: `Sorry, i couldn't complete your request. 😭\nI am baby and still learning😋`,
					type: "notFound",
					loading: false,
				});
			}
		} else {
			updateLastDataHandler({
				message: `Sorry, i couldn't complete your request. 😭\nI am baby and still learning😋`,
				type: "notFound",
				loading: false,
			});
		}
	};

	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "330px",
			transition: ".4s all ease",
			borderRadius: 12,
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
			overflow: "auto",
		},
	};

	const [isOpen, setIsOpen] = useState(false);
	const [modalType, setModalType] = useState("");
	const [modalInfoMessage, setModalInfoMessage] = useState("");
	const [depositAmount, setDepositAmount] = useState("");
	const [loader, setLoader] = useState(false);
	const [checkInId, setCheckInId] = useState(null);
	const [checkedStatus, setCheckedStatus] = useState(false);
	const dateFormat = useSelector((state) => state.date.format);
	const location = useSelector((state) => state.user.locationData);
	const [createRevenueModal, setCreateRevenueModal] = useState(false);
	const [createExpenseModal, setCreateExpenseModal] = useState(false);
	const [imageFiles, setImageFiles] = useState([]);
	const [isOpenDeposit, setIsOpenDeposit] = useState(false);

	const onOpenCreateRevenueModal = () => setCreateRevenueModal(true);
	const onOpenCreateExpenseModal = () => setCreateExpenseModal(true);
	const onCloseCreateRevenueModal = () => setCreateRevenueModal(false);
	const onCloseCreateExpenseModal = () => setCreateExpenseModal(false);

	const onOpenModal = (type) => {
		setModalType(type);
		setIsOpen(true);
		if (type === "deposit") {
			setModalInfoMessage("Write the amount you want to deposit");
		} else if (type === "checkedIn") {
			setModalInfoMessage("Are you sure you want to check in?");
		} else if (type === "checkedOut") {
			setModalInfoMessage("Are you sure you want to check out?");
		}
	};
	const onCloseModal = () => {
		setIsOpen(false);
		setModalType("");
	};
	const getCheckCheck = async () => {
		let response = await Accounts.statusCheck();

		if (response.status === 200) {
			setCheckInId(response?.results?.id);
			setCheckedStatus(response?.results?.is_check_in);
		} else {
			// toast.error(response.message);
		}
	};
	useEffect(() => {
		getCheckCheck();
	}, []);

	const messageDataHandler = (message) => {
		let data = [
			{
				cardType: "infoCard",
				id: 1,
				user_name: "Bot",
				receiver: true,
				message: message,
				created_at: moment().format("LLLL"),
				avatar: "assets/images/bot-s.png",
				type: "initial",
				loading: false,
			},
		];
		dispatch(setNewMessage(data));
	};

	const retryApiCall = async (apiCall, payload, type) => {
		let success = false;
		while (!success) {
			try {
				let response;
				if (type === "checkIn") {
					response = await apiCall(payload);
				} else {
					response = await apiCall(payload?.checkInId, payload?.payload);
				}
				if (response.success) {
					success = true;
					return response;
				}
			} catch (error) {
				console.error("API call failed, retrying...", error);
			}
			await new Promise((resolve) => setTimeout(resolve, 5000)); // Wait for 5 seconds before retrying
		}
	};

	const noRetryApiCall = async (apiCall, payload, type) => {
		try {
			let response;
			if (type === "checkIn") {
				response = await apiCall(payload);
			} else {
				response = await apiCall(payload?.checkInId, payload?.payload);
			}
			if (response.success) {
				return response;
			}
		} catch (error) {
			console.error("API call failed, retrying...", error);
		}
	};

	const depositSubmit = async function () {
		setLoader(true);
		let payload = {
			amount: depositAmount,
			thread: thread,
		};
		let response = await Accounts.depositAmount(payload);
		if (response.status === 201) {
			setLoader(false);
			messageDataHandler(
				`Deposited Amount: $${numberWithCommas(depositAmount)}`
			);

			toggleDropUp();
			onCloseCreateRevenueModal();
			dispatch(clearRevenue());
			dispatch(clearExpense());
			setDepositAmount("");
			onCloseModal();
			toggleDropUp();
			toast.success("Deposit amount successfully added");
			setTimeout(scrollToBottom, 100);
		} else {
			setLoader(false);
			// toast.error(response.message);
		}
	};

	const checkedInSubmit = async () => {
		setLoader(true);
		let payload = {
			business_id: business?.id,
			visit_type: "Hub Working",
			check_in_time: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
			latitude: location?.coordinates?.[0],
			longitude: location?.coordinates?.[1],
			thread: thread,
			timezone: timeZone,
		};
		let response = await noRetryApiCall(Accounts.checkIn, payload, "checkIn");
		if (response.status === 201) {
			getCheckCheck();
			setLoader(false);
			messageDataHandler(
				`Checked in to ${business?.business_name} at ${moment(
					response?.results?.check_in_time
				)
					.local()
					.format(`${dateFormat}, HH:mm:ss`)}`
			);

			onCloseModal();
			toggleDropUp();
			toast.success("Checked in successfully");
			setTimeout(scrollToBottom, 100);
		} else {
			setLoader(false);
			// toast.error(response.message);
		}
	};

	const checkedOutSubmit = async () => {
		setLoader(true);
		let payload = {
			business_id: business?.id,
			visit_type: "Hub Working",
			check_out_time: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
			latitude: location?.coordinates?.[0],
			longitude: location?.coordinates?.[1],
			thread: thread,
			timezone: timeZone,
		};

		let response = await noRetryApiCall(
			Accounts.checkOut,
			{
				checkInId,
				payload,
			},
			"checkOut"
		);
		if (response.success) {
			setLoader(false);
			getCheckCheck();
			messageDataHandler(
				`Checked out from ${business?.business_name} at ${moment(
					response?.results?.check_out_time
				)
					.local()
					.format(`${dateFormat}, HH:mm:ss`)} \n Total working hours: ${
					response?.results?.working_hour
				}`
			);
			onCloseModal();
			toggleDropUp();
			toast.success("Checked out successfully");
			setTimeout(scrollToBottom, 100);
		} else {
			setLoader(false);
			// toast.error(response.message);
		}
	};

	const modelAction = () => {
		if (modalType === "deposit") {
			return (
				<div>
					<input
						readOnly={messageData[messageData?.length - 1]?.loading}
						style={{
							height: `${height}px`,
							overflow: height === 120 ? "auto" : "hidden",
							resize: "none",
							width: "100%",
							padding: "10px",
							boxSizing: "border-box",
							paddingRight: 35,
							borderRadius: 15,
						}}
						onChange={(e) => {
							handleInputChange(e);
							setDepositAmount(e.target.value);
						}}
						value={depositAmount}
						type='number'
						placeholder='Enter deposit amount'
						className='gpt-text-area py-3.5 pr-10 px-3 appearance-none block mr-1 border leading-tight focus:outline-1 bg-white focus:bg-white text-14 w-full'
					/>
					<div className='flex justify-between mt-5'>
						<button
							className='border-2 font-bold h-10 rounded-[7px] w-1/2 ml-2 bg-primary text-white mr-2'
							onClick={onCloseModal}>
							{ekkLanguage.orderDetails.noBtn}
						</button>
						<button
							color='primary'
							className={`${
								loader && "select-none cursor-no-drop"
							} font-bold h-10 rounded-[7px] w-1/2 mr-2 border-2 bg-success text-white flex justify-center items-center ml-2`}
							onClick={!loader ? depositSubmit : undefined}>
							{loader ? (
								<ClipLoader
									sizeUnit={"px"}
									size={20}
									color={"white"}
									loading={true}
								/>
							) : (
								ekkLanguage.orderDetails.yesBtn
							)}
						</button>
					</div>
				</div>
			);
		} else if (modalType === "checkedIn") {
			return (
				<div className='flex justify-between mt-2'>
					<button
						className='border-2 font-bold h-10 rounded-[7px] w-1/2 ml-2 bg-primary text-white mr-2'
						onClick={onCloseModal}>
						{ekkLanguage.orderDetails.noBtn}
					</button>
					<button
						color='primary'
						className={`${
							loader && "select-none cursor-no-drop"
						} font-bold h-10 rounded-[7px] w-1/2 mr-2 border-2 bg-success text-white flex justify-center items-center ml-2`}
						onClick={!loader ? checkedInSubmit : undefined}>
						{loader ? (
							<ClipLoader
								sizeUnit={"px"}
								size={20}
								color={"white"}
								loading={true}
							/>
						) : (
							ekkLanguage.orderDetails.yesBtn
						)}
					</button>
				</div>
			);
		} else if (modalType === "checkedOut") {
			return (
				<div className='flex justify-between mt-2'>
					<button
						className='border-2 font-bold h-10 rounded-[7px] w-1/2 ml-2 bg-primary text-white mr-2'
						onClick={onCloseModal}>
						{ekkLanguage.orderDetails.noBtn}
					</button>
					<button
						color='primary'
						className={`${
							loader && "select-none cursor-no-drop"
						} font-bold h-10 rounded-[7px] w-1/2 mr-2 border-2 bg-success text-white flex justify-center items-center ml-2`}
						onClick={!loader ? checkedOutSubmit : undefined}>
						{loader ? (
							<ClipLoader
								sizeUnit={"px"}
								size={20}
								color={"white"}
								loading={true}
							/>
						) : (
							ekkLanguage.orderDetails.yesBtn
						)}
					</button>
				</div>
			);
		}
	};
	function DataURIToBlob(dataURI) {
		const splitDataURI = dataURI.split(",");
		const byteString =
			splitDataURI[0].indexOf("base64") >= 0
				? atob(splitDataURI[1])
				: decodeURI(splitDataURI[1]);
		const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
		const ia = new Uint8Array(byteString.length);
		for (let i = 0; i < byteString.length; i++)
			ia[i] = byteString.charCodeAt(i);
		return new Blob([ia], { type: mimeString });
	}
	const handleRevenueSubmit = async (type) => {
		setLoader(true);
		if (type === "revenue") {
			if (revenue?.images?.length) {
				const previewUrls = expense?.images?.map((image) =>
					URL.createObjectURL(image)
				);
				newSenderItemHandler({
					imageUrl: previewUrls,
					type: "image",
					loading: true,
				});
				const imageUrls = [];
				for (let index = 0; index < revenue?.images?.length; index++) {
					if (revenue?.images[index]?.cameraImage) {
						const cameraFile = DataURIToBlob(
							revenue?.images[index]?.preview
						);
						const cameraData = new FormData();
						cameraData.append("key", cameraFile, "image.jpg");
						let cameraResponse = await User.toBucketImage(cameraData);
						imageUrls.push(cameraResponse?.results[0]);
					} else {
						const imageData = new FormData();
						imageData.append(
							"key",
							revenue?.images[index],
							`image-${index}.png`
						);
						try {
							const uploadResponse = await User.toBucketImage(imageData);
							imageUrls.push(uploadResponse?.results[0]);
						} catch (error) {
							console.error(`Error uploading image-${index}:`, error);
						}
					}
				}
				if (imageUrls?.length) {
					updateBeforeLastDataHandler({
						imageUrl: imageUrls,
						loading: false,
						updateImage: true,
					});
					getSearchHandler(imageUrls, "revenue");
				}
			}
		} else if (type === "expense") {
			if (expense?.images?.length) {
				const previewUrls = expense?.images?.map((image) =>
					URL.createObjectURL(image)
				);
				newSenderItemHandler({
					imageUrl: previewUrls,
					type: "image",
					loading: true,
				});

				const imageUrls = [];
				for (let index = 0; index < expense?.images?.length; index++) {
					if (expense?.images[index]?.cameraImage) {
						const cameraFile = DataURIToBlob(
							expense?.images[index]?.preview
						);
						const cameraData = new FormData();
						cameraData.append("key", cameraFile, "image.jpg");
						let cameraResponse = await User.toBucketImage(cameraData);
						imageUrls.push(cameraResponse?.results[0]);
					} else {
						const imageData = new FormData();
						imageData.append(
							"key",
							expense?.images[index],
							`image-${index}.png`
						);
						try {
							const uploadResponse = await User.toBucketImage(imageData);
							imageUrls.push(uploadResponse?.results[0]);
						} catch (error) {
							console.error(`Error uploading image-${index}:`, error);
						}
					}
				}
				if (imageUrls?.length) {
					updateBeforeLastDataHandler({
						imageUrl: imageUrls,
						loading: false,
						updateImage: true,
					});
					getSearchHandler(imageUrls, "expense");
				}
			}
		}
	};

	return (
		<>
			<div className='flex items-center relative py-5 px-4'>
				<div className='flex items-center w-[calc(100%)]'>
					<span
						onClick={toggleDropUp}
						className='cursor-pointer'
						style={
							messageData[messageData?.length - 1]?.loading
								? {
										pointerEvents: "none",
										cursor: "not-allowed",
										opacity: 0.5,
								  }
								: {}
						}>
						<img
							className='w-7 h-7'
							src='/assets/images/bazai/plus.png'
							alt='icon'
						/>
					</span>

					{showDropUp && (
						<div className='absolute bottom-[70px] bg-white shadow-xl rounded p-2'>
							{!checkedStatus ? (
								<>
									<div>
										<div
											className='cursor-pointer py-1 px-2  rounded  font-bold flex w-full items-center space-x-2 bg-gray-200 hover:bg-gray-400 hover:text-white mb-1'
											onClick={() => onOpenModal("checkedIn")}>
											<p>Checked In</p>
										</div>
									</div>
								</>
							) : (
								<>
									<div>
										<div
											onClick={onOpenCreateRevenueModal}
											className='cursor-pointer py-2 px-2  rounded  font-bold flex w-full items-center space-x-2 bg-gray-200 hover:bg-gray-400 hover:text-white mb-1'>
											<p>Create Revenue</p>
										</div>
										{/* <div
											onClick={handleRevenueFileClick}
											className='cursor-pointer py-2 px-2  rounded  font-bold flex w-full items-center space-x-2 bg-gray-200 hover:bg-gray-400 hover:text-white mb-1'>
											<p>Create Revenue</p>
										</div>
										<input
											type='file'
											accept='image/*'
											ref={revenueFileInputRef}
											style={{ display: "none" }}
											onChange={(e) =>
												handleFileUpload(e, "revenue")
											}
										/> */}
									</div>
									{/* <div>
										<div
											className='cursor-pointer py-2 px-2  rounded  font-bold flex w-full items-center space-x-2 bg-gray-200 hover:bg-gray-400 hover:text-white mb-1'
											onClick={() => onOpenModal("deposit")}>
											<p>Deposit</p>
										</div>
									</div> */}
									<div>
										<div
											onClick={onOpenCreateExpenseModal}
											className='cursor-pointer py-2 px-2  rounded  font-bold flex w-full items-center space-x-2 bg-gray-200 hover:bg-gray-400 hover:text-white mb-1'>
											<p>Create Expense</p>
										</div>
										{/* <input
											type='file'
											accept='image/*'
											ref={expenseFileInputRef}
											style={{ display: "none" }}
											onChange={(e) =>
												handleFileUpload(e, "expense")
											}
										/> */}
									</div>

									<div>
										<div
											className='cursor-pointer py-2 px-2  rounded  font-bold flex w-full items-center space-x-2 bg-gray-200 hover:bg-gray-400 hover:text-white'
											onClick={() => onOpenModal("checkedOut")}>
											<p>Checked Out</p>
										</div>
									</div>
								</>
							)}
						</div>
					)}

					<div className='flex items-center relative w-full flex-1 ml-2 baz-ai-text-input'>
						<textarea
							ref={textareaRef}
							readOnly={messageData[messageData?.length - 1]?.loading}
							onKeyDown={handleKeyDown}
							style={{
								height: `${height}px`,
								overflow: height === 120 ? "auto" : "hidden",
								resize: "none",
								width: "100%",
								padding: "10px",
								paddingTop: "15px",
								boxSizing: "border-box",
								paddingRight: 35,
								borderRadius: 15,
							}}
							onChange={(e) => {
								handleInputChange(e);
								setComment(e.target.value);
							}}
							value={comment}
							type='text'
							placeholder='Enter your question'
							className='gpt-text-area py-3.5 pr-10 px-3 appearance-none block mr-1 border leading-tight focus:outline-1 bg-white focus:bg-white text-14 w-full'
						/>
						<p
							className='absolute right-[14px] bottom-[12px]'
							onClick={handleAssistant}>
							<img
								className='w-7 h-7'
								src='/assets/images/bazai/send.png'
								alt='icon'
							/>
						</p>
					</div>
				</div>

				{/* <div className='flex items-center justify-between bg-white rounded-[47px] h-12 w-[100px] relative'>
					{timer ? (
						<div className='absolute top-[-28px] left-[64px]'>
							<h4
								className='text-14 font-bold pt-2 text-center'
								style={{ color: "#FF564C" }}>
								{formatTime(timer)}
							</h4>
						</div>
					) : undefined}

					<span
						style={
							messageData[messageData?.length - 1]?.loading
								? {
										pointerEvents: "none",
										cursor: "not-allowed",
										opacity: 0.5,
								  }
								: {}
						}
						{...getRootProps({
							className: "dropzone",
							fileFrom: "general",
						})}
						onContextMenu={(e) => e.preventDefault()}
						className='image mx-1'>
						<input {...getInputProps()} />
						<img
							className='w-7 h-7'
							src='/assets/images/bazai/attachment-options.png'
							alt='icon'
						/>
					</span>
					<span
						onContextMenu={(e) => e.preventDefault()}
						style={
							messageData[messageData?.length - 1]?.loading
								? {
										pointerEvents: "none",
										cursor: "not-allowed",
										opacity: 0.5,
								  }
								: {}
						}
						className={`${ripple ? "ripple mic mx-1" : "mic mx-1"}`}
						onMouseDown={
							!messageData[messageData?.length - 1]?.loading
								? handleTouchStart
								: undefined
						}
						onMouseUp={handleTouchEnd}
						onTouchStart={
							!messageData[messageData?.length - 1]?.loading
								? handleTouchStart
								: undefined
						}
						onMouseLeave={handleTouchEnd}
						onTouchEnd={handleTouchEnd}>
						<img
							className='w-7 h-7'
							src='/assets/images/bazai/mic.png'
							alt='icon'
						/>
					</span>
				</div> */}
			</div>
			<Modal
				style={customStyles}
				closeTimeoutMS={200}
				isOpen={isOpen}
				onRequestClose={onCloseModal}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2'>
						<CancelPopUp />
					</span>
					<h6 className='text-16 font-bold pb-[15px]'>
						{modalInfoMessage}
					</h6>

					<div className=' '>{modelAction()}</div>
				</div>
			</Modal>
			<Modal
				style={customStyles}
				closeTimeoutMS={200}
				isOpen={createRevenueModal}
				onRequestClose={onCloseCreateRevenueModal}
				classNames='bottom-sheet-modal'>
				<div className='p-3'>
					<div>
						<h6 className='text-16 font-bold pb-[15px]'>
							Upload revenue receipt
						</h6>
						<MultiImageUploader
							type='revenue'
							files={imageFiles}
							setFiles={setImageFiles}
							bHeight={250}
							ekkLanguage={ekkLanguage}
							// isCameraMood={isCameraMood}
							// setIsCameraMood={setIsCameraMood}
						/>
					</div>
					{isOpenDeposit && (
						<div className='mt-4'>
							<h6 className='text-16 font-bold pb-[10px]'>
								Deposit Amount
							</h6>
							<input
								readOnly={messageData[messageData?.length - 1]?.loading}
								style={{
									height: `${height}px`,
									overflow: height === 120 ? "auto" : "hidden",
									resize: "none",
									width: "100%",
									padding: "10px",
									boxSizing: "border-box",
									paddingRight: 35,
									borderRadius: 15,
								}}
								onChange={(e) => {
									handleInputChange(e);
									setDepositAmount(e.target.value);
								}}
								value={depositAmount}
								type='number'
								placeholder='Enter deposit amount'
								className='gpt-text-area py-3.5 pr-10 px-3 appearance-none block mr-1 border leading-tight focus:outline-1 bg-white focus:bg-white text-14 w-full'
							/>
						</div>
					)}

					<div className='flex justify-between mt-5'>
						<button
							className='border-2 font-bold h-10 rounded-[7px] w-1/2 ml-2 bg-primary text-white mr-2'
							onClick={onCloseCreateRevenueModal}>
							{ekkLanguage.orderDetails.cancel}
						</button>
						{isOpenDeposit ? (
							<button
								onClick={() =>
									!loader && handleRevenueSubmit("revenue")
								}
								color='primary'
								className={`font-bold h-10 rounded-[7px] w-1/2 mr-2 border-2 bg-success text-white flex justify-center items-center ml-2 ${
									loader ? "cursor-not-allowed" : "cursor-pointer"
								}`}>
								{loader ? (
									<ClipLoader
										sizeUnit={"px"}
										size={20}
										color={"white"}
										loading={true}
									/>
								) : (
									ekkLanguage.businessCreateName.submit
								)}
							</button>
						) : (
							<button
								onClick={() =>
									revenue?.images?.length && setIsOpenDeposit(true)
								}
								color='primary'
								className={`font-bold h-10 rounded-[7px] w-1/2 mr-2 border-2  text-white flex justify-center items-center ml-2 ${
									revenue?.images?.length
										? "bg-success cursor-pointer"
										: "bg-gray-400 cursor-not-allowed"
								}`}>
								{ekkLanguage.orderDetails.next}
							</button>
						)}
					</div>
				</div>
			</Modal>

			<Modal
				style={customStyles}
				closeTimeoutMS={200}
				isOpen={createExpenseModal}
				onRequestClose={onCloseCreateExpenseModal}
				classNames='bottom-sheet-modal'>
				<div className='p-3'>
					<div>
						<h6 className='text-16 font-bold pb-[15px]'>
							Upload expense receipt
						</h6>
						<MultiImageUploader
							type='expense'
							files={imageFiles}
							setFiles={setImageFiles}
							bHeight={250}
							ekkLanguage={ekkLanguage}
							// isCameraMood={isCameraMood}
							// setIsCameraMood={setIsCameraMood}
						/>
					</div>

					<div className='flex justify-between mt-5'>
						<button
							className='border-2 font-bold h-10 rounded-[7px] w-1/2 ml-2 bg-primary text-white mr-2'
							onClick={onCloseCreateExpenseModal}>
							{ekkLanguage.orderDetails.cancel}
						</button>
						<button
							onClick={() => !loader && handleRevenueSubmit("expense")}
							color='primary'
							className={`font-bold h-10 rounded-[7px] w-1/2 mr-2 border-2 bg-success text-white flex justify-center items-center ml-2 ${
								loader ? "cursor-not-allowed" : "cursor-pointer"
							}`}>
							{loader ? (
								<ClipLoader
									sizeUnit={"px"}
									size={20}
									color={"white"}
									loading={true}
								/>
							) : (
								ekkLanguage.businessCreateName.submit
							)}
						</button>
					</div>
				</div>
			</Modal>
		</>
	);
}
