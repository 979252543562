import React from "react";

export default function StarBig() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='17'
			height='16'
			viewBox='0 0 17 16'
			fill='none'>
			<path
				id='Star 12'
				d='M8.02447 0.463524C8.17415 0.00286855 8.82585 0.00287012 8.97553 0.463525L10.5206 5.21885C10.5876 5.42486 10.7795 5.56434 10.9962 5.56434H15.9962C16.4806 5.56434 16.6819 6.18415 16.2901 6.46885L12.245 9.4078C12.0697 9.53512 11.9964 9.7608 12.0633 9.96681L13.6084 14.7221C13.7581 15.1828 13.2309 15.5659 12.839 15.2812L8.79389 12.3422C8.61865 12.2149 8.38135 12.2149 8.20611 12.3422L4.16099 15.2812C3.76913 15.5659 3.24189 15.1828 3.39157 14.7221L4.93667 9.96681C5.0036 9.7608 4.93027 9.53512 4.75503 9.4078L0.709911 6.46885C0.318054 6.18415 0.519443 5.56434 1.0038 5.56434H6.00385C6.22046 5.56434 6.41244 5.42486 6.47937 5.21885L8.02447 0.463524Z'
				fill='#FFC107'
			/>
		</svg>
	);
}
