import {
	IonApp,
	IonButton,
	IonCol,
	IonContent,
	IonGrid,
	IonPage,
} from "@ionic/react";
import { clearAuth } from "config/authFetch";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

export default function BusinessSuccess() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const kyc = useSelector((state) => state.businessSettings.data);
	const navigate = useNavigate();

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<IonGrid>
						<IonCol size='12'>
							<div className='flex items-center justify-center text-center h-screen px-4'>
								<div>
									<img
										src='/assets/images/winner.png'
										alt='congratulateion'
										className='inharit m-auto'
									/>
									<h2 className='text-30 text-success font-bold mb-2 pt-3'>
										{ekkLanguage.businessCreateName.congratulation}
									</h2>
									<>
										<p className='text-20 text-black-500 font-semibold mb-5'>
											{ekkLanguage.businessCreateName.pending}
										</p>
										<Link to='/' className='w-full'>
											<IonButton
												className='rounded-[10px] h-[50px] w-full text-white font-bold bg-success mb-5'
												style={{
													boxShadow:
														"0px 5px 15px rgba(0, 0, 0, 0.2)",
												}}>
												{ekkLanguage.changePass.btnLabel}
											</IonButton>
										</Link>
									</>
								</div>
							</div>
						</IonCol>
					</IonGrid>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
