import React, { useState } from "react";
import Sheet from "react-modal-sheet";
import Print from "assets/images/icon/svg/Print";
import { IonItem } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import {
	setAccountBottomSheet,
	setLoanBottomSheet,
} from "redux/container/userSlice";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { ClipLoader } from "react-spinners";
import CancelPopUp from "assets/images/icon/svg/CancelPopUp";
import Card from "../Common/Card";
import TextInputWithIcon from "../Common/TextInputWithIcon";

export default function LoanBottomSheet({ order }) {
	const ref = React.useRef();
	const isOpenBottomSheet = useSelector((state) => state.user.loanBottomSheet);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [loanAmount, setLoanAmount] = useState();

	const [requestLoan, setRequestLoan] = useState(false);
	const openRequestLoan = () => setRequestLoan(true);
	const closeRequestLoan = () => setRequestLoan(false);

	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "350px",
			transition: ".4s all ease",
			borderRadius: 12,
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};

	return (
		<div>
			<Sheet
				className='bottom-sheet'
				initialSnap={1}
				ref={ref}
				isOpen={isOpenBottomSheet}
				snapPoints={[180, 180, 100, 0]}
				onClose={() => dispatch(setLoanBottomSheet(false))}>
				<Sheet.Container>
					<Sheet.Header />
					<Sheet.Content>
						<IonItem
							button
							onClick={() => {
								navigate("/loan-request");
								dispatch(setLoanBottomSheet(false));
							}}
							lines='none'>
							<span className='py-[5px]'>
								<img
									src='assets/images/revenue.jpg'
									alt='icon'
									className='w-10'
								/>
							</span>
							<p className='text-16 font-semibold text-black-1000 pl-2'>
								{ekkLanguage.loan.createLoanRequest}
							</p>
						</IonItem>
					</Sheet.Content>
				</Sheet.Container>

				<Sheet.Backdrop
					onClick={() => dispatch(setLoanBottomSheet(false))}
				/>
			</Sheet>
			{/* <Modal
				style={customStyles}
				closeTimeoutMS={200}
				isOpen={requestLoan}
				onRequestClose={closeRequestLoan}
				classNames='bottom-sheet-modal'>
				<Card
					title='Loan request details'
					onHide={true}
					closeModal={closeRequestLoan}>
					<div className='pt-4'>
						{" "}
						<div>
							<TextInputWithIcon
								type='number'
								title={ekkLanguage.loan.loanAmount}
								inputText={loanAmount}
								setInputText={setLoanAmount}
								placeholder='write your request amount'
								required={true}
							/>
						</div>
						<div>
							<TextInputWithIcon
								type='string'
								title={ekkLanguage.loan.loanPurpose}
								inputText={loanAmount}
								setInputText={setLoanAmount}
								placeholder='write your lone purpose'
								required={true}
							/>
						</div>
						<div>
							<TextInputWithIcon
								type='string'
								title={ekkLanguage.loan.country}
								inputText={loanAmount}
								setInputText={setLoanAmount}
								placeholder='write your country name'
								required={true}
							/>
						</div>
						<div>
							<TextInputWithIcon
								type='number'
								title={ekkLanguage.loan.loanDuration}
								inputText={loanAmount}
								setInputText={setLoanAmount}
								placeholder='write loan duration day'
								required={true}
							/>
						</div>
						<div>
							<TextInputWithIcon
								type='string'
								title={ekkLanguage.loan.creditScore}
								inputText={loanAmount}
								setInputText={setLoanAmount}
								placeholder='write loan creditScore'
								required={true}
							/>
						</div>
					</div>
				</Card>
			</Modal> */}
		</div>
	);
}
