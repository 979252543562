import {
	IonApp,
	IonCheckbox,
	IonContent,
	IonPage,
	IonSpinner,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import { getUserBusiness } from "config/user";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Accounts from "services/Accounts";

export default function CategorySettingsPage() {
	let [loading, setLoading] = useState(true);
	let [submitLoading, setSubmitLoading] = useState(false);
	let [selectedCategory, setSelectedCategory] = useState([]);
	let [categoryData, setCategoryData] = useState(null);
	let [selectedCategoryData, setSelectedCategoryData] = useState([]);
	let business = getUserBusiness();

	// let [accountType, setAccountType] = useState(null);

	let getAccountType = async () => {
		let response = await Accounts.getAccountType("Revenue");
		if (response.status == 200) {
			// setAccountType(response?.results?.id);
			getCategoryData(response?.results?.id);
			getSelectedCategoryData();
		}
	};

	const getCategoryData = async (id) => {
		setLoading(true);
		const response = await Accounts.getCategoryList(id);
		if (response.status === 200) {
			setCategoryData(response.results);
			// let selectedCategoryId =
			// 	response.results.categories?.length > 0
			// 		? response.results.categories.map((item) => item.id)
			// 		: [];
			// let selectedCategoryId = response.results.map((item) => item.id);
			// setSelectedCategory(selectedCategoryId);
		}
		setLoading(false);
	};

	const getSelectedCategoryData = async () => {
		const response = await Accounts.getSelectedCategoryList();
		if (response.status === 200) {
			setSelectedCategoryData(response.results);
		} else {
			setSelectedCategoryData([]);
		}
	};

	useEffect(() => {
		let selectedCategoryId =
			selectedCategoryData?.categories?.length > 0
				? selectedCategoryData.categories?.map((item) => item.id)
				: categoryData?.map((item) => item.id);
		setSelectedCategory(selectedCategoryId);
	}, [categoryData, selectedCategoryData]);

	useEffect(() => {
		getAccountType();
	}, []);

	const updateCategoryData = (categoryId, checked) => {
		if (checked) {
			setSelectedCategory((prev) => {
				if (!prev.includes(categoryId)) {
					return [...prev, categoryId];
				}
				return prev;
			});
		} else {
			setSelectedCategory((prev) => prev.filter((id) => id !== categoryId));
		}
	};

	const updateCategoryDataHandler = async () => {
		if (selectedCategoryData?.categories?.length > 0) {
			setSubmitLoading(true);
			const payload = {
				categories: selectedCategory,
			};
			const response = await Accounts.updateCategoryList(
				selectedCategoryData?.id,
				payload
			);
			if (response.status === 200) {
				toast.success("Category updated successfully");
				// getCategoryData(accountType);
			}
			setSubmitLoading(false);
		} else {
			setSubmitLoading(true);
			const payload = {
				categories: selectedCategory,
			};
			const response = await Accounts.createCategoryList(payload);
			if (response.status === 201) {
				toast.success("Category created successfully");
				// getCategoryData(accountType);
			}
			setSubmitLoading(false);
		}
	};

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle title='P&L Settings' filter={false} />
					<div className='mt-14 px-4'>
						<div className='pb-10'>
							<div className='pt-4'>
								<div className='pb-4'>
									<h2 className='text-lg font-bold mb-6 '>
										Business Name: {business?.business_name}
										{/* <p className='text-sm font-semibold'>
											{categoryData?.address_line}
										</p> */}
									</h2>
									<table className='table-auto w-full bg-white shadow-md'>
										<thead>
											<tr className='border h-[45px] border-gray-300'>
												<th className='border p-2 font-bold text-left'>
													Revenue
												</th>
												<th className='border p-2 font-bold text-center'>
													Select revenue category
												</th>
											</tr>
										</thead>

										<tbody>
											{loading ? (
												<tr>
													<td
														colSpan='2'
														className='text-center py-10'>
														<IonSpinner />
													</td>
												</tr>
											) : (
												categoryData?.map((item) => (
													<tr key={item.id} className='border'>
														<td className='border p-1 pl-6'>
															{item.title}
														</td>
														<td className='border p-1 text-center flex justify-center'>
															<IonCheckbox
																color='success'
																checked={selectedCategory?.includes(
																	item.id
																)}
																onIonChange={(e) =>
																	updateCategoryData(
																		item.id,
																		e.detail.checked
																	)
																}
															/>
														</td>
													</tr>
												))
											)}
										</tbody>
									</table>
									<div className='flex justify-end pt-5'>
										<button
											color='primary'
											className='font-bold h-10 rounded-[7px] w-full ml-2 border-2 bg-success text-white'
											onClick={
												!submitLoading && updateCategoryDataHandler
											}>
											{submitLoading ? (
												<IonSpinner color='white'></IonSpinner>
											) : (
												"Update Revenue Category Settings"
											)}
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
