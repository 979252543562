import { IonCol, IonGrid, IonRow, IonSearchbar } from "@ionic/react";
import Alarm from "assets/images/icon/svg/Alarm";
import Menu from "assets/images/icon/svg/Menu";
import Qr from "assets/images/icon/svg/Qr";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./style.scss";

export default function NavigationBar({ menuIconClick, sticky }) {
	const [tarm, setTarm] = useState("");
	let navigate = useNavigate();
	const ekkLanguage = useSelector((state) => state.lan.lan);

	return (
		<div
			className={`home-nav absolute left-0 top-0 w-full z-10 px-3 pt-2 ${
				sticky ? sticky : ""
			}`}>
			<IonGrid>
				<IonRow className='items-center'>
					<IonCol size='1' className='p-0'>
						<div className='text-center' onClick={menuIconClick}>
							<Menu color={sticky !== "" ? "white" : "red"} />
						</div>
					</IonCol>
					<IonCol size='9'>
						<div>
							<IonSearchbar
								className='font-sans text-12 home-searchBar'
								placeholder={ekkLanguage.home.searchLabel}
								value={tarm}
								onIonChange={(e) => {
									setTarm(e.target.value);
								}}
								onKeyPress={(e) => {
									if (e.key === "Enter") {
										navigate(`/search-page/${e.target.value}`);
									}
								}}></IonSearchbar>
						</div>
					</IonCol>
					<IonCol size='2' className='p-0'>
						<div>
							<ul className='flex justify-center'>
								<li className='pr-2'>
									<span>
										<Qr color={sticky !== "" ? "white" : "white"} />
									</span>
								</li>
								<li className='pl-2'>
									<span>
										<Alarm
											color={sticky !== "" ? "white" : "white"}
										/>
									</span>
								</li>
							</ul>
						</div>
					</IonCol>
				</IonRow>
			</IonGrid>
		</div>
	);
}
