import { baseUrl } from "config/apiUrl";
import SuperFetch from "config/SuperFetch";

const Notification = {
  // * New APIs v2.0:
  getNewNotificationList: (page = 1, buyerId, language = "EN", limit = 12) => {
    return SuperFetch(
      `${baseUrl.rtc}/inapp/notification/unread-notification-list/?user_type=buyer&user_id=${buyerId}&page=${page}&limit=${limit}&country=${language}`
    );
  },
  setNewReadNotification: (notificationId) => {
    return SuperFetch(
      `${baseUrl.rtc}/inapp/notification/read/${notificationId}/?user_type=buyer`,
      {
        method: "PUT",
      }
    );
  },
  getNewUnreadCount: (id, language = "EN") => {
    return SuperFetch(
      `${baseUrl.rtc}/inapp/notification/unread-notification-count/?user_type=buyer&user_id=${id}&country=${language}`
    );
  },
};

export default Notification;
