import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCategory } from "redux/container/accountSlice";

export default function CategoryList({ categoryList }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	function getQueryParams(search) {
		const params = new URLSearchParams(search);
		return {
			edit: params.get("edit"),
			id: params.get("id"),
		};
	}
	return (
		<div>
			{categoryList.length > 0 ? (
				categoryList.map((item, i) => {
					return (
						<div className='items-center justify-between' key={i}>
							<div
								className='flex items-center ease-in duration-100 hover:bg-e6e6e6 mb-[5px] py-[5px] px-4 cursor-pointer'
								onClick={() => {
									dispatch(setCategory(item));
									if (
										getQueryParams(location.search)?.edit === "true"
									) {
										navigate(
											`${
												location.pathname.split("/")[2] ===
												"expense"
													? `/expense-edit/${
															getQueryParams(location.search)?.id
													  }`
													: `/revenue-edit/${
															getQueryParams(location.search)?.id
													  }`
											} `
										);
									} else {
										navigate(
											`${
												location.pathname.split("/")[2] ===
												"expense"
													? "/expense-form"
													: "/revenue-form"
											} `
										);
									}
								}}>
								{item?.images?.length ? (
									<div className='h-10 w-10 rounded-full overflow-hidden'>
										<img src={item?.images[0]} alt='logo'></img>
									</div>
								) : (
									<div className='h-10 w-10 flex justify-center items-center bg-black-333 rounded-full'>
										<h3 className='font-bold text-24 text-white'>
											{item?.title[0].toUpperCase()}
										</h3>
									</div>
								)}

								<div className='ml-4'>
									<h4 className='text-14 text-black-1000 font-bold pb-[2px]'>
										{item?.title}
									</h4>
									<p className='text-10 text-black-500'>
										{item?.owner_name}
									</p>
								</div>
							</div>
						</div>
					);
				})
			) : (
				<div
					className='text-center flex items-center justify-center'
					style={{ height: "calc(100vh - 420px)" }}>
					<div>
						<img
							className='m-auto mb-[10px] mt-5'
							src='/assets/images/not-found-file.png'
							alt='emptyCard'
						/>
						<p className='text-16 font-normal text-222222 mb-[35px]'>
							{ekkLanguage.orderList.notFound}
						</p>
					</div>
				</div>
			)}
		</div>
	);
}
