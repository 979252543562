import React from "react";
import CardTitle from "./CardTitle";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./style.scss";
import { useSelector } from "react-redux";
import { IonCol, IonRow, IonSpinner } from "@ionic/react";
import { useNavigate } from "react-router-dom";
import { BUSINESS_TYPE_INFO } from "data/businessTypeImage";
import Pin from "assets/images/icon/svg/Pin";
import { digitChanger, numberWithCommas, stringCount } from "helpers/helper";
import StartMini from "assets/images/icon/svg/StartMini";
import { getLanguage } from "config/language";

export default function NearSupplierCard({ slideData }) {
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const appLanguage = getLanguage();
  const navigate = useNavigate();

  return (
    <div className="home-card ">
      <CardTitle
        title={ekkLanguage.home.nearSuppliersLabel}
        link="/seller"
        icon="assets/images/default-supplier.png"
      />
      <div>
        {slideData ? (
          <>
            {slideData.length > 0 ? (
              <Swiper
                spaceBetween={16}
                breakpoints={{
                  260: {
                    slidesPerView: 1,
                  },
                  320: {
                    slidesPerView: 3.5,
                  },
                  360: {
                    slidesPerView: 3.5,
                  },
                  575: {
                    slidesPerView: 3.5,
                  },
                  768: {
                    slidesPerView: 4.5,
                  },
                }}
              >
                {slideData.map((el, i) => {
                  // let businessType = () =>
                  // 	BUSINESS_TYPE_INFO.find(
                  // 		(cur) => cur.type === el?.business_type?.title
                  // 	);
                  return (
                    <SwiperSlide key={i}>
                      <div className="relative overflow-hidden">
                        <div className="relative  mini-image-sizer rounded-[10px] flex pb-[5px] m-auto">
                          <img
                            onClick={() =>
                              navigate(`/supplier-details/${el?.id}`)
                            }
                            src={
                              el?.images?.length
                                ? el?.images[0]
                                : "assets/images/default-supplier.png"
                            }
                            alt="productImage"
                            className="w-full h-full object-contain m-auto"
                          />
                        </div>

                        <p className="w-full font-bold text-[11px] text-262626-1000 mb-[5px] min-h-[25px]">
                          {stringCount(el?.business_name, 30)}
                        </p>
                        <p className="font-normal text-10 text-gray-700 ml-[1px]">
                          {digitChanger(el?.publish_products_count)}{" "}
                          {appLanguage === "bn"
                            ? "টি পণ্য"
                            : appLanguage === "vn"
                            ? "Sản phẩm"
                            : appLanguage === "my"
                            ? "Produk"
                            : "Products"}
                        </p>
                        <div className="pt-[5px] flex justify-between items-center mr-[px]">
                          <div className="flex items-center">
                            <Pin />
                            <span className="text-primary text-10 font-bold ml-[1px]">
                              {digitChanger(numberWithCommas(el?.distance))}{" "}
                              {ekkLanguage.home.km}
                            </span>
                          </div>
                          {/* <div className="flex items-center">
                            <StartMini />
                            <span className="font-normal text-10 text-black-1000 ml-[1px]">
                              {digitChanger(el?.products_count)}
                            </span>
                          </div> */}
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            ) : (
              <IonRow>
                <IonCol>
                  <div className="text-center">
                    <img
                      className="m-auto mb-[10px] mt-5"
                      src="/assets/images/not-found-file.png"
                      alt="emptyCard"
                    />
                    <p className="text-14 font-semibold text-black-500 mb-[35px]">
                      {ekkLanguage.home.mySupplierNotFound}
                    </p>
                  </div>
                </IonCol>
              </IonRow>
            )}
          </>
        ) : (
          <div className="flex items-center justify-center h-[156px] m-auto">
            {" "}
            <IonSpinner name="crescent"></IonSpinner>
          </div>
        )}
      </div>
    </div>
  );
}
