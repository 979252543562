import { baseUrl } from "config/apiUrl";
import SuperFetch from "config/SuperFetch";

const Accounts = {
	getCategory: (typeId) => {
		return SuperFetch(
			`${baseUrl.account}/category/?acc_group=${typeId}&page=1&limit=100`
		);
	},
	getCategorySearch: (q, typeId) => {
		return SuperFetch(
			`${baseUrl.account}/category/?title=${q}&acc_group=${typeId}&page=1&limit=100`
		);
	},
	getAccountGroup: (q) => {
		return SuperFetch(`${baseUrl.account}/acc-group/`);
	},
	getAccountList: (id, start, end, sort = "-date", categoryId, page = 1, limit = 10) => {
		if (!start & !end) {
			return SuperFetch(
				`${baseUrl.account}/transaction/?acc_group=${id}&sort=${sort}&page=${page}&limit=${limit}${categoryId ? `&category_id=${categoryId}` : ''}`
			);
		} else {
			return SuperFetch(
				`${baseUrl.account}/transaction/?sort=${sort}&start_date=${start}&end_date=${end}&acc_group=${id}&page=${page}&limit=${limit}${categoryId ? `&category_id=${categoryId}` : ''}`
			);
		}
	},
	getAccountListSearch: (id, query, start, end, page = 1, limit = 10) => {
		return SuperFetch(
			`${baseUrl.account}/transaction/?start_date=${start}&end_date=${end}&acc_group=${id}&q=${query}&page=${page}&limit=${limit}`
		);
	},
	getAccountType: (type, start, end) => {
		return SuperFetch(`${baseUrl.account}/acc-group/?title=${type}`);
	},

	createTransaction: (query) => {
		return SuperFetch(`${baseUrl.account}/transaction/`, {
			method: "POST",
			data: query,
		});
	},
	updateTransaction: (id, query) => {
		return SuperFetch(`${baseUrl.account}/transaction/${id}/`, {
			method: "PUT",
			data: query,
		});
	},
	getTransaction: (id) => {
		return SuperFetch(`${baseUrl.account}/transaction/${id}/`);
	},
	getTodayTransaction: (timezone) => {
		return SuperFetch(
			`${baseUrl.account}/today-transaction/?timezone=${timezone}`
		);
	},
	getTransactionDate: (typeId, start, end) => {
		return SuperFetch(
			`${baseUrl.account}/transaction-chart/?acc_group=${typeId}&start_date=${start}&end_date=${end}`
		);
	},
	getExpenseData: (timezone) => {
		return SuperFetch(
			`${baseUrl.account}/transaction-expense-chart/?&timezone=${timezone}`
		);
	},
	getRevenueData: (timezone) => {
		return SuperFetch(
			`${baseUrl.account}/transaction-revenue-chart/?&timezone=${timezone}`
		);
	},
	getEmployeeRevenueData: (timeZone, start, end) => {
		return SuperFetch(
			`${baseUrl.account}/employee-revenue-chart/?start_date=${start}&end_date=${end}&timezone=${timeZone}`
		);
	},
	getEmployeeHoursData: (start, end) => {
		return SuperFetch(
			`${baseUrl.contract}/Ekkbaz/employee-working-hour/?start_date=${start}&end_date=${end}`
		);
	},
	getCombineTransactionDate: (timezone, start, end) => {
		return SuperFetch(
			`${baseUrl.account}/rev-exp-chart/?timezone=${timezone}`
		);
	},
	getProfitLoss: (start, end) => {
		return SuperFetch(
			`${baseUrl.account}/transaction-profit-loss/?start_date=${start}&end_date=${end}`
		);
	},
	deleteItem: (id) => {
		return SuperFetch(`${baseUrl.account}/transaction/${id}/`, {
			method: "DELETE",
		});
	},
	getDashboardTransactions: (timeZone) => {
		return SuperFetch(
			`${baseUrl.account}/dashboard-net-profit-chart/?timezone=${timeZone}`
		);
	},
	getDelayList: (type, isPaid, page = 1, limit = 100) => {
		return SuperFetch(
			`${baseUrl.account}/paid-unpaid-transaction/?acc_group=${type}&page=${page}&limit=${limit}&is_paid=${isPaid}`
		);
	},
	// getDeuPaymentDate: (typeId, start, end) => {
	// 	return SuperFetch(
	// 		`${baseUrl.account}/paid-unpaid-dashboard-transaction-chart/?is_paid=False&acc_group=${typeId}&start_date=${start}&end_date=${end}`
	// 	);
	// },
	// getDeuPaymentDate: (typeId, timezone) => {
	// 	return SuperFetch(
	// 		`${baseUrl.account}/paid-unpaid-monthly-dashboard-transaction-chart/?is_paid=False&acc_group=${typeId}&timezone=Asia/Dhaka`
	// 	);
	// },
	getDeuPaymentDate: (typeId, timezone) => {
		return SuperFetch(
			`${baseUrl.account}/Ekkbaz/paid-unpaid-monthly-dashboard-future-transaction-chart/?is_paid=False&acc_group=${typeId}&timezone=${timezone}`
		);
	},
	getTransactionDateWays: (typeId, start, end, page = 1, limit = 100) => {
		return SuperFetch(
			`${baseUrl.account}/transaction-chart-details/?acc_group=${typeId}&start_date=${start}&end_date=${end}&page=${page}&limit=${limit}`
		);
	},
	getRevenueDate: (start, end, page = 1, limit = 30) => {
		return SuperFetch(
			`${baseUrl.account}/Ekkbaz/category-revenue/?start_date=${start}&end_date=${end}`
		);
	},
	getDuePaymentDateWays: (typeId, start, end, page = 1, limit = 100) => {
		return SuperFetch(
			`${baseUrl.account}/transaction-chart-details/?acc_group=${typeId}&start_date=${start}&end_date=${end}&paid_date=True&is_paid=False&page=${page}&limit=${limit}`
		);
	},
	// getProfitLossDateWays: (start, end, page = 1, limit = 30) => {
	// 	return SuperFetch(
	// 		`${baseUrl.account}/transaction-chart-details/?start_date=${start}&end_date=${end}&page=${page}&limit=${limit}`
	// 	);
	// },
	getProfitLossDateWays: (start, end, timeZone) => {
		return SuperFetch(
			`${baseUrl.account}/Ekkbaz/category-profit-loss/?start_date=${start}&end_date=${end}&timezone=${timeZone}`
		);
	},
	getExpenseDateWays: (start, end, timeZone) => {
		return SuperFetch(
			`${baseUrl.account}/Ekkbaz/category-profit-loss-expense/?start_date=${start}&end_date=${end}&timezone=${timeZone}`
		);
	},
	getTaxDateWays: (start, end, timeZone) => {
		return SuperFetch(
			`${baseUrl.account}/Ekkbaz/alltaxes/?start_date=${start}&end_date=${end}&timezone=${timeZone}`
		);
	},
	getDepreciationDateWays: (start, end, timeZone) => {
		return SuperFetch(
			`${baseUrl.account}/Ekkbaz/category-depreciation/?start_date=${start}&end_date=${end}&timezone=${timeZone}`
		);
	},
	getNetProfitDateWays: (start, end, timeZone) => {
		return SuperFetch(
			`${baseUrl.account}/Ekkbaz/net-profit/?start_date=${start}&end_date=${end}&timezone=${timeZone}`
		);
	},
	getTaxData: (timeZone) => {
		return SuperFetch(
			`${baseUrl.account}/transaction-tax-chart/?timezone=${timeZone}`
		);
	},
	getProviderData: (timeZone) => {
		return SuperFetch(
			`${baseUrl.account}/transaction-service-amount-chart/?timezone=${timeZone}`
		);
	},
	getTaxDetails: (start, end, page = 1, limit = 100) => {
		return SuperFetch(
			`${baseUrl.account}/transaction-tax-details/?start_date=${start}&end_date=${end}&page=${page}&limit=${limit}`
		);
	},
	getProviderDetails: (start, end, page = 1, limit = 100) => {
		return SuperFetch(
			`${baseUrl.account}/transaction-service-amount-details/?start_date=${start}&end_date=${end}&page=${page}&limit=${limit}`
		);
	},

	depositAmount: (query) => {
		return SuperFetch(`${baseUrl.account}/account-deposit/`, {
			method: "POST",
			data: query,
		});
	},
	statusCheck: () => {
		return SuperFetch(`${baseUrl.contract}/last-visit/`);
	},
	checkIn: (query) => {
		return SuperFetch(`${baseUrl.contract}/visit/`, {
			method: "POST",
			data: query,
		});
	},
	checkOut: (id, query) => {
		return SuperFetch(`${baseUrl.contract}/visit/${id}/`, {
			method: "PUT",
			data: query,
		});
	},
	employeeCheck: (query) => {
		return SuperFetch(`${baseUrl.contract}/admin-visit/`, {
			method: "POST",
			data: query,
		});
	},
	
	getProfitLossPdf: (start, end, timeZone) => {
		return SuperFetch(
			`${baseUrl.contract}/Ekkbaz/profit-loss-statement-pdf/?start_date=${start}&end_date=${end}&timezone=${timeZone}`
		);
	},
	getDepositData: () => {
		return SuperFetch(`${baseUrl.account}/Ekkbaz/deposit-dashboard-chart`);
	},
	getDepositDataDetails: (start, end, timeZone, page = 1, limit = 100) => {
		return SuperFetch(
			`${baseUrl.account}/Ekkbaz/deposit-dashboard-details/?start_date=${start}&end_date=${end}&timezone=${timeZone}&page=${page}&limit=${limit}`
		);
	},
	getRevenueExpenseDate: (start, end, id, page = 1, limit = 30) => {
		return SuperFetch(
			`${baseUrl.account}/Ekkbaz/category-revenue/?start_date=${start}&end_date=${end}&user_id=${id}`
		);
	},
	getCategoryList: (id) => {
		return SuperFetch(
			`${baseUrl.account}/category/?acc_group=${id}&page=1&limit=100`
		);
	},
	createCategoryList: (query) => {
		return SuperFetch(`${baseUrl.account}/Ekkbaz/accounting-settings/`, {
			method: "POST",
			data: query,
		});
	},
	updateCategoryList: (id, query) => {
		return SuperFetch(
			`${baseUrl.account}/Ekkbaz/accounting-settings/${id}/`,
			{
				method: "PUT",
				data: query,
			}
		);
	},
	getSelectedCategoryList: () => {
		return SuperFetch(`${baseUrl.account}/Ekkbaz/accounting-settings/`);
	},
};
export default Accounts;
