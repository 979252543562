import React from "react";

export default function Branch({ employees }) {
  return (
    <div className="bg-white">
      <div
        className="px-4 py-[10px]"
        style={{ borderBottom: "1.5px solid rgba(0, 0, 0, 0.1)" }}
      >
        <h6 className="font-bold text-16">Branch</h6>
      </div>
      {employees?.branch_associations?.length
        ? employees?.branch_associations.map((employee) => {
            return (
              <div className="py-[15px] px-4 flex items-center">
                <div className="mr-4 h-10 w-10 bg-F2F3F7 rounded-full flex items-center justify-center">
                  <img
                    className="h-[24px] w-[24px]"
                    src="/assets/images/employ_icon.png"
                    alt="ekkbaz icon"
                  />
                </div>
                <div>
                  <h6 className="font-semibold text-14 text-black-1000">
                    {employee.branch.business_name}
                  </h6>
                  <p className="text-10 text-black-500 mb-[5px]">
                    {employee.branch.business_type}
                  </p>
                  <p className="text-10 text-black-500"> {employee.role}</p>
                </div>
              </div>
            );
          })
        : ""}
    </div>
  );
}
