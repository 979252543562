import React from "react";

export default function EditPen() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='12'
			height='12'
			viewBox='0 0 12 12'
			fill='none'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M4.46957 10.6829L9.59496 4.05484C9.87351 3.69741 9.97255 3.28417 9.8797 2.86341C9.79923 2.4809 9.56401 2.1172 9.21117 1.84129L8.35075 1.15779C7.60175 0.562073 6.67324 0.62478 6.14089 1.30828L5.56521 2.05512C5.49093 2.14855 5.5095 2.28651 5.60235 2.36176C5.60235 2.36176 7.05702 3.5281 7.08797 3.55318C7.18701 3.64724 7.26129 3.77266 7.27986 3.92315C7.31081 4.21788 7.10654 4.49379 6.80323 4.53141C6.66086 4.55022 6.52467 4.50633 6.42563 4.42481L4.89668 3.2083C4.8224 3.15249 4.71098 3.1644 4.64908 3.23965L1.0155 7.94266C0.780274 8.23738 0.699803 8.61989 0.780274 8.98986L1.24453 11.0027C1.26929 11.1093 1.36214 11.1846 1.47356 11.1846L3.51629 11.1595C3.8877 11.1532 4.23434 10.9839 4.46957 10.6829ZM7.32982 10.0561H10.6607C10.9857 10.0561 11.25 10.3238 11.25 10.653C11.25 10.9829 10.9857 11.25 10.6607 11.25H7.32982C7.00484 11.25 6.74052 10.9829 6.74052 10.653C6.74052 10.3238 7.00484 10.0561 7.32982 10.0561Z'
				fill='#808080'
			/>
		</svg>
	);
}
