import { IonCol, IonRow, IonSpinner } from "@ionic/react";
import { digitChanger, numberWithCommas, translator } from "helpers/helper";
import React from "react";
import { useSelector } from "react-redux";

export default function PurchaseSummary({ todayPurchase, orderAnalytics }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);

	return (
		<div className='purchase-summary pt-3 pb-5 relative'>
			<ion-grid>
				<IonRow>
					<IonCol size='6'>
						<div
							className='text-center rounded-[10px] bg-white p-[10px]'
							style={{
								boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)",
							}}>
							<p className='text-12 text-black-1000 font-bold mb-[10px]'>
								{ekkLanguage.home.todayPurchaseLabel}
							</p>

							<p className='text-12 text-success font-extrabold mb-[10px]'>
								{todayPurchase ? (
									<span>
										{digitChanger(todayPurchase?.total_orders)}{" "}
										{ekkLanguage.home.totalOrder}
									</span>
								) : (
									<IonSpinner color='green'></IonSpinner>
								)}
							</p>
							<h5 className='font-bold text-success text-18'>
								{todayPurchase?.currency &&
									translator(todayPurchase?.currency)}{" "}
								{todayPurchase &&
									digitChanger(
										numberWithCommas(todayPurchase?.total_amount)
									)}
							</h5>
						</div>
					</IonCol>
					<IonCol size='6'>
						<div
							className='text-center rounded-[10px] bg-white p-[10px]'
							style={{
								boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)",
							}}>
							<p className='text-12 text-black-1000 font-bold mb-[10px]'>
								{ekkLanguage.home.tillNow}
							</p>

							<p className='text-12 text-primary font-extrabold mb-[10px]'>
								{orderAnalytics ? (
									<span>
										{digitChanger(orderAnalytics?.total_orders)}{" "}
										{ekkLanguage.home.orderDue}
									</span>
								) : (
									<IonSpinner color='red'></IonSpinner>
								)}
							</p>
							<h5 className='font-bold text-primary text-18'>
								{orderAnalytics?.currency &&
									translator(orderAnalytics?.currency)}{" "}
								{orderAnalytics &&
									digitChanger(
										numberWithCommas(orderAnalytics?.total_due)
									)}
							</h5>
						</div>
					</IonCol>
				</IonRow>
			</ion-grid>
		</div>
	);
}
