import { getLanguage } from "config/language";
import { getUserBusiness } from "config/user";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";

export default function TopMenu() {
	let navigate = useNavigate();
	const appLanguage = getLanguage();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const business = getUserBusiness();

	let menuList = [
		{
			id: 1,
			image: "/assets/images/dashboard-icon.png",
			title: ekkLanguage.menu.dashboard,
			link: "/accounts-dashboard",
			permission: ["Admin"],
		},
		{
			id: 2,
			image: "/assets/images/accounting.png",
			title: 	ekkLanguage.menu.accounting,
			link: "/accounts",
			permission: ["Admin"],
		},
		{
			id: 3,
			image: "/assets/images/employee-icon.png",
			title: ekkLanguage.employees.pageTitle,
			link: "/employees-list",
			permission: ["Admin"],
		},
		{
			id: 4,
			image: "/assets/images/products-icon.png",
			title: ekkLanguage.menu.products,
			link: "/products",
			permission: ["all"],
		},
		{
			id: 5,
			image: "/assets/images/procurement-icon.png",
			title: ekkLanguage.menu.procurement,
			link: "/order-list",
			permission: ["all"],
		},
		{
			id: 6,
			image: "/assets/images/icon-supplier-options.png",
			title: ekkLanguage.navFooter.supplier,
			link: "/seller",
			permission: ["all"],
		},
		{
			id: 7,
			image: "/assets/images/loan-icon.png",
			title: ekkLanguage.menu.loan,
			link: "/loan",
			permission: ["all"],
		},
		{
			id: 8,
			image: "/assets/images/menu/learning-c.png",
			title: ekkLanguage.navFooter.learning,
			link: "/learning",
			permission: ["all"],
		},
	];

	const filteredMenuList = menuList?.filter((item) => {
		if (business?.role === "Admin") {
			return true;
		}
		return item.permission.includes("all");
	});

	return (
		<div className='product-slider'>
			<Swiper
				spaceBetween={0}
				slidesPerView={2.5}
				grabCursor={true}
				breakpoints={{
					260: {
						slidesPerView: 2,
					},
					320: {
						slidesPerView: 3,
					},
					360: {
						slidesPerView: 4.5,
					},
					575: {
						slidesPerView: 5.5,
					},
					768: {
						slidesPerView: 5.5,
					},
				}}>
				{filteredMenuList?.map((el, i) => {
					return (
						<>
							{" "}
							<SwiperSlide key={i} onClick={() => navigate(el.link)}>
								<div className='text-center'>
									<div
										className='rounded-full h-[50px] w-[50px] flex m-auto'
										style={{
											background: "rgba(0, 178, 36, 0.15)",
											boxShadow:
												"0px 5px 20px 0px rgba(0, 0, 0, 0.05)",
										}}>
										<img
											src={el?.image}
											className='m-auto'
											alt='icon'
										/>
									</div>
									<p className='text-12 font-semibold mt-[7px] text-262626-1000'>
										{el?.title}
									</p>
								</div>
							</SwiperSlide>
						</>
					);
				})}
			</Swiper>
		</div>
	);
}
