import { IonLoading } from "@ionic/react";
import EmploysList from "./EmploysList";
import EmploysSearch from "./EmploysSearch";
import Search from "../Common/Search";

export default function Employees({
  employees,
  empLoading,
  employeesSearch,
  isForSelect,
  tempSearchParams,
  setTempSearchParams,
  searchQ,
  setSearchQ,
  ekkLanguage,
}) {
  return (
    <div className="px-2 pt-14">
      {/* <EmploysSearch employeesSearch={employeesSearch} /> */}
      {/* <div className="px-2 pt-4">
        <Search
          search={searchQ}
          setSearch={setSearchQ}
          ekkLanguage={ekkLanguage}
          placeholder={ekkLanguage.employees.searchPlaceHolder}
          singleSearch={employeesSearch}
          filter={false}
          setTempSearchParams={setTempSearchParams}
        />
      </div>

      <p className="px-4 pt-2">
        {" "}
        {tempSearchParams && (
          <span>
            search: <span className="font-bold"> {tempSearchParams}</span>
          </span>
        )}
      </p> */}
      {empLoading ? (
        <IonLoading isOpen={true}></IonLoading>
      ) : (
        <EmploysList
          employees={employees}
          empLoading={empLoading}
          isForSelect={isForSelect}
        />
      )}
    </div>
  );
}
