import React, { useEffect, useState } from "react";
import {
	IonApp,
	IonCol,
	IonContent,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonLoading,
	IonPage,
	IonRow,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import { useDispatch, useSelector } from "react-redux";
import RecommendProduct from "components/mobile-components/Home/RecommendProduct";
import Product from "services/Product";
import SingleRecommendProduct from "components/mobile-components/Home/SingleRecommendProduct";
import StarBig from "assets/images/icon/svg/StarBig";
import { digitChanger, numberWithCommas } from "helpers/helper";
import { useNavigate } from "react-router-dom";
import NavFooter from "components/mobile-components/Common/NavFooter";
import TitleNSearch from "components/mobile-components/Common/TitleNSearch";
import StockOutMini from "assets/images/icon/svg/StockOutMini";
import Departments from "components/mobile-components/Home/Departments";
import Department from "services/Department";
import BrandCard2 from "components/mobile-components/Home/BrandCard2";
import Brand from "services/Brand";

export default function RecommendedProductPage() {
	const [pageCount, setPageCount] = useState(2);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [loading, setLoading] = useState(true);
	const [searchQ, setSearchQ] = useState("");
	const dispatch = useDispatch();
	const [products, setProduct] = useState([]);
	const [productCount, setProductCount] = useState();
	const navigate = useNavigate();
	const [totalCount, setTotalCount] = useState();
	const [hasMoreData, setHasMoreData] = useState(true);
	const [reachedEnd, setReachedEnd] = useState(false);
	const [departmentList, setDepartmentList] = useState([]);
	const [brandData, setBrandData] = useState("");
	const [cardLoading, setCardLoading] = useState(false);

	let getProductList = async () => {
		let response = await Product.productList();
		if (response.status === 200) {
			setLoading(false);
			setProduct(response.results);
			setProductCount(response.count);
		} else {
			setLoading(false);
		}
	};
	useEffect(() => {
		getProductList();
	}, []);

	let getTermToProduct = async () => {
		setPageCount(2);
		setLoading(true);
		let response = await Product.productSearch(searchQ);
		if (response.success) {
			setLoading(false);
			setProduct(response?.data);
			setTotalCount(response?.meta?.count);
		} else {
			setLoading(false);
		}
	};

	let loadMoreData = async () => {
		if (hasMoreData) {
			if (searchQ) {
				let response = await Product.productSearch(searchQ, pageCount);
				if (response.status_code === 200 && response.data.length > 0) {
					setLoading(false);
					setPageCount((prev) => prev + 1);
					setProduct([...products, ...response.data]);
					setTotalCount(response.meta.count);
				} else {
					// If no more data, update the state variable
					setHasMoreData(false);
				}
			} else {
				let response = await Product.productList(pageCount);
				console.log(response);
				if (response.status && response.results.length > 0) {
					setLoading(false);
					setProduct([...products, ...response.results]);
					setProductCount(response.count);
					setPageCount((prev) => prev + 1);
				} else {
					// If no more data, update the state variable
					setHasMoreData(false);
				}
			}
		}
	};

	const deleteQueryHandler = async (e) => {
		getProductList();
		setSearchQ("");
	};
	let getDepartments = async () => {
		let response = await Department.getDepartments();
		if (response.status === 200) {
			setDepartmentList(response.results);
		} else {
			setDepartmentList([]);
		}
	};
	useEffect(() => {
		getDepartments();
	}, []);

	let getBrandList = async () => {
		setCardLoading(true);
		let response = await Brand.getBrandList();
		if (response.status === 200) {
			setCardLoading(false);
			setBrandData(response.results);
		} else {
			setCardLoading(false);
		}
	};
	useEffect(() => {
		getBrandList();
	}, []);

	return (
		<IonApp>
			<IonPage>
				<IonContent scrollEvents={true}>
					{/* <BackButtonTitle title={ekkLanguage.home.recommendentLabel} /> */}
					<TitleNSearch
						title={ekkLanguage.menu.products}
						placeholder={ekkLanguage.home.searchLabel}
						searchQ={searchQ}
						setSearchQ={setSearchQ}
						fetchSearchData={getTermToProduct}
						initData={getProductList}
						deleteQuery={deleteQueryHandler}
						inputType='text'
					/>
					<section className='pt-14 px-2'>
						<div className='pt-5 pb-7'>
							<Departments departmentList={departmentList} />
						</div>
						<div className='mb-7'>
							{brandData?.length > 0 && (
								<BrandCard2
									slideData={brandData}
									loading={cardLoading}
								/>
							)}
						</div>

						{/* <ProductSearch
							searchQ={searchQ}
							setSearchQ={setSearchQ}
							placeholder={settings?.placeHolder}
							itemsSearch={itemsSearch}
						/>
						<p className='px-4 pb-4'>
							{tempSearchParams && (
								<span>
									search:{" "}
									<span className='font-bold'>{tempSearchParams}</span>
								</span>
							)}
						</p> */}
						{loading ? (
							<IonLoading
								isOpen={loading}
								message={ekkLanguage.orderList.loader}
							/>
						) : (
							<div className='pt-3'>
								{" "}
								<IonRow>
									{products?.length ? (
										products.map((el, i) => {
											let isImageLength = () => {
												if (el?.images?.length) {
													return `${el?.images[0]}`;
												} else {
													if (el?.type === "variant") {
														return "/assets/images/varient_default.png";
													} else if (el?.type === "pack") {
														return "/assets/images/pack_default.png";
													} else if (el?.type === "offer") {
														return "/assets/images/offer_default.png";
													}
												}
											};
											return (
												<IonCol
													size='6'
													sizeSm='4'
													className='mb-6'
													key={i}>
													<div className='relative overflow-hidden'>
														<div
															className='text-center flex items-center '
															onClick={() =>
																navigate(
																	`/product-details/${el?.id}`
																)
															}>
															<div className='product-image-sizer relative rounded-[10px] flex pb-[5px] m-auto'>
																{el?.inventory_quantity <=
																	0 && (
																	<div className='flex absolute w-[44px] h-[38px] border-2 border-white bg-primary items-center justify-center rounded-br-[10px] rounded-tl-[10px] top-[-2px] left-0 border-l-0'>
																		<StockOutMini />
																	</div>
																)}
																{Number(el?.profit) > 0 && (
																	<div className='flex absolute w-auto h-[24px] border-2 border-white bg-success items-center justify-center rounded-[10px]  top-0 right-0'>
																		<span className='ml-[5px] font-bold text-12 text-white px-1'>
																			{el?.country &&
																				el?.country
																					?.currency_symbol_native}
																			{digitChanger(
																				numberWithCommas(
																					el?.profit
																				)
																			)}
																		</span>
																	</div>
																)}

																<img
																	className='w-full h-full object-contain flex m-auto mb-[5px]'
																	src={isImageLength()}
																	alt='icon'
																/>
															</div>
														</div>
														<h5 className='font-bold text-14 text-primary mb-[5px]'>
															{el?.country &&
																el?.country
																	?.currency_symbol_native}
															{digitChanger(
																numberWithCommas(el?.base_price)
															)}
															{/* <span className='text-10 font-normal text-808080 ml-2 line-through'>
																{el?.country &&
																	el?.country
																		?.currency_symbol_native}
																{digitChanger(
																	response?.sub_total(
																		el?.base_price + 10
																	)
																)}
															</span> */}
														</h5>
														<h6 className='font-semibold text-14 text-262626-1000 mb-[5px] min-h-[36px]'>
															{el?.name}
														</h6>
														<div className='flex justify-between items-center mr-4'>
															<div>
																<h6 className='font-semibold text-14 text-black-500'>
																	{el?.weight}{" "}
																	<span>
																		{el?.weight_unit}
																	</span>
																</h6>
															</div>
															{/* <div className='flex'>
																<StarBig />
																<span className='text-14 text-black-1000 ml-1'>
																	3.7
																</span>
															</div> */}
														</div>
													</div>
												</IonCol>
											);
										})
									) : (
										<>
											<IonCol size='2'></IonCol>
											<IonCol size='8'>
												<div
													className='text-center flex items-center justify-center'
													style={{
														height: "calc(100vh - 420px)",
													}}>
													<div>
														<img
															className='m-auto mb-[10px] mt-5'
															src='/assets/images/not-found-file.png'
															alt='emptyCard'
														/>
														<p className='text-16 font-normal text-222222 mb-[35px]'>
															{ekkLanguage.orderList.notFound}
														</p>
													</div>
												</div>
											</IonCol>
										</>
									)}
								</IonRow>
							</div>
						)}
					</section>
					<IonInfiniteScroll
						onIonInfinite={(ev) => {
							if (reachedEnd) {
								ev.target.complete();
								return;
							}
							loadMoreData();
							setTimeout(() => {
								ev.target.complete();
								if (!hasMoreData) {
									setReachedEnd(true);
								}
							}, 500);
						}}>
						<IonInfiniteScrollContent
							loadingText={ekkLanguage.orderList.loader}
							loadingSpinner={
								hasMoreData ? "bubbles" : null
							}></IonInfiniteScrollContent>
					</IonInfiniteScroll>
				</IonContent>
				{/* <NavFooter /> */}
			</IonPage>
		</IonApp>
	);
}
