import {
	IonApp,
	IonButton,
	IonCol,
	IonContent,
	IonGrid,
	IonPage,
} from "@ionic/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { setPaymentMethodText } from "redux/container/paymentMethodSlice";

export default function PreOrderSuccessPage() {
	let { id } = useParams();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let dispatch = useDispatch();
	const paymentMethod = useSelector(
		(state) => state.payment.paymentMethodText
	);
	const orderNumber = useSelector(
		(state) => state.order?.singleOrder?.order_id
	);

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<IonGrid>
						<IonCol size='12'>
							<div className='flex items-center justify-center text-center h-screen px-2'>
								<div>
									<img
										src='/assets/images/winner.png'
										alt='congregations'
										className='inharit m-auto'
									/>
									<h2 className='text-30 text-success font-bold mb-2 pt-3'>
										{ekkLanguage.createOrder.cosgressTitle}
									</h2>
									<p className='text-16 text-black-500 font-semibold mb-5'>
										{ekkLanguage.createOrder.preOrderCongress}
									</p>

									<Link
										to='/home'
										className='w-full'
										onClick={() =>
											dispatch(setPaymentMethodText(""))
										}>
										<IonButton
											className='rounded-[10px] h-12 w-full text-white font-bold bg-primary'
											style={{
												boxShadow:
													"0px 5px 15px rgba(0, 0, 0, 0.2)",
											}}>
											{ekkLanguage.createOrder.congressProductLabel}
										</IonButton>
									</Link>
								</div>
							</div>
						</IonCol>
					</IonGrid>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
