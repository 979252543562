import React from "react";

export default function Filter() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			viewBox='0 0 20 20'
			fill='none'
			style={{ margin: "auto" }}>
			<path
				d='M11.6662 20C11.4859 20 11.3105 19.9415 11.1662 19.8333L7.83291 17.3333C7.72941 17.2557 7.64541 17.1551 7.58755 17.0393C7.5297 16.9236 7.49958 16.796 7.49958 16.6667V11.9833L1.65291 5.40583C1.23768 4.9374 0.96656 4.35901 0.872144 3.7402C0.777727 3.12138 0.86403 2.48846 1.12068 1.91751C1.37733 1.34656 1.7934 0.861882 2.31889 0.521721C2.84439 0.181561 3.45693 0.000395349 4.08291 0L15.9162 0C16.5422 0.000734209 17.1546 0.182199 17.6798 0.522589C18.2051 0.862978 18.6209 1.34781 18.8773 1.91882C19.1337 2.48983 19.2197 3.12273 19.1251 3.74146C19.0304 4.36018 18.7591 4.93841 18.3437 5.40667L12.4996 11.9833V19.1667C12.4996 19.3877 12.4118 19.5996 12.2555 19.7559C12.0992 19.9122 11.8873 20 11.6662 20ZM9.16624 16.25L10.8329 17.5V11.6667C10.8331 11.4626 10.9081 11.2657 11.0437 11.1133L17.1004 4.29917C17.3024 4.0709 17.4342 3.7892 17.4799 3.48788C17.5257 3.18655 17.4836 2.87842 17.3586 2.60046C17.2335 2.32251 17.0309 2.08655 16.7751 1.92091C16.5192 1.75527 16.221 1.66699 15.9162 1.66667H4.08291C3.77829 1.66713 3.48026 1.75546 3.22458 1.92105C2.9689 2.08665 2.76642 2.3225 2.64142 2.60029C2.51642 2.87809 2.47421 3.18605 2.51985 3.48723C2.56549 3.78842 2.69705 4.07004 2.89874 4.29833L8.95624 11.1133C9.09159 11.2659 9.16631 11.4627 9.16624 11.6667V16.25Z'
				fill='white'
				fillOpacity='0.5'
			/>
		</svg>
	);
}
