import { IonCol, IonGrid, IonRow, IonSearchbar } from "@ionic/react";
import { useEffect, useState } from "react";

export default function EmploysSearch({ employeesSearch }) {
  const [text, setText] = useState("");

  const _handleKeyPress = (e) => {
    if (e.key === "Enter") {
      employeesSearch(text);
    }
  };
  useEffect(() => {
    if (!text) {
      employeesSearch("");
    }
  }, [text]);

  return (
    <IonGrid>
      <IonRow>
        <IonCol size="12">
          <div>
            <IonSearchbar
              className="p-0"
              type="text"
              placeholder="Search your employees"
              value={text}
              onKeyPress={_handleKeyPress}
              onIonChange={(e) => {
                setText(e.target.value);
              }}
            ></IonSearchbar>
          </div>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
}
