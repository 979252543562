import React, { useState } from "react";
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonItem,
	IonLabel,
	IonThumbnail,
	useIonLoading,
	IonLoading,
	IonApp,
} from "@ionic/react";
import {
	getLocalUser,
	setLocalUser,
	setUser,
	setUserBusiness,
} from "config/user";
import { useNavigate } from "react-router-dom";
import Auth from "services/Auth";
import { updateToken } from "../../config/SuperFetch";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import { BUSINESS_TYPE } from "data/businessType";
import { useSelector } from "react-redux";

export default function SelectBusiness() {
	const [business, setBusiness] = useState(getLocalUser());
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	async function businessSelectEvent(data) {
		try {
			if (data?.business_status !== "Black Listed") {
				setLoading(true);
				const response = await Auth.businessSelect(data);
				//   setBusiness(response?.business_info);
				setUserBusiness(response?.business_info);
				setUser(response?.user);
				localStorage.removeItem("setUser");
				//   setLocalUser({});
				updateToken(response);

				setLoading(false);
				window.location.href = "/home";
				// navigate("/home", { replace: true });
			} else {
				navigate("/black-listed");
			}
		} catch (error) {
			console.log(error);
		}
	}
	console.log(business);

	return (
		<IonApp>
			<IonPage className='business-name pt-14'>
				<IonContent>
					<BackButtonTitle title={ekkLanguage.businessSelect.pageTitle} />
					<div className='pb-4 pt-4'>
						<div className='px-4 pb-4'>
							<h4 className='font-bold'>{ekkLanguage.home.account}</h4>
						</div>
						{business?.associated_businesses.map((select) => {
							BUSINESS_TYPE.forEach((el) => {
								if (el.type === select.business_type) {
									select.img = el.image;
								}
							});
							return (
								<IonItem
									key={select.business_id}
									button
									onClick={() => {
										businessSelectEvent(select);
									}}
									lines='none'
									className='mb-4'>
									<div className='flex py-[5px] px-4'>
										<div className='w-12 h-12 p-2 flex justify-center items-center bg-F3F3F3 rounded-[10px] mr-5'>
											<img
												src={select.img}
												className='w-7 h-7'
												alt='busnissLogo'
											/>
										</div>
										<div>
											<h3 className='text-16 text-black-1000 font-bold'>
												{select?.business_name}
											</h3>
											<p className='text-12 text-black-500 font-normal'>
												{select?.business_type} -{" "}
												{select?.business_status} - {select.role}
											</p>
											<p className='text-12 text-black-500 font-normal'>
												{select?.business_address_liner}
											</p>
										</div>
									</div>
								</IonItem>
							);
						})}
					</div>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
