import React, { useState } from "react";
import "moment-timezone";
import { addStatusClass, digitChanger, statusTranslator } from "helpers/helper";
import moment from "moment";
import { getLanguage } from "config/language";

export default function OrderMetaBar({ order }) {
	let [language] = useState(getLanguage());
	return (
		<>
			<div
				className={` bg-${addStatusClass(
					order?.order_status
				)} fixed left-0 top-14 w-full z-50`}
			>
				<div className='p-4'>
					<ul className='flex justify-between'>
						<li>
							<p className='text-14 text-white font-bold'>
								{digitChanger(order?.order_id)}
							</p>
						</li>
						<li>
							<p className='text-14 text-white font-bold'>
								{language === "bn"
									? statusTranslator(order?.order_status)
									: order?.order_status}
								{/* {order?.order_status} */}
							</p>
						</li>
						<li>
							<p className='text-14 text-white font-bold'>
								{digitChanger(moment(order?.createdAt).format("DD/MM/YYYY"))}
							</p>
						</li>
					</ul>
				</div>

				{order?.order_status === "Placed" && (
					<div className='animated-gradient'></div>
				)}
			</div>
		</>
	);
}
