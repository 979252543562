import {
	IonCol,
	IonGrid,
	IonRow,
	IonSegment,
	IonSegmentButton,
} from "@ionic/react";
import React, { memo } from "react";
import { useDispatch } from "react-redux";
import { setLoanProductType } from "redux/container/productSlice";

function LoanStatusBtn({
	setOrderStatus,
	setReachedEnd,
	setHasMoreData,
	getAccountType,
	ekkLanguage,
}) {
	const dispatch = useDispatch();

	return (
		<>
			<IonGrid>
				<IonRow>
					<IonCol size='12'>
						<IonSegment
							value='loanRequest'
							scrollable
							className='orderBtnWrapper no-ripple'
							onIonChange={(e) => {
								setReachedEnd(false);
								setHasMoreData(true);
								setOrderStatus(e.detail.value);
								getAccountType(e.detail.value);
								dispatch(setLoanProductType(e.detail.value));
							}}>
							<IonSegmentButton
								value='loanRequest'
								className='paid relative'>
								<button className='text-paid font-sans p-0 font-normal shadow-none text-14'>
									{ekkLanguage.loan.myLoanRequest}
								</button>
							</IonSegmentButton>

							<IonSegmentButton
								value='loanProducts'
								className='cancel relative'>
								<button className='text-F94B41-1000 font-sans p-0 font-normal shadow-none text-14'>
									{ekkLanguage.loan.loanProviders}
								</button>
							</IonSegmentButton>
						</IonSegment>
					</IonCol>
				</IonRow>
			</IonGrid>
		</>
	);
}
export default memo(LoanStatusBtn);
