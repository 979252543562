import {
	IonApp,
	IonCol,
	IonContent,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonPage,
	IonSpinner,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import ProfitLossBottomSheet from "components/mobile-components/ProfitLoss/ProfitLossBottomSheet";
import { baseUrl } from "config/apiUrl";
import { getAuth } from "config/authFetch";
import { getUserBusiness } from "config/user";
import { COUNTRYINFO } from "data/country";
import { digitChanger, numberWithCommas } from "helpers/helper";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Accounts from "services/Accounts";

export default function ProfitLossPartsPage() {
	const location = useLocation();
	let [loading, setLoading] = useState(true);
	let [revenueData, setRevenueData] = useState([]);
	let [expenseData, setExpenseData] = useState(null);
	let [taxData, setTaxData] = useState(null);
	let [depreciationData, setDepreciationData] = useState(null);
	let [netProfitData, setNetProfitData] = useState(null);
	const { createUserData } = useSelector((state) => state.user);
	let getBusiness = getUserBusiness();

	const dateFormat = useSelector((state) => state.date.format);

	const searchParams = new URLSearchParams(location?.search);
	const date = useSelector((state) => state.dashboard.dateInfo);
	const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

	const [getDate, setGetDate] = useState({
		start: moment(searchParams.get("state")).format("YYYY-MM-DD"),
		end: moment(searchParams.get("end")).format("YYYY-MM-DD"),
	});

	// let start, end;
	// if (date?.start && date?.end) {
	// 	start = moment(date.start).format("YYYY-MM-DD");
	// 	end = moment(date.end).format("YYYY-MM-DD");
	// } else {
	// 	start = moment(searchParams.get("state")).format("YYYY-MM-DD");
	// 	end = moment(searchParams.get("end")).format("YYYY-MM-DD");
	// }
	let value = date?.level;

	const getRevenueData = async () => {
		setLoading(true);
		let response = await Accounts.getProfitLossDateWays(
			getDate?.start,
			getDate?.end,
			timeZone
		);

		if (response.status === 200) {
			setLoading(false);
			setRevenueData(response.results);
		} else {
			setLoading(false);
			setRevenueData([]);
		}
	};

	const getExpenseData = async () => {
		setLoading(true);
		let response = await Accounts.getExpenseDateWays(
			getDate?.start,
			getDate?.end,
			timeZone
		);

		if (response.status === 200) {
			setLoading(false);
			setExpenseData(response.results);
		} else {
			setLoading(false);
			setExpenseData(null);
		}
	};

	const getTaxData = async () => {
		setLoading(true);
		let response = await Accounts.getTaxDateWays(
			getDate?.start,
			getDate?.end,
			timeZone
		);
		if (response.status === 200) {
			setLoading(false);
			setTaxData(response.results);
		} else {
			setLoading(false);
			setTaxData(null);
		}
	};

	const getDepreciationData = async () => {
		setLoading(true);
		let response = await Accounts.getDepreciationDateWays(
			getDate?.start,
			getDate?.end,
			timeZone
		);
		if (response.status === 200) {
			setLoading(false);
			setDepreciationData(response.results);
		} else {
			setLoading(false);
			setDepreciationData(null);
		}
	};

	const getNetProfitData = async () => {
		setLoading(true);
		let response = await Accounts.getNetProfitDateWays(
			getDate?.start,
			getDate?.end,
			timeZone
		);
		if (response.status === 200) {
			setLoading(false);
			setNetProfitData(response.results);
		} else {
			setLoading(false);
			setNetProfitData(null);
		}
	};

	useEffect(() => {
		getRevenueData();
		getExpenseData();
		getTaxData();
		getDepreciationData();
		getNetProfitData();
	}, [value]);

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle
						title='Profit and Loss'
						filter={true}
						setGetDate={setGetDate}
						from='profitLoss'
					/>
					<div className='mt-14 px-4'>
						<div className='pb-10'>
							<div className='pt-3'>
								{/* <div className='px-2 flex justify-between items-center pt-1 pb-5 '>
									<h4 className='font-bold text-20 text-black-1000'>
										Revenue/Revenue on{" "}
										{(value === "month") | !value
											? moment().format("MMMM")
											: value}
									</h4>
								</div> */}

								<div className='pb-5'>
									<h2 className='text-lg font-bold mb-6 text-center'>
										Profit and Loss Statement
										<p className='text-sm font-semibold'>
											{getBusiness?.business_name}
										</p>
										<p className='text-sm font-semibold'>
											{getBusiness?.address_line}
										</p>
										<p className='text-sm font-semibold'>
											<span>
												{moment(
													getDate?.start,
													'"YYYY-MM-DD"'
												).format(dateFormat)}{" "}
												-{" "}
												{moment(getDate?.end, "YYYY-MM-DD").format(
													dateFormat
												)}
											</span>
										</p>
									</h2>
									<table className='table-auto w-full bg-white shadow-md'>
										<thead>
											<tr className='border h-[45px] border-gray-300'>
												<th className='border p-2 font-bold text-left'>
													Revenue
												</th>
												<th className='border p-2 font-bold text-right'>
													Amount
												</th>
											</tr>
										</thead>
										<tbody
											style={{ borderBottom: "1.5px solid #888" }}>
											{revenueData?.revenue?.length
												? revenueData?.revenue?.map(
														(item, index) => (
															<tr key={index} className='border'>
																<td className='border p-2 pl-6'>
																	{Object.keys(item)[0]}
																</td>
																<td className='border p-2 text-right'>
																	{
																		COUNTRYINFO?.[
																			createUserData?.country
																		]?.currency
																	}
																	{numberWithCommas(
																		Object.values(item)[0]
																	)}
																</td>
															</tr>
														)
												  )
												: undefined}
										</tbody>
										<tfoot>
											<tr className='border font-bold border-t-1 border-t-black'>
												<td className='border p-2 '>
													Total Revenue
												</td>
												<td className='border p-2 text-right '>
													{
														COUNTRYINFO?.[createUserData?.country]
															?.currency
													}
													{numberWithCommas(
														revenueData?.total_revenue
													)}
												</td>
											</tr>
										</tfoot>
									</table>
								</div>
								<div className='pb-5'>
									<table className='table-auto w-full bg-white shadow-md'>
										<thead>
											<tr className='border h-[45px] border-gray-300'>
												<th className='border p-2 font-bold text-left'>
													Cost of Goods Sold(COGS)
												</th>
												<th className='border p-2 font-bold text-right'>
													Amount
												</th>
											</tr>
										</thead>
										<tbody
											style={{ borderBottom: "1.5px solid #888" }}>
											{Object.entries(
												revenueData?.cost_of_goods_sold || {}
											)
												.filter(([key]) => key !== "Total Cost")
												.map(([key, value], index) => (
													<tr key={index} className='border'>
														<td className='border p-2 pl-6'>
															{key}
														</td>
														<td className='border p-2 text-right'>
															{
																COUNTRYINFO?.[
																	createUserData?.country
																]?.currency
															}
															{numberWithCommas(value)}
														</td>
													</tr>
												))}
										</tbody>
										<tfoot>
											<tr className='border font-bold border-t-1 border-t-black'>
												<td className='border p-2 '>
													Total Cost of Goods Sold(COGS)
												</td>
												<td className='border p-2 text-right '>
													{
														COUNTRYINFO?.[createUserData?.country]
															?.currency
													}
													{numberWithCommas(
														revenueData?.cost_of_goods_sold?.[
															"Total Cost"
														]
													)}
												</td>
											</tr>
										</tfoot>
									</table>
								</div>
								<div className='pb-5'>
									<div className='flex justify-between items-center'>
										<h2 className='text-lg font-bold mb-2'>
											Gross Profit
										</h2>
										<h2 className='text-lg font-bold mb-2'>
											{
												COUNTRYINFO?.[createUserData?.country]
													?.currency
											}
											{numberWithCommas(netProfitData?.gross_profit)}
										</h2>
									</div>
								</div>

								<div className='pb-5'>
									<table className='table-auto w-full bg-white shadow-md'>
										<thead>
											<tr className='border h-[45px] border-gray-300'>
												<th className='border p-2 font-bold text-left'>
													Operating Expense
												</th>
												<th className='border p-2 font-bold text-right'>
													Amount
												</th>
											</tr>
										</thead>
										<tbody
											style={{ borderBottom: "1.5px solid #888" }}>
											{expenseData?.["Operating Expense"] &&
												Object.entries(
													expenseData?.["Operating Expense"]
												).map(([key, value], index) => {
													if (key === "total_operating_expense")
														return null;

													return (
														<React.Fragment key={index}>
															<tr className='border '>
																<td className='border p-2 font-bold pl-6'>
																	{key}
																</td>
																<td className='border p-2'></td>
															</tr>

															{Array.isArray(
																value.category || value
															) &&
																(value.category || value).map(
																	(item, subIndex) => (
																		<tr
																			key={subIndex}
																			className='border'>
																			<td className='border p-2 pl-6'>
																				{item.category}
																			</td>
																			<td className='border p-2 text-right'>
																				{
																					COUNTRYINFO?.[
																						createUserData
																							?.country
																					]?.currency
																				}
																				{numberWithCommas(
																					item.amount
																				)}
																			</td>
																		</tr>
																	)
																)}

															<tr className='border font-bold '>
																<td
																	className='border p-2 pl-6'
																	style={{
																		borderTop:
																			"1.5px solid #888",
																	}}>
																	Total {key}
																</td>
																<td
																	className='border p-2 text-right'
																	style={{
																		borderTop:
																			"1.5px solid #888",
																	}}>
																	{
																		COUNTRYINFO?.[
																			createUserData?.country
																		]?.currency
																	}
																	{numberWithCommas(
																		value.total_amount
																	)}
																</td>
															</tr>

															<tr>
																<td className='p-2'></td>
																<td className='p-2'></td>
															</tr>
														</React.Fragment>
													);
												})}
										</tbody>
										<tfoot>
											<tr className='border font-bold border-t-1 border-t-black'>
												<td className='border p-2'>
													Total Operating Expense
												</td>
												<td className='border p-2 text-right'>
													{
														COUNTRYINFO?.[createUserData?.country]
															?.currency
													}
													{numberWithCommas(
														expenseData?.["Operating Expense"]
															?.total_operating_expense
													)}
												</td>
											</tr>
										</tfoot>
									</table>
								</div>

								<div className='pb-5'>
									<div className='flex justify-between items-center'>
										<h2 className='text-lg font-bold mb-2'>
											Operating Profit (EBITDA)
										</h2>
										<h2 className='text-lg font-bold mb-2'>
											{
												COUNTRYINFO?.[createUserData?.country]
													?.currency
											}
											{numberWithCommas(
												netProfitData?.operating_profit
											)}
										</h2>
									</div>
								</div>

								{taxData?.["Tax"] &&
									Object.entries(taxData?.["Tax"]).some(
										([key, value]) =>
											key !== "total_tax" &&
											Array.isArray(value.category) &&
											value.category.length > 0
									) && (
										<div className='pb-5'>
											<table className='table-auto w-full bg-white shadow-md'>
												<thead>
													<tr className='border h-[45px] border-gray-300'>
														<th className='border p-2 font-bold text-left'>
															Tax
														</th>
														<th className='border p-2 font-bold text-right'>
															Amount
														</th>
													</tr>
												</thead>
												<tbody
													style={{
														borderBottom: "1.5px solid #888",
													}}>
													{Object.entries(taxData?.["Tax"]).map(
														([key, value], index) => {
															if (
																key === "total_tax" ||
																!value.category ||
																value.category.length === 0
															)
																return null;

															return (
																<React.Fragment key={index}>
																	<tr className='border '>
																		<td className='border p-2 font-bold pl-6'>
																			{key}
																		</td>
																		<td className='border p-2'></td>
																	</tr>

																	{Array.isArray(
																		value.category
																	) &&
																		value.category.map(
																			(item, subIndex) => (
																				<tr
																					key={subIndex}
																					className='border'>
																					<td className='border p-2 pl-6'>
																						{
																							item.category
																						}
																					</td>
																					<td className='border p-2 text-right'>
																						{
																							COUNTRYINFO?.[
																								createUserData
																									?.country
																							]?.currency
																						}
																						{numberWithCommas(
																							item.amount
																						)}
																					</td>
																				</tr>
																			)
																		)}

																	{value.category && (
																		<tr className='border font-bold '>
																			<td
																				className='border p-2 pl-6'
																				style={{
																					borderTop:
																						"1.5px solid #888",
																				}}>
																				Total {key}
																			</td>
																			<td
																				className='border p-2 text-right'
																				style={{
																					borderTop:
																						"1.5px solid #888",
																				}}>
																				{
																					COUNTRYINFO?.[
																						createUserData
																							?.country
																					]?.currency
																				}
																				{numberWithCommas(
																					value.total_amount
																				)}
																			</td>
																		</tr>
																	)}

																	<tr>
																		<td className='p-2'></td>
																		<td className='p-2'></td>
																	</tr>
																</React.Fragment>
															);
														}
													)}
												</tbody>
												<tfoot>
													<tr className='border font-bold border-t-1 border-t-black'>
														<td className='border p-2'>
															Total Tax
														</td>
														<td className='border p-2 text-right'>
															{
																COUNTRYINFO?.[
																	createUserData?.country
																]?.currency
															}
															{numberWithCommas(
																taxData?.["Tax"]?.total_tax
															)}
														</td>
													</tr>
												</tfoot>
											</table>
										</div>
									)}

								<div className='pb-5'>
									<table className='table-auto w-full bg-white shadow-md'>
										<thead>
											<tr className='border h-[45px] border-gray-300'>
												<th className='border p-2 font-bold text-left'>
													Amortization and Depreciation
												</th>
												<th className='border p-2 font-bold text-right'>
													Amount
												</th>
											</tr>
										</thead>
										<tbody
											style={{ borderBottom: "1.5px solid #888" }}>
											{depreciationData?.[
												"Amortization and Depreciation"
											] &&
												Object.entries(
													depreciationData?.[
														"Amortization and Depreciation"
													]
												).map(([key, value], index) => {
													if (key === "total_expense") return null;

													return (
														<React.Fragment key={index}>
															<tr className='border '>
																<td className='border p-2 font-bold pl-6'>
																	{key}
																</td>
																<td className='border p-2'></td>
															</tr>

															{Array.isArray(
																value.category || value
															) &&
																(value.category || value).map(
																	(item, subIndex) => (
																		<tr
																			key={subIndex}
																			className='border'>
																			<td className='border p-2 pl-6'>
																				{item.category}
																			</td>
																			<td className='border p-2 text-right'>
																				{
																					COUNTRYINFO?.[
																						createUserData
																							?.country
																					]?.currency
																				}
																				{numberWithCommas(
																					item.amount
																				)}
																			</td>
																		</tr>
																	)
																)}

															<tr className='border font-bold '>
																<td
																	className='border p-2 pl-6'
																	style={{
																		borderTop:
																			"1.5px solid #888",
																	}}>
																	Total {key}
																</td>
																<td
																	className='border p-2 text-right'
																	style={{
																		borderTop:
																			"1.5px solid #888",
																	}}>
																	{
																		COUNTRYINFO?.[
																			createUserData?.country
																		]?.currency
																	}
																	{numberWithCommas(
																		value.total_amount
																	)}
																</td>
															</tr>

															<tr>
																<td className='p-2'></td>
																<td className='p-2'></td>
															</tr>
														</React.Fragment>
													);
												})}
										</tbody>
										<tfoot>
											<tr className='border font-bold border-t-1 border-t-black'>
												<td className='border p-2'>
													Total Amortization and Depreciation
												</td>
												<td className='border p-2 text-right'>
													{
														COUNTRYINFO?.[createUserData?.country]
															?.currency
													}
													{numberWithCommas(
														depreciationData?.[
															"Amortization and Depreciation"
														]?.total_expense
													)}
												</td>
											</tr>
										</tfoot>
									</table>
								</div>

								<div className='pb-5'>
									<div className='flex justify-between items-center'>
										<h2 className='text-lg font-bold mb-2'>
											Net Profit
										</h2>
										<h2 className='text-lg font-bold mb-2'>
											{
												COUNTRYINFO?.[createUserData?.country]
													?.currency
											}
											{numberWithCommas(netProfitData?.net_profit)}
										</h2>
									</div>
								</div>
							</div>
						</div>
					</div>
				</IonContent>
			</IonPage>
			<ProfitLossBottomSheet getDate={getDate} />
		</IonApp>
	);
}
