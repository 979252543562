import { IonCol, IonLoading, IonRow, IonSpinner } from "@ionic/react";
import React, { forwardRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import DatePicker from "react-datepicker";
import Select from "react-select";
import {
	dateTimeFormatter,
	digitChanger,
	numberWithCommas,
	weekChanger,
} from "helpers/helper";
import { useDispatch, useSelector } from "react-redux";
import { getUserBusiness } from "config/user";

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import Business from "services/Business";
import Orders from "services/Orders";
import Accounts from "services/Accounts";
import Add from "assets/images/icon/svg/Add";
import Calendar from "assets/images/icon/svg/Calendar";
import { getSelectedDate } from "redux/container/dashboardSlice";
import { COUNTRYINFO } from "data/country";
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

export default function AccountDashboard() {
	const [profitLoss, setProfitLoss] = useState({});
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const dateInfo = useSelector((state) => state.dashboard.dateInfo);
	const navigate = useNavigate();
	const { createUserData } = useSelector((state) => state.user);

	const [selectedDate, setSelectedDate] = useState(
		dateInfo ? JSON.stringify(dateInfo) : moment(new Date())
	);
	const [dueLoan, setDueLoan] = useState();
	const [startDate, setStartDate] = useState(moment().startOf("day")._d);
	const [endDate, setEndDate] = useState(null);
	const [selectLevel, setSelectLevel] = useState(
		dateInfo?.level ? dateInfo.level : "today"
	);
	const [loader, setLoader] = useState(false);

	const userBusiness = getUserBusiness();
	const dispatch = useDispatch();
	const userInfo = useSelector((state) => state.auth.data);

	// const [dayCalender, setDayCalender] = useState(false);
	// const [rangeCalender, setRangeCalender] = useState(false);
	const [dayCalender, setDayCalender] = useState(
		dateInfo?.level === "selectDay" ? true : false
	);
	const [rangeCalender, setRangeCalender] = useState(
		dateInfo?.level === "custom" ? true : false
	);

	const getProfitLoss = async (start, end) => {
		setLoader(true);
		let response = await Accounts.getProfitLoss(start, end);
		if (response.success) {
			setLoader(false);
			setProfitLoss(response);
		} else {
			setLoader(false);
		}
	};
	moment.updateLocale("en", {
		week: {
			dow: 1,
		},
	});
	useEffect(() => {
		let interval = setTimeout(() => {
			let start = moment().startOf("day").toString();
			let end = moment().endOf("day").toString();
			let dateTime = { start, end, level: selectLevel };
			let stringDateTime = JSON.stringify(dateTime);

			setSelectedDate(stringDateTime);
			getProfitLoss(
				moment(start).format("YYYY-MM-DD"),
				moment(end).format("YYYY-MM-DD")
			);

			// const startDay = moment()
			// 	.startOf("week")
			// 	.day("Monday")
			// 	.format("YYYY-MM-DD");
			// const endDay = moment()
			// 	.endOf("week")
			// 	.day("Monday")
			// 	.format("YYYY-MM-DD");
			// setExpenseDataPeriod({
			// 	startDate: moment(startDay).format("YYYY-MM-DD"),
			// 	endDate: moment(endDay).format("YYYY-MM-DD"),
			// });
			// setRevenueDataPeriod({
			// 	startDate: moment(startDay).format("YYYY-MM-DD"),
			// 	endDate: moment(endDay).format("YYYY-MM-DD"),
			// });

			// getExpenseData(startDay, endDay);
			// getRevenueData(startDay, endDay);
			const startDay = moment().startOf("week").format("YYYY-MM-DD");
			const endDay = moment().endOf("week").format("YYYY-MM-DD");

			setExpenseDataPeriod({
				startDate: startDay,
				endDate: endDay,
			});

			setRevenueDataPeriod({
				startDate: startDay,
				endDate: endDay,
			});

			// Fetch data using the formatted dates
			getExpenseData(startDay, endDay);
			getRevenueData(startDay, endDay);
		}, 700);

		return () => clearTimeout(interval);
	}, []);

	const [expenseData, setExpenseData] = useState([]);
	const [revenueData, setRevenueData] = useState([]);
	const [revenueLoader, setRevenueLoader] = useState(false);
	const [expenseLoader, setExpenseLoader] = useState(false);
	const [expenseDataPeriod, setExpenseDataPeriod] = useState({
		startDate: "",
		endDate: "",
	});
	const [revenueDataPeriod, setRevenueDataPeriod] = useState({
		startDate: "",
		endDate: "",
	});

	const getExpenseData = async (start, end) => {
		let accountType = await Accounts.getAccountType("Expense");

		if (accountType.success) {
			setExpenseLoader(true);
			let response = await Accounts.getTransactionDate(
				accountType?.results?.id,
				start,
				end
			);
			if (response.status === 200) {
				setExpenseLoader(false);
				setExpenseData(response.results);
			} else {
				setExpenseLoader(false);
			}
		}
	};
	const getRevenueData = async (start, end) => {
		let accountType = await Accounts.getAccountType("Revenue");

		if (accountType.success) {
			setRevenueLoader(true);
			let response = await Accounts.getTransactionDate(
				accountType?.results?.id,
				start,
				end
			);
			if (response.status === 200) {
				setRevenueLoader(false);
				setRevenueData(response.results);
			} else {
				setRevenueLoader(false);
			}
		}
	};

	const [weekCount, setWeekCount] = useState(1);
	const [customerWeekCount, setCustomerWeekCount] = useState(1);

	const getWeekRange = (weeksAgo) => {
		moment.updateLocale("en", {
			week: {
				dow: 0,
			},
		});
		const endDay = moment()
			.subtract(weeksAgo * 7, "days")
			.endOf("week");
		const startDay = moment(endDay).subtract(6, "days");
		const formattedStartDay = startDay.format("YYYY-MM-DD");
		const formattedEndDay = endDay.format("YYYY-MM-DD");

		return { startDay: formattedStartDay, endDay: formattedEndDay };
	};
	const previousWeekHandler = (type) => {
		if (type === "expense") {
			setWeekCount(weekCount + 1);
			let start = getWeekRange(weekCount).startDay;
			let end = getWeekRange(weekCount).endDay;
			setExpenseDataPeriod({
				startDate: moment(start).format("YYYY-MM-DD"),
				endDate: moment(end).format("YYYY-MM-DD"),
			});

			getExpenseData(start, end);
		} else {
			setCustomerWeekCount(customerWeekCount + 1);
			let start = getWeekRange(customerWeekCount).startDay;
			let end = getWeekRange(customerWeekCount).endDay;
			setRevenueDataPeriod({
				startDate: moment(start).format("YYYY-MM-DD"),
				endDate: moment(end).format("YYYY-MM-DD"),
			});
			getRevenueData(start, end);
		}
	};
	const nextWeekHandler = (type) => {
		if (type === "expense") {
			if (weekCount > 1) {
				setWeekCount(weekCount - 1);
				let start = getWeekRange(weekCount - 2).startDay;
				let end = getWeekRange(weekCount - 2).endDay;
				setExpenseDataPeriod({
					startDate: moment(start).format("YYYY-MM-DD"),
					endDate: moment(end).format("YYYY-MM-DD"),
				});
				getExpenseData(start, end);
			}
		} else {
			if (customerWeekCount > 1) {
				setCustomerWeekCount(customerWeekCount - 1);
				let start = getWeekRange(customerWeekCount - 2).startDay;
				let end = getWeekRange(customerWeekCount - 2).endDay;
				setRevenueDataPeriod({
					startDate: moment(start).format("YYYY-MM-DD"),
					endDate: moment(end).format("YYYY-MM-DD"),
				});
				getRevenueData(start, end);
			}
		}
	};

	const expenseChartData = {
		labels: expenseData?.map((item) => item?.name),
		datasets: [
			{
				data: expenseData?.map((item) => item?.amount),
				backgroundColor: "#00B224",
			},
		],
	};

	const revenueChartData = {
		labels: revenueData?.map((item) => item?.name),
		datasets: [
			{
				data: revenueData?.map((item) => item?.amount),
				backgroundColor: "#00B224",
			},
		],
	};

	const options = [
		{ value: "today", label: ekkLanguage.dashboard.today },
		{ value: "yesterday", label: ekkLanguage.dashboard.yesterday },
		{ value: "week", label: ekkLanguage.dashboard.thisWeek },
		{ value: "month", label: ekkLanguage.dashboard.thisMonth },
		{ value: "year", label: ekkLanguage.dashboard.thisYear },
		{ value: "lastWeek", label: ekkLanguage.dashboard.lastWeek },
		{ value: "lastMonth", label: ekkLanguage.dashboard.lastMonth },
		{ value: "lastYear", label: ekkLanguage.dashboard.lastYear },
		{ value: "custom", label: ekkLanguage.dashboard.custom },
		{ value: "selectDay", label: ekkLanguage.dashboard.selectDay },
	];

	// const OfferStartDate = forwardRef(({ value, onClick }, ref) => (
	// 	<div
	// 		className={`bg-white h-10 w-10 rounded-[5px] ml-3 flex items-center justify-center`}
	// 		onClick={onClick}>
	// 		<Calendar></Calendar>
	// 	</div>
	// ));

	const selectDateChanger = (e) => {
		if (e.value === "custom") {
			setRangeCalender(true);
			setDayCalender(false);
			setSelectLevel(e.value ?? options[0]);
			let timeDate = dateTimeFormatter(e.value);
			let dateTime = {
				start: timeDate.start,
				end: timeDate.end,
				level: e.value,
			};
			let stringDateTime = JSON.stringify(dateTime);
			setSelectedDate(stringDateTime);
			dispatch(getSelectedDate(dateTime));
		} else if (e.value === "selectDay") {
			setDayCalender(true);
			setRangeCalender(false);
			setSelectLevel(e.value ?? options[0]);
			let timeDate = dateTimeFormatter(e.value);
			let dateTime = {
				start: timeDate.start,
				end: timeDate.end,
				level: e.value,
			};
			let stringDateTime = JSON.stringify(dateTime);
			setSelectedDate(stringDateTime);
			dispatch(getSelectedDate(dateTime));
		} else {
			setRangeCalender(false);
			setDayCalender(false);
			setSelectLevel(e.value ?? options[0]);
			let timeDate = dateTimeFormatter(e.value);
			let dateTime = {
				start: timeDate.start,
				end: timeDate.end,
				level: e.value,
			};
			dispatch(getSelectedDate(dateTime));
			let stringDateTime = JSON.stringify(dateTime);
			setSelectedDate(stringDateTime);
			// Get the analytics for the selected date range.

			getProfitLoss(
				moment(timeDate.start).format("YYYY-MM-DD"),
				moment(timeDate.end).format("YYYY-MM-DD")
			);
		}
	};

	const onDayChange = (dates) => {
		const end = moment(dates).endOf("day")._d;
		setStartDate(dates);
		setEndDate(endDate);
		let dateTime = { start: dates, end, level: selectLevel };
		dispatch(getSelectedDate(dateTime));
		let stringDateTime = JSON.stringify(dateTime);
		setSelectedDate(stringDateTime);
		getProfitLoss(
			moment(dates).format("YYYY-MM-DD"),
			moment(end).format("YYYY-MM-DD")
		);
	};

	const onRangeStart = (dates) => {
		setStartDate(dates);
		setEndDate(null);
		let dateTime = {
			start: dates,
			end: null,
			level: selectLevel,
		};
		dispatch(getSelectedDate(dateTime));
		let stringDateTime = JSON.stringify(dateTime);
		setSelectedDate(stringDateTime);
	};
	const onRangeEnd = (dates) => {
		const end = moment(dates).endOf("day")._d;
		setEndDate(end);
		let dateTime = {
			start: JSON.parse(selectedDate).start,
			end,
			level: selectLevel,
		};
		dispatch(getSelectedDate(dateTime));
		let stringDateTime = JSON.stringify(dateTime);
		setSelectedDate(stringDateTime);
		getProfitLoss(
			moment(JSON.parse(selectedDate).start).format("YYYY-MM-DD"),
			moment(end).format("YYYY-MM-DD")
		);
	};

	let getDetails = (name, listName) => {
		return navigate(`/dashboard-details/${selectedDate}/${name}/${listName}`);
	};

	const CustomInput = forwardRef(({ value, onClick }, ref) => (
		<div
			ref={ref}
			className={`w-full h-10 border p-2.5 rounded relative bg-white ml-4
								${
									selectedDate
										? "border-transparent font-bold text-[#333333]"
										: "border-[#FF564C]"
								} custom-placeholder-style`}
			onClick={onClick}>
			<div className='flex'>
				<span className=''>
					{selectedDate ? (
						<Calendar color={"#333333"}></Calendar>
					) : (
						<Calendar color={"#FF564C"}></Calendar>
					)}
				</span>
				<span>
					{selectedDate ? (
						<h1 className='text-center pl-2.5 text-12 font-bold text-[#333333] pt-0.5 '>
							{moment(JSON.parse(selectedDate)?.start).format(
								"DD-MM-YYYY"
							)}
						</h1>
					) : (
						<h1 className='text-center pl-2.5 text-12 font-normal text-[#FF564C] pt-0.5 '>
							Select Date
						</h1>
					)}
				</span>
				<span className='absolute right-0 mr-2.5'>
					{selectedDate ? (
						<Add color={"#333333"}></Add>
					) : (
						<Add color={"#FF564C"}></Add>
					)}
				</span>
			</div>
		</div>
	));

	const FromDateInput = forwardRef(({ value, onClick }, ref) => (
		<div
			ref={ref}
			className={`w-full h-10 border p-2.5 rounded relative bg-white mt-4
								${
									JSON.parse(selectedDate)?.start
										? "border-[#333333] font-bold text-[#333333]"
										: "border-[#FF564C]"
								} custom-placeholder-style`}
			onClick={onClick}>
			<div className='flex'>
				<span className=''>
					{JSON.parse(selectedDate)?.start ? (
						<Calendar color={"#333333"}></Calendar>
					) : (
						<Calendar color={"#FF564C"}></Calendar>
					)}
				</span>
				<span>
					{JSON.parse(selectedDate)?.start ? (
						<h1 className='text-center pl-2.5 text-12 font-bold text-[#333333] pt-0.5 '>
							{moment(JSON.parse(selectedDate)?.start).format(
								"DD-MM-YYYY"
							)}
						</h1>
					) : (
						<h1 className='text-center pl-2.5 text-12 font-normal text-[#FF564C] pt-0.5 '>
							From Date
						</h1>
					)}
				</span>
				<span className='absolute right-0 mr-2.5'>
					{JSON.parse(selectedDate)?.start ? (
						<Add color={"#333333"}></Add>
					) : (
						<Add color={"#FF564C"}></Add>
					)}
				</span>
			</div>
		</div>
	));

	const ToDateInput = forwardRef(({ value, onClick }, ref) => (
		<div
			style={{ width: "calc(100%-12px" }}
			ref={ref}
			className={`w-full h-10 border p-2.5 rounded relative bg-white mt-4
								${
									JSON.parse(selectedDate)?.end
										? "border-[#333333] font-bold text-[#333333]"
										: "border-[#FF564C]"
								} custom-placeholder-style`}
			onClick={onClick}>
			<div className='flex'>
				<span className=''>
					{JSON.parse(selectedDate)?.end ? (
						<Calendar color={"#333333"}></Calendar>
					) : (
						<Calendar color={"#FF564C"}></Calendar>
					)}
				</span>
				<span>
					{JSON.parse(selectedDate)?.end ? (
						<h1 className='text-center pl-2.5 text-12 font-bold text-[#333333] pt-0.5 '>
							{moment(JSON.parse(selectedDate)?.end).format(
								"DD-MM-YYYY"
							)}
						</h1>
					) : (
						<h1 className='text-center pl-2.5 text-12 font-normal text-[#FF564C] pt-0.5 '>
							To Date
						</h1>
					)}
				</span>
				<span className='absolute right-0 mr-2.5'>
					{JSON.parse(selectedDate)?.end ? (
						<Add color={"#333333"}></Add>
					) : (
						<Add color={"#FF564C"}></Add>
					)}
				</span>
			</div>
		</div>
	));

	//chart details

	moment.updateLocale("en", {
		week: {
			dow: 0,
		},
	});

	return (
		<div>
			<IonLoading
				isOpen={loader}
				message={ekkLanguage.createOrder.loader}
				spinner='circles'
			/>
			<div className='chart-wrapper relative mb-4'>
				{revenueLoader && (
					<div className='absolute h-full w-full flex justify-center items-center left-[7%] top-[5%]'>
						<IonSpinner name='circular'></IonSpinner>
					</div>
				)}

				<div className='px-2 pb-1 flex justify-between items-center pt-5'>
					<h4 className='font-bold text-20 text-black-1000'>Revenue</h4>
				</div>
				<div className='flex justify-between py-3 items-center'>
					<button
						className='font-bold h-9 rounded-[5px] ml-2 border-2 bg-success text-white px-2 text-12'
						onClick={() => previousWeekHandler("revenue")}>
						Pervious Week
					</button>
					<h4 className='font-bold'>
						<span className='font-semibold text-12'>
							{digitChanger(
								moment(revenueDataPeriod?.startDate).format(
									"DD-MM-YYYY"
								)
							)}{" "}
							-{" "}
							{digitChanger(
								moment(revenueDataPeriod?.endDate).format("DD-MM-YYYY")
							)}
						</span>
					</h4>
					<button
						className={`font-bold h-9 rounded-[5px] ml-2 border-2 ${
							customerWeekCount === 1 ? "bg-36A94D-333" : "bg-success"
						}  text-white px-2 text-12`}
						onClick={() => nextWeekHandler("revenue")}>
						Next Week
					</button>
				</div>

				<div
					className='flex justify-center px-2'
					style={{ width: "100%", maxWidth: "768px" }}>
					<Bar data={revenueChartData} options={{ barPercentage: 0.7 }} />
				</div>
			</div>
			<div className='chart-wrapper relative'>
				{expenseLoader && (
					<div className='absolute h-full w-full flex justify-center items-center left-[7%] top-[5%]'>
						<IonSpinner name='circular'></IonSpinner>
					</div>
				)}

				<div className='px-2 pb-1 flex justify-between items-center pt-5'>
					<h4 className='font-bold text-20 text-black-1000'>Expense</h4>
				</div>
				<div className='flex justify-between py-3 items-center'>
					<button
						className='font-bold h-9 rounded-[5px] ml-2 border-2 bg-success text-white px-2 text-12'
						onClick={() => previousWeekHandler("expense")}>
						Pervious Week
					</button>
					<h4 className='font-bold'>
						<span className='font-semibold text-12'>
							{digitChanger(
								moment(expenseDataPeriod?.startDate).format(
									"DD-MM-YYYY"
								)
							)}{" "}
							-{" "}
							{digitChanger(
								moment(expenseDataPeriod?.endDate).format("DD-MM-YYYY")
							)}
						</span>
					</h4>
					<button
						className={`font-bold h-9 rounded-[5px] ml-2 border-2 ${
							weekCount === 1 ? "bg-36A94D-333" : "bg-success"
						}  text-white px-2 text-12`}
						onClick={() => nextWeekHandler("expense")}>
						Next Week
					</button>
				</div>

				<div
					className='flex justify-center px-2'
					style={{ width: "100%", maxWidth: "768px" }}>
					<Bar data={expenseChartData} options={{ barPercentage: 0.7 }} />
				</div>
			</div>

			<div className='py-5'>
				<div>
					<div className='px-2 flex justify-between items-center pt-1 pb-5'>
						<h4 className='font-bold text-20 text-black-1000'>
							Profit & Loss
						</h4>
					</div>
				</div>
				<div className='px-2'>
					<div className={dayCalender ? "flex" : "block"}>
						<div className={dayCalender ? "w-[calc(50%-8px)]" : "w-full"}>
							<Select
								isSearchable={false}
								defaultValue={options.filter(
									(el) => el.value === dateInfo.level
								)}
								placeholder='Select date'
								options={options}
								onChange={selectDateChanger}
								styles={{
									control: (baseStyles, state) => ({
										...baseStyles,
										// width: "100%",
										height: 40,
										// border: "none",
										outLine: "none",
										fontWeight: 600,
										fontSize: 12,
										borderRadius: 5,
									}),
								}}
								theme={(theme) => ({
									...theme,
									borderRadius: 5,
									colors: {
										...theme.colors,
										primary25: "rgb(204, 255, 214)",
										primary: "#00FF33",
									},
								})}
							/>
						</div>
						{dayCalender && (
							<div className='w-[calc(50%-8px)]'>
								<DatePicker
									selected={startDate}
									onChange={onDayChange}
									startDate={startDate}
									endDate={endDate}
									selectsDisabledDaysInRange
									withPortal
									customInput={<CustomInput />}
								/>
							</div>
						)}
					</div>

					<div>
						{rangeCalender && (
							<div className='flex items-center'>
								<div className='mr-2 w-[calc(50%-8px)]'>
									<DatePicker
										selected={startDate}
										onChange={onRangeStart}
										startDate={startDate}
										endDate={startDate}
										selectsDisabledDaysInRange
										withPortal
										customInput={<FromDateInput />}
									/>
								</div>
								<div className='ml-2 w-[calc(50%-8px)]'>
									<DatePicker
										selected={endDate}
										onChange={onRangeEnd}
										startDate={endDate}
										endDate={endDate}
										selectsDisabledDaysInRange
										withPortal
										customInput={<ToDateInput />}
									/>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			{dateInfo?.start && dateInfo?.end ? (
				<>
					{" "}
					<h4 className='font-bold text-center'>
						<span className='font-semibold text-12'>
							{digitChanger(
								moment(dateInfo?.start).format("DD-MM-YYYY")
							)}{" "}
							-{" "}
							{digitChanger(moment(dateInfo?.end).format("DD-MM-YYYY"))}
						</span>
					</h4>
				</>
			) : undefined}

			<div className='pt-4 mb-12'>
				<IonRow>
					<IonCol size='6' className='pr-2 mb-[6px]'>
						<div
							className='p-[10px] bg-white rounded-[7px] cursor-pointer'
							style={{
								boxShadow: " 0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
							}}>
							<h4 className='font-bold text-14 text-black-1000 pb-4'>
								Profit
							</h4>
							<h2 className='font-bold text-16 text-center text-black-1000 pb-5'>
								{COUNTRYINFO?.[createUserData?.country]?.currency}
								{""}
								{digitChanger(
									numberWithCommas(profitLoss?.profit ?? 0)
								)}
							</h2>
							{/* <div className='flex justify-end items-center'>
								<span
									className='text-10 font-semibold text-success rounded-[21px] py-[3px] px-[4px] flex justify-center items-center'
									style={{ background: "#CCFFD6" }}>
									<ArrowUp color='#00991F' /> 0.5%
								</span>
								<Link
									className='text-12 underline'
									style={{ color: "#00ACE5" }}
									to=''
									// to='/dashboard-details/11-7-2023/order'
								>
									{ekkLanguage.dashboard.seeDetails}
								</Link>
							</div> */}
						</div>
					</IonCol>
					<IonCol size='6' className='pl-2 mb-[6px]'>
						<div
							className='p-[10px] bg-white rounded-[7px] cursor-pointer'
							style={{
								boxShadow: " 0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
							}}>
							<h4 className='font-bold text-14 text-black-1000 pb-4'>
								Loss
							</h4>
							<h2 className='font-bold text-16 text-center text-black-1000 pb-5'>
								{COUNTRYINFO?.[createUserData?.country]?.currency}
								{""}
								{digitChanger(numberWithCommas(profitLoss?.loss ?? 0))}
							</h2>
							{/* <div className='flex justify-end items-center'>
								<span
									className='text-10 font-semibold text-primary rounded-[21px] py-[3px] px-[4px] flex justify-center items-center'
									style={{ background: "#FFCFCC" }}>
									<ArrowDown color='#FF261A' /> 0.5%
								</span>
								<Link
									className='text-12 underline'
									style={{ color: "#00ACE5" }}
									to='#'>
									{ekkLanguage.dashboard.seeDetails}
								</Link>
							</div> */}
						</div>
					</IonCol>
				</IonRow>
			</div>
		</div>
	);
}
