import React from "react";

export default function HomeOutline() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			style={{ margin: "auto" }}>
			<g id='Iconly/Light-Outline/Home'>
				<g id='Home'>
					<mask
						id='mask0_3257_946'
						maskUnits='userSpaceOnUse'
						x='2'
						y='1'
						width='21'
						height='22'>
						<path
							id='Clip 2'
							fillRule='evenodd'
							clipRule='evenodd'
							d='M2 1.00018H22.4998V22.5052H2V1.00018Z'
							fill='white'
						/>
					</mask>
					<g mask='url(#mask0_3257_946)'>
						<path
							id='Fill 1'
							fillRule='evenodd'
							clipRule='evenodd'
							d='M13.717 15.2913C14.921 15.2913 15.901 16.2643 15.901 17.4603V20.5363C15.901 20.7933 16.107 20.9993 16.371 21.0053H18.277C19.779 21.0053 21 19.7993 21 18.3173V9.59331C20.993 9.08331 20.75 8.60331 20.333 8.28431L13.74 3.02631C12.855 2.32531 11.617 2.32531 10.729 3.02831L4.181 8.28231C3.748 8.61131 3.505 9.09131 3.5 9.61031V18.3173C3.5 19.7993 4.721 21.0053 6.223 21.0053H8.147C8.418 21.0053 8.638 20.7903 8.638 20.5263C8.638 20.4683 8.645 20.4103 8.657 20.3553V17.4603C8.657 16.2713 9.631 15.2993 10.826 15.2913H13.717ZM18.277 22.5053H16.353C15.251 22.4793 14.401 21.6143 14.401 20.5363V17.4603C14.401 17.0913 14.094 16.7913 13.717 16.7913H10.831C10.462 16.7933 10.157 17.0943 10.157 17.4603V20.5263C10.157 20.6013 10.147 20.6733 10.126 20.7413C10.018 21.7313 9.172 22.5053 8.147 22.5053H6.223C3.894 22.5053 2 20.6263 2 18.3173V9.60331C2.01 8.60931 2.468 7.69931 3.259 7.10031L9.794 1.85531C11.233 0.715311 13.238 0.715311 14.674 1.85331L21.256 7.10331C22.029 7.69231 22.487 8.60031 22.5 9.58231V18.3173C22.5 20.6263 20.606 22.5053 18.277 22.5053Z'
							fill='#A6A6A6'
						/>
					</g>
				</g>
			</g>
		</svg>
	);
}
