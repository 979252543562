import React from "react";

export default function CancelPopUp() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='50'
			height='50'
			viewBox='0 0 50 50'
			fill='none'>
			<circle cx='25' cy='25' r='25' fill='#FF564C' fillOpacity='0.15' />
			<circle cx='25' cy='25' r='18' fill='#FF564C' fillOpacity='0.2' />
			<path
				d='M23.75 17.4125C23.675 20.675 23.5875 23.925 23.5125 27.1875C23.5125 27.6019 23.6771 27.9993 23.9701 28.2923C24.2631 28.5854 24.6605 28.75 25.075 28.75C25.4894 28.75 25.8868 28.5854 26.1798 28.2923C26.4728 27.9993 26.6375 27.6019 26.6375 27.1875C26.7125 23.925 26.8 20.675 26.875 17.4125C26.875 16.9981 26.7103 16.6006 26.4173 16.3076C26.1243 16.0146 25.7269 15.85 25.3125 15.85C24.898 15.85 24.5006 16.0146 24.2076 16.3076C23.9146 16.6006 23.75 16.9981 23.75 17.4125Z'
				fill='#FF564C'
			/>
			<path
				d='M24.9126 30.2625C24.4982 30.2625 24.1008 30.4271 23.8077 30.7201C23.5147 31.0131 23.3501 31.4106 23.3501 31.825C23.3501 32.2394 23.5147 32.6368 23.8077 32.9298C24.1008 33.2228 24.4982 33.3875 24.9126 33.3875C25.327 33.3875 25.7244 33.2228 26.0175 32.9298C26.3105 32.6368 26.4751 32.2394 26.4751 31.825C26.4751 31.4106 26.3105 31.0131 26.0175 30.7201C25.7244 30.4271 25.327 30.2625 24.9126 30.2625Z'
				fill='#FF564C'
			/>
		</svg>
	);
}
