import { IonSearchbar } from "@ionic/react";
import Close from "assets/images/icon/svg/Close";
import Filter from "assets/images/icon/svg/Filter";
import React from "react";

export default function Search({
	placeholder,
	search,
	setSearch,
	type,
	singleSearch,
	filter,
	filterHandler,
	selectedFilter,
}) {
	const _handleKeyPress = (e) => {
		if (e.key === "Enter") {
			singleSearch(search);
		}
	};

	return (
		<div className='flex'>
			<IonSearchbar
				// searchIcon={<SearchDefault />}
				className={`p-0 common-search ${filter && "mr-2"}`}
				type={type}
				placeholder={placeholder}
				value={search}
				onKeyPress={_handleKeyPress}
				onIonChange={(e) => {
					setSearch(e.target.value);
				}}></IonSearchbar>
			{filter && (
				<>
					{selectedFilter ? (
						<span
							onClick={filterHandler}
							className=' bg-white rounded-[10px] flex cursor-pointer'
							style={{
								boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1)",
								width: "45.21px",
								height: "42px",
							}}>
							<Close />
						</span>
					) : (
						<span
							onClick={filterHandler}
							className=' bg-white rounded-[10px] flex cursor-pointer'
							style={{
								boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1)",
								width: "45.21px",
								height: "42px",
							}}>
							<Filter />
						</span>
					)}
				</>
			)}
		</div>
	);
}
