import { IonApp, IonContent, IonLoading, IonPage } from "@ionic/react";
import EmployeePosition from "components/mobile-components/Accounts/EmployeePosition";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Business from "services/Business";

export default function EmployeePositionPage() {
	const location = useLocation();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [employeePositions, setEmployeePositions] = useState([]);
	const [loading, setLoading] = useState(false);

	const searchParams = new URLSearchParams(location?.search);
	let empId = searchParams.get("id");

	const getEmployeePosition = async () => {
		setLoading(true);
		let response = await Business.getEmployeePosition();
		if (response.status === 200) {
			setEmployeePositions(response.results);
		} else {
			setEmployeePositions([]);
		}
		setLoading(false);
	};
	useEffect(() => {
		getEmployeePosition();
	}, []);

	return (
		<IonApp>
			<IonPage className='business-name'>
				<IonContent>
					<BackButtonTitle title={"Select Employee Role"} />
					<IonLoading
						isOpen={loading}
						message={ekkLanguage.createOrder.loader}
						spinner='circles'
					/>
					<div className='mt-14 px-2'>
						<EmployeePosition
							positionList={employeePositions}
							empId={empId}
						/>
					</div>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
