import React from "react";

export default function Send() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='30'
			height='26'
			viewBox='0 0 30 26'
			fill='none'>
			<path
				d='M27.9252 11.3037L27.9158 11.2996L2.8916 0.920276C2.68112 0.832179 2.45209 0.797629 2.22498 0.819715C1.99788 0.841802 1.7798 0.919836 1.59023 1.04684C1.38996 1.17807 1.22545 1.35703 1.11151 1.56763C0.997569 1.77822 0.937773 2.01384 0.9375 2.25328V8.89137C0.937612 9.2187 1.05191 9.53574 1.26069 9.78785C1.46948 10.04 1.75967 10.2113 2.08125 10.2724L15.7295 12.7961C15.7831 12.8062 15.8315 12.8348 15.8663 12.8768C15.9012 12.9189 15.9202 12.9717 15.9202 13.0263C15.9202 13.0809 15.9012 13.1338 15.8663 13.1758C15.8315 13.2179 15.7831 13.2464 15.7295 13.2566L2.08184 15.7802C1.76035 15.8412 1.47017 16.0123 1.26129 16.2642C1.05242 16.5161 0.93791 16.8329 0.9375 17.1601V23.7994C0.937345 24.028 0.993958 24.2531 1.10226 24.4545C1.21056 24.6559 1.36716 24.8272 1.55801 24.9531C1.78758 25.1056 2.057 25.1871 2.33262 25.1875C2.52423 25.1873 2.71388 25.1489 2.89043 25.0744L27.9141 14.7543L27.9252 14.749C28.262 14.6042 28.549 14.3639 28.7507 14.0577C28.9524 13.7515 29.0599 13.393 29.0599 13.0263C29.0599 12.6597 28.9524 12.3011 28.7507 11.9949C28.549 11.6888 28.262 11.4484 27.9252 11.3037Z'
				fill='#FF564C'
			/>
		</svg>
	);
}
