import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export const dashboardSlice = createSlice({
	name: "dashboard",
	initialState: {
		dateInfo: {
			start: moment().startOf("month").format("YYYY-MM-DD"),
			end: moment().endOf("month").format("YYYY-MM-DD"),
			level: "This Month",
		},
		selectedDate: {
			start: moment().startOf("day").format("YYYY-MM-DD"),
			end: moment().endOf("day").format("YYYY-MM-DD"),
			level: "today",
		},
		accountData: [],
		accountDataLoading: true,
		isFilter: true,
		accountType: null,
		category: null,
		isCategory: false,
		accountTypeDetails: null,
		pageCount: 2,
		scrollPosition: 0,
		accountingSort: "-date",
	},
	reducers: {
		getSelectedDate: (state, action) => {
			state.dateInfo = action.payload;
		},
		setSelectedDate: (state, action) => {
			state.selectedDate = action.payload;
		},
		setAccountData: (state, action) => {
			state.accountData = action.payload;
		},
		setAccountDataLoading: (state, action) => {
			state.accountDataLoading = action.payload;
		},
		setIsFilter: (state, action) => {
			state.isFilter = action.payload;
		},
		setIsCategory: (state, action) => {
			state.isCategory = action.payload;
		},
		setAccountType: (state, action) => {
			state.accountType = action.payload;
		},
		setAccountTypeDetails: (state, action) => {
			state.accountTypeDetails = action.payload;
		},
		setAccountingCategory: (state, action) => {
			state.category = action.payload;
		},
				
		setPageCount: (state, action) => {
			state.pageCount = action.payload;
		},
		setScrollPosition: (state, action) => {
			state.scrollPosition = action.payload;
		},
		resetAccountData: (state, action) => {
			state.dateInfo = {
				start: moment().startOf("month").format("YYYY-MM-DD"),
				end: moment().endOf("month").format("YYYY-MM-DD"),
				level: "This Month",
			};
			state.accountData = [];
			state.accountDataLoading = true;
			state.isFilter = true;
			state.pageCount = 2;
			state.scrollPosition = 0;
		},
		setAccountingSort: (state, action) => {
			state.accountingSort = action.payload;
		},
	},
});

export const { actions, reducer } = dashboardSlice;

export const getSelectedDate = (values) => async (dispatch) => {
	dispatch(actions.getSelectedDate(values));
};

export const setSelectedDate = (values) => async (dispatch) => {
	dispatch(actions.setSelectedDate(values));
};

export const setAccountData = (values) => async (dispatch) => {
	dispatch(actions.setAccountData(values));
};

export const setAccountDataLoading = (values) => async (dispatch) => {
	dispatch(actions.setAccountDataLoading(values));
};

export const setIsFilter = (values) => async (dispatch) => {
	dispatch(actions.setIsFilter(values));
};

export const setIsCategory = (values) => async (dispatch) => {
	dispatch(actions.setIsCategory(values));
};

export const setAccountType = (values) => async (dispatch) => {
	dispatch(actions.setAccountType(values));
};

export const setAccountTypeDetails = (values) => async (dispatch) => {
	dispatch(actions.setAccountTypeDetails(values));
};

export const setAccountingCategory = (values) => async (dispatch) => {
	dispatch(actions.setAccountingCategory(values));
};

export const setPageCount = (values) => async (dispatch) => {
	dispatch(actions.setPageCount(values));
};

export const resetAccountData = () => async (dispatch) => {
	dispatch(actions.resetAccountData());
};

export const setScrollPosition = (values) => async (dispatch) => {
	dispatch(actions.setScrollPosition(values));
};

export const setAccountingSort = (values) => async (dispatch) => {
	dispatch(actions.setAccountingSort(values));
};

export default reducer;
