import {
	IonButton,
	IonCol,
	IonGrid,
	IonItem,
	IonLabel,
	IonRadio,
	IonRadioGroup,
	IonRow,
} from "@ionic/react";
import { getLanguage } from "config/language";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setDateFormat } from "redux/container/dateSlice";

export default function DateFormate() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const dateFormat = useSelector((state) => state.date.format);
	const [selectedFormate, setSelectedFormate] = useState(dateFormat);
	let dispatch = useDispatch();
	let navigate = useNavigate();

	const languageSubmitHandler = () => {
		dispatch(setDateFormat(selectedFormate));
		navigate("/home", { replace: true });
	};

	return (
		<div className='px-2 pt-24 relative change-password-screen change-language'>
			<IonGrid>
				<IonRow>
					<IonCol size='12'>
						<div>
							<IonRadioGroup
								value={selectedFormate}
								onIonChange={(e) => {
									setSelectedFormate(e.detail.value);
								}}>
								<IonItem
									lines='none'
									className='bg-white border rounded-[10px] mb-5 relative'>
									<IonLabel className='absolute left-10 border-0 font-sans text-14 text-black-500 font-medium'>
										<div className='flex'>
											<span className='ml-2'>MM/DD/YYYY</span>
										</div>
									</IonLabel>
									<IonRadio value='MM/DD/YYYY' color='success' />
								</IonItem>

								<IonItem
									lines='none'
									className='bg-white border rounded-[10px] mb-5 relative'>
									<IonLabel className='absolute left-10 border-0 font-sans text-14 text-black-500 font-medium'>
										<div className='flex'>
											<span className='ml-2'>DD/MM/YYYY</span>
										</div>
									</IonLabel>
									<IonRadio value='DD/MM/YYYY' color='success' />
								</IonItem>
							</IonRadioGroup>
						</div>
					</IonCol>
				</IonRow>
				<div className='submit-button-wrapper '>
					<IonRow>
						<IonCol size='12'>
							<div className='text-center'>
								<IonButton
									onClick={languageSubmitHandler}
									expand='full'
									className='bg-success rounded-[7px] font-extrabold text-12 h-10'>
									{ekkLanguage.changeLanguages.btnLabel}
								</IonButton>
							</div>
						</IonCol>
					</IonRow>
				</div>
			</IonGrid>
		</div>
	);
}
