import {
	IonApp,
	IonCol,
	IonContent,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonPage,
	IonSpinner,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import { COUNTRYINFO } from "data/country";
import { digitChanger, numberWithCommas } from "helpers/helper";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Accounts from "services/Accounts";

export default function TaxPartsPage() {
	const location = useLocation();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let [loading, setLoading] = useState(true);
	let [taxData, setTaxData] = useState([]);
	const [pageCount, setPageCount] = useState(2);
	const [hasMoreData, setHasMoreData] = useState(true);
	const [reachedEnd, setReachedEnd] = useState(false);
	const { createUserData } = useSelector((state) => state.user);
	const [totalAmount, setTotalAmount] = useState(0);
	const dateFormat = useSelector((state) => state.date.format);
	const searchParams = new URLSearchParams(location?.search);
	const date = useSelector((state) => state.dashboard.dateInfo);
	const [getDate, setGetDate] = useState({
		start: moment(searchParams.get("state")).format("YYYY-MM-DD"),
		end: moment(searchParams.get("end")).format("YYYY-MM-DD"),
	});
	const navigate = useNavigate();

	// let start, end;
	// if (date?.start && date?.end) {
	// 	start = moment(date.start).format("YYYY-MM-DD");
	// 	end = moment(date.end).format("YYYY-MM-DD");
	// } else {
	// 	start = moment(searchParams.get("state")).format("YYYY-MM-DD");
	// 	end = moment(searchParams.get("end")).format("YYYY-MM-DD");
	// }
	let value = date?.level;

	const getTaxData = async () => {
		setLoading(true);
		let response = await Accounts.getTaxDetails(getDate?.start, getDate?.end);
		if (response.status === 200) {
			setLoading(false);
			setTaxData(response.results);
			setTotalAmount(response?.total_amount);
		} else {
			setLoading(false);
			setTaxData([]);
			setTotalAmount(0);
		}
	};
	useEffect(() => {
		getTaxData();
	}, [value]);

	let loadMoreTran = async () => {
		if (hasMoreData) {
			let response = await Accounts.getTaxDetails(
				getDate?.start,
				getDate?.end,
				pageCount
			);
			if (response.status === 200) {
				setLoading(false);
				setPageCount((prev) => prev + 1);
				setTaxData([...taxData, ...response.results]);
			} else {
				setHasMoreData(false);
				setLoading(false);
			}
		}
	};

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle
						title='Tax Details'
						filter={true}
						setGetDate={setGetDate}
					/>
					<div className='mt-14 px-4'>
						<div className='pb-10'>
							<div className='pt-3'>
								<div className='px-2 flex justify-between items-center pt-1 pb-5'>
									<h4 className='font-bold text-16 text-black-1000'>
										Tax on{" "}
										<span>
											<span>
												{moment(
													getDate?.start,
													'"YYYY-MM-DD"'
												).format(dateFormat)}{" "}
												-{" "}
												{moment(getDate?.end, "YYYY-MM-DD").format(
													dateFormat
												)}
											</span>
										</span>
									</h4>
								</div>
								<div className='px-2 flex justify-between items-center pt-1 pb-5'>
									<h4 className='font-bold text-16 text-black-1000'>
										Total Amount:{" "}
										{COUNTRYINFO?.[createUserData?.country]?.currency}
										{numberWithCommas(totalAmount ?? 0)}
									</h4>
								</div>
								<div>
									<div>
										{loading ? (
											<div className='flex items-center justify-center h-[156px] m-auto'>
												{" "}
												<IonSpinner name='crescent'></IonSpinner>
											</div>
										) : (
											<>
												<table
													className='table-auto w-full bg-white'
													style={{
														boxShadow:
															"0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
													}}>
													<thead>
														<tr className='border h-[45px] border-r-0 border-l-0 border-D9D9D9 px-4'>
															<th className='border p-1 font-bold text-12'>
																Date
															</th>

															<th className='border p-1 font-bold text-14'>
																Amount
															</th>
															{/* <th className='border p-1 font-bold text-14'>
																Created By
															</th> */}
															<th className='border p-1 font-bold text-14'>
																Category
															</th>
														</tr>
													</thead>
													<tbody>
														{taxData?.length
															? taxData?.map((el, i) => {
																	return (
																		<tr className='text-center h-[34px] border border-r-0 border-l-0 border-D9D9D9 px-4 cursor-pointer' onClick={() => {
																			navigate(`/transaction-details/${el?.id}?from=dashboard`);
																		}}>
																			<td className='border p-2 w-14 font-semibold text-10'>
																				{moment(
																					el?.date
																				).format(
																					dateFormat
																				)}
																			</td>
																			<td className='border  font-semibold p-2 text-14'>
																				{
																					COUNTRYINFO?.[
																						createUserData
																							?.country
																					]?.currency
																				}
																				{numberWithCommas(
																					el?.tax
																				)}
																			</td>
																			<td className='border  font-semibold p-2 text-14 text-left'>
																				{
																					el?.acc_category
																						?.title
																				}
																			</td>
																			{/* <td className='border  font-semibold p-2 text-14'>
																				<Link
																					className='text-delivered underline'
																					to={`/employee-details/${el?.created_by}?from=dashboard`}>
																					{
																						el?.created_by_name
																					}
																				</Link>
																			</td> */}
																		</tr>
																	);
															  })
															: ""}
													</tbody>
												</table>
												{!loading && taxData?.length === 0 ? (
													<>
														<IonCol size='2'></IonCol>
														<IonCol size='8'>
															<div
																className='text-center flex items-center justify-center'
																style={{
																	height:
																		"calc(100vh - 420px)",
																}}>
																<div>
																	<img
																		className='m-auto mb-[10px] mt-5'
																		src='/assets/images/not-found-file.png'
																		alt='emptyCard'
																	/>
																	<p className='text-16 font-normal text-222222 mb-[35px]'>
																		{
																			ekkLanguage.orderList
																				.notFound
																		}
																	</p>
																</div>
															</div>
														</IonCol>
													</>
												) : undefined}
											</>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* {loading && (
						<IonInfiniteScroll
							onIonInfinite={(ev) => {
								if (reachedEnd) {
									ev.target.complete();
									return;
								}
								loadMoreTran();
								setTimeout(() => {
									ev.target.complete();
									if (!hasMoreData) {
										setReachedEnd(true);
									}
								}, 1000);
							}}>
							<IonInfiniteScrollContent
								loadingText={ekkLanguage.orderList.loader}
								loadingSpinner={
									hasMoreData ? "bubbles" : null
								}></IonInfiniteScrollContent>
						</IonInfiniteScroll>
					)} */}
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
