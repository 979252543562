import {
	IonButton,
	IonCol,
	IonGrid,
	IonInput,
	IonItem,
	IonRow,
	useIonAlert,
} from "@ionic/react";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import { getCreateUserBusiness, setCreateUserBusiness } from "config/user";
import { getLanguage } from "config/language";
import { BUSINESS_TYPE } from "data/businessType";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Business from "services/Business";

export default function BusinessType() {
	const [businessId, setBusinessId] = useState(
		getCreateUserBusiness()?.business_type
	);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const selectedLanguage = getLanguage();
	const [businessTypeList, setBusinessTypeList] = useState([]);
	const [typeLoading, setTypeLoading] = useState(true);
	const navigate = useNavigate();

	function setBusinessType(business) {
		const lBusiness = getCreateUserBusiness();
		setBusinessId(business?.id);
		// lBusiness.business_type = text;
		// lBusiness.business_type = business.type;
		// lBusiness.business_known = business.known_as;
		// lBusiness.fullImage = business.fullImage;
		setCreateUserBusiness({
			...lBusiness,
			business_type: business.id,
			business_known: business.known_as,
			fullImage: business.image,
		});
	}

	const getBusinessType = async () => {
		let response = await Business.getBusinessType();
		if (response.status === 200) {
			setBusinessTypeList(response?.results);
			setTypeLoading(false);
		} else {
			setTypeLoading(false);
		}
	};

	useEffect(() => {
		getBusinessType();
	}, []);
	return (
		<div className='business-name pt-14'>
			<IonGrid>
				<IonRow>
					<IonCol size='12'>
						<div className='pt-5 pb-5 text-center'>
							<h5 className='text-black-1000 text-16 font-medium pb-9'>
								{ekkLanguage.businessType.paragraph}
							</h5>
							{/* Producer */}
							<div className='text-left pb-6'>
								{/* <h5 className='text-black-1000 text-16 font-blod pb-2 px-2'>
									{ekkLanguage.businessType.producer}
								</h5> */}
								<div>
									<IonRow>
										{businessTypeList.length > 0 &&
											businessTypeList.map((business, index) => {
												return (
													<IonCol
														className='p-1.5'
														size='4'
														key={index}
														onClick={() => {
															setBusinessType(business);
														}}>
														{/* <div className="border border-black-200 rounded-xl overflow-hidden p-2 text-center opacity-30"> */}
														<div
															className={
																business.id === businessId
																	? "border-2 border-success rounded-xl overflow-hidden py-3 text-center mb-4"
																	: "border border-black-200 rounded-xl overflow-hidden py-3  text-center opacity-30 mb-4"
															}
															style={{ minHeight: 96 }}>
															{business.image ? (
																<img
																	className='w-12 h-12 m-auto'
																	src={business.image}
																	alt='fmcg'
																/>
															) : (
																<h1 className='text-32 font-bold'>
																	{business.title
																		.split(" ")
																		.map((el) => el[0])
																		.join("")}
																</h1>
															)}

															<p className='text-10 text-black-1000 font-semibold pt-2'>
																{business.title}
															</p>
														</div>
													</IonCol>
												);
											})}
									</IonRow>
								</div>
							</div>

							{/* Reseller */}
							{/* <div className='text-left pb-6'>
								<h5 className='text-black-1000 text-16 font-blod pb-2 px-2'>
									{ekkLanguage.businessType.reseller}
								</h5>
								<div>
									<IonRow>
										{BUSINESS_TYPE.map((business, index) => {
											if (business.known_as === "reseller") {
												return (
													<IonCol
														className='p-1.5'
														size='4'
														key={index}
														onClick={() => {
															setBusinessType(business);
														}}>
														<div
															className={
																business.type === text
																	? "border-3 border-success rounded-xl overflow-hidden py-3 text-center mb-4"
																	: "border border-black-200 rounded-xl overflow-hidden py-3  text-center opacity-30 mb-4"
															}>
															<img
																className='w-12 h-12 m-auto'
																src={business.image}
																alt='fmcg'
															/>
															<p className='text-10 text-black-1000 font-semibold pt-2'>
																{selectedLanguage === "en"
																	? business.title
																	: business.banglaText}
															</p>
														</div>
													</IonCol>
												);
											}
										})}
									</IonRow>
								</div>
							</div> */}

							{/* FMCG */}
							{/* <div className='text-left pb-6'>
								<h5 className='text-black-1000 text-16 font-blod pb-2 px-2'>
									{ekkLanguage.businessType.fmcg}
								</h5>
								<div>
									<IonRow>
										{BUSINESS_TYPE.map((business, index) => {
											if (business.known_as === "FMCG") {
												return (
													<IonCol
														className='p-1.5'
														size='4'
														key={index}
														onClick={() => {
															setBusinessType(business);
														}}>
											
														<div
															className={
																business.type === text
																	? "border-3 border-success rounded-xl overflow-hidden py-3 text-center mb-4"
																	: "border border-black-200 rounded-xl overflow-hidden py-3  text-center opacity-30 mb-4"
															}>
															<img
																className='w-12 h-12 m-auto'
																src={business.image}
																alt='fmcg'
															/>
															<p className='text-10 text-black-1000 font-semibold pt-2'>
																{selectedLanguage === "en"
																	? business.title
																	: business.banglaText}
															</p>
														</div>
													</IonCol>
												);
											}
										})}
									</IonRow>
								</div>
							</div> */}
							{/* Non FMCG */}
							{/* <div className='text-left pb-6'>
								<h5 className='text-black-1000 text-16 font-blod pb-2 px-2'>
									{ekkLanguage.businessType.nonFmcg}
								</h5>
								<div>
									<IonRow>
										{BUSINESS_TYPE.map((business, index) => {
											if (business.known_as === "NON-FMCG") {
												return (
													<IonCol
														size='4'
														key={index}
														onClick={() => {
															setBusinessType(business);
														}}>
														
														<div
															className={
																business.type === text
																	? "border-3 border-success rounded-xl overflow-hidden py-3 text-center mb-4"
																	: "border border-black-200 rounded-xl overflow-hidden py-3 text-center opacity-30 mb-4"
															}>
															<img
																className='w-12 h-12 m-auto'
																src={business.image}
																alt='fmcg'
															/>
															<p className='text-10 text-black-1000 font-semibold pt-2'>
																{selectedLanguage === "en"
																	? business.title
																	: business.banglaText}
															</p>
														</div>
													</IonCol>
												);
											}
										})}
									</IonRow>
								</div>
							</div> */}
							<div className=' fixed bottom-5 w-full left-[50%] translate-x-[-50%]'>
								<button
									color='primary'
									className='m-auto font-bold h-10 rounded-[10px] text-white bg-success flex justify-center items-center w-[calc(100%-40px)]'
									onClick={() => {
										if (getCreateUserBusiness()?.business_id !== "") {
											navigate("/business-name");
										} else {
											toast.warning(
												ekkLanguage.businessType.validationLabel
											);
										}
									}}>
									{ekkLanguage.businessType.btnLabel}
									<span className='pl-2'>
										<ArrayRight />
									</span>
								</button>
							</div>
							
							{/* <div className='business-step'>
								<ul className='flex justify-center items-center'>
									<li className='w-full relative active'>
										<div className='w-7 h-7 border-2 border-primary rounded-full text-white bg-primary z-10 relative'>
											{ekkLanguage.businessType.numberOne}
										</div>
									</li>
									<li className='w-full relative'>
										<div className='w-7 h-7 border-2 border-F94B41-200 rounded-full text-white bg-primary z-10 relative'>
											{ekkLanguage.businessType.numberTwo}
										</div>
									</li>
									<li className='relative'>
										<div className='w-7 h-7 border-2 border-F94B41-200 rounded-full text-F94B41-200  z-10 relative bg-white'>
											{ekkLanguage.businessType.numberThree}
										</div>
									</li>
								</ul>
							</div> */}
						</div>
					</IonCol>
				</IonRow>
			</IonGrid>
		</div>
	);
}
