import React, { useEffect, useRef, useState } from "react";
import { IonGrid, IonRow, IonCol, IonButton, useIonAlert } from "@ionic/react";
import Minus from "assets/images/icon/svg/Minus";
import Plus from "assets/images/icon/svg/Plus";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addToCart } from "redux/container/shoppingCartSlice";
import { productAddToCart, shoppingCart } from "helpers/shoppingCart";
import { useNavigate } from "react-router-dom";
import { getUserBusiness, setProductSeller } from "config/user";
import { toast } from "react-toastify";
import { digitChanger, numberWithCommas, translator } from "helpers/helper";
import Orders from "services/Orders";

export default function OrderCard({ product }) {
	const navigate = useNavigate();
	const [qty, setQty] = useState(product?.minimum_order_quantity);
	const [preOrderQty, setPreOrderQty] = useState(1);
	const [present] = useIonAlert();
	let orderPrice = qty * Number(product?.base_price);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let business = getUserBusiness();

	function alertMs(message) {
		navigate("/create-order", { replace: true });
	}

	useEffect(() => {
		setQty(product?.minimum_order_quantity);
	}, [product]);

	const COUNT_ABBRS = ["", "K", "M", "G", "T", "P", "E", "Z", "Y"];

	function formatCount(count, withAbbr = false, decimals = 2) {
		const i =
			0 === count ? count : Math.floor(Math.log(count) / Math.log(1000));
		let result = parseFloat((count / Math.pow(1000, i)).toFixed(decimals));
		if (withAbbr) {
			result += `${COUNT_ABBRS[i]}`;
		}
		return result;
	}

	let preOrderHandler = async () => {
		let productInfo = {
			catalog: product.id,
			quantity: preOrderQty,
			business_id: business.id,
		};
		let response = await Orders.preOrder(productInfo);

		if (response.success) {
			navigate("/pre-order-success", { replace: true });
		}
	};
	const numberInputRef = useRef(null);
	useEffect(() => {
		const handleWheel = (event) => {
			if (
				numberInputRef.current &&
				numberInputRef.current === document.activeElement
			) {
				event.preventDefault();
			}
		};
		window.addEventListener("wheel", handleWheel, { passive: false });
		return () => {
			window.removeEventListener("wheel", handleWheel);
		};
	}, []);
	return (
		<div className='bg-white fixed bottom-0 left-0 w-full px-2 z-50 pb-7'>
			<IonGrid>
				{product.inventory_quantity > 0 ? (
					<IonRow>
						<IonCol size='12'>
							<div className='flex justify-between'>
								<h6 className='text-222222 font-bold text-22'>
									{ekkLanguage.createOrder.total}
								</h6>
								<h6 className='text-222222 font-bold text-22'>
									{product?.country?.currency_symbol_native}
									{digitChanger(numberWithCommas(orderPrice))}
								</h6>
							</div>
						</IonCol>
						<IonCol size='5' className='self-center'>
							<div className='flex'>
								<div>
									<span
										className='bg-primary w-6 h-6 flex justify-center items-center rounded-md leading-6'
										onClick={() => {
											if (product?.minimum_order_quantity < qty) {
												setQty(Number(qty) - 1);
											}
										}}>
										<Minus />
									</span>
								</div>

								<div className=' px-3'>
									<input
										ref={numberInputRef}
										className='appearance-none w-12 rounded-md border h-6 leading-6 text-center'
										type='number'
										value={Number(qty)}
										onChange={(e) => {
											setQty(e.target.value);
										}}></input>
								</div>
								<div>
									<span
										className='bg-success w-6 h-6 flex justify-center items-center rounded-md leading-6'
										onClick={() => setQty(Number(qty) + 1)}>
										<Plus />
									</span>
								</div>
							</div>
						</IonCol>
						<IonCol size='7'>
							<div>
								<IonButton
									onClick={() => {
										if (product?.minimum_order_quantity <= qty) {
											localStorage.removeItem("cart");
											let productQty = (product.qty = qty);
											// let status = shoppingCart.addToCart(product, qty);
											let status = productAddToCart(
												product,
												product.minimum_order_quantity,
												qty
											);
											if (status) {
												setProductSeller(product);
												alertMs("Product Add To Basket");
											} else {
												alertMs("Product Already Added To Basket");
											}
										} else {
											toast.warning("Please increase the quantity");
										}
									}}
									expand='block'
									className='bg-success rounded-xl h-12 font-sans font-bold shadow-none text-20 flex justify-between items-center'>
									<span>{ekkLanguage.productDetails.btnLabel}</span>
								</IonButton>
								{/* </Link> */}
							</div>
						</IonCol>
					</IonRow>
				) : (
					<>
						{product.type !== "offer" && (
							<div>
								<div className='flex px-3 py-3 justify-center items-center'>
									<p className='text-black-500 text-14'>
										{ekkLanguage.productDetails.preOrderQuantity +
											" :"}
									</p>
									<input
										ref={numberInputRef}
										className='appearance-none w-12 rounded-md border h-6 leading-6 ml-2 text-center'
										type='number'
										value={Number(preOrderQty)}
										onChange={(e) => {
											setPreOrderQty(e.target.value);
										}}></input>
								</div>
								<div>
									<IonButton
										onClick={preOrderHandler}
										expand='block'
										className='bg-success rounded-xl h-12 font-sans font-bold shadow-none text-20 flex justify-between items-center'>
										<span>
											{ekkLanguage.productDetails.preOrderBtn}
										</span>
									</IonButton>
								</div>
							</div>
						)}
					</>
				)}
			</IonGrid>
		</div>
	);
}
