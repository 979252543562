import { IonCol, IonGrid, IonLoading, IonRow } from "@ionic/react";
import { digitChanger } from "helpers/helper";
import { useSelector } from "react-redux";
import SectionTitle from "../Common/SectionTitle";
import RecommendProduct from "../Home/RecommendProduct";
import ProductSearch from "../SelectProduct/ProductSearch";
import SellerBrandCard from "./SellerBrandCard";
import UpdatedProduct from "../Home/UpdatedProduct";
import { getLanguage } from "config/language";

export default function BrandDetails({
  brandInfo,
  product,
  settinges,
  productBrands,
  getProductBrands,
  getProductsFromBrands,
  setSearchQ,
  searchQ,
  singleProductSearch,
  setSelectedBrand,
  setCardLoading,
  cardLoading,
  sellerData,
  getSellerProduct,
  sellerId,
  selectedSeller,
  sellerProductLoader,
  setSelectedSellerId,
  initSeller,
  setInitSeller,
  setSelectedSeller,
  setHasMoreData,
  setReachedEnd,
}) {
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const appLanguage = getLanguage();
  return (
    <div className="porduct-details">
      <IonGrid>
        <IonRow className="px-2">
          <IonCol size="12">
            <div>
              <h5 className="font-semibold text-20 text-dark pt-2">
                {brandInfo?.results?.brand_name}
              </h5>
            </div>
          </IonCol>
          <IonCol size="12" className="pb-2">
            <div className="d-flex items-baseline">
              <div>
                <h5 className="font-medium text-primary text-32">
                  {digitChanger(brandInfo?.results?.publish_products_count)}{" "}
                  {appLanguage === "bn"
                    ? "টি পণ্য"
                    : appLanguage === "vn"
                    ? "Sản phẩm"
                    : appLanguage === "my"
                    ? "Produk"
                    : "Products"}
                </h5>
              </div>
            </div>
          </IonCol>

          {/* <IonCol size='12' className='pb-6'>
						<ul className='flex justify-between'>
							<li>
								<div className='flex items-center'>
									<Star width={15} height={15} color='#FF564C' />
									<Star width={15} height={15} color='#FF564C' />
									<Star width={15} height={15} color='#FF564C' />
									<Star width={15} height={15} color='#FF564C' />
									<Star width={15} height={15} color='#00000030' />
									<div className='pl-2'>
										<p
											className='text-16'
											style={{ color: "#00000080" }}>
											4.0
										</p>
									</div>
								</div>
							</li>
							<li>
								<div>
									<p className='text-16 text-000054'>3.2k Sold</p>
								</div>
							</li>
							<li>
								<div>
									<HeartOutline />
								</div>
							</li>
							<li>
								<div>
									<Share />
								</div>
							</li>
						</ul>
					</IonCol> */}

          <IonCol size="12">
            {/* <SectionTitle
							title={ekkLanguage.brandDetails.descriptionLabel}
							innerPage={true}
						/> */}
            <div className="product-description">
              <p className="text-12 text-222222 mb-2">
                {brandInfo?.results?.description}
              </p>
            </div>
          </IonCol>
        </IonRow>
        <ProductSearch
          setSearchQ={setSearchQ}
          searchQ={searchQ}
          fetchProduct={getProductBrands}
          singleProductSearch={singleProductSearch}
          setSelectedBrand={setSelectedBrand}
        />
        <SellerBrandCard
          title={ekkLanguage.home.brandSeller}
          slideData={sellerData}
          icon="/assets/images/icon/delivery.png"
          defaultImage="/assets/images/supplier_default.png"
          nameShow={true}
          supplierNameShow={true}
          skuShow={true}
          priceShow={false}
          oldPriceShow={false}
          distShow={false}
          profitShow={false}
          sellPriceShow={false}
          link="brand-details"
          seeMoreProduct="supplier"
          productCard={false}
          type="products"
          loading={cardLoading}
          getSellerProduct={getSellerProduct}
          sellerId={sellerId}
          selectedSeller={selectedSeller}
          setSelectedBrand={setSelectedBrand}
          setSelectedSellerId={setSelectedSellerId}
          setSearchQ={setSearchQ}
          initSeller={initSeller}
          setInitSeller={setInitSeller}
          setSelectedSeller={setSelectedSeller}
          fetchProduct={getProductBrands}
          setHasMoreData={setHasMoreData}
          setReachedEnd={setReachedEnd}
        />
        {sellerProductLoader ? (
          <IonLoading
            isOpen={sellerProductLoader}
            message={ekkLanguage.orderList.loader}
          />
        ) : (
          <UpdatedProduct
            recommendData={product}
            settinges={settinges}
            title={ekkLanguage.brandDetails.productTitleLabel}
          />
        )}
      </IonGrid>
    </div>
  );
}
