import { getUser, getUserBusiness } from "config/user";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setEditEmployeeData, setMyEmployees } from "redux/container/userSlice";
import Business from "services/Business";
import EmployeesPosition from "./EmployeePosition";
import MobileNumber from "./MobileNumber";
import { toast } from "react-toastify";
import TextInputWithIcon from "../Common/TextInputWithIcon";
import { IonItem, IonSpinner } from "@ionic/react";

export default function EditEmployees() {
	let user = getUser();
	const { editEmployeeData } = useSelector((state) => state.user);

	// const [countryCode, setCountryCode] = useState(
	// 	editEmployeeData?.countryCode ?? user?.CountryCode
	// );
	// const [country, setCountry] = useState(
	// 	editEmployeeData?.country ?? user?.Country
	// );
	// const [phoneNo, setPhoneNo] = useState(editEmployeeData?.phoneNo ?? "");

	const [name, setName] = useState("");
	const [nameEmpty, setNameEmpty] = useState("");
	const [roleEmpty, setRoleEmpty] = useState("");
	const [employeePosition, setEmployeePosition] = useState(
		editEmployeeData?.title ?? "Select employee position"
	);
	const [employeePositionType, setEmployeePositionType] = useState();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let navigate = useNavigate();
	let dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);

	const employeeSubmission = async () => {
		if (name) {
			if (editEmployeeData) {
				let req_body = {
					display_name: name,
					role: editEmployeeData?.role?.id,
				};
				setIsLoading(true);
				let response = await Business.updateEmployee(
					editEmployeeData.id,
					req_body
				);
				if (response.status === 200) {
					navigate("/employees-list");
					dispatch(setMyEmployees(false));
					dispatch(setEditEmployeeData({}));
				}
				setIsLoading(false);
			}
		} else {
			setNameEmpty(ekkLanguage.createEmployees.nameError);
		}
	};

	useEffect(() => {
		if (editEmployeeData?.id) {
			setEmployeePositionType(editEmployeeData?.role?.id);
			setEmployeePosition(editEmployeeData?.role?.title);
			setName(editEmployeeData?.display_name ?? "");
		}
	}, [editEmployeeData?.id]);

	useEffect(() => {
		if (name?.length) {
			dispatch(
				setEditEmployeeData({
					...editEmployeeData,
					display_name: name,
				})
			);
		}
	}, [name]);

	return (
		<section className='px-4 pb-24 pt-14 h-screen relative'>
			<label
				className={`text-16 md:text-16 font-bold text-262626-1000 sm:pb-3 pb-[10px] block pt-4`}>
				{ekkLanguage?.createEmployees.employeeNumber}
			</label>
			<div
				className='mb-4 bg-white rounded-[5px] h-[50px] flex items-center pl-4'
				style={{ boxShadow: "rgba(0, 0, 0, 0.05) 0px 5px 20px" }}>
				<h6 className='text-success text-18 font-bold'>
					{editEmployeeData?.phone_number}
				</h6>
			</div>
			<div className='text-left mb-10'>
				<h5 className='text-dark-1000 text-16 mb-3 font-semibold'>
					{ekkLanguage.createEmployees.name}
				</h5>

				<TextInputWithIcon
					type='text'
					inputText={name}
					setInputText={setName}
					placeholder={ekkLanguage.createEmployees.namePlaceholder}
				/>
				{nameEmpty && (
					<div className='text-primary mt-2 text-right'>{nameEmpty}</div>
				)}
			</div>
			<EmployeesPosition
				employeePosition={employeePosition}
				setEmployeePosition={setEmployeePosition}
				roleEmpty={roleEmpty}
				setRoleEmpty={setRoleEmpty}
				setEmployeePositionType={setEmployeePositionType}
				employees={editEmployeeData}
			/>

			<div>
				<div className='text-center  w-full pt-[30px]'>
					<button
						onClick={!isLoading && employeeSubmission}
						style={{ background: "#00B224" }}
						className={`text-18 sm:text-18 font-semibold text-white  h-[50px] sm:h-[50px] rounded-[5px] w-full flex justify-center items-center`}>
						{isLoading ? (
							<IonSpinner color='light' />
						) : (
							ekkLanguage?.createEmployees.confirmBtn
						)}
					</button>
				</div>
			</div>
		</section>
	);
}
