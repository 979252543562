import {
	IonButton,
	IonItem,
	IonLabel,
	IonModal,
	IonRadio,
	IonRadioGroup,
} from "@ionic/react";
import Add from "assets/images/icon/svg/Add";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useSelector } from "react-redux";
import Card from "../Common/Card";
import Business from "services/Business";

export default function EmployeesPosition({
	employeePosition,
	setEmployeePosition,
	roleEmpty,
	setRoleEmpty,
	setEmployeePositionType,
}) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const employeeRef = useRef(null);
	const [employeePositions, setEmployeePositions] = useState([]);
	const employeeModalClose = () => {
		employeeRef.current?.dismiss();
	};

	const getEmployeePosition = async () => {
		let response = await Business.getEmployeePosition();
		if (response.status === 200) {
			setEmployeePositions(response.results);
		} else {
			setEmployeePosition([]);
		}
	};
	useEffect(() => {
		getEmployeePosition();
	}, []);

	return (
		<div className='pt-4 pb-14'>
			<div>
				<div
					className={`bg-F3F3F3 p-2 py-3 block rounded-[5px] border border-black-300 overflow-hidden`}
					id='open-employee-modal'>
					<div className='flex justify-between items-center'>
						<div className='flex'>
							<p className={`text-16 text-black-500 font-medium ml-2`}>
								{employeePosition}
							</p>
						</div>
						<div>
							<Add color={"#00000080"} />
						</div>
					</div>
				</div>
				{roleEmpty && (
					<div className='text-primary mt-2 text-right'>{roleEmpty}</div>
				)}
			</div>
			<IonModal
				className='paymentTrmsModal'
				trigger='open-employee-modal'
				showBackdrop={true}
				ref={employeeRef}>
				<div>
					<Card
						title={ekkLanguage.createEmployees.selectEmployees}
						onHide={true}
						closeModal={employeeModalClose}>
						<div>
							<div className='pt-5'>
								<IonRadioGroup
									value={employeePosition}
									onIonChange={(e) => {
										setEmployeePosition(e.detail.value);
										setRoleEmpty("");
										let type = employeePositions.find(
											(el) => el.title === e.detail.value
										);

										setEmployeePositionType(type.id);
									}}>
									{employeePositions?.map((el, i) => {
										return (
											<IonItem
												key={el.id}
												lines='none'
												className='bg-white border rounded-[10px] mb-4 no-ripple relative'>
												<IonLabel className='w-full absolute left-8 border-0 font-sans text-14 text-black-500 font-medium'>
													<span>{el.title}</span>
												</IonLabel>
												<IonRadio
													// slot='start'
													value={el.title}
													color='success'
												/>
											</IonItem>
										);
									})}
								</IonRadioGroup>
							</div>
							<div className='text-center pt-0'>
								<IonButton
									color='success'
									onClick={employeeModalClose}
									// disabled={true}
								>
									{ekkLanguage.createOrder.btnLabel}
								</IonButton>
							</div>
						</div>
					</Card>
				</div>
			</IonModal>
		</div>
	);
}
