import {
	IonApp,
	IonContent,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonLoading,
	IonPage,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import ProductSearch from "components/mobile-components/Common/ProductSearch";
import ProductList from "components/mobile-components/products/ProductList";
import SellerFilter from "components/mobile-components/products/SellerFilter";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { setSupplier } from "redux/container/supplierSlice";
import { setLocation } from "redux/container/userSlice";
import Supplier from "services/Supplier";
import TitleNSearch from "components/mobile-components/Common/TitleNSearch";

export default function SellerListPage() {
	let { category } = useParams();
	const [sellerData, setSellerData] = useState("");
	const [pageCount, setPageCount] = useState(2);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [loading, setLoading] = useState(true);
	const [searchQ, setSearchQ] = useState("");
	const dispatch = useDispatch();
	const location = useSelector(
		(state) => state.user?.locationData?.coordinates
	);
	const [hasMoreData, setHasMoreData] = useState(true);
	const [reachedEnd, setReachedEnd] = useState(false);
	const locatedSeller = useSelector((state) => state.supplier.data);
	const [tempSearchParams, setTempSearchParams] = useState(false);

	let settings = {
		nameShow: false,
		skuShow: false,
		priceShow: false,
		oldPriceShow: false,
		distShow: true,
		profitShow: false,
		sellPriceShow: false,
		supplierNameShow: true,
		newPriceShow: false,
		defaultImage: "/assets/images/supplier_default.png",
		placeHolder: ekkLanguage.seeMoreProduct.supplierSearchPlaceholder,
		nearBySupplierNotFound: ekkLanguage.sellerList.nearBySupplierNotFound,
		supplierNotFound: ekkLanguage.sellerList.supplierNotFound,
	};

	//get suppliers data
	let getSupplierList = async () => {
		setPageCount(2);
		// dispatch(setSupplier(true));
		setLoading(true);
		let response = searchQ
			? await Supplier.supplierSearch(searchQ, location[1], location[0])
			: await Supplier.getSupplierList(location[1], location[0]);

		if (response.status === 200) {
			setLoading(false);
			setSellerData(response.results);
		} else {
			setLoading(false);
		}
	};
	const getAllSupplierList = async () => {
		// dispatch(setSupplier(false));
		setPageCount(2);
		setLoading(true);
		let response = searchQ
			? await Supplier.allSupplierSearch(location[1], location[0], searchQ)
			: await Supplier.getAllSupplierList(location[1], location[0]);

		if (response.status === 200) {
			setLoading(false);
			setSellerData(response.results);
		} else {
			setLoading(false);
		}
	};

	let singleSellerSearch = async (s) => {
		setTempSearchParams(searchQ);
		setPageCount(2);
		setLoading(true);
		let response = locatedSeller
			? await Supplier.supplierSearch(searchQ, location[1], location[0])
			: await Supplier.allSupplierSearch(location[1], location[0], searchQ);
		if (response.status === 200) {
			setLoading(false);
			setSellerData(response.results);
		} else {
			setLoading(false);
		}
	};

	let loadMoreData = async () => {
		if (hasMoreData) {
			if (searchQ) {
				if (locatedSeller) {
					let response = await Supplier.supplierSearch(
						searchQ,
						location[1],
						location[0],
						pageCount
					);
					if (response.status === 200) {
						setPageCount((prev) => prev + 1);
						setSellerData([...sellerData, ...response.results]);
					} else {
						setHasMoreData(false);
					}
				} else {
					const response = await Supplier.allSupplierSearch(
						location[1],
						location[0],
						searchQ,
						pageCount
					);
					if (response.status === 200) {
						setPageCount((prev) => prev + 1);
						setSellerData([...sellerData, ...response.results]);
					} else {
						setHasMoreData(false);
					}
				}
			} else {
				if (locatedSeller) {
					const response = await Supplier.getSupplierList(
						location[1],
						location[0],
						pageCount
					);
					if (response.status === 200) {
						setPageCount((prev) => prev + 1);
						setSellerData([...sellerData, ...response.results]);
					} else {
						setHasMoreData(false);
					}
				} else {
					const response = await Supplier.getAllSupplierList(
						location[1],
						location[0],
						pageCount
					);
					if (response.status === 200) {
						setPageCount((prev) => prev + 1);
						setSellerData([...sellerData, ...response.results]);
					} else {
						setHasMoreData(false);
					}
				}
			}
		}
	};

	let getLocation = () => {
		if (navigator.geolocation) {
			let location = navigator.geolocation;
			if (location && Object.keys(location).length === 0) {
				navigator.geolocation.getCurrentPosition((p, e) => {
					// getSupplierList(p.coords.longitude, p.coords.latitude);
					dispatch(
						setLocation({
							type: "Point",
							coordinates: [p.coords.latitude, p.coords.longitude],
						})
					);
				});
			}
		} else {
			toast.error("Geolocation is not supported by this browser.");
		}
	};
	useEffect(() => {
		getLocation();
	}, []);

	useEffect(() => {
		if (searchQ === "") {
			locatedSeller
				? getSupplierList(location[1], location[0])
				: getAllSupplierList(location[1], location[0]);

			setTempSearchParams("");
		}
	}, [searchQ]);

	useEffect(() => {
		getInitList();
	}, [locatedSeller]);

	const getInitList = () => {
		if (locatedSeller) {
			getSupplierList();
		} else {
			getAllSupplierList();
		}
	};
	const deleteQueryHandler = async (e) => {
		getInitList();
		setSearchQ("");
	};

	return (
		<IonApp>
			<IonPage>
				<IonContent scrollEvents={true}>
					<TitleNSearch
						title={"Supplier List"}
						placeholder={settings?.placeHolder}
						searchQ={searchQ}
						setSearchQ={setSearchQ}
						fetchSearchData={singleSellerSearch}
						initData={getInitList}
						deleteQuery={deleteQueryHandler}
						inputType='text'
					/>

					<section className='pt-14'>
						<div className='pt-4 px-4 pb-4'>
							<SellerFilter
								ekkLanguage={ekkLanguage}
								setReachedEnd={setReachedEnd}
								setHasMoreData={setHasMoreData}
							/>
						</div>
						{loading ? (
							<IonLoading
								isOpen={loading}
								message={ekkLanguage.orderList.loader}
							/>
						) : (
							<ProductList
								locatedSeller={locatedSeller}
								loading={loading}
								productData={sellerData}
								category={category}
								settings={settings}
								link={"supplier-details"}
								ekkLanguage={ekkLanguage}
								identity='seller'
							/>
						)}
					</section>
					<IonInfiniteScroll
						onIonInfinite={(ev) => {
							if (reachedEnd) {
								ev.target.complete();
								return;
							}
							loadMoreData();
							setTimeout(() => {
								ev.target.complete();
								if (!hasMoreData) {
									setReachedEnd(true);
								}
							}, 500);
						}}>
						<IonInfiniteScrollContent
							loadingText={ekkLanguage.orderList.loader}
							loadingSpinner={
								hasMoreData ? "bubbles" : null
							}></IonInfiniteScrollContent>
					</IonInfiniteScroll>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
