import {
	IonApp,
	IonContent,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonPage,
} from "@ionic/react";
import BrandDetails from "components/mobile-components/BrandDetails/BrandDetails";
import BackButton from "components/mobile-components/Common/BackButton";
import NavFooter from "components/mobile-components/Common/NavFooter";
import Banner from "components/mobile-components/ProductDetails/Banner";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Brand from "services/Brand";
import Product from "services/Product";

export default function BrandDetailsPage() {
	let { id } = useParams();
	const [product, setProduct] = useState([]);
	const [brandInfo, setBrandInfo] = useState([]);
	const [initHeight] = useState(72);
	const [initStickyClass, setInitStikyClass] = useState("");
	const [pageCount, setPageCount] = useState(2);
	const [metaCount, setMetaCount] = useState(30);
	const [productBrands, setProductBrands] = useState([]);
	const [searchQ, setSearchQ] = useState("");
	const [selectedBrand, setSelectedBrand] = useState("");
	const [cardLoading, setCardLoading] = useState(false);
	const [sellerData, setSellerData] = useState([]);
	const [selectedSeller, setSelectedSeller] = useState("");
	const [sellerProductLoader, setSellerProductLoader] = useState(false);
	const [selectedSellerId, setSelectedSellerId] = useState("");
	const [initSeller, setInitSeller] = useState(true);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [hasMoreData, setHasMoreData] = useState(true);
	const [reachedEnd, setReachedEnd] = useState(false);

	//get brand data
	let getSellerData = async () => {
		setCardLoading(true);
		let response = await Brand.getSellerData(id);
		if (response.status === 200) {
			setCardLoading(false);
			setSellerData(response.results);
		} else {
			setCardLoading(false);
		}
	};
	useEffect(() => {
		getSellerData();
	}, []);

	let getSellerProduct = async (sellerId) => {
		setPageCount(2);
		setSellerProductLoader(true);
		setSelectedSeller(sellerId);
		if (searchQ) {
			let response = await Product.singleProductSearch(
				id,
				sellerId,
				searchQ
			);
			if (response.status === 200) {
				setSellerProductLoader(false);
				setProduct(response.results);
				setMetaCount(response.count);
			} else {
				setSellerProductLoader(false);
			}
		} else {
			let response = await Brand.getSellerProduct(id, sellerId);
			if (response.status === 200) {
				setProduct(response.results);
				setSellerProductLoader(false);
			} else {
				setSellerProductLoader(false);
			}
		}
	};

	//get brand data
	let brandData = async () => {
		let response = await Brand.singleBrandDetails(id);
		if (response.status === 200) {
			setBrandInfo(response);
		} else {
		}
	};
	useEffect(() => {
		brandData();
	}, []);

	//get suppliers data
	let productData = async () => {
		setPageCount(2);
		setSellerProductLoader(true);
		if (searchQ) {
			let response = await Product.brandProductSearch(id, searchQ);
			if (response.status === 200) {
				setSellerProductLoader(false);
				setProduct(response.results);
			} else {
				setSellerProductLoader(false);
			}
		} else {
			let response = await Brand.brandProducts(id);
			if (response.status === 200) {
				setProduct(response.results);
				setSellerProductLoader(false);
			} else {
				setSellerProductLoader(false);
			}
		}
	};
	useEffect(() => {
		// productData();
	}, []);

	const contentRef = useRef();
	let settinges = {
		nameShow: false,
		titleShow: true,
		skuShow: false,
		priceShow: true,
		oldPriceShow: false,
		distShow: false,
		profitShow: true,
		sellPriceShow: false,
		supplierNameShow: false,
		link: "product-details",
	};

	let slideData = brandInfo?.results?.images?.length
		? [...brandInfo?.results?.images]
		: ["/assets/images/badge_big_default.png"];

	async function getProductsFromBrands(Bid) {
		setPageCount(2);
		setSellerProductLoader(true);
		setSelectedBrand(Bid);
		try {
			const response = await Product.singleBrandProducts(id, Bid);
			if (response.status === 200) {
				setProduct(response?.results);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setSellerProductLoader(false);
		}
	}
	//brandProductSearch;
	async function singleProductSearch() {
		setPageCount(2);
		try {
			let response;
			if (selectedSellerId) {
				response = await Product.singleProductSearch(
					id,
					selectedSellerId,
					searchQ
				);
			} else {
				response = await Product.brandProductSearch(id, searchQ);
			}

			if (response.status === 200) {
				setProduct(response.results);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setSellerProductLoader(false);
		}
	}

	useEffect(() => {
		if (searchQ === "") {
			productData();
		}
	}, [searchQ]);

	let loadMoreData = async () => {
		if (hasMoreData) {
			if (searchQ) {
				if (selectedSeller) {
					let response = await Product.singleProductSearch(
						id,
						selectedSellerId,
						searchQ,
						pageCount
					);
					if (response.status === 200) {
						if (response.results.length > 0) {
							setPageCount((prev) => prev + 1);
							setProduct([...product, ...response.results]);
							setMetaCount(response.count);
						} else {
							setHasMoreData(false);
						}
					} else {
						setHasMoreData(false);
					}
				} else {
					let response = await Product.brandProductSearch(
						id,
						searchQ,
						pageCount
					);
					if (response.status === 200) {
						if (response.results.length > 0) {
							setPageCount((prev) => prev + 1);
							setProduct([...product, ...response.results]);
							setMetaCount(response.count);
						} else {
							setHasMoreData(false);
						}
					} else {
						setHasMoreData(false);
					}
				}
			} else {
				if (selectedSeller) {
					let response = await Brand.getSellerProduct(
						id,
						selectedSeller,
						pageCount
					);
					if (response.status === 200) {
						if (response.results.length > 0) {
							setPageCount((prev) => prev + 1);
							setProduct([...product, ...response.results]);
							setMetaCount(response.count);
						} else {
							setHasMoreData(false);
						}
					} else {
						setHasMoreData(false);
					}
				} else {
					let response = await Brand.brandProducts(id, pageCount);
					if (response.status === 200) {
						if (response.results.length > 0) {
							setPageCount((prev) => prev + 1);
							setProduct([...product, ...response.results]);
							setMetaCount(response.count);
						} else {
							setHasMoreData(false);
						}
					} else {
						setHasMoreData(false);
					}
				}
			}
		}
	};

	async function searchNext(event) {
		let timeout = setTimeout(() => {
			loadMoreData();
			// event.target.complete();
			clearTimeout(timeout);
		}, 0);
	}

	return (
		<IonApp>
			<IonPage>
				<IonContent
					scrollEvents={true}
					ref={contentRef}
					onIonScroll={async (ev) => {
						const scrollPosition = ev.detail.scrollTop;
						if (scrollPosition > initHeight) {
							setInitStikyClass("issticky");
						} else {
							setInitStikyClass("");
						}
					}}
					// onIonScrollEnd={(ev) => {
					// 	if (Number(metaCount) / 10 > pageCount) {
					// 		searchNext(ev);
					// 	} else {
					// 		document.querySelector(".infinite-loading").style.display =
					// 			"none";
					// 	}
					// }}
				>
					<BackButton sticky={initStickyClass} />
					<Banner slideData={slideData} />
					<BrandDetails
						brandInfo={brandInfo}
						product={product}
						settinges={settinges}
						productBrands={productBrands}
						getProductBrands={productData}
						getProductsFromBrands={getProductsFromBrands}
						setSearchQ={setSearchQ}
						searchQ={searchQ}
						singleProductSearch={singleProductSearch}
						setSelectedBrand={setSelectedBrand}
						setCardLoading={setCardLoading}
						cardLoading={cardLoading}
						sellerData={sellerData}
						getSellerProduct={getSellerProduct}
						sellerId={id}
						selectedSeller={selectedSeller}
						sellerProductLoader={sellerProductLoader}
						setSelectedSellerId={setSelectedSellerId}
						initSeller={initSeller}
						setInitSeller={setInitSeller}
						setSelectedSeller={setSelectedSeller}
						setHasMoreData={setHasMoreData}
						setReachedEnd={setReachedEnd}
					/>
					<IonInfiniteScroll
						onIonInfinite={(ev) => {
							if (reachedEnd) {
								ev.target.complete();
								return;
							}
							loadMoreData();
							setTimeout(() => {
								ev.target.complete();
								if (!hasMoreData) {
									setReachedEnd(true);
								}
							}, 500);
						}}>
						<IonInfiniteScrollContent
							loadingText={ekkLanguage.orderList.loader}
							loadingSpinner={
								hasMoreData ? "bubbles" : null
							}></IonInfiniteScrollContent>
					</IonInfiniteScroll>
				</IonContent>
				<NavFooter />
			</IonPage>
		</IonApp>
	);
}
