import {
	IonApp,
	IonContent,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonLoading,
	IonPage,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import NavFooter from "components/mobile-components/Common/NavFooter";
import ProductSearch from "components/mobile-components/Common/ProductSearch";
import ProductList from "components/mobile-components/products/ProductList";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { setLocation } from "redux/container/userSlice";
import Brand from "services/Brand";
import Product from "services/Product";
import Supplier from "services/Supplier";
import TitleNSearch from "components/mobile-components/Common/TitleNSearch";

export default function SeeMoreProductPage() {
	let { category } = useParams();
	const [brandData, setBrandData] = useState([]);
	const [pageCount, setPageCount] = useState(2);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [loading, setLoading] = useState(true);
	let [searchParams] = useSearchParams();
	const [searchQ, setSearchQ] = useState("");
	const dispatch = useDispatch();
	const location = useSelector(
		(state) => state.user?.locationData?.coordinates
	);
	const [hasMoreData, setHasMoreData] = useState(true);
	const [reachedEnd, setReachedEnd] = useState(false);
	const [tempSearchParams, setTempSearchParams] = useState(false);
	let title;
	let settings;
	let link;

	//get offer productList
	let getOfferList = async () => {
		setLoading(true);
		let response = await Product.offerProduct();
		if (response.status === 200) {
			setLoading(false);
			setBrandData(response.results);
		} else {
			setLoading(false);
		}
	};

	//get brand productList
	let getBrandList = async () => {
		setLoading(true);
		let response = await Brand.getBrandList();
		if (response.status === 200) {
			setBrandData(response.results);
			setLoading(false);
		} else {
			setLoading(false);
		}
	};
	//get suppliers data
	let getSupplierList = async (long, lat) => {
		setLoading(true);
		let response = await Supplier.getSupplierList(long, lat);
		if (response.status === 200) {
			setLoading(false);
			setBrandData(response.results);
		} else {
			setLoading(false);
		}
	};
	//get selected suppliers data
	let getSelectedSupplierList = async () => {
		setLoading(true);
		let response = await Supplier.getSelectedSupplierList(
			searchParams.get("sellerId")
		);
		if (response.status === 200) {
			setLoading(false);
			setBrandData(response.data);
		} else {
			setLoading(false);
		}
	};
	//get suppliers data
	let getMySupplierList = async () => {
		let response = await Supplier.getMySupplierList();

		if (response.status === 200) {
			const supplier = [];
			response.data.forEach((element) => {
				supplier.push(element.related_business);
			});
			setBrandData(supplier);
		} else {
		}
	};

	useEffect(() => {
		if (category === "brand") {
			getBrandList();
		} else if (category === "supplier" && searchParams.get("sellerId")) {
			getSelectedSupplierList();
		} else if (category === "mysupplier") {
			getMySupplierList();
		} else if (category === "offer") {
			getOfferList();
		}
	}, []);

	const getInitList = () => {
		if (category === "brand") {
			return getBrandList();
		} else if (category === "supplier" && searchParams.get("sellerId")) {
			return getSelectedSupplierList();
		} else if (category === "mysupplier") {
			return getMySupplierList();
		} else if (category === "offer") {
			return getOfferList();
		}
	};

	if (category === "brand") {
		title = ekkLanguage.home.brandProductPageTitle;
		link = "brand-details";
		settings = {
			nameShow: true,
			skuShow: true,
			priceShow: false,
			oldPriceShow: false,
			distShow: false,
			profitShow: false,
			sellPriceShow: false,
			supplierNameShow: false,
			newPriceShow: false,
			defaultImage: "/assets/images/badge_default.png",
			placeHolder: ekkLanguage.seeMoreProduct.brandsSearchPlaceholder,
		};
	} else if (category === "offer") {
		title = ekkLanguage.home.offerProductPageTitle;
		link = "product-details";
		settings = {
			nameShow: true,
			skuShow: false,
			priceShow: false,
			oldPriceShow: false,
			distShow: false,
			profitShow: true,
			sellPriceShow: false,
			supplierNameShow: false,
			newPriceShow: false,
			defaultImage: "/assets/images/offer_default.png",
			showOfferPrice: true,
			type: "offer",
			productType: "offers",
			emptyText: ekkLanguage.home.offerProductNotFound,
			placeHolder: ekkLanguage.seeMoreProduct.offerSearchPlaceholder,
		};
	} else if (category === "featured") {
		title = "Featured Products";
	} else if (category === "supplier" && searchParams.get("sellerId")) {
		title = ekkLanguage.home.nearSuppliersLabel;
		link = "supplier-details";
		settings = {
			nameShow: false,
			skuShow: false,
			priceShow: false,
			oldPriceShow: false,
			distShow: true,
			profitShow: false,
			sellPriceShow: false,
			supplierNameShow: true,
			newPriceShow: false,
			defaultImage: "/assets/images/supplier_default.png",
			placeHolder: ekkLanguage.seeMoreProduct.supplierSearchPlaceholder,
		};
	} else if (category === "supplier") {
		title = ekkLanguage.home.nearSuppliersLabel;
		link = "supplier-details";
		settings = {
			nameShow: false,
			skuShow: false,
			priceShow: false,
			oldPriceShow: false,
			distShow: true,
			profitShow: false,
			sellPriceShow: false,
			supplierNameShow: true,
			newPriceShow: false,
			defaultImage: "/assets/images/supplier_default.png",
			placeHolder: ekkLanguage.seeMoreProduct.supplierSearchPlaceholder,
		};
	} else if (category === "mysupplier") {
		title = ekkLanguage.home.mySuppliersLabel;
		link = "mysupplier-details";
		settings = {
			nameShow: false,
			skuShow: false,
			priceShow: false,
			oldPriceShow: false,
			distShow: true,
			profitShow: false,
			sellPriceShow: false,
			supplierNameShow: true,
			newPriceShow: false,
			defaultImage: "/assets/images/supplier_default.png",
			emptyText: "Sorry, No suppliers found nearby.",
		};
	} else if (category === "popular") {
		title = "Popular Products";
	}

	//items search
	const itemsSearch = async () => {
		setTempSearchParams(searchQ);
		setPageCount(2);
		setLoading(true);
		if (category === "brand") {
			let response = await Brand.brandsSearch(searchQ);
			if (response.status === 200) {
				setLoading(false);
				setBrandData(response.results);
			}
		} else if (category === "supplier") {
			let response = await Supplier.supplierSearch(
				searchQ,
				location[1],
				location[0]
			);
			if (response.status === 200) {
				setLoading(false);
				setBrandData(response.results);
			}
		} else if (category === "offer") {
			let response = await Product.offerSearch(searchQ);
			if (response.status === 200) {
				setLoading(false);
				setBrandData(response.results);
			}
		}
	};

	const loadMoreData = async () => {
		if (hasMoreData) {
			let response;
			if (category === "brand") {
				response = searchQ
					? await Brand.brandsSearch(searchQ, pageCount)
					: await Brand.getBrandList(pageCount);
			} else if (category === "supplier") {
				response = searchQ
					? await Supplier.supplierSearch(
							searchQ,
							location[1],
							location[0],
							pageCount
					  )
					: await Supplier.getSupplierList(
							location[1],
							location[0],
							pageCount
					  );
			} else if (category === "offer") {
				response = searchQ
					? await Product.offerSearch(searchQ, pageCount)
					: await Product.offerProduct(pageCount);
			}

			if (response.status === 200 && response.results.length > 0) {
				setPageCount((prev) => prev + 1);
				setBrandData([...brandData, ...response.results]);
			} else {
				setHasMoreData(false);
			}
		}
	};

	useEffect(() => {
		if (searchQ === "") {
			if (category === "brand") {
				getBrandList();
			} else if (category === "supplier") {
				getSupplierList(location[1], location[0]);
			}
			setTempSearchParams("");
		}
	}, [searchQ]);

	let getLocation = () => {
		if (navigator.geolocation) {
			let location = navigator.geolocation;
			if (location && Object.keys(location).length === 0) {
				navigator.geolocation.getCurrentPosition((p, e) => {
					if (category === "supplier") {
						getSupplierList(p.coords.longitude, p.coords.latitude);
					}

					dispatch(
						setLocation({
							type: "Point",
							coordinates: [p.coords.latitude, p.coords.longitude],
						})
					);
				});
			}
		} else {
			toast.error("Geolocation is not supported by this browser.");
		}
	};
	useEffect(() => {
		getLocation();
	}, []);

	const deleteQueryHandler = async (e) => {
		setSearchQ("");
	};

	return (
		<IonApp>
			<IonPage>
				<IonContent scrollEvents={true}>
					<TitleNSearch
						title={title}
						placeholder={settings?.placeHolder}
						searchQ={searchQ}
						setSearchQ={setSearchQ}
						fetchSearchData={itemsSearch}
						initData={getInitList}
						deleteQuery={deleteQueryHandler}
						inputType='text'
					/>
					<section className='pt-14'>
						{loading ? (
							<IonLoading
								isOpen={loading}
								message={ekkLanguage.orderList.loader}
							/>
						) : (
							<ProductList
								productData={brandData}
								category={category}
								settings={settings}
								link={link}
							/>
						)}
					</section>
					<IonInfiniteScroll
						onIonInfinite={(ev) => {
							if (reachedEnd) {
								ev.target.complete();
								return;
							}
							loadMoreData();
							setTimeout(() => {
								ev.target.complete();
								if (!hasMoreData) {
									setReachedEnd(true);
								}
							}, 1000);
						}}>
						<IonInfiniteScrollContent
							loadingText={ekkLanguage.orderList.loader}
							loadingSpinner={
								hasMoreData ? "bubbles" : null
							}></IonInfiniteScrollContent>
					</IonInfiniteScroll>
				</IonContent>
				<NavFooter />
			</IonPage>
		</IonApp>
	);
}
