import { baseUrl } from "config/apiUrl";
import SuperFetch from "config/SuperFetch";

const Loan = {
	// getAccountGroup: (q) => {
	// 	return SuperFetch(`${baseUrl.account}/acc-group/`);
	// },
	getCreditScore: (page = 1, limit = 100) => {
		return SuperFetch(
			`${baseUrl.fs}/ekkbaz/credit-score/?page=${page}&limit=${limit}`
		);
	},
	createLoanRequest: (query) => {
		return SuperFetch(`${baseUrl.fs}/ekkbaz/loan-request/`, {
			method: "POST",
			data: query,
		});
	},
	getRequestedLoan: (page = 1, limit = 10) => {
		return SuperFetch(
			`${baseUrl.fs}/ekkbaz/loan-request/?page=${page}&limit=${limit}`
		);
	},
};
export default Loan;
