import { IonCol, IonGrid, IonRow, IonCheckbox, IonLoading } from "@ionic/react";
import { getProductSeller, getUserBusiness } from "config/user";
import {
	cartTotalAmount,
	productAddToCart,
	removeCartProduct,
	shoppingListItem,
} from "helpers/shoppingCart";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";

import { useDispatch, useSelector } from "react-redux";
import { subtotalAmount, totalAmount } from "redux/container/shoppingCartSlice";
import { digitChanger } from "helpers/helper";
import CancelPopUp from "assets/images/icon/svg/CancelPopUp";
import StockOut from "assets/images/icon/svg/StockOut";

export default function ProductList({
	productList,
	setProductList,
	productCartList,
	setProductCartList,
	loading,
}) {
	const dispatch = useDispatch();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [open, isOpen] = useState(false);
	const onOpen = () => isOpen(true);
	const onClose = () => isOpen(false);
	// function add product
	function addProductOnCart(product) {
		product.seller_business_id = getProductSeller().seller_business_id;
		if (productCartList.find((element) => element.id === product.id)) {
			removeCartProduct(product);
			setProductCartList(shoppingListItem);
			dispatch(totalAmount(cartTotalAmount()));
			dispatch(subtotalAmount(cartTotalAmount()));
		} else {
			productAddToCart(
				product,
				product.quantity,
				product.minimum_order_quantity
			);
			setProductCartList(shoppingListItem);
		}
	}
	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "300px",
			transition: ".4s all ease",
			borderRadius: 12,
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};

	return (
		<div className='px-4' style={{ paddingBottom: 40 }}>
			<IonGrid className='bg-white rounded-md'>
				{loading ? (
					<IonLoading
						isOpen={loading}
						message={ekkLanguage.orderList.loader}
					/>
				) : (
					<IonRow>
						{productList?.length ? (
							productList.map((product, index) => {
								const imageChecker = () => {
									if (product.images[0]) {
										return `${product.images[0]}`;
									} else {
										if (product.type === "variant") {
											return "/assets/images/varient_default.png";
										} else if (product.type === "pack") {
											return "/assets/images/pack_default.png";
										} else if (product.type === "offer") {
											return "/assets/images/offer_default.png";
										}
									}
								};
								return (
									<IonCol size='6' className='mb-4' key={index}>
										<div
											className='rounded-md'
											onClick={() => {
												if (product.inventory_quantity >= 1) {
													addProductOnCart(
														product,
														product.minimum_order_quantity
													);
													dispatch(totalAmount(cartTotalAmount()));
													dispatch(
														subtotalAmount(cartTotalAmount())
													);
												} else {
													onOpen();
												}
											}}>
											<div className='rounded-xl overflow-hidden bg-E6E6E6 p-3 mb-1 relative'>
												{product?.inventory_quantity <= 0 && (
													<span className='quantity-badge text-white font-bold absolute z-10 text-12'>
														<StockOut />
													</span>
												)}
												<span
													className='absolute'
													style={{ top: -1, right: -1 }}>
													{productCartList.find(
														(element) => element.id === product.id
													) && (
														<IonCheckbox
															color='success'
															className='rounded select-product'
															checked={true}
														/>
													)}
												</span>

												<img
													src={imageChecker()}
													alt='productImage'
													className='w-full image-sizer'
												/>
											</div>

											<div>
												<h5 className='font-semibold text-16'>
													{product.title}
												</h5>

												<span className='font-bold text-12 text-primary'>
													{
														product?.country
															?.currency_symbol_native
													}{" "}
													{digitChanger(product.base_price)}
												</span>
											</div>
										</div>
									</IonCol>
								);
							})
						) : (
							<>
								<IonCol size='2'></IonCol>
								<IonCol size='8'>
									<div
										className='text-center flex items-center justify-center'
										style={{ height: "calc(100vh - 420px)" }}>
										<div>
											<img
												className='m-auto mb-[10px] mt-5'
												src='/assets/images/not-found-file.png'
												alt='emptyCard'
											/>
											<p className='text-16 font-normal text-222222 mb-[35px]'>
												{ekkLanguage.orderList.notFound}
											</p>
										</div>
									</div>
								</IonCol>
							</>
						)}
					</IonRow>
				)}
			</IonGrid>
			{/* Payment Method conformation modal*/}
			<Modal
				style={customStyles}
				closeTimeoutMS={200}
				isOpen={open}
				onRequestClose={onClose}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2'>
						<CancelPopUp />
					</span>
					<h6 className='text-16 font-bold pb-[20px]'>
						{ekkLanguage.createOrder.importentMessage}
					</h6>
					<h6 className='text-16 font-semibold pb-[20px]'>
						{ekkLanguage.createOrder.outOffStock}
					</h6>

					<div className='flex justify-end'>
						<button
							color='primary'
							className='font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-primary text-white'
							onClick={onClose}>
							{ekkLanguage.createOrder.okBtnLabel}
						</button>
					</div>
				</div>
			</Modal>
		</div>
	);
}
