import { IonCheckbox, IonCol, IonGrid, IonRow, IonSpinner } from "@ionic/react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import "../Home/style.scss";
import SingleBrandSeller from "./SingleBrandSeller";
import Van from "assets/images/icon/svg/Van";

export default function SellerBrandCard({
	title,
	slideData,
	icon,
	nameShow,
	skuShow,
	priceShow,
	oldPriceShow,
	distShow,
	profitShow,
	sellPriceShow,
	supplierNameShow,
	link,
	seeMoreProduct,
	defaultImage,
	type,
	emptyText,
	loading,
	getSellerProduct,
	sellerId,
	selectedSeller,
	setSelectedBrand,
	setSelectedSellerId,
	setSearchQ,
	initSeller,
	setInitSeller,
	setSelectedSeller,
	fetchProduct,
	setHasMoreData,
	setReachedEnd,
}) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<div className='home-product-card px-2 pb-7'>
			<IonGrid className='bg-white rounded-lg'>
				<IonRow>
					<IonCol size='12'>
						<div className='flex justify-between'>
							<div>
								<Link
									// to={
									// 	slideData?.length > 0
									// 		? `/products/${seeMoreProduct}?sellerId=${sellerId}`
									// 		: "#"
									// }
									to={slideData?.length > 0 ? `#` : "#"}
									className='flex items-center'>
									<Van />
									{/* <img src={icon} className='pr-2' alt='icon' /> */}
									<h5 className={`font-semibold text-16 ml-2`}>
										{title}
									</h5>
								</Link>
							</div>
							{/* {slideData?.length > 0 && (
								<Link to={`#`}>
									<IonButton
										size='small'
										fill='text'
										className='p-0 text-success font-semibold text-12 font-sans'>
										{ekkLanguage.home.showMoreLabel}
									</IonButton>
								</Link>
							)} */}
						</div>
					</IonCol>
					{loading ? (
						<div className='flex items-center justify-center h-[156px] m-auto'>
							{" "}
							<IonSpinner name='crescent'></IonSpinner>
						</div>
					) : (
						<>
							{" "}
							{slideData?.length ? (
								<IonCol>
									<div className='product-slider'>
										<Swiper
											key='swiper'
											spaceBetween={15}
											slidesPerView={2.5}
											grabCursor={true}
											breakpoints={{
												260: {
													slidesPerView: 2,
												},
												320: {
													slidesPerView: 3,
												},
												360: {
													slidesPerView: 3,
												},
												575: {
													slidesPerView: 4,
												},
												768: {
													slidesPerView: 5,
												},
											}}>
											<SwiperSlide key='all-brands'>
												<div className={`rounded-md `}>
													<div className='rounded-xl overflow-hidden bg-E6E6E6 p-3 mb-[5px]'>
														{initSeller && (
															<div className='absolute top-0 left-0'>
																<IonCheckbox
																	color='success'
																	className='rounded select-product'
																	checked={true}
																/>
															</div>
														)}
														{/* <img
															onClick={() => {
																fetchProduct();
																setSelectedSeller("");
																setInitSeller(true);
																setSelectedSellerId("");
															}}
															src='/assets/images/supplier_default.png'
															alt='productImage'
															className='w-full home-card-image-sizer'
														/> */}
														<div
															className=' home-card-image-sizer text-center flex items-center justify-center'
															onClick={() => {
																fetchProduct();
																setSelectedSeller("");
																setInitSeller(true);
																setSelectedSellerId("");
																setHasMoreData(true);
																setReachedEnd(false);
															}}>
															<h3
																className='font-bold capitalize text-22'
																style={{
																	wordBreak: "break-word",
																}}>
																{/* {
																	supplierName
																		.split(" ")[0]
																		.split("/")[0]
																} */}
																{
																	ekkLanguage.home.allBrandSeller.split(
																		" "
																	)[0]
																}
															</h3>
														</div>
													</div>

													<div>
														<h5 className='font-semibold text-12'>
															{ekkLanguage.home.allBrandSeller}
														</h5>
													</div>
												</div>
											</SwiperSlide>
											{slideData.map((el) => (
												<SwiperSlide key={el.id}>
													<SingleBrandSeller
														key={el.id}
														id={el.id ? el.id : el._id}
														name={
															el.brand_name
																? el.brand_name
																: el.title
														}
														supplierName={el.business_name}
														qty={el.products_count}
														img={el.images}
														tag={el.tag}
														offer={el.offer}
														offerAmount={el.offerAmount}
														nearby={el.nearby}
														oldPrice={Number(el.consumer_price)}
														newPrice={el.offer_price}
														sellPrice={el.sellPrice}
														profit={el.profit}
														nameShow={nameShow}
														supplierNameShow={supplierNameShow}
														skuShow={skuShow}
														priceShow={priceShow}
														oldPriceShow={oldPriceShow}
														distShow={distShow}
														profitShow={profitShow}
														sellPriceShow={sellPriceShow}
														link={link}
														defaultImage={defaultImage}
														seeMoreProduct={seeMoreProduct}
														type={type}
														getSellerProduct={getSellerProduct}
														selectedSeller={
															el.id === selectedSeller
														}
														setSelectedSellerId={
															setSelectedSellerId
														}
														setSearchQ={setSearchQ}
														setInitSeller={setInitSeller}
														setHasMoreData={setHasMoreData}
														setReachedEnd={setReachedEnd}
													/>
												</SwiperSlide>
											))}
										</Swiper>
									</div>
								</IonCol>
							) : (
								<IonCol>
									<div className='text-center'>
										<img
											className='m-auto mb-[10px] mt-5'
											src='/assets/images/not-found-file.png'
											alt='emptyCard'
										/>
										<p className='text-14 font-semibold text-black-500 mb-[35px]'>
											{emptyText}
										</p>
									</div>
								</IonCol>
							)}
						</>
					)}
				</IonRow>
			</IonGrid>
		</div>
	);
}
