import {
	IonApp,
	IonCol,
	IonContent,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonPage,
	IonSpinner,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import { COUNTRYINFO } from "data/country";
import { digitChanger, numberWithCommas } from "helpers/helper";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Accounts from "services/Accounts";
import Product from "services/Product";

export default function EmployeeHoursPartsPage() {
	const location = useLocation();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let [loading, setLoading] = useState(true);
	const [pageCount, setPageCount] = useState(2);
	const [hasMoreData, setHasMoreData] = useState(true);
	const [reachedEnd, setReachedEnd] = useState(false);
	const { createUserData } = useSelector((state) => state.user);
	const [totalAmount, setTotalAmount] = useState(0);
	const [employeeHours, setEmployeeHours] = useState([]);
	const dateFormat = useSelector((state) => state.date.format);

	const searchParams = new URLSearchParams(location?.search);
	const date = useSelector((state) => state.dashboard.dateInfo);

	let start, end;
	moment.updateLocale("en", {
		week: {
			dow: 1, // Monday is the first day of the week
		},
	});
	start = moment().startOf("week").format("YYYY-MM-DD");
	end = moment().format("YYYY-MM-DD");

	let userId = date?.userId ?? searchParams.get("userId");
	const getEmployeeHours = async (start, end) => {
		setLoading(true);
		let response = await Product.getEmployeeHoursData(userId, start, end);
		if (response.status === 200) {
			setLoading(false);
			setEmployeeHours(response.results);
			setTotalAmount(response?.total_working_hours);
		} else {
			setLoading(false);
			setEmployeeHours([]);
			setTotalAmount(0);
		}
	};
	useEffect(() => {
		getEmployeeHours(start, end);
	}, []);

	let loadMoreTran = async () => {
		if (hasMoreData) {
			let response = await Product.getEmployeeHoursData(
				userId,
				start,
				end,
				pageCount
			);
			if (response.status === 200) {
				setLoading(false);
				setPageCount((prev) => prev + 1);
				setEmployeeHours([...employeeHours, ...response.results]);
			} else {
				setHasMoreData(false);
				setLoading(false);
			}
		}
	};

	const [currentWeekStart, setCurrentWeekStart] = useState(
		moment().startOf("week")
	);
	const [currentWeekEnd, setCurrentWeekEnd] = useState(moment());

	const previousWeekHandler = () => {
		const newStart = moment(currentWeekStart)
			.subtract(1, "week")
			.startOf("week");
		const newEnd = moment(currentWeekStart).subtract(1, "day");

		setCurrentWeekStart(newStart);
		setCurrentWeekEnd(newEnd);

		getEmployeeHours(
			newStart.format("YYYY-MM-DD"),
			newEnd.format("YYYY-MM-DD")
		);
	};

	const nextWeekHandler = () => {
		const today = moment();
		const newStart = moment(currentWeekStart).add(1, "week").startOf("week");
		const newEnd = moment(currentWeekEnd).add(1, "week");

		// Allow going to current week even if it's not complete
		if (newStart.isSameOrBefore(today)) {
			const endDate = newEnd.isAfter(today) ? today : newEnd;
			setCurrentWeekStart(newStart);
			setCurrentWeekEnd(endDate);

			getEmployeeHours(
				newStart.format("YYYY-MM-DD"),
				endDate.format("YYYY-MM-DD")
			);
		}
	};

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle title='Employee Weekly Workhours' />
					<div className='mt-14 px-4'>
						<div className='pb-10'>
							<div className='px-2 flex justify-between items-center pt-5'>
								<h4 className='font-bold text-16 text-black-1000'>
									Employee Name: {searchParams.get("userName")}
								</h4>
							</div>
							<div className='px-2 flex justify-between items-center pt-1'>
								<h4 className='font-bold text-16 text-black-1000'>
									Total Hours: {totalAmount}
								</h4>
							</div>

							<div className='pt-3'>
								<div className='flex justify-between py-3 items-center mb-2'>
									<button
										className='font-bold h-9 rounded-[5px] ml-2 border-2 bg-success text-white px-2 text-12'
										onClick={previousWeekHandler}>
										{ekkLanguage.dashboard.perviousWeek}
									</button>
									<h4 className='font-bold'>
										<span className='font-semibold text-10 sm:text-12'>
											{currentWeekStart.format(dateFormat)} -{" "}
											{currentWeekEnd.format(dateFormat)}
										</span>
									</h4>
									<button
										className={`font-bold h-9 rounded-[5px] ml-2 border-2 ${
											currentWeekEnd.isSameOrAfter(moment(), "day")
												? "bg-36A94D-333"
												: "bg-success"
										}  text-white px-2 text-12`}
										onClick={nextWeekHandler}
										disabled={currentWeekEnd.isSameOrAfter(
											moment(),
											"day"
										)}>
										{ekkLanguage.dashboard.nextWeek}
									</button>
								</div>

								<div>
									<div>
										{loading ? (
											<div className='flex items-center justify-center h-[156px] m-auto'>
												{" "}
												<IonSpinner name='crescent'></IonSpinner>
											</div>
										) : (
											<>
												<div className='mb-4'>
													<table
														className='table-auto w-full bg-white'
														style={{
															boxShadow:
																"0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
														}}>
														<thead>
															<tr className='border h-[45px] border-r-0 border-l-0 border-D9D9D9 px-4'>
																<th className='font-bold text-14 text-left'>
																	Start Date Time <br/>
																	End Date Time
																</th>
																
																<th className='font-bold text-14 text-left ml-5'>
																	Working Hours <br/>
																	CreatedBy
																</th>
															</tr>
														</thead>

														<tbody>
															{employeeHours?.length > 0 &&
																employeeHours?.map((el, i) => (
																	<tr
																		key={i}
																		className=' h-[34px] border border-r-0 border-l-0 border-D9D9D9 px-4 my-2'>
																		<td className='font-semibold text-12 text-left m-2'>
																			{moment(
																				el?.check_in_time
																			).format(
																				`${dateFormat}-hh:mm:ss`
																			)}
																			<br/>

																			{el?.check_out_time ? (
																				<>
																					{moment(
																						el?.check_out_time
																					).format(
																						`${dateFormat}-hh:mm:ss`
																					)}
																				</>
																			) : (
																				<p>On Going</p>
																			)}
																		</td>
																		
																		<td className='font-semibold text-12 text-left ml-5'>
																			{el?.working_hour}
																			<br/>
																			{el?.created_by_username}
																		</td>
																	</tr>
																))}
														</tbody>
													</table>

													{employeeHours?.length === 0 && (
														<>
															<IonCol size='2'></IonCol>
															<IonCol size='8'>
																<div
																	className='text-center flex items-center justify-center'
																	style={{
																		height:
																			"calc(100vh - 420px)",
																	}}>
																	<div>
																		<img
																			className='m-auto mb-[10px] mt-5'
																			src='/assets/images/not-found-file.png'
																			alt='emptyCard'
																		/>
																		<p className='text-16 font-normal text-222222 mb-[35px]'>
																			{
																				ekkLanguage
																					.orderList
																					.notFound
																			}
																		</p>
																	</div>
																</div>
															</IonCol>
														</>
													)}
												</div>
											</>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
					{!loading && (
						<IonInfiniteScroll
							onIonInfinite={(ev) => {
								if (reachedEnd) {
									ev.target.complete();
									return;
								}
								loadMoreTran();
								setTimeout(() => {
									ev.target.complete();
									if (!hasMoreData) {
										setReachedEnd(true);
									}
								}, 1000);
							}}>
							<IonInfiniteScrollContent
								loadingText={ekkLanguage.orderList.loader}
								loadingSpinner={
									hasMoreData ? "bubbles" : null
								}></IonInfiniteScrollContent>
						</IonInfiniteScroll>
					)}
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
