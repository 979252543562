import {
	IonApp,
	IonContent,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonLoading,
	IonPage,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import Branch from "components/mobile-components/Employees/Branch";
import EditEmpBottomSheet from "components/mobile-components/Employees/EditEmpBootomSheet";
import EmployeesInfo from "components/mobile-components/Employees/EmployeesInfo";
import EmployeeWorkingList from "components/mobile-components/Employees/EmployeeWorkingList";
import SalaryList from "components/mobile-components/Employees/SalaryList";
import { getUserBusiness } from "config/user";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import Business from "services/Business";
import Product from "services/Product";

export default function EmployeesDetailsPage() {
	const { id } = useParams();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [employees, setEmployees] = useState([]);
	const [empLoading, setEmpLoading] = useState(false);
	const [employeeHours, setEmployeeHours] = useState([]);
	const [hasMoreData, setHasMoreData] = useState(true);
	const [reachedEnd, setReachedEnd] = useState(false);
	const [pageCount, setPageCount] = useState(2);
	const [totalAmount, setTotalAmount] = useState(0);
	const [salaryList, setSalaryList] = useState([]);
	const [salaryLoading, setSalaryLoading] = useState(false);
	const [employeeSalary, setEmployeeSalary] = useState([]);
	const [totalWeeklyAmount, setTotalWeeklyAmount] = useState(0);
	const location = useLocation();
	const searchParams = new URLSearchParams(location?.search);
	const from = searchParams.get("from");
	const business = getUserBusiness();

	const [currentWeekStart, setCurrentWeekStart] = useState(
		moment().startOf("week")
	);
	const [currentWeekEnd, setCurrentWeekEnd] = useState(moment());

	const getEmployees = async () => {
		setEmpLoading(true);
		let response;
		if (from) {
			response = await Product.getEmployeeData(business?.id, id);
		} else {
			response = await Business.getEmployees(id);
		}
		if (response.status === 200) {
			getEmployeesSalary(response.results?.employee_id, business?.id);
			// getEmployeesSalaryList(response.results?.user_id);
			handleEmployeeHoursClick(
				response.results?.user_id,
				moment(currentWeekStart).format("YYYY-MM-DD"),
				moment(currentWeekEnd).format("YYYY-MM-DD")
			);
			setEmployees(response.results);

			setEmpLoading(false);
		} else {
			setEmpLoading(false);
		}
	};

	const handleEmployeeHoursClick = async (userId, start, end) => {
		let response = await Product.getEmployeeHours(userId, start, end);
		if (response.status === 200) {
			setEmployeeHours(response.results);
			setTotalAmount(response?.total_working_hours);
			setTotalWeeklyAmount(response?.total_salary);
		} else {
			setEmployeeHours([]);
			setTotalAmount(0);
		}
	};

	useEffect(() => {
		getEmployees();
	}, []);

	const previousWeekHandler = () => {
		const newStart = moment(currentWeekStart)
			.subtract(1, "week")
			.startOf("week");
		const newEnd = moment(currentWeekStart).subtract(1, "day");

		setCurrentWeekStart(newStart);
		setCurrentWeekEnd(newEnd);

		handleEmployeeHoursClick(
			employees?.user_id,
			moment(newStart).format("YYYY-MM-DD"),
			moment(newEnd).format("YYYY-MM-DD")
		);
	};

	const nextWeekHandler = () => {
		const today = moment();
		const newStart = moment(currentWeekStart).add(1, "week").startOf("week");
		const newEnd = moment(currentWeekEnd).add(1, "week");

		// Allow going to current week even if it's not complete
		if (newStart.isSameOrBefore(today)) {
			const endDate = newEnd.isAfter(today) ? today : newEnd;
			setCurrentWeekStart(newStart);
			setCurrentWeekEnd(endDate);
			handleEmployeeHoursClick(
				employees?.user_id,
				moment(newStart).format("YYYY-MM-DD"),
				moment(endDate).format("YYYY-MM-DD")
			);
		}
	};
	const getEmployeesSalary = async (id, businessId) => {
		setSalaryLoading(true);
		let response = await Product.getEmployeeSalary(id, businessId);
		if (response.status === 200) {
			setEmployeeSalary(response.results);
		} else {
			setEmployeeSalary([]);
		}
	};

	const getEmployeesSalaryList = async (id) => {
		setSalaryLoading(true);
		let response = await Product.getEmployeeSalaryList(id);
		if (response.status === 200) {
			setSalaryList(response.results);
			setSalaryLoading(false);
		} else {
			setSalaryLoading(false);
		}
	};

	// let loadMoreData = async () => {
	// 	if (hasMoreData) {
	// 		let response = await Product.getEmployeeSalaryList(
	// 			employees?.user_id,
	// 			pageCount
	// 		);
	// 		if (response.status === 200) {
	// 			setPageCount((prev) => prev + 1);
	// 			setSalaryList([...salaryList, ...response.results]);
	// 		} else {
	// 			setHasMoreData(false);
	// 		}
	// 	}
	// };

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle
						title={ekkLanguage.employeesDetails.pageTitle}
						from={from ? from : "employeesDetails"}
					/>
					<EmployeesInfo
						employees={employees}
						from={from}
						employeeSalary={employeeSalary}
					/>
					{empLoading && <IonLoading isOpen={true} />}
					{/* <Branch employees={employees} /> */}
					{!from && business?.role === "Admin" ? (
						<>
							<EmployeeWorkingList
								totalAmount={totalAmount}
								employees={employees}
								employeeHours={employeeHours}
								ekkLanguage={ekkLanguage}
								getEmployees={getEmployees}
								currentWeekStart={currentWeekStart}
								currentWeekEnd={currentWeekEnd}
								previousWeekHandler={previousWeekHandler}
								nextWeekHandler={nextWeekHandler}
								employeeSalary={employeeSalary}
								totalWeeklyAmount={totalWeeklyAmount}
							/>
							<SalaryList
								totalAmount={totalAmount}
								employees={employees}
								employeeHours={employeeHours}
								ekkLanguage={ekkLanguage}
								getEmployees={getEmployees}
								employeeSalary={employeeSalary}
							/>
						</>
					) : undefined}
					<EditEmpBottomSheet
						getEmployees={getEmployees}
						employee={employees}
						employeeSalary={employeeSalary}
						employees={employees}

					/>
					{/* <IonInfiniteScroll
						onIonInfinite={(ev) => {
							if (reachedEnd) {
								ev.target.complete();
								return;
							}
							loadMoreData();
							setTimeout(() => {
								ev.target.complete();
								if (!hasMoreData) {
									setReachedEnd(true);
								}
							}, 1000);
						}}>
						<IonInfiniteScrollContent
							loadingText={ekkLanguage.orderList.loader}
							loadingSpinner={
								hasMoreData ? "bubbles" : null
							}></IonInfiniteScrollContent>
					</IonInfiniteScroll> */}
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
