import { IonApp, IonContent, IonLoading, IonPage } from "@ionic/react";
import CategoryList from "components/mobile-components/Accounts/CategoryList";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Accounts from "services/Accounts";
import TitleNSearch from "components/mobile-components/Common/TitleNSearch";

export default function CategoriesPage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [categoryList, setCategoryList] = useState([]);
	const [searchQ, setSearchQ] = useState("");
	const { id } = useParams();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		getCategory();
	}, []);

	let getCategory = async () => {
		setLoading(true);
		let response = await Accounts.getCategory(Number(id));

		if (response.status === 200) {
			setLoading(false);
			setCategoryList(response.results);
		} else {
			setLoading(false);
			setCategoryList([]);
		}
	};
	let getCategorySearch = async (q) => {
		setLoading(true);
		let response = await Accounts.getCategorySearch(searchQ, Number(id));
		if (response.status === 200) {
			setLoading(false);
			setCategoryList(response.results);
		} else {
			setLoading(false);
		}
	};

	const deleteQueryHandler = async () => {
		setLoading(false);
		setSearchQ("");
		getCategory();
	};

	// useEffect(() => {
	// 	if (searchQ === "") {
	// 		getCategory();
	// 	}
	// }, [searchQ]);

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle title='Categories' />
					<TitleNSearch
						title='Categories'
						placeholder='search categories'
						searchQ={searchQ}
						setSearchQ={setSearchQ}
						fetchSearchData={getCategorySearch}
						initData={getCategory}
						deleteQuery={deleteQueryHandler}
						inputType='string'
					/>
					<section className='pt-14'>
						<div className='pt-5'>
							{loading && (
								<IonLoading
									isOpen={true}
									message={ekkLanguage.orderList?.loader}
								/>
							)}
							<CategoryList categoryList={categoryList} />
						</div>
					</section>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
