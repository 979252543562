import { IonButton, IonCol, IonGrid, IonRow, IonSpinner } from "@ionic/react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import SingleProduct from "./SingleProduct";
import "./style.scss";

export default function ProductCard({
	title,
	slideData,
	icon,
	nameShow,
	skuShow,
	priceShow,
	oldPriceShow,
	distShow,
	profitShow,
	sellPriceShow,
	supplierNameShow,
	link,
	seeMoreProduct,
	defaultImage,
	productCard,
	type,
	emptyText,
	loading,
}) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<div className='home-product-card px-2 pb-7'>
			<IonGrid className='bg-white rounded-lg'>
				<IonRow>
					<IonCol size='12'>
						<div className='flex justify-between'>
							<div>
								<Link
									to={`/products/${seeMoreProduct}`}
									className='flex items-center'
								>
									{icon}
									{/* <img src={icon} className='pr-2' alt='icon' /> */}
									<h5 className={`font-semibold text-16 ml-2`}>{title}</h5>
								</Link>
							</div>
							{slideData?.length > 0 && (
								<Link to={`/products/${seeMoreProduct}`}>
									<IonButton
										size='small'
										fill='text'
										className='p-0 text-success font-semibold text-12 font-sans'
									>
										{ekkLanguage.home.showMoreLabel}
									</IonButton>
								</Link>
							)}
						</div>
					</IonCol>
					{loading ? (
						<div className='flex items-center justify-center h-[156px] m-auto'>
							{" "}
							<IonSpinner name='crescent'></IonSpinner>
						</div>
					) : (
						<>
							{slideData?.length > 0 ? (
								<IonCol>
									<div className='product-slider'>
										<Swiper
											spaceBetween={15}
											slidesPerView={2.5}
											grabCursor={true}
											breakpoints={{
												260: {
													slidesPerView: 2,
												},
												320: {
													slidesPerView: 3,
												},
												360: {
													slidesPerView: 3,
												},
												575: {
													slidesPerView: 4,
												},
												768: {
													slidesPerView: 5,
												},
											}}
										>
											{slideData?.map((el, i) => {
												return (
													<SwiperSlide key={i}>
														<SingleProduct
															id={el.id ? el.id : el._id}
															name={el.brand_name ? el.brand_name : el.title}
															supplierName={el.business_name}
															qty={el.product_count}
															img={el.images}
															tag={el.tag}
															offer={el.offer}
															offerAmount={el.offerAmount}
															nearby={el.nearby}
															oldPrice={Number(el.consumer_price)}
															newPrice={el.base_price}
															sellPrice={el.sellPrice}
															profit={
																Number(el.consumer_price) -
																Number(el.base_price)
															}
															nameShow={nameShow}
															supplierNameShow={supplierNameShow}
															skuShow={skuShow}
															priceShow={priceShow}
															oldPriceShow={oldPriceShow}
															distShow={distShow}
															profitShow={
																el.consumer_price ? profitShow : false
															}
															sellPriceShow={sellPriceShow}
															link={link}
															defaultImage={defaultImage}
															seeMoreProduct={seeMoreProduct}
															productCard={productCard}
															type='offers'
															currency={el.country}
														/>
													</SwiperSlide>
												);
											})}
										</Swiper>
									</div>
								</IonCol>
							) : (
								<IonCol>
									<div className='text-center'>
										<img
											className='m-auto mb-[10px] mt-5'
											src='/assets/images/not-found-file.png'
											alt='emptyCard'
										/>
										<p className='text-14 font-semibold text-black-500 mb-[35px]'>
											{emptyText}
										</p>
									</div>
								</IonCol>
							)}
						</>
					)}
				</IonRow>
			</IonGrid>
		</div>
	);
}
