import { baseUrl } from "config/apiUrl";
import SuperFetch from "config/SuperFetch";

const Blog = {
	getBlogList: (page = 1, limit = 9, lan = "EN") => {
		return SuperFetch(
			`${baseUrl.social}/articles/filter/category?&page=${page}&limit=${limit}&context=Article&categories=Corporate&language=${lan}`
		);
	},
	singleBlog: (id) => {
		return SuperFetch(`${baseUrl.social}/articles/${id}`);
	},
	learningList: (page = 1, limit = 9, lan) => {
		return SuperFetch(
			`${baseUrl.social}/articles/filter/category/?categories=Learning&language=${lan}&page=${page}&limit=${limit}&app=EkkBaz`
		);
	},
	learningDetails: (id) => {
		return SuperFetch(`${baseUrl.social}/articles/${id}`);
	},
	getCommentList: (id, page = 1, limit = 10) => {
		return SuperFetch(`${baseUrl.social}/articles/comment-list/${id}/`);
	},
	addComment: (id, comment) => {
		return SuperFetch(`${baseUrl.social}/articles/add-comment/${id}/`, {
			method: "POST",
			data: comment,
		});
	},
	addLike: (id) => {
		return SuperFetch(`${baseUrl.social}/articles/add-like/${id}/`, {
			method: "PUT",
		});
	},
	getTermConditonWithoutAuth: (lan = "EN") => {
		return SuperFetch(
			`${baseUrl.social}/articles/?categories=Terms%20and%20Conditions&language=${lan}&page=1&limit=1&app=EkkBaz`
		);
	},
	getPrivacyPolicyWithoutAuth: (lan = "EN") => {
		return SuperFetch(
			`${baseUrl.social}/articles/?categories=Privacy Policy&language=${lan}&page=1&limit=1&app=EkkBaz`
		);
	},
	getRefundPolicyWithoutAuth: (lan = "EN") => {
		return SuperFetch(
			`${baseUrl.social}/articles/?categories=Refund Policy&language=${lan}&page=1&limit=1&app=EkkBaz`
		);
	},
};

export default Blog;
