import React from "react";

export default function Supplier() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			style={{ margin: "auto" }}>
			<g id='delivery solid 4'>
				<g id='Group'>
					<path
						id='Vector'
						d='M5.15021 16H2.13021L2.01021 16.6971C1.97021 16.9486 2.03021 17.2114 2.17021 17.4057C2.31021 17.6 2.53021 17.7143 2.75021 17.7143H5.04021C4.96154 17.1408 4.99918 16.5543 5.15021 16ZM15.8302 4.32004C15.7616 4.21924 15.6736 4.13791 15.573 4.0824C15.4724 4.02689 15.3619 3.99871 15.2502 4.00005H4.75021C4.57555 4.00137 4.40663 4.07141 4.27185 4.19838C4.13708 4.32535 4.04468 4.5015 4.01021 4.69719L3.94021 5.1429H6.25021C7.21021 5.1429 8.00021 6.04575 8.00021 7.14289C8.00021 7.87432 7.65021 8.50289 7.14021 8.85717C7.65021 9.21146 8.00021 9.84003 8.00021 10.5715C8.00021 11.3029 7.65021 11.9314 7.14021 12.2857C7.45021 12.5029 7.70021 12.8229 7.85021 13.2114C8.35594 13.103 8.87636 13.1232 9.37449 13.2706C9.87262 13.418 10.3362 13.689 10.7324 14.0643C11.1285 14.4396 11.4475 14.91 11.6666 15.442C11.8857 15.9741 11.9996 16.5548 12.0002 17.1429C12.0002 17.3371 11.9902 17.5314 11.9602 17.7143H13.0002C13.3502 17.7143 13.6602 17.4286 13.7302 17.04L15.9802 5.04004C16.0302 4.78861 15.9802 4.52576 15.8302 4.32004ZM19.2502 7.42861H17.1102C16.9383 7.4288 16.7716 7.49558 16.6372 7.61804C16.5028 7.74051 16.4087 7.91142 16.3702 8.10289L14.7702 16.6743C14.7202 16.9257 14.7702 17.1886 14.9202 17.3943C15.0602 17.6 15.2702 17.7143 15.5002 17.7143H16.0402C16.0102 17.5314 16.0002 17.3371 16.0002 17.1429C16.0002 14.9372 17.5702 13.1429 19.5002 13.1429C21.4302 13.1429 23.0002 14.9372 23.0002 17.1429C23.0002 17.2686 23.0002 17.3829 22.9802 17.5086C23.1102 17.3943 23.2002 17.2343 23.2302 17.04L23.9802 13.04C23.9902 12.9829 24.0002 12.9143 24.0002 12.8572C24.0002 9.86288 21.8702 7.42861 19.2502 7.42861Z'
						fill='#00B224'
					/>
					<path
						id='Vector_2'
						d='M6.25 8.00005H2.75C2.55109 8.00005 2.36032 7.90974 2.21967 7.749C2.07902 7.58825 2 7.37023 2 7.14291C2 6.91558 2.07902 6.69756 2.21967 6.53682C2.36032 6.37607 2.55109 6.28577 2.75 6.28577H6.25C6.44891 6.28577 6.63968 6.37607 6.78033 6.53682C6.92098 6.69756 7 6.91558 7 7.14291C7 7.37023 6.92098 7.58825 6.78033 7.749C6.63968 7.90974 6.44891 8.00005 6.25 8.00005ZM6.25 11.4286H1.75C1.55109 11.4286 1.36032 11.3383 1.21967 11.1776C1.07902 11.0168 1 10.7988 1 10.5715C1 10.3441 1.07902 10.1261 1.21967 9.96538C1.36032 9.80463 1.55109 9.71433 1.75 9.71433H6.25C6.44891 9.71433 6.63968 9.80463 6.78033 9.96538C6.92098 10.1261 7 10.3441 7 10.5715C7 10.7988 6.92098 11.0168 6.78033 11.1776C6.63968 11.3383 6.44891 11.4286 6.25 11.4286ZM6.25 14.8572H0.75C0.551088 14.8572 0.360322 14.7669 0.21967 14.6061C0.0790176 14.4454 0 14.2274 0 14C0 13.7727 0.0790176 13.5547 0.21967 13.3939C0.360322 13.2332 0.551088 13.1429 0.75 13.1429H6.25C6.44891 13.1429 6.63968 13.2332 6.78033 13.3939C6.92098 13.5547 7 13.7727 7 14C7 14.2274 6.92098 14.4454 6.78033 14.6061C6.63968 14.7669 6.44891 14.8572 6.25 14.8572ZM19.5 20C18.122 20 17 18.7189 17 17.1429C17 15.5669 18.122 14.2857 19.5 14.2857C20.878 14.2857 22 15.5669 22 17.1429C22 18.7189 20.878 20 19.5 20ZM8.5 20C7.122 20 6 18.7189 6 17.1429C6 15.5669 7.122 14.2857 8.5 14.2857C9.878 14.2857 11 15.5669 11 17.1429C11 18.7189 9.878 20 8.5 20Z'
						fill='#00B224'
					/>
				</g>
			</g>
		</svg>
	);
}
