import {
	IonCol,
	IonGrid,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonRow,
} from "@ionic/react";
import SingleRecommendProduct from "../Home/SingleRecommendProduct";

export default function FoundProduct({
	recommendData,
	settinges,
	lodeMoreData,
	searchNext,
	totalProcuctCount,
	ekkLanguage,
}) {
	return (
		<div className='related-product-card px-2 pb-7 pt-2'>
			{recommendData?.length != 0 ? (
				<IonGrid className='bg-white rounded-lg'>
					<IonInfiniteScroll threshold='100px'>
						<IonInfiniteScrollContent
							// loadingText={
							// 	recommendData?.length
							// 		? totalProcuctCount >= recommendData?.length
							// 			? "Loading more products..."
							// 			: ""
							// 		: null
							// }
							loadingSpinner={
								totalProcuctCount > recommendData?.length ? false : true
							}>
							<IonRow>
								{recommendData &&
									recommendData?.map((el, i) => {
										return (
											<IonCol
												size='6'
												sizeSm='4'
												className='mb-6'
												key={i}>
												<SingleRecommendProduct
													id={el.id ? el.id : el._id}
													name={el.brand_name}
													title={el.title}
													supplierName={el.business_name}
													qty={el.product_count}
													img={el.images}
													tag={el.tag}
													offer={el.offer}
													offerAmount={el.offerAmount}
													nearby={el.nearby}
													price={el.base_price}
													oldPrice={el.oldPrice}
													newPrice={el.newPrice}
													sellPrice={el.sellPrice}
													profit={el.profit}
													nameShow={settinges.nameShow}
													supplierNameShow={
														settinges.supplierNameShow
													}
													skuShow={settinges.skuShow}
													priceShow={settinges.priceShow}
													oldPriceShow={settinges.oldPriceShow}
													distShow={settinges.distShow}
													profitShow={settinges.profitShow}
													sellPriceShow={settinges.sellPriceShow}
													titleShow={settinges.titleShow}
													link={settinges.link}
													type={el.type}
													currency={el.country}
													productType='products'
												/>
											</IonCol>
										);
									})}
							</IonRow>
						</IonInfiniteScrollContent>
					</IonInfiniteScroll>
				</IonGrid>
			) : (
				<>
					<IonCol size='2'></IonCol>
					<IonCol size='8'>
						<div
							className='text-center flex items-center justify-center'
							style={{ height: "calc(100vh - 420px)" }}>
							<div>
								<img
									className='m-auto mb-[10px] mt-5'
									src='/assets/images/not-found-file.png'
									alt='emptyCard'
								/>
								<p className='text-16 font-normal text-222222 mb-[35px]'>
									{ekkLanguage?.orderList?.notFound}
								</p>
							</div>
						</div>
					</IonCol>
				</>
			)}
		</div>
	);
}
