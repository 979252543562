import React, { useState } from "react";
import Sheet from "react-modal-sheet";
import Print from "assets/images/icon/svg/Print";
import { IonItem } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { setAccountDetailsSheet } from "redux/container/userSlice";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { ClipLoader } from "react-spinners";
import CancelPopUp from "assets/images/icon/svg/CancelPopUp";
import CancelOrder from "assets/images/icon/svg/CancelOrder";
import EditPen from "assets/images/icon/svg/EditPen";
import Accounts from "services/Accounts";
import { toast } from "react-toastify";
import { setAccountData } from "redux/container/dashboardSlice";

export default function AccountDetailsSheet({
	collection,
	getTransactionDetails,
}) {
	const ref = React.useRef();
	const isOpenBottomSheet = useSelector(
		(state) => state.user.accountDetailsSheet
	);
	const accountData = useSelector((state) => state.dashboard.accountData);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [deleteItem, setDeleteItem] = useState(false);
	const [deleteLoader, setDeleteLoader] = useState(false);
	const openDelete = () => setDeleteItem(true);
	const closeDelete = () => setDeleteItem(false);

	const [editItem, setEditItem] = useState(false);
	const openEdit = () => setEditItem(true);
	const closeEdit = () => setEditItem(false);
	const deleteModalHandler = async () => {
		setDeleteLoader(true);
		let response = await Accounts.deleteItem(collection?.id);
		if (response.success) {
			toast.success(`${collection?.acc_group?.title} deleted successfully`);
			setDeleteLoader(false);
			navigate("/accounts");
			let updatedData = accountData.filter(
				(item) => item?.id !== collection?.id
			);
			dispatch(setAccountData(updatedData));
		} else {
			setDeleteLoader(false);
		}
	};

	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "300px",
			transition: ".4s all ease",
			borderRadius: 12,
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};

	return (
		<div>
			<Sheet
				className='bottom-sheet'
				initialSnap={1}
				ref={ref}
				isOpen={isOpenBottomSheet}
				snapPoints={[180, 180, 100, 0]}
				onClose={() => dispatch(setAccountDetailsSheet(false))}>
				<Sheet.Container>
					<Sheet.Header />
					<Sheet.Content>
						<IonItem
							button
							onClick={() => {
								dispatch(setAccountDetailsSheet(false));
								openEdit();
							}}
							lines='none'>
							<span className='py-[5px]'>
								{/* <EditPen /> */}
								<div className='w-10 h-10 rounded-full'>
									<img
										src='/assets/images/accountNumber.png'
										className='w-9'
										alt=''
									/>
								</div>
							</span>
							<p className='text-16 font-semibold text-black-1000 pl-2'>
								Edit
							</p>
						</IonItem>
						<IonItem
							button
							onClick={() => {
								openDelete();
								dispatch(setAccountDetailsSheet(false));
							}}
							lines='none'>
							<span className='py-[5px]'>
								<CancelOrder />
							</span>
							<p className='text-16 font-semibold text-black-1000 pl-2'>
								Delete
							</p>
						</IonItem>
					</Sheet.Content>
				</Sheet.Container>

				<Sheet.Backdrop
					onClick={() => dispatch(setAccountDetailsSheet(false))}
				/>
			</Sheet>
			<Modal
				style={customStyles}
				closeTimeoutMS={200}
				isOpen={deleteItem}
				onRequestClose={closeDelete}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2'>
						<CancelPopUp />
					</span>
					<h6 className='text-16 font-bold pb-[30px]'>
						Are you sure, you want to delete this{" "}
						{collection?.acc_group?.title}?
					</h6>

					<div className='flex justify-end'>
						<>
							<button
								className='border-2 font-bold h-10 rounded-[7px] w-1/2 mr-2 bg-primary text-white'
								onClick={closeDelete}>
								{ekkLanguage.orderDetails.noBtn}
							</button>
							<button
								color='primary'
								className={`${
									deleteLoader && "select-none cursor-no-drop"
								} font-bold h-10 rounded-[7px] w-1/2 border-2 bg-success text-white flex justify-center items-center`}
								onClick={
									!deleteLoader ? deleteModalHandler : undefined
								}>
								{deleteLoader ? (
									<ClipLoader
										sizeUnit={"px"}
										size={20}
										color={"white"}
										loading={true}
									/>
								) : (
									ekkLanguage.orderDetails.yesBtn
								)}
							</button>
						</>
					</div>
				</div>
			</Modal>
			<Modal
				style={customStyles}
				closeTimeoutMS={200}
				isOpen={editItem}
				onRequestClose={closeEdit}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2'>
						<CancelPopUp />
					</span>
					<h6 className='text-16 font-bold pb-[30px]'>
						Are you sure, you want to edit this{" "}
						{collection?.acc_group?.title}?
					</h6>

					<div className='flex justify-end'>
						<>
							<button
								className='border-2 font-bold h-10 rounded-[7px] w-1/2 mr-2 bg-primary text-white'
								onClick={closeEdit}>
								{ekkLanguage.orderDetails.noBtn}
							</button>
							<button
								color='primary'
								className={`font-bold h-10 rounded-[7px] w-1/2 border-2 bg-success text-white flex justify-center items-center`}
								onClick={() => {
									getTransactionDetails();
									navigate(
										collection?.acc_group?.title === "Revenue"
											? `/revenue-edit/${collection?.id}`
											: `/expense-edit/${collection?.id}`
									);
								}}>
								{ekkLanguage.orderDetails.yesBtn}
							</button>
						</>
					</div>
				</div>
			</Modal>
		</div>
	);
}
