import React, { forwardRef, useEffect, useState } from "react";
import TextInputWithIcon from "../Common/TextInputWithIcon";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import RightArrow from "assets/images/icon/svg/RightArrow";
import { IonButton, IonToggle } from "@ionic/react";
import { setLoanRequest, clearLoanRequest } from "redux/container/accountSlice";
import Modal from "react-modal";
import { ClipLoader } from "react-spinners";
import CancelPopUp from "assets/images/icon/svg/CancelPopUp";
import Accounts from "services/Accounts";
import Select from "react-select";
import { toast } from "react-toastify";
import Loan from "services/Loan";

export default function LoanRequestForm() {
	let loan = useSelector((state) => state?.accounts?.loanRequest);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [loanAmount, setLoneAmount] = useState(loan?.loanAmount);
	const [loanPurpose, setLoanPurpose] = useState(loan?.description);
	const [loanDuration, setLoanDuration] = useState(loan?.loanDuration);
	const [loanCreditScore, setLoanCreditScore] = useState(
		loan?.loanCreditScore
	);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [confirmModal, setConfirmModal] = useState(false);
	const closeModal = () => setConfirmModal(false);
	const openModal = () => setConfirmModal(true);
	const [loader, setLoader] = useState(false);
	const [accId, setAccId] = useState(null);
	const location = useLocation();
	// const [creditScore, setCreditScore] = useState([])

	let getAccountType = async () => {
		let response = await Accounts.getAccountType(
			location?.pathname === "/expense-form" ? "Expense" : "Revenue"
		);
		if (response?.success) {
			setAccId(response?.results?.id);
		}
	};

	useEffect(() => {
		getAccountType();
	}, []);
	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "300px",
			transition: ".4s all ease",
			borderRadius: 12,
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};
	useEffect(() => {
		dispatch(
			setLoanRequest({
				...loan,
				loanAmount,
				loanPurpose,
			})
		);
	}, [loanAmount, loanPurpose]);

	let submitHandler = async () => {
		setLoader(true);
		let payLoad = {
			loan_amount: loan?.loanAmount,
			purpose: loan?.loanPurpose,
			loan_duration: loan?.loanDuration?.value,
			credit_score: loan.loanCreditScore?.id,
		};
		let response = await Loan.createLoanRequest(payLoad);
		if (response.success) {
			setLoader(false);
			closeModal();
			navigate("/loan");
			dispatch(clearLoanRequest());
		} else {
			setLoader(false);
		}
	};

	let [confirmModalWarning, setConfirmModalWaning] = useState(false);
	let openWarning = () => setConfirmModalWaning(true);
	let closeWarning = () => setConfirmModalWaning(false);
	let [warningMessage, setWarningMessage] = useState("");

	const durationOptions = [
		{ value: "1day", label: "1 Day" },
		{ value: "1 Month", label: "1 Month" },
		{ value: "3 Month", label: "3 Month" },
		{ value: "6 Month", label: "6 Month" },
		{ value: "1 Year", label: "1 Year" },
		{ value: "3 Years", label: "3 Years" },
	];

	const getCreditScore = async () => {
		let response = await Loan.getCreditScore();

		if (response.success) {
			let modifiedData = response.results.map(
				(el) => el && { ...el, value: el?.score, label: el?.title }
			);
			setLoanCreditScore(modifiedData);
		} else {
			setLoanCreditScore([]);
		}
	};

	useEffect(() => {
		getCreditScore();
	}, []);

	const selectScoreChanger = (el) => {
		dispatch(
			setLoanRequest({
				...loan,
				loanCreditScore: el,
			})
		);
	};
	const selectDurationChanger = (el) => {
		dispatch(
			setLoanRequest({
				...loan,
				loanDuration: el,
			})
		);
	};

	return (
		<div className='pt-5'>
			{" "}
			<div>
				<TextInputWithIcon
					type='number'
					title={ekkLanguage.loan.loanAmount}
					inputText={loanAmount}
					setInputText={setLoneAmount}
					placeholder={"write you loan request amount"}
					// icon='assets/images/cash.png'
					required={true}
				/>
				<div className='mb-4'>
					<h4 className='pb-3 font-bold text-14'>
						{ekkLanguage.loan.loanDuration} *
					</h4>
					<div className={"w-full "}>
						<Select
							isSearchable={false}
							defaultValue={loan?.loanDuration}
							placeholder='Select credit score'
							options={durationOptions}
							onChange={selectDurationChanger}
							styles={{
								control: (baseStyles, state) => ({
									...baseStyles,
									// width: "100%",
									height: 50,
									// border: "none",
									outLine: "none",
									fontWeight: 600,
									fontSize: 12,
									borderRadius: 5,
									borderColor: "#00000080",
								}),
							}}
							theme={(theme) => ({
								...theme,
								borderRadius: 5,
								colors: {
									...theme.colors,
									primary25: "rgb(204, 255, 214)",
									primary: "#00FF33",
								},
							})}
						/>
					</div>
				</div>

				<div className='mb-4'>
					<h4 className='pb-3 font-bold text-14'>
						{ekkLanguage.loan.creditScore} *
					</h4>
					<div className={"w-full "}>
						<Select
							isSearchable={false}
							defaultValue={loan.loanCreditScore}
							placeholder='Select credit score'
							options={loanCreditScore}
							onChange={selectScoreChanger}
							styles={{
								control: (baseStyles, state) => ({
									...baseStyles,
									// width: "100%",
									height: 50,
									// border: "none",
									outLine: "none",
									fontWeight: 600,
									fontSize: 12,
									borderRadius: 5,
									borderColor: "#00000080",
								}),
							}}
							theme={(theme) => ({
								...theme,
								borderRadius: 5,
								colors: {
									...theme.colors,
									primary25: "rgb(204, 255, 214)",
									primary: "#00FF33",
								},
							})}
						/>
					</div>
				</div>
				<TextInputWithIcon
					type='text'
					title={ekkLanguage.loan.loanPurpose}
					inputText={loanPurpose}
					setInputText={setLoanPurpose}
					placeholder={"write you loan request purpose"}
					required={false}
				/>
			</div>
			<div className='mb-8 mt-14'>
				<IonButton
					// disabled={
					// 	productName && sellingPrice && initInventory && true
					// }
					// disabled={publishDisabled}
					onClick={() => {
						if (
							!loan?.loanAmount &&
							!loan?.loanCreditScore &&
							!loan?.loanDuration
						) {
							openWarning();
							setWarningMessage(
								"Please add a valid amount, select a loan duration and select a credit score"
							);
							return false;
						} else if (!loan?.loanAmount && !loan?.loanCreditScore) {
							openWarning();
							setWarningMessage(
								"Please add a valid amount, select a loan credit score."
							);
							return false;
						} else if (!loan?.loanAmount && !loan?.loanDuration) {
							openWarning();
							setWarningMessage(
								"Please add a valid amount, and select a duration"
							);
							return false;
						} else if (!loan?.loanDuration && !loan?.loanCreditScore) {
							openWarning();
							setWarningMessage(
								"Please select a loan duration and select a credit score"
							);
							return false;
						} else if (!loan?.loanAmount) {
							openWarning();
							setWarningMessage("Please enter a valid amount.");
							return false;
						} else if (!loan?.loanCreditScore) {
							openWarning();
							setWarningMessage("Please select a credit score.");
							return false;
						} else if (!loan?.loanDuration) {
							openWarning();
							setWarningMessage("Please Select a loan duration.");
							return false;
						} else {
							openModal();
						}
					}}
					style={{ boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.15)" }}
					expand='block'
					className='bg-success rounded-[5px] h-12 font-sans font-bold text-20'>
					{ekkLanguage.loan.createLoanRequest}
				</IonButton>
			</div>
			<Modal
				style={customStyles}
				closeTimeoutMS={200}
				isOpen={confirmModal}
				onRequestClose={closeModal}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2'>
						<CancelPopUp />
					</span>
					<h6 className='text-16 font-bold pb-[30px]'>
						{ekkLanguage.loan.loanCreateWarning}
					</h6>

					<div className='flex justify-end'>
						<button
							className='border-2 font-bold h-10 rounded-[7px] w-1/2 bg-primary text-white mr-2'
							onClick={closeModal}>
							{ekkLanguage.orderDetails.noBtn}
							{/* No */}
						</button>
						<button
							color='primary'
							className={`${
								loader && "select-none cursor-no-drop"
							} font-bold h-10 rounded-[7px] w-1/2   ml-2 border-2 bg-success text-white flex justify-center items-center`}
							onClick={!loader ? submitHandler : undefined}>
							{loader ? (
								<ClipLoader
									sizeUnit={"px"}
									size={20}
									color={"white"}
									loading={true}
								/>
							) : (
								ekkLanguage.orderDetails.yesBtn
								// "Yes"
							)}
						</button>
					</div>
				</div>
			</Modal>
			<Modal
				style={customStyles}
				closeTimeoutMS={200}
				isOpen={confirmModalWarning}
				onRequestClose={closeWarning}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2'>
						<CancelPopUp />
					</span>
					<h6 className='text-16 font-bold pb-[30px]'>{warningMessage}</h6>

					<div className='flex justify-end'>
						<button
							color='primary'
							className={`${
								loader && "select-none cursor-no-drop"
							} font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-success text-white flex justify-center items-center`}
							onClick={closeWarning}>
							Ok
						</button>
					</div>
				</div>
			</Modal>
		</div>
	);
}
