import { createSlice, createSelector } from "@reduxjs/toolkit";

const userSlice = createSlice({
	name: "user",
	initialState: {
		loading: false,
		warning: true,
		data: {},
		location: false,
		locationData: null,
		bottomSheet: false,
		businessBottomSheet: false,
		bankBottomSheet: false,
		accountBottomSheet: false,
		accountDetailsSheet: false,
		loanBottomSheet: false,
		profitLossBottomSheet: false,
		pwaDownloadData: {},
		mapLocation: {},
		createUserData: {},
		myEmployees: false,
		editEmployees: false,
		newBusinessState: false,
		employeeData: {
			isHourly: true,
		},
		editEmployeeData: {},
		employeeSetting: {
			checkStatus: false,
			isNeededPassword: false,
			isCheckedNumber: false,
		},
	},
	reducers: {
		setUser: (state, action) => {
			state.data = action.payload;
		},
		setLocation: (state, action) => {
			state.location = true;
			state.locationData = action.payload;
		},
		setBottomSheet: (state, action) => {
			state.bottomSheet = action.payload;
		},
		setBusinessBottomSheet: (state, action) => {
			state.businessBottomSheet = action.payload;
		},
		setAddBankBottomSheet: (state, action) => {
			state.bankBottomSheet = action.payload;
		},
		setAccountBottomSheet: (state, action) => {
			state.accountBottomSheet = action.payload;
		},
		setAccountDetailsSheet: (state, action) => {
			state.accountDetailsSheet = action.payload;
		},
		setLoanBottomSheet: (state, action) => {
			state.loanBottomSheet = action.payload;
		},
		setProfitLossBottomSheet: (state, action) => {
			state.profitLossBottomSheet = action.payload;
		},
		setPwaDownloadData: (state, action) => {
			state.pwaDownloadData = action.payload;
		},
		setMapLocation: (state, action) => {
			state.mapLocation = action.payload;
		},
		setCreateUserData: (state, action) => {
			state.createUserData = action.payload;
		},
		setWarning: (state, action) => {
			state.warning = action.payload;
		},
		setMyEmployees: (state, action) => {
			state.myEmployees = action.payload;
		},
		setNewBusinessState: (state, action) => {
			state.newBusinessState = action.payload;
		},
		setEditEmployees: (state, action) => {
			state.editEmployees = action.payload;
		},
		setEmployeeData: (state, action) => {
			state.employeeData = action.payload;
		},
		setEditEmployeeData: (state, action) => {
			state.editEmployeeData = action.payload;
		},
		setEmployeeSetting: (state, action) => {
			state.employeeSetting = action.payload;
		},
		resetEmployeeSetting: (state) => {
			state.employeeSetting = {
				checkStatus: false,
				isNeededPassword: false,
				isCheckedNumber: false,
			};
			state.employeeData = {
				isHourly: true,
			};
		},
	},
});

// Extract the action creators object and the reducer
export const { actions, reducer } = userSlice;

export const selectors = {
	getLocation: createSelector(
		(state) => state.user,
		(location) => location
	),
};

export const setLocation = (values) => async (dispatch, getState) => {
	dispatch(actions.setLocation(values));
};
export const setUser = (data) => async (dispatch, getState) => {
	dispatch(actions.setUser(data));
};
export const setBottomSheet = (data) => async (dispatch) => {
	dispatch(actions.setBottomSheet(data));
};
export const setBusinessBottomSheet = (data) => async (dispatch) => {
	dispatch(actions.setBusinessBottomSheet(data));
};
export const setAddBankBottomSheet = (data) => async (dispatch) => {
	dispatch(actions.setAddBankBottomSheet(data));
};
export const setAccountDetailsSheet = (data) => async (dispatch) => {
	dispatch(actions.setAccountDetailsSheet(data));
};
export const setAccountBottomSheet = (data) => async (dispatch) => {
	dispatch(actions.setAccountBottomSheet(data));
};
export const setLoanBottomSheet = (data) => async (dispatch) => {
	dispatch(actions.setLoanBottomSheet(data));
};
export const setProfitLossBottomSheet = (data) => async (dispatch) => {
	dispatch(actions.setProfitLossBottomSheet(data));
};
export const setPwaDownloadData = (data) => async (dispatch) => {
	dispatch(actions.setPwaDownloadData(data));
};
export const setMapLocation = (data) => async (dispatch) => {
	dispatch(actions.setMapLocation(data));
};
export const setCreateUser = (data) => async (dispatch) => {
	dispatch(actions.setCreateUserData(data));
};
export const setWarning = (data) => async (dispatch) => {
	dispatch(actions.setWarning(data));
};
export const setMyEmployees = (data) => async (dispatch) => {
	dispatch(actions.setMyEmployees(data));
};
export const setNewBusinessState = (data) => async (dispatch) => {
	dispatch(actions.setNewBusinessState(data));
};

export const setEditEmployees = (data) => async (dispatch) => {
	dispatch(actions.setEditEmployees(data));
};
export const setEmployeeData = (data) => async (dispatch) => {
	dispatch(actions.setEmployeeData(data));
};
export const setEditEmployeeData = (data) => async (dispatch) => {
	dispatch(actions.setEditEmployeeData(data));
};
export const setEmployeeSetting = (data) => async (dispatch) => {
	dispatch(actions.setEmployeeSetting(data));
};
export const resetEmployeeSetting = () => async (dispatch) => {
	dispatch(actions.resetEmployeeSetting());
};
// Export the reducer, either as a default or named export
export default reducer;
