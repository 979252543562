import React from "react";
import CardTitle from "./CardTitle";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./style.scss";
import { useSelector } from "react-redux";
import { IonCol, IonRow, IonSpinner } from "@ionic/react";
import { useNavigate } from "react-router-dom";

export default function LatestNews({ slideData }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const navigate = useNavigate();

	return (
		<div className='home-card pt-[20px] recommended-order-card'>
			<CardTitle
				title={ekkLanguage.home.latestNewsLabel}
				link='/'
				icon='assets/images/search.png'
			/>
			<div>
				{slideData ? (
					<>
						{slideData.length > 0 ? (
							<Swiper
								spaceBetween={0}
								breakpoints={{
									260: {
										slidesPerView: 1,
									},
									320: {
										slidesPerView: 1.5,
									},
									360: {
										slidesPerView: 1.5,
									},
									575: {
										slidesPerView: 2.5,
									},
									768: {
										slidesPerView: 2.5,
									},
								}}>
								{slideData.map((el, i) => {
									// let businessType = () =>
									// 	BUSINESS_TYPE_INFO.find(
									// 		(cur) => cur.type === el?.business_type?.title
									// 	);
									return (
										<SwiperSlide key={i}>
											<div className='relative overflow-hidden'>
												<div className='relative h-[156px]'>
													<img
														src={
															el?.thumbnail_image &&
															el?.thumbnail_image
														}
														className='w-full h-full rounded-[10px] mb-[5px] m-auto object-contain'
														alt='icon'
													/>
												</div>

												<div className='flex items-center'>
													<span className='font-bold text-16 text-262626-1000'>
														{el?.title}
													</span>
												</div>
											</div>
										</SwiperSlide>
									);
								})}
							</Swiper>
						) : (
							<IonRow>
								<IonCol>
									<div className='text-center'>
										<img
											className='m-auto mb-[10px] mt-5'
											src='/assets/images/not-found-file.png'
											alt='emptyCard'
										/>
										<p className='text-14 font-semibold text-black-500 mb-[35px]'>
											{ekkLanguage.home.mySupplierNotFound}
										</p>
									</div>
								</IonCol>
							</IonRow>
						)}
					</>
				) : (
					<div className='flex items-center justify-center h-[156px] m-auto'>
						{" "}
						<IonSpinner name='crescent'></IonSpinner>
					</div>
				)}
			</div>
		</div>
	);
}
