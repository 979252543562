import { IonApp, IonCol, IonContent, IonLoading, IonPage } from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom";
import Accounts from "services/Accounts";
import TranDetailsCard from "components/mobile-components/Accounts/TranDetailsCard";
import AccountDetailsSheet from "components/mobile-components/Accounts/AccountDetailsSheet";
import {
	setCategory,
	setExpense,
	setRevenue,
	setSupplier,
} from "redux/container/accountSlice";
import { setAccountData } from "redux/container/dashboardSlice";

export default function TransactionDetailsPage() {
	let { id } = useParams();
	const dispatch = useDispatch();
	let [transaction, setTransaction] = useState({});
	let [loader, setLoader] = useState(true);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const accountData = useSelector((state) => state.dashboard.accountData);

	const getTransaction = async () => {
		let response = await Accounts.getTransaction(id);
		if (response.success) {
			setLoader(false);
			setTransaction(response.results);
			let updatedData = accountData.map((item) =>
				item?.id == id
					? {
							id: response.results?.id,
							created_by_name: response.results?.created_by_name,
							amount: response.results?.amount,
							total_collected: response.results?.total_collected,
							createdAt: response.results?.createdAt,
							acc_category: response.results?.acc_category?.title,
					  }
					: item
			);
			dispatch(setAccountData(updatedData));
		} else {
			setLoader(false);
			setTransaction({});
		}
	};

	useEffect(() => {
		getTransaction();
	}, []);

	const getTransactionDetails = async () => {
		dispatch(setCategory({ ...transaction.acc_category }));
		dispatch(
			setSupplier({
				seller_name: transaction.seller_name,
				seller_id: transaction.seller_id,
			})
		);
		let data = {
			amount: transaction.amount,
			description: transaction.description,
			id: transaction.id,
			taxAmount: transaction.tax,
			images:
				transaction?.images?.length > 0
					? transaction?.images.map((url) => ({
							preview: url,
							serverImage: true,
					  }))
					: [],
		};

		if (transaction?.acc_group?.title === "Revenue") {
			dispatch(setRevenue({ ...data, revenueDate: transaction.date }));
		} else if (transaction?.acc_group?.title === "Expense") {
			dispatch(
				setExpense({
					...data,
					expenseDate: transaction.date,
					paidChecked: transaction?.is_paid,
					paidDate: transaction?.paid_date,
				})
			);
		}
	};

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle
						title='Transaction Details'
						from={transaction?.id && "accDetails"}
					/>

					<section className='px-2 pt-20'>
						{loader ? (
							<IonLoading isOpen={true} />
						) : (
							<>
								{transaction?.id ? (
									<TranDetailsCard collection={transaction} />
								) : (
									<>
										<IonCol size='2'></IonCol>
										<IonCol size='8'>
											<div
												className='text-center flex items-center justify-center'
												style={{ height: "calc(100vh - 420px)" }}>
												<div>
													<img
														className='m-auto mb-[10px] mt-5'
														src='/assets/images/not-found-file.png'
														alt='emptyCard'
													/>
													<p className='text-16 font-normal text-222222 mb-[35px]'>
														{ekkLanguage.orderList.notFound}
													</p>
												</div>
											</div>
										</IonCol>
									</>
								)}
							</>
						)}
					</section>
					<AccountDetailsSheet
						collection={transaction}
						getTransactionDetails={getTransactionDetails}
					/>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
