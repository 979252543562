import React, { Component } from "react";
import PropTypes from "prop-types";
import EyeOn from "assets/images/icon/svg/EyeOn";
import EyeOff from "assets/images/icon/svg/EyeOff";

export class Input extends Component {
	static defaultProps = {
		label: "",
		errorMessage: "",
		placeholder: "",
	};
	render() {
		const {
			label,
			placeholder,
			errorMessage,
			eyeActive,
			setEyeIcon,
			eyeIcon,
			...inputExtras
		} = this.props;
		return (
			<div className='mb-1'>
				{typeof label === "function" ? (
					label()
				) : (
					<label className=' uppercase tracking-wide text-grey-darker text-sm font-semibold mb-2'>
						{label}
					</label>
				)}
				<div className='relative'>
					<input
						placeholder={placeholder}
						className='appearance-none block w-full bg-grey-lighter text-grey-darker border mt-2   rounded py-2 px-2 mb-4 leading-tight focus:outline-none focus:bg-white'
						{...inputExtras}
					/>
					{eyeActive && (
						<span
							className='absolute right-3 top-[50%]'
							onClick={() => setEyeIcon(!eyeIcon)}
							style={{ transform: "translateY(-50%)" }}>
							{" "}
							{eyeIcon ? <EyeOn /> : <EyeOff />}
						</span>
					)}
				</div>

				{errorMessage && <p className='text-red text-xs '>errorMessage </p>}
			</div>
		);
	}
}

Input.propTypes = {
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};
