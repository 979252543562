import React from "react";

export default function Van() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='26'
			height='24'
			viewBox='0 0 26 24'
			fill='none'>
			<path
				d='M20.5833 4.41666H18.4167V3.33333C18.4167 2.47137 18.0743 1.64472 17.4648 1.03523C16.8553 0.425738 16.0286 0.0833282 15.1667 0.0833282H3.25C2.38805 0.0833282 1.5614 0.425738 0.951903 1.03523C0.34241 1.64472 0 2.47137 0 3.33333L0 19.5833H2.21108C2.18321 19.7626 2.16836 19.9436 2.16667 20.125C2.16667 21.1306 2.56614 22.095 3.27722 22.8061C3.9883 23.5172 4.95272 23.9167 5.95833 23.9167C6.96395 23.9167 7.92837 23.5172 8.63945 22.8061C9.35052 22.095 9.75 21.1306 9.75 20.125C9.7483 19.9436 9.73346 19.7626 9.70558 19.5833H16.2944C16.2665 19.7626 16.2517 19.9436 16.25 20.125C16.25 21.1306 16.6495 22.095 17.3606 22.8061C18.0716 23.5172 19.0361 23.9167 20.0417 23.9167C21.0473 23.9167 22.0117 23.5172 22.7228 22.8061C23.4339 22.095 23.8333 21.1306 23.8333 20.125C23.8316 19.9436 23.8168 19.7626 23.7889 19.5833H26V9.83333C25.9983 8.39727 25.427 7.02052 24.4116 6.00507C23.3961 4.98962 22.0194 4.41838 20.5833 4.41666ZM20.5833 6.58333C21.4453 6.58333 22.2719 6.92574 22.8814 7.53523C23.4909 8.14472 23.8333 8.97137 23.8333 9.83333V10.9167H18.4167V6.58333H20.5833ZM7.58333 20.125C7.58333 20.556 7.41213 20.9693 7.10738 21.274C6.80264 21.5788 6.38931 21.75 5.95833 21.75C5.52736 21.75 5.11403 21.5788 4.80928 21.274C4.50454 20.9693 4.33333 20.556 4.33333 20.125C4.334 19.9398 4.36813 19.7563 4.43408 19.5833H7.48258C7.54854 19.7563 7.58267 19.9398 7.58333 20.125ZM16.25 17.4167H2.16667V3.33333C2.16667 3.04601 2.2808 2.77046 2.48397 2.5673C2.68713 2.36413 2.96268 2.24999 3.25 2.24999H15.1667C15.454 2.24999 15.7295 2.36413 15.9327 2.5673C16.1359 2.77046 16.25 3.04601 16.25 3.33333V17.4167ZM21.6667 20.125C21.6667 20.556 21.4955 20.9693 21.1907 21.274C20.886 21.5788 20.4726 21.75 20.0417 21.75C19.6107 21.75 19.1974 21.5788 18.8926 21.274C18.5879 20.9693 18.4167 20.556 18.4167 20.125C18.4171 19.9398 18.4513 19.7563 18.5174 19.5833H21.5659C21.6321 19.7563 21.6662 19.9398 21.6667 20.125ZM18.4167 17.4167V13.0833H23.8333V17.4167H18.4167Z'
				fill='#222222'
			/>
		</svg>
	);
}
