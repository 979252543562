import { createSlice } from "@reduxjs/toolkit";
// import { shoppingCart } from "helpers/shoppingCart";

export const learningSlice = createSlice({
	name: "singlePost",
	initialState: {
		singlePost: {},
		postList: [],
		selectedTab: "My Company",
		helpList: [],
	},
	reducers: {
		singlePost: (state, action) => {
			state.singlePost = action.payload;
		},
		setPostList: (state, action) => {
			state.postList = action.payload;
		},
		setSelectedTab: (state, action) => {
			state.selectedTab = action.payload;
		},
		setHelpList: (state, action) => {
			state.helpList = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { singlePost, setPostList, setSelectedTab, setHelpList } =
	learningSlice.actions;
export const { actions, reducer } = learningSlice;

// export const setSelectedTab = (values) => async (dispatch, getState) => {
// 	dispatch(actions.setSelectedTab(values));
// };
export default reducer;
