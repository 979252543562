import React from "react";

export default function Alarm({ color = "#ffffff" }) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill={color}>
			<path d='M15.506 10.8L13.7727 4.55267C13.4025 3.21628 12.596 2.0421 11.4815 1.21699C10.367 0.391889 9.00854 -0.0367169 7.62232 -0.000610427C6.23609 0.035496 4.90177 0.53424 3.83173 1.41624C2.76168 2.29825 2.0174 3.51282 1.71734 4.86667L0.376671 10.9C0.3118 11.1923 0.313382 11.4954 0.381302 11.787C0.449221 12.0786 0.581744 12.3513 0.769094 12.5848C0.956444 12.8184 1.19384 13.0069 1.46377 13.1364C1.73369 13.266 2.02926 13.3333 2.32867 13.3333H4.73334C4.88635 14.0869 5.29516 14.7643 5.8905 15.2509C6.48584 15.7375 7.2311 16.0033 8 16.0033C8.76891 16.0033 9.51417 15.7375 10.1095 15.2509C10.7049 14.7643 11.1137 14.0869 11.2667 13.3333H13.58C13.8881 13.3332 14.192 13.2619 14.4679 13.125C14.7439 12.9881 14.9845 12.7892 15.171 12.544C15.3575 12.2988 15.4848 12.0138 15.5431 11.7113C15.6013 11.4088 15.5888 11.0969 15.5067 10.8H15.506ZM8 14.6667C7.58783 14.665 7.18625 14.5359 6.85021 14.2973C6.51416 14.0586 6.26006 13.7219 6.12267 13.3333H9.87734C9.73995 13.7219 9.48585 14.0586 9.1498 14.2973C8.81376 14.5359 8.41218 14.665 8 14.6667ZM14.11 11.7367C14.0478 11.8191 13.9672 11.8859 13.8746 11.9316C13.782 11.9773 13.6799 12.0008 13.5767 12H2.32867C2.22885 12 2.1303 11.9775 2.0403 11.9344C1.95031 11.8912 1.87116 11.8283 1.80871 11.7504C1.74625 11.6725 1.70208 11.5816 1.67946 11.4844C1.65684 11.3872 1.65634 11.2861 1.678 11.1887L3.01867 5.15533C3.25491 4.09281 3.83954 3.13974 4.67964 2.44763C5.51974 1.75553 6.56709 1.36411 7.65519 1.33561C8.74329 1.3071 9.80969 1.64315 10.6849 2.29031C11.5601 2.93748 12.1938 3.85863 12.4853 4.90733L14.2187 11.1547C14.247 11.2535 14.2519 11.3575 14.233 11.4585C14.2142 11.5595 14.172 11.6547 14.11 11.7367Z' />
		</svg>
	);
}
