import SuperFetch from "config/SuperFetch";
import { baseUrl } from "config/apiUrl";

const BazAi = {
	searchProduct: (query) => {
		return SuperFetch(`${baseUrl.audio}bazai/order/`, {
			method: "POST",
			data: query,
		});
	},
	setProcurement: (query) => {
		return SuperFetch(`${baseUrl.procurement}procurements/`, {
			method: "POST",
			data: query,
		});
	},
	findProductWithImage: (data) => {
		return SuperFetch(
			`${baseUrl.aiAgent}/procurement_assistant/image-order/`,
			{
				method: "POST",
				data: data,
			}
		);
	},
	findProductWithAudio: (data) => {
		return SuperFetch(
			`${baseUrl.aiAgent}/procurement_assistant/voice-order/`,
			{
				method: "POST",
				data: data,
			}
		);
	},
	findProcurementProduct: (message) => {
		return SuperFetch(`${baseUrl.aiAgent}/procurement_assistant/chat/`, {
			method: "POST",
			data: { message: message },
		});
	},
	// bazAiChatURL: () => {
	// 	return `${baseUrl.aiAgent}/procurement_assistant/chat-stream/`;
	// },
	bazAiChatURL: (message, token) => {
		return `${baseUrl.aiAgent}/procurement_assistant/chat-stream2/?message=${message}&Authorization=${token}`;
	},
	bazAiImageURL: () => {
		return `${baseUrl.aiAgent}/procurement_assistant/image-order/`;
	},
	bazAiJReceptURL: () => {
		return `${baseUrl.aiAgent}/procurement_assistant/get-exp-rev-attachment/`;
	},
	bazAiVoiceURL: () => {
		return `${baseUrl.aiAgent}/procurement_assistant/voice-order/`;
	},
	mailSend: (id, payload) => {
		return SuperFetch(`${baseUrl.procurement}/procurement-mail-send/${id}/`, {
			method: "POST",
			data: payload,
		});
	},
	getBusinessRules: () => {
		return SuperFetch(`${baseUrl.bizNpdt}/ekkbaz/business-rules/`);
	},
	createBusinessRules: (payload) => {
		return SuperFetch(`${baseUrl.bizNpdt}/ekkbaz/business-rules/`, {
			method: "POST",
			data: payload,
		});
	},
	updateBusinessRules: (id, payload) => {
		return SuperFetch(`${baseUrl.bizNpdt}/ekkbaz/business-rules/${id}/`, {
			method: "PUT",
			data: payload,
		});
	},
	createNewThread: () => {
		return SuperFetch(`${baseUrl.chat}/chat-thread/`, {
			method: "POST",
		});
	},
	getThreads: () => {
		return SuperFetch(`${baseUrl.chat}/chat-history/`);
	},
	getEkkbazThreads: () => {
		return SuperFetch(`${baseUrl.chat}/ekkbaz-chat-thread/`);
	},
	getMessage: (id, page = 1, limit = 10) => {
		return SuperFetch(
			`${baseUrl.chat}/chat-thread/?thread=${id}&page=${page}&limit=${limit}`
		);
	},
	ekkbazMessage: (threadId, page = 1, limit = 10) => {
		return SuperFetch(
			`${baseUrl.chat}/ekkbaz-chat-history/?thread=${threadId}&page=${page}&limit=${limit}`
		);
	},
	newChatMessage: (payload) => {
		return SuperFetch(`${baseUrl.chat}/create-chat/`, {
			method: "POST",
			data: payload,
		});
	},
};

export default BazAi;
