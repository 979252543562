import {
	IonButton,
	IonCol,
	IonGrid,
	IonInput,
	IonItem,
	IonRow,
} from "@ionic/react";
import City from "assets/images/icon/svg/City";
import Envelope from "assets/images/icon/svg/Envelope";
import Glove from "assets/images/icon/svg/Glove";
import LocationPin from "assets/images/icon/svg/LocationPin";
import OfficeCall from "assets/images/icon/svg/OfficeCall";
import PhoneCall from "assets/images/icon/svg/PhoneCall";
import PostalCode from "assets/images/icon/svg/PostalCode";
import Register from "assets/images/icon/svg/Register";
import Tax from "assets/images/icon/svg/Tax";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ProfileEdit from "./ProfileEdit";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Business from "services/Business";
import { getUserBusiness, setUserBusiness } from "config/user";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function BusinessEdit() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let [businessData, setBusinessData] = useState({});
	let business = getUserBusiness();
	let [loading, setLoading] = useState(false);
	const validationSchema = Yup.object().shape({
		// address_line: Yup.string(),
	});
	const navigate = useNavigate();
	const {
		register,
		handleSubmit,
		reset,
		setValue,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(validationSchema),
	});

	//get business data
	let getBusiness = async () => {
		let response = await Business.getBusiness(business?.id);
		if (response.status === 200) {
			setBusinessData(response.results);
			setValue("address_line", response.results?.address_line);
			setValue("city", response.results?.city);
			setValue("postal_code", response.results?.postal_code);
			setValue("registration_number", response.results?.registration_number);
			setValue("tax_vat_number", response.results?.tax_vat_number);
			setValue("phone_number", response.results?.phone_number);
			setValue("office_number", response.results?.meta_data?.office_number);
			setValue("email", response.results?.email);
			setValue("website", response.results?.meta_data?.website);
		}
	};
	useEffect(() => {
		getBusiness();
	}, []);

	const onSubmit = async (data) => {
		let response = await Business.updateUserBusiness(business?.id, data);
		if (response.status == 200) {
			toast.success("Business updated successfully");
			setBusinessData(response.results);
			setUserBusiness(response.results);
			navigate("/profile");
		} else {
			toast.error("Business updated failed");
		}
	};

	return (
		<div className='profile-edit bg-gray pt-14'>
			<ProfileEdit
				loading={loading}
				setLoading={setLoading}
				businessData={businessData}
				setBusinessData={setBusinessData}
			/>
			<div>
				<div className='px-2 relative pt-14'>
					<form
						onSubmit={handleSubmit(onSubmit)}
						className='profile-edit-form'>
						<IonGrid>
							<IonRow>
								{businessData?.business_status !== "Verified" ? (
									<IonCol size='12'>
										<div className='flex mb-7'>
											<span>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='24'
													height='24'
													viewBox='0 0 24 24'
													fill='none'>
													<g clip-path='url(#clip0_5404_568)'>
														<path
															d='M19 16.9996C17.9465 16.9993 16.9118 16.7213 16 16.1936C15.0882 16.7213 14.0535 16.9993 13 16.9996H11C9.94509 16.9983 8.90959 16.7159 8 16.1816C7.09045 16.716 6.05492 16.9984 5 16.9996H4C2.94562 16.9993 1.91028 16.7187 1 16.1866V20.9996C1 21.7953 1.31607 22.5584 1.87868 23.121C2.44129 23.6836 3.20435 23.9996 4 23.9996H20C20.7957 23.9996 21.5587 23.6836 22.1213 23.121C22.6839 22.5584 23 21.7953 23 20.9996V16.1876C22.0895 16.719 21.0542 16.9992 20 16.9996H19Z'
															fill='black'
														/>
														<path
															d='M17 0V6H15V0H9V6H7V0H2.2L0.024 9.783L0 11C0 12.0609 0.421427 13.0783 1.17157 13.8284C1.92172 14.5786 2.93913 15 4 15H5C5.56989 14.9982 6.13273 14.8738 6.65034 14.6354C7.16795 14.3969 7.62824 14.0499 8 13.618C8.37176 14.0499 8.83205 14.3969 9.34966 14.6354C9.86727 14.8738 10.4301 14.9982 11 15H13C13.5678 15.0003 14.1292 14.8794 14.6465 14.6454C15.1639 14.4114 15.6253 14.0696 16 13.643C16.3747 14.0696 16.8361 14.4114 17.3535 14.6454C17.8708 14.8794 18.4322 15.0003 19 15H20C21.0609 15 22.0783 14.5786 22.8284 13.8284C23.5786 13.0783 24 12.0609 24 11V10L21.8 0H17Z'
															fill='black'
														/>
													</g>
													<defs>
														<clipPath id='clip0_5404_568'>
															<rect
																width='24'
																height='24'
																fill='white'
															/>
														</clipPath>
													</defs>
												</svg>
											</span>
											<div className='ml-[10px] w-full'>
												<h5 className='text-dark-1000 text-16 font-semibold'>
													{ekkLanguage.businessEdit.businessName}
												</h5>
												<IonItem>
													<IonInput
														value={businessData?.business_name}
														type='text'
														placeholder={
															ekkLanguage.businessEdit
																.businessNamePlaceholder
														}
														{...register("business_name")}
														clearInput></IonInput>
												</IonItem>
												{/* <p className="text-right">{errors.address?.message}</p> */}
											</div>
										</div>
									</IonCol>
								) : undefined}

								<IonCol size='12'>
									<div className='flex mb-7'>
										<span>
											<LocationPin color='#222222' />
										</span>
										<div className='ml-[10px] w-full'>
											<h5 className='text-dark-1000 text-16 font-semibold'>
												{ekkLanguage.businessEdit.address}
											</h5>
											<IonItem>
												<IonInput
													value={businessData?.address_line}
													type='text'
													placeholder={
														ekkLanguage.businessEdit
															.addressPlaceholder
													}
													{...register("address_line")}
													clearInput></IonInput>
											</IonItem>
											{/* <p className="text-right">{errors.address?.message}</p> */}
										</div>
									</div>
								</IonCol>
								<IonCol size='6'>
									<div className='flex mb-7'>
										<span>
											<City />
										</span>
										<div className='ml-[10px] w-full'>
											<h5 className='text-dark-1000 text-16 font-semibold'>
												{ekkLanguage.businessEdit.city}
											</h5>
											<IonItem>
												<IonInput
													type='text'
													value={businessData?.city}
													placeholder={
														ekkLanguage.businessEdit
															.cityPlaceholder
													}
													{...register("city")}
													clearInput></IonInput>
											</IonItem>
										</div>
									</div>
								</IonCol>
								<IonCol size='6'>
									<div className='flex mb-7'>
										<span>
											<PostalCode />
										</span>
										<div className='ml-[10px] w-full'>
											<h5 className='text-dark-1000 text-16 font-semibold'>
												{ekkLanguage.businessEdit.postalCode}
											</h5>
											<IonItem>
												<IonInput
													type='text'
													value={businessData?.postal_code}
													placeholder={
														ekkLanguage.businessEdit
															.postalCodePlaceholder
													}
													{...register("postal_code")}
													clearInput></IonInput>
											</IonItem>
										</div>
									</div>
								</IonCol>
								<IonCol size='12'>
									<div className='flex mb-7'>
										<span>
											<Register />
										</span>
										<div className='ml-[10px] w-full'>
											<h5 className='text-dark-1000 text-16 font-semibold'>
												{ekkLanguage.businessEdit.resigter}
											</h5>
											<IonItem>
												<IonInput
													type='text'
													value={businessData?.registration_number}
													placeholder={
														ekkLanguage.businessEdit
															.resigterPlaceholder
													}
													{...register("registration_number")}
													clearInput></IonInput>
											</IonItem>
										</div>
									</div>
								</IonCol>
								<IonCol size='12'>
									<div className='flex mb-7'>
										<span>
											<Tax />
										</span>
										<div className='ml-[10px] w-full'>
											<h5 className='text-dark-1000 text-16 font-semibold'>
												{ekkLanguage.businessEdit.tex}
											</h5>
											<IonItem>
												<IonInput
													type='text'
													value={businessData?.tax_vat_number}
													placeholder={
														ekkLanguage.businessEdit
															.texPlaceholder
													}
													{...register("tax_vat_number")}
													clearInput></IonInput>
											</IonItem>
										</div>
									</div>
								</IonCol>
								<IonCol size='12'>
									<div className='flex mb-7'>
										<span>
											<PhoneCall color='#222222' />
										</span>
										<div className='ml-[10px] w-full'>
											<h5 className='text-dark-1000 text-16 font-semibold'>
												{ekkLanguage.businessEdit.mobile}
											</h5>
											<IonItem>
												<IonInput
													type='number'
													value={businessData?.phone_number}
													placeholder={
														ekkLanguage.businessEdit
															.mobilePlaceholder
													}
													readonly={true}
													{...register("phone_number")}
													clearInput></IonInput>
											</IonItem>
										</div>
									</div>
								</IonCol>
								<IonCol size='12'>
									<div className='flex mb-7'>
										<span>
											<OfficeCall />
										</span>
										<div className='ml-[10px] w-full'>
											<h5 className='text-dark-1000 text-16 font-semibold'>
												{ekkLanguage.businessEdit.office}
											</h5>
											<IonItem>
												<IonInput
													type='number'
													value={businessData?.office_number}
													placeholder={
														ekkLanguage.businessEdit
															.officePlaceholder
													}
													{...register("office_number")}
													clearInput></IonInput>
											</IonItem>
										</div>
									</div>
								</IonCol>
								<IonCol size='12'>
									<div className='flex mb-7'>
										<span>
											<Envelope />
										</span>
										<div className='ml-[10px] w-full'>
											<h5 className='text-dark-1000 text-16 font-semibold'>
												{ekkLanguage.businessEdit.email}
											</h5>
											<IonItem>
												<IonInput
													type='email'
													value={businessData?.email}
													placeholder={
														ekkLanguage.businessEdit
															.emailPlaceholder
													}
													{...register("email")}
													clearInput></IonInput>
											</IonItem>
										</div>
									</div>
								</IonCol>
								<IonCol size='12'>
									<div className='flex mb-7'>
										<span>
											<Glove />
										</span>
										<div className='ml-[10px] w-full'>
											<h5 className='text-dark-1000 text-16 font-semibold'>
												{ekkLanguage.businessEdit.website}
											</h5>
											<IonItem>
												<IonInput
													type='website'
													value={businessData?.website}
													placeholder={
														ekkLanguage.businessEdit
															.websitePlaceholder
													}
													{...register("website")}
													clearInput></IonInput>
											</IonItem>
										</div>
									</div>
								</IonCol>
							</IonRow>
							<div>
								<IonRow>
									<IonCol size='12'>
										<div className='text-center'>
											<IonButton
												type='submit'
												disabled={loading}
												className='bg-success rounded-[7px] font-extrabold text-12 h-10'>
												{ekkLanguage.contact.btnLabel}
											</IonButton>
										</div>
									</IonCol>
								</IonRow>
							</div>
						</IonGrid>
					</form>
				</div>
			</div>
		</div>
	);
}
