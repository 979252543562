import EditBusinessProfile from "assets/images/icon/svg/EditBusinessProfile";
import React from "react";
import { useDropzone } from "react-dropzone";

export default function ImageUploader({ files, setFiles, bHeight }) {
	const { getRootProps, getInputProps } = useDropzone({
		accept: "image/jpeg, image/png, image/jpg",
		onDrop: (acceptedFiles) => {
			setFiles(
				acceptedFiles.map((file) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)
			);
		},
	});

	return (
		<>
			{files?.length ? (
				<div
					style={{ width: "100%", height: bHeight }}
					className='flex justify-center items-center relative overflow-hidden '
				>
					<div
						style={{
							height: bHeight,
						}}
						className='text-center'
					>
						<img
							style={{ height: bHeight }}
							src={files[0].preview ?? files}
							alt='addImage'
							className='m-auto mb-2 object-contain'
						/>
						<div {...getRootProps({ className: "dropzone" })}>
							<input {...getInputProps()} />
							{/* <div className='absolute z-10 bottom-4 right-4 cursor-pointer'>
								<EditBusinessProfile />
							</div> */}
						</div>
					</div>
				</div>
			) : (
				<div
					className='flex justify-center items-center rounded-[10px]'
					style={{
						cursor: "pointer",
						borderRadius: 10,
						width: "100%",
						height: bHeight,
						background: "#E9EFFF",
						border: "1px dashed rgba(0, 0, 0, 0.33)",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
					{...getRootProps({ className: "dropzone" })}
				>
					<div className='text-center'>
						<input {...getInputProps()} />
						<img
							src='assets/images/add-image.png'
							alt='addImage'
							className='m-auto mb-[10px]'
							style={{
								filter: "drop-shadow(0px 15px 15px rgba(0, 0, 0, 0.1))",
							}}
						/>
						<p
							className='text-12 font-bold pb-[5px]'
							style={{ color: "#4285F4" }}
						>
							Upload your image here, or browse
						</p>
						<p className='text-12 font-normal text-black-500'>
							Maximum file size support 10 MB, JPG, JPEG or PNG
						</p>
					</div>
				</div>
			)}
		</>
	);
}
