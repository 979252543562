import Analytics from "pages/Analytics";
import Login from "pages/Auth/Login";
import Dashboard from "pages/Dashboard";
import Home from "pages/Home";
import Users from "pages/Users";
import { BrowserRouter, Routes, Route, Link, Outlet } from "react-router-dom";
import PrivateRoutes from "./PrivateRoutes";

function Web() {
	return (
		<BrowserRouter>
			<Routes>
				<Route
					path='/'
					element={
						<PrivateRoutes>
							<Home />
						</PrivateRoutes>
					}
				/>
				<Route
					path='/users'
					element={
						<PrivateRoutes>
							<Users />
						</PrivateRoutes>
					}
				/>
				<Route path='/login' element={<Login location='/login' />} />
			</Routes>
		</BrowserRouter>
	);
}

export default Web;
