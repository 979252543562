import { IonApp, IonContent, IonPage } from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import Branch from "components/mobile-components/Employees/Branch";
import EditEmpBottomSheet from "components/mobile-components/Employees/EditEmpBootomSheet";
import EmployeesInfo from "components/mobile-components/Employees/EmployeesInfo";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Business from "services/Business";

export default function EmployeesDetailsPage() {
  const { id } = useParams();
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const [employees, setEmployees] = useState([]);
  const [empLoading, setEmpLoading] = useState(false);

  const getEmployees = async () => {
    setEmpLoading(true);
    let response = await Business.getEmployees(id);
    if (response.status === 200) {
      setEmployees(response.results);
      setEmpLoading(false);
    } else {
      setEmpLoading(false);
    }
  };

  useEffect(() => {
    getEmployees();
  }, []);

  return (
    <IonApp>
      <IonPage>
        <IonContent>
          <BackButtonTitle
            title={ekkLanguage.employeesDetails.pageTitle}
            from="employeesDetails"
          />
          <EmployeesInfo employees={employees} />
          <Branch employees={employees} />
          <EditEmpBottomSheet employee={employees} />
        </IonContent>
      </IonPage>
    </IonApp>
  );
}
