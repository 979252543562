import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./style.scss";
import Scan from "assets/images/icon/svg/Scan";
import Bell from "assets/images/icon/svg/Bell";
import Search from "assets/images/icon/svg/Search";
import Cross from "assets/images/icon/svg/Cross";
import { digitChanger } from "helpers/helper";
import Mic from "assets/images/icon/svg/Mic";

export default function TopNavigation({ menuIconClick, sticky }) {
	const [text, setText] = useState("");
	const [tempSearchParams, setTempSearchParams] = useState("");
	let navigate = useNavigate();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const unreadCount = useSelector((state) => state.notification.unreadCount);

	const deleteQueryHandler = async (e) => {
		setTempSearchParams("");
		setText("");
	};
	return (
		<div
			className={`new-nav home-nav absolute left-0 top-0 w-full z-10 px-4 pt-2 ${
				sticky ? sticky : ""
			} `}>
			<div className='flex'>
				{/* <div className='h-11 w-11 rounded-[10px] flex justify-center items-center'>
					<Scan />
				</div> */}
				<div className='flex-1'>
					<div className='relative mx-[10px]'>
						<span className='absolute left-[10px] top-[24%] h-full z-10 cursor-pointer'>
							<Search color='rgba(0,0,0,.6)' />
						</span>
						<input
							className='bg-white w-full h-11 rounded-[10px] pl-12 search-input'
							type='text'
							placeholder={ekkLanguage.home.searchLabel}
							value={text}
							onKeyPress={(e) => {
								if (e.key === "Enter") {
									navigate(`/search-page/${e.target.value}`);
								}
							}}
							onChange={(e) => {
								setText(e.target.value);
							}}></input>
						{text && (
							<span
								className='absolute  right-[10px] top-[34%] h-full z-10 cursor-pointer'
								onClick={deleteQueryHandler}>
								<Cross />
							</span>
						)}
					</div>
					<p className='px-4 pt-2'>
						{" "}
						{tempSearchParams && (
							<span>
								search:{" "}
								<span className='font-bold'> {tempSearchParams}</span>
							</span>
						)}
					</p>
				</div>
				{/* <div
					onClick={() => {
						navigate(`/baz-ai`);
					}}
					className='h-11 w-10 rounded-[10px]  flex justify-center items-center relative cursor-pointer'>
				
					<img
						className='h-6 w-6'
						src='assets/images/bazAiIcon.png'
						alt='mic'
					/>
				</div> */}
				<div
					onClick={() => {
						navigate(`/notifications`);
					}}
					className='h-11 w-11 rounded-[10px]  flex justify-center items-center relative cursor-pointer'>
					<Bell />
					{unreadCount ? (
						<div className='text-8 text-primary border-2 border-white rounded-full h-[15px] w-[15px] flex items-center justify-center font-bold absolute top-1.5 right-2.5 bg-white'>
							<p className='mx-[1px] mt-[0.75px] mb-[0px] font-bold'>
								{digitChanger(unreadCount)}
							</p>
						</div>
					) : (
						""
					)}
				</div>
			</div>
		</div>
	);
}
