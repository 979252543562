import Menu from "assets/images/icon/svg/Menu";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DotMenu from "assets/images/icon/svg/DotMenu";
import {
	setAccountDetailsSheet,
	setAddBankBottomSheet,
	setBottomSheet,
	setBusinessBottomSheet,
	setEditEmployees,
	setLoanBottomSheet,
	setMyEmployees,
} from "redux/container/userSlice";
import Modal from "react-modal";
import CancelPopUp from "assets/images/icon/svg/CancelPopUp";
import {
	creditFee,
	paymentMethod,
	paymentTerm,
	setPaymentMethodText,
	setPaymentName,
	deliveryDate,
} from "redux/container/paymentMethodSlice";
import MenuLeftArrow from "assets/images/icon/svg/MenuLeftArrow";
import { clearExpense, clearRevenue } from "redux/container/accountSlice";
export default function BackButtonTitle({
	title,
	burgerMenu,
	menuIconClick,
	from,
}) {
	const [isOpen, setIsOpen] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const onCloseModal = () => {
		setIsOpen(false);
	};
	let { id } = useParams();

	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "300px",
			transition: ".4s all ease",
			borderRadius: 12,
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};
	return (
		<>
			<div className='top-nav-bar z-40 flex pl-4 items-center justify-between py-2 fixed left-0 top-0 w-full'>
				<div className='flex items-center'>
					{burgerMenu ? (
						<div
							className='flex items-center justify-center h-10 w-10 mr-4 rounded-[10px]'
							onClick={menuIconClick}>
							<Menu color='white' />
						</div>
					) : (
						<div
							className='flex items-center justify-center h-10 w-10 mr-4 rounded-[10px] cursor-pointer'
							onClick={() => {
								if (location.pathname === "/create-order") {
									let localCart = localStorage.getItem("cart");
									let cartParse = JSON.parse(localCart);
									if (cartParse?.length > 0) {
										setIsOpen(true);
									} else {
										navigate(-1);
									}
								} else if (location.pathname === "/add-bank-account") {
									navigate("/added-bank-list");
								} else if (location.pathname === "/added-bank-list") {
									navigate("/profile");
								} else if (location.pathname === "/profile") {
									navigate("/home");
								} else if (location.pathname === "/revenue-form") {
									dispatch(clearRevenue());
									navigate("/accounts");
								} else if (
									location.pathname.split("/")[1] === "revenue-edit"
								) {
									dispatch(clearRevenue());
									navigate(`/transaction-details/${id}`);
								} else if (
									location.pathname.split("/")[1] === "expense-edit"
								) {
									dispatch(clearExpense());
									navigate(`/transaction-details/${id}`);
								} else if (
									location.pathname.split("/")[1] ===
									"transaction-details"
								) {
									navigate("/accounts");
								} else if (location.pathname === "/expense-form") {
									dispatch(clearExpense());
									navigate("/accounts");
								} else {
									navigate(-1);
								}
							}}>
							<MenuLeftArrow />
						</div>
					)}

					<h5 className='text-white text-18 font-bold'>{title}</h5>
				</div>
				{from === "loan" && (
					<div className='mr-2 ml-6 cursor-pointer'>
						<span onClick={() => dispatch(setLoanBottomSheet(true))}>
							<DotMenu />
						</span>
					</div>
				)}
				{from === "orderDetailsPage" && (
					<div className='mr-4'>
						<span onClick={() => dispatch(setBottomSheet(true))}>
							<DotMenu />
						</span>
					</div>
				)}
				{from === "businessProfile" && (
					<div className='mr-4'>
						<span onClick={() => dispatch(setBusinessBottomSheet(true))}>
							<DotMenu />
						</span>
					</div>
				)}
				{from === "addedBankList" && (
					<div className='mr-4'>
						<span onClick={() => dispatch(setAddBankBottomSheet(true))}>
							<DotMenu />
						</span>
					</div>
				)}
				{from === "myEmployees" && (
					<div className='mr-4'>
						<span onClick={() => dispatch(setMyEmployees(true))}>
							<DotMenu />
						</span>
					</div>
				)}
				{from === "employeesDetails" && (
					<div className='mr-4'>
						<span onClick={() => dispatch(setEditEmployees(true))}>
							<DotMenu />
						</span>
					</div>
				)}
				{from === "accDetails" && (
					<div className='mr-4'>
						<span onClick={() => dispatch(setAccountDetailsSheet(true))}>
							<DotMenu />
						</span>
					</div>
				)}
			</div>
			<Modal
				closeTimeoutMS={200}
				isOpen={isOpen}
				onRequestClose={onCloseModal}
				id='pwa-modal'
				style={customStyles}>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2'>
						<CancelPopUp />
					</span>
					<h6 className='text-16 font-bold pb-[10px]'>
						{ekkLanguage.createOrder.alert}
					</h6>
					<p className='text-12 font-medium pb-[10px] text-black-500'>
						{ekkLanguage.createOrder.backButtonMessage}
					</p>
					<div className='flex justify-end pt-3'>
						<button
							color='primary'
							className='font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-primary text-white text-14'
							onClick={() => {
								dispatch(deliveryDate(""));
								dispatch(creditFee(0));
								dispatch(paymentTerm(0));
								dispatch(paymentMethod(""));
								dispatch(setPaymentMethodText(""));
								dispatch(setPaymentName(""));
								localStorage.removeItem("cart");
								navigate("/home");
							}}>
							{ekkLanguage.createOrder.okBtnLabel}
						</button>
					</div>
				</div>
			</Modal>
		</>
	);
}
