import { baseUrl } from "config/apiUrl";
import SuperFetch from "../config/SuperFetch";

const Department = {
	getDepartments: () => {
		return SuperFetch(`${baseUrl.bizNpdt}/seller/departments/?is_home=True`);
	},
	getDepartmentProducts: (id, page = 1, limit = 10) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/ekkbaz/departments/products/${id}/?page=${page}&limit=${limit}`
		);
	},
};

export default Department;
