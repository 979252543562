import { IonApp, IonContent, IonPage } from "@ionic/react";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import Employees from "components/mobile-components/Employees";
import BottomSheet from "components/mobile-components/Employees/BottomSheet";
import { getUserBusiness } from "config/user";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Business from "services/Business";

export default function MyEmployeesListPage() {
  const { id, category } = useParams();
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const [employees, setEmployees] = useState([]);
  const [empLoading, setEmpLoading] = useState(false);
  const [menuCollapse, setMenuCollapse] = useState(true);
  const [searchQ, setSearchQ] = useState("");
  const [tempSearchParams, setTempSearchParams] = useState("");
  let useBusiness = getUserBusiness();
  const menuIconClick = () => {
    setMenuCollapse(!menuCollapse);
  };
  const employeesList = async () => {
    setEmpLoading(true);
    let res = await Business.checkBusiness(useBusiness.id);
    if (res.status === 200) {
      let response = await Business.getEmployesList(
        res.results.id,
        roleMaker(category)
      );
      if (response.status === 200) {
        setEmployees(response.results);
        setEmpLoading(false);
      } else {
        setEmpLoading(false);
      }
    } else {
      setEmpLoading(false);
    }
  };
  const roleMaker = (role) => {
    if (role === "driver") {
      return "Driver";
    } else if (role === "collector") {
      return "Collector";
    }
  };
  const employeesSearch = async (q) => {
    setTempSearchParams(q);
    setEmpLoading(true);
    let res = await Business.checkBusiness(useBusiness.id);
    if (res.status === 200) {
      let response = await Business.getEmployeeSearch(
        q,
        res.results.id,
        roleMaker(category)
      );
      if (response.status === 200) {
        setEmployees(response.results);
        setEmpLoading(false);
      } else {
        setEmpLoading(false);
      }
    }
  };
  useEffect(() => {
    searchQ === "" && employeesList();
  }, [searchQ]);

  return (
    <IonApp>
      <IonPage className="business-name">
        <IonContent>
          <BackButtonTitle
            title={ekkLanguage.employees.pageTitle}
            burgerMenu={false}
            menuCollapse={menuCollapse}
            menuIconClick={menuIconClick}
            from={!category && "myEmployees"}
          />
          <LeftSidebar
            isMobile={true}
            menuCollapse={menuCollapse}
            menuIconClick={menuIconClick}
          />
          <Employees
            employees={employees}
            empLoading={empLoading}
            employeesSearch={employeesSearch}
            isForSelect={id ? true : false}
            ekkLanguage={ekkLanguage}
            searchQ={searchQ}
            setSearchQ={setSearchQ}
            tempSearchParams={tempSearchParams}
            setTempSearchParams={setTempSearchParams}
          />
          <BottomSheet />
        </IonContent>
      </IonPage>
    </IonApp>
  );
}
