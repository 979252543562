import React from "react";
import Card from "../Common/Card";
import "moment-timezone";
import { useSelector } from "react-redux";
import { digitChanger } from "helpers/helper";
import moment from "moment-timezone";
export default function PaymentSettlementCard({ collection }) {
	return (
		<div className='px-2 pb-5'>
			<Card
				title={`${
					collection?.acc_group?.title === "Expense"
						? "Expense"
						: "Revenue"
				} Details`}>
				<div>
					<table className='table-fixed w-full paymentSettlementLabel'>
						<tbody className="before:content-[' '] before:block before:h-2 border-b-2">
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{collection?.acc_group?.title === "Expense"
										? "Expense"
										: "Revenue"}{" "}
									Date
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{moment(collection?.date).format("DD-MM-YYYY")}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{collection?.acc_group?.title === "Expense"
										? "Expense"
										: "Revenue"}{" "}
									Amount
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{collection?.amount}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									Category
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{collection?.acc_category?.title}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									Description
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{collection?.description}
								</td>
							</tr>
							{collection?.images?.length && (
								<tr>
									<div>
										<td className='text-000080 text-12 font-medium py-[6px]'>
											Images
										</td>
									</div>
								</tr>
							)}
						</tbody>
					</table>
					<div className='pt-4 flex'>
						{collection?.images?.map((el, i) => {
							return (
								<img
									key={i}
									src={el}
									alt='images'
									className='rounded-[5px] bg-white block min-h-[100px] m-auto w-[50%]'
									style={{
										border: "0.5px solid rgba(0, 0, 0, 0.3)",
									}}
								/>
							);
						})}
					</div>
				</div>
			</Card>
		</div>
	);
}
