import { IonButton } from "@ionic/react";
import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { digitChanger, numberWithCommas } from "helpers/helper";
export default function SelectProduct({ product }) {
	const productCart = useSelector((state) => state.cart);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<div
			className='bg-white fixed bottom-0 left-0 w-full p-2 pb-2 z-10'
			style={{ boxShadow: "0px -2px 15px rgba(0, 0, 0, 0.1)" }}>
			<div className='flex justify-between pb-3'>
				<h6 className='text-222222 font-bold text-22'>
					{ekkLanguage.selectProduct.totalLabel}
				</h6>
				<h6 className='text-222222 font-bold text-22'>
					{product?.country?.currency_symbol_native}{" "}
					{digitChanger(numberWithCommas(productCart.total))}
				</h6>
			</div>
			<div>
				<Link to='/create-order'>
					<IonButton
						expand='block'
						className='bg-success rounded-xl h-12 font-sans font-bold shadow-none text-20'>
						{ekkLanguage.selectProduct.btnLabel}
					</IonButton>
				</Link>
			</div>
		</div>
	);
}
