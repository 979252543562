import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import LoanStatusBtn from "./LoanStatusBtn";
import LoanStatusCard from "./LoanStatusCard";
import LoanProducts from "./LoanProducts";

export default function LoanStatus({
	setOrderStatus,
	loading,
	setLoading,
	setReachedEnd,
	setHasMoreData,
	getAccountType,
	response,
	products,
}) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const productType = useSelector((state) => state.product?.loanProductType);
	const memoizedAccountStatusBtn = useMemo(
		() => (
			<LoanStatusBtn
				setOrderStatus={setOrderStatus}
				setReachedEnd={setReachedEnd}
				setHasMoreData={setHasMoreData}
				getAccountType={getAccountType}
				ekkLanguage={ekkLanguage}
			/>
		),
		[]
	);

	return (
		<div className='px-2 pt-14'>
			{memoizedAccountStatusBtn}
			{productType === "loanProducts" ? (
				<LoanProducts products={products} ekkLanguage={ekkLanguage} />
			) : (
				<LoanStatusCard response={response} />
			)}
		</div>
	);
}
