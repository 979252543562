import { getLanguage } from "config/language";
import { getUser } from "config/user";
import { digitChanger, monthChanger } from "helpers/helper";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setPostList } from "redux/container/learningSlice";
import Blog from "services/Blog";

export default function Learning({
	learningList,
	ekkLanguage,
	isOpenHelpCategory,
	setIsOpenHelpCategory,
	setLoading,
	setName,
}) {
	const [showAnim, setShowAnim] = useState({});
	const selectedTab = useSelector((state) => state.learning.selectedTab);
	const helpList = useSelector((state) => state.learning.helpList);
	const navigate = useNavigate();
	const localUser = getUser();
	const dispatch = useDispatch();
	const localLanguage = getLanguage();

	useEffect(() => {
		const animationCSS = `
		  @keyframes myAnimation {
			0% {
			  transform: translate(0, 0) scale(1);
			}
			50% {
			  transform: translate(2px, 2px) scale(1.1);
			}
			100% {
			  transform: translate(0, 0) scale(1);
			}
		  }`;

		const styleElement = document.createElement("style");
		styleElement.textContent = animationCSS;

		document.head.appendChild(styleElement);
	}, []);

	const userLikeCheck = (learningDetails) => {
		if (learningDetails?.bazzed_users) {
			for (const bazz of learningDetails?.bazzed_users) {
				if (bazz === localUser._id) {
					return true;
				}
			}
		} else {
			return false;
		}
	};

	const handleLike = async (post) => {
		let response = await Blog.addLike(post.id);
		if (response.status === 200) {
			setShowAnim({ show: true, id: post.id });
			const likedPostList = learningList.map((element) =>
				element.id === post?.id
					? {
							...element,
							bazz_count: element.bazz_count + 1,
							bazzed_users: [...element.bazzed_users, localUser?._id],
					  }
					: element
			);
			dispatch(setPostList(likedPostList));
		}
	};

	const handleHelpClick = async (name) => {
		setName(name);
		setLoading(true);
		setIsOpenHelpCategory(false);
		let response = await Blog.helpList(
			name,
			1,
			9,
			localLanguage === "bn" ? "BN" : "EN"
		);
		if (response.status === 200) {
			dispatch(setPostList(response?.results));
			setLoading(false);
		} else {
			setLoading(false);
		}
	};

	return (
		<>
			{/* All Post Array Map  */}
			{selectedTab === "My Company" || selectedTab === "News" ? (
				<>
					{learningList?.length > 0 ? (
						learningList.map((learn) => {
							let span = document.createElement("span");
							span.innerHTML = learn.content;
							let content =
								(span.textContent || span.innerText).substring(0, 180) +
								"...";
							return (
								<div
									key={learn.id}
									className='py-3 bg-white px-4 mb-[6px]'>
									{/* Image Section  */}
									<div className='rounded-[8px] mb-[10px]'>
										<img
											onClick={() =>
												navigate(`/learning-details/${learn.id}`)
											}
											src={learn?.thumbnail_image}
											className='w-full'
											alt='slideImage'
										/>
									</div>
									{/* Title  */}
									<h6
										onClick={() =>
											navigate(`/learning-details/${learn.id}`)
										}
										className='font-bold text-16 mb-[5px]'>
										{learn.title}
										{learn?.tags?.length > 4 && <br />}
										{learn.tags &&
											learn.tags.length > 0 &&
											learn.tags.map((tag, index) => {
												return (
													<span
														key={index}
														className='text-delivered px-0.5'>
														#{tag}
													</span>
												);
											})}
									</h6>
									{/* Created Date  */}
									<span className='text-808080 font-semibold text-12'>
										{digitChanger(
											monthChanger(
												moment(learn.createdAt).format("DD MMM, YY")
											)
										)}
									</span>
									{/* Content  */}
									<p
										onClick={() =>
											navigate(`/learning-details/${learn.id}`)
										}
										className='text-14 font-medium text-222222 text-justify'>
										{content}
										{learn.content.length > 180 && (
											<span
												className='text-delivered cursor-pointer'
												onClick={() =>
													navigate(`/learning-details/${learn.id}`)
												}>
												{ekkLanguage.landing.seeMore}
											</span>
										)}
									</p>
									{/* Like & Comment Icon Section  */}
									<div className='flex items-center justify-between py-[2px]'>
										{/* Like Section  */}
										<div className='flex items-center'>
											{!userLikeCheck(learn) ? (
												<button
													className='flex items-center'
													onClick={() => {
														handleLike(learn);
													}}>
													<img
														src='/assets/images/like.png'
														alt='icon'
														className='mr-[7px] w-[26px] h-[26px]'
													/>
													<h6 className='text-18 font-semibold pt-2'>
														{digitChanger(learn?.bazz_count)}
													</h6>
												</button>
											) : (
												<button
													className='flex items-center'
													onClick={() =>
														toast.error(
															ekkLanguage.learning.likedWarning
														)
													}>
													<img
														style={{
															animation: `${
																showAnim.show &&
																showAnim.id === learn.id &&
																"myAnimation 1s ease-in-out"
															}`,
															animationPlayState: `${
																showAnim.show &&
																showAnim.id === learn.id &&
																"running"
															}`,
														}}
														src='/assets/images/liked.png'
														alt='icon'
														className='mr-[7px] w-[26px] h-[26px]'
													/>
													<h6 className='text-18 font-semibold pt-2'>
														{digitChanger(learn.bazz_count)}
													</h6>
												</button>
											)}
										</div>
										{/* Comment Section  */}
										<div
											onClick={() =>
												navigate(`/learning-details/${learn.id}`)
											}
											className='flex items-center'>
											<img
												src='assets/images/message.png'
												alt='icon'
												className='mr-[7px]'
											/>
											<span className='text-16 font-semibold'>
												{digitChanger(learn?.comments?.length)}
											</span>
										</div>
									</div>
								</div>
							);
						})
					) : (
						<div
							className='text-center w-full  flex items-center justify-center'
							style={{ height: "calc(100vh - 420px)" }}>
							<div>
								<img
									className='m-auto mb-[10px] mt-5'
									src='/assets/images/not-found-file.png'
									alt='emptyCard'
								/>
								<p className='text-16 font-normal text-222222 mb-[35px]'>
									{ekkLanguage.orderList.notFound}
								</p>
							</div>
						</div>
					)}
				</>
			) : (
				<div className='px-4'>
					{isOpenHelpCategory ? (
						helpList?.categories?.length > 0 ? (
							helpList?.categories?.map((help, index) => {
								return (
									<div
										onClick={() => handleHelpClick(help.name)}
										className='mb-2 rounded-[10px] overflow-hidden'
										key={index}>
										<div
											style={{ backgroundColor: "#f1f1f199" }}
											className={`py-[10px] sm:px-4  px-4 cursor-pointer`}>
											{help.name}
										</div>
									</div>
								);
							})
						) : (
							<div
								className='text-center w-full flex items-center justify-center'
								style={{ height: "calc(100vh - 420px)" }}>
								<div>
									<img
										className='m-auto mb-[10px] mt-5'
										src='/assets/images/not-found-file.png'
										alt='emptyCard'
									/>
									<p className='text-16 font-normal text-222222 mb-[35px]'>
										{ekkLanguage.orderList.notFound}
									</p>
								</div>
							</div>
						)
					) : (
						<>
							{learningList?.length > 0 ? (
								learningList.map((learn) => {
									let span = document.createElement("span");
									span.innerHTML = learn.content;
									let content =
										(span.textContent || span.innerText).substring(
											0,
											180
										) + "...";
									return (
										<div
											key={learn.id}
											className='py-3 bg-white px-4 mb-[6px]'>
											{/* Title  */}
											<h6
												onClick={() =>
													navigate(`/learning-details/${learn.id}`)
												}
												className='font-bold text-16 mb-[5px]'>
												{learn.title}
												{learn?.tags?.length > 4 && <br />}
												{learn.tags &&
													learn.tags.length > 0 &&
													learn.tags.map((tag, index) => {
														return (
															<span
																key={index}
																className='text-delivered px-0.5'>
																#{tag}
															</span>
														);
													})}
											</h6>
											{/* Created Date  */}
											<span className='text-808080 font-semibold text-12'>
												{digitChanger(
													monthChanger(
														moment(learn.createdAt).format(
															"DD MMM, YY"
														)
													)
												)}
											</span>
											{/* Content  */}
											<p
												onClick={() =>
													navigate(`/learning-details/${learn.id}`)
												}
												className='text-14 font-medium text-222222 text-justify'>
												{content}
												{learn.content.length > 180 && (
													<span
														className='text-delivered cursor-pointer'
														onClick={() =>
															navigate(
																`/learning-details/${learn.id}`
															)
														}>
														{ekkLanguage.landing.seeMore}
													</span>
												)}
											</p>
											{/* Like & Comment Icon Section  */}
											<div className='flex items-center justify-between py-[2px]'>
												{/* Like Section  */}
												<div className='flex items-center'>
													{!userLikeCheck(learn) ? (
														<button
															className='flex items-center'
															onClick={() => {
																handleLike(learn);
															}}>
															<img
																src='/assets/images/like.png'
																alt='icon'
																className='mr-[7px] w-[26px] h-[26px]'
															/>
															<h6 className='text-18 font-semibold pt-2'>
																{digitChanger(
																	learn?.bazz_count
																)}
															</h6>
														</button>
													) : (
														<button
															className='flex items-center'
															onClick={() =>
																toast.error(
																	ekkLanguage.learning
																		.likedWarning
																)
															}>
															<img
																style={{
																	animation: `${
																		showAnim.show &&
																		showAnim.id ===
																			learn.id &&
																		"myAnimation 1s ease-in-out"
																	}`,
																	animationPlayState: `${
																		showAnim.show &&
																		showAnim.id ===
																			learn.id &&
																		"running"
																	}`,
																}}
																src='/assets/images/liked.png'
																alt='icon'
																className='mr-[7px] w-[26px] h-[26px]'
															/>
															<h6 className='text-18 font-semibold pt-2'>
																{digitChanger(learn.bazz_count)}
															</h6>
														</button>
													)}
												</div>
												{/* Comment Section  */}
												<div
													onClick={() =>
														navigate(
															`/learning-details/${learn.id}`
														)
													}
													className='flex items-center'>
													<img
														src='assets/images/message.png'
														alt='icon'
														className='mr-[7px]'
													/>
													<span className='text-16 font-semibold'>
														{digitChanger(
															learn?.comments?.length
														)}
													</span>
												</div>
											</div>
										</div>
									);
								})
							) : (
								<div
									className='text-center w-full  flex items-center justify-center'
									style={{ height: "calc(100vh - 420px)" }}>
									<div>
										<img
											className='m-auto mb-[10px] mt-5'
											src='/assets/images/not-found-file.png'
											alt='emptyCard'
										/>
										<p className='text-16 font-normal text-222222 mb-[35px]'>
											{ekkLanguage.orderList.notFound}
										</p>
									</div>
								</div>
							)}
						</>
					)}
				</div>
			)}
		</>
	);
}
