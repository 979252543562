import React from "react";
import CardTitle from "./CardTitle";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./style.scss";
import { useSelector } from "react-redux";
import { IonCol, IonRow, IonSpinner } from "@ionic/react";
import { useNavigate } from "react-router-dom";
import { digitChanger, numberWithCommas } from "helpers/helper";
import StarBig from "assets/images/icon/svg/StarBig";

export default function NewProductCard({ slideData }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const navigate = useNavigate();

	return (
		<div className='home-card pt-[20px]'>
			<CardTitle
				title={ekkLanguage.home.newProductLabel}
				link='/'
				icon='assets/images/party-popper.png'
			/>
			<div>
				{slideData ? (
					<>
						{slideData.length > 0 ? (
							<Swiper
								spaceBetween={0}
								breakpoints={{
									260: {
										slidesPerView: 1,
									},
									320: {
										slidesPerView: 2.5,
									},
									360: {
										slidesPerView: 2.5,
									},
									575: {
										slidesPerView: 3.5,
									},
									768: {
										slidesPerView: 3.5,
									},
								}}>
								{slideData.map((el, i) => {
									let isImageLength = () => {
										if (el?.images?.length) {
											return `${el?.images[0]}`;
										} else {
											if (el?.type === "variant") {
												return "/assets/images/varient_default.png";
											} else if (el?.type === "pack") {
												return "/assets/images/pack_default.png";
											} else if (el?.type === "offer") {
												return "/assets/images/offer_default.png";
											}
										}
									};
									return (
										<SwiperSlide key={i}>
											<div className='relative overflow-hidden'>
												<div
													className=' text-center flex items-center '
													onClick={() =>
														navigate(
															navigate(
																`/supplier-details/${el?.id}`
															)
														)
													}>
													<div className='product-image-sizer relative rounded-[10px] flex pb-[5px]'>
														<div className='flex absolute w-auto h-[24px] border-2 border-white bg-success items-center justify-center rounded-[10px]  top-0 right-0'>
															<span className='ml-[5px] font-bold text-12 text-white px-1'>
																{el?.country &&
																	el?.country
																		?.currency_symbol_native}
																{digitChanger(
																	numberWithCommas(el?.profit)
																)}
															</span>
														</div>
														<img
															className='w-full h-full object-contain flex m-auto mb-[5px]'
															src={isImageLength()}
															alt='icon'
														/>
													</div>
												</div>
												<h5 className='font-bold text-14 text-primary mb-[5px]'>
													{el?.country &&
														el?.country?.currency_symbol_native}
													{digitChanger(
														numberWithCommas(el?.base_price)
													)}
													<span className='text-10 font-normal text-808080 ml-2 line-through'>
														{el?.country &&
															el?.country
																?.currency_symbol_native}
														{digitChanger(
															numberWithCommas(
																el?.base_price + 10
															)
														)}
													</span>
												</h5>
												<h6 className='font-semibold text-14 text-262626-1000 mb-[5px] min-h-[36px]'>
													{el?.name}
												</h6>
												<div className='flex justify-between items-center mr-4'>
													<div>
														<h6 className='font-semibold text-14 text-black-500'>
															{el?.weight}{" "}
															<span>{el?.weight_unit}</span>
														</h6>
													</div>
													<div className='flex'>
														<StarBig />
														<span className='text-14 text-black-1000 ml-1'>
															3.7
														</span>
													</div>
												</div>
											</div>
										</SwiperSlide>
									);
								})}
							</Swiper>
						) : (
							<IonRow>
								<IonCol>
									<div className='text-center'>
										<img
											className='m-auto mb-[10px] mt-5'
											src='/assets/images/not-found-file.png'
											alt='emptyCard'
										/>
										<p className='text-14 font-semibold text-black-500 mb-[35px]'>
											{ekkLanguage.home.mySupplierNotFound}
										</p>
									</div>
								</IonCol>
							</IonRow>
						)}
					</>
				) : (
					<div className='flex items-center justify-center h-[156px] m-auto'>
						{" "}
						<IonSpinner name='crescent'></IonSpinner>
					</div>
				)}
			</div>
		</div>
	);
}
