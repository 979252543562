import { baseUrl } from "config/apiUrl";
import { getUser } from "config/user";
import SuperFetch from "../config/SuperFetch";
let user = getUser();

const Product = {
	// productList: (page = 1, limit = 12) => {
	// 	return SuperFetch(
	// 		`${baseUrl.product}/v4/catalogs/buyer/discover?page=${page}&limit=${limit}&sort=price.desc`
	// 	);
	// },

	// productList: (page = 1, limit = 12) => {
	// 	if (user?.country === "Bangladesh") {
	// 		return SuperFetch(
	// 			`${baseUrl.product}/v4/products/buyer/ekk-trading-available-products?page=${page}&limit=${limit}`
	// 		);
	// 	} else {
	// 		return SuperFetch(
	// 			`${baseUrl.product}/v4/catalogs/buyer/discover?page=${page}&limit=${limit}&sort=price.desc`
	// 		);
	// 	}
	// },
	productList: (page = 1, limit = 12) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/products/?page=${page}&limit=${limit}`
		);
	},
	singleProductDetails: (id) => {
		return SuperFetch(`${baseUrl.bizNpdt}/seller/products/details/${id}/`);
	},
	singleProductDetailsOld: (id) => {
		return SuperFetch(`${baseUrl.bizNpdt}/offers/${id}`);
	},
	buyerSellerProductList: (buyer, seller, page = 1, limit = 10) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/sellers/${seller}/products?page=${page}&limit=${limit}`
		);
	},
	productSearch: (tarm, page = 1, limit = 500) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/products/search?term=${tarm}&page=${page}&limit=${limit}`
		);
	},
	// productSearch: (tarm, page = 1, limit = 500) => {
	// 	return SuperFetch(
	// 		`${baseUrl.bizNpdt}/v4/products/search/?search=${tarm}`
	// 	);
	// },

	offerProduct: (page = 1, limit = 10) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/offers/?page=${page}&limit=${limit}`
		);
	},
	offerProductDetails: (id) => {
		return SuperFetch(`${baseUrl.product}/v4/offers/${id}`);
	},
	productBrands: (id) => {
		return SuperFetch(`${baseUrl.bizNpdt}/sellers/${id}/brands`);
	},
	singleBrandProducts: (brandId, sellerId, page = 1, limit = 10) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/sellers/${sellerId}/brands/${brandId}/products/?page=${page}&limit=${limit}`
		);
	},

	// singleProductSearch: (sellerId, query, brandId) => {
	// 	return SuperFetch(
	// 		`${baseUrl.bizNpdt}/businesses/${sellerId}/products/?q=${query}&brand_id=${brandId}`
	// 	);
	// },
	singleProductSearch: (brandId, sellerId, query, page = 1) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/brands/${brandId}/sellers/${sellerId}/products/search/?term=${query}&page=${page}`
		);
	},
	sellerProductSearch: (sellerId, query, page = 1) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/sellers/${sellerId}/products/search/?term=${query}&page=${page}`
		);
	},
	brandProductSearch: (brandId, query, page = 1) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/brands/${brandId}/products/search/?term=${query}&page=${page}`
		);
	},
	offerSearch: (query, page = 1) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/seller/offer/search/?q=${query}&page=${page}`
		);
	},
	// audioProduct: (data) => {
	// 	return SuperFetch(`${baseUrl.audio}/voice/chatbot/`, {
	// 		method: "POST",
	// 		data: data,
	// 	});
	// },
	audioProduct: (data) => {
		return SuperFetch(`${baseUrl.audio}/voice/baz-chat/`, {
			method: "POST",
			data: data,
		});
	},
	getProducts: (query, page = 1, limit = 20) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/products/search/?term=${query}&page=${page}&limit=${limit}`
		);
	},
};
export default Product;
