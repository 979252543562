import { IonCol, IonGrid, IonRow, IonSpinner } from "@ionic/react";
import RightArrowThin from "assets/images/icon/svg/RightArrowThin";
import { digitChanger, numberWithCommas } from "helpers/helper";
import React from "react";
import { Link } from "react-router-dom";

export default function TransactionHistory({
	transLoading,
	todayPurchase,
	ekkLanguage,
}) {
	return (
		<div className='purchase-summary relative'>
			<IonGrid>
				<IonRow>
					<IonCol size='6'>
						<div className='text-center rounded-[10px] bg-white pr-[5px] pt-1'>
							<p className='text-14 text-success font-bold mb-[8px]'>
								{ekkLanguage.accounting.todayRevenue}
							</p>
							<div>
								{transLoading ? (
									<IonSpinner color='green'></IonSpinner>
								) : (
									<>
										<p className='text-12 text-262626-1000 font-semibold mb-[10px]'>
											{digitChanger(
												todayPurchase?.revenue_count ?? 0
											)}{" "}
											{ekkLanguage.accounting.revenues}
										</p>
										<h5 className='font-bold text-262626-1000 text-18'>
											${" "}
											{numberWithCommas(
												todayPurchase?.total_revenue ?? 0.0
											)}
										</h5>
									</>
								)}
							</div>
						</div>
					</IonCol>
					<IonCol size='6'>
						<div className='text-center rounded-[10px] bg-white pl-[5px] pt-1'>
							<p className='text-12 text-primary font-bold mb-[8px]'>
								{ekkLanguage.accounting.todayExpense}
							</p>

							<div>
								{transLoading ? (
									<IonSpinner color='green'></IonSpinner>
								) : (
									<>
										<p className='text-12 text-262626-1000 font-semibold mb-[10px]'>
											{digitChanger(
												todayPurchase?.expense_count ?? 0
											)}{" "}
											{ekkLanguage.accounting.expenses}
										</p>
										<h5 className='font-bold text-262626-1000 text-18'>
											${" "}
											{numberWithCommas(
												todayPurchase?.total_expense ?? 0.0
											)}
										</h5>
									</>
								)}
							</div>
						</div>
						<div className='flex justify-end items-center mt-1'>
							<Link
								to='/accounts-dashboard'
								className='text-0086B2 text-14 font-normal flex items-center underline'>
								{ekkLanguage.home.showMoreLabel}
								<span className='ml-2'>
									<RightArrowThin />
								</span>
							</Link>
						</div>
					</IonCol>
				</IonRow>
			</IonGrid>
		</div>
	);
}
