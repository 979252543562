import React from "react";

export default function Bag() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='12'
			height='12'
			viewBox='0 0 12 12'
			fill='none'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M8.87602 3.08334C8.89456 3.042 8.90325 2.99694 8.90142 2.95169H8.91667C8.85143 1.39512 7.56619 0.166672 6.00288 0.166672C4.43958 0.166672 3.15434 1.39512 3.0891 2.95169C3.08141 2.99524 3.08141 3.03979 3.0891 3.08334L3.0435 3.08334C2.29596 3.08334 1.49687 3.57682 1.26487 4.90338L0.811194 8.51693C0.440003 11.1701 1.80619 11.8333 3.58998 11.8333H8.42578C10.2044 11.8333 11.5294 10.8729 11.1994 8.51693L10.7509 4.90338C10.4776 3.61396 9.70432 3.08334 8.9671 3.08334L8.87602 3.08334ZM8.03771 3.08334C8.02147 3.04131 8.01287 2.99673 8.0123 2.95169C8.0123 1.83315 7.10242 0.926396 5.98002 0.926396C4.85762 0.926396 3.94774 1.83315 3.94774 2.95169C3.95543 2.99524 3.95543 3.03979 3.94774 3.08334H8.03771ZM4.30658 6.08667C4.02185 6.08667 3.79104 5.8491 3.79104 5.55605C3.79104 5.26299 4.02185 5.02542 4.30658 5.02542C4.59131 5.02542 4.82213 5.26299 4.82213 5.55605C4.82213 5.8491 4.59131 6.08667 4.30658 6.08667ZM7.16785 5.55605C7.16785 5.8491 7.39867 6.08667 7.68339 6.08667C7.96812 6.08667 8.19894 5.8491 8.19894 5.55605C8.19894 5.26299 7.96812 5.02542 7.68339 5.02542C7.39867 5.02542 7.16785 5.26299 7.16785 5.55605Z'
				fill='#808080'
			/>
		</svg>
	);
}
