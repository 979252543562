import { IonLoading } from "@ionic/react";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import AccountStatusCard from "./AccountStatusCard";
import AccountStatusBtn from "./AccountStatusBtn";

export default function AccountsStatus({
	setOrderStatus,
	loading,
	setLoading,
	setReachedEnd,
	setHasMoreData,
	getAccountType,
	response,
}) {
	const ekkLanguage = useSelector((state) => state.lan.lan);

	const memoizedAccountStatusBtn = useMemo(
		() => (
			<AccountStatusBtn
				setOrderStatus={setOrderStatus}
				setReachedEnd={setReachedEnd}
				setHasMoreData={setHasMoreData}
				getAccountType={getAccountType}
				ekkLanguage={ekkLanguage}
			/>
		),
		[]
	);
	return (
		<div className='px-2 pt-14'>
			{memoizedAccountStatusBtn}
			<AccountStatusCard response={response} />
		</div>
	);
}
