import React from "react";
import CardTitle from "./CardTitle";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./style.scss";
import { useSelector } from "react-redux";
import { IonCol, IonRow, IonSpinner } from "@ionic/react";
import { useNavigate } from "react-router-dom";
import { stringCount } from "helpers/helper";

export default function BrandCard2({ slideData, loading }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const navigate = useNavigate();

	return (
		<div className='home-card pt-[20px]'>
			<CardTitle
				title={ekkLanguage.home.brandLabel}
				link='/products/brand'
				icon='assets/images/brand.png'
			/>
			<div>
				<>
					{slideData.length > 0 ? (
						<Swiper
							spaceBetween={16}
							breakpoints={{
								260: {
									slidesPerView: 1,
								},
								320: {
									slidesPerView: 3.5,
								},
								360: {
									slidesPerView: 3.5,
								},
								575: {
									slidesPerView: 3.5,
								},
								768: {
									slidesPerView: 4.5,
								},
							}}>
							{slideData.map((el, i) => {
								return (
									<SwiperSlide key={i}>
										<div className='relative overflow-hidden'>
											<div className='relative  mini-image-sizer rounded-[10px] flex pb-[5px] m-auto'>
												<img
													onClick={() =>
														navigate(`/brand-details/${el?.id}`)
													}
													src={
														el?.images?.length
															? el?.images[0]
															: "/assets/images/badge_default.png"
													}
													alt='productImage'
													className='w-full h-full object-contain m-auto'
												/>
											</div>

											<h6 className='font-semibold text-12 text-262626-1000 mb-[5px]'>
												{stringCount(el?.brand_name, 20)}
											</h6>
											<div className='pt-[5px] flex'>
												<span className='text-primary text-10 font-bold ml-1'>
													{el?.publish_products_count}{" "}
													{ekkLanguage.home.product}
												</span>
											</div>
										</div>
									</SwiperSlide>
								);
							})}
						</Swiper>
					) : (
						<IonRow>
							<IonCol>
								<div className='text-center'>
									<img
										className='m-auto mb-[10px] mt-5'
										src='/assets/images/not-found-file.png'
										alt='emptyCard'
									/>
									<p className='text-14 font-semibold text-black-500 mb-[35px]'>
										{ekkLanguage.home.mySupplierNotFound}
									</p>
								</div>
							</IonCol>
						</IonRow>
					)}
				</>
			</div>
		</div>
	);
}
