import React, { useState } from "react";
import Sheet from "react-modal-sheet";
import Print from "assets/images/icon/svg/Print";
import { IonItem, IonSpinner } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { setProfitLossBottomSheet } from "redux/container/userSlice";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import CancelPopUp from "assets/images/icon/svg/CancelPopUp";
import { baseUrl } from "config/apiUrl";
import moment from "moment";
import { getAuth } from "config/authFetch";
import { useNavigate } from "react-router-dom";
import Pen from "assets/images/icon/svg/Pen";
import EditPen from "assets/images/icon/svg/EditPen";

export default function ProfitLossBottomSheet({ getDate }) {
	const ref = React.useRef();
	const navigate = useNavigate();
	const isOpenBottomSheet = useSelector(
		(state) => state.user.profitLossBottomSheet
	);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const dispatch = useDispatch();
	const [isOpenPrint, setIsOpenPrint] = useState(false);
	const [printLoading, setPrintLoading] = useState(false);
	const onClosePrintModal = () => setIsOpenPrint(false);
	const onOpenPrintModal = () => setIsOpenPrint(true);
	const { token } = getAuth();
	const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	const dateFormat = useSelector((state) => state.date.format);

	const getProfitLossPdf = async () => {
		setPrintLoading(true);
		let response = await fetch(
			`${baseUrl.contract}/Ekkbaz/profit-loss-statement-pdf/?start_date=${getDate?.start}&end_date=${getDate?.end}&timezone=${timeZone}`,
			{
				headers: {
					Authorization: `${token}`,
				},
			}
		).then((res) => res.blob());
		const url = window.URL.createObjectURL(response);
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute(
			"download",
			`profit-and-loss-${moment(getDate?.start, '"YYYY-MM-DD"').format(
				dateFormat
			)}-to-${moment(getDate?.end, "YYYY-MM-DD").format(dateFormat)}.pdf`
		);
		document.body.appendChild(link);
		link.click();
		link.remove();
		window.URL.revokeObjectURL(url);
		setPrintLoading(false);
		onClosePrintModal();
		dispatch(setProfitLossBottomSheet(false));
	};

	return (
		<div>
			<Sheet
				className='bottom-sheet'
				initialSnap={1}
				ref={ref}
				isOpen={isOpenBottomSheet}
				snapPoints={[170, 170, 170, 0]}
				onClose={() => dispatch(setProfitLossBottomSheet(false))}>
				<Sheet.Container>
					<Sheet.Header />
					<Sheet.Content>
						<IonItem button onClick={onOpenPrintModal} lines='none'>
							<span className='py-[5px]'>
								<Print />
							</span>
							<p className='text-14 font-normal text-black-1000 pl-2'>
								Save Profit and Loss Statement
							</p>
						</IonItem>
						<IonItem
							button
							onClick={() => {
								dispatch(setProfitLossBottomSheet(false));
								navigate("/category-settings");
							}}
							lines='none'>
							<span className='py-[5px] h-9 w-9 rounded-full bg-black-200 flex items-center justify-center'>
								<Pen />
							</span>
							<p className='text-14 font-normal text-black-1000 pl-2'>
								P&L Settings
							</p>
						</IonItem>
					</Sheet.Content>
				</Sheet.Container>

				<Sheet.Backdrop
					onClick={() => dispatch(setProfitLossBottomSheet(false))}
				/>
			</Sheet>
			<Modal
				open={isOpenPrint}
				onClose={onClosePrintModal}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2'>
						<CancelPopUp />
					</span>
					<h6 className='text-16 font-bold pb-[20px]'>
						Are you sure you want to save the profit and loss statement?
					</h6>

					<div className='flex'>
						<button
							className='border-2 font-bold h-10 rounded-[7px] w-1/2 mr-2 text-black-1000 border-black-200 border-solid'
							onClick={onClosePrintModal}>
							Cancel
						</button>
						<button
							color='success'
							className='font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-success text-white flex items-center justify-center'
							onClick={!printLoading && getProfitLossPdf}>
							{printLoading ? <IonSpinner name='crescent' /> : "Save"}
						</button>
					</div>
				</div>
			</Modal>
		</div>
	);
}
