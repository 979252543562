import React from "react";

export default function Shop() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='14'
			height='14'
			viewBox='0 0 14 14'
			fill='none'
		>
			<g clipPath='url(#clip0_2903_155)'>
				<path
					d='M9.3335 7.58334C9.06182 7.94557 8.70953 8.23958 8.30454 8.44208C7.89954 8.64458 7.45296 8.75 7.00016 8.75C6.54736 8.75 6.10079 8.64458 5.69579 8.44208C5.2908 8.23958 4.93851 7.94557 4.66683 7.58334C4.43818 7.89155 4.15045 8.15115 3.82043 8.34701C3.49041 8.54287 3.12469 8.67106 2.74461 8.72411C2.36453 8.77716 1.97769 8.754 1.60665 8.65599C1.23561 8.55798 0.887799 8.38708 0.583496 8.15325V11.0833C0.584422 11.8566 0.892011 12.5979 1.43879 13.1447C1.98557 13.6915 2.7269 13.9991 3.50016 14H10.5002C11.2734 13.9991 12.0148 13.6915 12.5615 13.1447C13.1083 12.5979 13.4159 11.8566 13.4168 11.0833V8.1515C13.1126 8.38542 12.7649 8.55644 12.3939 8.65459C12.023 8.75274 11.6362 8.77605 11.2561 8.72316C10.876 8.67028 10.5103 8.54226 10.1802 8.34656C9.8501 8.15087 9.56228 7.89142 9.3335 7.58334Z'
					fill='#808080'
				/>
				<path
					d='M12.6585 1.82642C12.5456 1.30672 12.2575 0.841635 11.8424 0.509112C11.4274 0.176589 10.9106 -0.00315734 10.3788 2.82214e-06H9.91683V1.75C9.91683 1.90471 9.85537 2.05309 9.74598 2.16248C9.63658 2.27188 9.48821 2.33334 9.3335 2.33334C9.17879 2.33334 9.03041 2.27188 8.92102 2.16248C8.81162 2.05309 8.75016 1.90471 8.75016 1.75V2.82214e-06H5.25016V1.75C5.25016 1.90471 5.1887 2.05309 5.07931 2.16248C4.96991 2.27188 4.82154 2.33334 4.66683 2.33334C4.51212 2.33334 4.36375 2.27188 4.25435 2.16248C4.14495 2.05309 4.0835 1.90471 4.0835 1.75V2.82214e-06H3.6215C3.0896 -0.0031288 2.57281 0.1767 2.15775 0.509339C1.7427 0.841977 1.45462 1.3072 1.34183 1.827L0.596329 5.19167L0.583496 5.845C0.584262 6.07482 0.630286 6.30223 0.718939 6.51425C0.807593 6.72628 0.93714 6.91877 1.10018 7.08073C1.42947 7.40783 1.8752 7.59072 2.33933 7.58917C2.56914 7.5884 2.79655 7.54238 3.00858 7.45373C3.22061 7.36507 3.4131 7.23553 3.57506 7.07248C3.73702 6.90944 3.86528 6.71609 3.95252 6.50348C4.03976 6.29086 4.08426 6.06315 4.0835 5.83334C4.0835 5.67863 4.14495 5.53025 4.25435 5.42086C4.36375 5.31146 4.51212 5.25 4.66683 5.25C4.82154 5.25 4.96991 5.31146 5.07931 5.42086C5.1887 5.53025 5.25016 5.67863 5.25016 5.83334C5.25016 6.29746 5.43454 6.74258 5.76273 7.07077C6.09091 7.39896 6.53603 7.58334 7.00016 7.58334C7.46429 7.58334 7.90941 7.39896 8.2376 7.07077C8.56579 6.74258 8.75016 6.29746 8.75016 5.83334C8.75016 5.67863 8.81162 5.53025 8.92102 5.42086C9.03041 5.31146 9.17879 5.25 9.3335 5.25C9.48821 5.25 9.63658 5.31146 9.74598 5.42086C9.85537 5.53025 9.91683 5.67863 9.91683 5.83334C9.91683 6.29746 10.1012 6.74258 10.4294 7.07077C10.7576 7.39896 11.2027 7.58334 11.6668 7.58334C12.131 7.58334 12.5761 7.39896 12.9043 7.07077C13.2325 6.74258 13.4168 6.29746 13.4168 5.83334V5.31242L12.6585 1.82642Z'
					fill='#808080'
				/>
			</g>
			<defs>
				<clipPath id='clip0_2903_155'>
					<rect width='14' height='14' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
}
