import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BazAi from "components/mobile-components/BazAI";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import NavFooter from "components/mobile-components/Common/NavFooter";
import BazAiApi from "services/BazAi";
import {
	setLoadMessage,
	setPage,
	setThreadId,
} from "redux/container/bazAiSlice";
import moment from "moment";
import { getLocalUser, getUser } from "config/user";
import { IonApp, IonContent, IonPage } from "@ionic/react";

function BazAiPage() {
	const contentRef = useRef();
	const messageData = useSelector((state) => state?.bazAi?.data);
	const dispatch = useDispatch();
	const user = getUser() ? getUser() : getLocalUser()?.user;

	const thread = useSelector((state) => state?.bazAi?.threadId);
	const page = useSelector((state) => state?.bazAi?.page);
	const [hasMore, setHasMore] = useState(true);
	const [loading, setLoading] = useState(false);

	const scrollToBottom = () => {
		if (contentRef.current) {
			const element = contentRef.current;
			element.scrollTop = element.scrollHeight;
		}
	};

	const getThreadList = async () => {
		let response = await BazAiApi.getEkkbazThreads();
		if (response.success) {
			dispatch(setThreadId(response.results?.thread));
			getMessage(response.results?.thread, 1);
		}
	};

	const getMessage = async (id, page) => {
		dispatch(setThreadId(id));
		let response = await BazAiApi.ekkbazMessage(id, page, 10);
		if (response.status) {
			setLoading(false);
			const transformedMessages = response?.results
				?.reverse()
				?.map((msg) => {
					return transformMessage(msg);
				});
			dispatch(setLoadMessage(transformedMessages));
			setTimeout(scrollToBottom, 100); // Add slight delay to ensure DOM is updated
		}
	};

	const transformMessage = (msg) => {
		const isBot = !msg.is_user;
		const baseMessageData = {
			user_name: isBot ? user?.DisplayName : "Bot",
			receiver: isBot,
			created_at: moment(msg.createdAt).format("LLLL"),
			avatar: isBot ? "assets/images/bot-s.png" : user?.profile_image,
			loading: false,
		};

		switch (msg.card_type) {
			case "cad-card":
				return {
					...baseMessageData,
					cardType: "infoCard",
					...(isBot
						? { message: msg.chat }
						: { text: msg.chat, type: "text" }),
				};

			case "bom-card":
				return {
					...baseMessageData,
					type: "success",
					bomJson: JSON.parse(msg?.cad_data),
					cardType: "bomCard",
					...(isBot
						? { message: msg.chat }
						: { text: msg.chat, type: "text" }),
				};

			case "procurement":
				return {
					...baseMessageData,
					type: "success",
					produermenId: msg?.procurement_id,
					cardType: "procurementCard",
					...(isBot
						? {
								message:
									"Your procurement request is successfully created.",
						  }
						: {
								text: "Your procurement request is successfully created.",
								type: "text",
						  }),
				};

			case "email":
				return {
					...baseMessageData,
					type: "success",
					cardType: "emailCard2",
					...(isBot
						? { message: msg.chat }
						: { text: msg.chat, type: "text" }),
				};

			case "image":
				return {
					...baseMessageData,
					type: "image",
					cardType: "image",
					imageUrl: msg?.images,
					...(isBot
						? { message: msg.chat }
						: { text: msg.chat, type: "image" }),
				};

			default:
				return {
					...baseMessageData,
					cardType: "infoCard",
					...(isBot
						? { message: msg.chat }
						: { text: msg.chat, type: "text" }),
				};
		}
	};

	useEffect(() => {
		dispatch(setPage(2));
		getThreadList();
	}, []);

	const loadMoreMessage = async () => {
		if (loading) return;
		try {
			setLoading(true);
			const response = await BazAiApi.ekkbazMessage(thread, page);
			if (response.results?.length > 0) {
				const transformedMessages = response?.results
					?.reverse()
					.map((msg) => {
						return transformMessage(msg);
					});
				dispatch(setLoadMessage([...transformedMessages, ...messageData]));
				dispatch(setPage(page + 1));
			} else {
				setHasMore(false);
			}
		} catch (error) {
			console.error("Error loading more messages:", error);
			setHasMore(false);
		} finally {
			setLoading(false);
		}
	};

	const handleScroll = (e) => {
		const element = e.target;
		if (element.scrollTop === 0 && hasMore && !loading) {
			loadMoreMessage();
		}
	};

	return (
		<IonApp>
			<IonPage>
				<IonContent scrollEvents={true}>
					<BackButtonTitle title='Baz AI' />
					<div
						ref={contentRef}
						className='flex-grow overflow-y-auto relative scroll-smooth h-full mt-14'
						style={{
							scrollbarWidth: "none",
							msOverflowStyle: "none",
							"::-webkit-scrollbar": {
								display: "none",
							},
							"::-moz-scrollbar": {
								display: "none",
							},
							"::-ms-scrollbar": {
								display: "none",
							},
							"::-o-scrollbar": {
								display: "none",
							},
							scrollbarColor: "transparent transparent",
							scrollbarTrack: {
								background: "transparent",
							},
							scrollbarThumb: {
								background: "transparent",
							},
						}}
						onScroll={handleScroll}>
						{loading && (
							<div className='text-center py-4'>
								<div className='loader mx-auto'></div>
							</div>
						)}
						<BazAi scrollToBottom={scrollToBottom} />
					</div>
				</IonContent>
				<NavFooter />
			</IonPage>
		</IonApp>
	);
}

export default BazAiPage;
