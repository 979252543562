import { IonLoading, IonSpinner } from "@ionic/react";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import AccountStatusCard from "./AccountStatusCard";
import AccountStatusBtn from "./AccountStatusBtn";

export default function AccountsStatus({
	setOrderStatus,
	loading,
	setLoading,
	setReachedEnd,
	setHasMoreData,
	getAccountType,
	response,
	start,
	end,
	showFilter,
	category,
	isCategory,
}) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const accountingSort = useSelector(
		(state) => state.dashboard.accountingSort
	);

	const memoizedAccountStatusBtn = useMemo(
		() => (
			<AccountStatusBtn
				setOrderStatus={setOrderStatus}
				setReachedEnd={setReachedEnd}
				setHasMoreData={setHasMoreData}
				getAccountType={getAccountType}
				ekkLanguage={ekkLanguage}
			/>
		),
		[start, end, showFilter, accountingSort]
	);
	return (
		<div className='px-2'>
			{memoizedAccountStatusBtn}
			{loading ? (
				<div className='flex items-center justify-center h-[calc(100vh-130px)] m-auto'>
					{" "}
					<IonSpinner name='crescent'></IonSpinner>
				</div>
			) : (
				<AccountStatusCard response={response} />
			)}
		</div>
	);
}
