export const DEVELOPMENT = {
	pass: "https://dpassapi.azurewebsites.net",
	business: "https://dbusiness.ekkbaz.com",
	product: "https://dproduct.ekkbaz.com",
	order: "https://dorderapi.azurewebsites.net",
	bucket: "https://dfileapi.azurewebsites.net",
	bizNpdt: "https://dbiznpdtapi.azurewebsites.net",
	social: "https://dsocialapi.azurewebsites.net",
	rtc: "https://drtc2api.azurewebsites.net",
	audio: "https://dbazaiapi.azurewebsites.net",
	mapKey: "AIzaSyBQ_daY075e4mISHGgNdjV5boeD_P73OV8",
	pay: "https://dpayapi.azurewebsites.net",
	account: "https://daccounting2.azurewebsites.net",
	fs: "https://dfinsptapi.azurewebsites.net",
	procurement: "https://dprocurementapi.azurewebsites.net",
	aiAgent: "https://dbazaiagentsapi2.azurewebsites.net",
	contract: "https://dcontractapi.azurewebsites.net",
	chat: "https://dchatapi.azurewebsites.net",
};
// export var baseUrl = DEVELOPMENT;

export const PRODUCTION = {
	pass: "https://ppassapi.azurewebsites.net",
	business: "https://pbusiness.ekkbaz.com",
	product: "https://pproduct.ekkbaz.com",
	order: "https://porderapi.azurewebsites.net",
	bucket: "https://pfileapi.azurewebsites.net",
	bizNpdt: "https://pbiznpdtapi.azurewebsites.net",
	social: "https://psocialapi.azurewebsites.net",
	rtc: "https://prtcapi.azurewebsites.net",
	audio: "https://pbazaiapi.azurewebsites.net",
	mapKey: "AIzaSyBQ_daY075e4mISHGgNdjV5boeD_P73OV8",
	pay: "https://ppayapi.azurewebsites.net",
	account: "https://paccounting3api.azurewebsites.net",
	fs: "https://pfinsptapi.azurewebsites.net",
	procurement: "https://pprocurementapi.azurewebsites.net",
	aiAgent: "https://pbazaiagentsapi.azurewebsites.net",
	contract: "https://pcontractapi.azurewebsites.net",
	chat: "https://pchat2api.azurewebsites.net",
};
export var baseUrl = PRODUCTION;
