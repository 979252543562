import React from "react";

export default function EmployeesInfo({ employees }) {
  return (
    <div className="pt-14 mb-5">
      <div className="flex py-5 px-4 bg-white items-center">
        <div
          className="flex w-[70px] h-[70px] bg-black-500 rounded-full justify-center items-center overflow-hidden"
          style={{ boxShadow: "1px 1px 5px rgba(0,0,0,.2)" }}
        >
          {employees?.profile_image ? (
            <img src={employees?.profile_image} alt="avatar" />
          ) : (
            <h3 className="text-40 font-bold text-white">
              {employees?.display_name && employees?.display_name[0]}
            </h3>
          )}
        </div>

        <div className="ml-4">
          <h6 className={`font-bold text-20 pb-[2px]`}>
            {employees?.display_name}
          </h6>
          <p className={`font-normal text-12 text-black-1000`}>
            {employees?.phone_number}
          </p>
          <p className={`font-normal text-12 text-black-1000`}>
            {employees?.role?.title}
          </p>
        </div>
      </div>
    </div>
  );
}
