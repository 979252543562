import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSupplier } from "redux/container/accountSlice";
import Pin from "assets/images/icon/svg/Pin";

export default function SupplierList({ supplierList, locatedSeller }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	function getQueryParams(search) {
		const params = new URLSearchParams(search);
		return {
			edit: params.get("edit"),
			id: params.get("id"),
		};
	}
	return (
		<div>
			{supplierList.length > 0 ? (
				supplierList.map((item, i) => {
					console.log(item);
					return (
						<div className='items-center justify-between' key={i}>
							<div
								className='flex items-center ease-in duration-100 hover:bg-e6e6e6 mb-[5px] py-[5px] px-4 cursor-pointer'
								onClick={() => {
									dispatch(
										setSupplier({
											seller_name: item?.business_name,
											seller_id: item?.id,
										})
									);

									if (
										getQueryParams(location.search)?.edit === "true"
									) {
										navigate(
											`/expense-edit/${
												getQueryParams(location.search)?.id
											}`
										);
									} else {
										navigate(
											`${
												location.pathname.split("/")[2] ===
												"expense"
													? "/expense-form"
													: "/revenue-form"
											} `
										);
									}
								}}>
								{item?.images?.length ? (
									<div className='h-10 w-10 rounded-full overflow-hidden'>
										<img src={item?.images[0]} alt='logo'></img>
									</div>
								) : (
									<div className='h-10 w-10 flex justify-center items-center bg-black-333 rounded-full'>
										<h3 className='font-bold text-24 text-white'>
											{item?.business_name[0].toUpperCase()}
										</h3>
									</div>
								)}

								<div className='ml-4'>
									<h4 className='text-14 text-black-1000 font-bold pb-[2px]'>
										{item?.business_name}
									</h4>
									<p className='text-10 text-black-500 ml-1'>
										{item?.address_line}
									</p>
									{locatedSeller && (
										<div>
											<span className='text-primary text-10 font-bold flex'>
												<Pin />
												<span className='ml-[2px]'>
													{item?.distance} {ekkLanguage.home.km}
												</span>
											</span>
										</div>
									)}
								</div>
							</div>
						</div>
					);
				})
			) : (
				<div
					className='text-center flex items-center justify-center'
					style={{ height: "calc(100vh - 420px)" }}>
					<div>
						<img
							className='m-auto mb-[10px] mt-5'
							src='/assets/images/not-found-file.png'
							alt='emptyCard'
						/>
						<p className='text-16 font-normal text-222222 mb-[35px]'>
							{ekkLanguage.orderList.notFound}
						</p>
					</div>
				</div>
			)}
		</div>
	);
}
