import {
	IonApp,
	IonCol,
	IonContent,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonPage,
	IonSpinner,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import { COUNTRYINFO } from "data/country";
import { numberWithCommas } from "helpers/helper";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Accounts from "services/Accounts";

export default function EmployeeMonthlyPartsPage() {
	const location = useLocation();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let [loading, setLoading] = useState(true);
	let [revenueData, setRevenueData] = useState([]);
	const [accountType, setAccountType] = useState(null);
	const [pageCount, setPageCount] = useState(2);
	const [hasMoreData, setHasMoreData] = useState(true);
	const [reachedEnd, setReachedEnd] = useState(false);
	const { createUserData } = useSelector((state) => state.user);
	const dateFormat = useSelector((state) => state.date.format);

	const searchParams = new URLSearchParams(location?.search);
	const date = useSelector((state) => state.dashboard.dateInfo);

	// let start, end;

	// if (date?.start && date?.end) {
	// 	start = moment(date.start).format("YYYY-MM-DD");
	// 	end = moment(date.end).format("YYYY-MM-DD");

	// 	} else {
	// 	start = moment(searchParams.get("state")).format("YYYY-MM-DD");
	// 	end = moment(searchParams.get("end")).format("YYYY-MM-DD");

	// }

	const [getDate, setGetDate] = useState({
		start: moment(searchParams.get("state")).format("YYYY-MM-DD"),
		end: moment(searchParams.get("end")).format("YYYY-MM-DD"),
	});

	let value = date?.level;

	const [totalAmount, setTotalAmount] = useState(0);
	const getRevenueData = async () => {
		let response = await Accounts.getRevenueExpenseDate(
			getDate?.start,
			getDate?.end,
			searchParams.get("userId")
		);

		if (response.status === 200) {
			setLoading(false);
			setRevenueData(response.results);
			setTotalAmount(response?.total);
		} else {
			setLoading(false);
			setRevenueData([]);
			setTotalAmount(0);
		}
	};
	useEffect(() => {
		getRevenueData();
	}, [value]);

	let loadMoreTran = async () => {
		if (hasMoreData) {
			let response = await Accounts.getRevenueExpenseDate(
				getDate?.start,
				getDate?.end,
				searchParams.get("userId"),
				pageCount
			);
			if (response.status === 200) {
				setLoading(false);
				setPageCount((prev) => prev + 1);
				setRevenueData([...revenueData, ...response.results]);
			} else {
				setHasMoreData(false);
				setLoading(false);
			}
		}
	};

	const headers = revenueData[0] ? Object?.keys(revenueData[0]) : [];

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle
						title='Employee Monthly Leaderboard'
						filter={true}
						setGetDate={setGetDate}
					/>
					<div className='mt-14 px-4'>
						<div className='pb-10'>
							<div className='pt-3'>
								<div className='px-2 flex justify-between items-center pt-1'>
									<h4 className='font-bold text-16 text-black-1000'>
										Revenue on{" "}
										<span>
											{moment(getDate?.start, '"YYYY-MM-DD"').format(
												dateFormat
											)}{" "}
											-{" "}
											{moment(getDate?.end, "YYYY-MM-DD").format(
												dateFormat
											)}
										</span>
									</h4>
								</div>
								<div className='px-2 flex justify-between items-center pt-1 pb-5'>
									<h4 className='font-bold text-16 text-black-1000'>
										Employee Name: {searchParams.get("userName")}
									</h4>
								</div>
								<div className='px-2 flex justify-between items-center mb-5 mt-1'>
									<h4 className='font-bold text-16 text-black-1000'>
										Total Amount:{" "}
										{COUNTRYINFO?.[createUserData?.country]?.currency}
										{numberWithCommas(totalAmount)}
									</h4>
								</div>

								<div>
									<div>
										{loading ? (
											<div className='flex items-center justify-center h-[156px] m-auto'>
												{" "}
												<IonSpinner name='crescent'></IonSpinner>
											</div>
										) : (
											<>
												<div className='overflow-x-auto'>
													<table
														className='table-auto w-full bg-white'
														style={{
															boxShadow:
																"0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
														}}>
														<thead>
															<tr className='border h-[45px] border-r-0 border-l-0 border-D9D9D9 px-4'>
																{headers?.map((header) => (
																	<th
																		key={header}
																		className='border p-1 font-bold text-12 min-w-[100px] capitalize'>
																		{header}
																	</th>
																))}
															</tr>
														</thead>
														<tbody>
															{revenueData?.length
																? revenueData?.map(
																		(row, index) => {
																			return (
																				<tr
																					key={index}
																					className='text-center h-[34px] border border-r-0 border-l-0 border-D9D9D9 px-4 cursor-pointer'>
																					{headers?.map(
																						(key) => (
																							<td
																								key={
																									key
																								}
																								className='border p-2 font-semibold text-10'>
																								{key ===
																								"date" ? (
																									<span className='font-bold'>
																										{moment(
																											row[
																												key
																											]
																										).format(
																											dateFormat
																										)}
																									</span>
																								) : (
																									<>
																										{
																											COUNTRYINFO?.[
																												createUserData
																													?.country
																											]
																												?.currency
																										}
																										{numberWithCommas(
																											row[
																												key
																											]
																										)}
																									</>
																								)}
																							</td>
																						)
																					)}
																				</tr>
																			);
																		}
																  )
																: undefined}
														</tbody>
													</table>
												</div>
												{!loading && revenueData?.length === 0 ? (
													<>
														<IonCol size='2'></IonCol>
														<IonCol size='8'>
															<div
																className='text-center flex items-center justify-center'
																style={{
																	height:
																		"calc(100vh - 420px)",
																}}>
																<div>
																	<img
																		className='m-auto mb-[10px] mt-5'
																		src='/assets/images/not-found-file.png'
																		alt='emptyCard'
																	/>
																	<p className='text-16 font-normal text-222222 mb-[35px]'>
																		{
																			ekkLanguage.orderList
																				.notFound
																		}
																	</p>
																</div>
															</div>
														</IonCol>
													</>
												) : undefined}
											</>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* {!loading && (
						<IonInfiniteScroll
							onIonInfinite={(ev) => {
								if (reachedEnd) {
									ev.target.complete();
									return;
								}
								loadMoreTran();
								setTimeout(() => {
									ev.target.complete();
									if (!hasMoreData) {
										setReachedEnd(true);
									}
								}, 1000);
							}}>
							<IonInfiniteScrollContent
								loadingText={ekkLanguage.orderList.loader}
								loadingSpinner={
									hasMoreData ? "bubbles" : null
								}></IonInfiniteScrollContent>
						</IonInfiniteScroll>
					)} */}
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
