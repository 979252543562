import {
	IonApp,
	IonContent,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonLoading,
	IonPage,
} from "@ionic/react";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import NavFooter from "components/mobile-components/Common/NavFooter";
import ProductSearch from "components/mobile-components/SelectProduct/ProductSearch";
import ProductList from "components/mobile-components/products/ProductList";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Supplier from "services/Supplier";

export default function MySupplierPage() {
	let { category } = useParams();
	const [brandData, setBrandData] = useState([]);
	const [productCount, setProductCount] = useState();
	const [pageCount, setPageCount] = useState(2);
	const [menuCollapse, setMenuCollapse] = useState(true);
	const [supplierLoading, setSupplierLoading] = useState(true);
	const menuIconClick = () => {
		setMenuCollapse(!menuCollapse);
	};
	const [hasMoreData, setHasMoreData] = useState(true);
	const [reachedEnd, setReachedEnd] = useState(false);
	const location = useSelector((state) => state.user.locationData);

	const ekkLanguage = useSelector((state) => state.lan.lan);
	//get suppliers data
	let getMySupplierList = async () => {
		if (location) {
			let response = await Supplier.getSupplierList(
				location?.coordinates[1],
				location?.coordinates[0]
			);

			if (response.status === 200) {
				setSupplierLoading(false);
				setBrandData(response.results);
				setProductCount(response?.count);
			} else {
				setSupplierLoading(false);
			}
		}
	};
	useEffect(() => {
		getMySupplierList();
	}, []);

	let link = "supplier-details";
	let settings = {
		nameShow: false,
		skuShow: false,
		priceShow: false,
		oldPriceShow: false,
		distShow: true,
		profitShow: false,
		sellPriceShow: false,
		supplierNameShow: true,
		newPriceShow: false,
		defaultImage: "/assets/images/supplier_default.png",
	};
	let lodeMoreData = async () => {
		if (hasMoreData) {
			let response = await Supplier.getSupplierList(pageCount);
			if (response.status === 200) {
				if (response.results.length > 0) {
					setPageCount((prev) => prev + 1);
					setBrandData([...brandData, ...response.results]);
				} else {
					setHasMoreData(false);
				}
			} else {
				setHasMoreData(false);
			}
		}
	};
	// async function searchNext(event) {
	// 	let timeout = setTimeout(() => {
	// 		lodeMoreData();
	// 		clearTimeout(timeout);
	// 	}, 500);
	// }
	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<LeftSidebar
						isMobile={true}
						menuCollapse={menuCollapse}
						menuIconClick={menuIconClick}
					/>

					<BackButtonTitle title={ekkLanguage.home.mySuppliersLabel} />
					<section className='pt-14'>
						{supplierLoading && <IonLoading isOpen={true} />}
						{/* <ProductSearch /> */}
						<ProductList
							productData={brandData}
							category={category}
							settings={settings}
							link={link}
						/>
					</section>
					<IonInfiniteScroll
						onIonInfinite={(ev) => {
							if (reachedEnd) {
								ev.target.complete();
								return;
							}
							lodeMoreData();
							setTimeout(() => {
								ev.target.complete();
								if (!hasMoreData) {
									setReachedEnd(true);
								}
							}, 500);
						}}>
						<IonInfiniteScrollContent
							loadingText={ekkLanguage.orderList.loader}
							loadingSpinner={
								hasMoreData ? "bubbles" : null
							}></IonInfiniteScrollContent>
					</IonInfiniteScroll>
				</IonContent>
				<NavFooter />
			</IonPage>
		</IonApp>
	);
}
