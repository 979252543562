import { IonItem, IonToggle } from "@ionic/react";

import React, { forwardRef, useEffect, useState } from "react";
import Sheet from "react-modal-sheet";
import { useDispatch, useSelector } from "react-redux";
import "react-responsive-modal/styles.css";
import { useNavigate } from "react-router-dom";
import {
	setEditEmployees,
	setEditEmployeeData,
} from "redux/container/userSlice";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import CancelPopUp from "assets/images/icon/svg/CancelPopUp";
import { ClipLoader } from "react-spinners";
import Business from "services/Business";
import TextInputWithIcon from "../Common/TextInputWithIcon";
import Product from "services/Product";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Accounts from "services/Accounts";
import { getUserBusiness } from "config/user";

export default function EditEmpBottomSheet({
	employee,
	employeeSalary,
	getEmployees,
	employees
}) {
	const ref = React.useRef();
	const isOpenBottomSheet = useSelector((state) => state.user.editEmployees);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [isOpen, setIsOpen] = useState(false);
	const [cancelLoader, setCancelLoader] = useState(false);
	const [salaryAmount, setSalaryAmount] = useState(0);

	const [isHourly, setIsHourly] = useState(null);
	const [salaryAmountEmpty, setSalaryAmountEmpty] = useState("");
	const onOpenModal = () => setIsOpen(true);
	const onCloseModal = () => setIsOpen(false);

	const [isOpenSalary, setIsOpenSalary] = useState(false);
	const [cancelLoaderSalary, setCancelLoaderSalary] = useState(false);
	const onOpenSalaryModal = () => setIsOpenSalary(true);
	const onCloseSalaryModal = () => setIsOpenSalary(false);
	const [totalWorkingHours, setTotalWorkingHours] = useState("00:00:00");
	const [selectedHours, setSelectedHours] = useState(null);
	const dateFormat = useSelector((state) => state.date.format);
	const [editHoursLoader, setEditHoursLoader] = useState(false);
	const location = useSelector((state) => state.user.locationData);
	const business = getUserBusiness();
	const thread = useSelector((state) => state?.bazAi?.threadId);
	const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

	const deleteHandler = async () => {
		setCancelLoader(true);
		let response = await Business.deleteEmployee(employee.id);
		if (response.status === 200) {
			dispatch(setEditEmployees(false));
			navigate("/employees-list");
			setCancelLoader(false);
		} else {
			setCancelLoader(false);
		}
	};

	const editSalaryHandler = async () => {
		if (salaryAmount) {
			setCancelLoaderSalary(true);
			let req_body = {
				amount: salaryAmount,
				salary_type: isHourly ? "Hourly" : "Monthly",
			};
			let response = await Product.updateEmployeeSalary(
				employee.id,
				req_body
			);
			if (response.status === 200 || response.status === 201) {
				setCancelLoaderSalary(false);
				onCloseSalaryModal();
				dispatch(setEditEmployees(false));
				getEmployees();
				toast.success("Salary updated successfully");
				setSalaryAmountEmpty("");
			} else {
				setCancelLoaderSalary(false);
			}
		} else {
			setSalaryAmountEmpty("Please enter salary amount");
		}
	};

	const handleToggleChange = (e) => {
		setIsHourly(e.detail.checked);
	};

	useEffect(() => {
		setSalaryAmount(Number(employeeSalary?.amount));
		setIsHourly(employeeSalary?.salary_type == "Hourly");
	}, [employeeSalary]);



	const [isOpenEditHoursModel, setIsOpenEditHoursModel] = useState(false);
	const onCloseModalEditHoursModel = () => {
		setTotalWorkingHours("00:00:00");
		setIsOpenEditHoursModel(false);
	};
	const onOpenModalEditHoursModel = () => setIsOpenEditHoursModel(true);


	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "calc(100% - 20px)",
			transition: ".4s all ease",
			borderRadius: 12,
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
			overflow: "auto",
		},
	};

	const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
		<div
			ref={ref}
			className={`p-3 block mt-4 example-custom-input pl-4 h-[50px] rounded-[5px] bg-F8F8F8  w-full border border-black-500`}
			onClick={onClick}>
			<div className='flex justify-between items-center'>
				<div className='flex items-center '>
					{selectedHours?.check_in_time
						? moment(selectedHours?.check_in_time).format(
								`${dateFormat}-HH:mm:ss`
						  )
						: "Select Time and Date"}
				</div>
			</div>
		</div>
	));

	const ExampleCustomInput2 = forwardRef(({ value, onClick }, ref) => (
		<div
			ref={ref}
			className={`p-3 block mt-4 example-custom-input pl-4 h-[50px] rounded-[5px] bg-F8F8F8  w-full border border-black-500`}
			onClick={onClick}>
			<div className='flex justify-between items-center'>
				<div className='flex items-center '>
					{selectedHours?.check_out_time
						? moment(selectedHours?.check_out_time).format(
								`${dateFormat}-HH:mm:ss`
						  )
						: "Select Time and Date"}
				</div>
			</div>
		</div>
	));

	let dateChanger = (date, type) => {
		let updatedHours;
		if (type === "start") {
			updatedHours = {
				...selectedHours,
				check_in_time: date,
			};
		} else {
			updatedHours = {
				...selectedHours,
				check_out_time: date,
			};
		}
		setSelectedHours(updatedHours);

		// Calculate total hours if both dates exist
		if (updatedHours.check_in_time && updatedHours.check_out_time) {
			const start = moment(updatedHours.check_in_time);
			const end = moment(updatedHours.check_out_time);
			const duration = moment.duration(end.diff(start));

			const hours = Math.floor(duration.asHours())
				.toString()
				.padStart(2, "0");
			const minutes = duration.minutes().toString().padStart(2, "0");
			const seconds = duration.seconds().toString().padStart(2, "0");

			setTotalWorkingHours(`${hours}:${minutes}:${seconds}`);
		}
	};

	const addHoursSubmit = async () => {
		setEditHoursLoader(true);
		let payload = {
			business_id: business?.id,
			created_by: employees?.user_id,
			visit_type: "Hub Working",
			check_out_time: moment(selectedHours?.check_out_time).format(
				"YYYY-MM-DDTHH:mm:ssZ"
			),
			check_in_time: moment(selectedHours?.check_in_time).format(
				"YYYY-MM-DDTHH:mm:ssZ"
			),
			timezone: timeZone,
		};
	
		let response = await Accounts.employeeCheck(payload);
		if (response.success) {
			setSelectedHours(null);
			getEmployees();
			setEditHoursLoader(false);
			toast.success("Working hours added successfully");
			onCloseModalEditHoursModel();
			dispatch(setEditEmployees(false));
		} else {
			setEditHoursLoader(false);
		}
	};

	return (
		<div>
			<Sheet
				className='bottom-sheet'
				initialSnap={1}
				ref={ref}
				isOpen={isOpenBottomSheet}
				snapPoints={[300, 300, 300, 0]}
				onClose={() => dispatch(setEditEmployees(false))}>
				<Sheet.Container>
					<Sheet.Header />
					<Sheet.Content>
						<IonItem
							button
							onClick={onOpenSalaryModal}
							lines='none'
							className='my-[5px]'>
							<span className='py-[5px]'>
								<img
									className='h-10 w-10'
									src='/assets/images/edit.png'
									alt='user'
								/>
							</span>
							<p className='text-16 font-bold text-black-1000 pl-2'>
								Edit Salary
							</p>
						</IonItem>
						<IonItem
							button
							onClick={() => {
								dispatch(setEditEmployeeData(employee));
								dispatch(setEditEmployees(false));
								navigate(`/update-employee/${employee.id}`);
							}}
							lines='none'
							className='my-[5px]'>
							<span className='py-[5px]'>
								<img
									className='h-10 w-10'
									src='/assets/images/edit.png'
									alt='user'
								/>
							</span>
							<p className='text-16 font-bold text-black-1000 pl-2'>
								Edit Employee
							</p>
						</IonItem>
						<IonItem
							button
							onClick={() => {onOpenModalEditHoursModel()}}
							lines='none'
							className='my-[5px]'>
							<span className='py-[5px]'>
								<img
									className='h-10 w-10'
									src='/assets/images/scheduled_for_delivery.png'
									alt='time'
								/>
							</span>
							<p className='text-16 font-bold text-black-1000 pl-2'>
								Add New Work Time
							</p>
						</IonItem>
						<IonItem
							button
							onClick={onOpenModal}
							lines='none'
							className='my-[5px]'>
							<span className='py-[5px]'>
								<img
									className='h-10 w-10'
									src='/assets/images/delete.png'
									alt='user'
								/>
							</span>
							<p className='text-16 font-bold text-black-1000 pl-2'>
								Delete Employee
							</p>
						</IonItem>
					</Sheet.Content>
				</Sheet.Container>

				<Sheet.Backdrop onClick={() => dispatch(setEditEmployees(false))} />
			</Sheet>
			<Modal
				open={isOpen}
				onClose={onCloseModal}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2'>
						<CancelPopUp />
					</span>
					<h6 className='text-16 font-bold pb-[10px]'>
						{ekkLanguage.createEmployees.deleteConformationAsk}
					</h6>

					<div className='flex'>
						<button
							className='border-2 font-bold h-10 rounded-[7px] w-1/2 mr-2 text-black-1000 border-black-200 border-solid'
							onClick={onCloseModal}>
							{ekkLanguage.orderDetails.noBtn}
						</button>
						<button
							color='primary'
							className='font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-primary text-white'
							onClick={!cancelLoader && deleteHandler}>
							{cancelLoader ? (
								<ClipLoader
									sizeUnit={"px"}
									size={20}
									color={"white"}
									loading={true}
								/>
							) : (
								ekkLanguage.orderDetails.yesBtn
							)}
						</button>
					</div>
				</div>
			</Modal>
			<Modal
				open={isOpenSalary}
				onClose={onCloseSalaryModal}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<h6 className='text-16 font-bold pb-[10px]'>Edit Salary</h6>

					<div className='text-left flex items-center justify-between'>
						<h5 className='text-dark-1000 text-16 mb-3 font-semibold'>
							Salary Type
						</h5>
						<div>
							<IonToggle
								labelPlacement='start'
								height='100'
								aria-label='Primary toggle'
								size='large'
								color='success'
								checked={isHourly}
								onIonChange={handleToggleChange}>
								<span className='font-bold text-14'>
									{isHourly ? "Hourly" : "Monthly"}
								</span>
							</IonToggle>
						</div>
					</div>

					<div className='text-left mb-4'>
						<h5 className='text-dark-1000 text-16 mb-3 font-semibold'>
							Salary
						</h5>

						<TextInputWithIcon
							type='number'
							inputText={salaryAmount}
							setInputText={setSalaryAmount}
							placeholder={`Enter salary ${
								isHourly ? "hourly" : "monthly"
							} amount`}
						/>
						{salaryAmountEmpty && (
							<p className='text-red-500 text-12'>{salaryAmountEmpty}</p>
						)}
					</div>

					<div className='flex'>
						<button
							className='border-2 font-bold h-10 rounded-[7px] w-1/2 mr-2 border-solid bg-primary text-white'
							onClick={onCloseSalaryModal}>
							Cancel
						</button>
						<button
							color='success'
							className='font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-success text-white'
							onClick={!cancelLoaderSalary && editSalaryHandler}>
							{cancelLoaderSalary ? (
								<ClipLoader
									sizeUnit={"px"}
									size={20}
									color={"white"}
									loading={true}
								/>
							) : (
								"Save"
							)}
						</button>
					</div>
				</div>
			</Modal>

			<Modal
				style={customStyles}
				open={isOpenEditHoursModel}
				onClose={() => setIsOpenEditHoursModel(false)}
				// classNames='bottom-sheet-modal'
			>
				<div className='p-5'>
					<div>
						<div className='pb-5'>
							<div className='flex'>
								<h4 className='font-bold text-14'>Start Date Time</h4>
							</div>
							<DatePicker
								showTimeSelect
								timeFormat='HH:mm'
								timeIntervals={15}
								dateFormat='dd/MM/yyyy HH:mm'
								selected={
									selectedHours?.check_in_time
										? moment(selectedHours.check_in_time).toDate()
										: new Date()
								}
								onChange={(date) => dateChanger(date, "start")}
								customInput={<ExampleCustomInput />}
								withPortal
								timeCaption='T(24H)'
							/>
						</div>
						<div className='pb-5'>
							<div className='flex'>
								<h4 className='font-bold text-14'>End Date Time</h4>
							</div>
							<DatePicker
								showTimeSelect
								timeFormat='HH:mm'
								timeIntervals={15}
								dateFormat='MMMM d, yyyy h:mm aa'
								selected={
									selectedHours?.check_out_time
										? moment(selectedHours.check_out_time).toDate()
										: selectedHours?.check_in_time ? moment(selectedHours.check_in_time).toDate() : new Date()
								}
								onChange={(date) => dateChanger(date, "end")}
								customInput={<ExampleCustomInput2 />}
								withPortal
								timeCaption='T(24H)'
								minDate={
									selectedHours?.check_in_time
										? moment(selectedHours.check_in_time).toDate()
										: new Date()
								}
							/>
						</div>
						<div className='pb-5'>
							<div className='flex'>
								<h4 className='font-bold text-14'>
									Total Working Hours: {totalWorkingHours}
								</h4>
							</div>
						</div>
					</div>

					<div className='flex'>
						<button
							className=' font-bold h-10 bg-primary rounded-[7px] w-1/2 mr-2 text-white  border-solid'
							onClick={() => {
								setSelectedHours(null);
								onCloseModalEditHoursModel();
							}}>
							{ekkLanguage.orderDetails.noBtn}
						</button>
						<button
							color='primary'
							className='font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-success text-white'
							onClick={addHoursSubmit}>
							{editHoursLoader ? (
								<ClipLoader
									sizeUnit={"px"}
									size={20}
									color={"white"}
									loading={true}
								/>
							) : (
								ekkLanguage.orderDetails.yesBtn
							)}
						</button>
					</div>
				</div>
			</Modal>
		</div>
	);
}
