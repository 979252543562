import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { setExpense, setRevenue } from "redux/container/accountSlice";
import Modal from "react-modal";
import Webcam from "react-webcam";
import Cross from "assets/images/icon/svg/Cross";
import { IonCol, IonRow } from "@ionic/react";
import Pen from "assets/images/icon/svg/Pen";
import CrossBig from "assets/images/icon/svg/CrossBig";

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

export default function MultiImageUploader({ bHeight, type }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let expense = useSelector((state) => state?.accounts?.expense);
	let revenue = useSelector((state) => state?.accounts?.revenue);
	let dispatch = useDispatch();
	const [isCameraOpen, setIsCameraOpen] = useState(false);
	const [isOpenUpload, setIsOpenUpload] = useState(false);
	const [isCameraMood, setIsCameraMood] = useState(false);

	const onOpenUploadModal = () => {
		setIsOpenUpload(true);
		setIsCameraOpen(false);
		setIsCameraMood(false);
	};

	const onCloseUploadModal = () => setIsOpenUpload(false);

	const [facingMode, setFacingMode] = React.useState(FACING_MODE_USER);

	const webcamRef = React.useRef(null);

	const cameraOpenHandler = () => {
		setIsCameraMood(true);
		setIsCameraOpen(true);
		onCloseUploadModal();
	};
	let videoConstraints = {
		facingMode: facingMode,
		width: { ideal: 1280 },
		height: { ideal: 720 },
		minWidth: "320px",
	};
	const trashImageHandler = () => {
		setIsCameraOpen(false);
		setIsCameraMood(true);
	};
	const closeCameraHandler = () => {
		setIsCameraMood(false);
		setIsCameraOpen(false);
	};

	const handleTakePhoto = useCallback(() => {
		const imageSrc = webcamRef.current.getScreenshot();
		setIsCameraOpen(false);
		setIsCameraMood(false);
		type === "expense"
			? dispatch(
					setExpense({
						...expense,
						images: [
							...expense?.images,
							{ preview: imageSrc, cameraImage: true },
						],
					})
			  )
			: dispatch(
					setRevenue({
						...revenue,
						images: [
							...revenue?.images,
							{ preview: imageSrc, cameraImage: true },
						],
					})
			  );
	}, [webcamRef, expense, revenue]);

	const handleClick = React.useCallback(() => {
		setFacingMode((prevState) =>
			prevState === FACING_MODE_USER
				? FACING_MODE_ENVIRONMENT
				: FACING_MODE_USER
		);
	}, []);

	const customModalStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "300px",
			transition: ".4s all ease",
			borderRadius: 12,
			// height: "calc(100% - 60px)",
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};

	const { getRootProps, getInputProps } = useDropzone({
		accept: {
			"image/jpeg": [".jpeg", ".jpg"],
			"image/png": [".png"],
		},
		onDrop: (acceptedFiles) => {
			onCloseUploadModal();
			type === "expense"
				? dispatch(
						setExpense({
							...expense,
							images: [
								...expense?.images,
								...acceptedFiles.map((file) =>
									Object.assign(file, {
										preview: URL.createObjectURL(file),
									})
								),
							],
						})
				  )
				: dispatch(
						setRevenue({
							...revenue,
							images: [
								...revenue.images,
								...acceptedFiles.map((file) =>
									Object.assign(file, {
										preview: URL.createObjectURL(file),
									})
								),
							],
						})
				  );
		},
	});

	const imageRemover = (el) => {
		if (type === "expense") {
			const filteredArray = expense?.images.filter(
				(item) => item.preview !== el.preview
			);
			dispatch(
				setExpense({
					...expense,
					images: filteredArray,
				})
			);
		} else {
			const filteredArray = revenue?.images.filter(
				(item) => item.preview !== el.preview
			);
			dispatch(
				setRevenue({
					...revenue,
					images: filteredArray,
				})
			);
		}
	};
	const handleError = (error) => {
		console.error("Webcam error: ", error);
	};

	return (
		<>
			{type === "expense" ? (
				<>
					{expense?.images?.length && !isCameraMood ? (
						<div
							style={{
								padding: 5,
								borderRadius: 5,
								width: "100%",
								minHeight: bHeight,
								border: "1px solid rgba(0, 0, 0, 0.33)",
							}}
							// className='flex justify-center items-center relative overflow-hidden '
						>
							<div
								style={{
									minHeight: bHeight,
								}}
								className='text-center'>
								{expense?.images?.length ? (
									<div>
										<div className='flex flex-wrap'>
											{expense?.images?.map((el, i) => {
												return (
													<div className='relative mr-1'>
														<img
															key={i}
															className='mb-3 border rounded-[4px] max-h-[100px]'
															src={el?.preview}
															alt='images'
														/>
														<span
															className='absolute top-1 right-2 flex bg-primary p-1 rounded-full deselect-image'
															onClick={() => imageRemover(el)}>
															<ion-icon
																size='small'
																name='trash-outline'></ion-icon>
														</span>
													</div>
												);
											})}
											<div className='border flex justify-center items-center rounded-[5px] h-[100px] w-[90px] mr-1'>
												<div
													className='relative'
													onClick={onOpenUploadModal}
													// {...getRootProps({
													// 	className: "dropzone",
													// })}
												>
													{/* <input {...getInputProps()} /> */}
													<img
														src='/assets/images/add-image.png'
														alt='addImage'
														className='m-auto mb-[10px]'
														style={{
															filter:
																"drop-shadow(0px 15px 15px rgba(0, 0, 0, 0.1))",
														}}
													/>
													<p
														className='text-12 font-bold pb-[5px]'
														style={{ color: "#4285F4" }}>
														Add Image
													</p>
												</div>
											</div>
										</div>
									</div>
								) : undefined}
							</div>
						</div>
					) : (
						<>
							{isCameraMood && (
								<div
									className='flex justify-center items-center rounded-[10px]'
									style={{
										cursor: "pointer",
										borderRadius: 10,
										width: "100%",
										height: bHeight,
										background: "#E9EFFF",
										border: "1px dashed rgba(0, 0, 0, 0.33)",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										position: "relative",
									}}>
									<div className='text-left rounded-[10px] overflow-hidden'>
										<div
											className='rounded-[10px] flex items-center justify-center'
											style={{
												boxShadow:
													"0px 5px 15px rgba(0, 0, 0, 0.07)",
												background: "#E6E6E6",
												height: !isCameraOpen && "320px",
												width: "100%",
											}}>
											{isCameraOpen ? (
												<div className='relative photo-camera h-[320px]'>
													<Webcam
														className='webcam'
														audio={false}
														ref={webcamRef}
														screenshotFormat='image/jpeg'
														videoConstraints={videoConstraints}
														screenshotQuality={1}
													/>
													<div className='bg-black-500 absolute bottom-0 w-full flex items-center justify-between'>
														<span
															onClick={handleClick}
															className=' rounded-[7px] text-center flex items-center justify-center'
															style={{
																boxShadow:
																	"0px 5px 15px rgba(0, 0, 0, 0.1)",
															}}>
															<img
																src='/assets/images/camera-switch.png'
																alt='Camera'
																className='z-1'
															/>
														</span>
														<span
															onClick={handleTakePhoto}
															className='cursor-pointer rounded-[7px] text-center flex items-center justify-center'
															style={{
																boxShadow:
																	"0px 5px 15px rgba(0, 0, 0, 0.1)",
																// left: "50%",
																// transform: "translateX(-50%)",
															}}>
															<img
																src='/assets/images/camera-icon.png'
																alt='Camera'
																className='z-1'
															/>
														</span>
														<span
															onClick={closeCameraHandler}
															className=' rounded-[7px] text-center flex items-center justify-center mr-3'
															style={{
																boxShadow:
																	"0px 5px 15px rgba(0, 0, 0, 0.1)",
															}}>
															<CrossBig></CrossBig>
														</span>
													</div>
												</div>
											) : (
												<>
													{expense?.images?.length ? (
														<>
															<img
																src={expense?.images[0]}
																alt='avatar'
															/>
															<div onClick={onOpenUploadModal}>
																<span
																	className='absolute h-7 w-7 rounded-full text-center flex items-center justify-center right-2 top-2 border-4 border-white'
																	style={{
																		background: "#5A7EFF",
																		lineHeight: "28px",
																	}}>
																	<Pen />
																</span>
															</div>
														</>
													) : (
														<div
															onClick={cameraOpenHandler}
															className='text-center rounded-[5px] inline-block bg-white px-9 py-5 cursor-pointer'
															style={{
																boxShadow:
																	"0px 5px 15px rgba(0, 0, 0, 0.07)",
															}}>
															<img
																className='m-auto'
																src='/assets/images/camera.jpg'
																alt='camera'
															/>
															<p className='text-14 pt-1 text-black-1000'>
																{ekkLanguage.payment.takePhoto}
																take photo
															</p>
														</div>
													)}
												</>
											)}{" "}
										</div>
									</div>
								</div>
							)}

							{!isCameraMood && (
								<div
									className='flex justify-center items-center rounded-[10px]'
									style={{
										cursor: "pointer",
										borderRadius: 10,
										width: "100%",
										height: bHeight,
										background: "#E9EFFF",
										border: "1px dashed rgba(0, 0, 0, 0.33)",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
									onClick={onOpenUploadModal}

									// {...getRootProps({ className: "dropzone" })}
								>
									<div className='text-center'>
										{/* <input {...getInputProps()} /> */}
										<img
											src='/assets/images/add-image.png'
											alt='addImage'
											className='m-auto mb-[10px]'
											style={{
												filter:
													"drop-shadow(0px 15px 15px rgba(0, 0, 0, 0.1))",
											}}
										/>
										<p
											className='text-12 font-bold pb-[5px]'
											style={{ color: "#4285F4" }}>
											{ekkLanguage.accounting.uploadImageInfo}
										</p>
										<p className='text-12 font-normal text-black-500'>
											{
												ekkLanguage.accounting
													.uploadImageInfoPlaceholder
											}
										</p>
									</div>
								</div>
							)}
						</>
					)}
				</>
			) : (
				<>
					{revenue?.images?.length && !isCameraMood ? (
						<div
							style={{
								padding: 5,
								borderRadius: 5,
								width: "100%",
								minHeight: bHeight,
								border: "1px solid rgba(0, 0, 0, 0.33)",
							}}
							// className='flex justify-center items-center relative overflow-hidden '
						>
							<div
								style={{
									minHeight: bHeight,
								}}
								className='text-center'>
								{revenue?.images?.length ? (
									<div>
										<div className='flex flex-wrap'>
											{revenue?.images?.map((el, i) => {
												return (
													<div className='relative mr-1'>
														<img
															key={i}
															className='mb-3 border rounded-[4px] max-h-[100px]'
															src={el?.preview}
															alt='images'
														/>
														<span
															className='absolute top-1 right-2 flex bg-primary p-1 rounded-full deselect-image'
															onClick={() => imageRemover(el)}>
															<ion-icon
																size='small'
																name='trash-outline'></ion-icon>
														</span>
													</div>
												);
											})}
											<div className='border flex justify-center items-center rounded-[5px] h-[100px] w-[90px] mr-1'>
												<div
													className='relative'
													onClick={onOpenUploadModal}
													// {...getRootProps({
													// 	className: "dropzone",
													// })}
												>
													{/* <input {...getInputProps()} /> */}
													<img
														src='/assets/images/add-image.png'
														alt='addImage'
														className='m-auto mb-[10px]'
														style={{
															filter:
																"drop-shadow(0px 15px 15px rgba(0, 0, 0, 0.1))",
														}}
													/>
													<p
														className='text-12 font-bold pb-[5px]'
														style={{ color: "#4285F4" }}>
														{ekkLanguage.accounting.addImage}
													</p>
												</div>
											</div>
										</div>
									</div>
								) : undefined}
							</div>
						</div>
					) : (
						<>
							{isCameraMood && (
								<div
									className='flex justify-center items-center rounded-[10px]'
									style={{
										cursor: "pointer",
										borderRadius: 10,
										width: "100%",
										height: bHeight,
										background: "#E9EFFF",
										border: "1px dashed rgba(0, 0, 0, 0.33)",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										position: "relative",
									}}>
									<div className='text-left rounded-[10px] overflow-hidden'>
										<div
											className='rounded-[10px] flex items-center justify-center'
											style={{
												boxShadow:
													"0px 5px 15px rgba(0, 0, 0, 0.07)",
												background: "#E6E6E6",
												height: !isCameraOpen && "320px",
												width: "100%",
											}}>
											{isCameraOpen ? (
												<div className='relative photo-camera w-full'>
													<Webcam
														className='webcam'
														audio={false}
														ref={webcamRef}
														screenshotFormat='image/jpeg'
														videoConstraints={videoConstraints}
														screenshotQuality={1}
														onUserMediaError={handleError}
													/>
													<div className='bg-black-500 absolute bottom-0 w-full flex items-center justify-between'>
														<span
															onClick={handleClick}
															className=' rounded-[7px] text-center flex items-center justify-center'
															style={{
																boxShadow:
																	"0px 5px 15px rgba(0, 0, 0, 0.1)",
															}}>
															<img
																src='/assets/images/camera-switch.png'
																alt='Camera'
																className='z-1'
															/>
														</span>
														<span
															onClick={handleTakePhoto}
															className='cursor-pointer rounded-[7px] text-center flex items-center justify-center'
															style={{
																boxShadow:
																	"0px 5px 15px rgba(0, 0, 0, 0.1)",
																// left: "50%",
																// transform: "translateX(-50%)",
															}}>
															<img
																src='/assets/images/camera-icon.png'
																alt='Camera'
																className='z-1'
															/>
														</span>
														<span
															onClick={closeCameraHandler}
															className=' rounded-[7px] text-center flex items-center justify-center mr-3'
															style={{
																boxShadow:
																	"0px 5px 15px rgba(0, 0, 0, 0.1)",
															}}>
															<CrossBig></CrossBig>
														</span>
													</div>
												</div>
											) : (
												<>
													{revenue?.images?.length ? (
														<>
															<img
																src={revenue?.images[0]}
																alt='avatar'
															/>
															<div onClick={onOpenUploadModal}>
																<span
																	className='absolute h-7 w-7 rounded-full text-center flex items-center justify-center right-2 top-2 border-4 border-white'
																	style={{
																		background: "#5A7EFF",
																		lineHeight: "28px",
																	}}>
																	<Pen />
																</span>
															</div>
														</>
													) : (
														<div
															onClick={cameraOpenHandler}
															className='text-center rounded-[5px] inline-block bg-white px-9 py-5 cursor-pointer'
															style={{
																boxShadow:
																	"0px 5px 15px rgba(0, 0, 0, 0.07)",
															}}>
															<img
																className='m-auto'
																src='/assets/images/camera.jpg'
																alt='camera'
															/>
															<p className='text-14 pt-1 text-black-1000'>
																{
																	ekkLanguage.accounting
																		.takePhoto
																}
															</p>
														</div>
													)}
												</>
											)}{" "}
										</div>
									</div>
								</div>
							)}

							{!isCameraMood && (
								<div
									className='flex justify-center items-center rounded-[10px]'
									style={{
										cursor: "pointer",
										borderRadius: 10,
										width: "100%",
										height: bHeight,
										background: "#E9EFFF",
										border: "1px dashed rgba(0, 0, 0, 0.33)",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
									onClick={onOpenUploadModal}

									// {...getRootProps({ className: "dropzone" })}
								>
									<div className='text-center'>
										{/* <input {...getInputProps()} /> */}
										<img
											src='/assets/images/add-image.png'
											alt='addImage'
											className='m-auto mb-[10px]'
											style={{
												filter:
													"drop-shadow(0px 15px 15px rgba(0, 0, 0, 0.1))",
											}}
										/>
										<p
											className='text-12 font-bold pb-[5px]'
											style={{ color: "#4285F4" }}>
											Upload your image here, or browse
										</p>
										<p className='text-12 font-normal text-black-500'>
											Maximum file size support 10 MB, JPG, JPEG or
											PNG
										</p>
									</div>
								</div>
							)}
						</>
					)}
				</>
			)}
			<Modal
				style={customModalStyles}
				closeTimeoutMS={200}
				isOpen={isOpenUpload}
				ariaHideApp={false}
				onRequestClose={onCloseUploadModal}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<div className='border-b-2 mb-4 flex  justify-between'>
						<h4 className='text-14 text-222222 font-bold pb-[10px]'>
							Upload Photo
						</h4>
						<span onClick={onCloseUploadModal} className='cursor-pointer'>
							<Cross />
						</span>
					</div>
					<div>
						<IonRow>
							<IonCol size='6' style={{ padding: 0, paddingRight: 5 }}>
								<div {...getRootProps({ className: "dropzone" })}>
									<input {...getInputProps()} />
									<div
										className='text-center rounded-[5px] inline-block bg-white py-5 cursor-pointer w-full'
										style={{
											boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.07)",
										}}
										// onClick={onOpenUploadModal}
									>
										<img
											className='m-auto pb-[10px]'
											src='/assets/images/gallery.png'
											alt='camera'
										/>
										<p className='text-10 pt-1 text-delivered'>
											Upload From Gallery
										</p>
									</div>
								</div>
							</IonCol>
							<IonCol size='6' style={{ padding: 0, paddingLeft: 5 }}>
								<div
									className='text-center rounded-[5px] inline-block bg-white py-5 cursor-pointer w-full'
									style={{
										boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.07)",
									}}
									onClick={cameraOpenHandler}>
									<img
										className='m-auto pb-[10px]'
										src='/assets/images/camera.png'
										alt='camera'
									/>
									<p className='text-10 pt-1 text-delivered'>
										Take Photo
									</p>
								</div>
							</IonCol>
						</IonRow>
					</div>
				</div>
			</Modal>
		</>
	);
}
