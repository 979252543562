import { IonSpinner } from "@ionic/react";
import RightArrowThin from "assets/images/icon/svg/RightArrowThin";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Bar, getElementAtEvent } from "react-chartjs-2";
import moment from "moment";
import { useSelector } from "react-redux";
import Accounts from "services/Accounts";
import { getSelectedDate } from "redux/container/dashboardSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function EmployeeChart() {
	const [employeeRevenueData, setEmployeeRevenueData] = useState([]);
	const dateFormat = useSelector((state) => state.date.format);
	const [loader, setLoader] = useState(false);
	const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	const screenSize = window.matchMedia("(max-width: 400px)")?.matches;
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const employeeRevenueChartData = {
		labels: employeeRevenueData?.map((item) => item.user_name),
		datasets: [
			{
				label: "Amount",
				data: employeeRevenueData?.map((item) => item?.total_amount),
				backgroundColor: "#00B224",
			},
		],
	};
	moment.updateLocale("en", {
		week: {
			dow: 1,
		},
	});
	const startDay = moment().startOf("month").format("YYYY-MM-DD");
	const endDay = moment().endOf("month").format("YYYY-MM-DD");

	const getEmployeeRevenueData = async () => {
		setLoader(true);
		let response = await Accounts.getEmployeeRevenueData(
			timeZone,
			startDay,
			endDay
		);
		if (response.success) {
			setLoader(false);
			setEmployeeRevenueData(response?.results);
		} else {
			setLoader(false);
		}
	};

	useEffect(() => {
		getEmployeeRevenueData();
	}, []);

	const employeeMonthlyBarRef = useRef();
	const barClickHandler = (event, type) => {
		const chartConfigs = {
			employeeMonthly: {
				ref: employeeMonthlyBarRef,
				data: employeeRevenueData,
				path: "employee-monthly-parts",
			},
		};
		const config = chartConfigs[type];
		if (!config) return;

		const clickedElement = getElementAtEvent(config.ref.current, event)[0];
		if (!clickedElement) return;

		const elementIndex = clickedElement.index;
		const value = config.data[elementIndex];
		if (!value) return;

		const startDay = moment().startOf("month").format("YYYY-MM-DD");
		const endDay = moment().endOf("month").format("YYYY-MM-DD");

		const dateTime = {
			start: startDay,
			end: endDay,
			level: "thisMonth",
			userId: value?.user_id,
		};
		console.log(value?.user_id);

		dispatch(getSelectedDate(dateTime));
		navigate(
			`/${config.path}?state=${startDay}&end=${endDay}&userName=${value?.user_name}&userId=${value?.user_id}`
		);
	};
	return (
		<div className='relative py-2'>
			<div className='chart-wrapper relative mb-4'>
				{loader ? (
					<div className='absolute h-full w-full flex justify-center items-center left-[7%] top-[5%]'>
						<IonSpinner name='circular'></IonSpinner>
					</div>
				) : undefined}

				<div className='px-2 pb-4 flex justify-between items-center'>
					<h4 className='font-bold text-18 sm:text-20 text-black-1000'>
					Revenue Leaderboard
					</h4>
					<h3 className='font-bold text-black-500 text-14'>
						Monthly
					</h3>
				</div>

				<div
					className='flex justify-center'
					style={{
						width: "100%",
						maxWidth: "768px",
						height: screenSize && "250px",
					}}>
					<Bar
						ref={employeeMonthlyBarRef}
						data={employeeRevenueChartData}
						onClick={(event) => barClickHandler(event, "employeeMonthly")}
						options={{
							// indexAxis: "y",
							scales: {
								x: {
									beginAtZero: true,
								},
								y: {
									// ticks: {
									// 	callback: function (value) {
									// 		// Set max width for label text
									// 		const maxWidth = 100;
									// 		const ctx = this.chart.ctx;
									// 		ctx.font = this.chart.config.options.font;
									// 		const text = this.getLabelForValue(value);
									// 		if (!text) return "";
									// 		// Split text into multiple lines if it's too long
									// 		const words = text.split(" ");
									// 		let lines = [];
									// 		let currentLine = words[0];
									// 		for (let i = 1; i < words.length; i++) {
									// 			const word = words[i];
									// 			const width = ctx.measureText(
									// 				currentLine + " " + word
									// 			).width;
									// 			if (width < maxWidth) {
									// 				currentLine += " " + word;
									// 			} else {
									// 				lines.push(currentLine);
									// 				currentLine = word;
									// 			}
									// 		}
									// 		lines.push(currentLine);
									// 		return lines;
									// 	},
									// 	padding: 10,
									// },
								},
							},
							barPercentage: 0.7,
							plugins: {
								legend: false,
							},
							maintainAspectRatio: false,
						}}
					/>
				</div>
			</div>
		</div>
	);
}
