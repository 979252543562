import React from "react";
import Card from "../Common/Card";
import Moment from "react-moment";
import "moment-timezone";
import { useSelector } from "react-redux";
import { digitChanger } from "helpers/helper";
import moment from "moment-timezone";
export default function DeliveryDetailsCard({ order }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<div className='px-2 pb-5'>
			<Card title={ekkLanguage.orderDetails.deliveryDetailsLabel}>
				<div>
					<table className='table-fixed w-full'>
						<tbody className="before:content-[' '] before:block before:h-2">
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{ekkLanguage.orderDetails.BuyerName}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{order?.buyer?.business_name}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{ekkLanguage.orderDetails.address}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{order?.shipping_address}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{ekkLanguage.orderDetails.phoneNumber}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{digitChanger(order?.buyer?.phone_number)}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{ekkLanguage.orderDetails.email}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{order?.buyer?.email}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{ekkLanguage.orderDetails.requestDeliveryDate}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{digitChanger(
										moment(order?.delivery_date).format("DD-MM-YYYY")
									)}
								</td>
							</tr>

							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{ekkLanguage.orderDetails.scheduleDeliveryDate}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{order?.scheduleDeliveryDate
										? digitChanger(order?.scheduleDeliveryDate)
										: "Not set yet"}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{ekkLanguage.orderDetails.scheduleDeliveryTime}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{order?.scheduleDeliveryTime
										? digitChanger(order?.scheduleDeliveryTime)
										: "Not set yet"}
								</td>
							</tr>
							{order?.delivery_info && (
								<tr>
									<td className='text-000080 text-12 font-medium py-[6px]'>
										{ekkLanguage.orderDetails.deliveryFailMessage}
									</td>
									<td className='text-000080 text-12 font-medium py-[6px] text-right'>
										{order?.delivery_info && order?.delivery_info.delivery_msg}
									</td>
								</tr>
							)}
							<tr>
								{order?.buyerDeliveredSignature && (
									<td className='text-000080 text-12 font-medium py-[6px]'>
										<img
											src={order?.buyerDeliveredSignature}
											alt='signature'
											className='rounded-[5px] bg-white block h-[100px] m-auto'
											style={{
												border: "0.5px solid rgba(0, 0, 0, 0.3)",
											}}
										/>
									</td>
								)}

								{order?.buyerDeliveredProof && (
									<td className='text-000080 text-12 font-medium py-[6px] text-right'>
										<img
											src={order?.buyerDeliveredProof}
											alt='billImage'
											className='rounded-[5px] block h-[100px] m-auto'
											style={{
												border: "0.5px solid rgba(0, 0, 0, 0.3)",
												background: "rgb(230, 230, 230)",
											}}
										/>
									</td>
								)}
							</tr>
						</tbody>
					</table>
				</div>
			</Card>
		</div>
	);
}
