import { IonToolbar } from "@ionic/react";
import React from "react";
import { hasActive } from "helpers/helper";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CheckBusiness from "components/CheckBusiness";
import { getSelectedDate, setIsFilter } from "redux/container/dashboardSlice";
import moment from "moment";

export default function NavFooter() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	return (
		<>
			<CheckBusiness />
			<IonToolbar>
				<div
					className='bg-white pb-2 bottom-navigation mt-1 pt-1'
					style={{
						boxShadow: "0px -2px 5px rgba(0, 0, 0, 0.15)",
					}}>
					<ul className='flex items-baseline justify-around'>
						<li
							className={`${hasActive(
								"/home",
								pathname
							)} cursor-pointer`}
							onClick={() => navigate("/home")}>
							{hasActive("/home", pathname) === "active" ? (
								<img
									className='m-auto h-7 w-7'
									src='/assets/images/menu/home-c.png'
									alt='icon'
								/>
							) : (
								<img
									className='m-auto h-7 w-7'
									src='/assets/images/menu/home.png'
									alt='icon'
								/>
							)}

							<span className='font-semibold text-A6A6A6 text-10'>
								{ekkLanguage.navFooter.home}
							</span>
						</li>
						{/* <li
							className={`${hasActive(
								"/order-list",
								pathname
							)} cursor-pointer`}
							onClick={() => navigate("/order-list")}>
							{hasActive("/order-list", pathname) === "active" ? (
								<OrderOutline />
							) : (
								<Order />
							)}

							<span className='font-semibold text-A6A6A6 text-10'>
							{ekkLanguage.navFooter.order}
							</span>
						</li> */}
						{/* <li className='text-center'>
								<Ekkbaz />
								<span className='font-semibold text-A6A6A6 text-10'>
									Ekkbaz
								</span>
							</li> */}
						{/* <li>
								<Favorite />
								<span className='font-semibold text-A6A6A6 text-10'>
									Favorite
								</span>
							</li> */}

						{/* <li
							className={`${hasActive(
								"/mysupplier",
								pathname
							)} cursor-pointer`}
							onClick={() => navigate("/mysupplier")}>
							{hasActive("/mysupplier", pathname) === "active" ? (
								<Supplier />
							) : (
								<SupplierOutline color='#A6A6A6' />
							)}

							<span className='font-semibold text-A6A6A6 text-10'>
							{ekkLanguage.navFooter.supplier}
							</span>
						</li> */}
						{/* <li
							className={`${hasActive(
								"/accounts",
								pathname
							)} cursor-pointer`}
							onClick={() => {
								dispatch(setIsFilter(true));
								dispatch(
									getSelectedDate({
										start: moment()
											.startOf("month")
											.format("YYYY-MM-DD"),
										end: moment().endOf("month").format("YYYY-MM-DD"),
										level: "This Month",
									})
								);
								navigate("/accounts");
							}}>
							{hasActive("/accounts", pathname) === "active" ? (
								<img
									className='m-auto h-7 w-7'
									src='/assets/images/menu/accounting-c.png'
									alt='icon'
								/>
							) : (
								<img
									className='m-auto h-7 w-7'
									src='/assets/images/menu/accounting.png'
									alt='icon'
								/>
							)}

							<span className='font-semibold text-A6A6A6 text-10'>
								{ekkLanguage.menu.accounting}
							</span>
						</li> */}
						<li
							className={`${hasActive(
								"/baz-ai",
								pathname
							)} cursor-pointer`}
							onClick={() => navigate("/baz-ai")}>
							{hasActive("/baz-ai", pathname) === "active" ? (
								<img
									className='m-auto h-7 w-7'
									src='/assets/images/menu/baz-ai-c.png'
									alt='icon'
								/>
							) : (
								<img
									className='m-auto h-7 w-7'
									src='/assets/images/menu/baz-ai.png'
									alt='icon'
								/>
							)}

							<span className='font-semibold text-A6A6A6 text-10'>
								Baz AI
							</span>
						</li>
						<li
							className={`${hasActive(
								"/team-chat",
								pathname
							)} cursor-pointer`}
							onClick={() => navigate("/team-chat")}>
							{hasActive("/team-chat", pathname) === "active" ? (
								<img
									className='m-auto h-7 w-7'
									src='/assets/images/menu/team-c.png'
									alt='icon'
								/>
							) : (
								<img
									className='m-auto h-7 w-7'
									src='/assets/images/menu/team.png'
									alt='icon'
								/>
							)}

							<span className='font-semibold text-A6A6A6 text-10'>
								Team 
							</span>
						</li>

						<li
							className={`${hasActive(
								"/profile",
								pathname
							)} cursor-pointer`}
							onClick={() => navigate("/profile")}>
							{hasActive("/profile", pathname) === "active" ? (
								<img
									className='m-auto h-7 w-7'
									src='/assets/images/menu/profile-c.png'
									alt='icon'
								/>
							) : (
								<img
									className='m-auto h-7 w-7'
									src='/assets/images/menu/profile.png'
									alt='icon'
								/>
							)}

							<span className='font-semibold text-A6A6A6 text-10'>
								{ekkLanguage.navFooter.profile}
							</span>
						</li>
					</ul>
				</div>
			</IonToolbar>
		</>
	);
}
