import React, { forwardRef, useEffect, useState } from "react";
import TextInputWithIcon from "../Common/TextInputWithIcon";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import RightArrow from "assets/images/icon/svg/RightArrow";
import MultiImageUploader from "./MultiImageUploader";
import { IonButton, IonToggle } from "@ionic/react";
import {
	clearExpense,
	setExpense,
	setSelectedTab,
} from "redux/container/accountSlice";
import User from "services/User";
import Modal from "react-modal";
import { ClipLoader } from "react-spinners";
import CancelPopUp from "assets/images/icon/svg/CancelPopUp";
import { getUserBusiness } from "config/user";
import Accounts from "services/Accounts";
import { toast } from "react-toastify";
import { resetAccountData } from "redux/container/dashboardSlice";

export default function ExpenseForm() {
	let expense = useSelector((state) => state?.accounts?.expense);
	let category = useSelector((state) => state?.accounts?.category);
	let supplier = useSelector((state) => state?.accounts?.supplier);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [amount, setAmount] = useState(expense?.amount);
	const [description, setDescription] = useState(expense?.description);
	const [selectedDalieveryDate, setSelectedDalieveryDate] = useState(
		expense?.expenseDate ? expense?.expenseDate : moment(new Date())
	);
	const [selectedPaidDate, setSelectedPaidDate] = useState(
		expense?.paidDate ? expense?.paidDate : moment(new Date())
	);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [imageFiles, setImageFiles] = useState([]);
	const [confirmModal, setConfirmModal] = useState(false);
	const closeModal = () => setConfirmModal(false);
	const openModal = () => setConfirmModal(true);
	const [loader, setLoader] = useState(false);
	const [accId, setAccId] = useState(null);
	const location = useLocation();
	const [isPaid, setIsPaid] = useState(expense?.paidChecked ?? true);
	const [isPaidText, setIsPaidText] = useState(
		expense.paidChecked
			? ekkLanguage.accounting.paid
			: ekkLanguage.accounting.notPaid
	);
	const dateFormat = useSelector((state) => state.date.format);

	let dateChanger = (e) => {
		setSelectedDalieveryDate(e);
	};
	let dateChangerPaid = (e) => {
		setSelectedPaidDate(e);
	};
	let getAccountType = async () => {
		let response = await Accounts.getAccountType(
			location?.pathname === "/expense-form" ? "Expense" : "Revenue"
		);
		if (response?.success) {
			setAccId(response?.results?.id);
		}
	};

	useEffect(() => {
		getAccountType();
	}, []);
	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "300px",
			transition: ".4s all ease",
			borderRadius: 12,
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};
	useEffect(() => {
		dispatch(
			setExpense({
				...expense,
				amount,
				description,
				expenseDate: selectedDalieveryDate,
				paidDate: selectedPaidDate,
				paidChecked: isPaid,
			})
		);
	}, [amount, description, selectedDalieveryDate, selectedPaidDate, isPaid]);

	const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
		<div
			ref={ref}
			className={`p-3 block mt-4 example-custom-input pl-4 h-[50px] rounded-[5px] bg-F8F8F8  w-full border border-black-500`}
			onClick={onClick}>
			<div className='flex justify-between items-center'>
				<div className='flex items-center '>
					{moment(selectedDalieveryDate).format(dateFormat)}
				</div>
			</div>
		</div>
	));
	const ExampleCustomInput2 = forwardRef(({ value, onClick }, ref) => (
		<div
			ref={ref}
			className={`p-3 block example-custom-input pl-4 h-[50px] rounded-[5px] bg-F8F8F8  w-full border border-black-500`}
			onClick={onClick}>
			<div className='flex justify-between items-center'>
				<div className='flex items-center '>
					{moment(selectedPaidDate).format(dateFormat)}
				</div>
			</div>
		</div>
	));

	function DataURIToBlob(dataURI) {
		const splitDataURI = dataURI.split(",");
		const byteString =
			splitDataURI[0].indexOf("base64") >= 0
				? atob(splitDataURI[1])
				: decodeURI(splitDataURI[1]);
		const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

		const ia = new Uint8Array(byteString.length);
		for (let i = 0; i < byteString.length; i++)
			ia[i] = byteString.charCodeAt(i);

		return new Blob([ia], { type: mimeString });
	}

	let submitHandler = async () => {
		dispatch(resetAccountData());
		setLoader(true);
		if (!expense?.amount) {
			toast.warning("Please enter a valid amount");
			return;
		}
		if (expense?.images?.length) {
			const imageUrls = [];

			for (let index = 0; index < expense?.images?.length; index++) {
				if (expense?.images[index]?.cameraImage) {
					const cameraFile = DataURIToBlob(
						expense?.images[index]?.preview
					);
					const cameraData = new FormData();
					cameraData.append("key", cameraFile, "image.jpg");
					let cameraResponse = await User.toBucketImage(cameraData);
					imageUrls.push(cameraResponse?.results[0]);
				} else {
					const imageData = new FormData();
					imageData.append(
						"key",
						expense?.images[index],
						`image-${index}.png`
					);
					try {
						const uploadResponse = await User.toBucketImage(imageData);
						imageUrls.push(uploadResponse?.results[0]);
					} catch (error) {
						console.error(`Error uploading image-${index}:`, error);
					}
				}
			}

			if (imageUrls?.length) {
				let payload = {
					acc_group: accId,
					acc_category: category?.id,
					amount: expense?.amount,
					seller_id: supplier?.seller_id,
					seller_name: supplier?.seller_name,
					description: expense?.description,
					date: moment(expense?.expenseDate).format("YYYY-MM-DD"),
					images: imageUrls,
					...(!isPaid
						? {
								is_paid: false,
								paid_date: moment(expense?.paidDate).format(
									"YYYY-MM-DD"
								),
						  }
						: {}),
				};

				let response = await Accounts.createTransaction(payload);
				if (response.success) {
					setLoader(false);
					dispatch(setSelectedTab("Expense"));
					closeModal();
					dispatch(clearExpense());
					toast.success(ekkLanguage.accounting.successFullyCreated);
					navigate("/accounts");
				} else {
					setLoader(false);
				}
			} else {
				let payload = {
					acc_group: accId,
					acc_category: category?.id,
					amount: expense?.amount,
					seller_id: supplier?.seller_id,
					seller_name: supplier?.seller_name,
					description: expense?.description,
					date: moment(expense?.expenseDate).format("YYYY-MM-DD"),
					...(!isPaid
						? {
								is_paid: false,
								paid_date: moment(expense?.paidDate).format(
									"YYYY-MM-DD"
								),
						  }
						: {}),
				};
				let response = await Accounts.createTransaction(payload);
				if (response.success) {
					setLoader(false);
					dispatch(setSelectedTab("Expense"));
					closeModal();
					dispatch(clearExpense());
					toast.success(ekkLanguage.accounting.successFullyCreated);
					navigate("/accounts");
				} else {
					setLoader(false);
				}
			}
		} else {
			let payload = {
				acc_group: accId,
				acc_category: category?.id,
				amount: expense?.amount,
				seller_id: supplier?.seller_id,
				seller_name: supplier?.seller_name,
				description: expense?.description,
				date: moment(expense?.expenseDate).format("YYYY-MM-DD"),
				...(!isPaid
					? {
							is_paid: false,
							paid_date: moment(expense?.paidDate).format("YYYY-MM-DD"),
					  }
					: {}),
			};
			let response = await Accounts.createTransaction(payload);
			if (response.success) {
				setLoader(false);
				dispatch(setSelectedTab("Expense"));
				closeModal();
				dispatch(clearExpense());
				toast.success(ekkLanguage.accounting.successExpense);
				navigate("/accounts");
			} else {
				setLoader(false);
			}
		}
	};

	let [confirmModalWarning, setConfirmModalWaning] = useState(false);
	let openWarning = () => setConfirmModalWaning(true);
	let closeWarning = () => setConfirmModalWaning(false);
	let [warningMessage, setWarningMessage] = useState("");

	const handleToggleChange = (e) => {
		const newValue = e.detail.checked;
		setIsPaid(newValue);
		setIsPaidText(
			newValue ? ekkLanguage.accounting.paid : ekkLanguage.accounting.notPaid
		);
	};
	return (
		<div className='pt-5'>
			{" "}
			<div>
				<div className='pb-5'>
					<div className='flex pb-4'>
						<h4 className='font-bold text-14'>
							{ekkLanguage.accounting.images}
						</h4>
					</div>
					<MultiImageUploader
						type='expense'
						files={imageFiles}
						setFiles={setImageFiles}
						bHeight={320}
					/>
				</div>
				<div className='pb-5'>
					<div className='flex'>
						<h4 className='font-bold text-14'>
							{ekkLanguage.accounting.date} *
						</h4>
					</div>
					<DatePicker
						// minDate={moment().toDate()}
						selected={moment(selectedDalieveryDate).toDate()}
						onChange={(date) => dateChanger(date)}
						customInput={<ExampleCustomInput />}
						withPortal
					/>
				</div>
				<TextInputWithIcon
					type='number'
					title={ekkLanguage.accounting.amount}
					inputText={amount}
					setInputText={setAmount}
					placeholder={ekkLanguage.accounting.amountPlaceholder}
					// icon='assets/images/cash.png'
					required={true}
				/>
				<div className='pb-5'>
					<div className='flex pb-4'>
						<h4 className='font-bold text-14'>
							{ekkLanguage.accounting.category} *
						</h4>
					</div>
					<div
						className={`bg-white block rounded-[5px] px-4 py-[4px] border border-black-500`}
						onClick={() => navigate(`/categories/expense/${accId}`)}>
						<div className='flex justify-between items-center'>
							<div className='flex items-center'>
								{category?.id ? (
									<div className='flex items-center'>
										<div className='h-10 w-10 flex justify-center items-center bg-black-333 rounded-full mr-2'>
											<h3 className='font-bold text-24 text-white'>
												{category?.title[0].toUpperCase()}
											</h3>
										</div>
										<h3 className='font-bold'>{category?.title}</h3>
									</div>
								) : (
									<p
										className={`text-16 py-[9px] font-medium ml-2 text-black-500`}>
										{ekkLanguage.accounting.categoryPlaceholder}
									</p>
								)}
							</div>
							<div>
								<RightArrow />
							</div>
						</div>
					</div>
				</div>
				<TextInputWithIcon
					type='text'
					title={ekkLanguage.accounting.description}
					inputText={description}
					setInputText={setDescription}
					placeholder={ekkLanguage.accounting.descriptionPlaceholder}
				/>

				<div className='pb-5'>
					<div className='flex justify-between items-center'>
						<div className='flex'>
							<h4 className='font-bold text-14'>
								{ekkLanguage.accounting.alreadyPaid}
							</h4>
						</div>
						<div>
							<IonToggle
								labelPlacement='start'
								height='100'
								aria-label='Primary toggle'
								size='large'
								color='success'
								checked={isPaid}
								onIonChange={handleToggleChange}>
								<span className='font-bold text-14'>{isPaidText}</span>
							</IonToggle>
						</div>
					</div>

					{!isPaid && (
						<div>
							<h4 className='font-bold text-14 mb-1'>
								{ekkLanguage.accounting.onto}
							</h4>
							<DatePicker
								selected={moment(selectedPaidDate).toDate()}
								onChange={(date) => dateChangerPaid(date)}
								customInput={<ExampleCustomInput2 />}
								withPortal
							/>
						</div>
					)}
				</div>
				<div className='pb-5'>
					<div className='flex pb-4'>
						<h4 className='font-bold text-14'>
							{ekkLanguage.accounting.supplier}
						</h4>
					</div>
					<div
						className={`bg-white block rounded-[5px] px-4 py-[4px] border border-black-500`}
						onClick={() => navigate(`/supplier-list/expense`)}>
						<div className='flex justify-between items-center'>
							<div className='flex items-center'>
								{supplier?.seller_id ? (
									<div className='flex items-center'>
										<div className='h-10 w-10 flex justify-center items-center bg-black-333 rounded-full mr-2'>
											{supplier?.seller_image?.length ? (
												<div className='h-10 w-10 rounded-full overflow-hidden'>
													<img
														src={supplier?.seller_image[0]}
														alt='logo'></img>
												</div>
											) : (
												<div className='h-10 w-10 flex justify-center items-center bg-black-333 rounded-full'>
													<h3 className='font-bold text-24 text-white'>
														{supplier?.seller_name[0].toUpperCase()}
													</h3>
												</div>
											)}
										</div>
										<h3 className='font-bold'>
											{supplier?.seller_name}
										</h3>
									</div>
								) : (
									<p
										className={`text-16 py-[9px] font-medium ml-2 text-black-500`}>
										{ekkLanguage.accounting.supplierPlaceholder}
									</p>
								)}
							</div>
							<div>
								<RightArrow />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='mb-8 mt-4'>
				<IonButton
					// disabled={
					// 	productName && sellingPrice && initInventory && true
					// }
					// disabled={publishDisabled}
					onClick={() => {
						if (!expense?.amount && !category?.id) {
							openWarning();
							setWarningMessage(
								"Please add a valid amount and select a category"
							);
							return false;
						} else if (!expense?.amount) {
							openWarning();
							setWarningMessage("Please add a valid amount.");
							return false;
						} else if (!category?.id) {
							openWarning();
							setWarningMessage("Please select a category.");
							return false;
						} else if (!category?.id) {
							openWarning();
							setWarningMessage("Please select a category");
							return false;
						} else if (!expense?.amount) {
							openWarning();
							setWarningMessage("Please enter a valid amount.");
							return false;
						} else if (!category?.id) {
							openWarning();
							setWarningMessage("Please Select a category.");
							return false;
						} else {
							openModal();
						}
					}}
					style={{ boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.15)" }}
					expand='block'
					className='bg-success rounded-[5px] h-12 font-sans font-bold text-20'>
					{ekkLanguage.accounting.submitExpense}
				</IonButton>
			</div>
			<Modal
				style={customStyles}
				closeTimeoutMS={200}
				isOpen={confirmModal}
				onRequestClose={closeModal}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2'>
						<CancelPopUp />
					</span>
					<h6 className='text-16 font-bold pb-[30px]'>
						{ekkLanguage.accounting.expenseCreateWarning}
					</h6>

					<div className='flex justify-end'>
						<button
							className='border-2 font-bold h-10 rounded-[7px] w-1/2 bg-primary text-white mr-2'
							onClick={closeModal}>
							{ekkLanguage.orderDetails.noBtn}
							{/* No */}
						</button>
						<button
							color='primary'
							className={`${
								loader && "select-none cursor-no-drop"
							} font-bold h-10 rounded-[7px] w-1/2   ml-2 border-2 bg-success text-white flex justify-center items-center`}
							onClick={!loader ? submitHandler : undefined}>
							{loader ? (
								<ClipLoader
									sizeUnit={"px"}
									size={20}
									color={"white"}
									loading={true}
								/>
							) : (
								ekkLanguage.orderDetails.yesBtn
								// "Yes"
							)}
						</button>
					</div>
				</div>
			</Modal>
			<Modal
				style={customStyles}
				closeTimeoutMS={200}
				isOpen={confirmModalWarning}
				onRequestClose={closeWarning}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2'>
						<CancelPopUp />
					</span>
					<h6 className='text-16 font-bold pb-[30px]'>{warningMessage}</h6>

					<div className='flex justify-end'>
						<button
							color='primary'
							className={`${
								loader && "select-none cursor-no-drop"
							} font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-success text-white flex justify-center items-center`}
							onClick={closeWarning}>
							Ok
						</button>
					</div>
				</div>
			</Modal>
		</div>
	);
}
