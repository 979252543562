import { IonSearchbar } from "@ionic/react";
import LeftArrow from "assets/images/icon/svg/LeftArrow";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Cross from "assets/images/icon/svg/Cross";
import { useSelector } from "react-redux";
import Search from "assets/images/icon/svg/Search";
import MenuLeftArrow from "assets/images/icon/svg/MenuLeftArrow";

export default function NavBar({ searchProduct, defalutTarm }) {
	let navigate = useNavigate();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let [tempTerm, setTempTerm] = useState(defalutTarm);

	let [tarm, setTarm] = useState(defalutTarm);
	const deleteQueryHandler = async (e) => {
		setTarm("");
		setTempTerm("");
	};
	return (
		<>
			<div className={`search-header-wrapper flex items-center px-4 py-4`}>
				<div
					className='short-nav left-4 top-7  rounded-xl z-10 mr-3'
					style={{
						height: 40,
						width: 40,
					}}
					onClick={() => navigate(-1)}>
					<div className=' h-full flex items-center justify-center'>
						<MenuLeftArrow color='#262626' />
					</div>
				</div>
				<div className='w-full'>
					<div className='relative'>
						<span className='absolute left-[10px] top-[24%] h-full z-10 cursor-pointer'>
							<Search color='rgba(0,0,0,.6)' />
						</span>
						<input
							className='bg-white w-full h-11 rounded-[7px] pl-12 search-input border'
							placeholder={ekkLanguage.home.searchLabel}
							style={{ boxShadow: "1px 2px 8px rgba(0, 0, 0, 0.2) " }}
							value={tarm}
							onKeyPress={(e) => {
								if (e.key === "Enter") {
									setTempTerm(e.target.value);
									tarm && searchProduct(tarm);
								}
							}}
							onChange={(e) => {
								setTarm(e.target.value);
							}}></input>
						{tarm && (
							<span
								className='absolute  right-[10px] top-[34%] h-full z-10 cursor-pointer'
								onClick={deleteQueryHandler}>
								<Cross />
							</span>
						)}
					</div>
				</div>
			</div>
			<div className='ml-14'>
				<p className='px-4 pb-4'>
					{tempTerm && (
						<span>
							{" "}
							search: <span className='font-bold'>{tempTerm}</span>
						</span>
					)}
				</p>
			</div>
		</>
	);
}
