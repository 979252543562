import React from "react";

export default function Scan() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='26'
			height='26'
			viewBox='0 0 26 26'
			fill='none'>
			<g id='Iconly/Light-Outline/Scan'>
				<g id='Scan'>
					<path
						id='Fill 1'
						fillRule='evenodd'
						clipRule='evenodd'
						d='M24.6455 15.1178H1.89551C1.44701 15.1178 1.08301 14.7538 1.08301 14.3053C1.08301 13.8568 1.44701 13.4928 1.89551 13.4928H24.6455C25.094 13.4928 25.458 13.8568 25.458 14.3053C25.458 14.7538 25.094 15.1178 24.6455 15.1178Z'
						fill='#ffffff'
					/>
					<path
						id='Fill 3'
						fillRule='evenodd'
						clipRule='evenodd'
						d='M22.62 10.5571C22.1715 10.5571 21.8075 10.1931 21.8075 9.74458V8.10442C21.8075 6.3245 20.358 4.875 18.5759 4.875H17.2705C16.822 4.875 16.458 4.511 16.458 4.0625C16.458 3.614 16.822 3.25 17.2705 3.25H18.5759C21.2539 3.25 23.4325 5.42858 23.4325 8.10442V9.74458C23.4325 10.1931 23.0685 10.5571 22.62 10.5571Z'
						fill='#ffffff'
					/>
					<path
						id='Fill 5'
						fillRule='evenodd'
						clipRule='evenodd'
						d='M3.92188 10.5571C3.47338 10.5571 3.10938 10.1931 3.10938 9.74458V8.10442C3.10938 5.42858 5.28796 3.25 7.96596 3.25H9.30496C9.75346 3.25 10.1175 3.614 10.1175 4.0625C10.1175 4.511 9.75346 4.875 9.30496 4.875H7.96596C6.18387 4.875 4.73438 6.3245 4.73438 8.10442V9.74458C4.73438 10.1931 4.37038 10.5571 3.92188 10.5571Z'
						fill='#ffffff'
					/>
					<path
						id='Fill 7'
						fillRule='evenodd'
						clipRule='evenodd'
						d='M18.5759 23.574H17.2705C16.822 23.574 16.458 23.21 16.458 22.7615C16.458 22.313 16.822 21.949 17.2705 21.949H18.5759C20.358 21.949 21.8075 20.4995 21.8075 18.7185V14.3039C21.8075 13.8554 22.1715 13.4914 22.62 13.4914C23.0685 13.4914 23.4325 13.8554 23.4325 14.3039V18.7185C23.4325 21.3954 21.2539 23.574 18.5759 23.574Z'
						fill='#ffffff'
					/>
					<path
						id='Fill 9'
						fillRule='evenodd'
						clipRule='evenodd'
						d='M9.30398 23.574H7.96498C5.28698 23.574 3.1084 21.3954 3.1084 18.7185V14.3039C3.1084 13.8554 3.4724 13.4914 3.9209 13.4914C4.3694 13.4914 4.7334 13.8554 4.7334 14.3039V18.7185C4.7334 20.4995 6.1829 21.949 7.96498 21.949H9.30398C9.75248 21.949 10.1165 22.313 10.1165 22.7615C10.1165 23.21 9.75248 23.574 9.30398 23.574Z'
						fill='#ffffff'
					/>
				</g>
			</g>
		</svg>
	);
}
