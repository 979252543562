import { IonButton } from "@ionic/react";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import Check from "assets/images/icon/svg/Check";
import React, { forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from "moment";
import Add from "assets/images/icon/svg/Add";
import Cross from "assets/images/icon/svg/Cross";
import Business from "services/Business";
import { emptyKYC, setKYC } from "redux/container/businessSettingsSlice";
import { getLocalUser, getUserBusiness, setUserBusiness } from "config/user";

export default function RentalInfo() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const bs = useSelector((state) => state.businessSettings);
	const navigate = useNavigate();
	let business = getUserBusiness();
	const dispatch = useDispatch();

	const deleteQueryHandler = () => {
		dispatch(
			setKYC({
				rent_amount: "",
			})
		);
	};

	async function kycDataSubmit() {
		let query = {
			...bs.data,
			business: business?.id,
		};
		try {
			const response = bs.data?.kycUpdate
				? await Business.updateKYC(bs.data?.kycId, query)
				: await Business.createKYC(query);
			if (response.status === 200) {
				localStorage.setItem("auth", true);

				dispatch(
					setKYC({
						business_status: "Pending",
					})
				);
				setUserBusiness({
					...business,
					business_status: "Pending",
				});
				navigate("/business-success");
			} else if (response.status === 201) {
				localStorage.setItem("auth", true);
				dispatch(
					setKYC({
						business_status: "Pending",
					})
				);
				setUserBusiness({
					...business,
					business_status: "Pending",
				});
				navigate("/business-success");
			}
		} catch (error) {
			console.log(error);
		}
	}
	const RentalStartDate = forwardRef(({ value, onClick }, ref) => (
		<div
			className={`bg-white p-2 block rounded-[5px] px-4 py-[10px] mb-5`}
			style={{ boxShadow: "1px 2px 8px rgba(0, 0, 0, 0.2) " }}
			onClick={onClick}>
			<div className='flex justify-between items-center'>
				<div className='flex items-center'>
					<img
						style={{ width: 30 }}
						src='assets/images/calender.png'
						alt='icon'
					/>
					<p
						className={`text-16 "text-black-500 font-medium ml-2 font-bold`}>
						{bs.data?.rent_start_date ? (
							moment(bs.data?.rent_start_date).format("DD-MM-YYYY")
						) : (
							<p className='font-bold'>Rental Start Date</p>
						)}
					</p>
				</div>
				<div>
					<Add color='#FF564C' />
				</div>
			</div>
		</div>
	));

	return (
		<div className='pt-14 verifyBusiness-content'>
			<div className='pt-5 px-4 pt-6 pb-0'>
				<p className='text-16 font-semibold text-black-1000 pb-3'>
					Rental Start Date
				</p>

				{/* manufacture date */}
				<div>
					<DatePicker
						showYearDropdown
						showMonthDropdown
						selected={
							bs.data?.rent_start_date
								? moment(bs.data?.rent_start_date)._d
								: moment(new Date())._d
						}
						onChange={(date) => {
							dispatch(
								setKYC({
									rent_start_date: date,
								})
							);
						}}
						customInput={<RentalStartDate />}
						withPortal
					/>
				</div>

				<div className='pb-[50px]'>
					<p className='text-16 font-semibold text-black-1000 pb-3 '>
						Rent per Month
					</p>

					<div className='relative w-full'>
						<input
							className={`bg-white w-full h-[50px] rounded-[7px] pl-5 search-input border common-search $`}
							type='number'
							placeholder='write your shop rent per month'
							style={{ boxShadow: "1px 2px 8px rgba(0, 0, 0, 0.2) " }}
							value={bs.data?.rent_amount}
							onChange={(e) => {
								dispatch(
									setKYC({
										rent_amount: e.target.value,
									})
								);
							}}></input>
						{bs.data?.rent_amount && (
							<span
								className='absolute  right-[10px] top-[34%] h-full z-10 cursor-pointer'
								onClick={deleteQueryHandler}>
								<Cross />
							</span>
						)}
					</div>
				</div>
				{bs.data?.rent_start_date && bs.data?.rent_amount ? (
					<div
						className='rounded-[5px] text-center py-4'
						style={{
							background: "#CCFFD6",
							boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.15)",
						}}>
						<p
							className='font-normal text-14 flex justify-center mb-[10px]'
							style={{ color: "#808080" }}>
							<Check></Check> Select Complete.
						</p>
						<p
							className='font-normal text-14'
							style={{ color: "#808080" }}>
							Please click "
							<span style={{ color: "#00B224" }}>SUBMIT</span>" button
						</p>
					</div>
				) : (
					<div
						className='rounded-[5px]'
						style={{
							background: "#FFFFCC",
							boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.15)",
						}}>
						<p
							className='font-normal p-[14px] text-14'
							style={{ color: "#808080" }}>
							Please, select your{" "}
							<b className='text-primary font-semibold'>
								“Rental Start Date”
							</b>{" "}
							& fill up your{" "}
							<b className='text-primary font-semibold'>
								“Rent per Month”
							</b>{" "}
							amount.
						</p>
					</div>
				)}
				<div className='pt-24 text-center pb-10'>
					<IonButton
						disabled={
							!bs.data?.rent_start_date && !bs.data?.rent_amount
								? true
								: false
						}
						color={
							!bs.data?.rent_start_date && !bs.data?.rent_amount
								? "dark"
								: "success"
						}
						className='font-bold h-[50px] w-full'
						onClick={() => kycDataSubmit()}>
						{ekkLanguage.businessCreateName.submit}
						<span className='pl-2'>
							<ArrayRight />
						</span>
					</IonButton>
					<IonButton
						color='white'
						className='font-bold h-[50px] w-full mt-[30px] border-primary border-2 text-primary rounded-[5px]'
						onClick={() =>
							navigate(
								bs.data?.kycUpdate
									? "/business-success"
									: "/business-profile-settings"
							)
						}>
						I will give this information later
						<span className='pl-2'>
							<ArrayRight color='red' />
						</span>
					</IonButton>
				</div>
			</div>
		</div>
	);
}
