import { IonApp, IonContent, IonPage } from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import React from "react";
import LoanRequestForm from "components/mobile-components/Loan/LoanRequestForm";

export default function LoanRequestPage() {
	return (
		<IonApp>
			<IonPage>
				<IonContent
					scrollEvents={true}
					// onIonScrollEnd={(ev) => {
					// 	getOrderList(orderStatus);
					// }}
				>
					<BackButtonTitle title='Loan Request' />

					<section className='pt-14 px-6'>
						<LoanRequestForm />
					</section>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
