import React from "react";

export default function RightArrowThin() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='14'
			height='14'
			viewBox='0 0 14 14'
			fill='none'>
			<g id='Iconly/Light/Arrow - Left'>
				<g id='Arrow - Left'>
					<path
						id='Stroke 1'
						d='M11.5205 7.15995L2.77051 7.15995'
						stroke='#0086B2'
						strokeLinecap='round'
						strokeLinejoin='round'
					/>
					<path
						id='Stroke 3'
						d='M7.99121 10.6742L11.5204 7.16021L7.99121 3.64563'
						stroke='#0086B2'
						strokeLinecap='round'
						strokeLinejoin='round'
					/>
				</g>
			</g>
		</svg>
	);
}
