import { IonRow } from "@ionic/react";
import React, { useEffect, useState } from "react";
import Card from "../Common/Card";
import { useSelector, useDispatch } from "react-redux";
import {
	getCartList,
	setTotalTax,
	subtotalAmount,
	totalAmount,
} from "redux/container/shoppingCartSlice";
import {
	cartMinus,
	cartPlus,
	cartTotalAmount,
	shoppingListItem,
} from "helpers/shoppingCart";

import { creditFee } from "redux/container/paymentMethodSlice";

import SingleProductForCard from "./SingleProductForCard";

export default function ProductCard() {
	const dispatch = useDispatch();
	const [productList, setProductList] = useState(shoppingListItem());
	const paymentTermsDay = useSelector((state) => state.payment.paymentTerm);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [renderCheck, setRerenderCheck] = useState(false);

	useEffect(() => {
		dispatch(subtotalAmount(cartTotalAmount()));
		dispatch(totalAmount(cartTotalAmount()));
	}, []);

	useEffect(() => {
		dispatch(getCartList());
	});

	let creditPercent;
	if (paymentTermsDay == 0) {
		creditPercent = 0;
	} else if (paymentTermsDay == 1) {
		creditPercent = 0.001;
	} else if (paymentTermsDay == 3) {
		creditPercent = 0.005;
	} else if (paymentTermsDay == 7) {
		creditPercent = 0.01;
	}

	// let totalWithCreitFee = cartTotalAmount() + creditFee;
	let cart = shoppingListItem();
	let tax = 0;

	const minusCartItem = (item) => {
		if (item.minimum_order_quantity < item.quantity) {
			cartMinus(item);
			setProductList(shoppingListItem());
			dispatch(
				totalAmount(cartTotalAmount() * creditPercent + cartTotalAmount())
			);
			dispatch(subtotalAmount(cartTotalAmount()));
			dispatch(creditFee(cartTotalAmount() * creditPercent));

			for (let i = 0; i < cart.length; i++) {
				let taxAmount =
					((cart[i].base_price * cart[i].quantity) / 100) * cart[i].tax;
				tax += taxAmount;
			}
		}
	};

	const plusCartItem = (item) => {
		cartPlus(item);
		setProductList(shoppingListItem());
		dispatch(
			totalAmount(cartTotalAmount() * creditPercent + cartTotalAmount())
		);
		dispatch(subtotalAmount(cartTotalAmount()));
		dispatch(creditFee(cartTotalAmount() * creditPercent));
		for (let i = 0; i < cart.length; i++) {
			let taxAmount =
				((cart[i].base_price * cart[i].quantity) / 100) * cart[i].tax;
			tax += taxAmount;
		}
	};
	for (let i = 0; i < cart.length; i++) {
		let taxAmount =
			((cart[i].base_price * cart[i].quantity) / 100) * cart[i].tax;
		tax += taxAmount;
	}

	useEffect(() => {
		dispatch(setTotalTax(tax));
	}, [tax]);

	useEffect(() => {
		setProductList(shoppingListItem());
	}, [renderCheck]);
	return (
		<div className='px-2 pt-2 pb-5'>
			<Card title={ekkLanguage.createOrder.productItemTitle}>
				<div>
					<IonRow>
						{productList.map((item) => {
							let image;
							if (item?.images[0]) {
								image = item?.images[0];
							} else {
								if (item.type === "variant") {
									image = "/assets/images/varient_default.png";
								} else if (item.type === "pack") {
									image = "/assets/images/pack_default.png";
								} else if (item.type === "offer") {
									image = "/assets/images/offer_default.png";
								}
							}
							return (
								<SingleProductForCard
									image={image}
									item={item}
									minusCartItem={minusCartItem}
									plusCartItem={plusCartItem}
									productList={productList}
									setProductList={setProductList}
									renderCheck={renderCheck}
									setRerenderCheck={setRerenderCheck}
								/>
							);
						})}
					</IonRow>
				</div>
			</Card>
		</div>
	);
}
