import {
	IonApp,
	IonContent,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonLoading,
	IonPage,
	IonSpinner,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import NavFooter from "components/mobile-components/Common/NavFooter";
import Learning from "components/mobile-components/Learning";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPostList, setHelpList } from "redux/container/learningSlice";
import Blog from "services/Blog";

import { getUser, getUserBusiness } from "config/user";
import { getLanguage } from "config/language";
import LearningStatusBtn from "components/mobile-components/Learning/LearningStatusBtn";

export default function LearningPage() {
	const [loading, setLoading] = useState(true);
	const [pageCount, setPageCount] = useState(2);
	const dispatch = useDispatch();
	const learningList = useSelector((state) => state.learning.postList);
	const localLanguage = getLanguage();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [hasMoreData, setHasMoreData] = useState(true);
	const [reachedEnd, setReachedEnd] = useState(false);
	const selectedTab = useSelector((state) => state.learning.selectedTab);
	const [isOpenHelpCategory, setIsOpenHelpCategory] = useState(true);
	const [name, setName] = useState("");
	const business = getUserBusiness();

	const getLearningList = async () => {
		setPageCount(2);
		setLoading(true);
		if (selectedTab === "My Company") {
			let response = await Blog.myCompanyList(
				business?.id,
				1,
				9,
				localLanguage === "bn" ? "BN" : "EN"
			);
			if (response.status === 200) {
				dispatch(setPostList(response?.results));
				setLoading(false);
			} else {
				setLoading(false);
			}
		} else if (selectedTab === "News") {
			let response = await Blog.learningList(
				1,
				9,
				localLanguage === "bn" ? "BN" : "EN"
			);
			if (response.status === 200) {
				dispatch(setPostList(response?.results));
				setLoading(false);
			} else {
				setLoading(false);
			}
		} else if (selectedTab === "Help") {
			setIsOpenHelpCategory(true);
			try {
				const response = await fetch(
					"https://dsocialapi.azurewebsites.net/articles/filter/public/context/?language=EN&page=1&limit=100&name=Help"
				);
				const data = await response.json();
				if (data?.success) {
					dispatch(setHelpList(data?.results[0]));
					setLoading(false);
				} else {
					setLoading(false);
				}
			} catch (error) {
				console.error("Error fetching help context:", error);
				setLoading(false);
			}
		}
	};

	let loadMoreData = async () => {
		if (hasMoreData) {
			if (selectedTab === "My Company") {
				let response = await Blog.myCompanyList(
					business?.id,
					pageCount,
					9,
					localLanguage === "bn" ? "BN" : "EN"
				);
				if (response.status === 200) {
					if (response?.results?.length > 0) {
						setPageCount((prev) => prev + 1);
						dispatch(
							setPostList([...learningList, ...response?.results])
						);
					} else {
						setHasMoreData(false);
					}
				} else {
					setHasMoreData(false);
				}
			} else if (selectedTab === "News") {
				let response = await Blog.learningList(
					pageCount,
					9,
					localLanguage === "bn" ? "BN" : "EN"
				);
				if (response.status === 200) {
					if (response?.results?.length > 0) {
						setPageCount((prev) => prev + 1);
						dispatch(
							setPostList([...learningList, ...response?.results])
						);
					} else {
						setHasMoreData(false);
					}
				} else {
					setHasMoreData(false);
				}
			} else if (selectedTab === "Help" && !isOpenHelpCategory) {
				let response = await Blog.helpList(
					name,
					pageCount,
					9,
					localLanguage === "bn" ? "BN" : "EN"
				);

				if (response.status === 200) {
					if (response?.results?.length > 0) {
						setPageCount((prev) => prev + 1);
						dispatch(
							setPostList([...learningList, ...response?.results])
						);
					} else {
						setHasMoreData(false);
					}
				} else {
					setHasMoreData(false);
				}
			}
		}
	};

	// useEffect(() => {
	// 	getLearningList();
	// }, []);

	useEffect(() => {
		getLearningList();
	}, [selectedTab]);

	// console.log("selectedTab", selectedTab);

	return (
		<IonApp style={{ "--ion-background-color": "#fff" }}>
			<IonPage>
				<IonContent scrollEvents={true}>
					<BackButtonTitle title={ekkLanguage.learning.pageTitle} />
					{/* {loading && <IonLoading isOpen={true} />} */}
					<section className='pt-14'>
						<LearningStatusBtn
							ekkLanguage={ekkLanguage}
							setReachedEnd={setReachedEnd}
							setHasMoreData={setHasMoreData}
							getLearningList={getLearningList}
						/>
						{loading ? (
							<div className='flex items-center justify-center h-[calc(100vh-130px)] m-auto'>
								{" "}
								<IonSpinner name='crescent'></IonSpinner>
							</div>
						) : (
							<Learning
								setLoading={setLoading}
								ekkLanguage={ekkLanguage}
								learningList={learningList}
								isOpenHelpCategory={isOpenHelpCategory}
								setIsOpenHelpCategory={setIsOpenHelpCategory}
								setName={setName}></Learning>
						)}
					</section>
					{selectedTab !== "Help" ? (
						<IonInfiniteScroll
							onIonInfinite={(ev) => {
								if (reachedEnd) {
									ev.target.complete();
									return;
								}
								loadMoreData();
								setTimeout(() => {
									ev.target.complete();
									if (!hasMoreData) {
										setReachedEnd(true);
									}
								}, 2000);
							}}>
							<IonInfiniteScrollContent
								loadingText={ekkLanguage.orderList.loader}
								loadingSpinner={
									hasMoreData ? "bubbles" : null
								}></IonInfiniteScrollContent>
						</IonInfiniteScroll>
					) : (
						<>
							{!isOpenHelpCategory ? (
								<IonInfiniteScroll
									onIonInfinite={(ev) => {
										if (reachedEnd) {
											ev.target.complete();
											return;
										}
										loadMoreData();
										setTimeout(() => {
											ev.target.complete();
											if (!hasMoreData) {
												setReachedEnd(true);
											}
										}, 2000);
									}}>
									<IonInfiniteScrollContent
										loadingText={ekkLanguage.orderList.loader}
										loadingSpinner={
											hasMoreData ? "bubbles" : null
										}></IonInfiniteScrollContent>
								</IonInfiniteScroll>
							) : (
								<></>
							)}
						</>
					)}
				</IonContent>

				{/* Navigation Footer  */}
				<NavFooter />
			</IonPage>
		</IonApp>
	);
}
