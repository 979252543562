import { IonCol } from "@ionic/react";
import CancelPopUp from "assets/images/icon/svg/CancelPopUp";
import moment from "moment";
import React, { forwardRef, useState } from "react";
import { useSelector } from "react-redux";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { ClipLoader } from "react-spinners";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Accounts from "services/Accounts";
import { toast } from "react-toastify";

export default function SalaryList({
	employees,
	employeeHours,
	ekkLanguage,
	totalAmount,
	getEmployees,
	currentWeekStart,
	currentWeekEnd,
	employeeSalary,
}) {
	const dateFormat = useSelector((state) => state.date.format);
	const [totalWorkingHours, setTotalWorkingHours] = useState("00:00:00");
	const [selectedHours, setSelectedHours] = useState(null);

	const [isOpenEditHours, setIsOpenEditHours] = useState(false);
	const onCloseModalEditHours = () => {
		setIsOpenEditHours(false);
	};
	const onOpenModalEditHours = () => setIsOpenEditHours(true);

	const [editHoursLoader, setEditHoursLoader] = useState(false);
	const [isOpenEditHoursModel, setIsOpenEditHoursModel] = useState(false);
	const onCloseModalEditHoursModel = () => {
		setTotalWorkingHours("00:00:00");
		setIsOpenEditHoursModel(false);
	};
	const onOpenModalEditHoursModel = () => setIsOpenEditHoursModel(true);

	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "calc(100% - 40px)",
			transition: ".4s all ease",
			borderRadius: 12,
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
			overflow: "auto",
		},
	};

	const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
		<div
			ref={ref}
			className={`p-3 block mt-4 example-custom-input pl-4 h-[50px] rounded-[5px] bg-F8F8F8  w-full border border-black-500`}
			onClick={onClick}>
			<div className='flex justify-between items-center'>
				<div className='flex items-center '>
					{selectedHours?.check_in_time
						? moment(selectedHours?.check_in_time).format(
								`${dateFormat}-HH:mm:ss`
						  )
						: "Select Time and Date"}
				</div>
			</div>
		</div>
	));

	const ExampleCustomInput2 = forwardRef(({ value, onClick }, ref) => (
		<div
			ref={ref}
			className={`p-3 block mt-4 example-custom-input pl-4 h-[50px] rounded-[5px] bg-F8F8F8  w-full border border-black-500`}
			onClick={onClick}>
			<div className='flex justify-between items-center'>
				<div className='flex items-center '>
					{selectedHours?.check_out_time
						? moment(selectedHours?.check_out_time).format(
								`${dateFormat}-HH:mm:ss`
						  )
						: "Select Time and Date"}
				</div>
			</div>
		</div>
	));

	let dateChanger = (date, type) => {
		let updatedHours;
		if (type === "start") {
			updatedHours = {
				...selectedHours,
				check_in_time: date,
			};
		} else {
			updatedHours = {
				...selectedHours,
				check_out_time: date,
			};
		}
		setSelectedHours(updatedHours);

		// Calculate total hours if both dates exist
		if (updatedHours.check_in_time && updatedHours.check_out_time) {
			const start = moment(updatedHours.check_in_time);
			const end = moment(updatedHours.check_out_time);
			const duration = moment.duration(end.diff(start));

			const hours = Math.floor(duration.asHours())
				.toString()
				.padStart(2, "0");
			const minutes = duration.minutes().toString().padStart(2, "0");
			const seconds = duration.seconds().toString().padStart(2, "0");

			setTotalWorkingHours(`${hours}:${minutes}:${seconds}`);
		}
	};

	const updateHoursSubmit = async () => {
		setEditHoursLoader(true);
		let payload = {
			check_out_time: moment(selectedHours?.check_out_time).format(
				"YYYY-MM-DDTHH:mm:ssZ"
			),
			check_in_time: moment(selectedHours?.check_in_time).format(
				"YYYY-MM-DDTHH:mm:ssZ"
			),
		};
		// console.log(payload);
		let response = await Accounts.checkOut(selectedHours?.id, payload);

		if (response.success) {
			getEmployees();
			setEditHoursLoader(false);
			toast.success("Working hours updated successfully");
			onCloseModalEditHours();
			onCloseModalEditHoursModel();
		} else {
			setEditHoursLoader(false);
		}
	};

	return (
		<div className='bg-white'>
			<div
				className='px-4 py-[10px]'
				style={{ borderBottom: "1.5px solid rgba(0, 0, 0, 0.1)" }}>
				<h6 className='font-bold text-16 pt-4 pb-4'>Salary</h6>
				<div className='flex justify-between items-center mb-1'>
					<h4 className='font-bold text-16 text-black-1000'>
						Salary Type: {employeeSalary?.salary_type}
					</h4>
				</div>
				<div className='flex justify-between items-center mb-5'>
					<h4 className='font-bold text-16 text-black-1000'>
						Salary Amount: {employeeSalary?.amount}
					</h4>
				</div>

				{/* <div className='mb-4'>
					<table
						className='table-auto w-full bg-white'
						style={{
							boxShadow: "0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
						}}>
						<thead>
							<tr className='border h-[45px] border-r-0 border-l-0 border-D9D9D9 px-4'>
								<th className='font-bold text-14'>Date</th>
								<th className='font-bold text-14'>Amount</th>
								<th className='font-bold text-14'>Paid By</th>
							</tr>
						</thead>

						<tbody>
							{employeeHours?.length > 0 &&
								employeeHours?.map((el, i) => (
									<tr
										key={i}
										className='text-center h-[34px] border border-r-0 border-l-0 border-D9D9D9 px-4'
										onClick={() => {
											setSelectedHours(el);
											setTotalWorkingHours(el?.working_hour);
											onOpenModalEditHours();
										}}>
										<td className='font-semibold text-12'>
											{moment(el?.check_in_time).format(
												`${dateFormat}-HH:mm:ss`
											)}
										</td>
										<td className='font-semibold text-12'>
											{el?.check_out_time
												? moment(el?.check_out_time).format(
														`${dateFormat}-HH:mm:ss`
												  )
												: "On Going"}
										</td>
										<td className='font-semibold text-12'>
											{el?.working_hour}
										</td>
									</tr>
								))}
						</tbody>
					</table>

					{employeeHours.length === 0 && (
						<>
							<IonCol size='2'></IonCol>
							<IonCol size='8'>
								<div
									className='text-center flex items-center justify-center'
									style={{ height: "calc(100vh - 420px)" }}>
									<div>
										<img
											className='m-auto mb-[10px] mt-5'
											src='/assets/images/not-found-file.png'
											alt='emptyCard'
										/>
										<p className='text-16 font-normal text-222222 mb-[35px]'>
											{ekkLanguage.orderList.notFound}
										</p>
									</div>
								</div>
							</IonCol>
						</>
					)}
				</div> */}
			</div>
		</div>
	);
}
