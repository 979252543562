const english = {
	login: {
		contryLabel: "COUNTRY",
		contryPlaceHolder: "",
		phoneLable: "PHONE NUMBER",
		phonePlaceHolder: "PHONE NUMBER",
		validationLabel:
			"Phone number not valid, Please write phone no in english",
		btnLabel: "Continue",
		tokenSuccessLabel: "Your token has been sent to your phone",
		tokenSuccessFailed: "Your token not create yet please try again",
		langChange: "Change language to",
		termsAllow: "Please allow terms and conditions while logging in!",
	},
	password: {
		passwordLabel: "PASSWORD",
		passwordPlaceHolder: "Enter your password",
		forgetPass: "Forgot Password",
		backWord: "Go Back",
		btnLabel: "Login",
		btnLoadingLabel: "Logging in",
		otpSendLabel: "Code has been sent to your mobile",
		passError: "Password is not matched with phone number",
	},
	changePass: {
		passwordLabel: "NEW PASSWORD",
		passwordPlaceHolder: "*******************",
		confirmPassLabel: "CONFIRM PASSWORD",
		confirmPassPlaceHolder: "*******************",
		passNotMatchLabel: "Password does not match",
		btnLabel: "Continue",
		successLabel: "Your password Changed successfully",
		errorLabel: "Your password Changed failed",
		lengthErrorLabel: "Password must be at least 4 characters long",
		notMatchLabel: "Your password and confirm password not match.",
	},
	token: {
		tokenLabel: "TOKEN",
		tokenPlaceHolder: "Enter your token",
		tokenResend: "Resend token",
		tokenSuccessLabel: "Code has been sent to your mobile, type to continue",
		tokenErrorLabel: "Token send failed",
		timerLabel: "Please wait",
		second: "second",
		btnLabel: "Continue",
		tokenNotMatch: "Token not match",
		tokenVarified: "Your token has been verified",
		tokenNotWrite: "Please enter your token",
	},
	registration: {
		nameLabel: "FULL NAME",
		namePlaceholder: "Enter your full name",
		emailLabel: "EMAIL",
		emailPlaceholder: "Enter your email",
		passwordLabel: "PASSWORD",
		passwordPlaceholder: "Enter your password",
		confirmPassLabel: "CONFIRM PASSWORD",
		confirmPassPlaceholder: "Enter your confirm password",
		validationLabel: "Input field must be at least 4 characters long",
		passNotMatch: "Your password and confirm password not match.",
		btnLabel: "Continue",
		successMessage: "Profile Created Successfully",
		errorMessage: "Please check valid input",
		passLengthValidLabel: "Password must be at least 4 characters long",
		passwordLength: "Your password is",
		strong: "Strong",
		medium: "Medium",
		weak: "Weak",
		gender: "GENDER",
		male: "Male",
		female: "Female",
		others: "Others",
		dobLabel: "DATE OF BIRTH",
		day: "Day",
		month: "Month",
		year: "Year",
	},
	letNav: {
		homeMenuLabel: "Home",
		orderMenuLabel: "Order History",
		mySupplierMenuLabel: "My Suppliers",
		businessProfileMenuLabel: "Business Profile Settings",
		phoneCall: "Call To Order",
	},

	menu: {
		accounting: "Accounting",
		procurement: "Procurement",
		products: "Products",
		loan: "Loan",
	},
	home: {
		showMoreLabel: "Show More",
		brandLabel: "Brand",
		offerLabel: "New Offer",
		mySuppliersLabel: "My Suppliers",
		nearSuppliersLabel: "Nearby Suppliers",
		recommendentLabel: "Just For You",
		products: "Products",
		newProductLabel: "New Product",
		recommendentOrderLabel: "Recommended order",
		latestNewsLabel: "Latest News",
		searchLabel: "Search products",
		supplierLabel: "Search supplier",
		todayPurchaseLabel: "Today’s Purchase",
		totalOrderLabel: "Products from",
		totalOrder: "Orders",
		skusLabel: "SKUs",
		tillNow: "Till Now",
		orderDue: "Orders Due",
		brandProductPageTitle: "All Brands",
		offerProductPageTitle: "Offer Products",
		offerProductNotFound: "Sorry, No offers were found for you.",
		mySupplierNotFound:
			"You dont have any suppliers. Please buy products to add your suppliers here.",
		supplierNotFound:
			"Sorry, no suppliers were found in your area, We will assign some suppliers in your area very soon.",
		brandSeller: "Suppliers",
		allBrandSeller: "All Suppliers",
		km: "km",
		account: "Account",
		warning: `Kindly note, transactions will be impacted on 1st March 2024 as system is being upgraded.`,
		warningHeading: "Warning",
		product: "Products",
		offerSearchNotFound: "No products found at the name",
	},
	profile: {
		pageTitle: "Profile & Settings",
		contactMenuLable: "Contact & Basic Info",
		passwordMenuLable: "Change Password",
		logoutMenuLable: "Sign Out",
		changeLanguageMenuLable: "Change Language",
	},
	changeLanguages: {
		pageTitle: "Select Language",
		btnLabel: "Save",
	},
	contact: {
		pageTitle: "Contact & Basic Info",
		emailLabel: "Email",
		emailPlaceholder: "Enter your email",
		nidLabel: "NID",
		nidPlaceholder: "Enter NID number",
		btnLabel: "Save",
		successLabel: "Your profile Updated. successfully",
		currectEmail: "please enter current email address",
	},
	otpsend: {
		pageTitle: "Verify Number",
		btnLabel: "Send",
		message: "Tap to send authentication code to your mobile via SMS.",
		successLabel: "Your token has been sent to your phone",
		errorLabel: "Your token not create yet please try again",
	},
	otpsubmit: {
		pageTitle: "Verify Number",
		btnLabel: "Submit",
		verifiLabel: "Verify & Proceed",
		title: "Verification Code",
		paragraph: "We have sent the verification code to Your Mobile Number",
		otpLabel: "Enter your OTP code",
		otpPlaceholder: "Enter your OTP code",
		receiveYetLabel: "Didn’t recieve code?",
		againRequestLabel: "Request again",
		timerLabel: "Please wait",
		second: "second",
		successLabel: "Your token has been sent to your phone",
		errorLabel: "Your token not create yet please try again",
		tokenVarifiedMessage: "Your token has been verified",
		validationLabel: "Please submit your OTP",
		optNotMatch: "Your OTP is not valid",
	},
	changePassword: {
		pageTitle: "Change Password",
		btnLabel: "Submit",
		passwordLabel: "PASSWORD",
		passwordPlaceholder: "Enter your password",
		confirmPassLabel: "CONFIRM PASSWORD",
		confirmPassPlaceholder: "Enter your confirm password",
		successLabel: "Your password Updated.",
		errorLabel: "Your password Updated Failed",
		passNotMatchLabel: "Your password and confirm password not match.",
		changePassSuccessPara: "Your password has been Changed Successfully.",
		changePassContinueBtn: "Continue",
	},
	brandDetails: {
		skusLabel: "SKUs",
		descriptionLabel: "Description",
		productTitleLabel: "All products of this brand",
	},
	mysuppliers: {
		skusLabel: "SKUs",
		productTitleLabel: "This Suppliers Product",
	},
	productList: {
		pageTitle: "Offer Product List",
	},
	productDetails: {
		offerStart: "Offer Start Date",
		offerEnd: "Offer End Date",
		moqLabel: "MOQ",
		consumerLabel: "Consumer Price (MRP)",
		profitLabel: "Profit",
		productDetailTitle: "Product Details",
		skuId: "SKU Id",
		expireData: "Expire Date",
		manufactureData: "Manufacture Date",
		productWeight: "Product Weight",
		packingType: "Packing Type",
		importedBy: "Imported By",
		manufacturedBy: "Manufactured By",
		country: "Country",
		descriptionLabel: "Description",
		btnLabel: "Order Now",
		inStock: "In stock",
		outStock: "Out stock",
		preOrderBtn: "Request Pre-Order",
		preOrderQuantity: "Write Pre-Order Quantity",
		searchTram: "Search Tram",
	},
	createOrder: {
		pageTitle: "Create Order",
		buyerDetailsTitle: "Buyer Details",
		sellerDetailsTitle: "Seller Details",
		deliveryDetailsTitle: "Delivery",
		productItemTitle: "Product Items",
		costSummaryTitle: "Cost Summary",
		paymentDetailsTitle: "Payment Details",
		paymentTermsTitle: "Payment Terms",
		paymentMethodeTitle: "Payment Method",
		moqLabel: "MOQ",
		pcsLabel: "Pcs",
		subtotal: "Subtotal",
		tax: "Tax / Vat",
		discount: "Discount",
		deliveryFee: "Delivery Fee",
		creditFee: "Service Fee",
		total: "Total",
		immediatePayment: "Immediate Payment",
		pickAndPay: "Pick And Pay",
		today: "At Night of Delivery Day",
		threeDays: "Pay in 3 days",
		sevenDays: "Pay in 7 days",
		immediatePaymentText: "No Service Fee applicable for this order.",
		todayPaymentText1: "For today payment, additional Service Fee of ",
		todayPaymentText2: "will be added to this order.",
		threePaymentText1: "For 3 days payment, additional Service Fee of ",
		threePaymentText2: "will be added to this order.",
		sevenPaymentText1: "For 7 days payment, additional Service Fee of ",
		sevenPaymentText2: " will be added to this order.",
		cashOnDelivery: "Cash On Delivery",
		cashOnDeliveryText1: "Pay Cash to",
		cashOnDeliveryText2: "when goods is delivered.",
		cash: "Cash",
		bkash: "Bkash",
		nagad: "Nagad",
		cashText: "Pay Cash to",
		bkashText: "Pay through Bkash",
		nagadText: "Pay through Nagad",
		bankTransfer: " Bank Transfer",
		bankTransferText1: "Bank Transfer - Pay to",
		bankTransferText2: "bank account with 'Order Number' as reference",
		bankTransferText3: "Bank Name",
		bankTransferText4: "A/C Name",
		bankTransferText5: "A/C Number",
		bankTransferText6: "Route Number",
		bankTransferText7: "Branch",
		btnLabel: "Confirm",
		requestDeliveryDate: "Request Delivery Date",
		addMoreProductLabel: "Add More Product",
		selectProductLabel: "Submit",
		cosgressTitle: "Congratulations !",
		cosgressPara: "Your order has been completed.",
		preOrderCongress:
			"Your pre-order has been completed successfully. Our representative will contact you shortly",
		congressCancelText:
			"If there is any problem with the order, cancel it before at 12 pm. You cannot cancel the order after at 12 pm.",
		congressProductLabel: "Continue",
		orderNow: "Order Now",
		alert: "Alert",
		importentMessage: "Important message",
		deliveryDateMessage: "Please Select Delivery Date",
		paymentTermsMessage: "Please Select Payment Term",
		paymentMethodMessage: "Please Select Payment Method",
		backButtonMessage:
			"Your cart have some items, if you leave this page your cart will be empty.",
		okBtnLabel: "OK",
		loader: "Please wait...",
		placeConformationMessage: "Are you sure you want to place this order?",
		lessThenMoq:
			"Items Quantity selected is less then minimum order quantity.",
		all: "All",
		number: "Number",
		numberWarning:
			"If money is sent to any number other than this number, it will be considered as cancelled.",
		islamiBank: "Islami Bank",
		bankAsia: "Bank Asia",
		payTo: "Pay To",
		merchantNumber: "Merchant Number",
		outOffStock: "The product is out of stock",
	},
	selectProduct: {
		pageTitle: "Select Product",
		totalLabel: "Total",
		btnLabel: "Submit",
	},
	orderList: {
		pageTitle: "Order",
		tabMenuRecent: "All",
		tabMenuPlaced: "Placed",
		tabMenuAccepted: "Accept",
		tabMenuScDelivered: "Scheduled For Delivery",
		tabMenuPartialDelivered: "Partial Delivered",
		tabMenuDelivered: "Delivered",
		tabMenuReturn: "Return Product",
		tabMenuPartialPaid: "Partial Paid",
		tabMenuPaid: "Paid",
		tabMenuCancel: "Cancel",
		tabMenuDeliveryFailed: "Delivery Failed",
		orderItem: "Order Item",
		seller: "Seller",
		buyer: "Buyer",
		loader: "Please wait...",
		createdBy: "Created By",
		to: "To",
		for: "For",
		notFound: "Not Found",
	},
	orderDetails: {
		createdBy: "Created by",
		placedLabel: "Placed",
		deliveredLabel: "Delivered",
		paidLabel: "Paid",
		orderItemLabel: "Order Item",
		castItemLabel: "Cost Details",
		timelineLabel: "TimeLine",
		subtotal: "Subtotal",
		tax: "Tax / Vat",
		discount: "Discount",
		deliveryFee: "Delivery Fee",
		creditFee: "Service Fee",
		total: "Total",
		paymentSettlementLabel: "Payment Settlement",
		date: "Date",
		amount: "Amount",
		dueDate: "Due Date",
		remark: "Remark",
		collectedBy: "Collected by ",
		paymentDetailsLabel: "Payment Details",
		paymentMethod: "Payment Method",
		paymentTerms: "Payment Terms",
		paymentDueDate: "Payment Due Date",
		totalDue: "Total Due",
		totalPaid: "Total Paid",
		remaining: "Remaining",
		deliveryDetailsLabel: "Delivery Details",
		BuyerName: "Buyer’s Name",
		address: "Address",
		phoneNumber: "Phone Number",
		email: "Email",
		requestDeliveryDate: "Request Delivery Date",
		scheduleDeliveryDate: "Schedule Delivery Date",
		scheduleDeliveryTime: "Schedule Delivery Time",
		deliveryDate: "Delivery Date",
		downloadInvoices: "Download Invoice",
		receiptDownloads: "Print Receipt",
		cancelOrder: "Cancel Order",
		cancelConformationAsk: "Are you sure you want to Cancel this Order ?",
		cancelConformationParagraph: `You can not cancel this order, you can cancel your order only same day.`,
		yesBtn: "Yes",
		noBtn: "No",
		withoutSettelment: "Payments completed information will be shown here.",
		bankInfoLabel: "Bank Account Information for Payment Transfer",
		deliveryFailMessage: "Delivery failed message",
		close: "Close",
	},
	businessCreateName: {
		pageTitle: "Business Name",
		businessName: "Business Name",
		businessNamePlaceholder: "Enter Business Name",
		paragraph:
			"We are 3 steps away to create your business. Lets start with your business name.",
		btnLabel: "Next",
		submit: "Submit",
		numberOne: 1,
		numberTwo: 2,
		numberThree: 3,
		errorLabel: "Enter business name!",
		congratulation: "Congratulation",
		congratulationMessage: "Your business created successfully",
		kycSubmit: "Submin Your KYC",
		pending:
			"Thanks for submitting KYC. Your KYC verification is in progress, please wait.",
		goBack: "Go Back",
	},
	businessType: {
		pageTitle: "Business Type",
		paragraph: "Lets choose your Business type.",
		fmcg: "FMCG",
		miniMart: "Mini Mart",
		superMart: "Super Mart",
		pharmacy: "Pharmacy",
		cart: "Cart",
		bazaarStore: "Bazaar Store",
		hawkerCenter: "Hawker Center",
		restaurant: "Restaurant",
		hotel: "Hotel",
		cosmeticsStore: "Cosmetics Store",
		nonFmcg: "NON FMCG",
		office: "Office",
		NGO: "NGO",
		entertainmentPlace: "Entertainment Place",
		religiousOrganization: "Religious Organization",
		spa: "Spa",
		salon: "Salon",
		gym: "Gym",
		electronicsStore: "Electronics Store",
		shoeStore: "Shoe Store",
		fashionStore: "Fashion Store",
		laundryStore: "Laundry Store",
		numberOne: 1,
		numberTwo: 2,
		numberThree: 3,
		btnLabel: "Next",
		validationLabel: "Please Select a business type",
	},
	businessLocation: {
		pageTitle: "Business Location",
		paragraph: "Enter your business address details",
		address: "Address",
		addressPlaceholder: "Enter the road number/area name",
		city: "City",
		cityPlaceholder: "Enter city Name",
		postalCode: "Postal Code",
		postalCodePlaceholder: "Enter postal code",
		email: "Email",
		emailPlaceholder: "Enter your email",
		userName: "User Name",
		userNamePlaceholder: "Enter your user name",
		numberOne: 1,
		numberTwo: 2,
		numberThree: 3,
		btnLabel: "DONE",
		successLabel: "Congratulations business created",
		errorLabel: "Business creation failed",
		validationLabel: "Please fill up the address to move.",
		loader: "Please wait...",
		validationCityLabel: "Please fill up the city.",
		validationPostCodeLabel: "Please fill up postal code.",
	},
	locationPicker: {
		pageTitle: "Business Location",
		btnLabel: "CONFIRM",
		paragraph: `Move map to your business location to select it`,
	},
	location: {
		title: "Enable your Location",
		paragraph:
			"Please check your location settings for browser if location is not automatically detected.",
		btnLabel: "Allow only while using the app",
	},
	businessSelect: {
		pageTitle: "Select Business",
	},
	pwaDownload: {
		header: "Download EkkBaz App",
		paragraph:
			"Download the app and order bulk from your supplier any time from now on.",
		cancelBtn: "Cancel",
		downloadBtn: "Download",
	},
	businessProfile: {
		pageTitle: "Business Profile Settings",
		businessDetailsLabel: "Details",
		registrationNo: "Register Number",
		taxNo: "Tex/Vat Number",
		mobileNo: "Mobile Number",
		officeNo: "Office Number",
		email: "Email",
		website: "Website",
		branchLabel: "Branch List",
		paymentDetailsLabel: "Payment Details",
		bankInfoLabel: "Bank Information",
		bankName: "Bank Name",
		branchName: "Bank Branch",
		branchAddress: "Bank Address",
		swiftCode: "Bank Swift Code",
		acName: "Bank A/C Name",
		acNumber: "Bank A/C Number",
		bkashInfoLabel: "Bkash Information",
		bkashAcName: "Bkash A/C Name",
		bkashAcNo: "Bkash A/C Number",
		nagadInfoLabel: "Nagad Information",
		nagadAcName: "Nagad A/C Name",
		nagadAcNo: "Nagad A/C Number",
		upayInfoLabel: "Upay Information",
		upayAcName: "Upay A/C Name",
		upayAcNo: "Upay A/C Number",
		verified: "Verified",
		unVerified: "Unverified",
		editBusinessMenu: "Edit Business",
		addPayMenu: "Add Payment Option ",
		setLocation: "Update Business Location",
		addMenu: "Add Branch",
		verifyBusinessMenu: "Give KYC Information",
		bankInfoNotFound: "Bank information not found.",
		deleteBusiness: "Delete Business",
		deleteWarning: "It's deleted everything in this business",
		otpSubmit: "OTP Submit",
		confirmDeleteWarning: "Are you sure you want to delete this business",
		switchBusiness: "Switch Business",
		myEmployeesMenuLabel: "Employees",
	},
	businessEdit: {
		pageTitle: "Edit Business ",
		uploadPhoto: "Upload Photo",
		businessName: "Business Name",
		businessNamePlaceholder: "Enter your business name",
		email: "Email",
		emailPlaceholder: "Enter your email",
		address: "Address",
		addressPlaceholder: "Enter your address",
		city: "City",
		cityPlaceholder: "Enter city Name",
		postalCode: "Postal Code",
		postalCodePlaceholder: "Enter postal code",
		resigter: "Register Number",
		resigterPlaceholder: "Enter your Register Number",
		tex: "Tex/Vat Number",
		texPlaceholder: "Enter your Tex/Vat Number",
		mobile: "Mobile Number",
		mobilePlaceholder: "Enter your Mobile Number",
		office: "Office Number",
		officePlaceholder: "Enter your Office Number",
		website: "Website",
		websitePlaceholder: "Enter your website",
		btnLabel: "Save",
		successLabel: "Your profile Updated. successfully",
		currectEmail: "Please enter current email address",
	},
	bankDetails: {
		referenceNumber: "Reference Number",
		bankName: "Bank Name",
		accountName: "A/C Name",
		accountNumber: "A/C Number",
		routeNumber: "Route Number",
		branch: "Branch",
	},
	invoice: {
		title: "INVOICE",
		receipt: "Receipt",
		buyerDetails: "Buyer Details",
		sellerDetails: "Seller Details",
		description: "DESCRIPTION",
		qty: "QTY",
		price: "PRICE",
		subtotal: "SUBTOTAL",
	},
	verifyBusiness: {
		numberOne: "১",
		numberTwo: "২",
		numberThree: "৩",
		numberFour: "৪",
		numberFive: "৫",
		numberSix: "৬",
	},
	receipt: {
		placed: "Placed",
		name: "Name",
		qty: "Qty",
		price: "Price",
		subtotal: "Subtotal",
		tax: "Tax",
		discount: "Discount",
		creditFee: "Service Fee",
		deliveryFee: "Delivery Fee",
		total: "Total",
		paid: "Paid",
		totalDue: "Total Due",
		paymentTerms: "Payment Terms",
		days: "Days",
		paymentMethod: "Payment Method",
		referenceNumber: "Bank Reference Number",
		deliveryDate: "Delivery Date",
		dueDate: "Payment Due Date",
		printTime: "Print Time",
		conditionsCreditFee: "Payment Conditions & Service Fee",
		immediatePayment: "Immediate Payment",
		cashOnDelivery: "Cash On Delivery",
		atNight: "At Night of Delivery Day",
		payInthreeDay: "Pay in 3 Days",
		payInSevenDay: "Pay in 7 Days",
		afterSeven: "After 7 Days (additional for everyday extra)",
	},
	landing: {
		header1: "Wholesale market at your fingertip.",
		header2: "Buy Now",
		header3: "Pay Later! ",
		login: "Log in",
		bangla: "বাংলা",
		english: "English",
		vietnamese: "Tiếng Việt",
		malaysia: "Malay",
		retail: "Retail",
		manufacture: "Supplier",
		hotline: "Hot line",
		phoneNumber: "Phone Number",
		phoneNumberPlaceHolder: "Enter Phone Number",
		fAbout: "Wholesale market at your fingertip. Buy Now, Pay Later!",
		location: "Location",
		singapore: "Singapore Office",
		bangladesh: "Bangladesh Office",
		bOfficeAddress: "13th Floor, Razzak Plaza, Mog Bazar Moor, Dhaka-1205",
		otherInfo: "Other info",
		partners: "Our Partners",
		news: "News",
		support: "Support",
		corporate: "Corporate",
		refundPolicy: "Refund Policy",
		termsCondition: "Terms Conditions",
		privacyPolicy: "Privacy Policy",
		contactUs: "Contact Us",
		messageUs: "Message Us",
		facebook: "Facebook",
		sales: "Sales",
		media: "Media",
		downloadBtn: "Download App",
		backers: "Our Backers",
		benefits: "Your Benefits",
		discover: "Latest EkkBaz News",
		benefitsOne: "24 hours on-time delivery",
		benefitsDisOne: "",
		benefitsTwo: "Best market prices",
		benefitsDisOneTwo: "",
		benefitsThree: "Largest SKUs assortments",
		benefitsDisOneThree: "",
		benefitsFore: "Financing supports for purchases",
		benefitsDisOneFore: "",
		seeMore: "See More",
		weAvailable: "We are Available",
		downloadApp: "Download the App Now !",
	},
	seeMoreProduct: {
		brandsSearchPlaceholder: "Search Brands",
		supplierSearchPlaceholder: "Supplier Search",
		offerSearchPlaceholder: "Search offer products",
	},
	sellerList: {
		pageTitle: "Seller List",
		nearBySeller: "Near By Seller",
		allSeller: "All Seller",
		supplierNotFound: "You have no supplier available",
		nearBySupplierNotFound:
			"Sorry, no supplier found in your area, we will assign some supplier in your area very soon.",
	},
	learning: {
		pageTitle: "Learning",
		learningDetailsTitle: "Learning Details",
		comments: "Comments",
		commentAs: "Write your comment here",
		likedWarning: "You cannot like more than once",
	},
	navFooter: {
		home: "Home",
		order: "Order",
		supplier: "Supplier",
		learning: "Learning",
		account: "Account",
	},
	notification: {
		pageTitle: "Notification",
		order: "Order",
		product: "Product",
		update: "Update",
	},
	autoLogOut: {
		pageTitle: "Automatic Logout",
		warningMsg: "Auto logout since this number was used in another Login",
		instrMsg: "Click the green button below to login again",
		btnLabel: "Log in",
	},
	dashboard: {
		statusLable: "Order Status",
		today: "Today",
		yesterday: "Yesterday",
		thisWeek: "This Week",
		thisMonth: "This Month",
		thisYear: "This Year",
		lastWeek: "Last Week",
		lastMonth: "Last Month",
		lastYear: "Last Year",
		custom: "Custom Days",
		selectDay: "Select Day",
		Order: "Order",
		totalOrder: "Total Order",
		placed: "Placed",
		accepted: "Accept",
		scDelivered: "Scheduled For Deli..",
		partialDelivered: "Partial Delivered",
		delivered: "Delivered",
		return: "Return Product",
		partialPaid: "Partial Paid",
		paid: "Paid",
		totalPaid: "Total Paid",
		cancel: "Cancel",
		deliveryFailed: "Delivery Failed",
		orderAmount: "Order Amount",
		totalAmount: "Total Amount",
		collectedAmount: "Collected Amount",
		dueAmount: "Due Amount",
		totalDue: "Total Due",
		directOrder: "Direct Order",
		directOrderAmount: "Direct Order Amo..",
		ekkHeroOrder: "EkkHero Order",
		ekkHeroOrderAmount: "EkkHero Order Am..",
		cancelOrderAmount: "Cancel Order Amo..",
		seeDetails: "See Details",
	},
	voiceOrder: {
		pageTitle: "Voice Ordering",
		bannerHello: "Hello",
		bannerAI: "Welcome to Voice Ordering AI",
		bannerUse: "You can find your desired product using me",
		speechTitle: "Your Speech",
		speechHeader: "I would like to order - ",
		speechInfo:
			"Please tell me the product name by clicking the above white button",
		keywordTitle: "Matched Keyword",
		keywordInfo: "Please select above one keyword to get related products",
		productsTitle: "Best Matched Product List",
		notFound: "Sorry, I couldn't find any products, please try again..!",
	},
	addBankSettings: {
		bottomSheetTitle: "Add Your Bank Account",
		pageTitle: "Add Your Bank Account",
		accountName: "Account Name",
		accountNamePlaceholder: "Enter your account name",
		accountNumber: "Account Number",
		accountNumberPlaceholder: "Enter your account number",
		selectBank: "Select Bank",
		selectBankPlaceHolder: "Select your bank account",
		branch: "Branch",
		branchPlaceHolder: "Enter your bank branch",
		address: "Address",
		addressPlaceholder: "Enter bank address",
		selectCountry: "Select Country",
		selectCountryPlaceHolder: "Select your bank country",
		selectCity: "Select City",
		selectCityPlaceHolder: "Select city",
		postalCode: "Postal Code",
		postalCodePlaceHolder: "Enter your bank postal code",
		buttonTitle: "Save",
		bankNameWarning: "Please enter your bank account name",
		bankNumberWarning: "Please enter your bank account number",
		selectBankWarning: "Please select a bank",
		branchWarning: "Please enter your bank branch",
		addressWarning: "Please enter your address",
		cityWarning: "Please enter your city",
	},
	addedBankList: {
		pageTitle: "Bank List",
		acName: "A/C Name",
		acNumber: "A/C Number",
		branch: "Branch",
		address: "Address",
		city: "City",
		country: "Country",
		addBank: "Add Your Bank Account",
	},
	blackListed: {
		pageTitle: "Blacklisted",
		warningMsg: "Your Business is Blacklisted",
		instrMsg:
			"Please request your business owners to contact EkkBaz for necessary actions to re-enable your business.",
		btnLabel: "Log out",
	},
	industryType: {
		pageTitle: "Working Industry",
		title: "Please select your working industry",
	},
	accounting: {
		todayRevenue: "Today’s Total Revenue",
		todayExpense: "Today's Total Expense",
		revenues: "Revenues",
		expenses: "Expenses",
		accPageTitle: "Accounts",
		revenue: "Revenue",
		expense: "Expense",
		addRevenue: "Add Revenue",
		addExpense: "Add Expense",
		date: "Date",
		amount: "Amount",
		amountPlaceholder: "Write amount",
		category: "Category",
		categoryPlaceholder: "Select a category",
		description: "Description",
		descriptionPlaceholder: "Write description",
		images: "Upload Images",
		supplier: "Supplier",
		supplierPlaceholder: "Select a supplier",
		submitRevenue: "Submit Revenue",
		submitExpense: "Submit Expense",
		uploadImageInfo: "Upload your image here, or browse",
		uploadImageInfoPlaceholder:
			"Maximum file size support 10 MB, JPG, JPEG or PNG",
		revenueCreateWarning:
			"Are you sure you want to submit this revenue report?",
		expenseCreateWarning:
			"Are you sure you want to submit this expense report?",
		successRevenue: "Revenue created successfully",
		successExpense: "Expense created successfully",
		supplierPageTitle: "Supplier List",
		alreadyPaid: "Expense Already Paid?",
		onto: "To Be Paid On",
		paid: "Paid",
		notPaid: "Not Paid",
		takePhoto: "Take Photo",
		addImage: "Add Image",
		successFullyCreated: "Successfully created",
		amountCategoryDescription:
			"Please add a valid amount, select a category and write a description",
		amountDescription: "Please add a valid amount and write a description",
		amountCategory: "Please add a valid amount and select a category.",
		categoryDescription: "Please select a category and write a description",
		amountWarning: "Please enter a valid amount.",
		descriptionWarning: "Please enter a valid amount.",
		categoryWarning: "Please Select a category.",
	},
	employees: {
		pageTitle: "Employees",
		createNewEmployees: "Create New Employee",
		searchPlaceHolder: "Search your employee",
		collectorList: "Collector List",
		collectorSearch: "Search collector",
	},
	employeesDetails: {
		pageTitle: "Employees Details",
	},
	createEmployees: {
		pageTitle: "Create New Employee",
		customerNoHolder: "Enter Mobile Number",
		employeeNumber: "Employee Number",
		name: "Name",
		namePlaceholder: "Enter employee name",
		confirmBtn: "Confirm",
		selectEmployees: "Select employee position",
		admin: "Administrator",
		stuff: "Staff",
		orderManager: "Order Manager",
		bookManager: "Book Manager",
		productManager: "Product Manager",
		employeeManager: "Employee Manager",
		nameError: "Please enter employee name",
		roleError: "Please select employee role",
		edit: "Edit",
		delete: "Delete",
		editEmployee: "Edit Employee",
		deleteConformationAsk: "Are you sure you want to delete this employee?",
		updateEmployee: "Update Employee",
	},
	loan: {
		pageTitle: "Loan",
		myLoanRequest: "My Loan Request",
		loanProviders: "Loan Products",
		loanAmount: "Loan Amount Needed",
		loanPurpose: "Loan Purpose",
		country: "Country",
		loanDuration: "Loan Duration",
		creditScore: "Credit Score",
		createLoanRequest: "Create Loan Request",
		loanCreateWarning: "Do you want to create a loan request?",
	},
};
export default english;
