import {
	IonApp,
	IonContent,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonPage,
	IonToolbar,
} from "@ionic/react";
import Badge from "assets/images/icon/svg/Badge";
import Offer from "assets/images/icon/svg/Offer";
import Van from "assets/images/icon/svg/Van";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import Banner from "components/mobile-components/Home/Banner";
import BrandCard from "components/mobile-components/Home/BrandCard";
import Departments from "components/mobile-components/Home/Departments";
import ProductCard from "components/mobile-components/Home/ProductCard";
import PurchaseSummary from "components/mobile-components/Home/PurchaseSummary";
import RecommendProduct from "components/mobile-components/Home/RecommendProduct";
import NavigationBar from "components/mobile-components/NavigationBar";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setLocation, setWarning } from "redux/container/userSlice";
import Brand from "services/Brand";
import Department from "services/Department";
import Product from "services/Product";
import Supplier from "services/Supplier";
import Modal from "react-modal";
import Cancel from "assets/images/icon/svg/Cancel";
import Orders from "services/Orders";
import moment from "moment";
import { getUserBusiness } from "config/user";

import TopNavigation from "components/mobile-components/Common/TopNavigation";
import PurchaseHistory from "components/mobile-components/Home/PurchaseHistory";
import RecommendCard from "components/mobile-components/Home/RecommendCard";
import NearSupplierCard from "components/mobile-components/Home/NearSupplierCard";
import RecommendedProductCard from "components/mobile-components/Home/RecommendedProductCard";
import { useLocation, useNavigate } from "react-router-dom";

import BrandCard2 from "components/mobile-components/Home/BrandCard2";
import NewProductCard from "components/mobile-components/Home/NewProductCard";
import OfferCard from "components/mobile-components/Home/OfferCard";
import LatestNews from "components/mobile-components/Home/LatestNews";
import BlogData from "services/Blog";
import NavFooter from "components/mobile-components/Common/NavFooter";
import { setUnreadCount } from "redux/container/notificationSlice";
import Notification from "services/Notification";
import { getLanguage } from "config/language";
import TransactionHistory from "components/mobile-components/Home/TransactionHistory";
import Accounts from "services/Accounts";
import TopMenu from "components/mobile-components/Home/TopMenu";

function Home() {
	const warning = useSelector((state) => state.user.warning);
	const contentRef = useRef();
	const [initHeight] = useState(72);
	const localLanguage = getLanguage();
	const [initStickyClass, setInitStikyClass] = useState("");
	const [menuCollapse, setMenuCollapse] = useState(true);
	const [brandData, setBrandData] = useState("");
	const [supplierData, setSupplierData] = useState("");
	const [mySupplierData, setMySupplierData] = useState("");
	const [offerData, setOfferData] = useState([]);
	const [products, setProduct] = useState([]);
	const [pageCount, setPageCount] = useState(2);
	const [productCount, setProductCount] = useState();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [cardLoading, setCardLoading] = useState(false);
	const dispatch = useDispatch();
	const [departmentList, setDepartmentList] = useState([]);
	const [isOpen, setIsOpen] = useState(false);
	let [todayPurchase, setTodayPurchase] = useState();
	let [todayTransaction, setTodayTransaction] = useState(null);
	let [transLoading, setTransLoading] = useState(false);
	let [orderAnalytics, setOrderAnalytics] = useState();
	const [blogList, setBlogList] = useState([]);
	const [page, setPage] = useState(1);
	const business = getUserBusiness();

	let now = moment().utc();
	let startTime = now.startOf("day").toString();
	let endTime = now.endOf("day").toString();

	//warning modal functions
	const onCloseModal = () => {
		dispatch(setWarning(false));
		setIsOpen(false);
	};

	const menuIconClick = () => {
		setMenuCollapse(!menuCollapse);
	};

	//get Today Purcahse & Order Analytics
	useEffect(() => {
		let getTodayPurchaseSummery = async () => {
			if (business) {
				let response = await Orders.todayPurchaseSummery(
					startTime,
					endTime
				);
				if (response.status === 200) {
					setTodayPurchase(response.data);
				}
			}
		};

		let getOrderAnalytics = async () => {
			if (business) {
				let response = await Orders.orderAnalytics();
				if (response.status === 200) {
					setOrderAnalytics(response.data);
				}
			}
		};

		getTodayPurchaseSummery();
		getOrderAnalytics();
	}, [startTime, endTime]);

	useEffect(() => {
		let getTodayTransaction = async () => {
			setTransLoading(true);
			let response = await Accounts.getTodayTransaction();
			if (response.status === 200) {
				setTodayTransaction(response);
				setTransLoading(false);
			} else {
				setTransLoading(false);
			}
		};
		getTodayTransaction();
	}, []);

	//get Department List
	let getDepartments = async () => {
		let response = await Department.getDepartments();
		if (response.status === 200) {
			setDepartmentList(response.results);
		} else {
			setDepartmentList([]);
		}
	};
	useEffect(() => {
		getDepartments();
	}, []);

	//get offer productList
	let getOfferList = async () => {
		setCardLoading(true);
		let response = await Product.offerProduct();
		if (response.status === 200) {
			setCardLoading(false);
			setOfferData(response.results);
		} else {
			setCardLoading(false);
		}
	};
	useEffect(() => {
		getOfferList();
	}, []);

	//get brand productList
	let getBrandList = async () => {
		setCardLoading(true);
		let response = await Brand.getBrandList();
		if (response.status === 200) {
			setCardLoading(false);
			setBrandData(response.results);
		} else {
			setCardLoading(false);
		}
	};
	useEffect(() => {
		getBrandList();
	}, []);

	//get suppliers data
	let getSupplierList = async (lan, lot) => {
		setCardLoading(true);
		if (lan && lot) {
			let response = await Supplier.getSupplierList(lan, lot);
			if (response.status === 200) {
				setSupplierData(response.results);
				setCardLoading(false);
			} else {
				setCardLoading(false);
			}
		}
	};
	useEffect(() => {
		getSupplierList();
	}, []);

	// // get suppliers data
	// let getMySupplierList = async () => {
	// 	let response = await Supplier.getMySupplierList();
	// 	if (response.status === 200) {
	// 		const supplier = [];
	// 		response.data.forEach((element) => {
	// 			supplier.push(element.related_business);
	// 		});
	// 		setMySupplierData(supplier);
	// 	} else {
	// 	}
	// };
	// useEffect(() => {
	// 	getMySupplierList();
	// }, []);

	//get product data
	let getProductList = async () => {
		let response = await Product.productList();
		if (response.status === 200) {
			setProduct(response.results);
			setProductCount(response.count);
		} else {
		}
	};
	useEffect(() => {
		getProductList();
	}, []);
	let settings = {
		nameShow: false,
		titleShow: true,
		skuShow: false,
		priceShow: true,
		oldPriceShow: false,
		distShow: false,
		profitShow: true,
		sellPriceShow: false,
		supplierNameShow: false,
		link: "product-details",
	};
	let lodeMoreData = async () => {
		let response = await Product.productList(pageCount);
		if (response.status === 200) {
			if (response.results.length > 0) {
				setPageCount((prev) => prev + 1);
				setProduct([...products, ...response.results]);
			}
		}
	};
	async function searchNext(event) {
		let timeout = setTimeout(() => {
			lodeMoreData();
			// event.target.complete();
			clearTimeout(timeout);
		}, 1000);
	}

	useEffect(() => {
		const getLocation = () => {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(
					(position) => {
						const { latitude, longitude } = position.coords;
						getSupplierList(longitude, latitude);
						dispatch(
							setLocation({
								type: "Point",
								coordinates: [latitude, longitude],
							})
						);
					},
					(error) => {
						toast.error("Failed to get geolocation.");
					}
				);
			} else {
				toast.error("Geolocation is not supported by this browser.");
			}
		};

		getLocation();
	}, [dispatch]);

	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "300px",
			transition: ".4s all ease",
			borderRadius: 20,
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};

	//   Notification count api integration
	useEffect(() => {
		const getNotificationCount = async () => {
			if (business) {
				let unreadCount = await Notification.getNewUnreadCount(
					business.id,
					localLanguage === "bn" ? "BD" : "EN"
				);
				if (unreadCount.status === 200) {
					dispatch(setUnreadCount(unreadCount?.unread_count));
				}
			}
		};
		getNotificationCount();
	}, [dispatch]);

	return (
		<IonApp>
			<IonPage>
				<IonContent
					scrollEvents={true}
					ref={contentRef}
					onIonScroll={async (ev) => {
						const scrollPosition = ev.detail.scrollTop;
						if (scrollPosition > initHeight) {
							setInitStikyClass("issticky");
						} else {
							setInitStikyClass("");
						}
					}}>
					<TopNavigation
						menuIconClick={menuIconClick}
						sticky={initStickyClass}
					/>

					{/* <LeftSidebar
						isMobile={true}
						menuCollapse={menuCollapse}
						menuIconClick={menuIconClick}
					/> */}

					<section className='px-2 pt-11'>
						{/* <div className='px-2'>
							<Banner />
						</div> */}
						<div className='pt-8'>
							<TopMenu />
							{/* <Departments departmentList={departmentList} /> */}
						</div>
						<div
							className='my-5 mx-2 rounded-[7px] mb-4'
							style={{
								boxShadow: "0px 2px 30px 0px rgba(0, 0, 0, 0.10)",
							}}>
							<TransactionHistory
								transLoading={transLoading}
								todayPurchase={todayTransaction}
								ekkLanguage={ekkLanguage}
							/>
						</div>
						<div
							className='my-5 mx-2 rounded-[7px]'
							style={{
								boxShadow: "0px 2px 30px 0px rgba(0, 0, 0, 0.10)",
							}}>
							<PurchaseHistory
								todayPurchase={todayPurchase}
								orderAnalytics={orderAnalytics}
							/>
						</div>
					</section>
					<div className='pl-4'>
						{/* <RecommendCard /> */}
						<NearSupplierCard slideData={supplierData} />
						{/* <NewProductCard slideData={products} /> */}
						{/* {brandData?.length > 0 && (
							<BrandCard2 slideData={brandData} />
						)} */}
						{offerData?.length > 0 && <OfferCard slideData={offerData} />}
						{/* <RecommendedProductCard slideData={products} /> */}
						{/* <LatestNews slideData={blogList} /> */}
					</div>

					<Modal
						closeTimeoutMS={200}
						isOpen={isOpen}
						onRequestClose={onCloseModal}
						id='warning-modal'
						style={customStyles}
						ariaHideApp={false}>
						<div className='p-5 pt-10'>
							<span
								className='mb-2 inline-block mx-2 absolute right-5 top-5 cursor-pointer'
								onClick={onCloseModal}>
								<Cancel />
							</span>
							<img
								src='assets/images/warning.png'
								alt='warning'
								className='m-auto mb-5'
							/>

							<p className='text-18 font-medium pb-[10px] text-black-500 text-center'>
								{ekkLanguage.home.warning}
							</p>
						</div>
					</Modal>
				</IonContent>
				<NavFooter />
			</IonPage>
		</IonApp>
	);
}
// export default withLocation(Home);
export default Home;
