import {
	IonButton,
	IonCol,
	IonGrid,
	IonInput,
	IonItem,
	IonRow,
	useIonAlert,
} from "@ionic/react";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import {
	getCreateUserBusiness,
	getLocalUser,
	setCreateUserBusiness,
} from "config/user";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Business from "services/Business";

export default function IndustryType() {
	const [businessIndustryId, setBusinessIndustryId] = useState(
		getCreateUserBusiness()?.primary_industry
	);
	const [industry, setIndustry] = useState([]);
	const [typeLoading, setTypeLoading] = useState(false);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const navigate = useNavigate();

	function setBusinessType(industry) {
		const lBusiness = getCreateUserBusiness();
		setBusinessIndustryId(industry?.id);
		setCreateUserBusiness({
			...lBusiness,
			primary_industry: industry.id,
		});
	}

	const getIndustryType = async () => {
		setTypeLoading(true);
		let response = await Business.getIndustryList();
		if (response.status === 200) {
			setIndustry(response?.results);
			setTypeLoading(false);
		} else {
			setTypeLoading(false);
		}
	};

	useEffect(() => {
		getIndustryType();
	}, []);

	return (
		<div className=' business-name px-2 pt-14'>
			<IonGrid>
				<IonRow>
					<IonCol size='12'>
						<div className='pt-5 text-center'>
							<h3 className='font-bold text-24 text-center mb-7'>
								{ekkLanguage?.industryType.title}
							</h3>
							<div className='text-left'>
								<ul className='flex flex-wrap'>
									{industry?.length
										? industry?.map((el, i) => {
												return (
													<li
														key={i}
														className='cursor-pointer mb-6'
														onClick={() => setBusinessType(el)}>
														<p
															className={`text-22 ${
																el?.id === businessIndustryId
																	? "text-white bg-00B224-1000 font-bold"
																	: "text-00B224-1000 font-semibold"
															}  h-10 border-2 border-00B224-1000 rounded-full inline-flex items-center px-4 py-[6px] mr-[25px]`}>
															{el?.title}
															<img
																className='w-6 h-6 ml-2'
																src={`${
																	el?.id === businessIndustryId
																		? el?.active_image
																		: el?.image
																}`}
																alt='icon'
															/>
														</p>
													</li>
												);
										  })
										: undefined}
								</ul>
							</div>
							<div className='pt-[100px] mb-5'>
								<IonButton
									color='success'
									className='font-bold h-[50px] w-full '
									onClick={() => {
										if (businessIndustryId) {
											navigate("/business-type");
										} else {
											toast.warning(
												ekkLanguage.businessType.validationLabel
											);
										}
									}}>
									<span className='text-white'>
										{" "}
										{ekkLanguage.businessCreateName.btnLabel}
									</span>

									<span className='pl-2'>
										<ArrayRight />
									</span>
								</IonButton>
							</div>
						</div>
					</IonCol>
				</IonRow>
			</IonGrid>
		</div>
	);
}
