import { createSlice } from "@reduxjs/toolkit";

export const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    singleNotification: {},
    data: [],
    unreadCount: 0,
  },
  reducers: {
    singleNotification: (state, action) => {
      state.singleNotification = action.payload;
    },
    setUnreadCount: (state, action) => {
      state.unreadCount = action.payload;
    },
    setNotification: (state, action) => {
      state.data = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { singleNotification, setUnreadCount, setNotification } =
  notificationSlice.actions;
export const { actions, reducer } = notificationSlice;
export default reducer;
