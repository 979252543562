import {
  IonApp,
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonLoading,
  IonPage,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import NavFooter from "components/mobile-components/Common/NavFooter";
import Learning from "components/mobile-components/Learning";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPostList } from "redux/container/learningSlice";
import Blog from "services/Blog";

import { getUser } from "config/user";
import { getLanguage } from "config/language";

export default function LearningPage() {
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(2);
  const dispatch = useDispatch();
  const learningList = useSelector((state) => state.learning.postList);
  const user = getUser();
  const localLanguage = getLanguage();
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [reachedEnd, setReachedEnd] = useState(false);

  const getLearningList = async () => {
    let response = await Blog.learningList(
      1,
      9,
      localLanguage === "bn" ? "BN" : "EN"
    );
    if (response.status === 200) {
      dispatch(setPostList(response?.results));
      setLoading(false);
    }
  };

  let loadMoreData = async () => {
    if (hasMoreData) {
      let response = await Blog.learningList(
        pageCount,
        9,
        localLanguage === "bn" ? "BN" : "EN"
      );
      if (response.status === 200) {
        if (response?.results?.length > 0) {
          setPageCount((prev) => prev + 1);
          dispatch(setPostList([...learningList, ...response?.results]));
        } else {
          setHasMoreData(false);
        }
      } else {
        setHasMoreData(false);
      }
    }
  };

  useEffect(() => {
    getLearningList();
  }, []);

  return (
    <IonApp style={{ "--ion-background-color": "#E6E5E5" }}>
      <IonPage>
        <IonContent scrollEvents={true}>
          <BackButtonTitle title={ekkLanguage.learning.pageTitle} />
          {loading && <IonLoading isOpen={true} />}
          <section className="pt-14">
            <Learning
              ekkLanguage={ekkLanguage}
              learningList={learningList}
            ></Learning>
          </section>
          <IonInfiniteScroll
            onIonInfinite={(ev) => {
              if (reachedEnd) {
                ev.target.complete();
                return;
              }
              loadMoreData();
              setTimeout(() => {
                ev.target.complete();
                if (!hasMoreData) {
                  setReachedEnd(true);
                }
              }, 2000);
            }}
          >
            <IonInfiniteScrollContent
              loadingText={ekkLanguage.orderList.loader}
              loadingSpinner={hasMoreData ? "bubbles" : null}
            ></IonInfiniteScrollContent>
          </IonInfiniteScroll>
        </IonContent>

        {/* Navigation Footer  */}
        <NavFooter />
      </IonPage>
    </IonApp>
  );
}
