import React, { useState } from "react";
import Card from "../Common/Card";
import "moment-timezone";
import { useSelector } from "react-redux";
import { digitChanger, numberWithCommas } from "helpers/helper";
import moment from "moment-timezone";
import Modal from "react-modal";
import Cross from "assets/images/icon/svg/Cross";
import { COUNTRYINFO } from "data/country";
import { getUserBusiness } from "config/user";
import { Link } from "react-router-dom";
export default function TranDetailsCard({ collection }) {
	const [confirmModal, setConfirmModal] = useState(false);
	const closeModal = () => setConfirmModal(false);
	const openModal = () => setConfirmModal(true);
	const [selectedImage, setSelectedImage] = useState(null);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const { createUserData } = useSelector((state) => state.user);
	const dateFormat = useSelector((state) => state.date.format);
	const business = getUserBusiness();

	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "350px",
			transition: ".4s all ease",
			borderRadius: 12,
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};

	return (
		<div className='px-2 pb-5'>
			<Card
				title={`${
					collection?.acc_group?.title === "Expense"
						? "Expense"
						: "Revenue"
				} Details`}>
				<div>
					<table className='table-fixed w-full paymentSettlementLabel'>
						<tbody className="before:content-[' '] before:block before:h-2 border-b-2">
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{collection?.acc_group?.title === "Expense"
										? "Expense"
										: "Revenue"}{" "}
									Date
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{moment(collection?.date).format(dateFormat)}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									Category
									{/* {ekkLanguage.accounting.category} */}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{collection?.acc_category?.title}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									Description
									{/* {ekkLanguage.accounting.description} */}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{collection?.description}
								</td>
							</tr>
							{collection?.acc_group?.title === "Revenue" && (
								<>
									{business.role === "Admin" ? (
										<>
											<tr>
												<td className='text-000080 text-12 font-medium py-[6px]'>
													Service Provider Amount
												</td>
												<td
													className={`${
														collection?.service_amount >= 0
															? "text-000080"
															: "text-primary"
													} text-12 font-medium py-[6px] text-right`}>
													{
														COUNTRYINFO?.[createUserData?.country]
															?.currency
													}
													{numberWithCommas(
														collection?.service_amount
													)}
												</td>
											</tr>

											<tr>
												<td className='text-000080 text-12 font-medium py-[6px]'>
													Net Revenue Earned
												</td>
												<td
													className={`${
														collection?.net_revenue >= 0
															? "text-000080"
															: "text-primary"
													} text-12 font-medium py-[6px] text-right`}>
													{
														COUNTRYINFO?.[createUserData?.country]
															?.currency
													}
													{numberWithCommas(
														collection?.net_revenue
													)}
												</td>
											</tr>
										</>
									) : undefined}

									<tr>
										<td className='text-000080 text-12 font-medium py-[6px]'>
											Revenue Amount
										</td>
										<td
											className={`${
												collection?.amount > 0
													? "text-000080"
													: "text-primary"
											} text-12 font-medium py-[6px] text-right`}>
											{
												COUNTRYINFO?.[createUserData?.country]
													?.currency
											}
											{numberWithCommas(collection?.amount)}
										</td>
									</tr>

									<tr>
										<td className='text-000080 text-12 font-medium py-[6px]'>
											Tax Amount
										</td>
										<td
											className={`${
												collection?.tax >= 0
													? "text-000080"
													: "text-primary"
											} text-12 font-medium py-[6px] text-right`}>
											{
												COUNTRYINFO?.[createUserData?.country]
													?.currency
											}
											{numberWithCommas(collection?.tax ?? 0)}
										</td>
									</tr>

									<tr>
										<td className='text-000080 text-12 font-medium py-[6px]'>
											Total Collected Amount
										</td>
										<td
											className={`${
												collection?.total_collected >= 0
													? "text-000080"
													: "text-primary"
											} text-12 font-medium py-[6px] text-right`}>
											{
												COUNTRYINFO?.[createUserData?.country]
													?.currency
											}
											{numberWithCommas(
												collection?.total_collected ?? 0
											)}
										</td>
									</tr>
								</>
							)}
							{collection?.acc_group?.title === "Expense" ? (
								<tr>
									<td className='text-000080 text-12 font-medium py-[6px]'>
										Expense Amount
									</td>
									<td className='text-000080 text-12 font-medium py-[6px] text-right'>
										{COUNTRYINFO?.[createUserData?.country]?.currency}
										{numberWithCommas(collection?.amount)}
									</td>
								</tr>
							) : undefined}

							{collection?.acc_group?.title === "Expense" && (
								<>
									<tr>
										<td className='text-000080 text-12 font-medium py-[6px]'>
											Supplier
										</td>
										<td className='text-000080 text-12 font-medium py-[6px] text-right'>
											{collection?.seller_name}
										</td>
									</tr>
									<tr>
										<td className='text-000080 text-12 font-medium py-[6px]'>
											{/* {ekkLanguage.accounting.alreadyPaid} */}
											Expense Already paid?
										</td>
										<td className='text-000080 text-12 font-medium py-[6px] text-right'>
											{collection?.is_paid ? "Paid" : "Not Paid"}
										</td>
									</tr>
									{!collection?.is_paid && (
										<tr>
											<td className='text-000080 text-12 font-medium py-[6px]'>
												{ekkLanguage.accounting.onto}
											</td>
											<td className='text-000080 text-12 font-medium py-[6px] text-right'>
												{moment(collection?.paid_date).format(
													dateFormat
												)}
											</td>
										</tr>
									)}
								</>
							)}
						</tbody>
					</table>
				</div>
			</Card>
			<div className='mt-5'>
				<Card title={`Images`}>
					<div>
						<div className='pt-4 grid grid-cols-1 sm:grid-cols-2 gap-1'>
							{collection?.images?.length ? (
								collection?.images?.map((el, i) => {
									return (
										<>
											<div
												key={i}
												className='relative aspect-square rounded-lg overflow-hidden shadow-sm hover:opacity-90 transition-opacity'
												onClick={() => {
													setSelectedImage(el);
													openModal();
												}}>
												<img
													className='w-full h-full object-cover cursor-pointer'
													src={el}
													alt={`Transaction ${i + 1}`}
												/>
												<div className='absolute inset-0 bg-black/10 opacity-0 hover:opacity-100 transition-opacity'></div>
											</div>
										</>
									);
								})
							) : (
								<h5 className='text-12'>Image not found</h5>
							)}
						</div>
					</div>
				</Card>
			</div>
			<div className='mt-5'>
				<Card title={`Others Details`}>
					<div>
						<table className='table-fixed w-full paymentSettlementLabel'>
							<tbody className="before:content-[' '] before:block before:h-2 border-b-2">
								<tr>
									<td className='text-000080 text-12 font-medium py-[6px]'>
										Created By
									</td>
									<td className='text-000080 text-12 font-medium py-[6px] text-right'>
										<Link
											className='text-delivered underline'
											to={`/employee-details/${collection?.created_by}?from=dashboard`}>
											{collection?.created_by_name}
										</Link>
									</td>
								</tr>
								<tr>
									<td className='text-000080 text-12 font-medium py-[6px]'>
										Created On
									</td>
									<td className='text-000080 text-12 font-medium py-[6px] text-right'>
										{moment(collection?.createdAt).format(dateFormat)}
									</td>
								</tr>
								<tr>
									<td className='text-000080 text-12 font-medium py-[6px]'>
										Updated By
									</td>
									<td className='text-000080 text-12 font-medium py-[6px] text-right'>
										{collection?.updated_by_name ?? "N/A"}
									</td>
								</tr>
								<tr>
									<td className='text-000080 text-12 font-medium py-[6px]'>
										Updated On
									</td>
									<td className='text-000080 text-12 font-medium py-[6px] text-right'>
										{moment(collection?.updatedAt).format(dateFormat)}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</Card>
			</div>
			<Modal
				style={customStyles}
				closeTimeoutMS={200}
				isOpen={confirmModal}
				onRequestClose={closeModal}
				classNames='bottom-sheet-modal'>
				<div
					className='absolute right-2 top-2 cursor-pointer'
					onClick={() => {
						closeModal();
					}}>
					<Cross />
				</div>
				<div className='p-5 pt-8'>
					<img src={selectedImage} alt='images' />
				</div>
			</Modal>
		</div>
	);
}
