import React from "react";

export default function Favorite({ color = "#A6A6A6" }) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='22'
			height='22'
			viewBox='0 0 22 22'
			style={{ margin: "auto" }}
			fill='none'>
			<g id='Iconly/Light-Outline/Heart'>
				<g id='Heart'>
					<g id='Group 3'>
						<mask
							id='mask0_3257_913'
							maskUnits='userSpaceOnUse'
							x='1'
							y='2'
							width='20'
							height='19'>
							<path
								id='Clip 2'
								fillRule='evenodd'
								clipRule='evenodd'
								d='M1.83301 2.74988H20.5995V20.6258H1.83301V2.74988Z'
								fill='white'
							/>
						</mask>
						<g mask='url(#mask0_3257_913)'>
							<path
								id='Fill 1'
								fillRule='evenodd'
								clipRule='evenodd'
								d='M3.50497 11.1127C4.79013 15.1112 9.86755 18.3443 11.2169 19.1446C12.5708 18.3361 17.6849 15.0672 18.9288 11.1164C19.7456 8.56257 18.9875 5.32765 15.9753 4.3569C14.516 3.88849 12.8137 4.17357 11.6386 5.0829C11.3929 5.27174 11.0519 5.2754 10.8044 5.0884C9.55955 4.15249 7.93339 3.8784 6.45114 4.3569C3.44355 5.32674 2.68822 8.56165 3.50497 11.1127ZM11.2178 20.6259C11.1041 20.6259 10.9914 20.5984 10.8887 20.5425C10.6018 20.3857 3.84322 16.6604 2.19597 11.5326C2.19505 11.5326 2.19505 11.5317 2.19505 11.5317C1.16105 8.30315 2.31238 4.24599 6.02947 3.0479C7.7748 2.48324 9.67689 2.73165 11.2151 3.7024C12.7056 2.76007 14.6856 2.49974 16.3961 3.0479C20.1168 4.24782 21.2718 8.30407 20.2387 11.5317C18.6446 16.6008 11.8366 20.3821 11.5478 20.5407C11.4451 20.5975 11.3315 20.6259 11.2178 20.6259Z'
								fill={color}
							/>
						</g>
					</g>
					<path
						id='Fill 4'
						fillRule='evenodd'
						clipRule='evenodd'
						d='M16.6405 9.73948C16.2857 9.73948 15.9851 9.46723 15.9557 9.1079C15.8952 8.3544 15.3911 7.71823 14.6733 7.48631C14.3112 7.36898 14.1132 6.98123 14.2296 6.62098C14.3479 6.25981 14.732 6.06365 15.095 6.17731C16.3444 6.58156 17.2198 7.68798 17.3271 8.99606C17.3573 9.37465 17.0759 9.70648 16.6973 9.73673C16.6781 9.73856 16.6597 9.73948 16.6405 9.73948Z'
						fill={color}
					/>
				</g>
			</g>
		</svg>
	);
}
