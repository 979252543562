import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BazAi from "components/mobile-components/BazAI";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import NavFooter from "components/mobile-components/Common/NavFooter";
import BazAiApi from "services/BazAi";
import {
	setLoadMessage,
	setPage,
	setThreadId,
} from "redux/container/bazAiSlice";
import moment from "moment";
import { getLocalUser, getUser } from "config/user";
import { IonApp, IonContent, IonPage } from "@ionic/react";

function TeamChatPage() {
	

	return (
		<IonApp>
			<IonPage>
				<IonContent scrollEvents={true}>
					<BackButtonTitle title='Team' />
                            <div className="mt-14">
                                <h1 className="text-center text-2xl font-bold pt-20">Coming Soon...</h1>
                            </div>
				</IonContent>
				<NavFooter />
			</IonPage>
		</IonApp>
	);
}

export default TeamChatPage;
