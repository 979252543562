import { IonButton, IonLoading, useIonAlert } from "@ionic/react";
import CancelPopUp from "assets/images/icon/svg/CancelPopUp";
import { getUserBusiness } from "config/user";
import { digitChanger, numberWithCommas, translator } from "helpers/helper";
import { shoppingListItem } from "helpers/shoppingCart";
import moment from "moment";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { clearPaymentCard } from "redux/container/paymentMethodSlice";
import Orders from "services/Orders";

export default function OrderTotalCard() {
	const [loader, setLoader] = useState(false);
	const productCart = useSelector((state) => state.cart);
	const paymentInfo = useSelector((state) => state.payment);
	const dispatch = useDispatch();
	const totalAmount = useSelector((state) => state.cart.total);
	const creditFee = useSelector((state) => state.payment.creditFee);
	const dueDate = moment().add(Number(paymentInfo.paymentTerm), "days");
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [isOpen, setIsOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [presentAlert] = useIonAlert();
	const navigate = useNavigate();
	const [isLessMoq, setIsLessMoq] = useState(false);
	const [deliveryDateModal, setDeliveryDateModal] = useState(false);
	const [paymentMethodModal, setPaymentMethodModal] = useState(false);
	const [paymentTermsModal, setPaymentTermsModal] = useState(false);
	const productList = shoppingListItem();

	const onOpenModal = () => setIsOpen(true);
	const onCloseModal = () => setIsOpen(false);
	const onOpenMoqModal = () => setIsLessMoq(true);
	const onCloseMoqModal = () => setIsLessMoq(false);
	const onOpenDeliveryDateModal = () => setDeliveryDateModal(true);
	const onCloseDeliveryDateModal = () => setDeliveryDateModal(false);
	const onOpenPaymentMethodModal = () => setPaymentMethodModal(true);
	const onClosePaymentMethodModal = () => setPaymentMethodModal(false);
	const onOpenPaymentTermsModal = () => setPaymentTermsModal(true);
	const onClosePaymentTermsModal = () => setPaymentTermsModal(false);

	// Create New function for order creations
	async function createNewOrder() {
		setLoader(true);
		const productList = shoppingListItem();

		if (productList.length !== 0) {
			setLoading(true);
			const data = {
				buyer: getUserBusiness().id,
				order_items: productList,
				price_adjustment: 0,
				seller: productList[0].seller_business_pg_id,
				payment_method: paymentInfo.paymentMethod,
				payment_term: {
					name: paymentInfo.paymentName,
					unit: "day",
					duration: Number(paymentInfo.paymentTerm),
					total: totalAmount,
					creditFee: creditFee,
					paymentDueDate: dueDate,
				},
				delivery_date: paymentInfo.deliveryDate
					? paymentInfo.deliveryDate
					: moment(new Date()).add(1, "days"),
				order_origin: "EkkBaz",
				business_direct_order: true,
			};

			if (paymentInfo.paymentTerm !== "") {
				if (paymentInfo.paymentMethod !== "") {
					try {
						const response = await Orders.createOrder(data);
						if (response.status === 200 || response.success) {
							setLoader(false);
							localStorage.removeItem("cart");
							localStorage.removeItem("ps");
							navigate(`/success-order/${response.data.order_id}`);
							dispatch(clearPaymentCard());
						} else {
							setLoader(false);
						}
					} catch (error) {
						console.log(error);
					}
				} else {
					onOpenPaymentMethodModal();
				}
			} else {
				onOpenPaymentTermsModal();
			}

			setLoading(false);
		} else {
			console.log("sdsdd");
		}
	}
	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "300px",
			transition: ".4s all ease",
			borderRadius: 12,
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};
	return (
		<>
			{loading ? (
				<IonLoading
					isOpen={loading}
					message={ekkLanguage.createOrder.loader}
				/>
			) : (
				""
			)}

			<div
				className='bg-white fixed bottom-0 left-0 w-full p-2 pb-7 pt-4 px-4'
				style={{ boxShadow: "0px -2px 15px rgba(0, 0, 0, 0.1)" }}>
				<div className='flex justify-between pb-2'>
					<div>
						<p className='text-black-500 font-bold text-12'>
							{ekkLanguage.createOrder.creditFee}
						</p>
						<p className='text-black-500 font-bold text-12'>
							{productList[0]?.country?.currency_symbol_native}
							{digitChanger(numberWithCommas(creditFee))}
						</p>
					</div>
					<div className='flex items-center'>
						<h6 className='text-222222 font-bold text-18 pr-3'>
							{ekkLanguage.createOrder.total}
						</h6>
						<h6 className='text-222222 font-bold text-22'>
							{productList[0]?.country?.currency_symbol_native}
							{digitChanger(
								numberWithCommas(
									productCart?.total + productCart?.totalTax
								)
							)}
						</h6>
					</div>
				</div>
				<div>
					<IonButton
						style={{ boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.15)" }}
						expand='block'
						className='bg-success rounded-xl h-12 font-sans font-bold text-24'
						onClick={() => {
							if (paymentInfo.paymentTerm !== "") {
								if (paymentInfo.paymentMethod !== "") {
									const productList = shoppingListItem();
									let lessThenMoq = false;
									productList.find((el) => {
										if (el.minimum_order_quantity > el.quantity) {
											lessThenMoq = true;
										}
									});

									if (!lessThenMoq) {
										onOpenModal();
									} else {
										onOpenMoqModal();
									}
								} else {
									onOpenPaymentMethodModal();
								}
							} else {
								onOpenPaymentTermsModal();
							}
						}}>
						{ekkLanguage.createOrder.orderNow}
					</IonButton>
					{/* </Link> */}
				</div>
			</div>
			{/* order conformation modal*/}
			<Modal
				style={customStyles}
				closeTimeoutMS={200}
				isOpen={isOpen}
				onRequestClose={onCloseModal}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2'>
						<CancelPopUp />
					</span>
					<h6 className='text-16 font-bold pb-[10px]'>
						{ekkLanguage.createOrder.placeConformationMessage}
					</h6>

					<div className='flex'>
						<button
							className='border-2 font-bold h-10 rounded-[7px] w-1/2 mr-2 text-black-1000 border-black-200 border-solid'
							onClick={onCloseModal}>
							{ekkLanguage.orderDetails.noBtn}
						</button>
						<button
							color='primary'
							className={`${
								loader && "select-none cursor-no-drop"
							} font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-primary text-white flex justify-center items-center`}
							onClick={!loader && createNewOrder}>
							{loader ? (
								<ClipLoader
									sizeUnit={"px"}
									size={20}
									color={"white"}
									loading={true}
								/>
							) : (
								ekkLanguage.orderDetails.yesBtn
							)}
						</button>
					</div>
				</div>
			</Modal>

			{/* Less MOQ modal*/}
			<Modal
				style={customStyles}
				closeTimeoutMS={200}
				isOpen={isLessMoq}
				onRequestClose={onCloseModal}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2'>
						<CancelPopUp />
					</span>
					<h6 className='text-16 font-bold pb-[20px]'>
						{ekkLanguage.createOrder.lessThenMoq}
					</h6>

					<div className='flex justify-end'>
						<button
							color='primary'
							className='font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-primary text-white'
							onClick={onCloseMoqModal}>
							{ekkLanguage.createOrder.okBtnLabel}
						</button>
					</div>
				</div>
			</Modal>

			{/* Delivery date conformation modal*/}
			<Modal
				style={customStyles}
				closeTimeoutMS={200}
				isOpen={deliveryDateModal}
				onRequestClose={onCloseDeliveryDateModal}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2'>
						<CancelPopUp />
					</span>
					<h6 className='text-16 font-bold pb-[20px]'>
						{ekkLanguage.createOrder.importentMessage}
					</h6>
					<h6 className='text-16 font-semibold pb-[20px]'>
						{ekkLanguage.createOrder.deliveryDateMessage}
					</h6>

					<div className='flex justify-end'>
						<button
							color='primary'
							className='font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-primary text-white'
							onClick={onCloseDeliveryDateModal}>
							{ekkLanguage.createOrder.okBtnLabel}
						</button>
					</div>
				</div>
			</Modal>

			{/* Payment Method conformation modal*/}
			<Modal
				style={customStyles}
				closeTimeoutMS={200}
				isOpen={paymentMethodModal}
				onRequestClose={onClosePaymentMethodModal}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2'>
						<CancelPopUp />
					</span>
					<h6 className='text-16 font-bold pb-[20px]'>
						{ekkLanguage.createOrder.importentMessage}
					</h6>
					<h6 className='text-16 font-semibold pb-[20px]'>
						{ekkLanguage.createOrder.paymentMethodMessage}
					</h6>

					<div className='flex justify-end'>
						<button
							color='primary'
							className='font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-primary text-white'
							onClick={onClosePaymentMethodModal}>
							{ekkLanguage.createOrder.okBtnLabel}
						</button>
					</div>
				</div>
			</Modal>

			{/* Payment Terms conformation modal*/}
			<Modal
				style={customStyles}
				closeTimeoutMS={200}
				isOpen={paymentTermsModal}
				onRequestClose={onClosePaymentTermsModal}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2'>
						<CancelPopUp />
					</span>
					<h6 className='text-16 font-bold pb-[20px]'>
						{ekkLanguage.createOrder.importentMessage}
					</h6>
					<h6 className='text-16 font-semibold pb-[20px]'>
						{ekkLanguage.createOrder.paymentTermsMessage}
					</h6>

					<div className='flex justify-end'>
						<button
							color='primary'
							className='font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-primary text-white'
							onClick={onClosePaymentTermsModal}>
							{ekkLanguage.createOrder.okBtnLabel}
						</button>
					</div>
				</div>
			</Modal>
		</>
	);
}
