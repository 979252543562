import LocationEnabled from "components/mobile-components/Business/LocationEnabled";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setLocation, setMapLocation } from "redux/container/userSlice";
import Geocode from "react-geocode";
import { getCreateUserBusiness, setCreateUserBusiness } from "config/user";

export default function withLocation(OrginalComponent) {
	//get city
	const getCity = (addressArray) => {
		let city = "";
		for (let i = 0; i < addressArray.length; i++) {
			if (
				addressArray[i].types[0] &&
				"administrative_area_level_2" === addressArray[i].types[0]
			) {
				city = addressArray[i].long_name;
				return city;
			}
		}
	};

	//get area
	const getArea = (addressArray) => {
		let area = "";
		for (let i = 0; i < addressArray.length; i++) {
			if (addressArray[i].types[0]) {
				for (let j = 0; j < addressArray[i].types.length; j++) {
					if (
						"sublocality_level_1" === addressArray[i].types[j] ||
						"locality" === addressArray[i].types[j]
					) {
						area = addressArray[i].long_name;
						return area;
					}
				}
			}
		}
	};

	//get state
	const getState = (addressArray) => {
		let state = "";
		for (let i = 0; i < addressArray.length; i++) {
			for (let i = 0; i < addressArray.length; i++) {
				if (
					addressArray[i].types[0] &&
					"administrative_area_level_1" === addressArray[i].types[0]
				) {
					state = addressArray[i].long_name;
					return state;
				}
			}
		}
	};

	function GeneratedComponent() {
		let dispatch = useDispatch();
		let location = useSelector((state) => state.user.location);

		useEffect(() => {
			if (navigator.geolocation) {
				let location = navigator.geolocation;
				if (location && Object.keys(location).length === 0) {
					navigator.geolocation.getCurrentPosition((p) => {
						dispatch(
							setLocation({
								type: "Point",
								coordinates: [p.coords.latitude, p.coords.longitude],
							})
						);
						Geocode.fromLatLng(
							p.coords.latitude,
							p.coords.longitude
						).then(
							(response) => {
								const addressArray =
										response.results[0].address_components,
									city = getCity(addressArray),
									area = getArea(addressArray),
									state = getState(addressArray);
								let locationBuilder = {
									city,
									area,
									state,
									lat: p.coords.latitude,
									lng: p.coords.longitude,
									address: `${city} ${area} ${state}`,
								};
								const business = getCreateUserBusiness();
								business.long_lat = {
									type: "Point",
									coordinates: [p.coords.longitude, p.coords.latitude],
								};
								setCreateUserBusiness(business);
								// dispatch(setMapLocation(locationBuilder));
							},
							(error) => {
								console.error(error);
							}
						);
					});
				}
			} else {
				toast.er("Geolocation is not supported by this browser.");
			}
		}, []);

		return location ? <OrginalComponent /> : <LocationEnabled />;
	}

	return GeneratedComponent;
}
