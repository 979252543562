import { IonSpinner } from "@ionic/react";
import RightArrowThin from "assets/images/icon/svg/RightArrowThin";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Bar, getElementAtEvent, getElementsAtEvent } from "react-chartjs-2";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Accounts from "services/Accounts";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";
import { getSelectedDate } from "redux/container/dashboardSlice";
ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

export default function CombineTransChart({}) {
	const [transactionData, setTransactionData] = useState([]);
	const [transactionLoader, setTransactionLoader] = useState(false);
	const dateFormat = useSelector((state) => state.date.format);
	const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const screenSize = window.matchMedia("(max-width: 400px)")?.matches;
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const transactionChartData = {
		labels: transactionData?.map((item) =>
			moment(item?.date, "YYYY-MM-DD").format(dateFormat)
		),
		datasets: [
			{
				label: "Revenue",
				data: transactionData?.map((item) => item?.revenue),
				backgroundColor: "#00B224",
			},
			{
				label: "Expense",
				data: transactionData?.map((item) => item?.expense),
				backgroundColor: "#FB766E",
			},
		],
	};
	const getTransactionData = async (start, end) => {
		let accountType = await Accounts.getAccountType("Revenue");

		if (accountType.success) {
			setTransactionLoader(true);
			//time range also acceptable
			let response = await Accounts.getCombineTransactionDate(timeZone);
			if (response.status === 200) {
				setTransactionLoader(false);
				setTransactionData(response.results);
			} else {
				setTransactionLoader(false);
			}
		}
	};
	useEffect(() => {
		getTransactionData();
	}, []);

	const options = {
		responsive: true,
		plugins: {
			legend: {
				position: "bottom",
			},
		},
	};

	const revenueExpenseBarRef = useRef();
	const barClickHandler = (event, type) => {
		const chartConfigs = {
			revenueExpense: {
				ref: revenueExpenseBarRef,
				data: transactionData,
				path: "revenue-expense-parts",
			},
		};
		const config = chartConfigs[type];
		if (!config) return;

		const clickedElement = getElementAtEvent(config.ref.current, event)[0];

		if (!clickedElement) return;

		const elementIndex = clickedElement.index;
		const datasetIndex = clickedElement.datasetIndex;
		const value = config.data[elementIndex];
		if (!value) return;

		const startDay = moment(value?.date, "YYYY-MM-DD")
			.startOf("day")
			.format("YYYY-MM-DD");
		const endDay = moment(value?.date, "YYYY-MM-DD")
			.endOf("day")
			.format("YYYY-MM-DD");

		const dateTime = {
			start: startDay,
			end: endDay,
			level: "today",
		};
		dispatch(getSelectedDate(dateTime));
		navigate(
			`/${config.path}?state=${startDay}&end=${endDay}&type=${
				datasetIndex === 0 ? "Revenue" : "Expense"
			}`
		);
	};

	return (
		// <div className='purchase-summary relative'>
		<div className='relative py-2'>
			<div className='chart-wrapper relative'>
				{transactionLoader ? (
					<div className='absolute h-full w-full flex justify-center items-center left-[7%] top-[5%]'>
						<IonSpinner name='circular'></IonSpinner>
					</div>
				) : undefined}
				{/* <div className='flex justify-between items-center'>
					
					<div className='flex justify-end items-center mt-1 absolute top-1 right-2 z-20'>
						<Link
							to='/accounts-dashboard'
							className='text-0086B2 text-12 font-normal flex items-center'>
						
							<span className='ml-2'>
								<RightArrowThin />
							</span>
						</Link>
					</div>
				</div> */}
				<div className=' pb-4 flex justify-between items-center'>
					<h4 className='font-bold text-black-1000 text-18 sm:text-20'>
						Revenue & Expense
					</h4>
					<h3 className='font-bold text-black-500 text-14'>
						Daily
					</h3>
				</div>

				<div
					className='flex justify-center'
					style={{
						width: "100%",
						maxWidth: "768px",
						height: screenSize && "250px",
					}}>
					<Bar
						ref={revenueExpenseBarRef}
						data={transactionChartData}
						options={{
							...options,
							maintainAspectRatio: false,
						}}
						onClick={(e) => barClickHandler(e, "revenueExpense")}
					/>
				</div>
			</div>
		</div>
	);
}
