import {
	IonApp,
	IonCol,
	IonContent,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonPage,
	IonSpinner,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import { COUNTRYINFO } from "data/country";
import { digitChanger, numberWithCommas } from "helpers/helper";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Accounts from "services/Accounts";

export default function RevenueExpensePartsPage() {
	const location = useLocation();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let [loading, setLoading] = useState(true);
	let [expenseData, setExpenseData] = useState([]);
	const [accountType, setAccountType] = useState(null);
	const [pageCount, setPageCount] = useState(2);
	const [hasMoreData, setHasMoreData] = useState(true);
	const [reachedEnd, setReachedEnd] = useState(false);
	const { createUserData } = useSelector((state) => state.user);
	const [totalAmount, setTotalAmount] = useState(0);
	const dateFormat = useSelector((state) => state.date.format);

	const searchParams = new URLSearchParams(location?.search);
	const date = useSelector((state) => state.dashboard.dateInfo);
	const [getDate, setGetDate] = useState({
		start: moment(searchParams.get("state")).format("YYYY-MM-DD"),
		end: moment(searchParams.get("end")).format("YYYY-MM-DD"),
	});

	// let start, end;
	// if (date?.start && date?.end) {
	// 	start = moment(date.start).format("YYYY-MM-DD");
	// 	end = moment(date.end).format("YYYY-MM-DD");
	// } else {
	// 	start = moment(searchParams.get("state")).format("YYYY-MM-DD");
	// 	end = moment(searchParams.get("end")).format("YYYY-MM-DD");
	// }
	let value = date?.level;

	const getRevenueData = async () => {
		let accountType = await Accounts.getAccountType(searchParams.get("type"));
		if (accountType.success) {
			setAccountType(accountType?.results?.id);
			setLoading(true);
			let response = await Accounts.getTransactionDateWays(
				accountType?.results?.id,
				getDate?.start,
				getDate?.end
			);
			if (response.status === 200) {
				setLoading(false);
				setExpenseData(response.results);
				setTotalAmount(response?.total_amount);
			} else {
				setLoading(false);
				setExpenseData([]);
				setTotalAmount(0);
			}
		}
	};
	useEffect(() => {
		getRevenueData();
	}, [value]);

	let loadMoreTran = async () => {
		if (hasMoreData) {
			let response = await Accounts.getTransactionDateWays(
				accountType,
				getDate?.start,
				getDate?.end,
				pageCount
			);
			if (response.status === 200) {
				setLoading(false);
				setPageCount((prev) => prev + 1);
				setExpenseData([...expenseData, ...response.results]);
			} else {
				setHasMoreData(false);
				setLoading(false);
			}
		}
	};

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle
						title={`Daily ${searchParams.get("type")}`}
						filter={true}
						setGetDate={setGetDate}
					/>
					<div className='mt-14 px-4'>
						<div className='pb-10'>
							<div className='pt-3'>
								<div className='px-2 flex justify-between items-center pt-1 pb-5'>
									<h4 className='font-bold text-16 text-black-1000'>
										{searchParams.get("type")} on{" "}
										<span>
											<span>
												{moment(
													getDate?.start,
													'"YYYY-MM-DD"'
												).format(dateFormat)}{" "}
												-{" "}
												{moment(getDate?.end, "YYYY-MM-DD").format(
													dateFormat
												)}
											</span>
										</span>
									</h4>
								</div>
								<div className='px-2 flex justify-between items-center pt-1 pb-5'>
									<h4 className='font-bold text-16 text-black-1000'>
										Total Amount:{" "}
										{COUNTRYINFO?.[createUserData?.country]?.currency}
										{numberWithCommas(totalAmount)}
									</h4>
								</div>
								<div>
									{/* <div className='flex justify-between items-center mb-6'>
							<div>
								<Select
									onFocus={(e) => (e.target.readOnly = true)}
									defaultValue={typeOptions[1]}
									options={typeOptions}
									onChange={selectStatusChanger}
									placeholder='Select Status'
									styles={{
										control: (baseStyles, state) => ({
											...baseStyles,
											width: "300px",
											height: 40,
											border: "1px solid #999",
											outLine: "none",
											fontWeight: 500,
											fontSize: 12,
											color: "blue",
											borderRadius: 5,
										}),
										placeholder: (baseStyles, state) => ({
											...baseStyles,
											// color: "#FF564C",
										}),
										singleValue: (baseStyles, state) => ({
											...baseStyles,
											// color: "#00B224",
											fontWeight: 700,
										}),
										indicatorSeparator: (baseStyles, state) => ({
											...baseStyles,
											display: "none",
										}),
										dropdownIndicator: (baseStyles, state) => ({
											...baseStyles,
											// color: status ? "#00B224" : "#FF564C",
											// color: "#999",
										}),
										option: (baseStyles, state) => ({
											...baseStyles,
											backgroundColor: state.isSelected
												? "#f6f6f6"
												: "#fff", // Darker background for selected option
											color: state.isSelected ? "#000" : "#666", // Dark text for selected
											"&:hover": {
												backgroundColor: "#f2f2f2", // Hover effect
											},
										}),
									}}
									theme={(theme) => ({
										...theme,
										borderRadius: 5,
										colors: {
											...theme.colors,
											// primary25: "rgb(204, 255, 214)",
											// primary: "#00FF33",
											primary25: "#efefef",
											primary: "#f6f6f6",
										},
									})}
								/>
							</div>

							<div>
								<IonToggle
									labelPlacement='start'
									height='100'
									aria-label='Primary toggle'
									size='large'
									color='success'
									checked={isPaid}
									onIonChange={handleToggleChange}>
									<span className='font-bold text-14'>Paid</span>
								</IonToggle>
							</div>
						</div> */}

									<div>
										{loading ? (
											<div className='flex items-center justify-center h-[156px] m-auto'>
												{" "}
												<IonSpinner name='crescent'></IonSpinner>
											</div>
										) : (
											<>
												<div className='overflow-x-auto'>
													<table
														className='table-auto w-full bg-white'
														style={{
															boxShadow:
																"0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
														}}>
														<thead>
															<tr className='border h-[45px] border-r-0 border-l-0 border-D9D9D9 px-4'>
																<th className='border p-1 font-bold text-12'>
																	Date
																</th>
																{/* <th className='border p-1 font-bold text-14'>
														Order Id
													</th> */}
																<th className='border p-1 font-bold text-14'>
																	Amount
																</th>
																<th className='border p-1 font-bold text-14'>
																	Category
																</th>
																{searchParams.get("type") ==
																	"Expense" && (
																	<th className='border p-1 font-bold text-14'>
																		Supplier
																	</th>
																)}

																<th className='border p-1 font-bold text-14'>
																	Created By
																</th>
															</tr>
														</thead>
														<tbody>
															{expenseData?.length
																? expenseData?.map((el, i) => {
																		return (
																			<tr className='text-center h-[34px] border border-r-0 border-l-0 border-D9D9D9 px-4 cursor-pointer'>
																				<td className='border p-2 font-semibold text-10'>
																					{moment(
																						el?.date
																					).format(
																						dateFormat
																					)}
																				</td>
																				<td
																					// onClick={() =>
																					// 	handleNavigate(
																					// 		e.productTitle,
																					// 		e.catalogId
																					// 	)
																					// }
																					className='border  font-semibold p-2 text-14'>
																					{
																						COUNTRYINFO?.[
																							createUserData
																								?.country
																						]?.currency
																					}
																					{numberWithCommas(
																						el?.amount
																					)}
																				</td>
																				<td className='border  font-semibold p-2 text-14 text-left'>
																					{
																						el
																							?.acc_category
																							?.title
																					}
																				</td>
																				{searchParams.get(
																					"type"
																				) == "Expense" && (
																					<td className='border  font-semibold p-2 text-14 text-left'>
																						{
																							el?.seller_name
																						}
																					</td>
																				)}

																				<td className='border  font-semibold p-2 text-14'>
																					<Link
																						className='text-delivered underline'
																						to={`/employee-details/${el?.created_by}?from=dashboard`}>
																						{
																							el?.created_by_name
																						}
																					</Link>
																				</td>
																			</tr>
																		);
																  })
																: ""}
														</tbody>
													</table>
												</div>

												{!loading && expenseData?.length === 0 ? (
													<>
														<IonCol size='2'></IonCol>
														<IonCol size='8'>
															<div
																className='text-center flex items-center justify-center'
																style={{
																	height:
																		"calc(100vh - 420px)",
																}}>
																<div>
																	<img
																		className='m-auto mb-[10px] mt-5'
																		src='/assets/images/not-found-file.png'
																		alt='emptyCard'
																	/>
																	<p className='text-16 font-normal text-222222 mb-[35px]'>
																		{
																			ekkLanguage.orderList
																				.notFound
																		}
																	</p>
																</div>
															</div>
														</IonCol>
													</>
												) : undefined}
											</>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* {!loading && (
						<IonInfiniteScroll
							onIonInfinite={(ev) => {
								if (reachedEnd) {
									ev.target.complete();
									return;
								}
								loadMoreTran();
								setTimeout(() => {
									ev.target.complete();
									if (!hasMoreData) {
										setReachedEnd(true);
									}
								}, 1000);
							}}>
							<IonInfiniteScrollContent
								loadingText={ekkLanguage.orderList.loader}
								loadingSpinner={
									hasMoreData ? "bubbles" : null
								}></IonInfiniteScrollContent>
						</IonInfiniteScroll>
					)} */}
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
