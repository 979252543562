import { IonCheckbox } from "@ionic/react";
import { BUSINESS_TYPE } from "data/businessType";
import { BUSINESS_TYPE_INFO } from "data/businessTypeImage";
import { digitChanger, numberWithCommas, translator } from "helpers/helper";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./style.scss";
import Pin from "assets/images/icon/svg/Pin";
import StockOut from "assets/images/icon/svg/StockOut";
import { getLanguage } from "config/language";

export default function SingleRecommendProduct({
  el,
  id,
  img,
  name,
  supplierName,
  title,
  qty,
  tag,
  offer,
  offerAmount,
  nearby,
  price,
  oldPrice,
  newPrice,
  sellPrice,
  profit,
  nameShow,
  skuShow,
  priceShow,
  oldPriceShow,
  distShow,
  profitShow,
  sellPriceShow,
  supplierNameShow,
  newPriceShow,
  titleShow,
  select,
  link,
  type,
  defaultImage,
  currency,
  offerPriceShow,
  offerPrice,
  productType,
  distance,
  identity,
  business_type,
  iQty,
  category,
}) {
  let navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const appLanguage = getLanguage();
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const locatedSeller = useSelector((state) => state.supplier.data);
  let defaultImageMokeUp = () => {
    if (defaultImage) {
      return defaultImage;
    } else {
      if (category === "brand") {
        return "/assets/images/badge_default.png";
      }
      if (type === "variant") {
        return "/assets/images/varient_default.png";
      } else if (type === "pack") {
        return "/assets/images/pack_default.png";
      } else if (type === "offer") {
        return "/assets/images/offer_default.png";
      }
    }
  };
  let isImageLength = () => {
    if (img?.length) {
      if (img[0] != "") {
        return `${img[0]}`;
      } else {
        if (type === "variant") {
          return "/assets/images/varient_default.png";
        } else if (type === "pack") {
          return "/assets/images/pack_default.png";
        } else if (type === "offer") {
          return "/assets/images/offer_default.png";
        }
      }
    }
  };

  let businessType = () =>
    BUSINESS_TYPE_INFO.find((el) => el.type === business_type);
  return (
    <div className="rounded-md">
      <div className="rounded-xl overflow-hidden bg-E6E6E6 p-3 mb-[5px] relative">
        {iQty <= 0 && (
          <span className="quantity-badge text-white font-bold absolute z-10 text-12 left-[12px]">
            <StockOut />
          </span>
        )}
        {select && (
          <span className="absolute left-0 top-0">
            <IonCheckbox
              color="success"
              className="rounede"
              checked={checked}
              onIonChange={(e) => setChecked(e.detail.checked)}
            />
          </span>
        )}

        {img?.length ? (
          <div className=" home-card-image-sizer text-center flex items-center justify-center h-[120px] relative">
            <img
              onClick={() => navigate(`/${link}/${id}?&type=${productType}`)}
              src={isImageLength()}
              alt="productImage"
              className="w-full image-sizer bg-cover absolute left-0 top-0 h-[100%]"
            />
          </div>
        ) : (
          <>
            {identity !== "seller" ? (
              <div className=" home-card-image-sizer text-center flex items-center justify-center h-[120px] relative">
                <img
                  onClick={() =>
                    navigate(`/${link}/${id}?&type=${productType}`)
                  }
                  src={defaultImageMokeUp()}
                  alt="productImage"
                  className="w-full image-sizer absolute left-0 top-0 h-[100%]"
                />
              </div>
            ) : (
              <div
                className=" home-card-image-sizer text-center flex items-center justify-center h-[120px] relative"
                onClick={() => navigate(`/${link}/${id}?&type=${productType}`)}
              >
                <img
                  className="h-[100%] w-full image-sizer absolute left-0 top-0"
                  src={img?.length ? img[0] : defaultImage}
                  alt="icon"
                />
                {/* {BUSINESS_TYPE.map((el) => {
									
									return <img src={el.image} alt='' />;
								})}
								<h3
									className='font-bold capitalize text-22'
									style={{ wordBreak: "break-word" }}>
									{supplierName.split(" ")[0]}
								</h3> */}
              </div>
            )}
          </>
        )}
      </div>

      <div>
        {nameShow && (
          <h5 className="font-semibold text-16">{digitChanger(name)}</h5>
        )}
        {supplierNameShow && (
          <h5 className="font-semibold text-12">{supplierName}</h5>
        )}
        {identity === "seller" && !locatedSeller && (
          <div>
            <p className="font-normal text-10 text-gray-700">
              {digitChanger(qty)}{" "}
              {appLanguage === "bn"
                ? "টি পণ্য"
                : appLanguage === "vn"
                ? "Sản phẩm"
                : appLanguage === "my"
                ? "Produk"
                : "Products"}
            </p>
          </div>
        )}
        {distance >= -1 ? (
          <div className="pt-[5px] flex justify-between">
            {/* <img src='/assets/images/icon/pin.png' alt='icon' /> */}

            <span className="text-primary text-10 font-bold flex">
              <Pin />
              <span className="ml-[2px]">
                {digitChanger(numberWithCommas(distance))} {ekkLanguage.home.km}
              </span>
            </span>

            <div>
              <p className="font-normal text-10 text-gray-700">
                {digitChanger(qty)}{" "}
                {appLanguage === "bn"
                  ? "টি পণ্য"
                  : appLanguage === "vn"
                  ? "Sản phẩm"
                  : appLanguage === "my"
                  ? "Produk"
                  : "Products"}
              </p>
            </div>
          </div>
        ) : (
          ""
        )}
        {titleShow && (
          <h5 className="font-semibold text-16 text-left">
            {digitChanger(title)}
          </h5>
        )}

        {/* {priceShow && (
					<span className='font-bold text-12 text-primary'>৳ {price}</span>
				)} */}
        {skuShow && (
          <span className="font-normal text-10">
            {digitChanger(qty)} {ekkLanguage.home.product}
          </span>
        )}
        <div className="flex justify-between">
          {offerPriceShow && (
            <span className="font-bold text-12 text-primary colors-222222 self-start">
              {currency && currency?.currency_symbol_native}
              {digitChanger(numberWithCommas(offerPrice))}
            </span>
          )}
          {priceShow && (
            <span className="font-bold text-12 text-primary colors-222222 self-start">
              {currency && currency?.currency_symbol_native}
              {digitChanger(numberWithCommas(price))}
            </span>
          )}
          {oldPriceShow && (
            <span className="font-medium text-12 colors-808080 line-through">
              {currency && currency?.currency_symbol_native}{" "}
              {digitChanger(numberWithCommas(oldPrice))}
            </span>
          )}
          {profitShow && (
            <span className="font-medium text-12 text-success">
              {currency && currency?.currency_symbol_native}
              {digitChanger(numberWithCommas(profit))}
            </span>
          )}
        </div>
        {/* <div className='flex justify-between'>
					{priceShow && (
						<span className='flex items-center'>
							<span className='font-bold text-12 text-primary '>
								{currency && currency?.currency_symbol_native} {digitChanger(numberWithCommas(price))}
							</span>
						</span>
					)}
					{profitShow && (
						<span className=' flex items-center'>
							<span className='font-bold text-12 text-success'>
								{currency && currency?.currency_symbol_native} {digitChanger(numberWithCommas(profit))}
							</span>
						</span>
					)}
				</div> */}
      </div>
    </div>
  );
}
