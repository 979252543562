import { IonApp, IonContent, IonPage } from "@ionic/react";
import AccountDashboard from "components/mobile-components/AccountDashboard";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import React from "react";

export default function AccountDashboardPage() {
	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle title='Account Dashboard' />
					<section className='pt-14 px-4'>
						<AccountDashboard />
					</section>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
