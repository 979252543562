import React from "react";
import CardTitle from "./CardTitle";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./style.scss";
import { useSelector } from "react-redux";

let slideData = [
	{ image: "assets/images/moc1.png" },
	{ image: "assets/images/moc1.png" },
];

export default function RecommendCard() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<div className='home-card recommended-order-card'>
			<CardTitle
				title={ekkLanguage.home.recommendentOrderLabel}
				link='/'
				icon='assets/images/reward.png'
			/>
			<div>
				<Swiper
					spaceBetween={0}
					breakpoints={{
						260: {
							slidesPerView: 1,
						},
						320: {
							slidesPerView: 1.5,
						},
						360: {
							slidesPerView: 1.5,
						},
						575: {
							slidesPerView: 2.5,
						},
						768: {
							slidesPerView: 2.5,
						},
					}}>
					{slideData.map((el, i) => {
						return (
							<SwiperSlide key={i}>
								<div className='relative overflow-hidden'>
									<div className='relative'>
										<div className='flex absolute w-[50px] h-[26px] border-2 border-white bg-success items-center justify-center rounded-br-[10px] rounded-tl-[10px] top-[-2px] left-0 border-l-0'>
											<div>
												<img
													src='assets/images/box.png'
													alt='icon'
												/>
											</div>
											<span className='ml-[5px] font-bold text-12 text-white'>
												5
											</span>
										</div>
										<div className='flex absolute w-auto h-[26px] border-2 border-white bg-success items-center justify-center rounded-bl-[10px] rounded-tr-[10px] top-[-2px] right-0 border-r-0'>
											<span className='ml-[5px] font-bold text-12 text-white px-1'>
												৳ 350.00
											</span>
										</div>

										<img
											src={el.image}
											className='w-100 rounded-[10px] mb-[5px]'
											alt='slideImage'
										/>
									</div>

									<h5 className='font-bold text-14 text-primary mb-[5px]'>
										৳ 450.00
									</h5>
									<div className='flex items-center'>
										<div>
											<img
												src='assets/images/truck-mini.png'
												alt='icon'
											/>
										</div>
										<span className='ml-[5px] font-semibold text-14'>
											Nababi Enterprise LTD
										</span>
									</div>
								</div>
							</SwiperSlide>
						);
					})}
				</Swiper>
			</div>
		</div>
	);
}
