import { Button } from "components/global-components/common/button";
import { Input } from "components/global-components/common/Input";
import React, { useRef, useState } from "react";
import Box from "./Box";
import Auth from "services/Auth";
import { authBox_Block } from "Constants";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";

export default function ChangePassword({
	loading,
	country,
	phoneNo,
	countryCode,
	startLoading,
	setBlockIndex,
	stopLoading,
	setIsForgotPassword,
}) {
	const [newPassword_forgotPassword, setNewPassword_forgotPassword] =
		useState("");
	const [confrimNewPassword, setConfrimNewPassword] = useState("");
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [error, setError] = useState("");
	const [lengthError, setLengthError] = useState("");
	const navigate = useNavigate();
	let clickBtnRef = useRef(null);
	let [eyeIcon, setEyeIcon] = useState(false);
	let [eyeIconConfirm, setEyeIconConfirm] = useState(false);

	const _handleKeyPress = (e) => {
		if (e.key === "Enter") {
			clickBtnRef.click();
		}
	};

	const resetPasswordHandler = async (
		newPassword_forgotPassword,
		country,
		phoneNumber,
		countryCode
	) => {
		if (newPassword_forgotPassword.length > 3) {
			let req_body = {
				password: newPassword_forgotPassword,
				country: country,
				phone_number: (countryCode + phoneNumber).trim(),
			};

			try {
				startLoading();
				let response = await Auth.resetPassword(req_body);
				if (response.status === 200 || response.status === 201) {
					await setIsForgotPassword(false);
					// setBlockIndex(authBox_Block.PHONE_NUMBER_BLOCK);
					navigate("/");
					stopLoading();
					toast.success(ekkLanguage.changePass.successLabel);
				} else {
					toast.error(ekkLanguage.changePass.errorLabel);
				}
			} catch (e) {
				stopLoading();
				console.log(e);
				toast.error(ekkLanguage.changePass.errorLabel);
			}
		} else {
			setLengthError(ekkLanguage.changePass.lengthErrorLabel);
		}
	};
	return (
		<Box>
			<Input
				onChange={(event) =>
					setNewPassword_forgotPassword(event.target.value)
				}
				type={!eyeIcon ? "password" : "text"}
				label={ekkLanguage.changePass.passwordLabel}
				placeholder={ekkLanguage.changePass.passwordPlaceHolder}
				eyeActive={true}
				setEyeIcon={setEyeIcon}
				eyeIcon={eyeIcon}
			/>

			{lengthError && (
				<span className='text-primary mt-1'>{lengthError}</span>
			)}
			<Input
				onChange={(event) => setConfrimNewPassword(event.target.value)}
				type={!eyeIconConfirm ? "password" : "text"}
				label={ekkLanguage.changePass.confirmPassLabel}
				placeholder={ekkLanguage.changePass.confirmPassPlaceHolder}
				errorMessage={
					newPassword_forgotPassword !== confrimNewPassword
						? ekkLanguage.changePass.passNotMatchLabel
						: false
				}
				eyeActive={true}
				setEyeIcon={setEyeIconConfirm}
				eyeIcon={eyeIconConfirm}
			/>
			{error && <span className='text-primary mt-1'>{error}</span>}
			<div className='flex justify-center py-4'>
				<Button
					title={ekkLanguage.changePass.btnLabel}
					loading={loading}
					onClick={() =>
						resetPasswordHandler(
							newPassword_forgotPassword,
							country,
							phoneNo,
							countryCode
						)
					}
					propsRef={(input) => {
						clickBtnRef = input;
					}}
				/>
			</div>
		</Box>
	);
}
