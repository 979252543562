import React from "react";

export default function Unverified() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'>
			<rect width='24' height='24' rx='12' fill='white' />
			<path
				d='M11.1781 6.17188C11.1219 8.61875 11.0562 11.0563 11 13.5031C11 13.8139 11.1235 14.112 11.3432 14.3318C11.563 14.5515 11.8611 14.675 12.1719 14.675C12.4827 14.675 12.7807 14.5515 13.0005 14.3318C13.2203 14.112 13.3437 13.8139 13.3437 13.5031C13.4 11.0563 13.4656 8.61875 13.5219 6.17188C13.5219 5.86107 13.3984 5.563 13.1786 5.34323C12.9589 5.12347 12.6608 5 12.35 5C12.0392 5 11.7411 5.12347 11.5214 5.34323C11.3016 5.563 11.1781 5.86107 11.1781 6.17188V6.17188Z'
				fill='#FF564C'
			/>
			<path
				d='M12.1719 15.8091C11.8611 15.8091 11.563 15.9325 11.3432 16.1523C11.1235 16.3721 11 16.6702 11 16.981C11 17.2918 11.1235 17.5898 11.3432 17.8096C11.563 18.0294 11.8611 18.1528 12.1719 18.1528C12.4827 18.1528 12.7807 18.0294 13.0005 17.8096C13.2203 17.5898 13.3438 17.2918 13.3438 16.981C13.3438 16.6702 13.2203 16.3721 13.0005 16.1523C12.7807 15.9325 12.4827 15.8091 12.1719 15.8091Z'
				fill='#FF564C'
			/>
		</svg>
	);
}
