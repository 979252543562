import { IonCol, IonGrid, IonRow, IonSpinner } from "@ionic/react";
import ArrowUp from "assets/images/icon/svg/ArrowUp";
import { COUNTRYINFO } from "data/country";
import { digitChanger, numberWithCommas, translator } from "helpers/helper";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function PurchaseHistory({ todayPurchase, orderAnalytics }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const { createUserData } = useSelector((state) => state.user);

	return (
		<div className='purchase-summary relative'>
			<IonGrid>
				<IonRow>
					<IonCol size='6'>
						<div className='text-center rounded-[10px] bg-white pr-[5px]'>
							<p className='text-14 text-success font-bold mb-[8px]'>
								{ekkLanguage.home.todayPurchaseLabel}
							</p>
							<p className='text-12 text-262626-1000 font-semibold mb-[10px]'>
								{todayPurchase ? (
									<span>
										{digitChanger(todayPurchase?.total_orders)}{" "}
										{ekkLanguage.home.totalOrder}
									</span>
								) : (
									<IonSpinner color='green'></IonSpinner>
								)}
							</p>
							<h5 className='font-bold text-262626-1000 text-18'>
								{/* {todayPurchase?.currency &&
                  translator(
                    todayPurchase?.currency?.currency_symbol_native ??
                      todayPurchase?.currency
                  )}{" "} */}
								{COUNTRYINFO?.[createUserData?.country]?.currency}
								{todayPurchase &&
									digitChanger(
										numberWithCommas(todayPurchase?.total_amount)
									)}
							</h5>

							{/* <div className='flex justify-between items-center pt-[10px]'>
								<span
									className='text-10 font-semibold text-success rounded-[21px] py-[3px] px-[4px] flex justify-center items-center'
									style={{ background: "#CCFFD6" }}>
									<ArrowUp color='#00991F' /> 0.5%
								</span>
								<Link
									className='font-12 font-normal underline'
									style={{ color: "#00ACE5" }}
									to='#'>
									See Details
								</Link>
							</div> */}
						</div>
					</IonCol>
					<IonCol size='6'>
						<div className='text-center rounded-[10px] bg-white pl-[5px]'>
							<p className='text-12 text-primary font-bold mb-[8px]'>
								{ekkLanguage.home.tillNow}
							</p>

							<p className='text-12 text-262626-1000 font-semibold mb-[10px]'>
								{orderAnalytics ? (
									<span>
										{digitChanger(orderAnalytics?.total_orders)}{" "}
										{ekkLanguage.home.orderDue}
									</span>
								) : (
									<IonSpinner color='red'></IonSpinner>
								)}
							</p>
							<h5 className='font-bold text-262626-1000 text-18'>
								{/* {orderAnalytics?.currency &&
									translator(
										orderAnalytics?.currency
											?.currency_symbol_native ??
											orderAnalytics?.currency
									)}{" "} */}
								{COUNTRYINFO?.[createUserData?.country]?.currency}
								{orderAnalytics &&
									digitChanger(
										numberWithCommas(orderAnalytics?.total_due)
									)}
							</h5>
							{/* <div className='flex justify-between items-center  pt-[10px]'>
								<span
									className='text-10 font-semibold text-primary rounded-[21px] py-[3px] px-[4px] flex justify-center items-center'
									style={{ background: "#FFCFCC" }}>
									<ArrowUp color='#FF261A' /> 0.5%
								</span>
								<Link
									className='font-12 font-normal underline'
									style={{ color: "#00ACE5" }}
									to='#'>
									See Details
								</Link>
							</div> */}
						</div>
					</IonCol>
				</IonRow>
			</IonGrid>
		</div>
	);
}
