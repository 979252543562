import {
	IonApp,
	IonContent,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonLoading,
	IonPage,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import UpdatedProduct from "components/mobile-components/Home/UpdatedProduct";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Department from "services/Department";
export default function Departments() {
	let settinges = {
		nameShow: false,
		titleShow: true,
		skuShow: false,
		priceShow: true,
		oldPriceShow: false,
		distShow: false,
		profitShow: true,
		sellPriceShow: false,
		supplierNameShow: false,
		link: "product-details",
	};
	let { id, department } = useParams();
	const [products, setProducts] = useState([]);
	const [totalCount, setTotalCount] = useState();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [pageCount, setPageCount] = useState(2);
	let [loading, setLoading] = useState(false);
	const [hasMoreData, setHasMoreData] = useState(true);
	const [reachedEnd, setReachedEnd] = useState(false);
	useEffect(() => {
		if (id) {
			let getDepartmentProduct = async () => {
				setLoading(true);
				let response = await Department.getDepartmentProducts(id);
				if (response.success) {
					setLoading(false);
					setProducts(response?.results);
					setTotalCount(response?.count);
				} else {
					setLoading(false);
					setProducts([]);
				}
			};
			getDepartmentProduct();
		}
	}, [id]);

	let lodeMoreData = async () => {
		if (hasMoreData) {
			let response = await Department.getDepartmentProducts(id, pageCount);
			if (response.status === 200) {
				if (response.results.length > 0) {
					setPageCount((prev) => prev + 1);
					setProducts([...products, ...response.results]);
				} else {
					setHasMoreData(false);
				}
			} else {
				setHasMoreData(false);
			}
		}
	};

	return (
		<IonApp>
			<IonPage>
				<IonContent
					scrollEvents={true}
					// onIonScrollEnd={(ev) => {
					// 	searchNext(ev);
					// }}
				>
					<div className='pb-14'>
						<BackButtonTitle title={department} />
					</div>
					<div className='pt-[10px] pb-4 px-2'></div>

					{loading ? (
						<IonLoading isOpen={true} />
					) : (
						<UpdatedProduct
							totalProcuctCount={totalCount}
							recommendData={products}
							settinges={settinges}
							ekkLanguage={ekkLanguage}
						/>
					)}

					<IonInfiniteScroll
						onIonInfinite={(ev) => {
							if (reachedEnd) {
								ev.target.complete();
								return;
							}
							lodeMoreData();
							setTimeout(() => {
								ev.target.complete();
								if (!hasMoreData) {
									setReachedEnd(true);
								}
							}, 1000);
						}}>
						<IonInfiniteScrollContent
							loadingText={ekkLanguage.orderList.loader}
							loadingSpinner={
								hasMoreData ? "bubbles" : null
							}></IonInfiniteScrollContent>
					</IonInfiniteScroll>
				</IonContent>
				{/* <NavFooter /> */}
			</IonPage>
		</IonApp>
	);
}
