import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { COUNTRYINFO } from "data/country";
import { IonCheckbox, IonSpinner } from "@ionic/react";
import { getLanguage } from "config/language";
import { useDispatch, useSelector } from "react-redux";
import { parseNumber } from "libphonenumber-js";
import Auth from "services/Auth";
import { Link, useNavigate } from "react-router-dom";
import { setCreateUser } from "redux/container/userSlice";

export default function PhoneNumber({
	sSize,
	phoneNo,
	setPhoneNo,
	country,
	setCountry,
	countryCode,
	setCountryCode,
}) {
	const [isChecked, setIsChecked] = useState(false);
	const ekkLanguage = useSelector((state) => state.lan);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [spamE, setSpamE] = useState("");
	const [phoneNoClick, setPhoneNoClick] = useState(false);
	var countryList = Object.values(COUNTRYINFO).map((val) => ({
		countryCode: val.code,
		country: val.name,
		value: val.code,
		label: (
			<div className='flex items-center justify-between'>
				<div className='flex justify-between items-center'>
					<img
						src={val.flag}
						className='h-5 lg:h-auto lg:w-10 rounded-[5px] w-6'
						alt='flag'></img>{" "}
					<p className='text-14 pl-2'>{val.name}</p>
				</div>
				<p className='text-14 pl-2'>({val.code})</p>
			</div>
		),
	}));
	const handleSelectCountry = (e) => {
		setCountryCode(e.countryCode);
		setCountry(e.country);
	};
	let clickBtnRef = useRef(null);
	const _handleKeyPress = (e) => {
		if (e.key === "Enter") {
			clickBtnRef.click();
		}
	};
	let customStyles = {
		option: (provided, state) => ({
			...provided,
			zIndex: 10,
			paddingLeft: 15,
		}),
		singleValue: (provided, state) => ({
			...provided,
			borderWidth: 0,
			height: sSize.matches ? 50 : 60,
			borderRadius: 0,
			display: "flex",
			fontSize: sSize.matches ? 16 : 24,
		}),
		control: (provided, state) => ({
			...provided,
			display: "flex !important",
			color: "#000",
			borderRadius: 15,
			borderWidth: 1,
			border: "1px solid #00000026",
			outLine: "none",
			height: sSize.matches ? 50 : 60,
			paddingLeft: sSize.matches ? 10 : 10,
			fontSize: sSize.matches ? 16 : 24,
			//   borderBottomLeftRadius: 5,
			//   borderTopLeftRadius: 5,
		}),
	};
	const handleOnChange = (e) => {
		setIsChecked(!isChecked);
	};
	const errorMessage = () => {
		if (phoneNo) {
			// Remove any special characters from phone number
			let cleanedPhoneNo = phoneNo.replace(/[^0-9]/g, "");

			// Get country code info
			const countryInfo = Object.values(COUNTRYINFO).find(
				(c) => c.name === country
			);
			const countryCodeFromInfo = countryInfo?.code;

			// If phone number starts with country code, remove it
			if (
				countryCodeFromInfo &&
				cleanedPhoneNo.startsWith(countryCodeFromInfo.substring(1))
			) {
				cleanedPhoneNo = cleanedPhoneNo.substring(
					countryCodeFromInfo.length - 1
				);
			}

			if (country === "Bangladesh") {
				if (
					!(
						cleanedPhoneNo.trim().length > 10 &&
						cleanedPhoneNo.trim().length < 12 &&
						cleanedPhoneNo[0] == 0
					)
				) {
					return ekkLanguage?.lan.login.validationLabel;
				} else {
					return "";
				}
			} else {
				if (
					!Object.keys(parseNumber(countryCode + cleanedPhoneNo)).length &&
					cleanedPhoneNo.trim().length > 0
				) {
					console.log(
						!Object.keys(parseNumber(countryCode + cleanedPhoneNo))
							.length && cleanedPhoneNo.trim().length
					);

					return ekkLanguage?.lan.login.validationLabel;
				} else {
					return "";
				}
			}
		}
	};

	const submitPhoneNumberCountry = async (
		countryCode,
		country,
		phoneNumber
	) => {
		let cleanedPhoneNo = phoneNumber.replace(/[^0-9]/g, "");

		const countryInfo = Object.values(COUNTRYINFO).find(
			(c) => c.name === country
		);
		const countryCodeFromInfo = countryInfo?.code;

		// If phone number starts with country code, remove it
		if (
			countryCodeFromInfo &&
			cleanedPhoneNo.startsWith(countryCodeFromInfo.substring(1))
		) {
			cleanedPhoneNo = cleanedPhoneNo.substring(
				countryCodeFromInfo.length - 1
			);
		}

		if (country === "Bangladesh") {
			if (
				!(
					cleanedPhoneNo.trim().length > 10 &&
					cleanedPhoneNo.trim().length < 12 &&
					cleanedPhoneNo[0] == 0
				)
			) {
				return;
			}
		}

		if (isChecked) {
			setPhoneNoClick(true);
			let req_body = {
				country,
				phone_number: (countryCode + cleanedPhoneNo).trim(),
			};

			try {
				let response = await Auth.countryAndUserFilter(req_body);
				if (response.status === 200) {
					if (response.success) {
						setPhoneNoClick(false);
						let data = {
							countryCode,
							country,
							phoneNumber: cleanedPhoneNo,
							verify: false,
						};

						if (
							response?.data?.body?.user_status === "Incomplete Profile"
						) {
							dispatch(setCreateUser({ ...data, verify: true }));
							setPhoneNoClick(false);
						} else if (
							response?.data?.body?.user_status === "Employee Invited"
						) {
							dispatch(setCreateUser(data));
							setPhoneNoClick(false);
							navigate("/password");
						} else {
							dispatch(setCreateUser(data));
							setPhoneNoClick(false);
							navigate("/password");
						}
					} else {
						let data = {
							countryCode,
							country,
							phoneNumber: cleanedPhoneNo,
							verify: true,
						};
						dispatch(setCreateUser(data));
						setPhoneNoClick(false);
						navigate("/registration");
						setPhoneNoClick(false);
					}
				} else {
					let data = {
						countryCode,
						country,
						phoneNumber: cleanedPhoneNo,
						verify: true,
					};
					dispatch(setCreateUser(data));
					setPhoneNoClick(false);
					navigate("/registration");
					setPhoneNoClick(false);
				}
				if (!response.success) {
					setPhoneNoClick(false);
				}
			} catch (e) {
				setPhoneNoClick(false);
				setSpamE(e?.errorData?.message);
				console.log(e);
			}
		} else {
			setPhoneNoClick(false);
		}
	};
	const numberInputRef = useRef(null);
	useEffect(() => {
		const handleWheel = (event) => {
			if (
				numberInputRef.current &&
				numberInputRef.current === document.activeElement
			) {
				event.preventDefault();
			}
		};
		window.addEventListener("wheel", handleWheel, { passive: false });
		return () => {
			window.removeEventListener("wheel", handleWheel);
		};
	}, []);

	return (
		<div className=' bg-[#FFFFFF] lg:p-10 px-5 py-10 rounded-lg shadow-md lg:w-1/2 w-full'>
			<label
				className={`text-14 md:text-20 text-262626-1000 sm:pb-3 pb-[10px] block`}>
				{ekkLanguage?.lan?.landing.phoneNumber}
			</label>
			<div
				className='z-10 relative number-selection'
				// style={{ boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.05)" }}
			>
				<Select
					styles={customStyles}
					className='basic-single'
					placeholder='+88'
					name='color'
					options={countryList}
					onChange={(e) => handleSelectCountry(e)}
					components={{
						IndicatorSeparator: () => null,
						SingleValue: ({ children, ...props }) => {
							// Custom component to show only country name and flag after selection
							const { data } = props;

							return (
								<div className='flex items-center justify-between'>
									<div className='flex items-center'>
										<img
											src={
												data?.label?.props?.children?.props
													?.children[0]?.props?.src
													? data?.label?.props?.children?.props
															?.children[0]?.props?.src
													: data?.label?.props?.children[0]?.props
															?.children[0]?.props?.src
											}
											className='h-4 lg:h-auto lg:w-6 rounded-[5px] w-6'
											alt='flag'
										/>
										<p className='text-14 pl-2'>{data.country}</p>
									</div>
								</div>
							);
						},
					}}
					isSearchable={false}
					style={{ fontSize: sSize.matches ? 14 : 20 }}
					defaultValue={{
						countryCode: "+1",
						country: "United States",
						label: (
							<div className='flex items-center justify-between'>
								<div className='flex items-center'>
									<img
										src='https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-united-states-of-america.png'
										className='h-4 lg:h-auto lg:w-6 rounded-[5px] w-6'
										alt='flag'
									/>
									<p className='text-14 pl-2'>United States</p>
								</div>
							</div>
						),
					}}
				/>

				<div className='flex'>
					<div
						className='px-4 items-center flex justify-center country-code-added'
						style={{
							height: sSize.matches ? 50 : 60,
							flex: "flex: 1 0 auto",
							borderRadius: 15,
							borderWidth: 1,
							border: "1px solid #00000026",
						}}>
						{countryCode}
					</div>
					<input
						ref={numberInputRef}
						type='string'
						onKeyPress={_handleKeyPress}
						onChange={(event) => {
							setSpamE("");
							// Only allow numbers by removing non-numeric characters
							const numbersOnly = event.target.value.replace(
								/[^\d]/g,
								""
							);
							setPhoneNo(numbersOnly);
						}}
						placeholder={ekkLanguage?.lan?.landing.phoneNumberPlaceHolder}
						id={"ekk-input"}
						style={{
							marginLeft: 10,
							paddingLeft: 10,
							height: sSize.matches ? 50 : 60,
							flex: "flex: 1 0 auto",
							borderRadius: 15,
							borderWidth: 1,
							border: "1px solid #00000026",
						}}
						value={phoneNo}
						className='appearance-none text-base w-full text-grey-darker border border-left-0  py-2 px-2 leading-tight focus:outline-none focus:bg-white bg-white'
					/>
				</div>
			</div>
			<div className='pt-4'>
				<p className='text-red text-xs text-primary'>{spamE}</p>
			</div>
			<div className='pt-4'>
				<p className='text-red text-xs text-primary'>{errorMessage()}</p>
			</div>

			<div className='trams-condition pt-2 pb-5 sm:pb-[20px]'>
				<label className='flex cursor-pointer my-2'>
					<IonCheckbox
						color='success'
						size='small'
						slot='start'
						value='terms'
						className='mr-2 checkbox-square'
						checked={isChecked}
						onIonChange={handleOnChange}></IonCheckbox>
					{getLanguage() == "en" ? (
						<p className='flex-1 text-12'>
							I agree with{" "}
							<Link
								to='/terms-conditions'
								className='text-delivered text-12'
								rel='noreferrer'>
								Terms & Conditions
							</Link>
							,{" "}
							<Link
								to='/privacy-policy'
								className='text-delivered text-12'
								rel='noreferrer'>
								{" "}
								Privacy Policy{" "}
							</Link>{" "}
							.
						</p>
					) : getLanguage() == "bn" ? (
						<p className='flex-1 text-12'>
							আমি EkkBaz এর{" "}
							<Link
								to='/terms-conditions'
								className='text-delivered'
								rel='noreferrer'>
								টার্মস & কন্ডিশনস
							</Link>
							,{" "}
							<Link
								to='/privacy-policy'
								className='text-delivered'
								rel='noreferrer'>
								{" "}
								প্রাইভেসী পলিসি।{" "}
							</Link>
						</p>
					) : (
						<p className='flex-1 text-12'>
							Tôi thuộc EkkBaz{" "}
							<Link
								to='/terms-conditions'
								className='text-delivered'
								rel='noreferrer'>
								Điều khoản & Điều kiện
							</Link>
							,{" "}
							<Link
								to='/privacy-policy'
								className='text-delivered'
								rel='noreferrer'>
								{" "}
								Chính sách bảo mật{" "}
							</Link>{" "}
							Và.
						</p>
					)}
				</label>
			</div>
			<button
				onClick={() => {
					if (!phoneNoClick) {
						submitPhoneNumberCountry(countryCode, country, phoneNo);
					}
				}}
				// propsref={(input) => {
				// 	clickBtnRef = input;
				// }}
				style={
					!isChecked
						? {
								color: "rgba(0, 0, 0, 0.26)",
								backgroundColor: "rgba(0, 0, 0, 0.12)",
								userSelect: "none",
								cursor: "no-drop",
						  }
						: phoneNoClick
						? {
								color: "rgba(0, 0, 0, 0.26)",
								backgroundColor: "rgba(0, 0, 0, 0.12)",
								userSelect: "none",
								cursor: "no-drop",
						  }
						: {
								background:
									"linear-gradient(89.31deg, #00C0FF 0%, rgba(0, 178, 36, 0.5) 100%)",
						  }
				}
				className={`text-20 sm:text-26 font-bold text-white w-full h-[50px] sm:h-[60px] rounded-[5px] lg:mt-0 mt-2`}>
				{phoneNoClick ? (
					<IonSpinner name='crescent'></IonSpinner>
				) : (
					ekkLanguage?.lan?.landing.login
				)}
			</button>
		</div>
	);
}
