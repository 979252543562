import React from "react";

export default function EditBusinessProfile() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='40'
			height='40'
			viewBox='0 0 40 40'
			fill='none'
		>
			<circle
				cx='20'
				cy='20'
				r='18.5'
				fill='#5A7EFF'
				stroke='white'
				strokeWidth='3'
			/>
			<g clipPath='url(#clip0_1865_277)'>
				<path
					d='M26.75 14H24.425C24.2509 13.1536 23.7904 12.393 23.1209 11.8465C22.4515 11.3001 21.6142 11.0011 20.75 11L19.25 11C18.3858 11.0011 17.5485 11.3001 16.8791 11.8465C16.2096 12.393 15.7491 13.1536 15.575 14H13.25C13.0511 14 12.8603 14.079 12.7197 14.2197C12.579 14.3603 12.5 14.5511 12.5 14.75C12.5 14.9489 12.579 15.1397 12.7197 15.2803C12.8603 15.421 13.0511 15.5 13.25 15.5H14V25.25C14.0012 26.2442 14.3967 27.1973 15.0997 27.9003C15.8027 28.6033 16.7558 28.9988 17.75 29H22.25C23.2442 28.9988 24.1973 28.6033 24.9003 27.9003C25.6033 27.1973 25.9988 26.2442 26 25.25V15.5H26.75C26.9489 15.5 27.1397 15.421 27.2803 15.2803C27.421 15.1397 27.5 14.9489 27.5 14.75C27.5 14.5511 27.421 14.3603 27.2803 14.2197C27.1397 14.079 26.9489 14 26.75 14ZM19.25 12.5H20.75C21.2152 12.5006 21.6688 12.645 22.0487 12.9136C22.4286 13.1821 22.7161 13.5616 22.8718 14H17.1283C17.2839 13.5616 17.5714 13.1821 17.9513 12.9136C18.3312 12.645 18.7848 12.5006 19.25 12.5ZM24.5 25.25C24.5 25.8467 24.2629 26.419 23.841 26.841C23.419 27.2629 22.8467 27.5 22.25 27.5H17.75C17.1533 27.5 16.581 27.2629 16.159 26.841C15.7371 26.419 15.5 25.8467 15.5 25.25V15.5H24.5V25.25Z'
					fill='white'
				/>
				<path
					d='M18.5 24.5C18.6989 24.5 18.8897 24.421 19.0303 24.2803C19.171 24.1397 19.25 23.9489 19.25 23.75V19.25C19.25 19.0511 19.171 18.8603 19.0303 18.7197C18.8897 18.579 18.6989 18.5 18.5 18.5C18.3011 18.5 18.1103 18.579 17.9697 18.7197C17.829 18.8603 17.75 19.0511 17.75 19.25V23.75C17.75 23.9489 17.829 24.1397 17.9697 24.2803C18.1103 24.421 18.3011 24.5 18.5 24.5Z'
					fill='white'
				/>
				<path
					d='M21.5 24.5C21.6989 24.5 21.8897 24.421 22.0303 24.2803C22.171 24.1397 22.25 23.9489 22.25 23.75V19.25C22.25 19.0511 22.171 18.8603 22.0303 18.7197C21.8897 18.579 21.6989 18.5 21.5 18.5C21.3011 18.5 21.1103 18.579 20.9697 18.7197C20.829 18.8603 20.75 19.0511 20.75 19.25V23.75C20.75 23.9489 20.829 24.1397 20.9697 24.2803C21.1103 24.421 21.3011 24.5 21.5 24.5Z'
					fill='white'
				/>
			</g>
			<defs>
				<clipPath id='clip0_1865_277'>
					<rect
						width='18'
						height='18'
						fill='white'
						transform='translate(11 11)'
					/>
				</clipPath>
			</defs>
		</svg>
	);
}
