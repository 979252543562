import {
	IonApp,
	IonContent,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonLoading,
	IonPage,
} from "@ionic/react";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import NavFooter from "components/mobile-components/Common/NavFooter";
import OrderStatus from "components/mobile-components/OrderList/OrderStatus";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TitleNSearch from "components/mobile-components/Common/TitleNSearch";
import { fetchOrder, searchOrder } from "redux/container/orderSlice";
import AccountsStatus from "components/mobile-components/Accounts/AccountsStatus";
import AccountBottomSheet from "components/mobile-components/Accounts/AccountBottomSheet";
import TextInputWithIcon from "components/mobile-components/Common/TextInputWithIcon";
import ExpenseForm from "components/mobile-components/Accounts/ExpenseForm";

export default function AccountExpensePage() {
	return (
		<IonApp>
			<IonPage>
				<IonContent
					scrollEvents={true}
					// onIonScrollEnd={(ev) => {
					// 	getOrderList(orderStatus);
					// }}
				>
					<BackButtonTitle title='Add Expense' />

					<section className='pt-14 px-6'>
						<ExpenseForm />
					</section>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
