import React, { useState } from "react";
import Sheet from "react-modal-sheet";
import Print from "assets/images/icon/svg/Print";
import { IonItem } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { setBottomSheet } from "redux/container/userSlice";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import CancelOrder from "assets/images/icon/svg/CancelOrder";
import CancelPopUp from "assets/images/icon/svg/CancelPopUp";
import moment from "moment";
import Orders from "services/Orders";
import { fetchOrderSingle } from "redux/container/orderSlice";
import { useNavigate } from "react-router-dom";

export default function BottomSheet({ order }) {
	const ref = React.useRef();
	const isOpenBottomSheet = useSelector((state) => state.user.bottomSheet);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const dispatch = useDispatch();
	const [isOpen, setIsOpen] = useState(false);
	const navigate = useNavigate();
	let timeOut = false;

	const onOpenModal = () => {
		setIsOpen(true);
	};

	const onCloseModal = () => {
		setIsOpen(false);
	};
	let startDayTime = moment().utc().startOf("day").toString();
	let dayEndTime = moment().utc().endOf("day").toString();
	let orderCreateTime = moment(order?.createdAt);

	if (
		Date.parse(dayEndTime) > Date.parse(orderCreateTime) &&
		Date.parse(startDayTime) < Date.parse(orderCreateTime)
	) {
		timeOut = true;
		// setTimeout(true);
	} else {
		// setTimeout(false);
	}
	let orderCancelHandler = async () => {
		let res = await Orders.orderCancel(order._id);
		if (res.status === 200) {
			setIsOpen(false);
			dispatch(setBottomSheet(false));
			dispatch(fetchOrderSingle(order._id));
		}
	};

	return (
		<div>
			<Sheet
				className='bottom-sheet'
				initialSnap={1}
				ref={ref}
				isOpen={isOpenBottomSheet}
				snapPoints={[200, 200, 100, 0]}
				onClose={() => dispatch(setBottomSheet(false))}>
				<Sheet.Container>
					<Sheet.Header />
					<Sheet.Content>
						<IonItem
							button
							onClick={() => navigate(`/invoice/${order?._id}`)}
							lines='none'>
							<span className='py-[5px]'>
								<Print />
							</span>
							<p className='text-14 font-normal text-black-1000 pl-2'>
								{ekkLanguage.orderDetails.downloadInvoices}
							</p>
						</IonItem>
						<IonItem
							button
							onClick={() => navigate(`/receipt/${order?._id}`)}
							lines='none'>
							<span className='py-[5px]'>
								<Print />
							</span>
							<p className='text-14 font-normal text-black-1000 pl-2'>
								{ekkLanguage.orderDetails.receiptDownloads}
							</p>
						</IonItem>
						{order?.order_status === "Placed" && (
							<IonItem button onClick={() => onOpenModal()} lines='none'>
								<span className='py-[5px]'>
									<CancelOrder />
								</span>
								<p className='text-14 font-normal text-black-1000 pl-2'>
									{ekkLanguage.orderDetails.cancelOrder}
								</p>
							</IonItem>
						)}
					</Sheet.Content>
				</Sheet.Container>

				<Sheet.Backdrop onClick={() => dispatch(setBottomSheet(false))} />
			</Sheet>
			<Modal
				open={isOpen}
				onClose={onCloseModal}
				classNames='bottom-sheet-modal'>
				{timeOut ? (
					<div className='p-5'>
						<span className='mb-2 inline-block mx-2'>
							<CancelPopUp />
						</span>
						<h6 className='text-16 font-bold pb-[10px]'>
							{ekkLanguage.orderDetails.cancelConformationAsk}
						</h6>

						<div className='flex'>
							<button
								className='border-2 font-bold h-10 rounded-[7px] w-1/2 mr-2 text-black-1000 border-black-200 border-solid'
								onClick={onCloseModal}>
								{ekkLanguage.orderDetails.noBtn}
							</button>
							<button
								color='primary'
								className='font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-primary text-white'
								onClick={orderCancelHandler}>
								{ekkLanguage.orderDetails.yesBtn}
							</button>
						</div>
					</div>
				) : (
					<div className='p-5'>
						<p>{ekkLanguage.orderDetails.cancelConformationParagraph}</p>
					</div>
				)}
			</Modal>
		</div>
	);
}
