import Pen from "assets/images/icon/svg/Pen";
import { getUserBusiness } from "config/user";
import { BUSINESS_TYPE } from "data/businessType";
import { digitChanger } from "helpers/helper";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

export default function IdentityCard({ user, businessData }) {
	const userData = useSelector((state) => state.user.data);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let userBusiness = getUserBusiness();
	const navigate = useNavigate();

	return (
		<div className='px-4 mb-[10px]'>
			<div className=' bg-white rounded-[30px] rounded-t-none px-4 pb-1'>
				<div className='flex items-center justify-center'>
					<div className='pt-4 pb-[10px]'>
						<div
							className='relative  rounded-full'
							style={{
								boxShadow: "0px 2px 15px 0px rgba(0, 0, 0, 0.10)",
							}}>
							{businessData && Array.isArray(businessData.images) ? (
								<img
									src={businessData?.images[0]}
									alt='avatar'
									className='m-auto h-24 w-24 rounded-full object-cover'
								/>
							) : (
								<img
									src='assets/images/avatar.png'
									alt='avatar'
									className='m-auto h-24 w-24 rounded-full object-cover'
								/>
							)}
							<div onClick={() => navigate("/business-edit")}>
								<span
									className='absolute h-10 w-10 rounded-full text-center flex items-center justify-center right-0 bottom-0 border-4 border-E6E6E6'
									style={{
										background: "#5A7EFF",
										lineHeight: "28px",
									}}>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='17'
										height='16'
										viewBox='0 0 17 16'
										fill='none'>
										<path
											d='M15.7732 4.7772C16.0563 4.5658 16.0231 4.12507 15.7732 3.87522L12.7573 0.859259C12.5074 0.609414 12.1052 0.609414 11.8553 0.859259L6.96398 5.7421L2.07921 10.6184C1.82714 12.1821 1.56848 13.7524 1.31641 15.3162L5.99657 14.5358C8.52671 12.0056 9.72234 10.8145 10.9291 9.61228C12.1358 8.41005 13.3227 7.22774 15.7732 4.7772V4.7772Z'
											stroke='white'
											strokeWidth='0.91'
											strokeMiterlimit='6.2'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
										<path
											d='M14.2627 6.28811L14.3159 6.2349C14.4812 6.06957 14.4812 5.80152 14.3159 5.6362L10.9499 2.27018C10.7846 2.10485 10.5165 2.10485 10.3512 2.27018L10.298 2.32339C10.1327 2.48872 10.1327 2.75677 10.298 2.92209L13.664 6.28811C13.8293 6.45344 14.0973 6.45344 14.2627 6.28811Z'
											fill='white'
										/>
										<path
											d='M4.11325 15.2453L1.42269 12.5547L1.31641 15.3139L4.11325 15.2453Z'
											fill='white'
										/>
										<path
											d='M6.36228 14.1377L6.4155 14.0845C6.58082 13.9192 6.58082 13.6511 6.4155 13.4858L3.04951 10.1198C2.88419 9.95446 2.61614 9.95446 2.45082 10.1198L2.3976 10.173C2.23228 10.3383 2.23228 10.6064 2.3976 10.7717L5.76359 14.1377C5.92891 14.303 6.19696 14.303 6.36228 14.1377Z'
											fill='white'
										/>
									</svg>
								</span>
							</div>
						</div>
					</div>
				</div>
				<div className='m-auto text-center'>
					<h4 className='font-bold text-16 text-222222 pb-1'>
						{businessData?.business_name}
					</h4>
					<div className='flex items-center pb-1 justify-center'>
						{businessData?.business_type_obj?.image && (
							<img
								src={businessData?.business_type_obj?.image}
								alt='img'
								className='w-4 h-4 mr-1'
							/>
						)}
						<h6 className='text-12 font-semibold text-222222'>
							{businessData?.business_type}
						</h6>
					</div>
					<div>
						<span className='text-12 text-black-500'>
							{businessData?.phone_number}
						</span>
					</div>
					<span className='text-12 text-black-500'>
						{businessData?.address_line}
					</span>
					<div className='flex justify-center pt-1 pb-5'>
						{businessData?.business_status === "Verified" ? (
							<div className='flex pt-1 justify-center px-2'>
								<h6 className='pt-1 px-3 bg-success rounded-[25px] text-white flex'>
									<span className='mt-[3px] mr-[5px]'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='12'
											height='12'
											viewBox='0 0 12 12'
											fill='none'>
											<path
												d='M3.87459 10.3306C3.53396 10.3307 3.20728 10.1953 2.96662 9.95428L0.22153 7.2102C-0.0738435 6.91473 -0.0738435 6.43578 0.22153 6.14031C0.516999 5.84494 0.995953 5.84494 1.29142 6.14031L3.87459 8.72348L10.7086 1.8895C11.004 1.59413 11.483 1.59413 11.7785 1.8895C12.0738 2.18497 12.0738 2.66392 11.7785 2.95939L4.78256 9.95428C4.5419 10.1953 4.21523 10.3307 3.87459 10.3306Z'
												fill='white'
											/>
										</svg>
									</span>
									{ekkLanguage.businessProfile.verified}
								</h6>
							</div>
						) : (
							<h6 className='pt-1 px-3 bg-primary rounded-[25px] text-white flex'>
								{ekkLanguage.businessProfile.unVerified}
							</h6>
						)}
						<div className='flex pt-1 justify-center px-2'>
							<h6
								className='py-1 px-3 rounded-[25px] text-white flex'
								style={{ background: "#515151" }}>
								<span className='mt-[3px] mr-[5px]'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='12'
										height='11'
										viewBox='0 0 12 11'
										fill='none'>
										<path
											d='M5.52447 0.463525C5.67415 0.00286925 6.32585 0.00286996 6.47553 0.463525L7.45934 3.49139C7.52628 3.6974 7.71826 3.83688 7.93487 3.83688H11.1186C11.6029 3.83688 11.8043 4.45669 11.4124 4.74139L8.83679 6.61271C8.66155 6.74003 8.58822 6.96572 8.65516 7.17173L9.63897 10.1996C9.78864 10.6602 9.2614 11.0433 8.86955 10.7586L6.29389 8.88729C6.11865 8.75997 5.88135 8.75997 5.70611 8.88729L3.13045 10.7586C2.73859 11.0433 2.21136 10.6602 2.36103 10.1996L3.34484 7.17173C3.41178 6.96572 3.33845 6.74003 3.16321 6.61271L0.587553 4.74139C0.195696 4.45669 0.397084 3.83688 0.881446 3.83688H4.06513C4.28174 3.83688 4.47372 3.6974 4.54066 3.49139L5.52447 0.463525Z'
											fill='#FFE600'
										/>
									</svg>
								</span>
								{digitChanger("0.0")}
							</h6>
						</div>
					</div>
				</div>
				{/* <div className='mt-5'>
					<Link
						to=''
						className='flex justify-between items-center rounded-[32px] mb-3 py-2 px-4'
						style={{ background: "#F3F3F3" }}>
						<div className='flex items-center'>
							<img
								src='assets/images/reload.png'
								alt='icon'
								className='mr-[5px] h-6 w-6'
							/>
							<span className='text-16 font-semibold text-222222'>
								{ekkLanguage.businessProfile.switchBusiness}
							</span>
						</div>

						<span>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='16'
								height='16'
								viewBox='0 0 16 16'
								fill='none'>
								<path
									d='M4.05273 15.0003C4.05297 14.7352 4.15848 14.481 4.34607 14.2937L9.46073 9.179C9.61555 9.02423 9.73835 8.84048 9.82214 8.63824C9.90592 8.43601 9.94905 8.21924 9.94905 8.00034C9.94905 7.78143 9.90592 7.56467 9.82214 7.36243C9.73835 7.16019 9.61555 6.97644 9.46073 6.82167L4.35273 1.71034C4.17058 1.52173 4.06978 1.26913 4.07206 1.00694C4.07434 0.744739 4.17951 0.493926 4.36492 0.308518C4.55032 0.12311 4.80114 0.0179412 5.06333 0.0156628C5.32553 0.0133844 5.57813 0.114179 5.76673 0.296337L10.8747 5.40367C11.5616 6.09185 11.9473 7.0244 11.9473 7.99667C11.9473 8.96894 11.5616 9.9015 10.8747 10.5897L5.76007 15.7043C5.62041 15.8441 5.4425 15.9393 5.24877 15.9781C5.05503 16.0168 4.85417 15.9973 4.67153 15.922C4.48888 15.8466 4.33263 15.7189 4.22251 15.5549C4.11238 15.3909 4.0533 15.1979 4.05273 15.0003Z'
									fill='#374957'
								/>
							</svg>
						</span>
					</Link>
				</div> */}
			</div>
			<div className='bg-white rounded-[30px] p-4 my-4 flex justify-between items-center'>
				<div className='flex items-center'>
					<div className='flex items-center justify-center pr-4'>
						<div
							className='relative rounded-full'
							style={{
								boxShadow: "0px 2px 15px 0px rgba(0, 0, 0, 0.10)",
							}}>
							{userData?.profile_image ? (
								<img
									src={userData?.profile_image}
									alt='avatar'
									className='m-auto h-[50px] w-[50px] rounded-full'
								/>
							) : (
								<img
									src='assets/images/avatar.png'
									alt='avatar'
									className='m-auto h-[50px] w-[50px] rounded-full'
								/>
							)}
							<span className='absolute h-[15px] w-[15px] rounded-full bg-success flex items-center justify-center right-0 bottom-0'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='10'
									height='10'
									viewBox='0 0 10 10'
									fill='none'>
									<path
										d='M3.22883 8.60948C2.94497 8.60959 2.67274 8.49677 2.47219 8.29588L0.184609 6.00915C-0.0615362 5.76293 -0.0615362 5.3638 0.184609 5.11758C0.430832 4.87143 0.829961 4.87143 1.07618 5.11758L3.22883 7.27022L8.92382 1.57523C9.17004 1.32909 9.56917 1.32909 9.81539 1.57523C10.0615 1.82146 10.0615 2.22059 9.81539 2.46681L3.98547 8.29588C3.78492 8.49677 3.51269 8.60959 3.22883 8.60948Z'
										fill='white'
									/>
								</svg>
							</span>
						</div>
					</div>
					<div>
						<h6 className='text-14 text-222222 font-bold mb-1'>
							{userData?.full_name}
						</h6>
						<p className='text-12 font-semibold text-black-500'>
							{digitChanger(user?.PhoneNumber)} {"-"}
							{userBusiness?.role}
						</p>
					</div>
				</div>
				<div>
					{userBusiness.role === "Admin" ? (
						<span
							onClick={() => navigate("/contact")}
							className='h-[50px] w-[50px] rounded-full text-center flex items-center justify-center right-1 bottom-0 border-4 border-E6E6E6'
							style={{ background: "#5A7EFF", lineHeight: "28px" }}>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='20'
								height='20'
								viewBox='0 0 20 20'
								fill='none'>
								<path
									d='M18.5034 6.06059C18.8495 5.80222 18.8088 5.26354 18.5034 4.95817L14.8173 1.27199C14.5119 0.966627 14.0202 0.966627 13.7149 1.27199L7.73657 7.23992L1.7663 13.1998C1.45822 15.111 1.14208 17.0303 0.833984 18.9416L6.55419 17.9877C9.64658 14.8953 11.1079 13.4395 12.5828 11.9701C14.0577 10.5007 15.5084 9.05569 18.5034 6.06059V6.06059Z'
									stroke='white'
									strokeWidth='1.13746'
									strokeMiterlimit='6.2'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
								<path
									d='M16.657 7.90748L16.722 7.84243C16.9241 7.64037 16.9241 7.31276 16.722 7.11069L12.608 2.99667C12.406 2.7946 12.0784 2.7946 11.8763 2.99667L11.8113 3.06171C11.6092 3.26377 11.6092 3.59139 11.8113 3.79345L15.9252 7.90748C16.1273 8.10954 16.4549 8.10954 16.657 7.90748Z'
									fill='white'
								/>
								<path
									d='M4.25235 18.8549L0.963892 15.5664L0.833984 18.9388L4.25235 18.8549Z'
									fill='white'
								/>
								<path
									d='M6.99878 17.5012L7.06382 17.4362C7.26588 17.2341 7.26588 16.9065 7.06382 16.7044L2.94984 12.5904C2.74778 12.3884 2.42017 12.3884 2.2181 12.5904L2.15306 12.6555C1.951 12.8575 1.951 13.1851 2.15306 13.3872L6.26704 17.5012C6.4691 17.7033 6.79671 17.7033 6.99878 17.5012Z'
									fill='white'
								/>
							</svg>
						</span>
					) : (
						<img
							src='assets/images/verified.png'
							alt='verified'
							className='h-[50px] w-[50px] m-auto'
						/>
					)}
				</div>
			</div>
		</div>
	);
}
