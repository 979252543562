import { getUser, getUserBusiness } from "config/user";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
	setEmployeeData,
	setEmployeeSetting,
	setMyEmployees,
	resetEmployeeSetting,
} from "redux/container/userSlice";
import Business from "services/Business";
import EmployeesPosition from "./EmployeePosition";
import MobileNumber from "./MobileNumber";
import { toast } from "react-toastify";
import TextInputWithIcon from "../Common/TextInputWithIcon";
import { IonSpinner, IonToggle } from "@ionic/react";
import Auth from "services/Auth";
import { COUNTRYINFO } from "data/country";

export default function CreateEmployees({ employees }) {
	let user = getUser();
	const { employeeData, employeeSetting } = useSelector((state) => state.user);

	let selectedCountry = COUNTRYINFO[user?.country];
	const [countryCode, setCountryCode] = useState(
		employeeData?.countryCode ?? selectedCountry?.code
	);
	const [country, setCountry] = useState(
		employeeData?.country ?? selectedCountry?.name
	);

	const [phoneNo, setPhoneNo] = useState(employeeData?.phoneNo ?? "");
	const [name, setName] = useState(employeeData?.name ?? "");
	const [salaryAmount, setSalaryAmount] = useState(
		employeeData?.salaryAmount ?? ""
	);
	const [isHourly, setIsHourly] = useState(employeeData?.isHourly ?? true);

	const [nameEmpty, setNameEmpty] = useState("");
	const [roleEmpty, setRoleEmpty] = useState("");
	const [employeePosition, setEmployeePosition] = useState(
		employeeData?.title ?? "Select employee position"
	);
	const [password, setPassword] = useState(employeeData?.password ?? "");
	const [passwordEmpty, setPasswordEmpty] = useState("");

	const [employeePositionType, setEmployeePositionType] = useState();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let useBusiness = getUserBusiness();
	let navigate = useNavigate();
	let dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const [activityCheckLoading, setActivityCheckLoading] = useState(false);

	const employeeSubmission = async () => {
		if (employees) {
			let req_body = {
				display_name: name,
				role: employeePositionType,
			};
			setIsLoading(true);
			let response = await Business.updateEmployee(employees.id, req_body);
			if (response.status === 200) {
				navigate("/employees-list");
				dispatch(setMyEmployees(false));
			}
			setIsLoading(false);
		} else {
			if (!name) {
				setNameEmpty(ekkLanguage.createEmployees.nameError);
				return;
			}
			if (!password && employeeSetting?.isNeededPassword) {
				setPasswordEmpty(ekkLanguage.createEmployees.passwordWarning);
				return;
			}
			if (employeePosition === "Select employee position") {
				setRoleEmpty(ekkLanguage.createEmployees.roleError);
				return;
			}
			if (country === "Bangladesh") {
				if (
					!(
						phoneNo.trim().length > 10 &&
						phoneNo.trim().length < 12 &&
						phoneNo[0] == 0
					)
				) {
					return;
				}
			}

			setIsLoading(true);
			let res = await Business.checkBusiness(useBusiness.id);
			if (res.status === 200) {
				setIsLoading(false);
				let req_body = {
					phone_number: (countryCode + phoneNo).trim(),
					business: res.results.id,
					display_name: name,
					role: employeeData?.id,
					employee_salary: {
						amount: salaryAmount,
						salary_type: isHourly ? "Hourly" : "Monthly",
					},
					...(password && { password: password, country_name: country }),
				};
				let response = await Business.createEmployee(req_body);
				if (response.status === 201) {
					setIsLoading(false);
					toast.success("Employee created successfully");
					dispatch(setEmployeeData({}));
					dispatch(resetEmployeeSetting());
					navigate("/employees-list");
					dispatch(setMyEmployees(false));
				} else {
					setIsLoading(false);
					toast.error(response.message);
				}
			} else {
				setIsLoading(false);
			}
		}
	};

	useEffect(() => {
		if (employees) {
			setEmployeePositionType(employees?.role?.id);
			setEmployeePosition(employees?.role?.title);
			setName(employees?.display_name ?? "");
			dispatch(
				setEmployeeData({
					...employeeData,
					name: employees?.display_name,
					id: employees?.role?.id,
					title: employees?.role?.title,
				})
			);
		}
	}, [employees?.id]);

	useEffect(() => {
		if (name?.length) {
			dispatch(
				setEmployeeData({
					...employeeData,
					name: name,
					password: password,
					salaryAmount: salaryAmount,
				})
			);
		}
	}, [name, password, salaryAmount]);

	const activeCheck = async () => {
		setActivityCheckLoading(true);
		let req_body = {
			country,
			phone_number: (countryCode + phoneNo).trim(),
		};
		let unique_number = await Business.checkBusinessEmployee(req_body?.phone_number);

		if (unique_number.success) {
			try {
				let response = await Auth.countryAndUserFilter(req_body);
				if (response.status === 200) {
					if (response.data.body.user_status === "Incomplete Profile") {
						setActivityCheckLoading(false);
						dispatch(
							setEmployeeSetting({
								...employeeSetting,
								checkStatus: true,
								isNeededPassword: true,
								isCheckedNumber: true,
							})
						);
					} else {
						setActivityCheckLoading(false);
						dispatch(
							setEmployeeSetting({
								...employeeSetting,
								checkStatus: true,
								isNeededPassword: false,
								isCheckedNumber: true,
							})
						);
					}
				} else if (response.status === 201) {
					setActivityCheckLoading(false);
					dispatch(
						setEmployeeSetting({
							...employeeSetting,
							checkStatus: true,
							isNeededPassword: true,
							isCheckedNumber: true,
						})
					);
				}
			} catch (e) {
				console.log(e);
			}
		}else{
			setActivityCheckLoading(false);
			if (unique_number.status === 406) {
				toast.error(unique_number.message);
			} else {
				toast.error("Something went wrong");
			}
		}
	};

	const handleToggleChange = (e) => {
		const newValue = e.detail.checked;
		setIsHourly(newValue);
		dispatch(
			setEmployeeData({
				...employeeData,
				isHourly: newValue,
			})
		);
	};

	// const setPasswordHandler = async () => {
	// 	setActivityCheckLoading(true);
	// 	let req_body = {
	// 		password: password,
	// 		country: country,
	// 		phone_number: (countryCode + phoneNo).trim(),
	// 	};

	// 	try {
	// 		let response = await Auth.updatePassword(req_body);
	// 		if (response.status === 200 || response.status === 201) {
	// 			dispatch(
	// 				setEmployeeSetting({
	// 					...employeeSetting,
	// 					isCheckedNumber: true,
	// 				})
	// 			);
	// 			setActivityCheckLoading(false);
	// 			toast.success(ekkLanguage.changePass.successLabel);
	// 		} else {
	// 			setActivityCheckLoading(false);
	// 			toast.error(ekkLanguage.changePass.errorLabel);
	// 		}
	// 	} catch (e) {
	// 		setActivityCheckLoading(false);
	// 		console.log(e);
	// 	}
	// };
	return (
		<section className='px-4 pb-24 pt-14 h-screen relative'>
			{employees ? (
				<>
					<label
						className={`text-16 md:text-16 font-bold text-262626-1000 sm:pb-3 pb-[10px] block pt-4`}>
						{ekkLanguage?.createEmployees.employeeNumber}
					</label>
					<div
						className='mb-4 bg-white rounded-[5px] h-[50px] flex items-center pl-4'
						style={{ boxShadow: "rgba(0, 0, 0, 0.05) 0px 5px 20px" }}>
						<h6 className='text-success text-18 font-bold'>
							{employees?.phone_number}
						</h6>
					</div>
				</>
			) : (
				<>
					{employeeSetting?.isCheckedNumber ? (
						<>
							<label
								className={`text-16 md:text-16 font-bold text-262626-1000 sm:pb-3 pb-[10px] block pt-4`}>
								{ekkLanguage?.createEmployees.employeeNumber}
							</label>
							<div
								className='mb-4 bg-white rounded-[5px] h-[50px] flex items-center pl-4'
								style={{
									boxShadow: "rgba(0, 0, 0, 0.05) 0px 5px 20px",
								}}>
								<h6 className='text-success text-18 font-bold'>
									{(countryCode + phoneNo).trim()}
								</h6>
							</div>
						</>
					) : (
						<MobileNumber
							countryCode={countryCode}
							setCountryCode={setCountryCode}
							country={country}
							setCountry={setCountry}
							phoneNo={phoneNo}
							setPhoneNo={setPhoneNo}
							employees={employees}
						/>
					)}
				</>
			)}

			{employeeSetting?.checkStatus ? (
				<>
					{employeeSetting?.isNeededPassword ? (
						<div className='text-left'>
							<h5 className='text-dark-1000 text-16 mb-3 font-semibold'>
								Set Password
							</h5>

							<TextInputWithIcon
								type='text'
								inputText={password}
								setInputText={setPassword}
								placeholder='Write set password'
							/>
							{passwordEmpty && (
								<div className='text-primary mt-2 text-right'>
									{passwordEmpty}
								</div>
							)}
						</div>
					) : null}

					<div className='text-left'>
						<h5 className='text-dark-1000 text-16 mb-3 font-semibold'>
							{ekkLanguage.createEmployees.name}
						</h5>

						<TextInputWithIcon
							type='text'
							inputText={name}
							setInputText={setName}
							placeholder={ekkLanguage.createEmployees.namePlaceholder}
						/>
						{nameEmpty && (
							<div className='text-primary mt-2 text-right'>
								{nameEmpty}
							</div>
						)}
					</div>
					<EmployeesPosition
						employeePosition={employeePosition}
						setEmployeePosition={setEmployeePosition}
						roleEmpty={roleEmpty}
						setRoleEmpty={setRoleEmpty}
						setEmployeePositionType={setEmployeePositionType}
					/>
					<div className='text-left flex items-center justify-between'>
						<h5 className='text-dark-1000 text-16 mb-3 font-semibold'>
							Salary Type
						</h5>
						<div>
							<IonToggle
								labelPlacement='start'
								height='100'
								aria-label='Primary toggle'
								size='large'
								color='success'
								checked={isHourly}
								onIonChange={handleToggleChange}>
								<span className='font-bold text-14'>
									{isHourly ? "Hourly" : "Monthly"}
								</span>
							</IonToggle>
						</div>

						{/* <IonList
							style={{
								padding: 0,
								border: "1px solid #666",
								borderRadius: "5px",
							}}>
							<IonItem>
								<IonSelect
									color='success'
									value={employeeData?.salaryType ?? "Hourly"}
									onIonChange={(e) => {
										dispatch(
											setEmployeeData({
												...employeeData,
												salaryType: e.detail.value,
											})
										);
									}}>
									<IonSelectOption value='Hourly'>
										Hourly
									</IonSelectOption>
									<IonSelectOption value='Monthly'>
										Monthly
									</IonSelectOption>
									<IonSelectOption value='Yearly'>
										Yearly
									</IonSelectOption>
								</IonSelect>
							</IonItem>
						</IonList> */}
					</div>

					<div className='text-left mb-4'>
						<h5 className='text-dark-1000 text-16 mb-3 font-semibold'>
							Salary
						</h5>

						<TextInputWithIcon
							type='number'
							inputText={salaryAmount}
							setInputText={setSalaryAmount}
							placeholder={`Enter salary ${
								isHourly ? "hourly" : "monthly"
							} amount`}
						/>
					</div>

					<div>
						<div className='text-center  w-full'>
							<button
								onClick={!isLoading && employeeSubmission}
								style={{ background: "#00B224" }}
								className={`text-18 sm:text-18 font-semibold text-white  h-[50px] sm:h-[50px] rounded-[5px] w-full flex justify-center items-center`}>
								{isLoading ? (
									<IonSpinner color='light' />
								) : (
									ekkLanguage?.createEmployees.confirmBtn
								)}
							</button>
						</div>
					</div>
				</>
			) : (
				<>
					<div className='pt-10'>
						<div className='text-center  w-full pt-[30px]'>
							<button
								onClick={!activityCheckLoading && activeCheck}
								style={{ background: "#00B224" }}
								className={`text-18 sm:text-18 font-semibold text-white  h-[50px] sm:h-[50px] rounded-[5px] w-full flex justify-center items-center`}>
								{activityCheckLoading ? (
									<IonSpinner color='light' />
								) : (
									"Next"
								)}
							</button>
						</div>
					</div>
				</>
			)}
		</section>
	);
}
