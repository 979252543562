import { IonApp, IonContent, IonPage } from "@ionic/react";
import AccountDashboard from "components/mobile-components/AccountDashboard";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import React from "react";

export default function AccountDashboardPage() {
	const screenSize = window.matchMedia("(max-width: 400px)")?.matches;
	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle title='Dashboard' />
					<section className={`pt-14 ${!screenSize && "px-4"}`}>
						<AccountDashboard />
					</section>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
