import { IonSegment, IonSegmentButton } from "@ionic/react";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSupplier } from "redux/container/supplierSlice";

function SellerFilter({ ekkLanguage, setReachedEnd, setHasMoreData }) {
	const dispatch = useDispatch();
	const locatedSeller = useSelector((state) => state.supplier.data);

	return (
		<IonSegment
			scrollable
			className='orderBtnWrapper'
			value={locatedSeller ? "nearbySeller" : "allSeller"}
			onIonChange={(e) => {
				setHasMoreData(true);
				setReachedEnd(false);
				dispatch(
					setSupplier(e.target.value === "nearbySeller" ? true : false)
				);
				// selectCustomer(e.target.value);
			}}>
			<IonSegmentButton
				value='allSeller'
				className='no-ripple recent'
				// onClick={() => {
				// 	setLocatedSeller(false);
				// 	getAllSupplierList();
				// }}
			>
				<button className='text-black font-sans p-0 font-normal shadow-none text-14'>
					{ekkLanguage.sellerList.allSeller}
				</button>
			</IonSegmentButton>
			<IonSegmentButton
				value='nearbySeller'
				className='text-black recent no-ripple'
				// onClick={() => {
				// 	setLocatedSeller(true);
				// 	getSupplierList();
				// }}
			>
				<button className='text-black font-sans p-0 font-normal shadow-none text-14'>
					{ekkLanguage.sellerList.nearBySeller}
				</button>
			</IonSegmentButton>
		</IonSegment>
	);
}
export default memo(SellerFilter);
