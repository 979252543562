import React, { useState } from "react";
import Sheet from "react-modal-sheet";
import Print from "assets/images/icon/svg/Print";
import { IonItem } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { setAccountBottomSheet } from "redux/container/userSlice";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { ClipLoader } from "react-spinners";
import CancelPopUp from "assets/images/icon/svg/CancelPopUp";

export default function AccountBottomSheet({ order }) {
  const ref = React.useRef();
  const isOpenBottomSheet = useSelector(
    (state) => state.user.accountBottomSheet
  );
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [addExpense, setAddExpense] = useState(false);
  const openExpense = () => setAddExpense(true);
  const closeExpense = () => setAddExpense(false);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      width: "300px",
      transition: ".4s all ease",
      borderRadius: 12,
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      transition: ".4s all ease",
      backgroundColor: "rgba(0,0,0,.5)",
    },
  };

  return (
    <div>
      <Sheet
        className="bottom-sheet"
        initialSnap={1}
        ref={ref}
        isOpen={isOpenBottomSheet}
        snapPoints={[180, 180, 100, 0]}
        onClose={() => dispatch(setAccountBottomSheet(false))}
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <IonItem
              button
              onClick={() => {
                dispatch(setAccountBottomSheet(false));
                navigate(`/revenue-form`);
              }}
              lines="none"
            >
              <span className="py-[5px]">
                <img
                  src="assets/images/revenue.jpg"
                  alt="icon"
                  className="w-10"
                />
              </span>
              <p className="text-16 font-semibold text-black-1000 pl-2">
                {ekkLanguage.accounting.addRevenue}
              </p>
            </IonItem>
            <IonItem
              button
              onClick={() => {
                dispatch(setAccountBottomSheet(false));
                navigate(`/expense-form`);
              }}
              lines="none"
            >
              <span className="py-[5px]">
                <img
                  src="assets/images/expense.jpg"
                  alt="icon"
                  className="w-10"
                />
              </span>
              <p className="text-16 font-semibold text-black-1000 pl-2">
                {ekkLanguage.accounting.addExpense}
              </p>
            </IonItem>
          </Sheet.Content>
        </Sheet.Container>

        <Sheet.Backdrop
          onClick={() => dispatch(setAccountBottomSheet(false))}
        />
      </Sheet>
      {/* <Modal
				style={customStyles}
				closeTimeoutMS={200}
				isOpen={addExpense}
				onRequestClose={closeExpense}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2'>
						<CancelPopUp />
					</span>
					<h6 className='text-16 font-bold pb-[30px]'>{warningMsg}</h6>

					<div className='flex justify-end'>
						{!dueAmount && (
							<>
								<button
									color='primary'
									className={`${
										loader && "select-none cursor-no-drop"
									} font-bold h-10 rounded-[7px] w-1/2 mr-2 border-2 bg-primary text-white flex justify-center items-center`}
									onClick={!loader ? optSend : undefined}>
									{loader ? (
										<ClipLoader
											sizeUnit={"px"}
											size={20}
											color={"white"}
											loading={true}
										/>
									) : (
										ekkLanguage.orderDetails.yesBtn
									)}
								</button>
								<button
									className='border-2 font-bold h-10 rounded-[7px] w-1/2 ml-2 bg-success text-white'
									onClick={closeExpense}>
									{ekkLanguage.orderDetails.noBtn}
								</button>
							</>
						)}
					</div>
				</div>
			</Modal> */}
    </div>
  );
}
