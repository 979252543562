import { IonCol, IonGrid, IonLoading, IonRow } from "@ionic/react";
import Share from "assets/images/icon/svg/Share";
import { digitChanger } from "helpers/helper";
import { useSelector } from "react-redux";
import RecommendProduct from "../Home/RecommendProduct";
import ProductFilter from "../SelectProduct/ProductFilter";
import ProductSearch from "../SelectProduct/ProductSearch";
import UpdatedProduct from "../Home/UpdatedProduct";
import { getLanguage } from "config/language";
import SingleAMountBox from "./SingleAMountBox";

export default function SuppliersDetails({
  supplierInfo,
  product,
  settinges,
  productCount,
  productBrands,
  getProductsFromBrands,
  getProductBrands,
  setSearchQ,
  searchQ,
  singleProductSearch,
  setSelectedBrand,
  sellerProductLoader,
  getAllProduct,
  tempSearchParams,
  setTempSearchParams,
  customer,
  setHasMoreData,
  setReachedEnd,
}) {
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const appLanguage = getLanguage();
  return (
    <div className="porduct-details">
      <IonGrid>
        <IonRow className="px-2">
          <IonCol size="12">
            <div>
              <h5 className="font-semibold text-20 text-dark pt-2 pb-3">
                {supplierInfo?.business_name}
              </h5>
              {/* <p className='text-16 text-black-500 font-semibold'>
								{digitChanger(supplierInfo?.address_line)}
							</p> */}
              {/* <p className='text-18 text-primary font-semibold'>
								{digitChanger(supplierInfo?.publish_products_count)}{" "}
								
							</p> */}
              <p className="text-18 text-primary font-semibold">
                {digitChanger(supplierInfo?.publish_products_count)}{" "}
                {appLanguage === "bn"
                  ? "টি পণ্য"
                  : appLanguage === "vn"
                  ? "Sản phẩm"
                  : appLanguage === "my"
                  ? "Produk"
                  : "Products"}
              </p>
            </div>
          </IonCol>
          <IonCol size="12" className="">
            <ul className="flex justify-between">
              {/* <li>
								<div className='flex items-center'>
									<Star width={15} height={15} color='#FF564C' />
									<Star width={15} height={15} color='#FF564C' />
									<Star width={15} height={15} color='#FF564C' />
									<Star width={15} height={15} color='#FF564C' />
									<Star width={15} height={15} color='#00000030' />
									<div className='pl-2'>
										<p
											className='text-16'
											style={{ color: "#00000080" }}>
											4.0
										</p>
									</div>
								</div>
							</li>
							<li>
								<div>
									<p className='text-16 text-000054'>3.2k Sold</p>
								</div>
							</li>
							<li>
								<div>
									<HeartOutline />
								</div>
							</li> */}
              <li>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                  >
                    <path
                      d="M24.0005 19.6301C22.812 19.6301 21.743 20.1301 20.984 20.9281L12.1155 16.5861C12.146 16.3821 12.167 16.1756 12.167 15.9631C12.167 15.7561 12.147 15.5541 12.118 15.3556L20.9735 11.0611C21.733 11.8656 22.807 12.3706 24.0005 12.3706C26.3015 12.3706 28.167 10.5051 28.167 8.2041C28.167 5.9026 26.3015 4.0376 24.0005 4.0376C21.6995 4.0376 19.834 5.9026 19.834 8.2041C19.834 8.4111 19.854 8.6126 19.883 8.8116L11.0275 13.1061C10.268 12.3016 9.19449 11.7966 8.00048 11.7966C5.69898 11.7966 3.83398 13.6621 3.83398 15.9631C3.83398 18.2646 5.69898 20.1296 8.00048 20.1296C9.18899 20.1296 10.258 19.6296 11.017 18.8316L19.8855 23.1736C19.855 23.3771 19.834 23.5841 19.834 23.7966C19.834 26.0981 21.6995 27.9631 24.0005 27.9631C26.3015 27.9631 28.167 26.0981 28.167 23.7966C28.167 21.4951 26.3015 19.6301 24.0005 19.6301Z"
                      fill="black"
                      fillOpacity="0.5"
                    />
                  </svg>
                </div>
              </li>
            </ul>
          </IonCol>
        </IonRow>
        <div className="pt-4 px-4 pb-2">
          <div className="grid grid-cols-2 gap-4">
            <SingleAMountBox
              name={ekkLanguage.dashboard.totalOrder}
              value={customer?.total_orders}
              withComma={false}
            />
            <SingleAMountBox
              name={ekkLanguage.dashboard.totalAmount}
              value={customer?.total_amount}
              withComma={true}
            />
            <SingleAMountBox
              name={ekkLanguage.dashboard.totalPaid}
              value={customer?.total_paid}
              withComma={true}
            />
            <SingleAMountBox
              name={ekkLanguage.dashboard.totalDue}
              value={customer?.due}
              withComma={true}
            />
          </div>
        </div>
        <ProductSearch
          setSearchQ={setSearchQ}
          searchQ={searchQ}
          fetchProduct={getProductBrands}
          singleProductSearch={singleProductSearch}
          setSelectedBrand={setSelectedBrand}
          setHasMoreData={setHasMoreData}
          setReachedEnd={setReachedEnd}
        />
        <p className="px-4">
          {tempSearchParams && (
            <span>
              search: <span className="font-bold">{tempSearchParams}</span>
            </span>
          )}
        </p>
        <ProductFilter
          productBrands={productBrands}
          getProductsFromBrands={getProductsFromBrands}
          fetchProduct={getProductBrands}
          setSearchQ={setSearchQ}
          setSelectedBrand={setSelectedBrand}
          getAllProduct={getAllProduct}
          setHasMoreData={setHasMoreData}
          setReachedEnd={setReachedEnd}
        />

        {sellerProductLoader ? (
          <IonLoading
            isOpen={sellerProductLoader}
            message={ekkLanguage.orderList.loader}
          />
        ) : (
          <UpdatedProduct
            recommendData={product}
            title={ekkLanguage.mysuppliers.productTitleLabel}
            settinges={settinges}
          />
        )}
      </IonGrid>
    </div>
  );
}
