import { IonCol, IonGrid, IonRow, IonSearchbar } from "@ionic/react";
import Cross from "assets/images/icon/svg/Cross";
import Search from "assets/images/icon/svg/Search";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { fetchOrder, searchOrder } from "redux/container/orderSlice";

export default function OrderInvoiceSearch({ setLoading, ekkLanguage }) {
	const [text, setText] = useState("");
	const dispatch = useDispatch();
	const [tempSearchParams, setTempSearchParams] = useState("");

	const _handleKeyPress = async (e) => {
		if (e.key === "Enter") {
			setTempSearchParams(text);
			setLoading(true);
			let res = await dispatch(searchOrder(text, 500, 1));
			if (res.status === 200) {
				setLoading(false);
			} else {
				setLoading(false);
			}
		}
	};

	const deleteQueryHandler = async (e) => {
		setTempSearchParams("");
		setText("");
		setLoading(true);
		let res = await dispatch(fetchOrder());
		if (res.status === 200) {
			setLoading(false);
		} else {
			setLoading(false);
		}
	};

	return (
		<IonGrid>
			<IonRow>
				<IonCol size='12'>
					<div className='relative'>
						<span className='absolute left-[10px] top-[24%] h-full z-10 cursor-pointer'>
							<Search color='rgba(0,0,0,.6)' />
						</span>
						<input
							className='bg-white w-full h-11 rounded-[7px] pl-12 search-input border'
							type='number'
							placeholder={ekkLanguage.orderDetails.orderSearchLabel}
							style={{ boxShadow: "1px 2px 8px rgba(0, 0, 0, 0.2) " }}
							value={text}
							onKeyPress={_handleKeyPress}
							onChange={(e) => {
								setText(e.target.value);
								if (e.target.value === "") {
									dispatch(fetchOrder(1, 500));
								}
							}}></input>
						{text && (
							<span
								className='absolute  right-[10px] top-[34%] h-full z-10 cursor-pointer'
								onClick={deleteQueryHandler}>
								<Cross />
							</span>
						)}
					</div>
					<p className='px-4 pt-2'>
						{" "}
						{tempSearchParams && (
							<span>
								search:{" "}
								<span className='font-bold'> {tempSearchParams}</span>
							</span>
						)}
					</p>
				</IonCol>
			</IonRow>
		</IonGrid>
	);
}
