import {
	IonCol,
	IonGrid,
	IonIcon,
	IonLoading,
	IonRow,
	IonSegment,
	IonSegmentButton,
} from "@ionic/react";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedTab } from "redux/container/accountSlice";
import { setAccountingCategory, setIsCategory } from "redux/container/dashboardSlice";

function AccountStatusBtn({
	setOrderStatus,
	setReachedEnd,
	setHasMoreData,
	getAccountType,
	ekkLanguage,
}) {
	const dispatch = useDispatch();
	const selectedTab = useSelector((state) => state.accounts.selectedTab);
	const date = useSelector((state) => state.dashboard.dateInfo);
	return (
		<>
			<IonGrid>
				<IonRow>
					<IonCol size='12'>
						<IonSegment
							value={selectedTab}
							scrollable
							className='orderBtnWrapper no-ripple'
							onIonChange={(e) => {
								dispatch(setAccountingCategory(null));
								dispatch(setIsCategory(false));

								setReachedEnd(false);
								setHasMoreData(true);
								setOrderStatus(e.detail.value);
								getAccountType(e.detail.value, date?.start, date?.end);
								dispatch(setSelectedTab(e.detail.value));
								
							}}>
							<IonSegmentButton
								value='Revenue'
								className='paid relative' >
								<button className='text-paid font-sans p-0 font-normal shadow-none text-14'>
									{ekkLanguage.accounting.revenue}
								</button>
							</IonSegmentButton>

							<IonSegmentButton
								value='Expense'
								className='cancel relative'>
								<button className='text-F94B41-1000 font-sans p-0 font-normal shadow-none text-14'>
									{ekkLanguage.accounting.expense}
								</button>
							</IonSegmentButton>
						</IonSegment>
					</IonCol>
				</IonRow>
			</IonGrid>
		</>
	);
}
export default memo(AccountStatusBtn);
