import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { digitChanger } from "helpers/helper";
import { setNotification } from "redux/container/notificationSlice";
import Notification from "services/Notification";
import { setFrom } from "redux/container/orderSlice";

export default function NotificationCard({ localLanguage }) {
  const notifications = useSelector((state) => state.notification.data);
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function devideWord(str) {
    if (str?.length === 0) {
      return str;
    } else {
      if (localLanguage === "bn") {
        switch (str) {
          case "Placed":
            return "স্থাপিত";
          case "Accepted":
            return "গ্রহণ";
          case "Scheduled For Delivery":
            return "ডেলিভারির জন্য নির্ধারিত";
          case "Partially_Delivered":
            return "আংশিক ডেলিভারি";
          case "Delivered":
            return "পরিপূর্ণ ডেলিভারি";
          case "Partially_Paid":
            return "আংশিক অর্থ সংগ্রহ";
          case "Paid":
            return "পরিপূর্ণ অর্থ সংগ্রহ";
          case "Delivery Failed":
            return "ডেলিভারি ব্যর্থ";
          case "Cancel":
            return "বাতিল অর্ডার";
          // FS
          case "Approved":
            return "অনুমোদিত";
          case "Rejected":
            return "প্রত্যাখ্যাত";
          case "Down Payment Fully Paid":
            return "ডাউন পেমেন্ট সম্পূর্ণ পরিশোধিত";
          case "Down Payment Partially Paid":
            return "ডাউন পেমেন্ট আংশিক পরিশোধিত";
          case "Seller Payment Fully Paid":
            return "বিক্রেতা পেমেন্ট সম্পূর্ণ পরিশোধিত";
          case "Seller Payment Partially Paid":
            return "বিক্রেতা পেমেন্ট আংশিক পরিশোধিত";
          case "Fully Delivered":
            return "পরিপূর্ণ ডেলিভারি";
          case "Partially Delivered":
            return "আংশিক ডেলিভারি";
          case "Fully Repaid":
            return "সম্পূর্ণরূপে পরিশোধিত";
          case "Partially Repaid":
            return "আংশিক পরিশোধিত";
          case "1st Default":
            return "১ম ডিফল্ট";
          case "2nd Default":
            return "২য় ডিফল্ট";
          case "3rd Default":
            return "৩য় ডিফল্ট";
          case "In Settlement":
            return "সেটেলমেন্টে";
          case "Close Settlement":
            return "ক্লোজ সেটেলমেন্ট";
          default:
            break;
        }
      } else {
        const words = str.split("_");
        return words.join(" ");
      }
    }
  }

  const handleMarkAsRead = async (notification) => {
    let response = await Notification.setNewReadNotification(notification?.ref_id);
    if (response.success) {
      const readNotificationList = notifications.map((element) =>
        element?.id === notification?.id
          ? {
              ...element,
              buyer_read: true,
            }
          : element
      );
      dispatch(setNotification(readNotificationList));
      dispatch(setFrom("notificationList"));
      navigate(`/order-details/${notification?.service_field_id}`);
    }
  };

  return (
    <>
      <IonGrid>
        <IonRow>
          {notifications.length ? (
            notifications.map((notification, index) => {
              let orderStatus = notification?.status;
              return (
                <IonCol size="12" key={index}>
                  <div
                    onClick={() => handleMarkAsRead(notification)}
                    className="mb-1 rounded-[10px] overflow-hidden"
                    // style={{
                    // 	boxShadow: `0px 5px 15px rgba(0, 0, 0, 0.05)`,
                    // }}
                  >
                    <div className=" p-[2px] flex w-full">
                      <img
                        className={`w-[50px] h-[50px] mr-[15px] ${
                          notification?.buyer_read
                            ? "opacity-50"
                            : "opacity-100"
                        }`}
                        src={notification?.image}
                        alt="statusIcon"
                      />

                      <div className="flex flex-col">
                        <p
                          className={`text-14 font-bold ${
                            notification?.buyer_read && "opacity-50"
                          }`}
                        >
                          {devideWord(orderStatus)}
                        </p>
                        <p
                          className={`text-12 font-bold ${
                            notification?.buyer_read && "opacity-50"
                          }`}
                        >
                           {notification?.service === "Orders"
                            ? `${digitChanger(
                                notification?.service_field_id
                              )} ${notification?.message}`
                            : `${notification?.message} ${digitChanger(
                                notification?.service_field_id
                              )} `}
                        </p>
                      </div>
                    </div>
                  </div>
                </IonCol>
              );
            })
          ) : (
            <>
              <IonCol size="2"></IonCol>
              <IonCol size="8">
                <div
                  className="text-center flex items-center justify-center"
                  style={{ height: "calc(100vh - 420px)" }}
                >
                  <div>
                    <img
                      className="m-auto mb-[10px] mt-5"
                      src="/assets/images/not-found-file.png"
                      alt="emptyCard"
                    />
                    <p className="text-16 font-normal text-222222 mb-[35px]">
                      {ekkLanguage.orderList.notFound}
                    </p>
                  </div>
                </div>
              </IonCol>
            </>
          )}
        </IonRow>
      </IonGrid>
    </>
  );
}
