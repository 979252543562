import React, { useState, useEffect } from "react";
import { Transition, animated } from "react-spring/renderprops";
import AuthLayout from "components/global-components/AuthLayout";
import PhoneNumberCountry from "components/pages-components/LogIn/PhoneNumberCountry";
import "../../components/pages-components/LogIn/styles/index.scss";
import Password from "components/pages-components/LogIn/Password";
import { selectors } from "redux/container/authSlice";
import { useSelector } from "react-redux";
import Token from "components/pages-components/LogIn/Token";
import ChangePassword from "components/pages-components/LogIn/ChangePassword";
import Registration from "components/pages-components/LogIn/Registration";
import { authBox_Block } from "Constants";
import { setLanguage } from "config/language";
import VerificationBox from "components/pages-components/LogIn/VerificationBox";
import DownloadPwaScreen from "./DownloadPwaModal";
import { useNavigate } from "react-router-dom";
import { IonApp } from "@ionic/react";
import MenuLeftArrow from "assets/images/icon/svg/MenuLeftArrow";

const Login = (props) => {
  if (localStorage.getItem("lan") === null) {
    setLanguage("bn");
  }
  const { loginStatus } = useSelector(selectors.getAuth);
  const navigate = useNavigate();
  if (loginStatus) window.location.href = "/home";

  let userInfo = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [blockIndex, setBlockIndex] = useState(authBox_Block.TOKEN);
  const [phoneNo, setPhoneNo] = useState(userInfo?.createUserData.phoneNumber);
  const [countryCode, setCountryCode] = useState(
    userInfo?.createUserData.countryCode
  );

  const [country, setCountry] = useState(userInfo?.createUserData.country);
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const startLoading = () => setLoading(true);
  const stopLoading = () => setLoading(false);
  const [verifyNumber, setVerifyNumber] = useState(
    userInfo?.createUserData.verify
  );

  let backButtonHandler = () => {
    if (blockIndex === 2) {
      navigate(-1);
    } else if (blockIndex === 5) {
      navigate(-1);
    } else if (blockIndex === 3) {
      navigate("/");
    } else {
      setBlockIndex(blockIndex - 1);
    }
  };

  return (
    <IonApp>
      {!verifyNumber ? (
        <AuthLayout title="Login" loading={loading}>
          <div
            className="left-5 top-5 rounded-xl z-10 backIcon cursor-pointer absolute"
            // style={{
            //   height: 40,
            //   width: 40,
            //   boxShadow: "1px 1px 5px rgba(0,0,0,.3)",
            // }}
            onClick={backButtonHandler}
          >
            <div className="h-full flex items-center justify-center">
              <MenuLeftArrow color={"black"} />
            </div>
          </div>
          <DownloadPwaScreen />;
          <Transition
            native
            reset
            unique
            items={blockIndex}
            from={{
              opacity: 0,
              transform: "translate3d(100%,0,0)",
            }}
            enter={{ opacity: 1, transform: "translate3d(0%,0,0)" }}
            leave={{
              opacity: 0,
              transform: "translate3d(-50%,0,0)",
            }}
          >
            {(index) => {
              if (index === authBox_Block.PHONE_NUMBER_BLOCK) {
                return (style) => (
                  <animated.div className="absolute" style={{ ...style }}>
                    <PhoneNumberCountry
                      loading={loading}
                      phoneNo={phoneNo}
                      setPhoneNo={setPhoneNo}
                      countryCode={countryCode}
                      setCountryCode={setCountryCode}
                      country={country}
                      setCountry={setCountry}
                      startLoading={startLoading}
                      stopLoading={stopLoading}
                      setBlockIndex={setBlockIndex}
                      setVerifyNumber={setVerifyNumber}
                    />
                  </animated.div>
                );
              }

              if (index === authBox_Block.PASSWORD_BLOCK) {
                return (style) => (
                  <animated.div className="absolute" style={{ ...style }}>
                    <div>
                      <Password
                        setPassword={setPassword}
                        loading={loading}
                        setIsForgotPassword={setIsForgotPassword}
                        password={password}
                        phoneNo={phoneNo}
                        country={country}
                        countryCode={countryCode}
                        startLoading={startLoading}
                        stopLoading={stopLoading}
                        setBlockIndex={setBlockIndex}
                      />
                    </div>
                  </animated.div>
                );
              }

              if (index === authBox_Block.TOKEN) {
                return (style) => (
                  <animated.div className="absolute" style={{ ...style }}>
                    <div>
                      <Token
                        loading={loading}
                        setToken={setToken}
                        phoneNo={phoneNo}
                        country={country}
                        countryCode={countryCode}
                        startLoading={startLoading}
                        stopLoading={stopLoading}
                        setBlockIndex={setBlockIndex}
                        token={token}
                        isForgotPassword={isForgotPassword}
                      />
                    </div>
                  </animated.div>
                );
              }

              if (index === authBox_Block.CHANGE_PASS_BLOCK) {
                return (style) => (
                  <animated.div className="absolute" style={{ ...style }}>
                    <div>
                      <ChangePassword
                        loading={loading}
                        phoneNo={phoneNo}
                        country={country}
                        countryCode={countryCode}
                        startLoading={startLoading}
                        stopLoading={stopLoading}
                        setBlockIndex={setBlockIndex}
                        setIsForgotPassword={setIsForgotPassword}
                      />
                    </div>
                  </animated.div>
                );
              }

              if (index === authBox_Block.REGISTRATION_BLOCK) {
                return (style) => (
                  <animated.div className="absolute" style={{ ...style }}>
                    <div>
                      <Registration
                        loading={loading}
                        phoneNo={phoneNo}
                        country={country}
                        countryCode={countryCode}
                      />
                    </div>
                  </animated.div>
                );
              }
            }}
          </Transition>
        </AuthLayout>
      ) : (
        <VerificationBox
          phoneNo={phoneNo}
          country={country}
          countryCode={countryCode}
          setBlockIndex={setBlockIndex}
          setVerifyNumber={setVerifyNumber}
        />
      )}
    </IonApp>
  );
};

export default Login;
