import { IonInput, IonItem } from "@ionic/react";
import { getUserBusiness } from "config/user";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setMyEmployees } from "redux/container/userSlice";
import Business from "services/Business";
import EmployeesPosition from "./EmployeePosition";
import MobileNumber from "./MobileNumber";
import { toast } from "react-toastify";

export default function CreateEmployees({ employees }) {
  const [countryCode, setCountryCode] = useState("+88");
  const [country, setCountry] = useState("Bangladesh");
  const [phoneNo, setPhoneNo] = useState("");
  const [name, setName] = useState("");
  const [nameEmpty, setNameEmpty] = useState("");
  const [roleEmpty, setRoleEmpty] = useState("");
  const [employeePosition, setEmployeePosition] = useState(
    "Select employee position"
  );
  const [employeePositionType, setEmployeePositionType] = useState();
  const ekkLanguage = useSelector((state) => state.lan.lan);
  let useBusiness = getUserBusiness();
  let navigate = useNavigate();
  let dispatch = useDispatch();

  const employeeSubmission = async () => {
    if (employees) {
      let req_body = {
        display_name: name,
        role: employeePositionType,
      };
      let response = await Business.updateEmployee(employees.id, req_body);
      if (response.status === 200) {
        navigate("/employees-list");
        dispatch(setMyEmployees(false));
      }
    } else {
      if (country === "Bangladesh") {
        if (
          !(
            phoneNo.trim().length > 10 &&
            phoneNo.trim().length < 12 &&
            phoneNo[0] == 0
          )
        ) {
          return;
        }
      }
      if (!name) {
        setNameEmpty(ekkLanguage.createEmployees.nameError);
        return;
      }
      if (employeePosition === "Select employee position") {
        setRoleEmpty(ekkLanguage.createEmployees.roleError);
        return;
      }
      // let req_body = {
      // 	country,
      // 	phone_number: (countryCode + phoneNo).trim(),
      // 	assigned_distributor: useBusiness._id,
      // 	business: useBusiness._id,
      // 	name,
      // 	role: employeePosition,
      // };
      // let response = await Business.createBusiness(req_body);
      // if (response.status === 201) {
      // 	navigate("/employees-list");
      // 	dispatch(setMyEmployees(false));
      // }
      let res = await Business.checkBusiness(useBusiness.id);
      if (res.status === 200) {
        let req_body = {
          phone_number: (countryCode + phoneNo).trim(),
          business: res.results.id,
          display_name: name,
          role: employeePositionType,
        };
        let response = await Business.createEmployee(req_body);
        if (response.status === 201) {
          toast.success("Employee created successfully");
          navigate("/employees-list");
          dispatch(setMyEmployees(false));
        } else {
          toast.error(response.message);
        }
      }
    }
  };

  useEffect(() => {
    if (employees) {
      setEmployeePositionType(employees?.role?.id);
      setEmployeePosition(employees?.role?.title);
      setName(employees?.display_name ?? "");
    }
  }, [employees?.id]);

  return (
    <section className="px-4 pb-24 pt-14 h-screen relative">
      {employees ? (
        <>
          <label
            className={`text-16 md:text-16 font-bold text-262626-1000 sm:pb-3 pb-[10px] block pt-4`}
          >
            {ekkLanguage?.createEmployees.employeeNumber}
          </label>
          <div
            className="mb-4 bg-white rounded-[5px] h-[50px] flex items-center pl-4"
            style={{ boxShadow: "rgba(0, 0, 0, 0.05) 0px 5px 20px" }}
          >
            <h6 className="text-success text-18 font-bold">
              {employees?.phone_number}
            </h6>
          </div>
        </>
      ) : (
        <MobileNumber
          countryCode={countryCode}
          setCountryCode={setCountryCode}
          country={country}
          setCountry={setCountry}
          phoneNo={phoneNo}
          setPhoneNo={setPhoneNo}
          employees={employees}
        />
      )}

      <div className="text-left mb-10">
        <h5 className="text-dark-1000 text-16 font-semibold">
          {ekkLanguage.createEmployees.name}
        </h5>
        <IonItem>
          <IonInput
            value={name}
            placeholder={ekkLanguage.createEmployees.namePlaceholder}
            onIonChange={(e) => {
              setName(e.detail.value);
              setNameEmpty("");
            }}
            clearInput
          ></IonInput>
        </IonItem>
        {nameEmpty && (
          <div className="text-primary mt-2 text-right">{nameEmpty}</div>
        )}
      </div>
      <EmployeesPosition
        employeePosition={employeePosition}
        setEmployeePosition={setEmployeePosition}
        roleEmpty={roleEmpty}
        setRoleEmpty={setRoleEmpty}
        setEmployeePositionType={setEmployeePositionType}
      />

      <div>
        <div className="text-center absolute bottom-3 w-full pt-[70px] pr-7">
          <button
            onClick={employeeSubmission}
            style={{ background: "#00B224" }}
            className={`text-18 sm:text-18 font-semibold text-white  h-[50px] sm:h-[50px] rounded-[5px] w-full`}
          >
            {ekkLanguage?.createEmployees.confirmBtn}
          </button>
        </div>
      </div>
    </section>
  );
}
