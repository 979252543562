import { createSlice } from "@reduxjs/toolkit";
export const dateSlice = createSlice({
	name: "date",
	initialState: {
		format: "MM/DD/YYYY",
	},
	reducers: {
		setFormat: (state, action) => {
			state.format = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { actions, reducer } = dateSlice;

export const setDateFormat = (values) => async (dispatch) => {
	dispatch(actions.setFormat(values));
};

export default reducer;
