import {
	IonApp,
	IonContent,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonLoading,
	IonPage,
} from "@ionic/react";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import NavFooter from "components/mobile-components/Common/NavFooter";
import OrderStatus from "components/mobile-components/OrderList/OrderStatus";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TitleNSearch from "components/mobile-components/Common/TitleNSearch";
import { fetchOrder, searchOrder } from "redux/container/orderSlice";

export default function OrderList() {
	const [menuCollapse, setMenuCollapse] = useState(true);
	const [loading, setLoading] = useState(false);
	const [orderStatus, setOrderStatus] = useState([]);
	const dispatch = useDispatch();
	const [text, setText] = useState("");
	const menuIconClick = () => {
		setMenuCollapse(!menuCollapse);
	};
	const [hasMoreData, setHasMoreData] = useState(true);
	const [reachedEnd, setReachedEnd] = useState(false);

	const ekkLanguage = useSelector((state) => state.lan.lan);
	let orderListPage = useSelector((state) => state.order.page);

	let getOrderList = async (value) => {
		if (hasMoreData) {
			let response = await dispatch(fetchOrder(value, orderListPage));
			if (!response.data.length) {
				setHasMoreData(false);
			}
		}
	};

	let getInitOrderList = async () => {
		setLoading(true);
		let response = await dispatch(fetchOrder("", orderListPage));
		if (response.status === 200) {
			setLoading(false);
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {
		getInitOrderList();
	}, []);

	const deleteQueryHandler = async (e) => {
		setText("");
		setLoading(true);
		let res = await dispatch(fetchOrder());
		if (res.status === 200) {
			setLoading(false);
		} else {
			setLoading(false);
		}
	};
	const getSearchData = async (e) => {
		if (e.key === "Enter") {
			setLoading(true);
			let res = await dispatch(searchOrder(text, 500, 1));
			if (res.status === 200) {
				setLoading(false);
			} else {
				setLoading(false);
			}
		}
	};

	return (
		<IonApp>
			<IonPage>
				<IonContent
					scrollEvents={true}
					// onIonScrollEnd={(ev) => {
					// 	getOrderList(orderStatus);
					// }}
				>
					{/* <LeftSidebar
						isMobile={true}
						menuCollapse={menuCollapse}
						menuIconClick={menuIconClick}
					/>
					<BackButtonTitle
						title={ekkLanguage.orderList.pageTitle}
						burgerMenu={true}
						menuCollapse={menuCollapse}
						menuIconClick={menuIconClick}
					/> */}
					<TitleNSearch
						title={ekkLanguage.orderList.pageTitle}
						placeholder={ekkLanguage.orderDetails.orderSearchLabel}
						searchQ={text}
						setSearchQ={setText}
						fetchSearchData={getSearchData}
						initData={getInitOrderList}
						deleteQuery={deleteQueryHandler}
						inputType='number'
					/>
					{loading && <IonLoading isOpen={true} />}
					<section>
						<OrderStatus
							setOrderStatus={setOrderStatus}
							loading={loading}
							setLoading={setLoading}
							setReachedEnd={setReachedEnd}
							setHasMoreData={setHasMoreData}
						/>
					</section>
					{/* <IonInfiniteScroll
						onIonInfinite={(ev) => {
							getOrderList(orderStatus);
							setTimeout(() => ev.target.complete(), 500);
						}}>
						<IonInfiniteScrollContent
							loadingText={ekkLanguage.orderList.loader}
							loadingSpinner='bubbles'></IonInfiniteScrollContent>
					</IonInfiniteScroll> */}
					<IonInfiniteScroll
						onIonInfinite={(ev) => {
							if (reachedEnd) {
								ev.target.complete();
								return;
							}
							getOrderList(orderStatus);
							setTimeout(() => {
								ev.target.complete();
								if (!hasMoreData) {
									setReachedEnd(true);
								}
							}, 500);
						}}>
						<IonInfiniteScrollContent
							loadingText={ekkLanguage.orderList.loader}
							loadingSpinner={
								hasMoreData ? "bubbles" : null
							}></IonInfiniteScrollContent>
					</IonInfiniteScroll>
				</IonContent>
				{/* <NavFooter /> */}
			</IonPage>
		</IonApp>
	);
}
