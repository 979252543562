import HeartOutline from "assets/images/icon/svg/HeartOutline";
import LeftArrow from "assets/images/icon/svg/LeftArrow";
import MenuLeftArrow from "assets/images/icon/svg/MenuLeftArrow";
import Share from "assets/images/icon/svg/Share";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function BackButton({ sticky }) {
	const navigate = useNavigate();
	return (
		<div className={`header-wrapper ${sticky}`}>
			<div
				className='short-nav absolute left-4 top-7  rounded-xl z-10 '
				style={{
					height: 40,
					width: 40,
				}}
				onClick={() => navigate(-1)}>
				<div className=' h-full flex items-center justify-center'>
					<MenuLeftArrow color='#262626' />
				</div>
			</div>
			<div className='long-nav w-full top-nav-bar'>
				<div className='flex justify-between items-center p-2'>
					<div
						className='left-4 top-7 rounded-xl z-10 '
						style={{
							height: 40,
							width: 40,
						}}
						onClick={() => navigate(-1)}>
						<div className='h-full flex items-center justify-center'>
							{/* <LeftArrow color={sticky ? "white" : "white"} /> */}
							<MenuLeftArrow color={sticky ? "white" : "white"} />
						</div>
					</div>
					<div>
						<ul className='flex items-center'>
							<li className='px-2'>
								<div>
									<HeartOutline />
								</div>
							</li>
							<li className='px-2'>
								<div>
									<Share />
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}
