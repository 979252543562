import React from "react";

export default function Ekkbaz() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='40'
			height='40'
			viewBox='0 0 40 40'
			style={{ margin: "auto" }}
			fill='none'>
			<g id='Frame 6' clipPath='url(#clip0_3257_918)'>
				<rect width='40' height='40' fill='white' />
				<g id='Group 1298'>
					<path
						id='Vector 4565'
						d='M19.4286 1.63822L2.38961 5.48796C0.561039 5.98739 0.034632 7.29144 0 7.88103V11.8348V23.6962V30.8754C0 33.3726 1.66234 33.9968 2.49351 33.9968L17.974 36.8061L19.8442 36.9102C21.6727 37.0766 22.1991 35.5922 22.2338 34.8292C22.8987 35.7448 24.2424 35.6269 24.8312 35.4535L38.6493 30.5633C39.7299 30.1471 40 28.9332 40 28.3783V9.85792C39.9169 8.85907 38.9957 8.3319 38.5455 8.19317L23.5844 3.09486C23.1688 3.01163 22.7186 2.99082 22.5455 2.99083C22.213 1.40931 20.329 1.43012 19.4286 1.63822Z'
						fill='#A6A6A6'
						stroke='#A6A6A6'
						strokeWidth='0.769231'
					/>
					<path
						id='Vector 4566'
						d='M11.1167 5.27982L3.4284 6.94457C1.59983 7.36076 1.14269 8.78274 1.14269 9.4417V10.2741V25.465V29.2106C1.05957 32.3737 2.70113 32.8523 3.5323 32.9563L10.1817 34.2049L14.0258 34.9332C15.4111 35.1413 18.2232 35.5575 18.3894 35.5575H19.2206C20.9661 35.5575 21.3332 34.3783 21.2985 33.7887V31.0835L21.4024 25.1528C19.7401 24.9863 18.8397 24.7366 18.5972 24.6326C16.6855 23.9667 15.3765 23.2453 14.9609 22.9678C13.2154 21.969 12.2249 20.7481 11.9479 20.2626C11.6154 20.4291 10.8397 20.2626 10.4933 20.1586C9.3297 19.5759 9.17732 18.3204 9.24658 17.7655C9.3297 16.0175 10.805 15.6498 11.5323 15.6845C13.9427 16.184 13.8526 18.0429 13.5063 18.91C16.5816 22.2395 19.844 23.0719 21.0907 23.0719H21.5063V19.8464C21.5756 15.962 21.7141 7.6729 21.7141 5.59196C21.7141 3.51102 19.9825 3.26824 19.1167 3.40697L11.1167 5.27982Z'
						fill='white'
						stroke='#A6A6A6'
						strokeWidth='0.769231'
					/>
					<path
						id='Vector 4567'
						d='M25.039 5.8002C23.4598 5.21754 23.2035 6.45917 23.2728 7.15282L23.2728 11.939V22.968C24.0208 22.968 24.9005 22.7599 25.2468 22.6558C26.5767 22.1564 27.7403 21.546 28.1559 21.3032C29.3195 20.7206 30.3031 19.6038 30.6494 19.1182C30.2338 18.5356 30.407 17.5575 30.5455 17.1413C30.878 16.1425 31.8615 15.7541 32.3118 15.6847C33.4754 15.6847 34.1819 16.3783 34.3897 16.7252C35.0546 17.6408 34.6667 18.9101 34.3897 19.4304C33.8079 20.1795 32.762 20.3668 32.3118 20.3668C31.8962 20.9495 31.0996 21.65 30.7533 21.9275C29.5897 22.8431 28.1213 23.6963 27.5325 24.0084C25.8702 24.9241 24.0001 25.153 23.2728 25.153V26.1934V30.4594C23.2728 32.8733 25.0044 32.9912 25.8702 32.7484L30.8572 30.9796L36.9871 28.8987C38.3169 28.316 38.5801 27.0605 38.5455 26.5056V24.0084V17.3494V11.7309C38.5455 10.732 37.7143 10.1355 37.2988 9.9621L33.5585 8.71353L28.8832 7.15282C28.2598 6.94472 26.6182 6.38287 25.039 5.8002Z'
						fill='white'
						stroke='#A6A6A6'
						strokeWidth='0.769231'
					/>
				</g>
			</g>
			<defs>
				<clipPath id='clip0_3257_918'>
					<rect width='40' height='40' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
}
