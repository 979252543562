import { baseUrl } from "config/apiUrl";
import SuperFetch from "config/SuperFetch";

const Payment = {
	getAllPaymentTerms: () => {
		return SuperFetch(
			`${baseUrl.pay}/ekkbaz/all_terms/?order_type=Secondary`
		);
	},
	sellerPaymentTerms: () => {
		return SuperFetch(`${baseUrl.pay}/ekkbaz/seller_terms/`);
	},
	createPaymentTerms: (data) => {
		return SuperFetch(`${baseUrl.pay}/ekkbaz/seller_terms/create/`, {
			method: "POST",
			data: data,
		});
	},
	updatePaymentTerms: (data) => {
		return SuperFetch(`${baseUrl.pay}/ekkbaz/seller_terms/update/`, {
			method: "PUT",
			data: data,
		});
	},
	getReceiverBankInfo: (bank) => {
		return SuperFetch(`${baseUrl.pay}/ekkbaz/ekkpay_bank/?bank_name=${bank}`);
	},
	getSenderBankInfo: (bank) => {
		return SuperFetch(`${baseUrl.pay}/ekkbaz/seller_payment_gateways/`);
	},
	sellerPaySubmit: (data) => {
		return SuperFetch(`${baseUrl.pay}/ekkbaz/seller_pay/`, {
			method: "POST",
			data: data,
		});
	},
	getBankList: (id, page = 1, limit = 100) => {
		return SuperFetch(`${baseUrl.pay}/ekkbaz/banks/`);
	},
	createBankAccount: (data) => {
		return SuperFetch(`${baseUrl.pay}/ekkbaz/seller_payment_gateways/`, {
			method: "POST",
			data: data,
		});
	},
};

export default Payment;
