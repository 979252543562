import React from "react";

export default function OrderOutline({ color = "#A6A6A6" }) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			style={{ margin: "auto" }}
			fill='none'>
			<g id='Iconly/Light-Outline/Document'>
				<g id='Document'>
					<path
						id='Fill 1'
						fillRule='evenodd'
						clipRule='evenodd'
						d='M14.4064 15.5592H7.78809C7.40859 15.5592 7.10059 15.2512 7.10059 14.8717C7.10059 14.4922 7.40859 14.1842 7.78809 14.1842H14.4064C14.7859 14.1842 15.0939 14.4922 15.0939 14.8717C15.0939 15.2512 14.7859 15.5592 14.4064 15.5592Z'
						fill={color}
					/>
					<path
						id='Fill 3'
						fillRule='evenodd'
						clipRule='evenodd'
						d='M14.4064 11.7216H7.78809C7.40859 11.7216 7.10059 11.4136 7.10059 11.0341C7.10059 10.6546 7.40859 10.3466 7.78809 10.3466H14.4064C14.7859 10.3466 15.0939 10.6546 15.0939 11.0341C15.0939 11.4136 14.7859 11.7216 14.4064 11.7216Z'
						fill={color}
					/>
					<path
						id='Fill 5'
						fillRule='evenodd'
						clipRule='evenodd'
						d='M10.3125 7.89282H7.78711C7.40761 7.89282 7.09961 7.58482 7.09961 7.20532C7.09961 6.82582 7.40761 6.51782 7.78711 6.51782H10.3125C10.692 6.51782 11 6.82582 11 7.20532C11 7.58482 10.692 7.89282 10.3125 7.89282Z'
						fill={color}
					/>
					<g id='Group 9'>
						<mask
							id='mask0_3257_909'
							maskUnits='userSpaceOnUse'
							x='2'
							y='1'
							width='18'
							height='20'>
							<path
								id='Clip 8'
								fillRule='evenodd'
								clipRule='evenodd'
								d='M2.75 1.8335H19.401V20.0841H2.75V1.8335Z'
								fill='white'
							/>
						</mask>
						<g mask='url(#mask0_3257_909)'>
							<path
								id='Fill 7'
								fillRule='evenodd'
								clipRule='evenodd'
								d='M14.5833 3.20831L7.535 3.21198C5.401 3.22481 4.125 4.54481 4.125 6.7439V15.1736C4.125 17.3873 5.41292 18.7091 7.568 18.7091L14.6162 18.7064C16.7502 18.6936 18.0262 17.3717 18.0262 15.1736V6.7439C18.0262 4.53015 16.7392 3.20831 14.5833 3.20831ZM7.56892 20.0841C4.68692 20.0841 2.75 18.1106 2.75 15.1736V6.7439C2.75 3.78031 4.62642 1.8544 7.53042 1.83698L14.5823 1.83331H14.5833C17.4653 1.83331 19.4013 3.8069 19.4013 6.7439V15.1736C19.4013 18.1362 17.5248 20.0631 14.6208 20.0814L7.56892 20.0841Z'
								fill={color}
							/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}
