import {
  IonApp,
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonLoading,
  IonPage,
} from "@ionic/react";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import NavFooter from "components/mobile-components/Common/NavFooter";
import OrderStatus from "components/mobile-components/OrderList/OrderStatus";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TitleNSearch from "components/mobile-components/Common/TitleNSearch";
import { fetchOrder, searchOrder } from "redux/container/orderSlice";
import NotificationList from "components/mobile-components/Notifications";
import Notification from "services/Notification";
import { getUserBusiness } from "config/user";
import { setNotification } from "redux/container/notificationSlice";
import { getLanguage } from "config/language";

export default function NotificationPage() {
  const [loading, setLoading] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const dispatch = useDispatch();
  const [pageCount, setPageCount] = useState(2);
  const buyerId = getUserBusiness()?.id;
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const localLanguage = getLanguage();
  const [hasMoreData, setHasMoreData] = useState(true);
  const [reachedEnd, setReachedEnd] = useState(false);

  let getNotificationList = async (value) => {
    if (hasMoreData) {
      let response = await Notification.getNewNotificationList(
        pageCount,
        buyerId,
        localLanguage === "bn" ? "BD" : "EN"
      );
      if (response.success) {
        setPageCount((prev) => prev + 1);
        dispatch(setNotification([...notificationList, ...response?.results]));
        setNotificationList([...notificationList, ...response?.results]);
      } else {
        setHasMoreData(false);
      }
    }
  };

  let getInitNotificationList = async () => {
    setLoading(true);
    let response = await Notification.getNewNotificationList(
      1,
      buyerId,
      localLanguage === "bn" ? "BD" : "EN"
    );
    if (response.success) {
      setLoading(false);
      dispatch(setNotification(response?.results));
      setNotificationList(response?.results);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInitNotificationList();
  }, []);

  return (
    <IonApp>
      <IonPage>
        <IonContent scrollEvents={true}>
          <BackButtonTitle
            title={ekkLanguage.notification.pageTitle}
            // from='orderDetailsPage'
          />
          <section>
            <NotificationList
              loading={loading}
              setLoading={setLoading}
              localLanguage={localLanguage}
            />
          </section>
          <IonInfiniteScroll
            onIonInfinite={(ev) => {
              if (reachedEnd) {
                ev.target.complete();
                return;
              }
              getNotificationList();
              setTimeout(() => {
                ev.target.complete();
                if (!hasMoreData) {
                  setReachedEnd(true);
                }
              }, 500);
            }}
          >
            <IonInfiniteScrollContent
              loadingText={ekkLanguage.orderList.loader}
              loadingSpinner={hasMoreData ? "bubbles" : null}
            ></IonInfiniteScrollContent>
          </IonInfiniteScroll>
        </IonContent>
      </IonPage>
    </IonApp>
  );
}
