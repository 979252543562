import React, { useState } from "react";
import LandingNavBar from "./LandingNavBar";
import TextBannerArea from "./TextBannerArea";
import { useSelector } from "react-redux";
import Password from "./Password";
import FooterV2 from "./FooterV2";

export default function PasswordV2() {
	let userInfo = useSelector((state) => state.user);
	const [loading, setLoading] = useState(false);
	const countryCode = userInfo?.createUserData?.countryCode;
	const country = userInfo?.createUserData?.country;
	const phoneNo = userInfo?.createUserData?.phoneNumber;
	var sSize = window.matchMedia("(max-width: 599px)");
	const [password, setPassword] = useState("");
	const [isForgotPassword, setIsForgotPassword] = useState(false);
	const startLoading = () => setLoading(true);
	const stopLoading = () => setLoading(false);
	const ekkLanguage = useSelector((state) => state.lan.lan);

	return (
		<>
			<LandingNavBar />
			<div className='container w-full h-screen flex items-center justify-center lg:flex-row flex-col overflow-y-scroll'>
				{/* <TextBannerArea ekkLanguage={ekkLanguage} /> */}
				<Password
					password={password}
					setPassword={setPassword}
					loading={loading}
					startLoading={startLoading}
					setIsForgotPassword={setIsForgotPassword}
					stopLoading={stopLoading}
					phoneNo={phoneNo}
					country={country}
					countryCode={countryCode}
					sSize={sSize}
					isForgotPassword={isForgotPassword}
				/>
			</div>
			{/* <FooterV2 /> */}
		</>
	);
}
