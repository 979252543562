import { setLanguage } from "config/language";
import { useEffect, useState, lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { selectors } from "redux/container/authSlice";
import BlogData from "services/Blog";
import { getCreateUserBusiness, getUserBusiness } from "config/user";
import CheckBusiness from "components/CheckBusiness";

// Lazy load components
const Backers = lazy(() => import("./Backers"));
const BannerArea = lazy(() => import("./BannerArea"));
const Benefits = lazy(() => import("./Benefits"));
const Blog = lazy(() => import("./Blog"));
const Brands = lazy(() => import("./Brands"));
const Footer = lazy(() => import("./Footer"));
const Navigation = lazy(() => import("./Navigation"));
const OurLocation = lazy(() => import("./OurLocation"));
const Partners = lazy(() => import("./Partners"));
const FooterStyle2 = lazy(() => import("./FooterStyle2"));

export default function Landing() {
	const [scrolling, setScrolling] = useState(false);
	const [scrollTop, setScrollTop] = useState(0);
	const { loginStatus } = useSelector(selectors.getAuth);
	const [page, setPage] = useState(1);
	const [blogList, setBlogList] = useState([]);
	let navigate = useNavigate();

	useEffect(() => {
		function onScroll() {
			let currentPosition = document.documentElement.scrollTop;
			if (currentPosition > 150) {
				setScrolling(true);
			} else {
				setScrolling(false);
			}
			setScrollTop(currentPosition);
		}

		window.addEventListener("scroll", onScroll);
		return () => window.removeEventListener("scroll", onScroll);
	}, [scrollTop]);

	// const getBlogList = async (ctx) => {
	// 	let blogList = await BlogData.getBlogList(page);
	// 	if (blogList.status === 200) {
	// 		setBlogList(blogList.data);
	// 	}
	// };

	// useEffect(() => {
	// 	getBlogList();
	// }, []);

	// useEffect(() => {
	// 	getBlogList();
	// }, []);

	if (loginStatus) {
		let business = getUserBusiness();
		if (business) {
			return <Navigate to='/home' />;
		} else {
			console.log("Not logged in");
			return <CheckBusiness />;
		}
	}

	if (localStorage.getItem("lan") === null) {
		setLanguage();
	}

	return (
		<section>
			<Suspense
				fallback={
					<div>
						<div className='preloader'>
							<div style={{ textAlign: "center" }}>
								<div class='lds-dual-ring'></div>
							</div>
							<h3 className='text-bold text-20'>Loading...</h3>
						</div>
					</div>
				}>
				<Navigation scrolling={scrolling ? "isScrolled" : ""} />
				<BannerArea />
				<Benefits />
				{/* <Blog items={blogList} /> */}
				<FooterStyle2 />
			</Suspense>
		</section>
	);
}
