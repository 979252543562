import {
	IonApp,
	IonContent,
	IonLoading,
	IonPage,
	useIonViewDidEnter,
	useIonViewWillEnter,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import CostSummeryCard from "components/mobile-components/OrderDetails/CostSummeryCard";
import DeliveryDetailsCard from "components/mobile-components/OrderDetails/DeliveryDetailsCard";
import OrderItemCard from "components/mobile-components/OrderDetails/OrderItemCard";
import OrderMetaBar from "components/mobile-components/OrderDetails/OrderMetaBar";
import OrderOnwerState from "components/mobile-components/OrderDetails/OrderOnwerState";
import PaymentDetailsCard from "components/mobile-components/OrderDetails/PaymetDetailsCard";
import TimeLine from "components/mobile-components/OrderDetails/TimeLine";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	fetchOrderDetailsByOrderId,
	fetchOrderSingle,
} from "redux/container/orderSlice";
import { useParams } from "react-router-dom";
import BottomSheet from "components/mobile-components/OrderDetails/BottomSheet";
import PaymentSettlementCard from "components/mobile-components/OrderDetails/PaymentSettlement";
import Orders from "services/Orders";
import Accounts from "services/Accounts";
import TranDetailsCard from "components/mobile-components/Accounts/TranDetailsCard";
import AccountDetailsSheet from "components/mobile-components/Accounts/AccountDetailsSheet";
import {
	setCategory,
	setExpense,
	setRevenue,
	setSupplier,
} from "redux/container/accountSlice";

export default function TransactionDetailsPage() {
	let { id } = useParams();
	const dispatch = useDispatch();
	let [transaction, setTransaction] = useState([]);
	let [loader, setLoader] = useState(false);

	const getTransaction = async () => {
		setLoader(true);
		let response = await Accounts.getTransaction(id);
		console.log();

		if (response.success) {
			setLoader(false);
			setTransaction(response.results);
			dispatch(setCategory({ ...response.results.acc_category }));
			dispatch(setSupplier({ ...response.results.seller }));
			let data = {
				amount: response.results.amount,
				description: response.results.description,
				id: response.results.id,
				images:
					response.results?.images?.length > 0
						? response.results?.images.map((url) => ({
								preview: url,
								serverImage: true,
						  }))
						: [],
			};

			if (response.results?.acc_group?.title === "Revenue") {
				dispatch(
					setRevenue({ ...data, revenueDate: response.results.date })
				);
			} else if (response.results?.acc_group?.title === "Expense") {
				dispatch(
					setExpense({
						...data,
						expenseDate: response.results.date,
						paidChecked: response.results?.is_paid,
						paidDate: response.results?.paid_date,
					})
				);
			}
		} else {
			setLoader(false);
		}
	};

	useEffect(() => {
		getTransaction();
	}, []);

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle title='Transaction Details' from='accDetails' />
					{/* <OrderMetaBar order={response.singleOrder} /> */}
					<section className='px-2 pt-20'>
						{/* <OrderOnwerState order={response.singleOrder} /> */}
						{/* <DeliveryDetailsCard order={response.singleOrder} />
						<OrderItemCard order={response.singleOrder} />
						<CostSummeryCard order={response.singleOrder} />
						<PaymentDetailsCard order={response.singleOrder} /> */}
						{loader && <IonLoading isOpen={true} />}
						<TranDetailsCard collection={transaction} />

						{/* <TimeLine order={response.singleOrder} /> */}
						{/* <BottomSheet order={response.singleOrder} /> */}
					</section>
					<AccountDetailsSheet collection={transaction} />
					{/* <TimeLineTextBox order={response.singleOrder} /> */}
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
