import React from "react";

export default function Qr({ color }) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill={color}>
			<path d='M2 2H4V4H2V2Z' />
			<path d='M6 0V6H0V0H6ZM5 1H1V5H5V1ZM4 12H2V14H4V12Z' />
			<path d='M6 10V16H0V10H6ZM1 11V15H5V11H1ZM12 2H14V4H12V2Z' />
			<path d='M10 0V6H16V0H10ZM15 1V5H11V1H15ZM8 1V0H9V2H8V4H7V1H8ZM8 6V4H9V6H8ZM6 8V7H7V6H8V8H9V7H14V8H10V9H7V8H6ZM6 8V9H2V8H1V9H0V7H3V8H6ZM16 9H15V7H16V9ZM15 9H14V11H16V10H15V9ZM11 9H13V10H12V11H11V9ZM13 12V11H12V12H11V13H9V14H12V12H13ZM13 12H16V13H14V14H13V12ZM9 11V12H10V10H7V11H9Z' />
			<path d='M7 12H8V15H12V16H7V12ZM16 14V16H13V15H15V14H16Z' />
		</svg>
	);
}
