import React from "react";

export default function AdminUser() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			style={{ margin: "auto" }}>
			<g id='Iconly/Bold/Profile'>
				<g id='Profile'>
					<path
						id='Profile_2'
						fillRule='evenodd'
						clipRule='evenodd'
						d='M17.294 7.29105C17.294 10.2281 14.9391 12.5831 12 12.5831C9.0619 12.5831 6.70601 10.2281 6.70601 7.29105C6.70601 4.35402 9.0619 2 12 2C14.9391 2 17.294 4.35402 17.294 7.29105ZM12 22C7.66237 22 4 21.295 4 18.575C4 15.8539 7.68538 15.1739 12 15.1739C16.3386 15.1739 20 15.8789 20 18.599C20 21.32 16.3146 22 12 22Z'
						fill='#00B224'
					/>
				</g>
			</g>
		</svg>
	);
}
