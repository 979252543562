import { IonApp, IonContent, IonLoading, IonPage } from "@ionic/react";
import FoundProduct from "components/mobile-components/Search/FoundProduct";
import NavBar from "components/mobile-components/Search/NavBar";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Product from "services/Product";
export default function SearchPage() {
	let settinges = {
		nameShow: false,
		titleShow: true,
		skuShow: false,
		priceShow: true,
		oldPriceShow: false,
		distShow: false,
		profitShow: false,
		sellPriceShow: false,
		supplierNameShow: false,
		link: "product-details",
	};
	let { tarm } = useParams();
	let navicate = useNavigate();
	const [products, setProducts] = useState();
	const [totalCount, setTotalCount] = useState();
	const [pageCount, setPageCount] = useState(2);
	let [loading, setLoading] = useState(false);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let searchProduct = async (title) => {
		setLoading(true);
		let response = await Product.productSearch(title);
		if (response.success) {
			setLoading(false);
			setProducts(response.data);
			setTotalCount(response?.meta?.count);
		} else {
			setLoading(false);
		}
	};
	let getTermToProduct = async () => {
		setLoading(true);
		let response = await Product.productSearch(tarm);
		if (response.success) {
			setLoading(false);
			setProducts(response?.data);
			setTotalCount(response?.meta?.count);
		} else {
			setLoading(false);
		}
	};
	useEffect(() => {
		if (tarm) {
			getTermToProduct();
		}
	}, []);

	let lodeMoreData = async () => {
		let response = await Product.productSearch(tarm, pageCount);
		if (response.status === 200) {
			if (response.data.length > 0) {
				setPageCount((prev) => prev + 1);
				setProducts([...products, ...response.data]);
				setTotalCount(response.meta.count);
			}
		}
	};
	async function searchNext(event) {
		let timeout = setTimeout(() => {
			lodeMoreData();
			// event.target.complete();
			clearTimeout(timeout);
		}, 1000);
	}
	return (
		<IonApp>
			<IonPage>
				<NavBar searchProduct={searchProduct} defalutTarm={tarm} />
				<IonContent
					scrollEvents={true}
					// onIonScrollEnd={(ev) => {
					// 	searchNext(ev);
					// }}
				>
					{loading ? (
						<IonLoading
							isOpen={true}
							message={ekkLanguage?.createOrder?.loader}></IonLoading>
					) : (
						<FoundProduct
							totalProcuctCount={totalCount}
							recommendData={products}
							settinges={settinges}
							// lodeMoreData={lodeMoreData}
							// searchNext={searchNext}
						/>
					)}
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
