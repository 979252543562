import { digitChanger, numberWithCommas, translator } from "helpers/helper";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./style.scss";
import { BUSINESS_TYPE_INFO } from "data/businessTypeImage";
import Pin from "assets/images/icon/svg/Pin";

export default function SingleProduct({
	id,
	img,
	name,
	supplierName,
	qty,
	tag,
	offer,
	offerAmount,
	nearby,
	oldPrice,
	newPrice,
	sellPrice,
	profit,
	nameShow,
	supplierNameShow,
	skuShow,
	priceShow,
	oldPriceShow,
	distShow,
	profitShow,
	sellPriceShow,
	link,
	defaultImage,
	seeMoreProduct,
	productCard,
	type,
	currency,
	supplier,
	distance,
	identity,
	business_type,
}) {
	let navigate = useNavigate();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let businessType = () =>
		BUSINESS_TYPE_INFO.find((el) => el.type === business_type);

	return (
		<div className='rounded-md'>
			<div className='rounded-xl overflow-hidden bg-E6E6E6 p-3 mb-[5px] flex items-center'>
				{img?.length ? (
					<img
						onClick={() =>
							navigate(
								productCard
									? `/${link}/${id}?&type=${type}`
									: `/${link}/${id}`
							)
						}
						src={supplier ? img : img[0]}
						alt='productImage'
						className='w-full home-card-image-sizer'
					/>
				) : (
					<>
						{identity !== "supplier" ? (
							<img
								onClick={() =>
									navigate(
										productCard
											? `/${link}/${id}?&type=${type}`
											: `/${link}/${id}`
									)
								}
								src={defaultImage}
								alt='productImage'
								className='w-full home-card-image-sizer'
							/>
						) : (
							<div
								className=' home-card-image-sizer text-center flex items-center'
								onClick={() =>
									navigate(
										productCard
											? `/${link}/${id}?&type=${type}`
											: `/${link}/${id}`
									)
								}>
								<img
									className='h-full'
									src={img?.length ? img[0] : businessType()?.image}
									alt='icon'
								/>
							</div>
						)}
					</>
				)}
			</div>

			<div>
				{nameShow && <h5 className='font-semibold text-12'>{name}</h5>}
				{supplierNameShow && (
					<h5 className='font-semibold text-12'>{supplierName}</h5>
				)}
				{supplier && (
					<div className='pt-[5px] flex'>
						{/* <img src='assets/images/icon/pin.png' alt='icon' /> */}
						<Pin />
						<span className='text-primary text-10 font-bold ml-1'>
							{digitChanger(numberWithCommas(distance).toFixed(2))}{" "}
							{ekkLanguage.home.km}
						</span>
					</div>
				)}
				{skuShow && (
					<span className='font-normal text-10'>
						{digitChanger(qty)} {ekkLanguage.home.skusLabel}
					</span>
				)}
				<div className='flex justify-between'>
					{sellPriceShow && (
						<span className='flex items-center'>
							<span className='font-bold text-10 text-primary flex'>
								<span>
									{currency && currency?.currency_symbol_native}{" "}
								</span>{" "}
								{sellPrice && digitChanger(numberWithCommas(sellPrice))}
							</span>
						</span>
					)}
					{priceShow && (
						<span className='font-bold text-12 text-primary flex'>
							<span>
								{currency && currency?.currency_symbol_native}{" "}
							</span>
							{newPrice && digitChanger(numberWithCommas(newPrice))}
						</span>
					)}
					{oldPriceShow && (
						<span className='font-medium text-10 colors-808080 line-through flex'>
							{oldPrice && digitChanger(numberWithCommas(oldPrice))}
						</span>
					)}
					{profitShow && (
						<span className='font-medium text-10 text-success flex'>
							<span>
								{currency && currency?.currency_symbol_native}{" "}
							</span>
							{profit && digitChanger(numberWithCommas(profit))}
						</span>
					)}
				</div>
				{/* <div className='flex justify-between'>
					{profitShow && (
						<span className=' flex items-center'>
							<span className='font-bold text-10 text-success flex'>
								<span>৳ </span>{" "}
								{profit && digitChanger(profit.toFixed(2))}
							</span>
						</span>
					)}
				</div> */}
				<div className='flex justify-between'>
					{distShow && (
						<span className='flex items-center'>
							<img
								src='assets/images/icon/pin.png'
								alt='location'
								className='pr-1'
							/>
							<span className='font-medium text-10 text-primary '>
								{nearby}
							</span>
						</span>
					)}
				</div>
			</div>
		</div>
	);
}
