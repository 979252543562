import { IonItem } from "@ionic/react";

import React, { useState } from "react";
import Sheet from "react-modal-sheet";
import { useDispatch, useSelector } from "react-redux";
import "react-responsive-modal/styles.css";
import { useNavigate } from "react-router-dom";
import { setEditEmployees } from "redux/container/userSlice";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import CancelPopUp from "assets/images/icon/svg/CancelPopUp";
import { ClipLoader } from "react-spinners";
import Business from "services/Business";

export default function EditEmpBottomSheet({ employee }) {
  const ref = React.useRef();
  const isOpenBottomSheet = useSelector((state) => state.user.editEmployees);
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [cancelLoader, setCancelLoader] = useState(false);

  const onOpenModal = () => setIsOpen(true);
  const onCloseModal = () => setIsOpen(false);

  const deleteHandler = async () => {
    setCancelLoader(true);
    let response = await Business.deleteEmployee(employee.id);
    if (response.status === 200) {
      dispatch(setEditEmployees(false));
      navigate("/employees-list");
      setCancelLoader(false);
    } else {
      setCancelLoader(false);
    }
  };

  return (
    <div>
      <Sheet
        className="bottom-sheet"
        initialSnap={1}
        ref={ref}
        isOpen={isOpenBottomSheet}
        snapPoints={[160, 160, 100, 0]}
        onClose={() => dispatch(setEditEmployees(false))}
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <IonItem
              button
              onClick={() => navigate(`/update-employee/${employee.id}`)}
              lines="none"
              className="my-[5px]"
            >
              <span className="py-[5px]">
                <img
                  className="h-10 w-10"
                  src="/assets/images/edit.png"
                  alt="user"
                />
              </span>
              <p className="text-16 font-bold text-black-1000 pl-2">
                {ekkLanguage.createEmployees.edit}
              </p>
            </IonItem>
            <IonItem
              button
              onClick={onOpenModal}
              lines="none"
              className="my-[5px]"
            >
              <span className="py-[5px]">
                <img
                  className="h-10 w-10"
                  src="/assets/images/delete.png"
                  alt="user"
                />
              </span>
              <p className="text-16 font-bold text-black-1000 pl-2">
                {ekkLanguage.createEmployees.delete}
              </p>
            </IonItem>
          </Sheet.Content>
        </Sheet.Container>

        <Sheet.Backdrop onClick={() => dispatch(setEditEmployees(false))} />
      </Sheet>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        classNames="bottom-sheet-modal"
      >
        <div className="p-5">
          <span className="mb-2 inline-block mx-2">
            <CancelPopUp />
          </span>
          <h6 className="text-16 font-bold pb-[10px]">
            {ekkLanguage.createEmployees.deleteConformationAsk}
          </h6>

          <div className="flex">
            <button
              className="border-2 font-bold h-10 rounded-[7px] w-1/2 mr-2 text-black-1000 border-black-200 border-solid"
              onClick={onCloseModal}
            >
              {ekkLanguage.orderDetails.noBtn}
            </button>
            <button
              color="primary"
              className="font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-primary text-white"
              onClick={!cancelLoader && deleteHandler}
            >
              {cancelLoader ? (
                <ClipLoader
                  sizeUnit={"px"}
                  size={20}
                  color={"white"}
                  loading={true}
                />
              ) : (
                ekkLanguage.orderDetails.yesBtn
              )}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
