import React from "react";

export default function MenuLeftArrow({ color = "white" }) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='30'
			height='30'
			viewBox='0 0 30 30'
			fill='none'>
			<g id='Iconly/Light/Arrow - Left'>
				<g id='Arrow - Left'>
					<path
						id='Stroke 1'
						d='M5.3125 15.3429L24.0625 15.3429'
						stroke={color}
						strokeWidth='1.5'
						strokeLinecap='round'
						strokeLinejoin='round'
					/>
					<path
						id='Stroke 3'
						d='M12.875 22.8734L5.3125 15.3434L12.875 7.81212'
						stroke={color}
						strokeWidth='1.5'
						strokeLinecap='round'
						strokeLinejoin='round'
					/>
				</g>
			</g>
		</svg>
	);
}
