import { createSlice, createSelector } from "@reduxjs/toolkit";

const accountsSlice = createSlice({
	name: "accounts",
	initialState: {
		loading: false,
		expense: { images: [], paidChecked: true },
		revenue: { images: [] },
		category: {},
		supplier: {},
		loanRequest: {},
		selectedTab: "Revenue",
	},
	reducers: {
		setExpense: (state, action) => {
			state.expense = action.payload;
		},
		setRevenue: (state, action) => {
			state.revenue = action.payload;
		},
		setCategory: (state, action) => {
			state.category = action.payload;
		},
		setSupplier: (state, action) => {
			state.supplier = action.payload;
		},
		setSelectedTab: (state, action) => {
			state.selectedTab = action.payload;
		},

		emptyExpense: (state, action) => {
			state.expense = {
				images: [],
				paidChecked: true,
			};
			state.category = {};
			state.supplier = {};
		},
		emptyRevenue: (state, action) => {
			state.revenue = {
				images: [],
			};
			state.category = {};
			state.supplier = {};
		},
		setLoanRequest: (state, action) => {
			state.loanRequest = action.payload;
		},
		emptyLoanRequest: (state) => {
			state.loanRequest = {};
		},
	},
});

// Extract the action creators object and the reducer
export const { actions, reducer } = accountsSlice;

export const selectors = {
	getExpense: createSelector(
		(state) => state.accounts,
		(expense) => expense
	),
};

export const setExpense = (values) => async (dispatch, getState) => {
	dispatch(actions.setExpense(values));
};
export const setRevenue = (values) => async (dispatch, getState) => {
	dispatch(actions.setRevenue(values));
};
export const setCategory = (values) => async (dispatch, getState) => {
	dispatch(actions.setCategory(values));
};
export const setSupplier = (values) => async (dispatch, getState) => {
	dispatch(actions.setSupplier(values));
};
export const clearExpense = () => async (dispatch, getState) => {
	dispatch(actions.emptyExpense());
};
export const clearRevenue = () => async (dispatch, getState) => {
	dispatch(actions.emptyRevenue());
};
export const setSelectedTab = (values) => async (dispatch, getState) => {
	dispatch(actions.setSelectedTab(values));
};

export const setLoanRequest = (values) => async (dispatch, getState) => {
	dispatch(actions.setLoanRequest(values));
};
export const clearLoanRequest = () => async (dispatch, getState) => {
	dispatch(actions.emptyLoanRequest());
};
export default reducer;
