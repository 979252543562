import React from "react";

export default function SupplierOutline({ color = "#A6A6A6" }) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			style={{ margin: "auto" }}>
			<path
				d='M6.5 17.7143H4.5C4.36739 17.7143 4.24021 17.6541 4.14645 17.5469C4.05268 17.4398 4 17.2944 4 17.1429C4 16.9913 4.05268 16.846 4.14645 16.7388C4.24021 16.6316 4.36739 16.5714 4.5 16.5714H6.5C6.63261 16.5714 6.75979 16.6316 6.85355 16.7388C6.94732 16.846 7 16.9913 7 17.1429C7 17.2944 6.94732 17.4398 6.85355 17.5469C6.75979 17.6541 6.63261 17.7143 6.5 17.7143ZM22.75 17.7143H21.5C21.3674 17.7143 21.2402 17.6541 21.1464 17.5469C21.0527 17.4398 21 17.2944 21 17.1429C21 16.9913 21.0527 16.846 21.1464 16.7388C21.2402 16.6316 21.3674 16.5714 21.5 16.5714H22.335L23.009 12.4663C23 10.3657 21.43 8.57145 19.5 8.57145H16.217L14.626 16.5714H17.5C17.6326 16.5714 17.7598 16.6316 17.8536 16.7388C17.9473 16.846 18 16.9913 18 17.1429C18 17.2944 17.9473 17.4398 17.8536 17.5469C17.7598 17.6541 17.6326 17.7143 17.5 17.7143H14C13.925 17.7143 13.8509 17.6951 13.7833 17.658C13.7156 17.6208 13.6562 17.5668 13.6093 17.4998C13.5624 17.4329 13.5294 17.3547 13.5125 17.2712C13.4957 17.1876 13.4955 17.1008 13.512 17.0172L15.33 7.8743C15.355 7.74773 15.4169 7.63463 15.5056 7.55363C15.5943 7.47262 15.7045 7.42852 15.818 7.42859H19.5C21.981 7.42859 24 9.73602 24 12.5714L23.241 17.248C23.2197 17.3791 23.159 17.4975 23.0693 17.5826C22.9796 17.6678 22.8666 17.7144 22.75 17.7143Z'
				fill={color}
			/>
			<path
				d='M19.5 20C18.122 20 17 18.7188 17 17.1428C17 15.5668 18.122 14.2857 19.5 14.2857C20.878 14.2857 22 15.5668 22 17.1428C22 18.7188 20.878 20 19.5 20ZM19.5 15.4286C18.673 15.4286 18 16.1977 18 17.1428C18 18.088 18.673 18.8571 19.5 18.8571C20.327 18.8571 21 18.088 21 17.1428C21 16.1977 20.327 15.4286 19.5 15.4286ZM8.5 20C7.122 20 6 18.7188 6 17.1428C6 15.5668 7.122 14.2857 8.5 14.2857C9.878 14.2857 11 15.5668 11 17.1428C11 18.7188 9.878 20 8.5 20ZM8.5 15.4286C7.673 15.4286 7 16.1977 7 17.1428C7 18.088 7.673 18.8571 8.5 18.8571C9.327 18.8571 10 18.088 10 17.1428C10 16.1977 9.327 15.4286 8.5 15.4286ZM6.5 7.42856H2.5C2.36739 7.42856 2.24021 7.36836 2.14645 7.2612C2.05268 7.15403 2 7.00869 2 6.85713C2 6.70558 2.05268 6.56024 2.14645 6.45307C2.24021 6.34591 2.36739 6.28571 2.5 6.28571H6.5C6.63261 6.28571 6.75979 6.34591 6.85355 6.45307C6.94732 6.56024 7 6.70558 7 6.85713C7 7.00869 6.94732 7.15403 6.85355 7.2612C6.75979 7.36836 6.63261 7.42856 6.5 7.42856ZM6.5 10.8571H1.5C1.36739 10.8571 1.24021 10.7969 1.14645 10.6898C1.05268 10.5826 1 10.4373 1 10.2857C1 10.1342 1.05268 9.98881 1.14645 9.88164C1.24021 9.77448 1.36739 9.71428 1.5 9.71428H6.5C6.63261 9.71428 6.75979 9.77448 6.85355 9.88164C6.94732 9.98881 7 10.1342 7 10.2857C7 10.4373 6.94732 10.5826 6.85355 10.6898C6.75979 10.7969 6.63261 10.8571 6.5 10.8571ZM6.5 14.2857H0.5C0.367392 14.2857 0.240215 14.2255 0.146447 14.1183C0.0526784 14.0112 0 13.8658 0 13.7143C0 13.5627 0.0526784 13.4174 0.146447 13.3102C0.240215 13.2031 0.367392 13.1428 0.5 13.1428H6.5C6.63261 13.1428 6.75979 13.2031 6.85355 13.3102C6.94732 13.4174 7 13.5627 7 13.7143C7 13.8658 6.94732 14.0112 6.85355 14.1183C6.75979 14.2255 6.63261 14.2857 6.5 14.2857Z'
				fill={color}
			/>
			<path
				d='M14 17.7143H10.5C10.3674 17.7143 10.2402 17.6541 10.1464 17.5469C10.0527 17.4398 10 17.2944 10 17.1429C10 16.9913 10.0527 16.846 10.1464 16.7388C10.2402 16.6316 10.3674 16.5714 10.5 16.5714H13.601L15.873 5.14286H4.5C4.36739 5.14286 4.24021 5.08265 4.14645 4.97549C4.05268 4.86833 4 4.72298 4 4.57143C4 4.41988 4.05268 4.27453 4.14645 4.16737C4.24021 4.0602 4.36739 4 4.5 4H16.5C16.575 3.99996 16.6491 4.01921 16.7167 4.05634C16.7844 4.09346 16.8438 4.1475 16.8907 4.21446C16.9376 4.28142 16.9706 4.35957 16.9875 4.44312C17.0043 4.52668 17.0045 4.6135 16.988 4.69714L14.488 17.2686C14.463 17.3951 14.4011 17.5082 14.3124 17.5892C14.2237 17.6703 14.1135 17.7144 14 17.7143Z'
				fill={color}
			/>
		</svg>
	);
}
