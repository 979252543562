const vietnamese = {
	login: {
		contryLabel: "QUỐC GIA",
		contryPlaceHolder: "",
		phoneLable: "SỐ ĐIỆN THOẠI",
		phonePlaceHolder: "SỐ ĐIỆN THOẠI",
		validationLabel:
			"Số điện thoại không hợp lệ, vui lòng viết số điện thoại bằng tiếng Anh",
		btnLabel: "Tiếp tục",
		tokenSuccessLabel:
			"Mã thông báo của bạn đã được gửi đến điện thoại của bạn",
		tokenSuccessFailed:
			"Mã thông báo của bạn chưa được tạo, vui lòng thử lại",
		langChange: "Thay đổi ngôn ngữ thành",
		termsAllow:
			"Vui lòng cho phép các điều khoản và điều kiện khi đăng nhập!",
	},
	password: {
		passwordLabel: "MẬT KHẨU",
		passwordPlaceHolder: "Nhập mật khẩu của bạn",
		forgetPass: "Quên mật khẩu",
		backWord: "Quay lại",
		btnLabel: "Đăng nhập",
		btnLoadingLabel: "Đăng nhập",
		otpSendLabel: "Mã đã được gửi đến điện thoại di động của bạn",
		passError: "Mật khẩu không khớp với số điện thoại",
	},
	changePass: {
		passwordLabel: "MẬT KHẨU MỚI",
		passwordPlaceHolder: "*******************",
		confirmPassLabel: "XÁC NHẬN MẬT KHẨU",
		confirmPassPlaceHolder: "*******************",
		passNotMatchLabel: "Mật khẩu không hợp lệ",
		btnLabel: "Tiếp tục",
		successLabel: "Mật khẩu của bạn đã được thay đổi thành công",
		errorLabel: "Mật khẩu của bạn Thay đổi không thành công",
		lengthErrorLabel: "Mật khẩu phải dài ít nhất 4 ký tự",
		notMatchLabel: "Mật khẩu của bạn và mật khẩu xác nhận không khớp.",
	},
	token: {
		tokenLabel: "MÃ THÔNG BÁO",
		tokenPlaceHolder: "Nhập mã thông báo của bạn",
		tokenResend: "Gửi lại mã thông báo",
		tokenSuccessLabel:
			"Mã đã được gửi đến điện thoại di động của bạn, hãy nhập để tiếp tục",
		tokenErrorLabel: "Gửi mã thông báo không thành công",
		timerLabel: "Vui lòng chờ",
		second: "thứ hai",
		btnLabel: "Tiếp tục",
		tokenNotMatch: "Mã thông báo không khớp",
		tokenVarified: "Mã thông báo của bạn đã được xác minh",
		tokenNotWrite: "Vui lòng nhập mã thông báo của bạn",
	},
	registration: {
		nameLabel: "HỌ VÀ TÊN",
		namePlaceholder: "Nhập tên đầy đủ của bạn",
		emailLabel: "E-MAIL",
		emailPlaceholder: "Nhập email của bạn",
		passwordLabel: "MẬT KHẨU",
		passwordPlaceholder: "Nhập mật khẩu của bạn",
		confirmPassLabel: "XÁC NHẬN MẬT KHẨU",
		confirmPassPlaceholder: "Nhập mật khẩu xác nhận của bạn",
		validationLabel: "Trường nhập phải dài ít nhất 4 ký tự",
		passNotMatch: "Mật khẩu của bạn và mật khẩu xác nhận không khớp.",
		btnLabel: "Tiếp tục",
		successMessage: "Hồ sơ được tạo thành công",
		errorMessage: "Vui lòng kiểm tra đầu vào hợp lệ",
		passLengthValidLabel: "Mật khẩu phải dài ít nhất 4 ký tự",
		passwordLength: "Mật khẩu của bạn là",
		strong: "Mạnh mẽ'",
		medium: "Trung bình",
		weak: "yếu",
		gender: "Giới tính",
		male: "Nam",
		female: "Nữ",
		others: "Khác",
		dobLabel: "Ngày Sinh",
		day: "Ngày",
		month: "Tháng",
		year: "Năm",
	},
	letNav: {
		homeMenuLabel: "Trang chủ",
		orderMenuLabel: "Lịch sử đặt hàng",
		mySupplierMenuLabel: "Nhà cung cấp của tôi",
		businessProfileMenuLabel: "Cài đặt hồ sơ doanh nghiệp",
		phoneCall: "Gọi để đặt hàng",
	},
	menu: {
		accounting: "Kế toán",
		procurement: "Mua sắm",
		products: "Sản phẩm",
		loan: "Khoản vay",
	},
	home: {
		showMoreLabel: "Cho xem nhiều hơn",
		brandLabel: "Thương hiệu",
		offerLabel: "Ưu đãi mới",
		mySuppliersLabel: "Nhà cung cấp của tôi",
		nearSuppliersLabel: "Nhà cung cấp lân cận",
		recommendentLabel: "Chỉ Dành Cho Bạn",
		newProductLabel: "Sản Phẩm Mới",
		recommendentOrderLabel: "Đơn Hàng Được Đề Xuất",
		latestNewsLabel: "Tin Tức Mới Nhất",
		searchLabel: "Tìm Kiếm Sản Phẩm",
		supplierLabel: "Tìm Kiếm Nhà Cung Cấp",
		todayPurchaseLabel: "Mua Hàng Hôm Nay",
		totalOrderLabel: "Sản Phẩm Từ",
		totalOrder: "Đơn Hàng",
		skusLabel: "Mã SKU",
		tillNow: "Đến Hiện Tại",
		orderDue: "Đơn Hàng Đã Đến Hạn",
		brandProductPageTitle: "Tất Cả Các Thương Hiệu",
		offerProductPageTitle: "Sản Phẩm Khuyến Mãi",
		offerProductNotFound:
			"Xin lỗi, Không có ưu đãi nào được tìm thấy cho bạn.",
		mySupplierNotFound:
			"Bạn không có bất kỳ nhà cung cấp nào. Vui lòng mua sản phẩm để thêm nhà cung cấp của bạn ở đây.",
		brandSeller: "Nhà Cung Cấp",
		allBrandSeller: "Tất Cả Nhà Cung Cấp",
		km: "km",
		account: "Tài Khoản",
		warning: `Vui lòng lưu ý, các giao dịch sẽ bị ảnh hưởng vào ngày 1 tháng 3 năm 2024 do hệ thống đang được nâng cấp.`,
		warningHeading: "Cảnh Báo",
		product: "Sản Phẩm",
		offerSearchNotFound: "Không tìm thấy sản phẩm với tên",
	},
	profile: {
		pageTitle: "Hồ Sơ & Cài Đặt",
		contactMenuLable: "Liên Hệ & Thông Tin Cơ Bản",
		passwordMenuLable: "Đổi Mật Khẩu",
		logoutMenuLable: "Đăng Xuất",
		changeLanguageMenuLable: "Thay Đổi Ngôn Ngữ",
	},
	changeLanguages: {
		pageTitle: "Chọn Ngôn Ngữ",
		btnLabel: "Lưu",
	},
	contact: {
		pageTitle: "Liên Hệ & Thông Tin Cơ Bản",
		emailLabel: "Email",
		emailPlaceholder: "Nhập địa chỉ email của bạn",
		nidLabel: "Số CMTND",
		nidPlaceholder: "Nhập số CMTND",
		btnLabel: "Lưu",
		successLabel: "Hồ sơ của bạn đã được cập nhật thành công",
		currectEmail: "Vui lòng nhập địa chỉ email hiện tại",
	},
	otpsend: {
		pageTitle: "Xác Minh Số Điện Thoại",
		btnLabel: "Gửi",
		message: "Chạm để gửi mã xác minh đến điện thoại của bạn qua SMS.",
		successLabel: "Mã xác minh của bạn đã được gửi đến điện thoại của bạn",
		errorLabel: "Mã xác minh của bạn chưa được tạo, vui lòng thử lại",
	},
	otpsubmit: {
		pageTitle: "Xác Minh Số Điện Thoại",
		btnLabel: "Gửi",
		verifiLabel: "Xác minh và tiếp tục",
		title: "Mã Xác Minh",
		paragraph:
			"Chúng tôi đã gửi mã xác minh đến số điện thoại di động của bạn",
		otpLabel: "Mã OTP",
		otpPlaceholder: "Nhập mã OTP của bạn",
		receiveYetLabel: "Chưa nhận được mã?",
		againRequestLabel: "Yêu cầu lại",
		timerLabel: "Vui lòng đợi",
		second: "giây",
		successLabel: "Mã xác minh của bạn đã được gửi đến điện thoại của bạn",
		errorLabel: "Mã xác minh của bạn chưa được tạo, vui lòng thử lại",
		tokenVarifiedMessage: "Mã xác minh của bạn đã được xác minh",
		validationLabel: "Vui lòng gửi mã OTP của bạn",
	},
	changePassword: {
		pageTitle: "Đổi Mật Khẩu",
		btnLabel: "Gửi",
		passwordLabel: "MẬT KHẨU",
		passwordPlaceholder: "Nhập mật khẩu của bạn",
		confirmPassLabel: "XÁC NHẬN MẬT KHẨU",
		confirmPassPlaceholder: "Nhập lại mật khẩu của bạn",
		successLabel: "Mật khẩu của bạn đã được cập nhật.",
		errorLabel: "Cập nhật mật khẩu thất bại.",
		passNotMatchLabel: "Mật khẩu và mật khẩu xác nhận không khớp.",
		changePassSuccessPara: "Mật khẩu của bạn đã được thay đổi thành công.",
		changePassContinueBtn: "Tiếp tục",
	},
	brandDetails: {
		skusLabel: "Mã SKU",
		descriptionLabel: "Mô tả",
		productTitleLabel: "Tất cả sản phẩm của thương hiệu này",
	},
	mysuppliers: {
		skusLabel: "Mã SKU",
		productTitleLabel: "Sản Phẩm Của Nhà Cung Cấp Này",
	},
	productList: {
		pageTitle: "Danh Sách Sản Phẩm Khuyến Mãi",
	},
	productDetails: {
		offerStart: "Ngày Bắt Đầu Khuyến Mãi",
		offerEnd: "Ngày Kết Thúc Khuyến Mãi",
		moqLabel: "MOQ",
		consumerLabel: "Giá Tiêu Dùng (MRP)",
		profitLabel: "Lợi Nhuận",
		productDetailTitle: "Chi Tiết Sản Phẩm",
		skuId: "Mã SKU",
		expireData: "Ngày Hết Hạn",
		manufactureData: "Ngày Sản Xuất",
		productWeight: "Trọng Lượng Sản Phẩm",
		packingType: "Loại Đóng Gói",
		importedBy: "Nhập Khẩu Bởi",
		manufacturedBy: "Sản Xuất Bởi",
		country: "Quốc Gia",
		descriptionLabel: "Mô Tả",
		btnLabel: "Đặt Hàng Ngay",
		inStock: "Còn Hàng",
		outStock: "Hết Hàng",
		preOrderBtn: "Yêu Cầu Đặt Hàng Trước",
		preOrderQuantity: "Nhập Số Lượng Đặt Hàng Trước",
		searchTram: "Tìm Kiếm Trạm",
	},
	createOrder: {
		pageTitle: "Tạo Đơn Hàng",
		buyerDetailsTitle: "Chi Tiết Người Mua",
		sellerDetailsTitle: "Chi Tiết Người Bán",
		deliveryDetailsTitle: "Thông Tin Giao Hàng",
		productItemTitle: "Sản Phẩm",
		costSummaryTitle: "Tóm Tắt Chi Phí",
		paymentDetailsTitle: "Thông Tin Thanh Toán",
		paymentTermsTitle: "Điều Khoản Thanh Toán",
		paymentMethodeTitle: "Phương Thức Thanh Toán",
		moqLabel: "Số Lượng Tối Thiểu Đặt Hàng",
		pcsLabel: "Sản Phẩm",
		subtotal: "Tổng Phụ",
		tax: "Thuế / VAT",
		discount: "Giảm Giá",
		deliveryFee: "Phí Giao Hàng",
		creditFee: "Phí Dịch Vụ",
		total: "Tổng Cộng",
		immediatePayment: "Thanh Toán Ngay",
		pickAndPay: "Tự Đến Thanh Toán",
		today: "Trong Đêm Của Ngày Giao Hàng",
		threeDays: "Thanh Toán Trong 3 Ngày",
		sevenDays: "Thanh Toán Trong 7 Ngày",
		immediatePaymentText: "Không có Phí Dịch Vụ áp dụng cho đơn hàng này.",
		todayPaymentText1:
			"Đối với thanh toán ngay hôm nay, sẽ có Phí Dịch Vụ bổ sung ",
		todayPaymentText2: "sẽ được thêm vào đơn hàng này.",
		threePaymentText1:
			"Đối với thanh toán trong 3 ngày, sẽ có Phí Dịch Vụ bổ sung ",
		threePaymentText2: "sẽ được thêm vào đơn hàng này.",
		sevenPaymentText1:
			"Đối với thanh toán trong 7 ngày, sẽ có Phí Dịch Vụ bổ sung ",
		sevenPaymentText2: " sẽ được thêm vào đơn hàng này.",
		cashOnDelivery: "Thanh Toán Bằng Tiền Mặt Khi Nhận Hàng",
		cashOnDeliveryText1: "Thanh toán bằng tiền mặt cho",
		cashOnDeliveryText2: "khi hàng được giao.",
		cash: "Tiền Mặt",
		bkash: "Bkash",
		nagad: "Nagad",
		cashText: "Thanh toán bằng tiền mặt cho",
		bankTransfer: "Chuyển Khoản Ngân Hàng",
		bankTransferText1:
			"Chuyển khoản ngân hàng - Thanh toán vào tài khoản ngân hàng có 'Mã Đơn Hàng' làm tham chiếu",
		bankTransferText2: "Tên Ngân Hàng",
		bankTransferText3: "Tên Tài Khoản",
		bankTransferText4: "Số Tài Khoản",
		bankTransferText5: "Số Đường Dây",
		bankTransferText6: "Chi Nhánh",
		btnLabel: "Xác Nhận",
		requestDeliveryDate: "Chọn Ngày Giao Hàng",
		addMoreProductLabel: "Thêm Sản Phẩm Khác",
		selectProductLabel: "Chọn",
		cosgressTitle: "Chúc Mừng!",
		cosgressPara: "Đơn hàng của bạn đã hoàn thành.",
		preOrderCongress:
			"Đơn đặt hàng trước của bạn đã được hoàn thành thành công. Đại diện của chúng tôi sẽ liên hệ với bạn sớm.",
		congressCancelText:
			"Nếu có bất kỳ vấn đề gì với đơn hàng, hãy hủy trước 12 giờ trưa. Sau 12 giờ trưa bạn không thể hủy đơn hàng.",
		congressProductLabel: "Tiếp Tục",
		orderNow: "Đặt Hàng Ngay",
		alert: "Cảnh Báo",
		importentMessage: "Thông Báo Quan Trọng",
		deliveryDateMessage: "Vui lòng Chọn Ngày Giao Hàng",
		paymentTermsMessage: "Vui lòng Chọn Điều Khoản Thanh Toán",
		paymentMethodMessage: "Vui lòng Chọn Phương Thức Thanh Toán",
		backButtonMessage:
			"Giỏ hàng của bạn có một số mặt hàng, nếu bạn rời khỏi trang này thì giỏ hàng của bạn sẽ bị trống.",
		okBtnLabel: "OK",
		loader: "Vui lòng đợi...",
		placeConformationMessage: "Bạn có chắc chắn muốn đặt đơn hàng này không?",
		lessThenMoq:
			"Số lượng sản phẩm đã chọn ít hơn Số Lượng Đặt Hàng Tối Thiểu.",
		all: "Tất Cả",
		number: "Số",
		numberWarning:
			"Nếu tiền được gửi đến bất kỳ số nào khác ngoài số này, đơn hàng sẽ bị coi là đã hủy.",
		islamiBank: "Ngân Hàng Islami",
		bankAsia: "Ngân Hàng Bank Asia",
		payTo: "Thanh Toán Đến",
		merchantNumber: "Số Thương Nhân",
		outOffStock: "Sản phẩm đã hết hàng",
	},
	selectProduct: {
		pageTitle: "Chọn Sản Phẩm",
		totalLabel: "Tổng Cộng",
		btnLabel: "Xác Nhận",
	},
	orderList: {
		pageTitle: "Tạo Đơn Hàng",
		tabMenuRecent: "Tất Cả",
		tabMenuPlaced: "Đã Đặt Hàng",
		tabMenuAccepted: "Đã Chấp Nhận",
		tabMenuScDelivered: "Đã Lên Lịch Giao Hàng",
		tabMenuPartialDelivered: "Giao Hàng Một Phần",
		tabMenuDelivered: "Đã Giao Hàng",
		tabMenuReturn: "Trả Lại Sản Phẩm",
		tabMenuPartialPaid: "Thanh Toán Một Phần",
		tabMenuPaid: "Đã Thanh Toán",
		tabMenuCancel: "Đã Hủy",
		tabMenuDeliveryFailed: "Giao Hàng Thất Bại",
		orderItem: "Sản Phẩm Đặt Hàng",
		seller: "Người Bán",
		buyer: "Người Mua",
		loader: "Vui lòng đợi...",
		createdBy: "Tạo Bởi",
		to: "Đến",
		for: "Cho",
		notFound: "Không Tìm Thấy",
	},
	orderDetails: {
		createdBy: "Tạo bởi",
		placedLabel: "Đã Đặt Hàng",
		deliveredLabel: "Đã Giao Hàng",
		paidLabel: "Đã Thanh Toán",
		orderItemLabel: "Sản Phẩm Đặt Hàng",
		castItemLabel: "Chi Tiết Chi Phí",
		timelineLabel: "Dòng Thời Gian",
		subtotal: "Tổng Phụ",
		tax: "Thuế / VAT",
		discount: "Giảm Giá",
		deliveryFee: "Phí Giao Hàng",
		creditFee: "Phí Dịch Vụ",
		total: "Tổng Cộng",
		paymentSettlementLabel: "Thanh Toán",
		date: "Ngày",
		amount: "Số Tiền",
		dueDate: "Ngày Đáo Hạn",
		remark: "Ghi Chú",
		collectedBy: "Thu Thập Bởi",
		paymentDetailsLabel: "Chi Tiết Thanh Toán",
		paymentMethod: "Phương Thức Thanh Toán",
		paymentTerms: "Điều Khoản Thanh Toán",
		paymentDueDate: "Ngày Đáo Hạn Thanh Toán",
		totalDue: "Tổng Dư Nợ",
		totalPaid: "Tổng Đã Thanh Toán",
		remaining: "Còn Lại",
		deliveryDetailsLabel: "Chi Tiết Giao Hàng",
		BuyerName: "Tên Người Mua",
		address: "Địa Chỉ",
		phoneNumber: "Số Điện Thoại",
		email: "Email",
		requestDeliveryDate: "Ngày Yêu Cầu Giao Hàng",
		scheduleDeliveryDate: "Ngày Giao Hàng Được Lên Lịch",
		scheduleDeliveryTime: "Thời Gian Giao Hàng Được Lên Lịch",
		deliveryDate: "Ngày Giao Hàng",
		downloadInvoices: "Tải Hóa Đơn",
		receiptDownloads: "In Biên Nhận",
		cancelOrder: "Hủy Đơn Hàng",
		cancelConformationAsk: "Bạn có chắc chắn muốn Hủy Đơn Hàng này không?",
		cancelConformationParagraph:
			"Bạn không thể hủy đơn hàng này, bạn chỉ có thể hủy đơn hàng trong cùng ngày.",
		yesBtn: "Có",
		noBtn: "Không",
		withoutSettelment:
			"Thông tin thanh toán đã hoàn tất sẽ được hiển thị ở đây.",
		bankInfoLabel: "Thông Tin Tài Khoản Ngân Hàng để Chuyển Tiền Thanh Toán",
		deliveryFailMessage: "Thông báo giao hàng thất bại",
		close: "Đóng",
	},
	businessCreateName: {
		pageTitle: "Tên Doanh Nghiệp",
		businessName: "Tên Doanh Nghiệp",
		businessNamePlaceholder: "Nhập Tên Doanh Nghiệp",
		paragraph:
			"Chúng tôi cách bạn 3 bước để tạo doanh nghiệp của bạn. Hãy bắt đầu bằng tên doanh nghiệp của bạn.",
		btnLabel: "Tiếp Theo",
		submit: "Xác Nhận",
		numberOne: 1,
		numberTwo: 2,
		numberThree: 3,
		errorLabel: "Vui lòng nhập tên doanh nghiệp!",
		congratulation: "Xin chúc mừng",
		congratulationMessage: "Doanh nghiệp của bạn đã được tạo thành công",
		kycSubmit: "Nộp Tài Liệu KYC Của Bạn",
		pending:
			"Cảm ơn bạn đã nộp tài liệu KYC. Quá trình xác minh KYC của bạn đang được tiến hành, vui lòng đợi.",
		goBack: "Quay Lại",
	},
	businessType: {
		pageTitle: "Loại Hình Kinh Doanh",
		paragraph: "Hãy chọn loại hình kinh doanh của bạn.",
		fmcg: "FMCG",
		miniMart: "Mini Mart",
		superMart: "Siêu Thị",
		pharmacy: "Nhà Thuốc",
		cart: "Xe Đẩy",
		bazaarStore: "Cửa Hàng Bazaar",
		hawkerCenter: "Khu Chợ Rong",
		restaurant: "Nhà Hàng",
		hotel: "Khách Sạn",
		cosmeticsStore: "Cửa Hàng Mỹ Phẩm",
		nonFmcg: "Không Phải FMCG",
		office: "Văn Phòng",
		NGO: "Tổ Chức Phi Chính Phủ",
		entertainmentPlace: "Địa Điểm Giải Trí",
		religiousOrganization: "Tổ Chức Tôn Giáo",
		spa: "Spa",
		salon: "Tiệm Tóc",
		gym: "Phòng Tập Thể Dục",
		electronicsStore: "Cửa Hàng Điện Tử",
		shoeStore: "Cửa Hàng Giày Dép",
		fashionStore: "Cửa Hàng Thời Trang",
		laundryStore: "Cửa Hàng Giặt Ủi",
		numberOne: 1,
		numberTwo: 2,
		numberThree: 3,
		btnLabel: "Tiếp Theo",
		validationLabel: "Vui lòng chọn loại hình kinh doanh",
	},
	businessLocation: {
		pageTitle: "Vị Trí Kinh Doanh",
		paragraph: "Nhập chi tiết địa chỉ doanh nghiệp của bạn",
		address: "Địa Chỉ",
		addressPlaceholder: "Nhập số đường/tên khu vực",
		city: "Thành Phố",
		cityPlaceholder: "Nhập tên thành phố",
		postalCode: "Mã Bưu Chính",
		postalCodePlaceholder: "Nhập mã bưu chính",
		email: "Email",
		emailPlaceholder: "Nhập địa chỉ email của bạn",
		userName: "Tên người dùng",
		userNamePlaceholder: "Nhập tên người dùng của bạn",
		numberOne: 1,
		numberTwo: 2,
		numberThree: 3,
		btnLabel: "HOÀN THÀNH",
		successLabel: "Chúc mừng, doanh nghiệp đã được tạo",
		errorLabel: "Tạo doanh nghiệp thất bại",
		validationLabel: "Vui lòng điền địa chỉ để tiếp tục.",
		loader: "Vui lòng đợi...",
		validationCityLabel: "Vui lòng điền tên thành phố.",
		validationPostCodeLabel: "Vui lòng điền mã bưu chính.",
	},
	locationPicker: {
		pageTitle: "Địa điểm kinh doanh",
		btnLabel: "XÁC NHẬN",
		paragraph: "Di chuyển bản đồ đến vị trí kinh doanh của bạn để chọn nó",
	},
	location: {
		title: "Bật Chức Năng Vị Trí Của Bạn",
		paragraph:
			"Ứng dụng này yêu cầu dịch vụ vị trí được bật trên thiết bị của bạn và cho ứng dụng này. Bạn phải bật chúng trong Cài đặt trước khi sử dụng ứng dụng này.",
		btnLabel: "Cho phép chỉ khi sử dụng ứng dụng",
	},
	businessSelect: {
		pageTitle: "Chọn Doanh Nghiệp",
	},
	pwaDownload: {
		header: "Tải Ứng Dụng EkkBaz",
		paragraph:
			"Tải ứng dụng và đặt hàng số lượng lớn từ nhà cung cấp của bạn bất cứ lúc nào từ bây giờ trở đi.",
		cancelBtn: "Hủy",
		downloadBtn: "Tải Xuống",
	},

	businessProfile: {
		pageTitle: "Cài Đặt Hồ Sơ Doanh Nghiệp",
		businessDetailsLabel: "Thông Tin Doanh Nghiệp",
		registrationNo: "Số Đăng Ký",
		taxNo: "Số Thuế/VAT",
		mobileNo: "Số Điện Thoại Di Động",
		officeNo: "Số Điện Thoại Văn Phòng",
		email: "Email",
		website: "Website",
		branchLabel: "Danh Sách Chi Nhánh",
		paymentDetailsLabel: "Chi Tiết Thanh Toán",
		bankInfoLabel: "Thông Tin Ngân Hàng",
		bankName: "Tên Ngân Hàng",
		branchName: "Tên Chi Nhánh Ngân Hàng",
		branchAddress: "Địa Chỉ Ngân Hàng",
		swiftCode: "Mã SWIFT Ngân Hàng",
		acName: "Tên Tài Khoản Ngân Hàng",
		acNumber: "Số Tài Khoản Ngân Hàng",
		bkashInfoLabel: "Thông Tin Bkash",
		bkashAcName: "Tên Tài Khoản Bkash",
		bkashAcNo: "Số Tài Khoản Bkash",
		nagadInfoLabel: "Thông Tin Nagad",
		nagadAcName: "Tên Tài Khoản Nagad",
		nagadAcNo: "Số Tài Khoản Nagad",
		upayInfoLabel: "Thông Tin Upay",
		upayAcName: "Tên Tài Khoản Upay",
		upayAcNo: "Số Tài Khoản Upay",
		verified: "Đã Xác Minh",
		unVerified: "Chưa Xác Minh",
		editBusinessMenu: "Chỉnh Sửa Doanh Nghiệp",
		addPayMenu: "Thêm Phương Thức Thanh Toán",
		setLocation: "Cập nhật địa điểm kinh doanh",
		addMenu: "Thêm Chi Nhánh",
		verifyBusinessMenu: "Nhập Thông Tin KYC",
		bankInfoNotFound: "Không tìm thấy thông tin ngân hàng.",
		deleteBusiness: "Xóa doanh nghiệp",
		deleteWarning:
			"Tất cả các đơn đặt hàng, sản phẩm, nhân viên và toàn bộ tài khoản của bạn sẽ bị xóa. Bạn có chắc chắn muốn xóa Doanh nghiệp này không?",
		otpSubmit: "OTP Gửi",
		confirmDeleteWarning:
			"Xin hãy suy nghĩ lại! tất cả các đơn đặt hàng, sản phẩm, nhân viên và toàn bộ tài khoản của bạn sẽ bị xóa. Bạn có chắc chắn muốn xóa Doanh nghiệp này không?",
		switchBusiness: "chuyển đổi kinh doanh",
		myEmployeesMenuLabel: "Nhân Viên Của Tôi",
	},

	businessEdit: {
		pageTitle: "Chỉnh Sửa Doanh Nghiệp",
		uploadPhoto: "Tải Ảnh Lên",
		businessName: "Tên doanh nghiệp",
		businessNamePlaceholder: "Nhập tên doanh nghiệp của bạn",
		email: "Email",
		emailPlaceholder: "Nhập địa chỉ email của bạn",
		address: "Địa Chỉ",
		addressPlaceholder: "Nhập địa chỉ của bạn",
		city: "Thành Phố",
		cityPlaceholder: "Nhập tên thành phố",
		postalCode: "Mã Bưu Chính",
		postalCodePlaceholder: "Nhập mã bưu chính",
		resigter: "Số Đăng Ký",
		resigterPlaceholder: "Nhập số đăng ký của bạn",
		tex: "Số Thuế/VAT",
		texPlaceholder: "Nhập số thuế/VAT của bạn",
		mobile: "Số Điện Thoại Di Động",
		mobilePlaceholder: "Nhập số điện thoại di động của bạn",
		office: "Số Điện Thoại Văn Phòng",
		officePlaceholder: "Nhập số điện thoại văn phòng của bạn",
		website: "Website",
		websitePlaceholder: "Nhập địa chỉ website của bạn",
		btnLabel: "Lưu",
		successLabel: "Hồ sơ của bạn đã được cập nhật thành công.",
		currectEmail: "Vui lòng nhập địa chỉ email hiện tại",
	},
	bankDetails: {
		referenceNumber: "Số Tham Khảo",
		bankName: "Tên Ngân Hàng",
		accountName: "Tên Tài Khoản",
		accountNumber: "Số Tài Khoản",
		routeNumber: "Số Route",
		branch: "Chi Nhánh",
	},
	invoice: {
		title: "HÓA ĐƠN",
		receipt: "Biên Nhận",
		buyerDetails: "Thông Tin Người Mua",
		sellerDetails: "Thông Tin Người Bán",
		description: "MÔ TẢ",
		qty: "SỐ LƯỢNG",
		price: "GIÁ",
		subtotal: "TỔNG PHỤ",
	},
	verifyBusiness: {
		numberOne: "1",
		numberTwo: "2",
		numberThree: "3",
		numberFour: "4",
		numberFive: "5",
		numberSix: "6",
	},
	receipt: {
		placed: "Đặt Hàng",
		name: "Tên",
		qty: "Số Lượng",
		price: "Giá",
		subtotal: "Tổng Phụ",
		tax: "Thuế",
		discount: "Giảm Giá",
		creditFee: "Phí Dịch Vụ",
		deliveryFee: "Phí Giao Hàng",
		total: "Tổng Cộng",
		paid: "Đã Thanh Toán",
		totalDue: "Tổng Còn Lại",
		paymentTerms: "Điều Kiện Thanh Toán",
		days: "Ngày",
		paymentMethod: "Phương Thức Thanh Toán",
		referenceNumber: "Số Tham Khảo Ngân Hàng",
		deliveryDate: "Ngày Giao Hàng",
		dueDate: "Ngày Đến Hạn Thanh Toán",
		printTime: "Thời Gian In",
		conditionsCreditFee: "Điều Kiện Thanh Toán & Phí Dịch Vụ",
		immediatePayment: "Thanh Toán Ngay Lập Tức",
		cashOnDelivery: "Thanh Toán Bằng Tiền Mặt Khi Giao Hàng",
		atNight: "Vào Đêm Ngày Giao Hàng",
		payInthreeDay: "Thanh Toán Trong Vòng 3 Ngày",
		payInSevenDay: "Thanh Toán Trong Vòng 7 Ngày",
		afterSeven: "Sau 7 Ngày (Thêm Phí Mỗi Ngày)",
	},

	landing: {
		header1: "Thị trường sỉ tại đầu ngón tay bạn.",
		header2: "Mua Ngay",
		header3: "Thanh Toán Sau! ",
		login: "Đăng Nhập",
		bangla: "বাংলা",
		english: "English",
		vietnamese: "Tiếng Việt",
		malaysia: "Malay",
		retail: "Bán Lẻ",
		manufacture: "Nhà Cung Cấp",
		hotline: "Hotline",
		phoneNumber: "Số Điện Thoại",
		phoneNumberPlaceHolder: "Nhập Số Điện Thoại",
		fAbout: "Thị trường sỉ tại đầu ngón tay bạn. Mua Ngay, Thanh Toán Sau!",
		location: "Vị Trí",
		singapore: "Văn Phòng Singapore",
		bangladesh: "Văn Phòng Bangladesh",
		bOfficeAddress:
			"Tầng 13, Tòa Nhà Razzak Plaza, Giao Lộ Mog Bazar, Dhaka-1205",
		otherInfo: "Thông Tin Khác",
		partners: "Đối Tác Của Chúng Tôi",
		news: "Tin Tức",
		support: "Hỗ Trợ",
		corporate: "Công Ty",
		refundPolicy: "Chính Sách Hoàn Tiền",
		termsCondition: "Điều Khoản và Điều Kiện",
		privacyPolicy: "Chính Sách Bảo Mật",
		contactUs: "Liên Hệ Chúng Tôi",
		messageUs: "Gửi Tin Nhắn",
		facebook: "Facebook",
		sales: "Bán Hàng",
		media: "Phương Tiện Truyền Thông",
		downloadBtn: "Tải Ứng Dụng",
		backers: "Những Người Ủng Hộ Của Chúng Tôi",
		benefits: "Lợi Ích Của Bạn",
		discover: "Tin Tức Mới Nhất từ EkkBaz",
		benefitsOne: "Giao hàng đúng hẹn trong vòng 24 giờ",
		benefitsDisOne: "",
		benefitsTwo: "Giá thị trường tốt nhất",
		benefitsDisOneTwo: "",
		benefitsThree: "Sự đa dạng lớn nhất về SKU",
		benefitsDisOneThree: "",
		benefitsFore: "Hỗ trợ tài chính cho các đơn hàng",
		benefitsDisOneFore: "",
		seeMore: "Xem Thêm",
		weAvailable: "Chúng Tôi Có Mặt",
		downloadApp: "Tải ứng dụng ngay!",
	},

	seeMoreProduct: {
		brandsSearchPlaceholder: "Tìm Kiếm Thương Hiệu",
		supplierSearchPlaceholder: "Tìm Nhà Cung Cấp",
		offerSearchPlaceholder: "Tìm Sản Phẩm Khuyến Mãi",
	},

	sellerList: {
		pageTitle: "Danh Sách Nhà Cung Cấp",
		nearBySeller: "Nhà Cung Cấp Gần Bạn",
		allSeller: "Tất Cả Nhà Cung Cấp",
		supplierNotFound: "Bạn không có nhà cung cấp nào có sẵn",
		nearBySupplierNotFound:
			"Xin lỗi, không tìm thấy nhà cung cấp trong khu vực của bạn, chúng tôi sẽ sớm giao phó một số nhà cung cấp trong khu vực của bạn.",
	},
	learning: {
		pageTitle: "Danh sách Học tập",
		learningDetailsTitle: "Chi tiết Học tập",
		comments: "Bình luận",
		commentAs: "Viết bình luận của bạn ở đây",
		likedWarning: "Bạn không thể thích nhiều hơn một lần",
	},
	navFooter: {
		home: "Trang chủ",
		order: "Đặt hàng",
		supplier: "Nhà cung cấp",
		learning: "Học tập",
		account: "Tài khoản",
	},
	notification: {
		pageTitle: "Thông báo",
		order: "Đơn hàng",
		product: "Sản phẩm",
		update: "Cập nhật",
	},
	autoLogOut: {
		pageTitle: "Đăng xuất tự động",
		warningMsg:
			"Tự động đăng xuất vì số này đã được sử dụng trong lần đăng nhập khác",
		instrMsg: "Bấm vào nút xanh bên dưới để đăng nhập lại",
		btnLabel: "Đăng nhập",
	},
	dashboard: {
		statusLable: "Trạng thái Đơn hàng",
		today: "Hôm nay",
		yesterday: "Hôm qua",
		thisWeek: "Tuần này",
		thisMonth: "Tháng này",
		thisYear: "Năm nay",
		lastWeek: "Tuần trước",
		lastMonth: "Tháng trước",
		lastYear: "Năm trước",
		custom: "Ngày tùy chỉnh",
		selectDay: "Chọn ngày",
		Order: "Đơn hàng",
		totalOrder: "Tổng số đơn hàng",
		placed: "Đã đặt",
		accepted: "Đã chấp nhận",
		scDelivered: "Đã lên lịch giao hàng",
		partialDelivered: "Giao hàng một phần",
		delivered: "Đã giao hàng",
		return: "Trả sản phẩm",
		partialPaid: "Thanh toán một phần",
		paid: "Đã thanh toán",
		totalPaid: "Đã thanh toán",
		cancel: "Hủy",
		deliveryFailed: "Giao hàng thất bại",
		orderAmount: "Số tiền đơn hàng",
		totalAmount: "Số tiền đơn hàng",
		collectedAmount: "Số tiền thu",
		dueAmount: "Số tiền còn nợ",
		totalDue: "Số tiền còn nợ",
		directOrder: "Đơn hàng trực tiếp",
		directOrderAmount: "Số tiền đơn hàng trực tiếp",
		ekkHeroOrder: "Đơn hàng EkkHero",
		ekkHeroOrderAmount: "Số tiền đơn hàng EkkHero",
		cancelOrderAmount: "Số tiền đơn hàng bị hủy",
		seeDetails: "Xem chi tiết",
	},
	voiceOrder: {
		pageTitle: "Đặt hàng bằng giọng nói",
		bannerHello: "Xin chào",
		bannerAI: "Chào mừng bạn đến với Voice Ordering AI",
		bannerUse: "Bạn có thể tìm thấy sản phẩm mong muốn bằng cách sử dụng tôi",
		speechTitle: "Giọng nói của bạn",
		speechHeader: "Tôi muốn đặt hàng - ",
		speechInfo:
			"Vui lòng nói cho tôi tên sản phẩm bằng cách nhấp vào nút trắng ở trên",
		keywordTitle: "Từ khóa phù hợp",
		keywordInfo:
			"Vui lòng chọn một từ khóa ở trên để lấy các sản phẩm liên quan",
		productsTitle: "Danh sách Sản phẩm phù hợp nhất",
		notFound:
			"Xin lỗi, tôi không thể tìm thấy bất kỳ sản phẩm nào, vui lòng thử lại..!",
	},
	addBankSettings: {
		bottomSheetTitle: "Thêm Tài Khoản Ngân Hàng",
		pageTitle: "Thêm Tài Khoản Ngân Hàng",
		accountName: "Tên Tài Khoản",
		accountNamePlaceholder: "Nhập tên tài khoản của bạn",
		accountNumber: "Số Tài Khoản",
		accountNumberPlaceholder: "Nhập số tài khoản của bạn",
		selectBank: "Chọn Ngân Hàng",
		selectBankPlaceHolder: "Chọn tài khoản ngân hàng của bạn",
		branch: "Chi Nhánh",
		branchPlaceHolder: "Nhập chi nhánh ngân hàng của bạn",
		address: "Địa Chỉ",
		addressPlaceholder: "Nhập địa chỉ ngân hàng",
		selectCountry: "Chọn Quốc Gia",
		selectCountryPlaceHolder: "Chọn quốc gia ngân hàng của bạn",
		selectCity: "Chọn Thành Phố",
		selectCityPlaceHolder: "Chọn thành phố ngân hàng của bạn",
		postalCode: "Mã Bưu Điện",
		postalCodePlaceHolder: "Nhập mã bưu điện ngân hàng của bạn",
		buttonTitle: "Lưu",
		bankNameWarning: "Vui lòng nhập tên tài khoản ngân hàng của bạn",
		bankNumberWarning: "Vui lòng nhập số tài khoản ngân hàng của bạn",
		selectBankWarning: "Vui lòng chọn ngân hàng",
		branchWarning: "Vui lòng nhập chi nhánh của bạn",
		addressWarning: "Vui lòng nhập địa chỉ của bạn",
		cityWarning: "Vui lòng nhập thành phố của bạn",
	},
	addedBankList: {
		pageTitle: "Danh sách ngân hàng đã thêm",
		acName: "Tên tài khoản",
		acNumber: "Số tài khoản",
		branch: "Chi nhánh",
		address: "Địa chỉ",
		city: "Thành phố",
		country: "Quốc gia",
		addBank: "Thêm tài khoản ngân hàng của bạn",
	},
	blackListed: {
		pageTitle: "Danh sách đen",
		warningMsg: "Doanh nghiệp của bạn đã bị đưa vào Danh sách đen",
		instrMsg:
			"Vui lòng yêu cầu chủ doanh nghiệp của bạn liên hệ với EkkBaz để thực hiện các biện pháp cần thiết để kích hoạt lại doanh nghiệp của bạn.",
		btnLabel: "Đăng xuất",
	},
	industryType: {
		pageTitle: "Ngành công nghiệp'",
		title: "Xin vui lòng chọn ngành nghề làm việc của bạn",
	},
	accounting: {
		todayRevenue: "Tổng Doanh Thu Hôm Nay",
		todayExpense: "Tổng Chi Phí Hôm Nay",
		revenues: "Doanh Thu",
		expenses: "Chi Phí",
		accPageTitle: "Tài Khoản",
		revenue: "Doanh Thu",
		expense: "Chi Phí",
		addRevenue: "Thêm Doanh Thu",
		addExpense: "Thêm Chi Phí",
		date: "Ngày",
		amount: "Số Tiền",
		amountPlaceholder: "Nhập số tiền",
		category: "Danh Mục",
		categoryPlaceholder: "Chọn một danh mục",
		description: "Mô Tả",
		descriptionPlaceholder: "Nhập mô tả",
		images: "Tải Hình Ảnh",
		supplier: "Nhà Cung Cấp",
		supplierPlaceholder: "Chọn một nhà cung cấp",
		submitRevenue: "Gửi Doanh Thu",
		submitExpense: "Gửi Chi Phí",
		uploadImageInfo: "Tải ảnh của bạn lên đây, hoặc duyệt",
		uploadImageInfoPlaceholder:
			"Dung lượng file tối đa hỗ trợ 10 MB, JPG, JPEG hoặc PNG",
		revenueCreateWarning:
			"Bạn có chắc chắn muốn gửi báo cáo doanh thu này không?",
		expenseCreateWarning:
			"Bạn có chắc chắn muốn gửi báo cáo chi phí này không?",
		successRevenue: "Tạo doanh thu thành công",
		successExpense: "Tạo chi phí thành công",
		supplierPageTitle: "Danh Sách Nhà Cung Cấp",
		alreadyPaid: "Chi phí đã được thanh toán?",
		onto: "Sẽ được thanh toán vào",
		paid: "Đã thanh toán",
		notPaid: "Chưa thanh toán",
		takePhoto: "Chụp ảnh",
		addImage: "Thêm hình ảnh",
		successFullyCreated: "Tạo thành công",
		amountCategoryDescription:
			"Vui lòng thêm một số tiền hợp lệ, chọn danh mục và viết mô tả",
		amountDescription: "Vui lòng thêm một số tiền hợp lệ và viết mô tả",
		amountCategory: "Vui lòng thêm một số tiền hợp lệ và chọn danh mục.",
		categoryDescription: "Vui lòng chọn một danh mục và viết mô tả",
		amountWarning: "Vui lòng nhập một số tiền hợp lệ.",
		descriptionWarning: "Vui lòng nhập một mô tả hợp lệ.",
		categoryWarning: "Vui lòng chọn một danh mục.",
	},
	employees: {
		pageTitle: "Nhân viên",
		createNewEmployees: "Tạo nhân viên mới",
		searchPlaceHolder: "Tìm kiếm nhân viên của bạn",
		collectorList: "Danh sách thu thập",
		collectorSearch: "Trình thu thập tìm kiếm",
	},
	employeesDetails: {
		pageTitle: "Chi tiết nhân viên",
	},
	createEmployees: {
		pageTitle: "Tạo Nhân Viên Mới",
		customerNoHolder: "Nhập Số Điện Thoại Di Động",
		employeeNumber: "Mã Nhân Viên",
		name: "Tên",
		namePlaceholder: "Nhập tên nhân viên",
		confirmBtn: "Xác Nhận",
		selectEmployees: "Chọn chức vụ của nhân viên",
		admin: "Quản trị viên",
		stuff: "Nhân viên",
		orderManager: "Quản lý Đơn Hàng",
		bookManager: "Quản lý Sách",
		productManager: "Quản lý Sản Phẩm",
		employeeManager: "Quản lý Nhân Viên",
		nameError: "Vui lòng nhập tên nhân viên",
		roleError: "Vui lòng chọn chức vụ cho nhân viên",
		edit: "Chỉnh Sửa",
		delete: "Xóa",
		editEmployee: "Chỉnh Sửa Nhân Viên",
		deleteConformationAsk: "Bạn có chắc chắn muốn xóa nhân viên này không?",
		updateEmployee: "Cập Nhật Nhân Viên",
	},
	loan: {
		pageTitle: "Khoản vay",
		myLoanRequest: "Yêu cầu vay của tôi",
		loanProviders: "Nhà cung cấp khoản vay",
		loanAmount: "Số tiền vay cần thiết",
		loanPurpose: "Mục đích khoản vay",
		country: "Quốc gia",
		loanDuration: "Thời hạn vay",
		creditScore: "Điểm tín dụng",
		createLoanRequest: "Tạo yêu cầu vay",
	},
};
export default vietnamese;
