import { IonApp, IonContent, IonPage } from "@ionic/react";

import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";

import React from "react";
import { useSelector } from "react-redux";
import RevenueEditForm from "components/mobile-components/Accounts/RevenueEditForm";

export default function AccountRevenueEditPage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<IonApp>
			<IonPage>
				<IonContent
					scrollEvents={true}
					// onIonScrollEnd={(ev) => {
					// 	getOrderList(orderStatus);
					// }}
				>
					<BackButtonTitle title='Revenue Update' />

					<section className='pt-14 px-6'>
						<RevenueEditForm ekkLanguage={ekkLanguage} />
					</section>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
