import React from "react";

export default function Image() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			viewBox='0 0 20 20'
			fill='none'>
			<path
				d='M16.25 0H3.75C2.75578 0.00110286 1.80259 0.396545 1.09957 1.09957C0.396545 1.80259 0.00110286 2.75578 0 3.75L0 16.25C0.00110286 17.2442 0.396545 18.1974 1.09957 18.9004C1.80259 19.6035 2.75578 19.9989 3.75 20H16.25C17.2442 19.9989 18.1974 19.6035 18.9004 18.9004C19.6035 18.1974 19.9989 17.2442 20 16.25V3.75C19.9989 2.75578 19.6035 1.80259 18.9004 1.09957C18.1974 0.396545 17.2442 0.00110286 16.25 0V0ZM3.75 2.5H16.25C16.5815 2.5 16.8995 2.6317 17.1339 2.86612C17.3683 3.10054 17.5 3.41848 17.5 3.75V16.25C17.5002 16.4142 17.4679 16.5767 17.405 16.7283C17.3421 16.8799 17.2497 17.0176 17.1333 17.1333L9.85667 9.85667C9.23157 9.23176 8.38388 8.88071 7.5 8.88071C6.61612 8.88071 5.76843 9.23176 5.14333 9.85667L2.5 12.5V3.75C2.5 3.41848 2.6317 3.10054 2.86612 2.86612C3.10054 2.6317 3.41848 2.5 3.75 2.5Z'
				fill='#FF564C'
			/>
		</svg>
	);
}
