import {
  IonApp,
  IonContent,
  IonPage,
  useIonViewDidEnter,
  useIonViewWillEnter,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import CostSummeryCard from "components/mobile-components/OrderDetails/CostSummeryCard";
import DeliveryDetailsCard from "components/mobile-components/OrderDetails/DeliveryDetailsCard";
import OrderItemCard from "components/mobile-components/OrderDetails/OrderItemCard";
import OrderMetaBar from "components/mobile-components/OrderDetails/OrderMetaBar";
import OrderOnwerState from "components/mobile-components/OrderDetails/OrderOnwerState";
import PaymentDetailsCard from "components/mobile-components/OrderDetails/PaymetDetailsCard";
import TimeLine from "components/mobile-components/OrderDetails/TimeLine";
import TimeLineTextBox from "components/mobile-components/OrderDetails/TimeLineTextBox";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOderCollectionAmount,
  fetchOrderDetailsByOrderId,
  fetchOrderSingle,
} from "redux/container/orderSlice";
import { useParams } from "react-router-dom";
import BottomSheet from "components/mobile-components/OrderDetails/BottomSheet";
import PaymentSettlementCard from "components/mobile-components/OrderDetails/PaymentSettlement";
import Orders from "services/Orders";

export default function OrderDetailsPage() {
  let { id } = useParams();
  const from = useSelector((state) => state.order.from);
  const dispatch = useDispatch();
  const response = useSelector((state) => state.order);
  let [collection, setCollection] = useState([]);

  useIonViewDidEnter(() => {
    if (from === "orderList") {
      dispatch(fetchOrderSingle(id));
    } else {
      dispatch(fetchOrderDetailsByOrderId(id));
    }
  });
  useIonViewWillEnter(() => {
    if (from === "orderList") {
      dispatch(fetchOrderSingle(id));
    } else {
      dispatch(fetchOrderDetailsByOrderId(id));
    }
  });

  const fetchOderCollectionAmount = async () => {
    if (from === "orderList") {
      let response = await Orders.orderAmountCollection(id);
      if (response?.status === 200) {
        setCollection(response.data);
      }
    } else {
      let res = await Orders.orderAmountCollection(response?.singleOrder?._id);
      if (res?.status === 200) {
        setCollection(res.data);
      }
    }
  };

  useEffect(() => {
    if (from === "orderList") {
      dispatch(fetchOrderSingle(id));
      fetchOderCollectionAmount(id);
    } else {
      dispatch(fetchOrderDetailsByOrderId(id));
      if (response?.singleOrder?.order_id === id) {
        fetchOderCollectionAmount(response?.singleOrder?._id);
      }
    }
  }, [response?.singleOrder?.order_id, id]);

  return (
    <IonApp>
      <IonPage>
        <IonContent>
          <BackButtonTitle
            title={response?.singleOrder?.seller?.business_name}
            from="orderDetailsPage"
          />

          <OrderMetaBar order={response.singleOrder} />
          <section className="px-2 pt-24">
            <OrderOnwerState order={response.singleOrder} />
            <DeliveryDetailsCard order={response.singleOrder} />
            <OrderItemCard order={response.singleOrder} />
            <CostSummeryCard order={response.singleOrder} />
            <PaymentDetailsCard order={response.singleOrder} />
            <PaymentSettlementCard collection={collection} />

            <TimeLine order={response.singleOrder} />
            <BottomSheet order={response.singleOrder} />
          </section>
          {/* <TimeLineTextBox order={response.singleOrder} /> */}
        </IonContent>
      </IonPage>
    </IonApp>
  );
}
