import { createSlice } from "@reduxjs/toolkit";

const supplierSlice = createSlice({
	name: "supplier",
	initialState: {
		data: false,
	},
	reducers: {
		setSupplier: (state, action) => {
			state.data = action.payload;
		},
	},
});

// Extract the action creators object and the reducer
export const { actions, reducer } = supplierSlice;

export const setSupplier = (values) => async (dispatch, getState) => {
	dispatch(actions.setSupplier(values));
};

// Export the reducer, either as a default or named export
export default reducer;
