import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import MenuLeftArrow from "assets/images/icon/svg/MenuLeftArrow";
import Search from "assets/images/icon/svg/Search";
import Cross from "assets/images/icon/svg/Cross";
import CrossBig from "assets/images/icon/svg/CrossBig";
import DotMenu from "assets/images/icon/svg/DotMenu";
import {
	setAccountBottomSheet,
	setLoanBottomSheet,
	setMyEmployees,
} from "redux/container/userSlice";
import Filter from "assets/images/icon/svg/Filter";
import { dateTimeFormatter } from "helpers/helper";
import {
	getSelectedDate,
	setAccountDataLoading,
	setAccountingSort,
	setIsFilter,
	setSelectedDate,
} from "redux/container/dashboardSlice";
import Delete from "assets/images/icon/svg/Delete";
import moment from "moment";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IonItem, IonLabel, IonRadio, IonRadioGroup } from "@ionic/react";

export default function BackButtonTitle({
	title,
	getData,
	searchQ,
	setSearchQ,
	itemsSearch,
	placeholder,
	fetchSearchData,
	initData,
	deleteQuery,
	inputType,
	from,
	status,
	filter,
	sort,
	showFilter,
	setHasMoreData,
	setReachedEnd,
}) {
	const [isOpen, setIsOpen] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const accountTypeDetails = useSelector((state) => state.dashboard.accountTypeDetails);
	const onCloseModal = () => {
		setIsOpen(false);
	};
	const [searchOpen, setSearchOpen] = useState(false);
	const [tempSearchParams, setTempSearchParams] = useState("");
	const numberInputRef = useRef(null);
	const date = useSelector((state) => state.dashboard.dateInfo);
	const selectedDate = useSelector((state) => state.dashboard.selectedDate);
	const dateFormat = useSelector((state) => state.date.format);

	const accountingSort = useSelector(
		(state) => state.dashboard.accountingSort
	);
	const [selectedSort, setSelectedSort] = useState(accountingSort);

	let value = date?.level ? date?.level : "this month";
	const options = [
		{ value: "today", label: ekkLanguage.dashboard.today, name: "Today" },
		{
			value: "yesterday",
			label: ekkLanguage.dashboard.yesterday,
			name: "Yesterday",
		},
		{
			value: "week",
			label: ekkLanguage.dashboard.thisWeek,
			name: "This Week",
		},
		{
			value: "month",
			label: ekkLanguage.dashboard.thisMonth,
			name: "This Month",
		},
		{
			value: "year",
			label: ekkLanguage.dashboard.thisYear,
			name: "This Year",
		},
		{
			value: "lastWeek",
			label: ekkLanguage.dashboard.lastWeek,
			name: "Last Week",
		},
		{
			value: "lastMonth",
			label: ekkLanguage.dashboard.lastMonth,
			name: "Last Month",
		},
		{
			value: "lastYear",
			label: ekkLanguage.dashboard.lastYear,
			name: "Last Year",
		},
		{
			value: "custom",
			label: ekkLanguage.dashboard.custom,
			name: "Custom Day",
		},
		{
			value: "selectDay",
			label: ekkLanguage.dashboard.selectDay,
			name: "Custom Day Range",
		},
		{
			value: "category",
			label: "Category",
			name: "Category",
		},
	];
	useEffect(() => {
		const handleWheel = (event) => {
			if (
				numberInputRef.current &&
				numberInputRef.current === document.activeElement
			) {
				event.preventDefault();
			}
		};
		window.addEventListener("wheel", handleWheel, { passive: false });
		return () => {
			window.removeEventListener("wheel", handleWheel);
		};
	}, []);

	const [filterOpen, setFilterOpen] = useState(false);
	const dropdownRef = useRef(null);
	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "calc(100% - 40px)",
			transition: ".4s all ease",
			borderRadius: 12,
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
			overflow: "auto",
		},
	};
	const [isOpenSelectDayModel, setIsOpenSelectDayModel] = useState(false);
	const [isOpenCustomDayModel, setIsOpenCustomDayModel] = useState(false);
	const [sortModal, setSortModal] = useState(false);
	const openCustomDayModal = () => setIsOpenCustomDayModel(true);
	const openSelectDayModal = () => setIsOpenSelectDayModel(true);
	const closeCustomDayModal = () => setIsOpenCustomDayModel(false);
	const closeSelectDayModal = () => setIsOpenSelectDayModel(false);
	const closeSortModal = () => setSortModal(false);
	const openSortModal = () => setSortModal(true);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target)
			) {
				setFilterOpen(false);
			}
		};

		window.addEventListener("mousedown", handleClickOutside);
		return () => {
			window.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const dateChanger = (date, type) => {
		if (type === "startSelected") {
			let dateTime = {
				start: moment(date).format("YYYY-MM-DD"),
				end: moment(selectedDate?.end).format("YYYY-MM-DD"),
				level: "selectDay",
			};
			dispatch(setSelectedDate(dateTime));
		} else if (type === "endSelected") {
			let dateTime = {
				start: moment(selectedDate?.start).format("YYYY-MM-DD"),
				end: moment(date).format("YYYY-MM-DD"),
				level: "selectDay",
			};
			dispatch(setSelectedDate(dateTime));
		} else if (type === "startCustom") {
			let dateTime = {
				start: moment(date).format("YYYY-MM-DD"),
				end: moment(date).format("YYYY-MM-DD"),
				level: "custom",
			};
			dispatch(setSelectedDate(dateTime));
		}
	};

	const customDateHandler = () => {
		let dateTime = {
			start: moment(selectedDate?.start).format("YYYY-MM-DD"),
			end: moment(selectedDate?.end).format("YYYY-MM-DD"),
			level: selectedDate?.level,
		};
		dispatch(getSelectedDate(dateTime));
		setFilterOpen(false);
	};
	const selectDayHandler = () => {
		let dateTime = {
			start: moment(selectedDate?.start).format("YYYY-MM-DD"),
			end: moment(selectedDate?.end).format("YYYY-MM-DD"),
			level: selectedDate?.level,
		};
		dispatch(getSelectedDate(dateTime));
		setFilterOpen(false);
	};

	const handleFilter = (value) => {
		setReachedEnd(false);
		setHasMoreData(true);

		if (value?.value === "custom") {
			let dateTime = {
				start: moment().startOf("day").format("YYYY-MM-DD"),
				end: moment().endOf("day").format("YYYY-MM-DD"),
				level: "today",
			};
			dispatch(setSelectedDate(dateTime));
			openCustomDayModal();
		} else if (value?.value === "selectDay") {
			let dateTime = {
				start: moment().startOf("day").format("YYYY-MM-DD"),
				end: moment().endOf("day").format("YYYY-MM-DD"),
				level: "today",
			};
			dispatch(setSelectedDate(dateTime));
			openSelectDayModal();
		}else if (value?.value === "category") {
			navigate(`/categories/${accountTypeDetails?.title}/${accountTypeDetails?.id}?from=accounts`);
		} else {
			let timeDate = dateTimeFormatter(value?.value);
			let dateTime = {
				start: moment(timeDate?.start).format("YYYY-MM-DD"),
				end: moment(timeDate?.end).format("YYYY-MM-DD"),
				level: value?.name,
			};
			dispatch(getSelectedDate(dateTime));
			setFilterOpen(false);
		}
	};

	const SelectDayCustomInput = forwardRef(({ value, onClick }, ref) => (
		<div
			ref={ref}
			className={`p-3 block mt-4 example-custom-input pl-4 h-[50px] rounded-[5px] bg-F8F8F8  w-full border border-black-500`}
			onClick={onClick}>
			<div className='flex justify-between items-center'>
				<div className='flex items-center '>
					{moment(selectedDate?.start, "YYYY-MM-DD").format(dateFormat)}
				</div>
			</div>
		</div>
	));
	const SelectDayCustomInput2 = forwardRef(({ value, onClick }, ref) => (
		<div
			ref={ref}
			className={`p-3 block mt-4 example-custom-input pl-4 h-[50px] rounded-[5px] bg-F8F8F8  w-full border border-black-500`}
			onClick={onClick}>
			<div className='flex justify-between items-center'>
				<div className='flex items-center '>
					{moment(selectedDate?.end, "YYYY-MM-DD").format(dateFormat)}
				</div>
			</div>
		</div>
	));
	const CustomDayCustomInput = forwardRef(({ value, onClick }, ref) => (
		<div
			ref={ref}
			className={`p-3 block mt-4 example-custom-input pl-4 h-[50px] rounded-[5px] bg-F8F8F8  w-full border border-black-500`}
			onClick={onClick}>
			<div className='flex justify-between items-center'>
				<div className='flex items-center '>
					{moment(selectedDate?.start, "YYYY-MM-DD").format(dateFormat)}
				</div>
			</div>
		</div>
	));

	return (
		<>
			<div
				className={`top-nav-bar z-40 flex pl-4 items-center justify-between py-2 pt-2 px-2 fixed left-0 top-0 w-full pr-4 ${"pb-1"}`}>
				<div className='flex items-center justify-between w-full'>
					<div className='flex items-center w-full justify-start'>
						<div
							className='flex items-center justify-start h-10 rounded-[10px] cursor-pointer'
							onClick={() => {
								if (location.pathname === "/create-order") {
									let localCart = localStorage.getItem("cart");
									let cartParse = JSON.parse(localCart);
									if (cartParse?.length > 0) {
										setIsOpen(true);
									} else {
										navigate(-1);
									}
								} else if (location.pathname === "/accounts") {
									navigate("/home");
								}else if (location.pathname === "/employees-list") {
									navigate("/profile");
								}  else {
									navigate(-1);
								}
								// if (location.pathname.split("/")[1] === "categories") {
								// 	navigate(-1);
								// } else {
								// 	navigate(-1);
								// }
							}}>
							{/* else if (
									location.pathname.split("/")[1] === "supplier-list"
								) {
									navigate("/expense-form");
								} */}
							<MenuLeftArrow />
						</div>
						<div className={`mx-5 w-full`}>
							{searchOpen ? (
								<div>
									<div className={`relative`}>
										<span className='absolute left-[10px] top-[24%] h-full z-10 cursor-pointer'>
											<Search color='rgba(0,0,0,.6)' />
										</span>
										<input
											ref={numberInputRef}
											type={inputType}
											className='bg-white w-full h-[38px] rounded-[7px] pl-12 search-input border'
											placeholder={placeholder}
											style={{
												boxShadow:
													"1px 2px 8px rgba(0, 0, 0, 0.2) ",
											}}
											value={searchQ}
											onKeyPress={(e) => {
												if (e.key === "Enter") {
													fetchSearchData(e, searchQ);
													setTempSearchParams(searchQ);
												}
											}}
											onChange={(e) =>
												setSearchQ(e.target.value)
											}></input>
										{searchQ && (
											<span
												className='absolute  right-[10px] top-[34%] h-full z-10 cursor-pointer'
												onClick={deleteQuery}>
												<Cross />
											</span>
										)}
									</div>

									{tempSearchParams && (
										<p className='px-4 pt-1 text-10 text-white'>
											<span>
												Search:{" "}
												<span className='font-bold'>
													{" "}
													{tempSearchParams}
												</span>
											</span>
										</p>
									)}
								</div>
							) : (
								<h5 className='text-white text-18 font-bold'>
									{title}
								</h5>
							)}
						</div>
					</div>
					<div
						className='h-10 flex items-center cursor-pointer'
						onClick={() => {
							setSearchOpen(!searchOpen);
							if (searchOpen) {
								dispatch(setAccountDataLoading(false));
								setTempSearchParams("");
								setSearchQ("");
								initData(status, date?.start, date?.end);
							}
						}}>
						{searchOpen ? <CrossBig /> : <Search />}
					</div>
					<>
						{!searchOpen && (
							<>
								{filter && (
									<div
										className='ml-2 relative cursor-pointer'
										ref={dropdownRef}>
										<span onClick={() => setFilterOpen(!filterOpen)}>
											<Filter />
										</span>
										{filterOpen && (
											<div
												className='absolute right-0 top-full mt-2 w-48 bg-white rounded-lg shadow-lg z-50'
												onBlur={() => setFilterOpen(false)}>
												<div className='py-2'>
													{options?.map((option) => (
														<div
															className={`flex items-center justify-between ${
																showFilter &&
																value === option?.name
																	? "bg-gray-200"
																	: "bg-white"
															} hover:bg-gray-200`}>
															<button
																className={`w-full text-left px-4 py-2 `}
																onClick={() => {
																	handleFilter(option);
																	// setShowFilter(true);
																	dispatch(setIsFilter(true));
																}}>
																{option?.name}
															</button>
															{/* {showFilter &&
															value === option?.name ? (
																<span
																	onClick={filterHide}
																	className='cursor-pointer font-bold text-14 border-black-500 border rounded-full h-[18px] w-[18px] inline-block text-center mr-2'
																	style={{
																		lineHeight: "16px",
																	}}>
																	X
																</span>
															) : undefined} */}
														</div>
													))}
												</div>
											</div>
										)}
									</div>
								)}

								{sort && (
									<div
										className='mr-1 ml-2 cursor-pointer w-9'
										style={
											accountingSort === "-id"
												? {
														boxShadow:
															"1px 2px 10px rgba(255, 255, 255, .6)",
														borderRadius: "4px",
												  }
												: {}
										}
										onClick={openSortModal}>
										<img src='assets/images/sort.png' alt='filter' />
									</div>
								)}
								{from === "myEmployees" && (
									<div className='ml-2'>
										<span onClick={() => dispatch(setMyEmployees(true))}>
											<DotMenu />
										</span>
									</div>
								)}
								{from === "accounts" && (
									<div className='ml-2 cursor-pointer'>
										<span
											onClick={() =>
												dispatch(setAccountBottomSheet(true))
											}>
											<DotMenu />
										</span>
									</div>
								)}
								{from === "loan" && (
									<div className='mr-2 ml-2 cursor-pointer'>
										<span
											onClick={() =>
												dispatch(setLoanBottomSheet(true))
											}>
											<DotMenu />
										</span>
									</div>
								)}
							</>
						)}
					</>
				</div>
			</div>

			<Modal
				style={customStyles}
				open={isOpenSelectDayModel}
				onClose={closeSelectDayModal}>
				<div className='p-5'>
					<div>
						<div className='pb-5'>
							<div className='flex'>
								<h4 className='font-bold text-14'>Start Date</h4>
							</div>
							<DatePicker
								dateFormat='dd/MM/yyyy'
								selected={moment(selectedDate?.start).toDate()}
								onChange={(date) => dateChanger(date, "startSelected")}
								customInput={<SelectDayCustomInput />}
								withPortal
							/>
						</div>
						<div className='pb-5'>
							<div className='flex'>
								<h4 className='font-bold text-14'>End Date</h4>
							</div>
							<DatePicker
								dateFormat='dd/MM/yyyy'
								selected={moment(selectedDate?.end).toDate()}
								onChange={(date) => dateChanger(date, "endSelected")}
								customInput={<SelectDayCustomInput2 />}
								withPortal
							/>
						</div>
					</div>

					<div className='flex'>
						<button
							className=' font-bold h-10 bg-primary rounded-[7px] w-1/2 mr-2 text-white  border-solid'
							onClick={() => {
								closeSelectDayModal();
							}}>
							{ekkLanguage.orderDetails.noBtn}
						</button>
						<button
							color='primary'
							className='font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-success text-white'
							onClick={() => {
								closeSelectDayModal();
								selectDayHandler();
							}}>
							{ekkLanguage.orderDetails.yesBtn}
						</button>
					</div>
				</div>
			</Modal>
			<Modal
				style={customStyles}
				open={isOpenCustomDayModel}
				onClose={closeCustomDayModal}>
				<div className='p-5'>
					<div>
						<div className='pb-5'>
							<div className='flex'>
								<h4 className='font-bold text-14'>Select Date</h4>
							</div>
							<DatePicker
								dateFormat='dd/MM/yyyy'
								selected={moment(selectedDate?.start).toDate()}
								onChange={(date) => dateChanger(date, "startCustom")}
								customInput={<CustomDayCustomInput />}
								withPortal
							/>
						</div>
					</div>

					<div className='flex'>
						<button
							className=' font-bold h-10 bg-primary rounded-[7px] w-1/2 mr-2 text-white  border-solid'
							onClick={() => {
								closeCustomDayModal();
							}}>
							{ekkLanguage.orderDetails.noBtn}
						</button>
						<button
							color='primary'
							className='font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-success text-white'
							onClick={() => {
								closeCustomDayModal();
								customDateHandler();
							}}>
							{ekkLanguage.orderDetails.yesBtn}
						</button>
					</div>
				</div>
			</Modal>

			<Modal style={customStyles} open={sortModal} onClose={closeSortModal}>
				<div className='p-5'>
					<div>
						<div className='pb-5'>
							<h4 className='font-bold text-14 mb-6'>
								Select Sort Option
							</h4>

							<div>
								<IonRadioGroup
									value={selectedSort}
									onIonChange={(e) => {
										setSelectedSort(e.detail.value);
									}}>
									<IonItem
										lines='none'
										className='bg-white border rounded-[10px] mb-5 relative'>
										<IonRadio value='-date' color='success' />
										<IonLabel className='absolute w-full left-10 border-0 font-sans text-14 text-black-500 font-medium'>
											<span className=''>Record Date</span>
										</IonLabel>
									</IonItem>
									<IonItem
										lines='none'
										className='bg-white border rounded-[10px] mb-5 relative'>
										<IonRadio value='-id' color='success' />
										<IonLabel className='absolute w-full left-10 border-0 font-sans text-14 text-black-500 font-medium'>
											<span className=''>
												Created By Date and Time
											</span>
										</IonLabel>
									</IonItem>
								</IonRadioGroup>
							</div>
						</div>
					</div>

					<div className='flex'>
						<button
							className=' font-bold h-10 bg-primary rounded-[7px] w-1/2 mr-2 text-white  border-solid'
							onClick={() => {
								closeSortModal();
							}}>
							Cancel
						</button>
						<button
							color='primary'
							className='font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-success text-white'
							onClick={() => {
								dispatch(setAccountingSort(selectedSort));
								closeSortModal();
							}}>
							Apply Sort
						</button>
					</div>
				</div>
			</Modal>
		</>
	);
}
