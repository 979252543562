import {
	IonApp,
	IonContent,
	IonHeader,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonPage,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import ProductFilter from "components/mobile-components/SelectProduct/ProductFilter";
import ProductList from "components/mobile-components/SelectProduct/ProductList";
import ProductSearch from "components/mobile-components/SelectProduct/ProductSearch";
import SelectProduct from "components/mobile-components/SelectProduct/SelectProduct";
import { getProductSeller, getUserBusiness } from "config/user";
import { shoppingListItem } from "helpers/shoppingCart";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Product from "services/Product";

export default function SelectProductPage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [productList, setProductList] = useState([]);
	const [productCartList, setProductCartList] = useState(shoppingListItem());
	const [productBrands, setProductBrands] = useState([]);
	const [searchQ, setSearchQ] = useState("");
	const [selectedBrand, setSelectedBrand] = useState("");
	const [loading, setLoading] = useState(false);
	const [pageCount, setPageCount] = useState(2);
	const [metaCount, setMetaCount] = useState(30);
	const [tempSearchParams, setTempSearchParams] = useState(false);
	// brandId;

	async function getProductBrands() {
		try {
			const response = await Product.productBrands(
				getProductSeller().seller_business_pg_id
			);
			if (response.status === 200) {
				setProductBrands(response.results);
				setMetaCount(response.count);
			}
		} catch (error) {
			console.log(error);
		}
	}

	async function getProductsFromBrands(id) {
		setLoading(true);
		setPageCount(2);
		setSelectedBrand(id);
		setMetaCount(1);
		setProductList([]);
		try {
			if (searchQ) {
				let response = await Product.singleProductSearch(
					id,
					getProductSeller().seller_business_pg_id,
					searchQ
				);
				if (response.status === 200) {
					setProductList(response?.results);
					setMetaCount(response.count);
				}
			} else {
				const response = await Product.singleBrandProducts(
					id,
					getProductSeller().seller_business_pg_id
				);
				if (response.status === 200) {
					setProductList(response?.results);
					setMetaCount(response.count);
				}
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	}
	async function fetchProduct() {
		setLoading(true);
		try {
			if (searchQ) {
				let response = await Product.sellerProductSearch(
					getProductSeller().seller_business_pg_id,
					searchQ
				);
				if (response.status === 200) {
					setLoading(false);
					setProductList(response.results);
					setMetaCount(response.count);
				} else {
					setLoading(false);
				}
			} else {
				if (selectedBrand) {
					const response = await Product.singleBrandProducts(
						selectedBrand
					);
					if (response.status === 200) {
						setProductList(response?.results);
						setMetaCount(response.count);
						setLoading(false);
					} else {
						setLoading(false);
					}
				} else {
					const response = await Product.buyerSellerProductList(
						getUserBusiness().id,
						getProductSeller().seller_business_pg_id
					);
					if (response.status === 200) {
						setLoading(false);
						setProductList(response.results);
						setMetaCount(response.count);
					} else {
						setLoading(false);
					}
				}
			}
		} catch (error) {
			console.log(error);
		}
	}

	let getAllProduct = async () => {
		setLoading(true);
		try {
			if (searchQ) {
				let response = await Product.sellerProductSearch(
					getProductSeller().seller_business_pg_id,
					searchQ
				);
				if (response.status === 200) {
					setLoading(false);
					setProductList(response.results);
					setMetaCount(response.count);
				} else {
					setLoading(false);
				}
			} else {
				const response = await Product.buyerSellerProductList(
					getUserBusiness().id,
					getProductSeller().seller_business_pg_id
				);
				if (response.status === 200) {
					setLoading(false);
					setProductList(response.results);
					setMetaCount(response.count);
				} else {
					setLoading(false);
				}
			}
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		fetchProduct();
		getProductBrands();
	}, []);
	async function singleProductSearch() {
		setTempSearchParams(searchQ);
		try {
			let response;
			if (selectedBrand) {
				response = await Product.singleProductSearch(
					selectedBrand,
					getProductSeller().seller_business_pg_id,
					searchQ
				);
			} else {
				response = await Product.sellerProductSearch(
					getProductSeller().seller_business_pg_id,
					searchQ
				);
			}

			if (response.status === 200) {
				setProductList(response.results);
				setMetaCount(response.count);
			}
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		if (searchQ === "") {
			fetchProduct();
			setTempSearchParams("");
		}
	}, [searchQ]);

	let lodeMoreData = async () => {
		if (searchQ) {
			if (selectedBrand) {
				let response = await Product.singleProductSearch(
					selectedBrand,
					getProductSeller().seller_business_pg_id,
					searchQ,
					pageCount
				);
				if (response.status === 200) {
					if (response.results.length > 0) {
						setPageCount((prev) => prev + 1);
						setProductList([...productList, ...response.results]);
						setMetaCount(response.count);
					}
				}
			} else {
				let response = await Product.sellerProductSearch(
					getProductSeller().seller_business_pg_id,
					searchQ,
					pageCount
				);
				if (response.status === 200) {
					if (response.results.length > 0) {
						setPageCount((prev) => prev + 1);
						setProductList([...productList, ...response.results]);
						setMetaCount(response.count);
					}
				}
			}
		} else {
			if (selectedBrand) {
				const response = await Product.singleBrandProducts(
					selectedBrand,
					pageCount
				);
				if (response.status === 200) {
					setPageCount((prev) => prev + 1);
					setProductList([...productList, ...response.results]);
					setMetaCount(response.count);
				}
			} else {
				let response = await Product.buyerSellerProductList(
					getUserBusiness().id,
					getProductSeller().seller_business_pg_id,
					pageCount
				);
				if (response.status === 200) {
					if (response.results.length > 0) {
						setPageCount((prev) => prev + 1);
						setProductList([...productList, ...response.results]);
						setMetaCount(response.count);
					}
				}
			}
		}
	};

	return (
		<IonApp>
			<IonPage>
				<IonHeader>
					<BackButtonTitle
						title={ekkLanguage.selectProduct.pageTitle}
						seletAll='true'
					/>
				</IonHeader>
				<IonContent
					scrollEvents={true}
					// onIonScrollEnd={(ev) => {
					// 	if (Number(metaCount) / 10 > pageCount) {
					// 		searchNext(ev);
					// 	}
					// }}
				>
					<section className='pt-14'>
						<ProductSearch
							setSearchQ={setSearchQ}
							searchQ={searchQ}
							// stickySearch={true}
							fetchProduct={fetchProduct}
							singleProductSearch={singleProductSearch}
							setSelectedBrand={setSelectedBrand}
						/>
						<p className='px-4 pb-4'>
							{tempSearchParams && (
								<span>
									search:{" "}
									<span className='font-bold'>{tempSearchParams}</span>
								</span>
							)}
						</p>
						<ProductFilter
							productBrands={productBrands}
							getProductsFromBrands={getProductsFromBrands}
							getProductBrands={getProductBrands}
							fetchProduct={fetchProduct}
							setSearchQ={setSearchQ}
							setSelectedBrand={setSelectedBrand}
							getAllProduct={getAllProduct}
						/>
						<ProductList
							loading={loading}
							productList={productList}
							setProductList={setProductList}
							productCartList={productCartList}
							setProductCartList={setProductCartList}
						/>
						<SelectProduct product={productList && productList[0]} />
					</section>
					<div>
						<IonInfiniteScroll
							onIonInfinite={(ev) => {
								lodeMoreData();
								setTimeout(() => ev.target.complete(), 1000);
							}}>
							<IonInfiniteScrollContent
								className='pb-24'
								loadingText={ekkLanguage.orderList.loader}
								loadingSpinner='bubbles'></IonInfiniteScrollContent>
						</IonInfiniteScroll>
					</div>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
