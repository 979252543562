import {
	IonApp,
	IonButton,
	IonCol,
	IonContent,
	IonPage,
	IonRow,
} from "@ionic/react";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function AutoLogoutPage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);

	const navigate = useNavigate();
	const handleNavigate = () => {
		localStorage.clear();
		window.location.reload(false);
		window.location.href = "/";
	};
	return (
		<IonApp>
			<IonPage className='business-location'>
				<IonContent className='h-screen relative'>
					<div className='pt-9 pb-4 shadow-sm h-20 bg-[#FF564C]'>
						<h1 className='text-center text-[#FFF] text-20 font-bold'>
							{ekkLanguage.autoLogOut.pageTitle}
						</h1>
					</div>
					<div className='pt-10 flex justify-center'>
						<img className='h-60 w-60' src='assets/images/error.png' />
					</div>
					<h2 className='px-5 pt-5 font-bold text-16 text-[#222] text-center'>
						{ekkLanguage.autoLogOut.warningMsg}
					</h2>
					<h4 className='px-7 pt-8 text-[#FF564CCC] font-semibold text-14 text-[#222] text-center'>
						{ekkLanguage.autoLogOut.instrMsg}
					</h4>
					<div className='flex justify-center'>
						<IonButton
							color='success'
							className='font-bold rounded-lg items-center mx-4 h-12 mt-6 w-full text-24 '
							onClick={() => {
								handleNavigate();
							}}>
							{ekkLanguage.autoLogOut.btnLabel}
						</IonButton>
					</div>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
