import { IonButton, IonSpinner } from "@ionic/react";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import User from "services/User";
import Check from "assets/images/icon/svg/Check";
import ImageUploader from "./ImageUploader";
import Delete from "assets/images/icon/svg/Delete";
import { setKYC } from "redux/container/businessSettingsSlice";
import Business from "services/Business";
import { getUserBusiness, setUserBusiness } from "config/user";

export default function NIDVerification() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const bs = useSelector((state) => state.businessSettings);
	const navigate = useNavigate();
	const [files1, setFiles1] = useState(bs.data?.nid_front_img ?? "");
	const [files2, setFiles2] = useState(bs.data?.nid_back_img ?? "");
	const [loader, setLoader] = useState(false);
	const business = getUserBusiness();

	let dispatch = useDispatch();

	const nextHandler = async () => {
		if (!files1[0].preview && !files2[0].preview) {
			localStorage.setItem("auth", true);
			return navigate("/trade-license-verification");
		}

		setLoader(true);
		let frontPart = new FormData();
		let backPart = new FormData();
		frontPart.append("key", files1[0]);
		backPart.append("key", files2[0]);

		if (files1.length !== 0 && files2.length !== 0) {
			let response = await User.toBucketImage(frontPart);
			let response2 = await User.toBucketImage(frontPart);
			if (response.status === 201 && response2.status === 201) {
				setLoader(false);
				dispatch(
					setKYC({
						nid_front_img: response.results[0],
						nid_back_img: response2.results[0],
					})
				);

				let query = {
					nid_front_img: response.results[0],
					nid_back_img: response2.results[0],
					business: business?.id,
				};

				const res = bs.data?.kycUpdate
					? await Business.updateKYC(bs.data?.kycId, {
							nid_front_img: response.results[0],
							nid_back_img: response2.results[0],
					  })
					: await Business.createKYC(query);
				if (res.status === 200) {
					dispatch(
						setKYC({
							kycUpdate: true,
							business_status: "Pending",
						})
					);
					setUserBusiness({
						...business,
						business_status: "Pending",
					});
					navigate("/trade-license-verification");
				} else if (res.status === 201) {
					dispatch(
						setKYC({
							kycId: res.results?.id,
							kycUpdate: true,
							business_status: "Pending",
						})
					);
					setUserBusiness({
						...business,
						business_status: "Pending",
					});
					navigate("/trade-license-verification");
				}
				localStorage.setItem("auth", true);
			} else {
				setLoader(false);
			}
		}
	};
	const getKyc = async () => {
		let res = await Business.getKYC(business.id);

		if (res.success) {
			dispatch(
				setKYC({
					kycUpdate: true,
					nid_back_img: res.results?.nid_back_img,
					nid_front_img: res.results?.nid_front_img,
					rent_amount: res.results?.rent_amount,
					rent_start_date: res.results?.rent_start_date,
					property_type: res.results?.property_type,
					trade_img: res.results?.trade_img,
					contract_paper_img: res.results?.contract_paper_img,
					business: business?.id,
					kycId: res.results?.id,
					business_status: res.results?.business_status,
				})
			);
		} else {
			dispatch(
				setKYC({
					kycUpdate: false,
				})
			);
		}
	};

	useEffect(() => {
		getKyc();
	}, []);

	return (
		<div className='pt-14 verifyBusiness-content'>
			<div className='py-5 px-4'>
				<div className='pt-4 pb-[10px] flex items-center justify-between'>
					<h4 className='font-bold text-black text-16'>NID - Front Side</h4>
					<span
						className={`h-10 w-10 flex items-center justify-center  bg-white
						rounded-[5px]`}
						onClick={() => setFiles1("")}
					>
						<Delete />
					</span>
				</div>

				<ImageUploader
					files={files1}
					setFiles={setFiles1}
					bHeight={246}
					placeHolder='Upload NID “Front Side”'
				/>
			</div>
			<div className='pb-5 px-4'>
				<div className='pt-4 pb-[10px] flex items-center justify-between'>
					<h4 className='font-bold text-black text-16'>NID - Back Side</h4>
					<span
						className={`h-10 w-10 flex items-center justify-center bg-white
						 rounded-[5px]`}
						onClick={() => setFiles2("")}
					>
						<Delete />
					</span>
				</div>
				<ImageUploader
					files={files2}
					setFiles={setFiles2}
					bHeight={246}
					placeHolder='Upload NID “Back Side”'
				/>
			</div>
			<div className='pt-5 px-4'>
				{files1.length && files2.length ? (
					<div
						className='rounded-[5px] text-center py-4'
						style={{
							background: "#CCFFD6",
							boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.15)",
						}}
					>
						<p
							className='font-normal text-14 flex justify-center mb-[10px]'
							style={{ color: "#808080" }}
						>
							<Check></Check> Upload Complete.
						</p>
						<p className='font-normal text-14' style={{ color: "#808080" }}>
							Please click "<span style={{ color: "#00B224" }}>NEXT</span>"
							button
						</p>
					</div>
				) : (
					<div
						className='rounded-[5px]'
						style={{
							background: "#FFFFCC",
							boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.15)",
						}}
					>
						<p
							className='font-normal p-[14px] text-14'
							style={{ color: "#808080" }}
						>
							Please, upload your{" "}
							<b className='text-primary font-semibold'>“NID”</b> copy.
						</p>
					</div>
				)}

				<div className='pt-24 text-center pb-10'>
					<IonButton
						disabled={files1 && files2 ? false : true}
						color={files1 && files2 ? "success" : "dark"}
						className='font-bold h-[50px] w-full'
						onClick={nextHandler}
					>
						{loader ? (
							<IonSpinner></IonSpinner>
						) : (
							<>
								{ekkLanguage.businessCreateName.btnLabel}
								<span className='pl-2'>
									<ArrayRight />
								</span>
							</>
						)}
					</IonButton>
					<IonButton
						color='white'
						className='font-bold h-[50px] w-full mt-[30px] border-primary border-2 text-primary rounded-[5px]'
						onClick={() => navigate("/trade-license-verification")}
					>
						I will give this information later
						<span className='pl-2'>
							<ArrayRight color='red' />
						</span>
					</IonButton>
				</div>
			</div>
		</div>
	);
}
