import {
	IonApp,
	IonContent,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonLoading,
	IonPage,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TitleNSearch from "components/mobile-components/Common/TitleNSearch";
import AccountsStatus from "components/mobile-components/Accounts/AccountsStatus";
import AccountBottomSheet from "components/mobile-components/Accounts/AccountBottomSheet";
import Accounts from "services/Accounts";
import LoanStatus from "components/mobile-components/Loan/LoanStatus";
import LoanBottomSheet from "components/mobile-components/Loan/LoanBottomSheet";
import Loan from "services/Loan";
import Department from "services/Department";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";

export default function LoanPage() {
	const [menuCollapse, setMenuCollapse] = useState(true);
	const [loading, setLoading] = useState(false);
	const [orderStatus, setOrderStatus] = useState("loanRequest");
	const productType = useSelector((state) => state.product?.loanProductType);
	const dispatch = useDispatch();
	const [text, setText] = useState("");
	const menuIconClick = () => {
		setMenuCollapse(!menuCollapse);
	};
	const [pageCount, setPageCount] = useState(2);
	const [accountType, setAccountType] = useState(null);
	const [hasMoreData, setHasMoreData] = useState(true);
	const [reachedEnd, setReachedEnd] = useState(false);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [accountData, setAccountData] = useState([]);
	const [loanProductId, setLoanProductId] = useState("");
	const [products, setProducts] = useState([]);
	const [totalCount, setTotalCount] = useState();

	let getAccountInfoList = async (type) => {
		if (type === "loanProducts") {
			let response = await Department.getDepartments();
			if (response.status === 200) {
				let finance = response.results?.find((el) => el.title === "Loans");
				setLoanProductId(finance?.id);
				if (finance?.id) {
					setLoading(true);
					let response = await Department.getDepartmentProducts(
						finance?.id
					);
					if (response.success) {
						setLoading(false);
						setProducts(response?.results);
						setTotalCount(response?.count);
					} else {
						setLoading(false);
						setProducts([]);
					}
				}
			} else {
				setLoanProductId("");
			}
		} else {
			setLoading(true);
			setPageCount(2);
			if (hasMoreData) {
				let response = await Loan.getRequestedLoan();
				if (!response?.results?.length) {
					setLoading(false);
					setHasMoreData(false);
				}
				if (response?.success) {
					setLoading(false);
					setAccountData(response?.results);
				} else {
					setLoading(false);
					setAccountData([]);
				}
			}
		}
	};

	let getSearchData = async () => {
		// setPageCount(2);
		// setLoading(true);
		// let resp = await Accounts.getAccountListSearch(text, 1, 500);
		// if (!resp?.results?.length) {
		// 	setHasMoreData(false);
		// 	setLoading(false);
		// }
		// if (resp?.success) {
		// 	setLoading(false);
		// 	setAccountData(resp?.results);
		// } else {
		// 	setLoading(false);
		// 	setAccountData([]);
		// }
	};

	const deleteQueryHandler = async (e) => {
		setLoading(false);
		setText("");
		getAccountInfoList(orderStatus);
	};

	let loadMoreTran = async (type) => {
		if (hasMoreData) {
			if (text) {
				let response = await Accounts.getAccountListSearch(
					accountType,
					text,
					pageCount
				);
				if (response.status === 200) {
					setLoading(false);
					setPageCount((prev) => prev + 1);
					setAccountData([...accountData, ...response.results]);
				} else {
					setHasMoreData(false);
					setLoading(false);
				}
			} else {
				if (type === "loanProducts") {
					if (hasMoreData) {
						let response = await Department.getDepartmentProducts(
							loanProductId,
							pageCount
						);
						if (response.status === 200) {
							if (response.results.length > 0) {
								setPageCount((prev) => prev + 1);
								setProducts([...products, ...response.results]);
							} else {
								setHasMoreData(false);
							}
						} else {
							setHasMoreData(false);
						}
					}
				} else {
					let response = await Accounts.getAccountList(
						accountType,
						pageCount
					);
					if (response.status === 200) {
						setLoading(false);
						setPageCount((prev) => prev + 1);
						setAccountData([...accountData, ...response.results]);
					} else {
						setHasMoreData(false);
						setLoading(false);
					}
				}
			}
		}
	};
	useEffect(() => {
		getAccountInfoList();
	}, []);

	return (
		<IonApp>
			<IonPage>
				<IonContent
					scrollEvents={true}
					// onIonScrollEnd={(ev) => {
					// 	getOrderList(orderStatus);
					// }}
				>
					<BackButtonTitle
						title={ekkLanguage.loan.pageTitle}
						from='loan'
					/>
					{/* <TitleNSearch
						title={ekkLanguage.loan.pageTitle}
						placeholder={`Search Your loan`}
						searchQ={text}
						setSearchQ={setText}
						fetchSearchData={getSearchData}
						initData={getAccountInfoList}
						deleteQuery={deleteQueryHandler}
						inputType='string'
						from='loan'
						status={orderStatus}
						ekkLanguage={ekkLanguage}
						setLoading={setLoading}
					/> */}
					{loading && (
						<IonLoading
							isOpen={true}
							message={ekkLanguage.orderList?.loader}
						/>
					)}
					<section>
						<LoanStatus
							setOrderStatus={setOrderStatus}
							loading={loading}
							setLoading={setLoading}
							setReachedEnd={setReachedEnd}
							setHasMoreData={setHasMoreData}
							getAccountType={getAccountInfoList}
							response={accountData}
							products={products}
						/>
					</section>
					<IonInfiniteScroll
						onIonInfinite={(ev) => {
							if (reachedEnd) {
								ev.target.complete();
								return;
							}
							loadMoreTran(productType);
							setTimeout(() => {
								ev.target.complete();
								if (!hasMoreData) {
									setReachedEnd(true);
								}
							}, 1000);
						}}>
						<IonInfiniteScrollContent
							loadingText={ekkLanguage.orderList.loader}
							loadingSpinner={
								hasMoreData ? "bubbles" : null
							}></IonInfiniteScrollContent>
					</IonInfiniteScroll>
					<LoanBottomSheet />
				</IonContent>
				{/* <NavFooter /> */}
			</IonPage>
		</IonApp>
	);
}
