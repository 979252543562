import { IonCol, IonRow } from "@ionic/react";
import StockOutMini from "assets/images/icon/svg/StockOutMini";
import { digitChanger, numberWithCommas } from "helpers/helper";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLoanProductType } from "redux/container/productSlice";

export default function LoanProducts({ products, ekkLanguage }) {
	let navigate = useNavigate();
	let dispatch = useDispatch();
	return (
		<div className='pt-3'>
			{" "}
			<IonRow>
				{products?.length ? (
					products.map((el, i) => {
						let isImageLength = () => {
							if (el?.images?.length) {
								return `${el?.images[0]}`;
							} else {
								if (el?.type === "variant") {
									return "/assets/images/varient_default.png";
								} else if (el?.type === "pack") {
									return "/assets/images/pack_default.png";
								} else if (el?.type === "offer") {
									return "/assets/images/offer_default.png";
								}
							}
						};
						return (
							<IonCol size='6' sizeSm='4' className='mb-6' key={i}>
								<div className='relative overflow-hidden'>
									<div
										className='text-center flex items-center '
										onClick={() => {
											dispatch(setLoanProductType("loanRequest"));
											navigate(`/loan-product-details/${el?.id}`);
										}}>
										<div className='product-image-sizer relative rounded-[10px] flex pb-[5px] m-auto'>
											{/* {el?.inventory_quantity <= 0 && (
												<div className='flex absolute w-[44px] h-[38px] border-2 border-white bg-primary items-center justify-center rounded-br-[10px] rounded-tl-[10px] top-[-2px] left-0 border-l-0'>
													<StockOutMini />
												</div>
											)} */}

											<img
												className='w-full h-full object-contain flex m-auto mb-[5px]'
												src={isImageLength()}
												alt='icon'
											/>
										</div>
									</div>

									<h6 className='font-semibold text-14 text-262626-1000 mb-[5px] min-h-[36px]'>
										{el?.name}
									</h6>
								</div>
							</IonCol>
						);
					})
				) : (
					<>
						<IonCol size='2'></IonCol>
						<IonCol size='8'>
							<div
								className='text-center flex items-center justify-center'
								style={{
									height: "calc(100vh - 420px)",
								}}>
								<div>
									<img
										className='m-auto mb-[10px] mt-5'
										src='/assets/images/not-found-file.png'
										alt='emptyCard'
									/>
									<p className='text-16 font-normal text-222222 mb-[35px]'>
										{ekkLanguage.orderList.notFound}
									</p>
								</div>
							</div>
						</IonCol>
					</>
				)}
			</IonRow>
		</div>
	);
}
