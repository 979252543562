import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function FooterV2() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [promptInstall, setPromptInstall] = useState(null);
	const [supportsPWA, setSupportsPWA] = useState(false);
	// useEffect(() => {
	// 	const handler = (e) => {
	// 		e.preventDefault();
	// 		console.log("we are being triggered :D");
	// 		setSupportsPWA(true);
	// 		setPromptInstall(e);
	// 	};
	// 	window.addEventListener("beforeinstallprompt", handler);
	// 	return () => window.removeEventListener("transitionend", handler);
	// }, []);

	// const onClickPWADownload = (evt) => {
	// 	evt.preventDefault();
	// 	if (!promptInstall) {
	// 		return;
	// 	}
	// 	promptInstall.prompt();
	// };

	useEffect(() => {
		const handler = (e) => {
			e.preventDefault();
			console.log("we are being triggered :D");
			setSupportsPWA(true);
			setPromptInstall(e);
		};
		window.addEventListener("beforeinstallprompt", handler);
		return () => window.removeEventListener("beforeinstallprompt", handler);
	}, []);

	const onClickPWADownload = (evt) => {
		evt.preventDefault();
		if (!promptInstall) {
			console.warn("Prompt is not available.");
			return;
		}
		promptInstall.prompt();
		promptInstall.userChoice.then((choiceResult) => {
			if (choiceResult.outcome === "accepted") {
				console.log("User accepted the PWA install prompt");
			} else {
				console.log("User dismissed the PWA install prompt");
			}
			setPromptInstall(null); // Reset the state
		});
	};

	return (
		<div className='lg:absolute relative bottom-0 left-0 right-0'>
			<hr className='border border-[#0000001A]' />
			<div className='flex lg:justify-center lg:flex-row flex-col justify-center items-center container lg:py-3 py-2'>
				{/* <div className='flex flex-col'>
					<p className='text-[#808080] font-semibold text-16 lg:text-left text-center'>
						&copy; <span>EkkBaz.AI Pte. Ltd.</span>
					</p>
					<p className='text-[#808080] font-semibold text-16 lg:text-left text-center'>
						Paya Lebar Square #07-54, 60 Paya Lebar Road Singapore 409051
					</p>
				</div> */}
				<div className='flex items-center justify-center'>
					{/* <p className='text-[#000000] text-center font-semibold text-14 mr-2'>
						{ekkLanguage.landing.downloadApp}
					</p> */}
					<div className='flex justify-center lg:justify-start mt-1'>
						<a
							className='mr-4'
							target='_blank'
							href='https://play.google.com/store/apps/details?id=ekkbaz.com&hl=en&gl=US'
							rel='noreferrer'>
							<img
								src='assets/images/play-store.png'
								alt='play'
								className='h-5 w-[80px]'
							/>
						</a>
						<a
							className='mr-4'
							target='_blank'
							href='https://apps.apple.com/us/app/ekkbaz-flexible-work-earn/id1448679586'
							rel='noreferrer'>
							<img
								src='assets/images/app-store.png'
								alt='app'
								className='h-5 w-[80px]'
							/>
						</a>
						<a className='' href='!#'>
							<img
								src='assets/images/pwa.png'
								alt='pwa'
								className='w-[80px] h-5'
								onClick={onClickPWADownload}
							/>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}
