import React from "react";
import { Link } from "react-router-dom";

const LeftSidebarMenu = (props) => {
	return (
		<>
			{/* <Accordion.Item
				as='li'
				className={props.className ? props.className : undefined}
				eventKey={props.setKey}>
				<Link to={props.data?.url}>
					<i className={props.data?.icon ? props.data.icon : undefined} />
					<span>{props.data?.name}</span>
				</Link>
			</Accordion.Item> */}
		</>
	);
};

export default LeftSidebarMenu;
