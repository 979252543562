import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "moment-timezone";
import {
	addStatusClass,
	digitChanger,
	monthChanger,
	numberWithCommas,
} from "helpers/helper";
import moment from "moment-timezone";
import Bag from "assets/images/icon/svg/Bag";
import Car from "assets/images/icon/svg/Car";
import EditPen from "assets/images/icon/svg/EditPen";
import Shop from "assets/images/icon/svg/Shop";
import { setFrom } from "redux/container/orderSlice";

export default function LoanStatusCard({ response }) {
	// const response = useSelector((state) => state.order);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const dispatch = useDispatch();

	return (
		<>
			<IonGrid>
				<IonRow>
					{response?.length ? (
						response.map((order, index) => {
							return (
								<IonCol size='12' key={index}>
									<div>
										<div
											className='mb-2 rounded-[10px] overflow-hidden'
											style={{
												boxShadow: `0px 5px 15px rgba(0, 0, 0, 0.05)`,
											}}>
											<div
												className={`${
													order?.acc_group?.title === "Expense"
														? "bg-primary"
														: "bg-success"
												} py-[7px]  px-4`}>
												<ul className='flex items-center justify-center'>
													{/* <li>
														<h6 className='font-bold text-12 text-white'>
										
															{order?.acc_category?.title}
														</h6>
													</li> */}
													<li className='text-center'>
														<h6 className='font-bold text-12 text-white '>
															{digitChanger(
																moment(order?.createdAt).format(
																	"DD-MM-YYYY"
																)
															)}
														</h6>
													</li>
												</ul>
											</div>
											<div className='flex justify-between items-center'>
												<div className='px-4 bg-white py-[7px] w-[60%]'>
													<div className='flex items-center mb-1'>
														<span className='text-14 text-normal'>
															<span className='text-black-500'>
																Purpose:
															</span>{" "}
															{order?.purpose}
														</span>
													</div>
													<div className='flex items-center mb-1'>
														<span className='text-14 text-normal'>
															<span className='text-black-500'>
																duration:{" "}
															</span>
															{order?.loan_duration}
														</span>
													</div>
													<div className='flex items-center mb-1'>
														<span className='text-14 text-normal'>
															<span className='text-black-500'>
																Credit score:{" "}
															</span>

															{order?.credit_score?.title}
														</span>
													</div>
													<div></div>
													{/* <div>
														<ul className='flex account-single-info'>
															<li>
																{digitChanger(
																	order?.acc_category?.acc_code
																)}
															</li>
															<li className="font-bold">
                                {order?.acc_category?.title}
                              </li>
														</ul>
													</div> */}
												</div>
												<div className='mr-4 w-[39%] text-right'>
													<h6
														className={`font-bold text-14 ${"text-success"}`}>
														${" "}
														{numberWithCommas(order?.loan_amount)}
													</h6>
												</div>
											</div>
										</div>
									</div>
								</IonCol>
							);
						})
					) : (
						<>
							<IonCol size='2'></IonCol>
							<IonCol size='8'>
								<div
									className='text-center flex items-center justify-center'
									style={{ height: "calc(100vh - 420px)" }}>
									<div>
										<img
											className='m-auto mb-[10px] mt-5'
											src='/assets/images/not-found-file.png'
											alt='emptyCard'
										/>
										<p className='text-16 font-normal text-222222 mb-[35px]'>
											{ekkLanguage.orderList.notFound}
										</p>
									</div>
								</div>
							</IonCol>
						</>
					)}
				</IonRow>
			</IonGrid>
		</>
	);
}
