import { IonCheckbox } from "@ionic/react";
import { digitChanger, numberWithCommas, translator } from "helpers/helper";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../Home/style.scss";

export default function SingleBrandSeller({
	id,
	img,
	name,
	supplierName,
	qty,
	tag,
	offer,
	offerAmount,
	nearby,
	oldPrice,
	newPrice,
	sellPrice,
	profit,
	nameShow,
	supplierNameShow,
	skuShow,
	priceShow,
	oldPriceShow,
	distShow,
	profitShow,
	sellPriceShow,
	link,
	defaultImage,
	seeMoreProduct,
	productCard,
	type,
	currency,
	getSellerProduct,
	selectedSeller,
	setSelectedSellerId,
	setSearchQ,
	setInitSeller,
	setHasMoreData,
	setReachedEnd,
}) {
	let navigate = useNavigate();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<div className={`rounded-md `}>
			<div className='rounded-xl overflow-hidden bg-E6E6E6 p-3 mb-[5px]'>
				{selectedSeller && (
					<div className='absolute top-0 left-0'>
						<IonCheckbox
							color='success'
							className='rounded select-product'
							checked={true}
						/>
					</div>
				)}
				{img?.length ? (
					<img
						onClick={() => {
							setSelectedSellerId(id);
							getSellerProduct(id);
							setInitSeller(false);
							setHasMoreData(true);
							setReachedEnd(false);
						}}
						src={img[0]}
						alt='productImage'
						className='w-full home-card-image-sizer'
					/>
				) : (
					<div
						className=' home-card-image-sizer text-center flex items-center justify-center'
						onClick={() => {
							setSelectedSellerId(id);
							getSellerProduct(id);
							setInitSeller(false);
							setHasMoreData(true);
							setReachedEnd(false);
						}}>
						<h3
							className='font-bold capitalize text-22'
							style={{ wordBreak: "break-word" }}>
							{supplierName.split(" ")[0].split("/")[0]}
						</h3>
					</div>
					// <img
					// onClick={() => {
					// 	setSelectedSellerId(id);
					// 	getSellerProduct(id);
					// 	setInitSeller(false);
					// }}
					// 	src={defaultImage}
					// 	alt='productImage'
					// 	className='w-full home-card-image-sizer'
					// />
				)}
			</div>

			<div>
				{nameShow && <h5 className='font-semibold text-12'>{name}</h5>}
				{supplierNameShow && (
					<h5 className='font-semibold text-12'>{supplierName}</h5>
				)}

				{/* {skuShow && (
					<span className='font-normal text-10'>
						{digitChanger(qty)} {ekkLanguage.home.skusLabel}
					</span>
				)} */}
				<div className='flex justify-between'>
					{sellPriceShow && (
						<span className='flex items-center'>
							<span className='font-bold text-10 text-primary flex'>
								<span>{translator(currency)} </span>{" "}
								{sellPrice && digitChanger(numberWithCommas(sellPrice))}
							</span>
						</span>
					)}
					{priceShow && (
						<span className='font-bold text-12 text-primary flex'>
							<span>{translator(currency)} </span>
							{newPrice && digitChanger(numberWithCommas(newPrice))}
						</span>
					)}
					{oldPriceShow && (
						<span className='font-medium text-10 colors-808080 line-through flex'>
							<span>{translator(currency)} </span>{" "}
							{oldPrice && digitChanger(numberWithCommas(oldPrice))}
						</span>
					)}
					{profitShow && (
						<span className='font-medium text-10 text-success flex'>
							<span>{translator(currency)} </span>{" "}
							{profit && digitChanger(numberWithCommas(profit))}
						</span>
					)}
				</div>
				{/* <div className='flex justify-between'>
					{profitShow && (
						<span className=' flex items-center'>
							<span className='font-bold text-10 text-success flex'>
								<span>৳ </span>{" "}
								{profit && digitChanger(profit.toFixed(2))}
							</span>
						</span>
					)}
				</div> */}
				<div className='flex justify-between'>
					{distShow && (
						<span className='flex items-center'>
							<img
								src='assets/images/icon/pin.png'
								alt='location'
								className='pr-1'
							/>
							<span className='font-medium text-10 text-primary '>
								{nearby}
							</span>
						</span>
					)}
				</div>
			</div>
		</div>
	);
}
