import React, { useState, useEffect } from "react";
import LeftSidebarMenu from "./LeftSidebarMenu";
import Scrollbar from "smooth-scrollbar";
import { Link, NavLink, useLocation } from "react-router-dom";
import { digitChanger, hasActive } from "helpers/helper";
import "./styles/_SidebarComponent.scss";
import {
	ProSidebar,
	Menu,
	MenuItem,
	SidebarFooter,
	SidebarHeader,
	SidebarContent,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { useDispatch, useSelector } from "react-redux";
import CheckBusiness from "components/CheckBusiness";
import { getCreateUserBusiness, getUser, getUserBusiness } from "config/user";
import Chart from "assets/images/icon/svg/Chart";
import Modal from "react-modal";
import { setPwaDownloadData } from "redux/container/userSlice";
import Download2 from "assets/images/icon/svg/Download2";
import DeviceDetector from "device-detector-js";
import Call from "assets/images/icon/svg/Call";
import Home from "assets/images/icon/svg/Home";
import Order from "assets/images/icon/svg/OrderOutline";
// import Business from "services/Business";

const LeftSidebar = ({ isMobile, menuCollapse, menuIconClick }) => {
	const { pathname } = useLocation();
	let dispatch = useDispatch();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const pwaObject = useSelector((state) => state.user);
	const [isOpen, setIsOpen] = useState(false);
	let user = getUser();
	let userBusiness = getUserBusiness();
	const userData = useSelector((state) => state.user.data);
	// const [businessData, setBusinessData] = useState({});

	// let getBusiness = async () => {
	// 	let response = await Business.getBusiness(userBusiness?.id);
	// 	if (response.status === 200) {
	// 		setBusinessData(response.results);
	// 	}
	// };
	// useEffect(() => {
	// 	getBusiness();
	// }, []);

	useEffect(() => {
		Modal.setAppElement("body");
		Scrollbar.init(document.querySelector("#sidebar-scrollbar"));
	});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handler = (e) => {
		e.preventDefault();
		dispatch(setPwaDownloadData(e));
	};
	useEffect(() => {
		window.addEventListener("beforeinstallprompt", handler);
		return () => window.removeEventListener("beforeinstallprompt", handler);
	}, [pathname, handler]);

	const onClick = () => {
		if (!pwaObject.pwaDownloadData) {
			return;
		}
		pwaObject.pwaDownloadData.prompt();
		setIsOpen(false);
	};
	const onCloseModal = () => {
		setIsOpen(false);
	};
	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "300px",
			transition: ".4s all ease",
			borderRadius: 12,
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};
	const deviceDetector = new DeviceDetector();
	const userAgent = navigator.userAgent;
	const device = deviceDetector.parse(userAgent);

	return (
		<>
			<CheckBusiness />
			<div
				onClick={menuIconClick}
				className={`back-drop w-full h-full bg-black-1000 z-[100]  opacity-40 transition-opacity ${
					menuCollapse
						? "opacity-0 invisible absolute"
						: "opacity-40 visible fixed"
				}`}></div>
			<div
				className={`sidebar ${isMobile ? "mobiel-sidebar" : ""}`}
				style={
					menuCollapse
						? {
								width: 0,
								zIndex: 1000000,
						  }
						: {
								width: "300px",
								zIndex: 1000000,
						  }
				}>
				<div id='sidebar-scrollbar'>
					<span
						className='absolute top-2 right-2 bg-primary text-white p-1 rounded-[5px]'
						style={{ zIndex: 1111, lineHeight: 1 }}
						onClick={menuIconClick}>
						<i className='material-icons'>close</i>
					</span>
					<nav className='sidebar-menu'>
						<ProSidebar collapsed={menuCollapse} className='relative'>
							{!menuCollapse && (
								<SidebarHeader>
									<Link to='/profile'>
										<div
											className='logo-box flex items-center pt-[30px] px-5 pb-5'
											// style={{
											// 	background:
											// 		"url(assets/images/sidebarBg.png)",
											// 	position: "relative",
											// }}
										>
											<div className='user-img mr-[10px] '>
												{userData?.profile_image ? (
													<img
														src={userData?.profile_image}
														alt='avatar'
														className='h-[80px] w-[80px] rounded-full'
													/>
												) : (
													<img
														src={user?.profile_image}
														alt='user_avater'
														className='h-[80px] w-[80px] rounded-full'
													/>
												)}
											</div>
											<div className='user-info'>
												<p className='text-16 font-semibold text-222222 pb-[5px]'>
													{user?.full_name}
												</p>
												<p className='text-10 pb-[5px]'>
													{digitChanger(user?.PhoneNumber)}
												</p>
												<p className='text-10 pb-[5px]'>
													{userBusiness?.business_name}
												</p>
												<span className='text-12'>
													{userBusiness?.business_type?.title}
													{" - "}
													<span
														className={
															userBusiness?.business_status ===
															"Verified"
																? "text-success"
																: "text-primary"
														}>
														{userBusiness?.business_status}
													</span>
													{" - "}
													{userBusiness?.role}
												</span>
											</div>
										</div>
									</Link>
								</SidebarHeader>
							)}
							<SidebarContent>
								<Menu iconShape='square'>
									<MenuItem
										icon={<Home />}
										className={hasActive("/home", pathname)}>
										{ekkLanguage.letNav.homeMenuLabel}
										<NavLink to={`/home`} />
									</MenuItem>
									<MenuItem
										className={hasActive("/order-list", pathname)}
										icon={<Order />}>
										{ekkLanguage.letNav.orderMenuLabel}
										<NavLink to={`/order-list`} />
									</MenuItem>
									<MenuItem
										className={hasActive("/mysupplier", pathname)}
										icon={
											<i className='material-icons'>
												electric_rickshaw
											</i>
										}>
										{ekkLanguage.letNav.mySupplierMenuLabel}
										<NavLink to={`/mysupplier`} />
									</MenuItem>
									<MenuItem
										className={hasActive(
											"/business-profile-settings",
											pathname
										)}
										icon={<Chart />}>
										{ekkLanguage.letNav.businessProfileMenuLabel}
										<NavLink to={`/business-profile-settings`} />
									</MenuItem>
									{user?.Country === "Bangladesh" && (
										<MenuItem icon={<Call />}>
											<a
												href='tel:+8801701898145'
												style={{ color: "#3f3f3f" }}>
												{ekkLanguage.letNav.phoneCall}
											</a>
										</MenuItem>
									)}
									{/* <MenuItem
									className={hasActive("/product", pathname)}
									icon={<i className='material-icons'>inventory</i>}>
									Product
									<NavLink to={`/product`} />
								</MenuItem> */}
									{/* <MenuItem
									className={hasActive("/order-list", pathname)}
									icon={<i className='material-icons'>inventory_2</i>}>
									Suppliers
									<NavLink to={`order-list`} />
								</MenuItem> */}
									{/* <SubMenu
								title='Purchase'
								icon={<i className='material-icons'>shop_two</i>}
								className={hasActive("/purchase", pathname)}>
								<MenuItem>
									Supplier
									<NavLink to={`supplier`} />
								</MenuItem>
								<MenuItem>
									Order
									<NavLink to={`order`} />
								</MenuItem>
							</SubMenu> */}
								</Menu>
							</SidebarContent>
							<SidebarFooter>
								{pwaObject.pwaDownloadData !== undefined ? (
									<div
										className={`text-center cursor-pointer p-4  flex justify-center items-center`}
										onClick={() => onClick()}>
										<Download2 color='#FF564C' />
										<p
											className={`text-primary text-14 font-semibold pl-3 pt-1`}>
											Download EkkBaz App
										</p>
									</div>
								) : (
									<p
										className={`text-primary text-14 text-center font-semibold pl-3 pt-3 py-3`}>
										Installed{" "}
										{device?.device === "desktop"
											? device?.device?.brand
											: device?.device?.model}
									</p>
								)}
							</SidebarFooter>
						</ProSidebar>
					</nav>
				</div>
			</div>
		</>
	);
};

export default LeftSidebar;
