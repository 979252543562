import { IonCheckbox, IonCol, IonRow } from "@ionic/react";
import { BUSINESS_TYPE } from "data/businessType";
import { BUSINESS_TYPE_INFO } from "data/businessTypeImage";
import { digitChanger, numberWithCommas, translator } from "helpers/helper";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./style.scss";
import Pin from "assets/images/icon/svg/Pin";
import StockOut from "assets/images/icon/svg/StockOut";
import StockOutMini from "assets/images/icon/svg/StockOutMini";

export default function UpdatedProductCard({
	id,
	img,
	name,
	price,
	profit,
	type,
	image,
	currency,
	business_type,
	iQty,
	category,
	weight,
	weight_unit,
}) {
	let navigate = useNavigate();
	const [checked, setChecked] = useState(false);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	//   console.log(type)

	let isImageLength = () => {
		if (img?.length) {
			if (img[0]?.length > 0) {
				return `${img[0]}`;
			}
		} else {
			if (type === "variant") {
				return "/assets/images/varient_default.png";
			} else if (type === "pack") {
				return "/assets/images/pack_default.png";
			} else if (type === "offer") {
				return "/assets/images/offer_default.png";
			}
		}
	};

	let businessType = () =>
		BUSINESS_TYPE_INFO.find((el) => el.type === business_type);
	return (
		<div className='pt-3'>
			{" "}
			<div className='relative overflow-hidden'>
				<div
					className='text-center flex items-center '
					onClick={() => navigate(`/product-details/${id}`)}>
					<div className='product-image-sizer relative rounded-[10px] flex pb-[5px] m-auto'>
						{iQty <= 0 && (
							<div className='flex absolute w-[44px] h-[38px] border-2 border-white bg-primary items-center justify-center rounded-br-[10px] rounded-tl-[10px] top-[-2px] left-0 border-l-0'>
								<StockOutMini />
							</div>
						)}
						{Number(profit) > 0 && (
							<div className='flex absolute w-auto h-[24px] border-2 border-white bg-success items-center justify-center rounded-[10px]  top-0 right-0'>
								<span className='ml-[5px] font-bold text-12 text-white px-1'>
									{currency && currency?.currency_symbol_native}
									{digitChanger(numberWithCommas(profit))}
								</span>
							</div>
						)}

						<img
							className='w-full h-full object-contain flex m-auto mb-[5px]'
							src={isImageLength()}
							alt='icon'
						/>
					</div>
				</div>
				<h5 className='font-bold text-14 text-primary mb-[5px]'>
					{currency && currency?.currency_symbol_native}
					{digitChanger(numberWithCommas(price))}
					{/* <span className='text-10 font-normal text-808080 ml-2 line-through'>
																{el?.country &&
																	el?.country
																		?.currency_symbol_native}
																{digitChanger(
																	numberWithCommas(
																		el?.base_price + 10
																	)
																)}
															</span> */}
				</h5>
				<h6 className='font-semibold text-14 text-262626-1000 mb-[5px] min-h-[36px]'>
					{name}
				</h6>
				<div className='flex justify-between items-center mr-4'>
					<div>
						<h6 className='font-semibold text-14 text-black-500'>
							{weight} <span>{weight_unit}</span>
						</h6>
					</div>
					{/* <div className='flex'>
																<StarBig />
																<span className='text-14 text-black-1000 ml-1'>
																	3.7
																</span>
															</div> */}
				</div>
			</div>
		</div>
	);
}
