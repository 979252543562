import {
  IonCol,
  IonGrid,
  IonIcon,
  IonLoading,
  IonRow,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
import NotificationDot from "assets/images/icon/svg/NotificationDot";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrder } from "redux/container/orderSlice";

function NotificationTypeBtn({ setOrderStatus }) {
  const dispatch = useDispatch();
  const ekkLanguage = useSelector((state) => state.lan.lan);

  return (
    <>
      <IonGrid>
        <IonRow>
          <IonCol size="12">
            <IonSegment
              value=""
              scrollable
              className="orderBtnWrapper no-ripple"
              onIonChange={(e) => {
                setOrderStatus(e.detail.value);
                dispatch(fetchOrder(e.detail.value));
              }}
            >
              <IonSegmentButton value="" className="cancel relative">
                <button className="text-black-1000 font-sans p-0 font-normal shadow-none text-14">
                  {ekkLanguage.notification.order}
                </button>
                <div className="absolute top-4 right-1">
                  <NotificationDot />
                </div>
              </IonSegmentButton>
              <IonSegmentButton value="Cancel" className="cancel relative">
                {/* text-FFC227-1000 */}
                <button className=" font-sans p-0 font-normal shadow-none text-14">
                  {ekkLanguage.notification.product}
                </button>
              </IonSegmentButton>
              <IonSegmentButton value="Accepted" className="cancel relative">
                {/* text-ADA2FF */}
                <button className={`  font-sans p-0 shadow-none text-14`}>
                  {ekkLanguage.notification.update}
                </button>
              </IonSegmentButton>
            </IonSegment>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
}
export default memo(NotificationTypeBtn);
