import {
	IonApp,
	IonContent,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonPage,
} from "@ionic/react";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import TitleNSearch from "components/mobile-components/Common/TitleNSearch";
import AccountsStatus from "components/mobile-components/Accounts/AccountsStatus";
import AccountBottomSheet from "components/mobile-components/Accounts/AccountBottomSheet";
import Accounts from "services/Accounts";
import NavFooter from "components/mobile-components/Common/NavFooter";
import moment from "moment";
import {
	getSelectedDate,
	setAccountData,
	setAccountDataLoading,
	setAccountingCategory,
	setAccountType,
	setAccountTypeDetails,
	setCategory,
	setIsCategory,
	setIsFilter,
	setPageCount,
	setScrollPosition,
} from "redux/container/dashboardSlice";
import { createRef } from "react";

export default function AccountsPage() {
	const selectedTab = useSelector((state) => state.accounts.selectedTab);
	const showFilter = useSelector((state) => state.dashboard.isFilter);
	const showCategory = useSelector((state) => state.dashboard.isCategory);
	const loading = useSelector((state) => state.dashboard.accountDataLoading);
	const accountType = useSelector((state) => state.dashboard.accountType);
	const pageCount = useSelector((state) => state.dashboard.pageCount);
	const category = useSelector((state) => state.dashboard.category);
	const accountingSort = useSelector(
		(state) => state.dashboard.accountingSort
	);
	const scrollPosition = useSelector(
		(state) => state.dashboard.scrollPosition
	);
	const [menuCollapse, setMenuCollapse] = useState(true);
	const [orderStatus, setOrderStatus] = useState(selectedTab);
	const dispatch = useDispatch();
	const [text, setText] = useState("");
	const contentRef = createRef();

	const menuIconClick = () => {
		setMenuCollapse(!menuCollapse);
	};
	const [hasMoreData, setHasMoreData] = useState(true);
	const [reachedEnd, setReachedEnd] = useState(false);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const date = useSelector((state) => state.dashboard.dateInfo);
	const dateFormat = useSelector((state) => state.date.format);
	const accountData = useSelector((state) => state.dashboard.accountData);

	let getAccountType = async (title, start, end, categoryId) => {
		dispatch(setAccountDataLoading(true));
		let response = await Accounts.getAccountType(title ?? "Revenue");
		if (response.status == 200) {
			getAccountInfoList(response?.results?.id, start, end, categoryId);
			dispatch(setAccountType(response?.results?.id));
			dispatch(setAccountTypeDetails(response?.results));
		} else {
			dispatch(setAccountData([]));
		}
	};

	let getAccountInfoList = async (accountId, start, end, categoryId) => {
		dispatch(setAccountDataLoading(true));
		dispatch(setPageCount(2));
		let response = await Accounts.getAccountList(
			accountId,
			start,
			end,
			accountingSort,
			categoryId ?? null
		);
		if (response?.success) {
			dispatch(setAccountDataLoading(false));
			dispatch(setAccountData(response?.results));
		} else {
			dispatch(setAccountDataLoading(false));
			dispatch(setAccountData([]));
		}
	};

	let getSearchData = async () => {
		dispatch(setAccountDataLoading(true));
		dispatch(setPageCount(2));
		let response = await Accounts.getAccountType(orderStatus ?? "Revenue");
		if (response?.success) {
			let resp = await Accounts.getAccountListSearch(
				response?.results?.id,
				text,
				date?.start,
				date?.end,
				1,
				500
			);
			if (!resp?.results?.length) {
				setHasMoreData(false);
				dispatch(setAccountDataLoading(false));
			}
			if (resp?.success) {
				dispatch(setAccountDataLoading(false));
				dispatch(setAccountData(resp?.results));
			} else {
				dispatch(setAccountDataLoading(false));
				dispatch(setAccountData([]));
			}
		}
	};

	const deleteQueryHandler = async (e) => {
		dispatch(setAccountDataLoading(false));
		setText("");
		getAccountType(orderStatus, date?.start, date?.end, category?.id);
	};

	let loadMoreTran = async () => {
		if (hasMoreData) {
			if (text) {
				let response = await Accounts.getAccountListSearch(
					accountType,
					text,
					date?.start,
					date?.end,
					pageCount
				);
				if (response.status === 200) {
					dispatch(setAccountDataLoading(false));
					dispatch(setPageCount(pageCount + 1));
					dispatch(setAccountData([...accountData, ...response.results]));
				} else {
					dispatch(setAccountDataLoading(false));
					setHasMoreData(false);
				}
			} else {
				let response = await Accounts.getAccountList(
					accountType,
					date?.start,
					date?.end,
					accountingSort,
					category?.id ?? null,
					pageCount
				);
				if (response.status === 200) {
					dispatch(setAccountDataLoading(false));
					dispatch(setPageCount(pageCount + 1));
					dispatch(setAccountData([...accountData, ...response.results]));
				} else {
					setHasMoreData(false);
					dispatch(setAccountDataLoading(false));
				}
			}
		}
	};

	const filterHide = () => {
		setReachedEnd(false);
		setHasMoreData(true);
		let dateTime = { start: null, end: null, level: null };
		dispatch(getSelectedDate(dateTime));
		dispatch(setIsFilter(false));
		getAccountType(orderStatus, null, null, category?.id);
	};

	const categoryRemove = () => {
		dispatch(setIsCategory(false));
		dispatch(setAccountingCategory(null));
		getAccountType(orderStatus, date?.start, date?.end, null);
	};

	useEffect(() => {
		if (date?.start && date?.end) {
			setReachedEnd(false);
			setHasMoreData(true);
			getAccountType(orderStatus, date?.start, date?.end, category?.id);
		}
	}, [date?.start, date?.end, accountingSort]);

	useEffect(() => {
		if (contentRef.current) {
			contentRef.current?.scrollByPoint(0, scrollPosition);
		}
	}, []);

	const handleScroll = (e) => {
		const scrollPosition = e.detail.scrollTop;
		dispatch(setScrollPosition(scrollPosition));
	};

	useEffect(() => {
		if (contentRef.current) {
			contentRef.current.addEventListener("ionScroll", handleScroll);
		}

		return () => {
			if (contentRef.current) {
				contentRef.current.removeEventListener("ionScroll", handleScroll);
			}
		};
	}, [contentRef]);


	return (
		<IonApp>
			<IonPage>
				<IonContent scrollEvents={true} ref={contentRef}>
					<TitleNSearch
						title={"Accounting"}
						placeholder={`Search Your ${orderStatus}`}
						searchQ={text}
						setSearchQ={setText}
						fetchSearchData={getSearchData}
						initData={getAccountType}
						deleteQuery={deleteQueryHandler}
						inputType='string'
						from='accounts'
						status={orderStatus}
						ekkLanguage={ekkLanguage}
						filter={true}
						sort={true}
						showFilter={showFilter}
						filterHide={filterHide}
						setHasMoreData={setHasMoreData}
						setReachedEnd={setReachedEnd}
					/>
					<div className='pt-14'>
						{showFilter && (
							<p className='px-4 pt-1 text-16 text-black cursor-pointer'>
								<span>
									Filter On:{" "}
									<span
										className='font-bold text-16 capitalize'
										onClick={filterHide}>
										{" "}
										{moment(date?.start, "YYYY-MM-DD").format(
											dateFormat
										)}{" "}
										-{" "}
										{moment(date?.end, "YYYY-MM-DD").format(
											dateFormat
										)}
										<span
											className='cursor-pointer font-bold text-14 border rounded-full h-[18px] w-[18px] inline-block text-center'
											style={{ lineHeight: "16px" }}>
											X
										</span>
									</span>
								</span>
							</p>
						)}
						{showCategory && (
							<p className='px-4 pt-1 text-16 text-black cursor-pointer'>
								<span>
									Category:{" "}
									<span
										className='font-bold text-16 capitalize'
										onClick={categoryRemove}>
										{" "}
										{category?.title}
										<span
											className='cursor-pointer font-bold text-14 border rounded-full h-[18px] w-[18px] inline-block text-center'
											style={{ lineHeight: "16px" }}>
											X
										</span>
									</span>
								</span>
							</p>
						)}
					</div>

					<section>
						<AccountsStatus
							setOrderStatus={setOrderStatus}
							loading={loading}
							setReachedEnd={setReachedEnd}
							setHasMoreData={setHasMoreData}
							getAccountType={getAccountType}
							response={accountData}
							start={date?.start}
							end={date?.end}
							category={category}
							isCategory={showCategory}
						/>
					</section>
					{!loading && (
						<IonInfiniteScroll
							onIonInfinite={(ev) => {
								if (reachedEnd) {
									ev.target.complete();
									return;
								}
								loadMoreTran(orderStatus);
								setTimeout(() => {
									ev.target.complete();
									if (!hasMoreData) {
										setReachedEnd(true);
									}
								}, 1000);
							}}>
							<IonInfiniteScrollContent
								loadingText={ekkLanguage.orderList.loader}
								loadingSpinner={
									hasMoreData ? "bubbles" : null
								}></IonInfiniteScrollContent>
						</IonInfiniteScroll>
					)}

					<AccountBottomSheet />
				</IonContent>
				<NavFooter />
			</IonPage>
		</IonApp>
	);
}
