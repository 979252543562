import { IonApp, IonContent, IonPage } from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import React from "react";
import { useSelector } from "react-redux";
import ExpenseEditForm from "components/mobile-components/Accounts/ExpenseEditForm";

export default function AccountExpenseEditPage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<IonApp>
			<IonPage>
				<IonContent scrollEvents={true}>
					<BackButtonTitle title='Expense Update' />

					<section className='pt-14 px-6'>
						<ExpenseEditForm ekkLanguage={ekkLanguage} />
					</section>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
