import { IonCol } from "@ionic/react";
import CancelPopUp from "assets/images/icon/svg/CancelPopUp";
import moment from "moment";
import React, { forwardRef, useState } from "react";
import { useSelector } from "react-redux";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { ClipLoader } from "react-spinners";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Accounts from "services/Accounts";
import { toast } from "react-toastify";

export default function EmployeeWorkingList({
	employees,
	employeeHours,
	ekkLanguage,
	totalAmount,
	getEmployees,
	currentWeekStart,
	currentWeekEnd,
	previousWeekHandler,
	nextWeekHandler,
	employeeSalary,
	totalWeeklyAmount,
}) {
	const dateFormat = useSelector((state) => state.date.format);
	const [totalWorkingHours, setTotalWorkingHours] = useState("00:00:00");
	const [selectedHours, setSelectedHours] = useState(null);

	const [isOpenEditHours, setIsOpenEditHours] = useState(false);
	const onCloseModalEditHours = () => {
		setIsOpenEditHours(false);
	};
	const onOpenModalEditHours = () => setIsOpenEditHours(true);

	const [editHoursLoader, setEditHoursLoader] = useState(false);
	const [isOpenEditHoursModel, setIsOpenEditHoursModel] = useState(false);
	const onCloseModalEditHoursModel = () => {
		setTotalWorkingHours("00:00:00");
		setIsOpenEditHoursModel(false);
	};
	const onOpenModalEditHoursModel = () => setIsOpenEditHoursModel(true);

	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "calc(100% - 40px)",
			transition: ".4s all ease",
			borderRadius: 12,
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
			overflow: "auto",
		},
	};

	const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
		<div
			ref={ref}
			className={`p-3 block mt-4 example-custom-input pl-4 h-[50px] rounded-[5px] bg-F8F8F8  w-full border border-black-500`}
			onClick={onClick}>
			<div className='flex justify-between items-center'>
				<div className='flex items-center '>
					{selectedHours?.check_in_time
						? moment(selectedHours?.check_in_time).format(
								`${dateFormat}-HH:mm:ss`
						  )
						: "Select Time and Date"}
				</div>
			</div>
		</div>
	));

	const ExampleCustomInput2 = forwardRef(({ value, onClick }, ref) => (
		<div
			ref={ref}
			className={`p-3 block mt-4 example-custom-input pl-4 h-[50px] rounded-[5px] bg-F8F8F8  w-full border border-black-500`}
			onClick={onClick}>
			<div className='flex justify-between items-center'>
				<div className='flex items-center '>
					{selectedHours?.check_out_time
						? moment(selectedHours?.check_out_time).format(
								`${dateFormat}-HH:mm:ss`
						  )
						: "Select Time and Date"}
				</div>
			</div>
		</div>
	));

	let dateChanger = (date, type) => {
		let updatedHours;
		if (type === "start") {
			updatedHours = {
				...selectedHours,
				check_in_time: date,
			};
		} else {
			updatedHours = {
				...selectedHours,
				check_out_time: date,
			};
		}
		setSelectedHours(updatedHours);

		// Calculate total hours if both dates exist
		if (updatedHours.check_in_time && updatedHours.check_out_time) {
			const start = moment(updatedHours.check_in_time);
			const end = moment(updatedHours.check_out_time);
			const duration = moment.duration(end.diff(start));

			const hours = Math.floor(duration.asHours())
				.toString()
				.padStart(2, "0");
			const minutes = duration.minutes().toString().padStart(2, "0");
			const seconds = duration.seconds().toString().padStart(2, "0");

			setTotalWorkingHours(`${hours}:${minutes}:${seconds}`);
		}
	};

	const updateHoursSubmit = async () => {
		setEditHoursLoader(true);
		let payload = {
			check_out_time: moment(selectedHours?.check_out_time).format(
				"YYYY-MM-DDTHH:mm:ssZ"
			),
			check_in_time: moment(selectedHours?.check_in_time).format(
				"YYYY-MM-DDTHH:mm:ssZ"
			),
		};
		// console.log(payload);
		let response = await Accounts.checkOut(selectedHours?.id, payload);

		if (response.success) {
			getEmployees();
			setEditHoursLoader(false);
			toast.success("Working hours updated successfully");
			onCloseModalEditHours();
			onCloseModalEditHoursModel();
		} else {
			setEditHoursLoader(false);
		}
	};

	return (
		<div className='bg-white'>
			<div className='px-4 py-[10px]'>
				<h6 className='font-bold text-16'>Work Hours</h6>

				<div className='flex justify-between items-center mb-5'>
					<h4 className='font-bold text-16 text-black-1000'>
						Total Working Hours: {totalAmount}
					</h4>
				</div>

				{/* <div className='flex justify-between items-center pb-4'>
					<h4 className='font-bold text-16 text-black-1000'>
						Total Weekly Amount: {totalWeeklyAmount}
					</h4>
				</div> */}

				<div className='flex justify-between py-3 items-center'>
					<button
						className='font-bold h-9 rounded-[5px] ml-2 border-2 bg-success text-white px-2 text-12'
						onClick={previousWeekHandler}>
						{ekkLanguage.dashboard.perviousWeek}
					</button>

					<div className='flex justify-between items-center mb-1 mt-1'>
						<h4 className='font-bold text-16 text-black-1000'>
							{moment(currentWeekStart, "YYYY-MM-DD").format(dateFormat)}{" "}
							- {moment(currentWeekEnd, "YYYY-MM-DD").format(dateFormat)}
						</h4>
					</div>

					<button
						className={`font-bold h-9 rounded-[5px] ml-2 border-2 ${
							currentWeekEnd.isSameOrAfter(moment(), "day")
								? "bg-36A94D-333"
								: "bg-success"
						}  text-white px-2 text-12`}
						onClick={nextWeekHandler}
						disabled={currentWeekEnd.isSameOrAfter(moment(), "day")}>
						{ekkLanguage.dashboard.nextWeek}
					</button>
				</div>
				<div className='mb-4'>
					<table
						className='table-auto w-full bg-white'
						style={{
							boxShadow: "0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
						}}>
						<thead>
							<tr className='border h-[45px] border-r-0 border-l-0 border-D9D9D9 px-4'>
								<th className='font-bold text-14 text-left'>Start Date Time <br/>End Date Time</th>
								<th className='font-bold text-14 text-left'>Working Hours <br/>Created By</th>
						
							</tr>
						</thead>

						<tbody>
							{employeeHours?.length > 0 &&
								employeeHours?.map((el, i) => (
									<tr
										key={i}
										className='text-center h-[34px] border border-r-0 border-l-0 border-D9D9D9 px-4'
										onClick={() => {
											setSelectedHours(el);
											setTotalWorkingHours(el?.working_hour);
											onOpenModalEditHours();
										}}>
										<td className='font-semibold text-12 text-left'>
											{moment(el?.check_in_time).format(
												`${dateFormat}-HH:mm:ss`
											)}
											<br/>
											{el?.check_out_time
												? moment(el?.check_out_time).format(
														`${dateFormat}-HH:mm:ss`
												  )
												: "On Going"}
										</td>
									
										<td className='font-semibold text-12 text-left'>
											{el?.working_hour} <br/> {el?.created_by_username}
										</td>
										
									</tr>
								))}
						</tbody>
					</table>

					{employeeHours.length === 0 && (
						<>
							<IonCol size='2'></IonCol>
							<IonCol size='8'>
								<div
									className='text-center flex items-center justify-center'
									style={{ height: "calc(100vh - 420px)" }}>
									<div>
										<img
											className='m-auto mb-[10px] mt-5'
											src='/assets/images/not-found-file.png'
											alt='emptyCard'
										/>
										<p className='text-16 font-normal text-222222 mb-[35px]'>
											{ekkLanguage.orderList.notFound}
										</p>
									</div>
								</div>
							</IonCol>
						</>
					)}
				</div>
			</div>

			<Modal
				style={customStyles}
				open={isOpenEditHours}
				onClose={() => setIsOpenEditHours(false)}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2'>
						<CancelPopUp />
					</span>
					<h6 className='text-16 font-bold pb-[10px]'>
						Are you sure you want to edit the working hours?
					</h6>

					<div className='flex'>
						<button
							className=' font-bold h-10 bg-primary rounded-[7px] w-1/2 mr-2 text-white  border-solid'
							onClick={onCloseModalEditHours}>
							{ekkLanguage.orderDetails.noBtn}
						</button>
						<button
							color='primary'
							className='font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-success text-white'
							onClick={() => {
								onOpenModalEditHoursModel();
								onCloseModalEditHours();
							}}>
							{/* {cancelLoader ? (
								<ClipLoader
									sizeUnit={"px"}
									size={20}
									color={"white"}
									loading={true}
								/>
							) : (
								ekkLanguage.orderDetails.yesBtn
							)} */}
							{ekkLanguage.orderDetails.yesBtn}
						</button>
					</div>
				</div>
			</Modal>
			<Modal
				style={customStyles}
				open={isOpenEditHoursModel}
				onClose={() => setIsOpenEditHoursModel(false)}
				// classNames='bottom-sheet-modal'
			>
				<div className='p-5'>
					<div>
						<div className='pb-5'>
							<div className='flex'>
								<h4 className='font-bold text-14'>Start Date Time</h4>
							</div>
							<DatePicker
								showTimeSelect
								timeFormat='HH:mm'
								timeIntervals={15}
								dateFormat='dd/MM/yyyy HH:mm'
								selected={
									selectedHours?.check_in_time
										? moment(selectedHours.check_in_time).toDate()
										: new Date()
								}
								onChange={(date) => dateChanger(date, "start")}
								customInput={<ExampleCustomInput />}
								withPortal
								timeCaption='T(24H)'
								// popperClassName="small-datepicker"
							/>
							 
						</div>
						<div className='pb-5'>
							<div className='flex'>
								<h4 className='font-bold text-14'>End Date Time</h4>
							</div>
							<DatePicker
								showTimeSelect
								timeFormat='HH:mm'
								timeIntervals={15}
								dateFormat='MMMM d, yyyy h:mm aa'
								selected={
									selectedHours?.check_out_time
										? moment(selectedHours.check_out_time).toDate()
										: new Date()
								}
								onChange={(date) => dateChanger(date, "end")}
								customInput={<ExampleCustomInput2 />}
								withPortal
								timeCaption='T(24H)'
								minDate={
									selectedHours?.check_in_time
										? moment(selectedHours.check_in_time).toDate()
										: new Date()
								}
							/>
						</div>
						<div className='pb-5'>
							<div className='flex'>
								<h4 className='font-bold text-14'>
									Total Working Hours: {totalWorkingHours}
								</h4>
							</div>
						</div>
					</div>

					<div className='flex'>
						<button
							className=' font-bold h-10 bg-primary rounded-[7px] w-1/2 mr-2 text-white  border-solid'
							onClick={() => {
								onCloseModalEditHours();
								onCloseModalEditHoursModel();
							}}>
							{ekkLanguage.orderDetails.noBtn}
						</button>
						<button
							color='primary'
							className='font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-success text-white'
							onClick={updateHoursSubmit}>
							{editHoursLoader ? (
								<ClipLoader
									sizeUnit={"px"}
									size={20}
									color={"white"}
									loading={true}
								/>
							) : (
								ekkLanguage.orderDetails.yesBtn
							)}
						</button>
					</div>
				</div>
			</Modal>
		</div>
	);
}
