import { IonButton, IonCol, IonGrid, IonRow, IonSpinner } from "@ionic/react";
import { getUser } from "config/user";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { authOtpSend } from "helpers/helper";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

export default function VerifyNumberSend() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let user = getUser();
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();
	//this function handle token resend
	const resendTokenHandler = async (phoneNumber, country, countryCode) => {
		setIsLoading(true);
		let getToken = authOtpSend(phoneNumber, country, countryCode);
		getToken
			.then((res) => {
				if (res.status === 200) {
					setIsLoading(false);
					// setBlockIndex(authBox_Block.TOKEN);
					toast.success(ekkLanguage.otpsend.successLabel);
				} else {
					setIsLoading(false);
					// setBlockIndex(authBox_Block.TOKEN);
					toast.success(ekkLanguage.otpsend.errorLabel);
				}
			})
			.catch((err) => {
				setIsLoading(false);
				console.log(err);
			});
	};
	return (
		<div className='px-2 pt-24 relative change-password-screen'>
			<IonGrid>
				<IonRow>
					<IonCol size='12'>
						<div className='mb-10 text-center'>
							<img
								src='assets/images/email.png'
								alt=''
								className='m-auto mb-5'
							/>
							<h6 className='text-16 color-black-1000 font-medium'>
								{ekkLanguage.otpsend.message}
							</h6>
						</div>
					</IonCol>
				</IonRow>
				<div className='submit-button-wrapper'>
					<IonRow>
						<IonCol size='12'>
							<div
								className='text-center'
								onClick={() => {
									if (!isLoading) {
										navigate("/otp-submit");
										resendTokenHandler(
											user.phone_number,
											user.Country,
											""
										);
									}
								}}>
								<IonButton
									expand='full'
									className='bg-success rounded-[7px] font-extrabold text-12 h-10'>
									{isLoading ? (
										<IonSpinner color='light'></IonSpinner>
									) : (
										ekkLanguage.otpsend.btnLabel
									)}
								</IonButton>
							</div>
						</IonCol>
					</IonRow>
				</div>
			</IonGrid>
		</div>
	);
}
